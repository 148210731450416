import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

// material UI
import { Stepper, Step, StepButton, Paper } from '@material-ui/core';

import SerialMailTemplateSelection from './SerialMailTemplateSelection';
import SerialMailEditorSection from './SerialMailEditorSection';

import * as CONSTANTS from '../../constants';
import * as CONFIG from '../../config';
import * as UTILS from '../../utils/utilFunctions';
import withUser from '../../utils/withUser';

class SerialMailContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      templateId: '',
      signatureId: '',
      contactHistoryData: {}
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  handleContinueButtonClick = ({ selectedTemplateId, selectedSignatureId, contactHistory }) => {
    this.setState({
      activeStep: 1,
      templateId: selectedTemplateId,
      signatureId: selectedSignatureId,
      contactHistoryData: contactHistory
    });
  };

  handleSendMailButtonClick = (serialMail, isTestMail, validCandidateCount) => {
    const contactHistoryData = this.state.contactHistoryData;
    const pastContact = {
      contactDate: contactHistoryData.date,
      contactType: 'Serienmail',
      project: contactHistoryData.project,
      rejected: contactHistoryData.rejectionNotice,
      subject: contactHistoryData.subject || serialMail.subject,
      comment: contactHistoryData.comment,
      isGeneralEntry: contactHistoryData.generalEntry
    };
    const requestBody = { serialMail, pastContact };

    const url = isTestMail ? CONSTANTS.sendTestMailURL : CONSTANTS.sendSerialMailURL;
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        console.log('API /sendMail or /sendTestMail response body: ', responseBody);
        if (isTestMail) {
          this.props.handleSnackbarOpen('Die Testmails wurden an Ihre hinterlegte Email-Adresse versandt!');
        } else if (!responseBody.success) {
          this.props.handleSnackbarOpen('Die Mails konnten nicht versandt werden. Evtl. ist Ihr tägliches Limit überschritten.');
        } else {
          this.setState({
            activeStep: 2
          });
          // the mails are sent in background, so just assume all valid mails have been sent
          // and increase the count of the user in app state
          this.props.liftUpStateToApp({
            user: {
              ...this.props.user,
              serialMailCount: this.props.user.serialMailCount + validCandidateCount
            }
          });
        }
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.errorHandling(err, url);
      });
  };

  render() {
    const steps = ['Vorlage auswählen', 'Serienmail versenden', 'Bestätigung'];
    const { activeStep } = this.state;
    return (
      <div>
        <div
          style={{
            maxWidth: '500px',
            marginLeft: 'auto',
            marginRight: 'auto'
          }}
        >
          <Stepper nonLinear activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton disabled completed={index < activeStep}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-1" />
            <div className="col-10">
              <Paper style={{ padding: '28px 48px' }}>
                {this.state.activeStep === 0 && (
                  <SerialMailTemplateSelection
                    markListId={this.props.markListId}
                    handleContinueButtonClick={this.handleContinueButtonClick}
                    serialMailType={this.props.serialMailType}
                  />
                )}
                {this.state.activeStep === 1 && (
                  <SerialMailEditorSection
                    key={`${this.props.markList}-${this.state.templateId}`}
                    templateId={this.state.templateId}
                    signatureId={this.state.signatureId}
                    markList={this.props.markList}
                    handleSendMailButtonClick={this.handleSendMailButtonClick}
                    serialMailType={this.props.serialMailType}
                  />
                )}
                {this.state.activeStep === 2 && (
                  <div style={{ padding: '50px 0 200px 0', textAlign: 'center' }}>
                    <div className="confirmation-dialog-title">
                      <i className="material-icons confirmation-dialog-title-icon pb-3">check_circle_outline</i>
                    </div>
                    <p>Die Serienmails werden im Hindergrund automatisch versendet!</p>
                  </div>
                )}
              </Paper>
            </div>
            <div className="col-10" />
          </div>
        </div>
      </div>
    );
  }
}

SerialMailContainer.propTypes = {
  markListId: PropTypes.string.isRequired,
  markList: PropTypes.object.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  serialMailType: PropTypes.string.isRequired
};

export default withUser(SerialMailContainer);
