import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';

import IconButton from '@material-ui/core/IconButton';
import $ from 'jquery';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';

class LoadSearchBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      savedSearches: []
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.getSavedSearches = this.getSavedSearches.bind(this);
  }

  getSavedSearches() {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.getSavedSearchesURL;
    $.ajax({
      url,
      method: 'POST',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        console.log('loaded Searches', responseBody);
        this.setState({
          savedSearches: responseBody.savedSearches
        });
      })
      .fail(err => {
        console.log('error while loading');
        this.errorHandling(err, url, this.liftUpLoggedIn);
      });
  }

  deleteSavedSearch(savedSearchId) {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.getDeleteSearchesURL;
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify({ savedSearchId }),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        if (status === 'success') {
          this.setState(prevState => ({
            savedSearches: prevState.savedSearches.filter(savedSearch => savedSearch.searchID !== savedSearchId)
          }));
        }
      })
      .fail(err => {
        console.log('error while deletion of search', JSON.stringify(err));
        this.errorHandling(err, url, this.liftUpLoggedIn);
      });
  }

  getDialogContext() {
    if (this.state.savedSearches.length < 1) {
      return 'Es sind noch keine gespeicherten Listen vorhanden';
    }
    return 'Wählen Sie eine gespeicherte Suche aus der Liste';
  }

  renderListItems() {
    const savedSearches = this.state.savedSearches;
    const array = [];
    savedSearches.forEach(savedSearch => {
      const savedSearchQuery = [];
      savedSearch.query.forEach(column => {
        column.column.forEach(searchTerm => {
          savedSearchQuery.push(searchTerm.searchTerm);
        });
      });
      array.push(
        <ListItem
          button
          onClick={() =>
            this.props.handleLoad(
              savedSearch.query,
              savedSearch.geoLocation,
              savedSearch.distance,
              savedSearch.postalCode,
              savedSearch.filters
            )
          }
          component={Link}
          to="/search"
          color="primary"
          key={savedSearch.name}
        >
          <ListItemText primary={savedSearch.name} secondary={savedSearchQuery.join(', ')} />
          <ListItemSecondaryAction>
            <IconButton aria-label="Delete" onClick={() => this.deleteSavedSearch(savedSearch.searchID)}>
              <i className="material-icons">delete</i>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
    return array;
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={() => this.props.liftUpStateToApp({ loadSearchBoxOpen: false })}
        onEntering={this.getSavedSearches}
        aria-labelledby="loadSearchBox-dialog-title"
      >
        <DialogTitle id="loadSearchBox-dialog-title">Suche Laden</DialogTitle>
        <DialogContent>
          <DialogContentText>{this.getDialogContext()}</DialogContentText>
          <List style={{ maxHeight: '100%', overflow: 'auto' }}>{this.renderListItems()}</List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.props.handleClose}>
            Abbrechen
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

LoadSearchBox.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleLoad: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
};
export default LoadSearchBox;
