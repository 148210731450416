/* eslint-disable jsx-a11y/anchor-is-valid */
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export default function IntranetBreadcrumbs({ folderList, currentFolder, onFolderClick }) {
  return (
    <Breadcrumbs maxItems={5} aria-label="breadcrumb">
      {folderList.map(folder => (
        <Link key={folder.id} color="inherit" href="#" onClick={() => onFolderClick(folder.id)}>
          {folder.name}{' '}
        </Link>
      ))}
      {/* no ID means root */}
      {currentFolder.id && <Typography color="textPrimary">{currentFolder.name}</Typography>}
    </Breadcrumbs>
  );
}
IntranetBreadcrumbs.propTypes = {
  folderList: PropTypes.array.isRequired,
  onFolderClick: PropTypes.func.isRequired,
  currentFolder: PropTypes.object.isRequired
};
