import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
// import Validator from 'validator';
import StarRating from './StarRating';

import TEXT from '../text';

class SendSearchBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ratings: {
        rating1: 3,
        rating2: 3,
        rating3: 3,
        rating4: 3
      }
      // TODO eslint error for unused state
      // isPostalCodeValid: false
    };
  }

  // TODO after testing please remove
  // onPostalCodeChange = e => {
  //   // TODO eslint error for unused state
  //   // if (Validator.isNumeric(e.target.value) && e.target.value.length === 5) {
  //   //   this.setState({
  //   //     isPostalCodeValid: true
  //   //   });
  //   // } else {
  //   //   this.setState({
  //   //     isPostalCodeValid: false
  //   //   });
  //   // }
  //   this.props.liftUpStateToApp('postalCode', e.target.value);
  // };

  onStarChange = (e, groupName) => {
    this.setState(prevState => ({
      ratings: {
        ...prevState.ratings,
        [groupName]: e
      }
    }));
  };

  showResultButtonHandler = () => {
    this.props.showResultButtonHandler(this.state.ratings);
  };

  render() {
    // TODO after testing please remove
    // const postalCodeError =
    //   this.props.postalCode.length > 5 ||
    //   (!Validator.isNumeric(this.props.postalCode) &&
    //     this.props.postalCode.length > 0);

    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{TEXT.searchPage.sendSearchBox.headline}</DialogTitle>
        <DialogContent>
          <DialogContentText>{TEXT.searchPage.sendSearchBox.sendSearchBoxMessage}</DialogContentText>
          {/* // TODO after testing please remove */}
          {/* <div className="row">
            <div className="col-12 pb-3 pt-1">
              <TextField
                fullWidth
                onChange={this.onPostalCodeChange}
                value={this.props.postalCode}
                label={TEXT.searchPage.sendSearchBox.postalCodeLabel}
                autoFocus
                error={postalCodeError}
                helperText={
                  postalCodeError
                    ? TEXT.searchPage.sendSearchBox.postalCodeLabel
                    : ''
                }
              />
            </div>
          </div> */}

          <div className="row pt-3">
            <div className="col-12 col-md-6 text-center text-lg-left">
              <p>{TEXT.searchPage.sendSearchBox.starsLabel1}</p>
            </div>
            <div className="col-12 col-md-6 text-center text-lg-left">
              <StarRating groupName="rating1" starValue={this.state.ratings.rating1} onStarChange={this.onStarChange} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 text-center text-lg-left">
              <p>{TEXT.searchPage.sendSearchBox.starsLabel2}</p>
            </div>
            <div className="col-12 col-md-6 text-center text-lg-left">
              <StarRating groupName="rating2" starValue={this.state.ratings.rating2} onStarChange={this.onStarChange} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 text-center text-lg-left">
              <p>{TEXT.searchPage.sendSearchBox.starsLabel3}</p>
            </div>
            <div className="col-12 col-md-6 text-center text-lg-left">
              <StarRating groupName="rating3" starValue={this.state.ratings.rating3} onStarChange={this.onStarChange} />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 text-center text-lg-left">
              <p>{TEXT.searchPage.sendSearchBox.starsLabel4}</p>
            </div>
            <div className="col-12 col-md-6 text-center text-lg-left">
              <StarRating groupName="rating4" starValue={this.state.ratings.rating4} onStarChange={this.onStarChange} />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            {TEXT.searchPage.sendSearchBox.cancelButton}
          </Button>
          <Button
            onClick={this.showResultButtonHandler}
            component={Link}
            to="/results"
            color="primary"
            // disabled={postalCodeError || this.props.postalCode.length !== 5}
          >
            {TEXT.searchPage.sendSearchBox.continueButton}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
SendSearchBox.defaultProps = {
  // TODO:
};
SendSearchBox.propTypes = {
  open: PropTypes.bool.isRequired,
  showResultButtonHandler: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
  // postalCode: PropTypes.string.isRequired,
  // liftUpStateToApp: PropTypes.func.isRequired
};
export default SendSearchBox;
