import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import debounce from 'lodash/debounce';
import flowRight from 'lodash/flowRight';
import ConfirmationModal from '../../generic_components/ConfirmationModal';
import ProjectResult from '../../projectArchive/ProjectResult';
import TEXT_GENERAL from '../../text';
import * as UTILS from '../../utils/utilFunctions';
import * as CONFIG from '../../config';
import * as CONSTANTS from '../../constants';
// import TEXT from './staticContent/text';import debounce from 'lodash/debounce';
// import { staticResources } from './staticContent/ProjectDetailsUpdatePopUpDropDowns';
import MainSearchField from '../../generic_components/MainSearchField';

import TEXT from '../staticContent/text';

class AddCandidateToProjectApplicationPopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      projectList: [],
      searchFieldValidationError: false,
      showConfirmationModal: false
    };
    this.mainSearchFieldChange = flowRight(
      debounce(this.mainSearchFieldChange, 200),
      e => e.persist() || e
    );
    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  getProjectListBySearchAPICall(searchTerm) {
    console.log('searchTerm', searchTerm);
    if (!this.isValidateSearchTerm(searchTerm) || !searchTerm) return;

    const requestBody = { searchTerm };
    const url = CONSTANTS.getProjectListBySearchTermURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        console.log(`API ${url} response `, responseBody);
        let projectList = responseBody;
        if (CONSTANTS.isLienert) projectList = projectList.filter(project => project.status === 'In Bearbeitung');
        this.setState({ projectList });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  mainSearchFieldChange = e => {
    if (e.target.value !== '') this.getProjectListBySearchAPICall(e.target.value);
  };

  transition(props) {
    return <Slide direction="up" mountOnEnter unmountOnExit {...props} />;
  }

  isValidateSearchTerm(searchTerm) {
    if (!UTILS.isSearchtermValid(searchTerm)) {
      this.setState({ searchFieldValidationError: true });
      return false;
    }
    if (this.state.searchFieldValidationError) this.setState({ searchFieldValidationError: false });
    return true;
  }

  selectProjectRowClick = row => {
    // console.log(row);
    this.setState({ showConfirmationModal: true, selectedProject: row });
  };

  renderSearch() {
    return (
      <div className="container">
        <MainSearchField
          placeholder="Bitte suchen Sie nach einem Auftrag"
          onChange={this.mainSearchFieldChange}
          error={this.state.searchFieldValidationError}
          helperText={this.state.searchFieldValidationError && TEXT.search.searchFieldErrorMessage}
          onExtendedSearchClick={() => console.log('extendedSearchClicked')}
        />
        <ProjectResult handleProjectSelected={this.selectProjectRowClick} projectList={this.state.projectList} />
      </div>
    );
  }

  renderDescriptionText() {
    return (
      <div className="userResetConfirmation-descriptionText">
        <p>{TEXT.addCandidateToProjectApplicationPopUp.description}</p>
      </div>
    );
  }

  renderContentText() {
    const projectInfos = this.state.selectedProject.slice();
    projectInfos.shift();
    return (
      <div className="userDeleteConfirmation-contentText">
        {projectInfos.map(field => (
          <p key={field}>{field}</p>
        ))}
      </div>
    );
  }

  render() {
    return (
      <Dialog fullScreen open={this.props.open} onClose={this.props.handleClose} TransitionComponent={this.transition}>
        <AppBar style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton color="inherit" onClick={this.props.handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              Kandidat für Auftrag beworben
            </Typography>
          </Toolbar>
        </AppBar>
        {this.renderSearch()}
        {this.state.showConfirmationModal && (
          <ConfirmationModal
            key="confirmation-addCandidateTo-Project"
            handleClose={() => this.setState({ showConfirmationModal: false })}
            open={this.state.showConfirmationModal}
            buttonPrimaryAction={() => {
              this.setState({ showConfirmationModal: false });
              this.props.handleAddCandidateToProject(this.state.selectedProject[0], this.state.selectedProject[6]);
            }}
            headlineText={TEXT_GENERAL.ConfirmationModal.headlineText}
            descriptionText={this.renderDescriptionText()}
            contentText={this.renderContentText()}
            buttonPrimaryText={TEXT_GENERAL.ConfirmationModal.buttonPrimaryText}
          />
        )}
      </Dialog>
    );
  }
}
AddCandidateToProjectApplicationPopUp.defaultProps = {};

AddCandidateToProjectApplicationPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAddCandidateToProject: PropTypes.func.isRequired
};

export default AddCandidateToProjectApplicationPopUp;
