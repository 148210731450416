import React, { useCallback } from 'react';
import PopUp from '../../components/PopUp';
import { useValidatedTextField } from '../../generic_components/ValidatedTextField';
import TEXT from '../../text';
import { createUserFromCounterPart } from '../api/companyArchiveAPI';
import CounterPartPopUpPropTypes from './propTypes';
import * as CONSTANTS from '../../constants';

const CreateCounterPartAccountPopUp = ({ counterPart, open, onClose, afterSubmit, handleSnackbarOpen }) => {
  const [firstNameComp, firstNameValidate, firstName] = useValidatedTextField({
    id: 'firstName',
    label: TEXT.companyArchive.createCounterPartAccountPopUp.inputFields.firstName,
    validator: 'isText',
    value: counterPart.firstName
  });
  const [lastNameComp, lastNameValidate, lastName] = useValidatedTextField({
    id: 'lastName',
    label: TEXT.companyArchive.createCounterPartAccountPopUp.inputFields.lastName,
    validator: 'isText',
    value: counterPart.lastName,
    required: true
  });
  const [phoneNumberMobileComp, phoneNumberMobileValidate, phoneNumberMobile] = useValidatedTextField({
    fullWidth: false,
    id: 'phoneNumberMobile',
    autoFocus: true,
    label: TEXT.companyArchive.createCounterPartAccountPopUp.inputFields.phoneNumberMobile,
    validator: 'isPhoneNumberFullyQualified',
    required: !CONSTANTS.isSenator,
    value: counterPart.contactInfo.phoneNumberMobile
  });
  const [mailAddressComp, mailAddressValidate, mailAddress] = useValidatedTextField({
    id: 'mailAddress',
    label: TEXT.companyArchive.createCounterPartAccountPopUp.inputFields.mailAddress,
    validator: 'isEmail',
    required: true,
    value: counterPart.contactInfo.mailAddress
  });

  const validateAll = useCallback(
    () =>
      // Validate all, add new validation functions to the dependency array also.
      phoneNumberMobileValidate() && firstNameValidate() && lastNameValidate() && mailAddressValidate(),
    [phoneNumberMobileValidate, firstNameValidate, lastNameValidate, mailAddressValidate]
  );

  const handleSubmit = useCallback(async () => {
    if (!validateAll()) {
      return;
    }
    try {
      const response = await createUserFromCounterPart(counterPart.id, mailAddress, firstName, lastName, phoneNumberMobile);
      if (!response.success) {
        // handle email already exists and other errors from backend
        handleSnackbarOpen(TEXT.companyArchive.createCounterPartAccountPopUp.createdNotificationDuplicatedEmail);
      } else {
        // handle success
        handleSnackbarOpen(TEXT.companyArchive.createCounterPartAccountPopUp.createdNotificationSuccess);
      }
    } catch (e) {
      console.log(e);
      handleSnackbarOpen(TEXT.companyArchive.createCounterPartAccountPopUp.createdNotificationFailure);
    }
    afterSubmit();
  }, [counterPart, phoneNumberMobile, firstName, lastName, mailAddress, afterSubmit, handleSnackbarOpen, validateAll]);

  return (
    <PopUp
      open={open}
      title={TEXT.companyArchive.createCounterPartAccountPopUp.headline}
      closeText={TEXT.companyArchive.buttons.cancel}
      saveText={TEXT.companyArchive.buttons.save}
      onClose={onClose}
      onSubmit={handleSubmit}
    >
      <div className="row">
        {[phoneNumberMobileComp, firstNameComp, lastNameComp, mailAddressComp].map(c => (
          <div key={c.key} className="col-6 mt-1 mb-1">
            {c}
          </div>
        ))}
        <div className="col-12 mt-5 mb-1">{TEXT.companyArchive.createCounterPartAccountPopUp.infoText}</div>
      </div>
    </PopUp>
  );
};

CreateCounterPartAccountPopUp.propTypes = CounterPartPopUpPropTypes;

export default CreateCounterPartAccountPopUp;
