import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import accountStatus from '../../general/staticContent/accountStatus';
import ConfirmationModal from '../../generic_components/ConfirmationModal';
import TEXT from '../../text';
import { setCounterPartUserStatus } from '../api/companyArchiveAPI';
import CreateCounterPartAccountPopUp from './CreateCounterPartAccountPopUp';
import UnlockProjectsCounterPartPopUp from './UnlockProjectsCounterPartPopUp';
import counterPartAccountActions from './actions';
import CounterPartPopUpPropTypes from './propTypes';

/**
 * Just a small wrapper component which selects the dialog to show.
 * @param {*} props
 * @returns
 */
const PopUpSelector = props => {
  switch (props.action) {
    case counterPartAccountActions.CREATE:
      return <CreateCounterPartAccountPopUp {...props} />;
    case counterPartAccountActions.ACTIVATE:
      return (
        <ConfirmationModal
          handleClose={props.onClose}
          open={props.open}
          buttonPrimaryAction={async () => {
            try {
              await setCounterPartUserStatus(props.counterPart.id, accountStatus.REINVITED);
              props.handleSnackbarOpen(TEXT.companyArchive.activateCounterPartAccountPopUp.notificationSuccess);
            } catch (e) {
              props.handleSnackbarOpen(TEXT.companyArchive.activateCounterPartAccountPopUp.notificationFailure);
            }
            props.afterSubmit();
          }}
          headlineText={TEXT.companyArchive.activateCounterPartAccountPopUp.headline}
          descriptionText={TEXT.companyArchive.activateCounterPartAccountPopUp.infoText}
          buttonPrimaryText={TEXT.companyArchive.buttons.yes}
        />
      );
    case counterPartAccountActions.DISABLE:
      return (
        <ConfirmationModal
          handleClose={props.onClose}
          open={props.open}
          buttonPrimaryAction={async () => {
            try {
              await setCounterPartUserStatus(props.counterPart.id, accountStatus.ACCOUNT_LOCKED);
              props.handleSnackbarOpen(TEXT.companyArchive.disableCounterPartAccountPopUp.notificationSuccess);
            } catch (e) {
              console.log(e);
              props.handleSnackbarOpen(TEXT.companyArchive.disableCounterPartAccountPopUp.notificationFailure);
            }

            props.afterSubmit();
          }}
          headlineText={TEXT.companyArchive.disableCounterPartAccountPopUp.headline}
          descriptionText={TEXT.companyArchive.disableCounterPartAccountPopUp.infoText}
          buttonPrimaryText={TEXT.companyArchive.buttons.yes}
        >
          <Box>
            <Typography variant="body1">{TEXT.companyArchive.activateCounterPartAccountPopUp.additionalInfo}</Typography>
          </Box>
        </ConfirmationModal>
      );
    case counterPartAccountActions.UNLOCK_PROJECTS:
      return <UnlockProjectsCounterPartPopUp {...props} />;
    case counterPartAccountActions.RESETPASSWORD:
      return (
        <ConfirmationModal
          handleClose={props.onClose}
          open={props.open}
          buttonPrimaryAction={async () => {
            await setCounterPartUserStatus(props.counterPart.id, accountStatus.REINVITED);
            props.afterSubmit();
          }}
          headlineText={TEXT.companyArchive.resetPasswordCounterPartAccount.headline}
          descriptionText={TEXT.companyArchive.resetPasswordCounterPartAccount.infoText}
          buttonPrimaryText={TEXT.companyArchive.buttons.yes}
          buttonSecondaryText={TEXT.companyArchive.buttons.cancel}
        />
      );
    default:
      return null;
  }
};

PopUpSelector.propTypes = {
  ...CounterPartPopUpPropTypes,
  action: PropTypes.string.isRequired
};

/**
 * Gives you a rendered popup and an function to open it.
 *
 * @example
 * ```
 * const {counterPartAccountPopUp, openCounterPartAccountPopUp} = useCounterPartAccountPopUp();
 *
 * return (
 *  <>
 *    <button onClick={() => openCounterPartAccountPopUp({
 *      action: CounterPartAccountActions.CREATE,
 *      counterPart,
 *      afterSubmit: () => alert("yay")
 *     })}>Open</button>
 *    { counterPartAccountPopUp }
 *  </>
 * )
 * ```
 */
export const useCounterPartAccountPopUp = props => {
  const [open, setOpen] = useState(false);

  /**
   * Contains the options provided with the open from the user.
   */
  const [options, setOptions] = useState(undefined);

  const handleClose = useCallback(() => {
    setOpen(false);
    setOptions(undefined);
  }, []);

  const handleSubmit = useCallback(() => {
    setOpen(false);

    if (!options) {
      return;
    }

    if (options.afterSubmit) {
      options.afterSubmit();
    }
    setOptions(undefined);
  }, [options]);

  /**
   * see {@link useCounterPartAccountPopUp} for the type
   */
  const handleOpen = useCallback(
    newOptions => {
      setOptions(newOptions);
      setOpen(true);
    },
    [handleClose, handleSubmit]
  );
  return {
    counterPartAccountPopUp: options ? (
      <PopUpSelector
        action={options.action}
        open={open}
        counterPart={options.counterPart}
        onClose={handleClose}
        projects={props.projects}
        handleSnackbarOpen={props.handleSnackbarOpen}
        afterSubmit={handleSubmit}
      />
    ) : null,
    openCounterPartAccountPopUp: handleOpen
  };
};

/**
 * See {@link useCounterPartAccountPopUp}.
 * This wraps the {@link useCounterPartAccountPopUp} so that it can be used with Class components.
 * It already renders the popup and injects the open function as prop.
 *
 * @example
 * ```
 * class CompanyArchive extends PureComponent {
 *  render() {
 *    return (
 *      <>
 *        <button onClick={() => this.props.openCounterPartAccountPopUp({
 *          action: CounterPartAccountActions.CREATE,
 *          counterPart,
 *          afterSubmit: () => alert("yay")
 *         })}>Open</button>
 *      </>
 *    )
 *  }
 * }
 *
 * export withCounterPartAccountPopUp(CompanyArchive);
 *
 * const {CounterPartAccountPopUp, openCounterPartAccountPopUp} = useCounterPartAccountPopUp();
 *
 * return (
 *  <>
 *    <button onClick={() => openCounterPartAccountPopUp({
 *      action: CounterPartAccountActions.CREATE,
 *      counterPart,
 *      afterSubmit: () => alert("yay")
 *     })}>Open</button>
 *    <CounterPartAccountPopUp />
 *  </>
 * )
 * ```
 */
export function withCounterPartAccountPopUp(Component) {
  // ...and returns another component...
  return function CounterPartAccountPopUpComponent(props) {
    const { counterPartAccountPopUp, openCounterPartAccountPopUp } = useCounterPartAccountPopUp(props);

    // ... and renders the wrapped component with the context theme!
    // Notice that we pass through any additional props as well
    return (
      <>
        {counterPartAccountPopUp || <></>}
        <Component {...props} openCounterPartAccountPopUp={openCounterPartAccountPopUp} />
      </>
    );
  };
}
