const synonyms = [
  ['abteilungsleiter', 'head of ', 'leader', 'abteilungsleiter/in', 'leader', 'leiter'],
  ['account', 'kunde', 'abteilung', 'vertrieb', 'sales'],
  ['account manage', 'key account manager', 'kam', 'vertriebsmitarbeiter', 'sales manager', 'sales engineer', 'key account management'],
  ['anlagenbau', 'plant engieering', 'verfahrenstechnik'],
  ['architekt', 'architect', 'architecture', 'bauingenieur', 'bauing', 'architektur'],
  ['assistenz', 'assistent', 'teamassistentin', 'sekretär', 'assi', 'assistent', 'assitent'],
  ['audit', 'prüfung', 'überwachung', 'revision ', 'qm'],
  ['fachberater', 'sales', 'area sales manager', 'vertrieb', 'verkauf', 'akquise', 'vertriebsmitarbeiter'],
  ['representative', 'gebietsverkaufsleiter', 'sales rep'],
  ['aussendienst', 'gebietsverkaufsleiter'],
  ['auto', 'car', 'kfz', 'pkw', 'fahrzeug'],
  ['autohaus', 'automobilhandelsgruppe', 'automobilhandel'],
  ['automation', 'automatisierung', 'automatisierungstechnik'],
  ['automatisierung', 'automation', 'automatisierung'],
  ['automatisierungstechnik', 'automatization', 'automation', 'automatisierung'],
  ['automobil', 'automotive', 'kfz', 'fahrzeug', 'fahrzeugtechnik', 'pkw'],
  ['automotive', 'kfz', 'fahrzeug', 'automobil', 'automobilbau', 'fahrzeugtechnik', 'automobilindustrie'],
  ['b2b', 'firmenkunden', 'handel'],
  ['bachelor', 'bakklaureaus', 'b.a.', 'bsc', 'b.eng'],
  ['bank', 'finance', 'finanz', 'finanzdienstleister'],
  ['bankkaufmann', 'bank officer', 'bank clerk'],
  ['bau', 'building', 'construction', 'architektur', 'bauwesen', 'modibau', 'tiefbau'],
  ['bauing', 'building', 'bauingenieur', 'bau-ing.'],
  ['bauingenieur', 'bauleiter', 'fachplaner', 'construction manager', 'civil engineer', 'bau ing.', 'baumeister', 'architet', 'bau-ing.'],
  ['bauleiter', 'bauingenieur', 'fachplaner', 'construction manager', 'engineer', 'bauleitung', 'leitung bau', 'bauleiterin', 'bauleit'],
  ['baustoffe', 'modul', 'bauteil', 'baumaterial', 'bauelement'],
  ['beckhoff', 'sps', 'simatic', 'step7', 'pcc', 'tia', 'win cc', 'automation'],
  ['berater', 'consultant'],
  ['betriebsleiter', 'niederlassungsleiter', 'betriebleitung', 'leiter des betriebes', 'filialleiter', 'branch manager'],
  ['betriebswirtschaft', 'bwl', 'business administration', 'betriebswirtschaftslehre', 'business economics'],
  ['bpm', 'business process management'],
  ['bsc', 'balanced scorecard'],
  ['buchhalt', 'accounting ', 'bilanzbuchhalt', 'rechnungswesen', 'buchhaltung', 'finanzbuchhalt'],
  ['bürokauffrau', 'bürokaufmann', 'office manager', 'bürokommunikation', 'office manager'],
  ['business analyst', 'ba'],
  ['bwl', 'betriebswirtschaft', 'business administration', 'betriebswirtschaftslehre'],
  ['c#', 'c-sharp', 'c++'],
  ['cad', 'auto cad', 'inventor', 'catia', 'ptc ', 'creo', 'solidedge', 'solidworks', 'konstruktion', 'konstrukteur', 'construction'],
  ['chemie', 'chemistry'],
  ['consultant', 'berater'],
  ['controll', 'kostenplanung', 'kontrolle', 'controller', 'controlling'],
  ['controller', 'kostenplanung', 'kontrolle', 'controlling'],
  ['controlling', 'kostenplanung', 'kontrolle', 'revision ', 'controller'],
  ['customer', 'kunde'],
  ['dipl ing', 'diplom', 'ingenieur', 'engineer'],
  ['einkauf', 'purchasing', 'purchase', 'procurement', 'commodity', 'commodity management'],
  ['elektrik', 'electronic', 'electric ', 'elektro', 'elektronik'],
  ['elektro', 'electronic', 'electric ', 'elektro', 'elektronik'],
  ['elektrokonstruktion', 'electronic', 'electric ', 'elektro', 'elektronik', 'eplan'],
  ['elektronik', 'electronic', 'electric ', 'elektro', 'elektronik'],
  ['elektrotechnik', 'electrical', 'electric ', 'elektro', 'elektronik'],
  ['embedded', 'eingebaut', 'eingelagert'],
  ['energiewirtschaft', 'energy industry', 'stromversorgung'],
  ['engineer', 'ingenieur', 'entwickler', 'engineer', 'diplom'],
  ['entgeltabrechnung', 'lohnabrechnung', 'gehaltsabrechnung', 'monatsabrechnung', 'lohnbuchhaltung', 'gehaltsbuchhaltung'],
  ['entwicklung', 'development'],
  ['eplan', 'elektronikentwicklung'],
  ['erp', 'abas', 'proalpha', 'sap', 'navision'],
  ['factoring', 'kauf von forderungen'],
  [
    'filialleiter',
    'marktleitung',
    'standortleiter',
    'leiter der filiale',
    'geschäftsstellenleiter',
    'niederlassungsleiter',
    'betriebsleiter'
  ],
  ['finance', 'finanz', 'finanzen', 'accounting'],
  ['finanz', 'banking', 'finance', 'accounting'],
  ['finanzdienstleist', 'banking', 'finance', 'versicherung', 'bank', 'insurance'],
  ['finanzen', 'banking', 'finance'],
  ['finanzierung', 'banking', 'finance'],
  ['firmenkunden', 'business client'],
  ['französisch', 'france'],
  ['gebäudetechnik', 'tga', 'technische gebäudeausrüstung', 'haustechnik'],
  ['gerüstbau', 'staging'],
  ['geschäftsführer', 'gf', 'geschäftsführung', 'geschäftsleitung', 'general manager ', 'managing director', 'ceo'],
  ['geschäftsleitung', 'gl', 'managing director'],
  ['gmp', 'good manufacturing practice'],
  ['handel', 'wholesale', 'einzelhandel', 'b2b'],
  ['haustechnik', 'tga', 'technische gebäudeausrüstung', 'haustechnik'],
  ['hgb', 'handelsgesetzbuch'],
  ['hkls', 'tga', 'technische gebäudeausrüstung', 'haustechnik'],
  ['hoai', 'bau', 'bauwesen', 'vob'],
  ['ifrs', 'international financial reporting standards'],
  ['immobilien', 'real estate'],
  ['industrie', 'industry', 'branche'],
  ['informatik', 'it', 'edv', 'informatiker'],
  ['ing', 'engineer'],
  ['ingenieur', 'engineer'],
  ['instandhaltung', 'reparatur', 'service', 'maintenance'],
  ['insurance', 'versicherung'],
  ['internes kontrollsystem', 'iks'],
  ['it', 'informatik', 'edv', 'informatiker', 'informatik'],
  ['java', 'software'],
  ['kalkulation', 'calculation'],
  ['kam', 'key account manager', 'sales', 'vertrieb', 'kosten & leistungsrechnung'],
  ['kaufmann', 'dealer', 'trader'],
  ['key account', 'kunde', 'key account manager', 'key account management', 'sales manager'],
  ['kfz', 'automobil ', 'automotive', 'automobilbau', 'fahrzeugtechnik'],
  ['konstrukt', 'entwicklung', 'konstruktion', 'konstrukteur', 'construction'],
  ['konstrukteur', 'entwicklung', 'konstruktion', 'konstrukteur', 'construction'],
  ['konstruktion', 'entwicklung', 'konstruktion', 'contruction', 'konstrukteur'],
  ['kostenplan', 'kalkulation', 'budget', 'kostenkalkulation'],
  ['kredit', 'credit'],
  ['kundenberat', 'consulting', 'consultant', 'kundenberater/in', 'kundenbetreuer/in'],
  ['kundenberater', 'consulting', 'kundenberater/in', 'kundenbetreuer/in'],
  ['kundenbetreu', 'consulting', 'kundensupport', 'kundenbetreuer/in', 'kundenberater/in'],
  ['kunststoff', 'plastic', 'kunststofftechnik', 'plastik', 'elastomer'],
  ['kunststofftechnik', 'plastic', 'kunststoff  ', 'plastik', 'gummi', 'elastomer'],
  ['lebensmittel', 'konsumgüter', 'consumer goods', 'fmcg'],
  ['linux', 'windows', 'mac os', 'software', 'betriebssystem'],
  ['lkw-fracht', 'spedition'],
  ['logistik', 'logistic', 'intralogistik', 'lagerwesen', 'lagerführung', 'lagerorganisation', 'spedition'],
  ['luftfracht', 'air freight'],
  ['marketing', 'social media', 'werbung'],
  ['marktleiter', 'filialleitung', 'standortleiter', 'geschäftsstellenleiter', 'marktleitung'],
  ['maschinenbau', 'mechanical engineering', 'mechanical engineer'],
  ['master', 'magister', 'magistra', 'msc', 'm.s.', 'msc'],
  ['mechatronik', 'mechatronics', 'serviceberater', 'kundenberater'],
  ['medizintechnik', 'medical'],
  ['meister', 'foreman', 'serviceberater'],
  ['messelogistik', 'fair logistics'],
  ['messtechnik', 'verfahrenstechnik', 'regeltechnik', 'msr/regelungstechnik'],
  ['metall', 'metal'],
  ['metallbau', 'metal structure'],
  ['msc', 'master'],
  ['nachrichtentechnik', 'informationstechnik', 'elektrotechnik', 'kommunikationstechnik'],
  ['net', 'netz'],
  ['netzwerk', 'network', 'kontakte'],
  ['niederlassungsleiter', 'standortleiter', 'filialleitung', 'betriebsleiter', 'filialleiter', 'betriebsleiter'],
  ['payroll', 'lohn- und gehaltsabrechnung'],
  ['personalleit', 'hr', 'human resources', 'personalleiter/in', 'hr manager', 'head of hr'],
  ['personalreferent', 'personalrecruiting', 'hr referent'],
  ['petrochemie', 'petrochemistry'],
  ['pharma', 'medizin', 'gesundheitsbranche'],
  ['print', 'druck'],
  ['produktion', 'production', 'fertigung', 'herstellung'],
  ['produktmanager', 'product manager'],
  ['projekt', 'project'],
  [
    'projektmanagement',
    'project management',
    'projektmanager',
    'projektleiter',
    'project manager',
    'projektleit',
    'project leader',
    'projekting',
    'project manager',
    'projectingenieur'
  ],
  ['prozess', 'process'],
  ['prozessindustrie', 'process industry'],
  ['qm', 'qmb ', 'qualitätsmanager', 'quality manager', 'qualitätsmanagement'],
  ['qualität', 'qmb ', 'quality'],
  ['quality', 'qmb'],
  ['raffinerie', 'rafinery'],
  ['rechnungswesen', 'buchhaltung', 'rechnungslegung', 'controlling', 'rewe', 'finanzwesen'],
  ['rohrleitungsbau', 'pipeline construction'],
  ['s7', 'step7', 'sps', 'simatic', 'plc', 'steuerung', 'automation', 'simantic'],
  ['sachbearbeit', 'mitarbeiter'],
  ['sale', 'vertrieb', 'verkauf'],
  ['sales', 'vertrieb', 'verkauf'],
  ['sap', 'erp'],
  ['security', 'sicherheit'],
  ['seefracht', 'air freight', 'schifffracht'],
  ['service', 'kundendienst'],
  ['serviceberater', 'kundendienstberater', 'kfz-meister', 'kfz-techniker'],
  ['sicherheit', 'security'],
  ['simatic', 's7', 'step7', 'sps', 'plc', 'steuerung', 'automation', 'simantic'],
  ['software', 'programm'],
  ['solidworks', 'catia', 'ptc creo', 'solidedge'],
  ['sondermaschinenbau', 'spercial purpose machinery manufacture'],
  ['sozialversicherung', 'national insurance'],
  ['sparkasse', 'commerzbank', 'deutsche bank', 'bank', 'öffentlich rechtiches kreditinstitut'],
  ['speditionskauffrau', 'speditionskaufmann', 'disponentin'],
  ['speditionskaufmann', 'forwarding merchant', 'speditionskauffrau', 'disponent'],
  ['sps', 'simatic', 'step7', 'plc', 'steuerung', 'automation', 'simantic'],
  ['sql', 'structured query language'],
  ['stahl', 'steel'],
  ['systemintegration', 'system integration'],
  ['teamleit', 'teamleader', 'teamleitung', 'teamleiterin'],
  ['teamleiter', 'teamleader', 'teamleitung', 'teamleiterin'],
  ['techniker', 'technician', 'technical'],
  ['technischer leiter', 'betriebleitung', 'produktionsleitung', 'technical leader'],
  ['tga', 'technische gebäudeausrüstung', 'haustechnik'],
  ['usa', 'amerika', 'united states'],
  ['verfahrenstechnik', 'process engineering'],
  ['verkauf', 'vertrieb', 'sales', 'handel'],
  ['vermögen', 'kapital', 'asset', 'property'],
  ['vermögensberat', 'kapitalberater'],
  ['versicherung', 'insurance', 'absicherung'],
  ['versorgungstechnik', 'tga'],
  ['vertrieb', 'sales', 'vertrieb', 'verkauf'],
  ['vertriebsingenieur', 'sales manager', 'sales engineer'],
  ['vertriebsmitarbeiter', 'sales consultant', 'vertriebsbeauftragter'],
  ['wartung', 'reparatur', 'service', 'maintenance'],
  ['werkstattleiter', 'leiter der werkstatt'],
  ['wertpapier', 'aktie', 'share'],
  ['wirtschaftsinformatik', 'business informatics'],
  ['wirtschaftsingenieur', 'industrial engineer'],
  ['zerspanung', 'chipping'],
  ['produktionsplanung', 'arbeitsvorbereitung', 'arbeitsvorbereiter'],
  ['durchlaufzeiten', 'planungsprozesse', 'fertigungsplanung', 'kapazität'],
  [
    'caterpillar',
    'bomag',
    'liebherr',
    'atlas',
    'wackler',
    'vermeer',
    'bobcat',
    'gruma',
    'fendt',
    'deere',
    'hanomag',
    'komatsu',
    'magirus',
    'deutz',
    'new holland',
    'claas'
  ],
  [
    'nutzfahrzeuge',
    'bagger',
    'gabelstapler',
    'kettenlader',
    'kran',
    'minibagger',
    'muldenkipper',
    'radlader',
    'flurförderfahrzeug',
    'baumaschinen'
  ],
  ['hochschule', 'universität'],
  ['zimmerer', 'zimmerei', 'zimmermann', 'schreiner', 'schreinerei', 'schreinermeister'],
  ['außendienst', 'aussendienst'],
  ['vertriebsmitarbeiter', 'sales representative', 'gebietsverkaufsleiter', 'sales rep', 'handelsvertreter'],
  ['handwerk', 'zimmerer', 'maurer', 'tischler', 'schreinerei', 'fliesenleger', 'gerüstbauer', 'maler', 'lackierer', 'elektriker'],
  ['softwareentwicklung', 'softwareentwickler', 'software engineer', 'softwareingenieur'],
  ['embedded systems', 'embedded system', 'embedded systeme'],
  ['multi-core-prozessor', 'multi-core-cpu']
];
export default synonyms;
