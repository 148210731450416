import * as CONSTANTS from '../../constants';
import { post } from '../../utils/apiUtils';

export function resetCandidateStatus(candidateId) {
  const payload = {
    candidateId,
    updateData: {
      isAvailable: true,
      statusType: 'available',
      statusExpirationDate: ''
    }
  };
  return post(CONSTANTS.updateCandidateURL, payload);
}

export function addCandidateToProjectApplication(projectId, candidateId) {
  const payload = {
    projectId,
    potentialCandidateData: {
      candidate: candidateId,
      applied: true,
      proposed: false
    }
  };
  return post(CONSTANTS.addPotentialCandidateURL, payload);
}

export function addCandidateToProject(projectId, candidateId) {
  const payload = {
    projectId,
    potentialCandidateData: {
      candidate: candidateId,
      applied: false,
      proposed: true
    }
  };
  return post(CONSTANTS.addPotentialCandidateURL, payload);
}

export function editCandidateStatus(candidateId, statusType, statusExpirationDate) {
  const payload = {
    candidateId,
    updateData: {
      isAvailable: false,
      statusType,
      statusExpirationDate
    }
  };
  return post(CONSTANTS.updateCandidateURL, payload);
}

export function deleteCandidateDocuments(candidateId) {
  const payload = {
    candidateId
  };
  return post(CONSTANTS.deleteCandidateDocumentsURL, payload);
}

export function deleteCandidate(candidateId, force) {
  const payload = {
    candidateId,
    force
  };
  return post(CONSTANTS.deleteCandidateURL, payload);
}

export function deleteCandidateImage(candidateId) {
  const payload = {
    candidateId
  };
  return post(CONSTANTS.deleteCandidateImageURL, payload);
}

export function updateCandidatePicture(candidateId, acceptedFiles) {
  const request = new FormData();
  acceptedFiles.forEach((file, index) => {
    request.append(`file-${index}`, file);
  });
  request.append('candidateId', candidateId);

  return post(CONSTANTS.updateCandidatePictureURL, request);
}

export function getCandidatePdfDocument(candidateId) {
  const payload = {
    candidateId
  };
  return post(CONSTANTS.getCandidatePdfDocumentURL, payload);
}

export function getCandidateImages(...candidateIDs) {
  const payload = {
    candidateIDs: candidateIDs.map(candidateId => ({ ID: candidateId }))
  };
  return post(CONSTANTS.getCandidateImage2URL, payload);
}

export function getDuplicateCandidateList(selectedIncomingCandidate) {
  const request = {
    firstName: selectedIncomingCandidate.firstName,
    lastName: selectedIncomingCandidate.lastName,
    mailAddress: selectedIncomingCandidate.mailAddress,
    dateOfBirth: selectedIncomingCandidate.dateOfBirth,
    externalProfile: selectedIncomingCandidate.externalProfile
  };

  if (selectedIncomingCandidate.dateOfBirth && selectedIncomingCandidate.dateOfBirth.length >= 10)
    request.dateOfBirth = selectedIncomingCandidate.dateOfBirth.substring(0, 10);

  return post(CONSTANTS.getDuplicateCandidateListURL, request);
}

export function getCandidateById(candidateId) {
  const requestBody = { candidateId };

  return post(CONSTANTS.getCandidateByIdURL, requestBody);
}

export function getProjectListByCandidateId(candidateId) {
  const payload = {
    candidateId
  };

  return post(CONSTANTS.getProjectListByCandidateIdURL, payload);
}

export function setCandidateNeedsValidation(candidateId, needsValidation) {
  const payload = {
    candidateId,
    updateData: {
      needsValidation
    }
  };
  return post(CONSTANTS.updateCandidateURL, payload);
}

export function getProjectListBySearchTerm(searchTerm) {
  const payload = {
    searchTerm
  };
  return post(CONSTANTS.getProjectListBySearchTermURL, payload);
}
export function getProjectListByLastUpdate() {
  const payload = { isLienert: CONSTANTS.isLienert };
  return post(CONSTANTS.getProjectListByLastUpdateURL, payload);
}

export function addCandidateMarkListToProject(projectId, candidateMarkListId) {
  const payload = {
    projectId,
    candidateMarkListId
  };
  return post(CONSTANTS.addCandidateMarkListToProjectURL, payload);
}

export function getProjectCandidateDossierList(candidateId) {
  const payload = {
    candidateId
  };

  return post(CONSTANTS.getProjectCandidateDossierListURL, payload);
}

export function getProjectCandidateDossier(candidateId, fileName) {
  const payload = {
    candidateId,
    fileName
  };

  return post(CONSTANTS.getProjectCandidateDossierURL, payload);
}

export function deleteProjectCandidateDossier(candidateId, fileName) {
  const payload = {
    candidateId,
    fileName
  };

  return post(CONSTANTS.deleteProjectCandidateDossierURL, payload);
}

export function setProjectCandidateDossierVisibleToCustomer(candidateId, fileName) {
  const payload = {
    candidateId,
    fileName
  };

  return post(CONSTANTS.setProjectCandidateDossierVisibleToCustomerURL, payload);
}

export function deleteCandidateDocument(candidateId, fileName, taggedDocumentId) {
  const payload = {
    candidateId,
    fileName,
    taggedDocumentId
  };

  return post(CONSTANTS.deleteCandidateDocumentURL, payload);
}

export function getCandidateDocumentUrlById(candidateId, documentId) {
  const payload = {
    candidateId,
    documentId,
    isTaggedDocument: true
  };

  return post(CONSTANTS.getCandidateDocumentByIdURL, payload);
}

export function updateDocumentTagById(candidateId, fileName, isTaggedDocument, tag) {
  const payload = {
    candidateId,
    fileName,
    isTaggedDocument,
    tag
  };

  return post(CONSTANTS.updateCandidateDocumentTagURL, payload);
}

export function removeCandidateFromProject(projectId, potentialCandidateId) {
  const payload = {
    projectId,
    potentialCandidateId,
    removeFrom: 'proposed'
  };

  return post(CONSTANTS.removePotentialCandidateURL, payload);
}

export function getProjectById(projectId) {
  const payload = {
    projectId
  };

  return post(CONSTANTS.getProjectByIdURL, payload);
}

/**
 * API function to get Candidates by searchQuery. Returns
 * This call comes from DetailSearch and is currently only used by a single user
 * @param {unknown[]} detailSearchFieldList
 * @return Promise<unknown>
 */
export async function exportCandidateListByDetailSearchAPICall(detailSearchFieldList) {
  const payload = { detailSearchFieldList };
  return post(CONSTANTS.exportCandidateListByDetailSearchTermsURL, payload);
}

/**
 *
 * @param {string} candidateId
 * @param {Omit<import('../../generic_components/LinkList/LinkListItem').LinkItem, 'id'>} linkData
 */
export async function updateCandidateLinks(candidateId, linkId, linkData) {
  const payload = {
    candidateId,
    subSchemaName: 'customLinks',
    subSchemaId: linkId,
    subSchemaUpdatedData: {
      name: linkData.name,
      url: linkData.url
    }
  };

  return post(CONSTANTS.updateCandidateSubSchemaURL, payload);
}
/**
 *
 * @param {string} candidateId
 * @param {Omit<import('../../generic_components/LinkList/LinkListItem').LinkItem, 'id'>} linkData
 */
export async function addCandidateLinks(candidateId, linkData) {
  const payload = {
    candidateId,
    subSchemaName: 'customLinks',
    subSchemaData: {
      name: linkData.name,
      url: linkData.url
    }
  };

  return post(CONSTANTS.addCandidateSubSchemaEntryURL, payload);
}
/**
 *
 * @param {string} candidateId
 * @param {string} linkData
 */
export async function removeCandidateLinks(candidateId, linkId) {
  const payload = {
    candidateId,
    subSchemaName: 'customLinks',
    subSchemaId: linkId
  };

  return post(CONSTANTS.removeCandidateSubSchemaEntryURL, payload);
}
