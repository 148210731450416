import $ from 'jquery';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material-ui components
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { TransitionSlideNoUnmount } from '../generic_components/TransitionSlide';
// custom components
import ProjectMatchingForm from '../projectArchive/ProjectMatchingForm';

// utils
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';

const systemPartner = CONSTANTS.partner;

const THEME = require('../theme')[systemPartner];

class CandidateMatchingSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      candidateDB: true
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  matchingSearchApiCall = updatedData => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    const url = CONSTANTS.getCandidateListByMatchingProfileURL;
    console.log(`API call request ${url}: `, updatedData);
    const matching = {
      ...updatedData,
      candidateDB: this.state.candidateDB
    };

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(matching),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        this.props.updateCandidateArchive(responseBody.candidateList);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        this.errorHandling(err, url);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  };

  renderAppBar() {
    return (
      <div>
        <AppBar style={THEME.appBar.candidates}>
          <Toolbar>
            <IconButton onClick={this.props.handleDrawerClose} aria-label="Close" color="inherit">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              Matching Suche
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ height: 64 }}>{/* Shim - Not visible */}</div>
      </div>
    );
  }

  renderCandidateDbDropdown() {
    return (
      <div className="col-4">
        <Typography variant="h6" gutterBottom>
          Kandidaten DB
        </Typography>
        <Select
          value={this.state.candidateDB}
          onChange={event => {
            this.setState({
              candidateDB: event.target.value
            });
          }}
          inputProps={{
            name: 'candidate-db-name',
            id: 'candidate-db-id'
          }}
        >
          <MenuItem value>Ja</MenuItem>
          <MenuItem value={false}>Nein</MenuItem>
        </Select>
      </div>
    );
  }

  render() {
    return (
      <Dialog
        fullScreen
        open={this.props.open}
        onClose={this.props.handleDrawerClose}
        keepMounted
        TransitionComponent={TransitionSlideNoUnmount}
      >
        {this.renderAppBar()}

        <div className="container">
          <Paper className="mt-3">
            <div className="container">
              <div className="row pt-3 pl-1">{this.renderCandidateDbDropdown()}</div>
            </div>
            <ProjectMatchingForm
              handleSnackbarOpen={this.props.handleSnackbarOpen}
              inMatchingSearch
              customSubmit={this.matchingSearchApiCall}
            />
          </Paper>
        </div>
      </Dialog>
    );
  }
}

CandidateMatchingSearch.propTypes = {
  open: PropTypes.bool.isRequired,

  handleSnackbarOpen: PropTypes.func.isRequired,
  handleDrawerClose: PropTypes.func.isRequired,
  updateCandidateArchive: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
};

export default CandidateMatchingSearch;
