import { Box, Checkbox, TextField, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Editor } from '@tinymce/tinymce-react';
import $ from 'jquery';
import { defaultTo } from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { Alert } from 'react-bootstrap';
import { Send } from '@material-ui/icons';
import ConfirmationModal from '../generic_components/ConfirmationModal';
import ImageCropDropContainer from '../generic_components/ImageCropDropContainer';
import ValidatedDropDown from '../generic_components/ValidatedDropDown';
import ValidatedTextField from '../generic_components/ValidatedTextField';
import * as UTILS from '../utils/utilFunctions';
import DefaultTextForEditor, { DefaultTextForSenator } from './DefaultTextForEditor';

import * as CONFIG from '../config';
import * as CONSTANTS from '../constants';
import DropContainer from '../generic_components/DropContainer';
import TEXT_GENERAL from '../text';
import { post } from '../utils/apiUtils';
import withUser from '../utils/withUser';
import './JobPosting.css';
import { updateProjectStatusStepstone } from './api/projectArchiveAPI';
import JobCategorySelect from './components/JobCategorySelect';
import JobLocations from './components/JobLocations';
import LabelEditor from './components/LabelEditor';
import PictureSelect from './components/PictureSelect';
import SalarySelect from './components/SalarySelect';
import { Branches, TypeOfContract, WorkingExperience, WorkingTime } from './staticContent/stepstoneCategories';
import { isArrayEqual, isObjectArrayEqual } from './utils/arrayEquals';
import WorkingTypeSelect from './components/WorkingTypeSelect';

const editorInitTemplate = {
  language_url: '/langs/de.js',
  branding: false,
  height: '300',
  fontsize_formats: '8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt',
  plugins: 'link code lists textcolor colorpicker textpattern imagetools',
  font_formats:
    'Arial=arial,helvetica,sans-serif;Georgia=georgia,serif;Times New Roman=times new roman,serif;Courier New=courier new,courier,monospace;Stencil=stencil,arial,sans-serif',
  toolbar: 'bold italic | bullist numlist | fontselect |  sizeselect fontsizeselect | forecolor | image'
};

class JobPosting extends PureComponent {
  constructor(props) {
    super(props);

    let defaultText = JSON.parse(JSON.stringify(DefaultTextForEditor.defaultText));
    defaultText = defaultText.replace('[§PROJECTNUMBER§]', props.selectedProject.projectNumber);
    if (props.selectedProject.partner) defaultText = defaultText.replace('[§EMAIL§]', this.getPlaceholderMail());
    if (props.selectedProject.editingUser)
      defaultText = defaultText.replace(
        '[§CONSULTANT§]',
        `${props.selectedProject.editingUser.firstName} ${props.selectedProject.editingUser.lastName}`
      );
    if (props.selectedProject.editingUser) defaultText = defaultText.replace('[§PHONENUMbER§]', '[Telefonnummer]');
    if (props.selectedProject.partner) defaultText = defaultText.replace('[§URL§]', this.getPlaceholderUrl());

    let defaultTextSenator = JSON.parse(JSON.stringify(DefaultTextForSenator.contactInfo));
    defaultTextSenator = defaultTextSenator.replace('[§PROJECTNUMBER§]', props.selectedProject.projectNumber);
    if (props.selectedProject.partner) defaultTextSenator = defaultTextSenator.replace('[§EMAIL§]', this.getPlaceholderMail());
    if (props.selectedProject.editingUser)
      defaultTextSenator = defaultTextSenator.replace(
        '[§CONSULTANT§]',
        `${props.selectedProject.editingUser.firstName} ${props.selectedProject.editingUser.lastName}`
      );
    if (props.selectedProject.editingUser) defaultTextSenator = defaultTextSenator.replace('[§PHONENUMbER§]', '[Telefonnummer]');
    if (props.selectedProject.partner) defaultTextSenator = defaultTextSenator.replace('[§URL§]', this.getPlaceholderUrl());

    this.selectedProject = JSON.parse(JSON.stringify(this.props.selectedProject));
    this.state = {
      isPdfExportButtonActive: true,
      isDocExportButtonActive: true,
      pictureErrorMessage: '',
      headerPictureErrorMessage: '',
      footerPictureErrorMessage: '',
      pictureLoading: false,
      headerPictureLoading: false,
      footerPictureLoading: false,
      documentErrorMessage: '',
      stepstoneValidationError: '',
      stepstoneQuotaError: '',
      documentsLoading: false,
      isDownloadButtonActive: false,
      isDeleteButtonActive: false,
      isConfirmationModalOpen: false,
      isConfirmationNotifyClientModalOpen: false,
      isStepstoneConfirmationModalOpen: false,
      isMoreLocations: !!this.selectedProject.jobPosting.stepstoneLocations.length || false,
      isButtonActive: false,
      isNotifyButtonActive: false,
      availableStepstoneQuota: 0,
      inputFields: {
        location: {
          value: this.selectedProject.jobPosting.location || '',
          validator: 'isText'
        },
        postalCode: {
          value: this.selectedProject.jobPosting.postalCode || '',
          validator: 'isText'
        },
        city: {
          value: this.selectedProject.jobPosting.city || '',
          validator: 'isText'
        },
        title: {
          value: this.selectedProject.jobPosting.title || '',
          validator: 'isText'
        },
        country: {
          value: this.selectedProject.jobPosting.country || ''
        },

        isOnline: {
          value: this.selectedProject.jobPosting.isOnline || false
        },
        isOnlineHrcg: {
          value: this.selectedProject.jobPosting.isOnlineHrcg || false
        },
        isOnlineJoin: {
          value: this.selectedProject.jobPosting.isOnlineJoin || false
        },
        isOnlineStepstone: {
          value: this.selectedProject.jobPosting.isOnlineStepstone || false
        },
        isOnlineLienertOnHrcg: {
          value: this.selectedProject.jobPosting.isOnlineLienertOnHrcg || false
        },
        isApplicantsLive: {
          value: this.selectedProject.jobPosting.isApplicantsLive || false
        },
        isOnlineNzz: {
          value: this.selectedProject.jobPosting.isOnlineNzz || false
        },
        isOnlineXing: {
          value: this.selectedProject.jobPosting.isOnlineXing || false
        },
        isOnlineLinkedIn: {
          value: this.selectedProject.jobPosting.isOnlineLinkedIn || false
        },
        isOnlineJobsCh: {
          value: this.selectedProject.jobPosting.isOnlineJobsCh || false
        },
        isInEnglish: {
          value: this.selectedProject.jobPosting.isInEnglish || false
        },
        lienertNotifiedJobPosting: {
          value: this.selectedProject.jobPosting.lienertNotifiedJobPosting || null
        },
        lienertReportJobPosting: {
          value: this.selectedProject.jobPosting.lienertReportJobPosting || null
        },
        companyNameInPosting: {
          value: this.selectedProject.jobPosting.companyNameInPosting || ''
        },
        partTimeTill: {
          value: defaultTo(this.selectedProject.jobPosting.partTimeTill, 100)
        },
        partTimeFrom: {
          value: defaultTo(this.selectedProject.jobPosting.partTimeFrom, 100)
        },
        description: {
          /** @type String */
          value: this.selectedProject.jobPosting.description || (!CONSTANTS.isLienert ? DefaultTextForSenator.description : defaultText)
        },
        tasks: {
          /** @type String */
          value: this.selectedProject.jobPosting.tasks || DefaultTextForSenator.tasks
        },
        profile: {
          /** @type String */
          value: this.selectedProject.jobPosting.profile || DefaultTextForSenator.profile
        },
        offer: {
          /** @type String */
          value: this.selectedProject.jobPosting.offer || DefaultTextForSenator.benefits
        },
        contactinfo: {
          /** @type String */
          value: this.selectedProject.jobPosting.contactinfo || defaultTextSenator
        },
        stepstoneLocations: {
          value: this.selectedProject.jobPosting.stepstoneLocations || []
        },

        salaryMax: {
          value: this.selectedProject.jobPosting.salaryMax || 0
        },

        salaryMin: {
          value: this.selectedProject.jobPosting.salaryMin || 0
        },
        keywords: {
          /** @type {String} */
          value: this.selectedProject.jobPosting.keywords || ''
        },
        stepstonePictures: {
          /** @type ImageData[] */
          value: undefined
        },
        industrySectors: {
          /** @type {Sektor[]} */
          value: this.selectedProject.jobPosting.industrySectors || []
        },

        branches: {
          /** @type {{label: string, id: number}} */
          value: {
            label: this.selectedProject.jobPosting.branches ? this.selectedProject.jobPosting.branches.label : '',
            id: this.selectedProject.jobPosting.branches ? this.selectedProject.jobPosting.branches.id : undefined
          }
        },
        typeOfContract: {
          /** @type {{label: string, id: number}} */
          value: {
            label: this.selectedProject.jobPosting.typeOfContract ? this.selectedProject.jobPosting.typeOfContract.label : '',
            id: this.selectedProject.jobPosting.typeOfContract ? this.selectedProject.jobPosting.typeOfContract.id : undefined
          }
        },
        workingTime: {
          /** @type {{label: string, id: number}[]} */
          value: this.selectedProject.jobPosting.workingTime || []
        },
        workingExperience: {
          /** @type {{label: string, id: number}} */
          value: {
            label: this.selectedProject.jobPosting.workingExperience ? this.selectedProject.jobPosting.workingExperience.label : '',
            id: this.selectedProject.jobPosting.workingExperience ? this.selectedProject.jobPosting.workingExperience.id : undefined
          }
        },
        videoURL: {
          value: this.selectedProject.jobPosting.videoURL || ''
        },
        companyWebsiteURL: {
          value: this.selectedProject.jobPosting.companyWebsiteURL || ''
        },
        applicationFormType: {
          /** @type {{label: string, id: number}} */
          value: this.selectedProject.jobPosting.applicationFormType || 0
        }
      },
      initialData: this.props.selectedProject,
      showDeleteProjectImageModal: false,
      deleteProjectImageType: ''
    };

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  /**
   *
   * @param {ImageData[]} newStepstonePictures
   */
  updateStepstonePictures = newStepstonePictures => {
    this.setState(prevState => ({
      ...prevState,
      isButtonActive: true,
      inputFields: {
        ...prevState.inputFields,
        stepstonePictures: {
          value: [...newStepstonePictures]
        }
      }
    }));
  };

  /**
   * This will build the stepstone picture array and check if pictures were selected
   * and then it will set the state
   */
  generateImageSelect = () => {
    /**
     * @type {string[]}
     */
    const partnerImages = CONSTANTS.stepstoneImages[CONSTANTS.partner];
    // If there is a partner with no images do nothing (eg. Lienert)
    if (!partnerImages) {
      return;
    }
    /**
     * @type {ImageData[]}
     */
    const imageList = [];
    // Then for partner images available
    partnerImages.forEach((imageURL, index) => {
      // Konstrukt the complete image url
      const completeImageURL = `${CONSTANTS.stepstoneImageBaseURL}${CONSTANTS.stepstonePathToImagesURL[CONSTANTS.partner]}${
        partnerImages[index]
      }`;
      // Then check if the url matches with one from database
      const hasFoundImageURL = this.props.selectedProject.jobPosting.stepstonePictures.find(url => completeImageURL === url);
      // Build the array and when img url was found then the img is (was) selected
      imageList.push({
        img: completeImageURL,
        selected: !!hasFoundImageURL,
        title: imageURL
      });
    });

    this.setStateStepstonePictures(imageList);
  };

  /**
   *  This will set the state for stepstonepicutres
   *
   * @param {ImageData[]} imageList
   */
  setStateStepstonePictures = imageList => {
    this.setState(prevState => ({
      ...prevState,
      inputFields: {
        ...prevState.inputFields,
        stepstonePictures: {
          value: imageList
        }
      }
    }));
  };

  /**
   * This will set the state for industrySector
   *
   * @param {Sektor[]} newArray
   */
  setIndustrySectors = newArray => {
    this.setState(prevState => ({
      isButtonActive: true,
      inputFields: {
        ...prevState.inputFields,
        industrySectors: {
          value: newArray
        }
      }
    }));
  };

  setStepstoneLocations = newArray => {
    this.setState(prevState => ({
      ...prevState,
      isButtonActive: true,
      inputFields: {
        ...prevState.inputFields,
        stepstoneLocations: {
          value: newArray
        }
      }
    }));
  };

  componentWillMount() {
    if (!(CONSTANTS.isSenator || CONSTANTS.isPersonalTotal)) {
      return;
    }
    this.generateImageSelect();
  }

  getPlaceholderMail() {
    switch (CONSTANTS.partner) {
      case 'senator':
        return `<a href="mailto:cv@senator-partners.de?subject=Bewerbung%20auf%20Kennziffer%20${
          this.props.selectedProject.projectNumber
        }">cv@senator-partners.de</a>`;
      case 'interim':
        return `<a href="mailto:cv@interim.senator-partners.de?subject=Bewerbung%20auf%20Kennziffer%20${
          this.props.selectedProject.projectNumber
        }">cv@interim.senator-partners.de</a>`;
      case 'personaltotal':
        return `<a href="mailto:cv@personal-total.de?subject=Bewerbung%20auf%20Kennziffer%20${
          this.props.selectedProject.projectNumber
        }">cv@personal-total.de</a>`;
      case 'matchflix':
        return `<a href="mailto:cv@match-flix.de?subject=Bewerbung%20auf%20Kennziffer%20${
          this.props.selectedProject.projectNumber
        }">cv@match-flix.de</a>`;
      default:
        return '[Email]';
    }
  }

  getPlaceholderUrl() {
    switch (CONSTANTS.partner) {
      case 'senator':
        return '<a href="https://senator-partners.de/?utm_source=Stellenanzeige&utm_medium=Homepage&utm_campaign=Jobs_Senator">www.senator-partners.de</a>';
      case 'personaltotal':
        return '<a href="https://www.personal-total.de">www.personal-total.de</a>';
      case 'matchflix':
        return '<a href="https://www.match-flix.de">www.match-flix.de</a>';
      default:
        return '[Webadresse]';
    }
  }

  onInputFieldChange = e => {
    this.setState({
      isButtonActive: true
    });

    let inputFieldValue = e.target.value;
    let inputFieldKey = e.target.id || e.target.name;
    if (e.target.type === 'checkbox') {
      inputFieldValue = e.target.checked;
      inputFieldKey = e.target.value;
    }
    if (inputFieldKey === 'mailAddress') inputFieldValue = inputFieldValue.toLowerCase().trim();

    // Case if field key is stepstone
    if (inputFieldKey === 'isOnlineStepstone') {
      if (inputFieldValue) {
        if (this.notEnoughStepstoneQuota()) {
          this.setState({
            stepstoneQuotaError: TEXT_GENERAL.jobPosting.errors.notEnoughQuota
          });
        } else {
          this.setState({
            stepstoneQuotaError: ''
          });
        }

        this.setState({
          isStepstoneConfirmationModalOpen: true
        });
        this.checkStepstoneQuota().then(quota => this.setState({ availableStepstoneQuota: quota }));
      } else {
        this.setState(prevState => ({
          inputFields: {
            ...prevState.inputFields,
            isOnlineStepstone: {
              value: false
            }
          }
        }));
      }
    } else {
      this.setState(prevState => ({
        inputFields: {
          ...prevState.inputFields,
          [inputFieldKey]: {
            ...prevState.inputFields[inputFieldKey],
            value: inputFieldValue
          }
        }
      }));
    }
  };

  sendClientNotification() {
    // Make API Call
    post(CONSTANTS.notifyClientJobPostingReleaseURL, { projectId: this.selectedProject.id })
      .then(() => {
        this.props.handleSnackbarOpen('Benachrichtigungen wurden erfolgreich den Kunden gesendet!');
        this.setState({
          isNotifyButtonActive: false
        });
      })
      .catch(() => {
        this.props.handleSnackbarOpen('Fehler: Es konnten keine Benachrichtigungen gesendet werden!');
      })
      .finally(() => {
        this.setState({
          isConfirmationNotifyClientModalOpen: false
        });
      });
  }

  async checkStepstoneQuota() {
    if (!this.props.user.partnerId) {
      return 0;
    }
    const res = await post(CONSTANTS.getPartnerByIdURL, { partnerId: this.props.user.partnerId }).catch(() => {
      this.handleSnackbarOpen('Konnte Kontingent nicht laden!');
      return 0;
    });

    return res.stepstoneQuota;
  }

  getUpdateData(key) {
    let result;

    const initialValue = this.state.initialData.jobPosting[key];

    let currentValue;
    // case inputField
    // TODO
    if (this.state.inputFields[key]) currentValue = this.state.inputFields[key].value;
    // case dropDowns
    else currentValue = this.state.inputFields[key].value;

    if (initialValue !== currentValue) {
      // handle case initialValue and currentValue both undefined, empty or null
      if (initialValue || currentValue) result = currentValue;
    }
    return result;
  }

  generateUpdateData = () => {
    const updatedData = {};
    Object.keys(this.state.inputFields).forEach(fieldKey => {
      if (
        fieldKey === 'stepstonePictures' ||
        fieldKey === 'stepstoneLocations' ||
        fieldKey === 'industrySectors' ||
        fieldKey === 'branches' ||
        fieldKey === 'typeOfContract' ||
        fieldKey === 'workingTime' ||
        fieldKey === 'workingExperience' ||
        fieldKey === 'isStepstoneOnline'
      ) {
        return;
      }
      const updatedValue = this.getUpdateData(fieldKey);
      if (updatedValue !== undefined) updatedData[fieldKey] = updatedValue;
    });
    // case stepstonePictures changed
    if ((CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) && this.state.inputFields.stepstonePictures.value.length > 0) {
      const filterdStateArray = this.state.inputFields.stepstonePictures.value.filter(obj => obj.selected);
      const stringArrayProps = filterdStateArray.map(obj => obj.img);

      if (!isArrayEqual(this.props.selectedProject.jobPosting.stepstonePictures, stringArrayProps)) {
        /**
         *
         * @type {*[]}
         */
        const resultImages = [];
        this.state.inputFields.stepstonePictures.value.forEach(
          /**
           *
           * @param {ImageData} imgObj
           */
          imgObj => {
            if (imgObj.selected) {
              resultImages.push(imgObj.img);
            }
          }
        );
        updatedData.stepstonePictures = resultImages;
      }
    }

    if ((CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) && this.state.isMoreLocations) {
      if (!isObjectArrayEqual(this.state.inputFields.stepstoneLocations.value, this.props.selectedProject.jobPosting.stepstoneLocations)) {
        updatedData.stepstoneLocations = this.state.inputFields.stepstoneLocations.value;
      }
    }

    if (
      (CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) &&
      this.state.isMoreLocations === false &&
      !!this.props.selectedProject.jobPosting.stepstoneLocations.length
    ) {
      updatedData.stepstoneLocations = [];
    }

    // case industrySector has changed
    if (!isObjectArrayEqual(this.state.inputFields.industrySectors.value, this.props.selectedProject.jobPosting.industrySectors)) {
      updatedData.industrySectors = this.state.inputFields.industrySectors.value;
    }

    if (
      !this.props.selectedProject.jobPosting.branches ||
      this.state.inputFields.branches.value.id !== this.props.selectedProject.jobPosting.branches.id
    ) {
      updatedData.branches = this.state.inputFields.branches.value;
    }
    if (
      !this.props.selectedProject.jobPosting.typeOfContract ||
      this.state.inputFields.typeOfContract.value.id !== this.props.selectedProject.jobPosting.typeOfContract.id
    ) {
      updatedData.typeOfContract = this.state.inputFields.typeOfContract.value;
    }
    if (!isObjectArrayEqual(this.state.inputFields.workingTime.value, this.props.selectedProject.jobPosting.workingTime)) {
      updatedData.workingTime = this.state.inputFields.workingTime.value;
    }
    if (
      !this.props.selectedProject.jobPosting.workingExperience ||
      this.state.inputFields.workingExperience.value.id !== this.props.selectedProject.jobPosting.workingExperience.id
    ) {
      updatedData.workingExperience = this.state.inputFields.workingExperience.value;
    }

    // only if there were changes
    if (Object.keys(updatedData).length > 0) {
      if (!this.state.initialData.jobPosting.datePosted && updatedData.isOnline) {
        const dateIn30Days = new Date();
        dateIn30Days.setDate(dateIn30Days.getDate() + 90);

        updatedData.datePosted = new Date();
        updatedData.validThroughDate = dateIn30Days;
      }
      updatedData.lastRefreshedDate = new Date();
    }

    return updatedData;
  };

  handleChangeIsMoreLocations = () => {
    this.setState(prevState => ({
      isButtonActive: !!prevState.isMoreLocations,
      isMoreLocations: !prevState.isMoreLocations
    }));
  };

  handleEditorChange = e => {
    this.setState({
      isButtonActive: true
    });
    this.setState(prevState => ({
      inputFields: {
        ...prevState.inputFields,
        description: {
          ...prevState.inputFields.description,
          value: e.target.getContent()
        }
      }
    }));
  };

  /**
   *
   * @param {React.ChangeEvent>} event
   */
  handleSalaryChange = (event, value) => {
    const salaryArray = [...value];

    this.setState(prevState => ({
      ...prevState,
      isButtonActive: true,
      inputFields: {
        ...prevState.inputFields,
        salaryMin: { value: salaryArray[0] },
        salaryMax: { value: salaryArray[1] }
      }
    }));
  };

  /**
   * This will handle change for the editors
   *
   * @param {React.ChangeEvent<Editor>} event
   */
  handleEditorFieldsChange = event => {
    const name = event.target.id;
    this.setState(prevState => ({
      isButtonActive: true,
      inputFields: {
        ...prevState.inputFields,
        [name]: {
          ...prevState.inputFields[name],
          value: event.target.getContent()
        }
      }
    }));
    this.validateStepstoneFields();
  };

  onDeleteButtonClick = () => {
    this.setState({
      isDeleteButtonActive: true,
      documentErrorMessage: '',
      documentsLoading: true
    });
    const url = CONSTANTS.deleteProjectDocumentURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = { projectId: this.selectedProject.id };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.props.liftUpStateToProjectArchive({
          selectedProject: {
            ...this.props.selectedProject,
            document: undefined
          }
        });
        this.setState({
          isDeleteButtonActive: false,
          documentsLoading: false
        });
      })
      .fail(err => {
        this.errorHandling(err, url, null);
      });
  };

  onDowloadButtonClick = () => {
    this.setState({
      isDownloadButtonActive: true
    });
    const url = CONSTANTS.downloadProjectDocumentURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = {
      projectId: this.selectedProject.id,
      fileName: this.selectedProject.document
    };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        const file = responseBody.contentUrl;
        const downloadElement = document.createElement('a');
        const fileName = this.selectedProject.document;

        downloadElement.href = file;
        downloadElement.download = fileName;

        downloadElement.style.display = 'none';
        document.body.appendChild(downloadElement);

        downloadElement.click();

        document.body.removeChild(downloadElement);
        this.setState({
          isDownloadButtonActive: false
        });
      })
      .fail(err => {
        this.errorHandling(err, url, null);
      });
  };

  onSaveButtonClick = onlyRefresh => {
    // Validation
    if (CONSTANTS.isLienert) {
      if (this.state.inputFields.isOnline || this.state.inputFields.isOnlineJobsCh || this.state.inputFields.isOnlineNzz) {
        if (!this.state.inputFields.title) {
          this.props.handleSnackbarOpen(TEXT_GENERAL.jobPosting.errors.titleRequired);
          return;
        }
      }
    }
    if (
      (CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) &&
      !this.validateStepstoneFields() &&
      this.state.inputFields.isOnlineStepstone.value
    ) {
      this.props.handleSnackbarOpen(TEXT_GENERAL.jobPosting.errors.allFieldsRequired);
      return;
    }

    if ((CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) && !this.validateJoinFields() && this.state.inputFields.isOnlineJoin.value) {
      this.props.handleSnackbarOpen(TEXT_GENERAL.jobPosting.errors.allFieldsRequired);
      return;
    }

    this.setState({
      isButtonActive: false
    });

    if (this.props.selectedProject.jobPosting.isOnlineStepstone !== this.state.inputFields.isOnlineStepstone.value) {
      this.handlePublishStepstone(this.state.inputFields.isOnlineStepstone.value).catch(() => {
        this.props.handleSnackbarOpen(TEXT_GENERAL.jobPosting.errors.notPublished);
      });
    }
    let updateData = {};
    if (onlyRefresh) {
      const dateIn30Days = new Date();
      dateIn30Days.setDate(dateIn30Days.getDate() + 90);

      updateData.validThroughDate = dateIn30Days;

      updateData.lastRefreshedDate = new Date();
    } else {
      updateData = this.generateUpdateData();
    }
    const requestBody = {
      projectId: this.selectedProject.id,
      updateData: {
        jobPosting: {
          ...updateData
        }
      }
    };

    const url = CONSTANTS.updateProjectURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    if (Object.keys(updateData).length > 0) {
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(requestBody),
        timeout: CONFIG.ajaxTimeout
      })
        .done(() => {
          this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.updateSuccess);

          this.setState(prevState => {
            const updatedJobPosting = prevState.initialData.jobPosting;
            Object.keys(updateData).forEach(key => {
              updatedJobPosting[key] = updateData[key];
            });
            return {
              isNotifyButtonActive: true,
              initialData: {
                ...prevState.initialData,
                jobPosting: updatedJobPosting
              }
            };
          });
        })
        .fail(err => {
          this.errorHandling(err, url, null);
        });
    } else {
      this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.noUpdate);
    }
  };

  onHeaderImageDrop = acceptedFiles => {
    this.onImageFileDrop(acceptedFiles, 'headerPicture');
  };

  onFooterImageDrop = acceptedFiles => {
    this.onImageFileDrop(acceptedFiles, 'footerPicture');
  };

  onImageDrop = acceptedFiles => {
    this.onImageFileDrop(acceptedFiles, 'picture');
  };

  onImageFileDrop = (acceptedFiles, pictureFieldName) => {
    const url = CONSTANTS.updateProjectPictureURL;
    const projectId = this.props.selectedProject.id;
    const newState = { pictureErrorMessage: '' };
    newState[`${pictureFieldName}Loading`] = true;
    this.setState(newState);

    const request = new FormData();

    request.append('type', pictureFieldName);

    acceptedFiles.forEach((file, index) => {
      request.append(`file-${index}`, file);
    });

    request.append('projectId', projectId);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      contentType: false,
      processData: false,
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: request
    })
      .done(responseBody => {
        const selectedProjectData = {
          ...this.props.selectedProject
        };

        selectedProjectData[pictureFieldName] = responseBody[pictureFieldName];

        this.props.liftUpStateToProjectArchive({
          selectedProject: selectedProjectData
        });
        const disableLoadingState = {};
        disableLoadingState[`${pictureFieldName}Loading`] = false;
        this.setState(disableLoadingState);
      })
      .fail(err => {
        const ignoreErrorCodes = [0, 413, 415];
        if (err.status === 413 || err.status === 0) {
          const errorState = {};
          errorState[`${pictureFieldName}Loading`] = false;
          errorState[`${pictureFieldName}ErrorMessage`] = TEXT_GENERAL.jobPosting.errors.fileToBig;

          this.setState(errorState);
        }
        if (err.status === 415) {
          const errorState = {};
          errorState[`${pictureFieldName}Loading`] = false;
          errorState[`${pictureFieldName}ErrorMessage`] = TEXT_GENERAL.jobPosting.errors.notSupportedFile;

          this.setState(errorState);
        }

        this.errorHandling(err, url, null, ignoreErrorCodes);
      });
  };

  handleDeleteProjectPictureAPICall = () => {
    const url = CONSTANTS.removeProjectPictureURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const payload = {
      projectId: this.props.selectedProject.id,
      type: this.state.deleteProjectImageType
    };

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        const updatedProject = {
          ...this.props.selectedProject
        };

        updatedProject[this.state.deleteProjectImageType] = '';

        this.props.liftUpStateToProjectArchive({
          selectedProject: updatedProject
        });
        this.setState({
          showDeleteProjectImageModal: false,
          deleteProjectImageType: ''
        });
        this.props.handleSnackbarOpen(TEXT_GENERAL.jobPosting.success.pictureDeleted);
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  handleSetStateStepstone = () => {
    this.setState(prevState => ({
      isStepstoneConfirmationModalOpen: false,
      inputFields: {
        ...prevState.inputFields,
        isOnlineStepstone: {
          value: true
        }
      }
    }));
  };

  handlePublishStepstone = async value => {
    const result = await updateProjectStatusStepstone(this.props.selectedProject.id, value).catch(() => {
      this.props.handleSnackbarOpen(TEXT_GENERAL.jobPosting.errors.notPublished);
    });
    if (!result || !result.success) {
      return;
    }
    if (value) {
      this.props.liftUpStateToApp({
        user: {
          ...this.props.user,
          stepstoneQuota: this.props.user.stepstoneQuota - 1
        }
      });
    }
    this.props.liftUpStateToProjectArchive({
      selectedProject: {
        ...this.props.selectedProject,
        jobPosting: {
          ...this.props.selectedProject.jobPosting,
          isOnlineStepstone: value
        }
      }
    });
    this.setState(prevState => ({
      isStepstoneConfirmationModalOpen: false,
      inputFields: {
        ...prevState.inputFields,
        isOnlineStepstone: { value }
      }
    }));
  };

  handleChangeEnglish = () => {
    this.setState(prevState => ({
      isButtonActive: true,
      inputFields: {
        ...prevState.inputFields,
        isInEnglish: { value: !prevState.inputFields.isInEnglish.value }
      }
    }));
  };

  onDocumentsDrop = acceptedFile => {
    const url = CONSTANTS.uploadProjectDocumentsURL;
    const projectId = this.props.selectedProject.id;
    this.setState({ documentErrorMessage: '', documentsLoading: true });

    const request = new FormData();
    request.append('file', acceptedFile[0]);

    request.append('projectId', projectId);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      contentType: false,
      processData: false,
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: request
    })
      .done(responseBody => {
        this.props.liftUpStateToProjectArchive({
          selectedProject: {
            ...this.props.selectedProject,
            document: responseBody.uploadedFile
          }
        });
        this.setState({
          documentsLoading: false
        });
      })
      .fail(err => {
        const ignoreErrorCodes = [0, 413, 415];
        if (err.status === 413 || err.status === 0) {
          this.setState({
            documentErrorMessage: TEXT_GENERAL.jobPosting.errors.fileToBig,
            documentsLoading: false
          });
        }
        if (err.status === 415) {
          this.setState({
            documentErrorMessage: TEXT_GENERAL.jobPosting.errors.notSupportedFile,
            documentsLoading: false
          });
        }
        this.errorHandling(err, url, null, ignoreErrorCodes);
      });
  };

  onPdfExportButtonClick() {
    this.setState({
      isPdfExportButtonActive: false
    });
    const url = CONSTANTS.getJobPostingPdfURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = {
      projectId: this.selectedProject.id
    };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        const file = `data:application/pdf;base64,${responseBody.buffer}`;

        const downloadElement = document.createElement('a');
        const fileName = 'Stellenanzeige.pdf';

        downloadElement.href = file;
        downloadElement.download = fileName;

        downloadElement.style.display = 'none';
        document.body.appendChild(downloadElement);

        downloadElement.click();

        document.body.removeChild(downloadElement);
        this.setState({
          isPdfExportButtonActive: true
        });
      })
      .fail(err => {
        this.errorHandling(err, url, null, [400, 500, 514]);
        this.setState({
          isPdfExportButtonActive: true
        });
      });
  }

  onDocExportButtonClick() {
    this.setState({
      isDocExportButtonActive: false
    });
    const url = CONSTANTS.getJobPostingHTMLURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = {
      projectId: this.selectedProject.id
    };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        const blob = new Blob(['\ufeff', responseBody.html], {
          type: 'application/msword'
        });
        const fileUrl = `data:application/vnd.ms-word;charset=utf-8,${encodeURIComponent(responseBody.html)}`;
        const fileName = 'Stellenanzeige.doc';
        const downloadElement = document.createElement('a');
        console.log(responseBody.html);
        document.body.appendChild(downloadElement);

        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          downloadElement.href = fileUrl;
          downloadElement.download = fileName;
          downloadElement.click();
        }

        document.body.removeChild(downloadElement);
        this.setState({
          isDocExportButtonActive: true
        });
      })
      .fail(err => {
        this.errorHandling(err, url, null, [400, 500, 514]);
        this.setState({
          isDocExportButtonActive: true
        });
      });
  }

  renderImage(pictureField) {
    if (this.props.selectedProject[pictureField]) {
      const image = UTILS.convertBufferToImage(this.props.selectedProject[pictureField]);
      return (
        <div className="row">
          <div className="col-12 text-center">
            <img src={image} alt="logo" className="img-fluid rounded" />
            <div className="pt-4">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.setState({
                    showDeleteProjectImageModal: true,
                    deleteProjectImageType: pictureField
                  });
                }}
              >
                <i className="material-icons">delete</i>&nbsp;Bild löschen
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-12 text-center">
          <CloudUploadIcon color="primary" className="mt-2" style={{ fontSize: '70px' }} />
        </div>
      </div>
    );
  }

  renderDocumentName() {
    if (this.props.selectedProject.document) {
      return (
        <div className="row">
          <div className="col-12 text-center">
            <p>{this.props.selectedProject.document} </p>
          </div>
          <div className="col-12 text-center">
            <Button onClick={this.onDowloadButtonClick} variant="contained" color="primary" disabled={this.state.isDownloadButtonActive}>
              <i className="material-icons pr-2">cloud_download</i>
              Download
            </Button>
          </div>
          <div className="col-12 text-center">
            <Button
              onClick={() => this.setState({ isConfirmationModalOpen: true })}
              variant="contained"
              color="primary"
              disabled={this.state.isDeleteButtonActive}
              className="mt-3"
            >
              <i className="material-icons pr-2">delete</i>
              Dokument löschen
            </Button>
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-12 text-center">
          <CloudUploadIcon color="primary" className="mt-2" style={{ fontSize: '70px' }} />
        </div>
      </div>
    );
  }

  /**
   *
   * @param {React.ChangeEvent<HTMLSelectElement>} event
   */
  handleBranchesChange = event => {
    const { value, id } = event.target;

    let foundObj;
    let stateToModify;

    switch (id) {
      case 'Branches':
        foundObj = Branches.find(branch => branch.label === value);
        stateToModify = 'branches';
        break;
      case 'TypeOfContract':
        foundObj = TypeOfContract.find(contract => contract.label === value);
        stateToModify = 'typeOfContract';
        break;
      case 'WorkingTime':
        foundObj = WorkingTime.find(workingTime => workingTime.label === value);
        stateToModify = 'workingTime';
        break;

      case 'WorkingExperience':
        foundObj = WorkingExperience.find(workingExperience => workingExperience.label === value);
        stateToModify = 'workingExperience';
        break;
      default:
        return;
    }
    this.setState(prevState => ({
      isButtonActive: true,
      inputFields: {
        ...prevState.inputFields,
        [stateToModify]: {
          value: {
            id: foundObj ? foundObj.id : undefined,
            label: foundObj ? foundObj.label : ''
          }
        }
      }
    }));
  };

  handleStepstoneWorkTypeSelect = workTypes => {
    this.setState(prevState => ({
      isButtonActive: true,
      inputFields: {
        ...prevState.inputFields,
        workingTime: {
          value: workTypes
        }
      }
    }));
  };

  validateJoinFields = () => {
    const isValid =
      this.state.inputFields &&
      !!this.state.inputFields.title.value &&
      !!this.state.inputFields.description.value &&
      !!this.state.inputFields.contactinfo.value &&
      !!this.state.inputFields.city.value;

    return isValid;
  };

  validateStepstoneFields = () => {
    const isValid =
      this.state.inputFields &&
      !!this.state.inputFields.title.value &&
      !!this.state.inputFields.description.value &&
      !!this.state.inputFields.contactinfo.value &&
      !!this.state.inputFields.city.value &&
      !!this.state.inputFields.offer.value &&
      !!this.state.inputFields.profile.value &&
      !!this.state.inputFields.tasks.value &&
      !!this.state.inputFields.postalCode.value &&
      !!this.state.inputFields.location.value &&
      this.state.inputFields.industrySectors.value.length > 0 &&
      !!this.state.inputFields.branches.value.id &&
      !!this.state.inputFields.typeOfContract.value.id &&
      !!this.state.inputFields.salaryMax.value &&
      !!this.state.inputFields.salaryMin.value &&
      this.state.inputFields.workingTime.value.length > 0 &&
      !!this.state.inputFields.workingExperience.value.id;
    if (!isValid) {
      this.setState({
        stepstoneValidationError: TEXT_GENERAL.jobPosting.errors.allStepstoneFieldsRequired
      });
    } else {
      this.setState({
        stepstoneValidationError: ''
      });
    }
    return isValid;
  };

  notEnoughStepstoneQuota = () => this.state.availableStepstoneQuota === 0;

  render() {
    return (
      <div className="container-fluid">
        <ConfirmationModal
          handleClose={() => this.setState({ isConfirmationModalOpen: false })}
          open={this.state.isConfirmationModalOpen}
          buttonPrimaryAction={() => {
            this.setState({ isConfirmationModalOpen: false });
            this.onDeleteButtonClick();
          }}
          headlineText={TEXT_GENERAL.jobPosting.modalText.deleteFile}
          buttonPrimaryText={TEXT_GENERAL.jobPosting.modalText.confirm}
          icon="warning"
        />
        <ConfirmationModal
          key="confirmation-deleteProjectImage"
          handleClose={() => this.setState({ showDeleteProjectImageModal: false, deleteProjectImageType: '' })}
          open={this.state.showDeleteProjectImageModal}
          buttonPrimaryAction={this.handleDeleteProjectPictureAPICall}
          headlineText={TEXT_GENERAL.jobPosting.modalText.deletePicture}
          icon="delete"
          descriptionText=""
        />
        <ConfirmationModal
          key="confirmation-publishStepstone"
          icon="warning"
          headlineText={TEXT_GENERAL.jobPosting.modalText.publishStepstone}
          descriptionText={
            <Box>
              <Typography variant="body1">{TEXT_GENERAL.jobPosting.stepStoneModalText.warning1}</Typography>
              <Typography variant="body1">{TEXT_GENERAL.jobPosting.stepStoneModalText.warning2}</Typography>
              <Typography variant="subtitle1">
                <b>{TEXT_GENERAL.jobPosting.stepStoneModalText.warning3}</b>
              </Typography>
              <Alert bsStyle="warning">Verfügbares Kontingent: {this.state.availableStepstoneQuota}</Alert>
            </Box>
          }
          buttonPrimaryAction={() => this.handleSetStateStepstone()}
          primaryButtonIsDisabled={this.notEnoughStepstoneQuota() || !this.validateStepstoneFields()}
          handleClose={() => this.setState({ isStepstoneConfirmationModalOpen: false })}
          buttonPrimaryDisabledTooltipText={`${this.state.stepstoneValidationError} ${this.state.stepstoneQuotaError}`}
          open={this.state.isStepstoneConfirmationModalOpen}
        />
        <ConfirmationModal
          key="notify-client"
          icon="warning"
          headlineText={TEXT_GENERAL.jobPosting.confirmationNotifyClient.headline}
          descriptionText={
            <Box>
              <Typography variant="body1">{TEXT_GENERAL.jobPosting.confirmationNotifyClient.warning1}</Typography>
            </Box>
          }
          buttonPrimaryAction={() => this.sendClientNotification()}
          handleClose={() => this.setState({ isConfirmationNotifyClientModalOpen: false })}
          open={this.state.isConfirmationNotifyClientModalOpen}
        />
        <Paper>
          <div className="row p-3">
            <div className="col">
              <p className="projectDetailLabel">{TEXT_GENERAL.jobPosting.published}</p>
              <p>
                {this.state.initialData.jobPosting.datePosted &&
                  UTILS.formatDateToDELocale(new Date(this.state.initialData.jobPosting.datePosted))}
              </p>
            </div>
            {(CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) && (
              <div className="col">
                <p className="projectDetailLabel">{TEXT_GENERAL.jobPosting.publishedStepstone}</p>
                <p>
                  {this.state.initialData.jobPosting.stepstoneDatePublished &&
                    UTILS.formatDateToDELocale(new Date(this.state.initialData.jobPosting.stepstoneDatePublished))}
                </p>
              </div>
            )}
            {CONSTANTS.isSenator || CONSTANTS.isPersonalTotal ? (
              <div className="col">
                <p className="projectDetailLabel">{TEXT_GENERAL.jobPosting.isReleasedByClient}:</p>
                <p>{this.state.initialData.jobPosting.isReleasedByClient ? TEXT_GENERAL.commonTerms.yes : TEXT_GENERAL.commonTerms.no}</p>
              </div>
            ) : null}
            <div className="col">
              <p className="projectDetailLabel">{TEXT_GENERAL.jobPosting.lastUpdated}</p>

              {this.state.initialData.jobPosting.lastRefreshedDate && (
                <p>{UTILS.formatDateToDELocale(new Date(this.state.initialData.jobPosting.lastRefreshedDate))}</p>
              )}
            </div>
            <div className="col">
              <p className="projectDetailLabel">{TEXT_GENERAL.jobPosting.validThrough}</p>
              <p>
                {this.state.initialData.jobPosting.validThroughDate &&
                  UTILS.formatDateToDELocale(new Date(this.state.initialData.jobPosting.validThroughDate))}
              </p>
            </div>
            {(CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) && (
              <div className="col">
                <p className="projectDetailLabel">{TEXT_GENERAL.jobPosting.validThroughStepstone}</p>
                <p>
                  {this.state.initialData.jobPosting.validThroughDateStepstone &&
                    UTILS.formatDateToDELocale(new Date(this.state.initialData.jobPosting.validThroughDateStepstone))}
                </p>
              </div>
            )}
            {CONSTANTS.isLienert && (
              <div className="col">
                <KeyboardDatePicker
                  emptyLabel=""
                  id="lienertNotifiedJobPosting"
                  key="lienertNotifiedJobPosting"
                  label="Stelle gemeldet:"
                  format="dd.MM.yyyy"
                  cancelLabel="Abbrechen"
                  value={this.state.inputFields.lienertNotifiedJobPosting.value}
                  onChange={date =>
                    this.setState(prevState => ({
                      isButtonActive: true,
                      inputFields: {
                        ...prevState.inputFields,
                        lienertNotifiedJobPosting: { value: date }
                      }
                    }))
                  }
                  animateYearScrolling={false}
                />
              </div>
            )}
            {CONSTANTS.isLienert && (
              <div className="col">
                <KeyboardDatePicker
                  emptyLabel=""
                  id="lienertReportJobPosting"
                  key="lienertReportJobPosting"
                  label="Rapport gemacht:"
                  format="dd.MM.yyyy"
                  cancelLabel="Abbrechen"
                  value={this.state.inputFields.lienertReportJobPosting.value}
                  onChange={date =>
                    this.setState(prevState => ({
                      isButtonActive: true,
                      inputFields: {
                        ...prevState.inputFields,
                        lienertReportJobPosting: { value: date }
                      }
                    }))
                  }
                  animateYearScrolling={false}
                />
              </div>
            )}
            {this.state.inputFields.isOnline.value &&
              new Date(this.state.initialData.jobPosting.validThroughDate) >= new Date() &&
              !CONSTANTS.isLienert && (
                <div className="col">
                  <p className="projectDetailLabel">Link zur Anzeige auf Homepage:</p>
                  <p>
                    <a
                      href={`https://jobs.${CONSTANTS.domain()}/${this.props.selectedProject.projectNumber}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      - Link -
                    </a>
                  </p>
                </div>
              )}
            <div className="col-12">
              <span style={{ paddingRight: '24px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!this.state.isPdfExportButtonActive}
                  onClick={() => {
                    this.onPdfExportButtonClick();
                  }}
                >
                  <i className="material-icons pr-2">file_copy</i>
                  PDF Export
                </Button>
                {CONSTANTS.isLienert && (
                  <Button
                    className="ml-3"
                    variant="contained"
                    color="primary"
                    disabled={!this.state.isDocExportButtonActive}
                    onClick={() => {
                      this.onDocExportButtonClick();
                    }}
                  >
                    <i className="material-icons pr-2">file_copy</i>
                    Doc Export
                  </Button>
                )}
              </span>
              {CONSTANTS.isLienert && (
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={this.onInputFieldChange}
                        value="isOnlineJobsCh"
                        disabled={!this.props.selectedProject.isEditable}
                        checked={this.state.inputFields.isOnlineJobsCh.value}
                        color="primary"
                      />
                    }
                    label="jobs.ch"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={this.onInputFieldChange}
                        value="isOnlineNzz"
                        disabled={!this.props.selectedProject.isEditable}
                        checked={this.state.inputFields.isOnlineNzz.value}
                        color="primary"
                      />
                    }
                    label="nzz.ch"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={this.onInputFieldChange}
                        value="isOnlineXing"
                        disabled={!this.props.selectedProject.isEditable}
                        checked={this.state.inputFields.isOnlineXing.value}
                        color="primary"
                      />
                    }
                    label="Xing"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={this.onInputFieldChange}
                        value="isOnlineLinkedIn"
                        disabled={!this.props.selectedProject.isEditable}
                        checked={this.state.inputFields.isOnlineLinkedIn.value}
                        color="primary"
                      />
                    }
                    label="LinkedIn"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={this.onInputFieldChange}
                        value="isOnlineLienertOnHrcg"
                        disabled={!this.props.selectedProject.isEditable}
                        checked={this.state.inputFields.isOnlineLienertOnHrcg.value}
                        color="primary"
                      />
                    }
                    label="HRCG"
                  />
                </>
              )}
              {CONSTANTS.isMatchflix && (
                <FormControlLabel
                  control={
                    <Switch
                      onChange={this.onInputFieldChange}
                      value="isApplicantsLive"
                      disabled={!this.props.selectedProject.isEditable}
                      checked={this.state.inputFields.isApplicantsLive.value}
                      color="primary"
                    />
                  }
                  label="applicants.live"
                />
              )}
              {CONSTANTS.isSenator && (
                <FormControlLabel
                  control={
                    <Switch
                      onChange={this.onInputFieldChange}
                      value="isOnlineHrcg"
                      disabled={!this.props.selectedProject.isEditable}
                      checked={this.state.inputFields.isOnlineHrcg.value}
                      color="primary"
                    />
                  }
                  label="HRCG exklusiv"
                />
              )}
              {CONSTANTS.isSenator || CONSTANTS.isPersonalTotal ? (
                <FormControlLabel
                  control={
                    <Switch
                      onChange={this.onInputFieldChange}
                      value="isOnlineJoin"
                      disabled={
                        !this.props.selectedProject.isEditable ||
                        !(this.props.user.additionalPermissions || []).some(p => p.key === 'canAccessMultiposting' && p.isSet)
                      }
                      checked={this.state.inputFields.isOnlineJoin.value}
                      color="primary"
                    />
                  }
                  label="Multiposting (Join)"
                />
              ) : (
                ''
              )}
              {(CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) && (
                <>
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={this.onInputFieldChange}
                        checked={this.state.inputFields.isOnlineStepstone.value}
                        value="isOnlineStepstone"
                        color="primary"
                      />
                    }
                    label="Stepstone Veröffentlichung"
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        onChange={this.handleChangeEnglish}
                        checked={this.state.inputFields.isInEnglish.value}
                        value="isInEnglish"
                        color="primary"
                      />
                    }
                    label="Anzeige in Englisch"
                  />
                </>
              )}
              <FormControlLabel
                control={
                  <Switch
                    onChange={this.onInputFieldChange}
                    value="isOnline"
                    disabled={!this.props.selectedProject.isEditable}
                    checked={this.state.inputFields.isOnline.value}
                    color="primary"
                  />
                }
                label="Online"
              />
              <FormControlLabel
                control={
                  <IconButton
                    disabled={!this.props.selectedProject.isEditable}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      this.onSaveButtonClick(true);
                    }}
                  >
                    <RefreshIcon />
                  </IconButton>
                }
                label="Refresh"
              />
            </div>
          </div>
          {CONSTANTS.isLienert && (
            <div className="row p-3">
              <div className="col-2">
                <DropContainer
                  dropzoneErrorMessage={this.state.pictureErrorMessage}
                  showLoadingIndicator={this.state.pictureLoading}
                  onDrop={this.onImageDrop}
                  actionDescription="Logo hier ablegen"
                  disabled={!this.props.selectedProject.isEditable}
                  cloudIcon={false}
                >
                  {this.renderImage('picture')}
                </DropContainer>
              </div>
              <div className="col-2">
                <ImageCropDropContainer
                  dropzoneErrorMessage={this.state.headerPictureErrorMessage}
                  showLoadingIndicator={this.state.headerPictureLoading}
                  onDrop={this.onHeaderImageDrop}
                  actionDescription="Header hier ablegen"
                  disabled={!this.props.selectedProject.isEditable}
                  cloudIcon={false}
                  aspect={900 / 250}
                  outputMaxWidth={2000}
                  outputMaxHeight={500}
                  minWidth={900}
                >
                  {this.renderImage('headerPicture')}
                </ImageCropDropContainer>
              </div>
              <div className="col-2">
                <ImageCropDropContainer
                  dropzoneErrorMessage={this.state.footerPictureErrorMessage}
                  showLoadingIndicator={this.state.footerPictureLoading}
                  onDrop={this.onFooterImageDrop}
                  actionDescription="Footer hier ablegen"
                  disabled={!this.props.selectedProject.isEditable}
                  cloudIcon={false}
                  aspect={900 / 506}
                  outputMaxWidth={2000}
                  outputMaxHeight={1100}
                  minWidth={900}
                >
                  {this.renderImage('footerPicture')}
                </ImageCropDropContainer>
              </div>
              <div className="col-6">
                <DropContainer
                  dropzoneErrorMessage={this.state.documentErrorMessage}
                  showLoadingIndicator={this.state.documentsLoading}
                  onDrop={this.onDocumentsDrop}
                  disabled={!this.props.selectedProject.isEditable}
                  actionDescription="Dokument hier ablegen"
                  cloudIcon={false}
                >
                  {this.renderDocumentName()}
                </DropContainer>
              </div>
            </div>
          )}
          <div className="row pl-3 pr-3">
            <div className="col-6 mt-1 mb-1">
              <ValidatedTextField
                fullWidth
                required={CONSTANTS.isSenator || CONSTANTS.isPersonalTotal}
                value={this.state.inputFields.location}
                id="location"
                onChange={this.onInputFieldChange}
                {...this.state.inputFields.location}
                label="Arbeitsort"
              />
            </div>
            <div className="col-6 mt-1 mb-1">
              <ValidatedTextField
                fullWidth
                required={CONSTANTS.isSenator || CONSTANTS.isPersonalTotal}
                value={this.state.inputFields.postalCode}
                id="postalCode"
                onChange={this.onInputFieldChange}
                {...this.state.inputFields.postalCode}
                label="Postleitzahl"
              />
            </div>
          </div>
          <div className="row pl-3 pr-3">
            <div className="col-6 mt-1 mb-1">
              {!CONSTANTS.isLienert ? (
                <ValidatedTextField
                  fullWidth
                  required={CONSTANTS.isSenator || CONSTANTS.isPersonalTotal}
                  value={this.state.inputFields.city}
                  id="city"
                  onChange={this.onInputFieldChange}
                  {...this.state.inputFields.city}
                  label="Stadt"
                />
              ) : (
                <ValidatedDropDown
                  id="city"
                  required={CONSTANTS.isSenator || CONSTANTS.isPersonalTotal}
                  value={this.state.inputFields.city}
                  content={['Luzern', 'Zug', 'Bern', 'Basel', 'Zürich', 'Aarau']}
                  label="Standort"
                  onChange={this.onInputFieldChange}
                  {...this.state.inputFields.city}
                />
              )}
            </div>
            <div className="col-6 mt-1 mb-1">
              <ValidatedDropDown
                id="country"
                required={CONSTANTS.isSenator || CONSTANTS.isPersonalTotal}
                value={this.state.inputFields.country}
                content={['Deutschland', 'Österreich', 'Schweiz']}
                label="Land"
                onChange={this.onInputFieldChange}
                {...this.state.inputFields.country}
              />
            </div>
          </div>
          <div className="row pl-3 pr-3">
            <div className="col-6 mt-1 mb-1 pb-3">
              <ValidatedTextField
                fullWidth
                required={CONSTANTS.isSenator || CONSTANTS.isPersonalTotal}
                value={this.state.inputFields.title}
                id="title"
                onChange={this.onInputFieldChange}
                {...this.state.inputFields.title}
                label="Anzeigentitel"
              />
            </div>
            <div className="col-6 mt-1 mb-1 pb-3">
              <ValidatedTextField
                fullWidth
                value={this.state.inputFields.companyNameInPosting}
                id="companyNameInPosting"
                onChange={this.onInputFieldChange}
                {...this.state.inputFields.companyNameInPosting}
                label="Angezeigter Unternehmensname"
              />
            </div>
          </div>
          {(CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) && (
            <>
              <div className="row pl-3 pr-3">
                <div className="mt-1 mb-1 p-3" style={{ display: 'flex', justifyContent: 'center' }}>
                  <FormControlLabel
                    control={<Checkbox onChange={this.handleChangeIsMoreLocations} checked={this.state.isMoreLocations} />}
                    label={TEXT_GENERAL.jobPosting.labels.moreLocations}
                  />
                </div>
              </div>
              {this.state.isMoreLocations && (
                <div className="row pl-3 pr-3">
                  <div className="col-12 mt-1 mb-1 p-3">
                    <JobLocations
                      handleSnackbarOpen={this.props.handleSnackbarOpen}
                      setLocations={this.setStepstoneLocations}
                      locations={this.state.inputFields.stepstoneLocations.value}
                    />
                  </div>
                </div>
              )}
              <div className="row pl-3 pr-3">
                <div className="mt-1 mb-1 p-3">
                  <Typography variant="subtitle1">{TEXT_GENERAL.jobPosting.labels.stepstoneCategories}</Typography>
                </div>
              </div>
              <div className="row pl-3 pr-3 d-flex align-items-end">
                <div className="col-3 mt-1 mb-1 pb-3">
                  <ValidatedDropDown
                    label={TEXT_GENERAL.jobPosting.labels.branches}
                    onChange={this.handleBranchesChange}
                    required={this.state.inputFields.isOnlineStepstone.value}
                    content={Branches}
                    id="Branches"
                    value={this.state.inputFields.branches.value.label}
                  />
                </div>
                <div className="col-3 mt-1 mb-1 pb-3">
                  <ValidatedDropDown
                    onChange={this.handleBranchesChange}
                    label={TEXT_GENERAL.jobPosting.labels.typeOfContract}
                    required={this.state.inputFields.isOnlineStepstone.value}
                    content={TypeOfContract}
                    id="TypeOfContract"
                    value={this.state.inputFields.typeOfContract.value.label}
                  />
                </div>
                <div className="col-3 mt-1 mb-1 pb-3">
                  <WorkingTypeSelect
                    currentList={this.state.inputFields.workingTime.value.map(wt => ({ id: wt.id, label: wt.label }))}
                    onSelect={this.handleStepstoneWorkTypeSelect}
                    label={TEXT_GENERAL.jobPosting.labels.workingTime}
                  />
                </div>
                <div className="col-3 mt-1 mb-1 pb-3">
                  <ValidatedDropDown
                    onChange={this.handleBranchesChange}
                    label={TEXT_GENERAL.jobPosting.labels.workingExperience}
                    required={this.state.inputFields.isOnlineStepstone.value}
                    content={WorkingExperience}
                    id="WorkingExperience"
                    value={this.state.inputFields.workingExperience.value.label}
                  />
                </div>
              </div>

              <div className="row pl-3 pr-3">
                <div className="col-12 mt-1 mb-1 pb-3">
                  <JobCategorySelect
                    industrySectors={this.state.inputFields.industrySectors.value}
                    setIndustrySectors={this.setIndustrySectors}
                    required={this.state.inputFields.isOnlineStepstone.value}
                    handleOpenSnackbar={this.props.handleSnackbarOpen}
                  />
                </div>
              </div>
              <div className="row pl-3 pr-3">
                <div className="col-3 mt-1 mb-1 pb-3">
                  <ValidatedTextField
                    id="keywords"
                    label={TEXT_GENERAL.jobPosting.labels.keywords}
                    fullWidth
                    onChange={this.onInputFieldChange}
                    value={this.state.inputFields.keywords.value}
                  />
                  <label className="label">{TEXT_GENERAL.jobPosting.labels.separateKeywords}</label>
                </div>
              </div>
              <div className="row pl-3 pr-3">
                <div className="col-12 mt-2 mb-1 px-3">
                  <SalarySelect
                    value={[this.state.inputFields.salaryMin.value, this.state.inputFields.salaryMax.value]}
                    onChange={this.handleSalaryChange}
                    id="salary"
                    label={TEXT_GENERAL.jobPosting.labels.salary}
                  />
                </div>
              </div>
            </>
          )}
          {CONSTANTS.isLienert && (
            <>
              <div className="row pl-3 pr-3">
                <div className="col-6 mt-1 mb-1 pb-3">
                  <ValidatedTextField
                    fullWidth
                    required={false}
                    value={this.state.inputFields.videoURL}
                    id="videoURL"
                    onChange={this.onInputFieldChange}
                    label={TEXT_GENERAL.jobPosting.labels.videoURL}
                    {...this.state.inputFields.videoURL}
                  />
                </div>
                <div className="col-6 mt-1 mb-1 pb-3">
                  <ValidatedTextField
                    fullWidth
                    required={false}
                    value={this.state.inputFields.companyWebsiteURL}
                    id="companyWebsiteURL"
                    onChange={this.onInputFieldChange}
                    label={TEXT_GENERAL.jobPosting.labels.companyWebsiteURL}
                    {...this.state.inputFields.companyWebsiteURL}
                  />
                </div>
              </div>
              <div className="row pl-3 pr-3">
                <div className="col-3 mt-1 mb-1">
                  <TextField
                    id="partTimeFrom"
                    value={this.state.inputFields.partTimeFrom}
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    label="Pensum von"
                    onChange={this.onInputFieldChange}
                    {...this.state.inputFields.partTimeFrom}
                  />
                </div>
                <div className="col-3 mt-1 mb-1">
                  <TextField
                    id="partTimeTill"
                    value={this.state.inputFields.partTimeTill}
                    label="Pensum bis"
                    type="number"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    onChange={this.onInputFieldChange}
                    {...this.state.inputFields.partTimeTill}
                  />
                </div>
                <div className="col-3 mt-1 mb-1">
                  <ValidatedDropDown
                    value={this.state.inputFields.applicationFormType.value}
                    id="applicationFormType"
                    label="Bewerbungsformular"
                    content={[
                      { value: '0', label: 'Keine Angabe' },
                      { value: '1', label: 'Minimum' },
                      { value: '2', label: 'Medium' },
                      { value: '3', label: 'Maximum' }
                    ]}
                    onChange={this.onInputFieldChange}
                  />
                </div>
              </div>
            </>
          )}

          {!(CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) && (
            <div className="row p-3">
              <div className="col-12">
                <Editor
                  initialValue={this.state.inputFields.description.value}
                  id="description"
                  init={{
                    language_url: '/langs/de.js',
                    branding: false,
                    height: CONSTANTS.isSenator ? '900' : '500',
                    fontsize_formats: '8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt',
                    plugins: 'link code lists textcolor colorpicker textpattern imagetools',
                    font_formats:
                      'Arial=arial,helvetica,sans-serif;Georgia=georgia,serif;Times New Roman=times new roman,serif;Courier New=courier new,courier,monospace;Stencil=stencil,arial,sans-serif;Optima nova LT Pro=Optima nova LT Pro',
                    toolbar: 'bold italic | bullist numlist | fontselect |  sizeselect fontsizeselect | forecolor | image',
                    // WYSIWIG is rendered in an IFrame, thats why we need to add the Fonts here aswell
                    content_style: `@font-face {
                      font-family: 'Optima nova LT Pro';
                      src: url('/fonts/OptimaNovaLTPro-Regular.otf') format('truetype');
                      font-weight: normal;
                      font-style: normal;
                      font-display: swap;
                    }
                    
                    @font-face {
                      font-family: 'Optima nova LT Pro';
                      src: url('/fonts/OptimaNovaLTPro-Bold.otf') format('truetype');
                      font-weight: bold;
                      font-style: normal;
                      font-display: swap;
                    }
                    
                    @font-face {
                      font-family: 'Optima nova LT Pro';
                      src: url('/fonts/OptimaNovaLTPro-Demi.otf') format('truetype');
                      font-weight: 600;
                      font-style: normal;
                      font-display: swap;
                    }                    
                    `
                  }}
                  onChange={this.handleEditorChange}
                />
              </div>
            </div>
          )}
          {(CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) && (
            <>
              <div className="row p-3">
                <div className="col-6">
                  <LabelEditor
                    isValid={!!this.state.inputFields.description.value}
                    label={this.state.inputFields.isInEnglish.value ? 'Company' : 'Das Unternehmen:'}
                    onChange={this.handleEditorFieldsChange}
                    id="description"
                    init={editorInitTemplate}
                    initialValue={this.state.inputFields.description.value}
                  />
                </div>
                <div className="col-6">
                  <LabelEditor
                    label={this.state.inputFields.isInEnglish.value ? 'The position' : 'Die Position:'}
                    isValid={!!this.state.inputFields.tasks.value}
                    onChange={this.handleEditorFieldsChange}
                    id="tasks"
                    init={editorInitTemplate}
                    initialValue={this.state.inputFields.tasks.value}
                  />
                </div>
              </div>
              <div className="row p-3">
                <div className="col-6">
                  <LabelEditor
                    label={this.state.inputFields.isInEnglish.value ? 'Your profile' : 'Ihr Profil:'}
                    isValid={!!this.state.inputFields.profile.value}
                    onChange={this.handleEditorFieldsChange}
                    id="profile"
                    init={editorInitTemplate}
                    initialValue={this.state.inputFields.profile.value}
                  />
                </div>
                <div className="col-6">
                  <LabelEditor
                    label={this.state.inputFields.isInEnglish.value ? 'Benefits' : 'Benefits:'}
                    isValid={!!this.state.inputFields.offer.value}
                    onChange={this.handleEditorFieldsChange}
                    id="offer"
                    init={editorInitTemplate}
                    initialValue={this.state.inputFields.offer.value}
                  />
                </div>
              </div>
              <div className="row p-3">
                <div className="col-6">
                  <LabelEditor
                    label={this.state.inputFields.isInEnglish.value ? 'Contact' : 'Kontakt:'}
                    onChange={this.handleEditorFieldsChange}
                    id="contactinfo"
                    init={editorInitTemplate}
                    isValid={!!this.state.inputFields.contactinfo.value}
                    initialValue={this.state.inputFields.contactinfo.value}
                  />
                </div>
                <div className="col-6">
                  <PictureSelect setImageDate={this.updateStepstonePictures} imageData={this.state.inputFields.stepstonePictures.value} />
                </div>
              </div>
            </>
          )}
          <div className="row flex p-3 text-right">
            <div className="col-12 mt-1 mb-1 pb-3">
              {(CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) && (
                <Button
                  onClick={() => this.setState({ isConfirmationNotifyClientModalOpen: true })}
                  disabled={!this.state.isNotifyButtonActive}
                  className="mr-1 ml-1"
                  variant="outlined"
                  color="secondary"
                >
                  <Send />
                  Kunden benachrichtigen
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this.onSaveButtonClick();
                }}
                disabled={!this.state.isButtonActive || !this.props.selectedProject.isEditable}
              >
                <SaveIcon />
                Speichern
              </Button>
            </div>
          </div>
        </Paper>
      </div>
    );
  }
}

JobPosting.defaultProps = {};
JobPosting.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired,
  selectedProject: PropTypes.object.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToProjectArchive: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};
export default withUser(JobPosting);
