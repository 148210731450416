module.exports = {
  staticResources: {
    dropDowns: {
      contactType: {
        textNone: 'Bitte wählen Sie',
        errorMessage: 'Bitte treffen Sie eine Auswahl',
        content: ['telefonisch', 'E-Mail', 'Brief', 'persönlich', 'Webkonferenz']
      },
      companyStatus: {
        textNone: 'Bitte wählen Sie',
        errorMessage: 'Bitte treffen Sie eine Auswahl',
        content: [
          'AP nicht erreicht',
          'derzeit kein Bedarf',
          'in Kontakt bleiben',
          'kein Feedback',
          'kein Interesse',
          'neuen AP empfohlen',
          'Kundentermin',
          'Termin bestätigt',
          'Termin in Anbahnung',
          'Termin vereinbart',
          'Termin verschoben',
          'Unterlagen zugeschickt',
          'Unterlagen zuschicken',
          'Verbindliches Angebot geschickt',
          'Wiedervorlage vereinbart'
        ]
      },
      matchFlixCompanyStatus: {
        textNone: 'Bitte wählen Sie',
        errorMessage: 'Bitte treffen Sie eine Auswahl',
        content: [
          'Unterlagen verschickt',
          'Unterlagen erhalten',
          'AP nicht erreicht',
          'neuer AP empfohlen',
          'derzeit kein Interesse',
          'derzeit kein Bedarf',
          'Wiedervorlagetermin vereinbart',
          'Live Demo vereinbart',
          'Live Demo bestätigt',
          'Live Demo abgehalten',
          'Vertrag erhalten',
          'Lizenz aktiviert (nach Zahlungseingang)',
          'Schulung vereinbart',
          'Schulung bestätigt',
          'Schulung abgehalten',
          'Interviewliste erhalten',
          'Formblatt „Information zur Position“ erhalten',
          'Stellenbeschreibung erhalten',
          'Dossier versendet',
          'Feedback zu Dossier',
          'erfolgreiche Besetzung',
          'Rechnung gestellt',
          'Rechnung bezahlt'
        ]
      },
      contactPerson: {
        textNone: 'Bitte wählen Sie',
        errorMessage: 'Bitte treffen Sie eine Auswahl'
      }
    }
  }
};
