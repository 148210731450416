import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material UI
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';
import $ from 'jquery';
import { Document, Page } from 'react-pdf';
import CircularProgress from '@material-ui/core/CircularProgress';
import './CandidateDocuments.css';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import ListItem from '@material-ui/core/ListItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Paper from '@material-ui/core/Paper';

// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FolderIcon from '@material-ui/icons/Folder';

import { TextField } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import withUser from '../utils/withUser';
import * as CONSTANTS from '../constants';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

/**
 * @readonly
 * @enum {string}
 */
const DOSSIER_TYPE = {
  isDossierLienertLongList: 'isDossierLienertLongList',
  isDossierLienertShortList: 'isDossierLienertShortList',
  isDossierLienertPreSelection: 'isDossierLienertPreSelection',
  isDossierLienertLateComer: 'isDossierLienertLateComer'
};

class CandidateDossier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      downloadDossier: !CONSTANTS.isLienert,
      currentDoc: '',
      currentDocFileType: '',
      taggedDocuments: [],
      isLoadingDocument: false,
      selectedEmployeeId: '',
      selectedProjectId: '',
      includeCandidatePage: false,
      includePersonalDataPage: false,
      includeFrontPage: !CONSTANTS.isLienert,
      consultantPosition: '',
      includeCandidateLinks: false
    };
  }

  componentDidMount() {
    this.prepareDocumentPanel();
  }

  handleToggle = value => {
    console.log('debug handleToggle value', value);
    this.setState(prevState => {
      const taggedDocuments = prevState.taggedDocuments;
      console.log('debug handleToggle taggedDocuments', taggedDocuments);
      taggedDocuments.forEach((taggedDocument, index) => {
        if (taggedDocument.id === value.id) {
          console.log('debug handleToggle taggedDocument', taggedDocument);
          taggedDocuments[index].checked = !value.checked;
        }
      });
      return { taggedDocuments };
    });
  };

  handleListItemClick = (documentId, isTaggedDocument) => {
    if (this.state.currentDocId !== documentId) {
      this.setState({ isLoadingDocument: true });
      this.getCandidateDocumentById(documentId, isTaggedDocument);
    }
  };

  swapPosition(isUp, index) {
    if (isUp && index > 0) {
      this.setState(prevState => {
        const tempArray = [...prevState.taggedDocuments];
        [tempArray[index], tempArray[index - 1]] = [tempArray[index - 1], tempArray[index]];

        return { taggedDocuments: tempArray };
      });
    }
    if (!isUp && index < this.state.taggedDocuments.length - 1) {
      this.setState(prevState => {
        const tempArray = [...prevState.taggedDocuments];
        [tempArray[index], tempArray[index + 1]] = [tempArray[index + 1], tempArray[index]];

        return { taggedDocuments: tempArray };
      });
    }
  }

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const docs = this.state.taggedDocuments;
    const newTaggedDocuments = this.reorder(docs, source.index, destination.index);
    this.setState({ taggedDocuments: newTaggedDocuments });
  };

  renderAvailableFiles() {
    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className="text-center">
          <List dense>
            <Droppable droppableId="droppable-list">
              {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {this.state.taggedDocuments.map((taggedDocument, index) => (
                    <Draggable draggableId={taggedDocument.id} index={index} key={taggedDocument.id}>
                      {providedDrag => (
                        <ListItem
                          button
                          selected={taggedDocument.id === this.state.currentDocId}
                          onClick={() => this.handleListItemClick(taggedDocument.id, true)}
                          ref={providedDrag.innerRef}
                          {...providedDrag.draggableProps}
                          {...providedDrag.dragHandleProps}
                        >
                          <ListItemAvatar>
                            <Avatar>{taggedDocument.documentType ? taggedDocument.documentType : <FolderIcon />}</Avatar>
                          </ListItemAvatar>
                          <ListItemText
                            primary={taggedDocument.documentName}
                            secondary={`Erstellungsdatum: ${new Date(taggedDocument.creationDate).toLocaleDateString('de-DE')}`}
                          />
                          <Checkbox onChange={() => this.handleToggle(taggedDocument)} checked={taggedDocument.checked} />
                        </ListItem>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </List>
        </div>
      </DragDropContext>
    );
  }

  documentLoadingInfo() {
    return <CircularProgress size={110} thickness={3} />;
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages, isLoadingDocument: false });
  };

  documentPreview() {
    if (this.state.currentDocFileType && this.state.currentDocFileType.toLowerCase() === 'pdf') {
      return (
        <Document
          file={this.state.currentDoc}
          loading={this.documentLoadingInfo()}
          noData={this.documentLoadingInfo()}
          onLoadSuccess={this.onDocumentLoad}
          error={this.documentLoadingInfo()}
          renderMode="canvas"
        >
          {this.displayPdfDocumentPages(this.state.numPages)}
        </Document>
      );
    }
    if (
      this.state.currentDocFileType &&
      (this.state.currentDocFileType.toLowerCase() === 'jpeg' ||
        this.state.currentDocFileType.toLowerCase() === 'jpg' ||
        this.state.currentDocFileType.toLowerCase() === 'png')
    ) {
      this.setState({ isLoadingDocument: false });
      return (
        <div className="text-center">
          <img src={this.state.currentDoc} alt="" className="img-fluid rounded" />
        </div>
      );
    }
    // this.setState({ isLoadingDocument: false });
    if (!this.state.currentDoc) {
      return <h4 className="m-3">{TEXT.resultPage.sideBar.documentsView.pdfErrorNoDocumentAvailable}</h4>;
    }
    return <h4 className="m-3">{TEXT.resultPage.sideBar.documentsView.fileOnlyAsDownload}</h4>;
  }

  noDocumentAvailableInfo() {
    this.setState({ isLoadingDocument: false });
    if (this.state.currentDoc === '') {
      return <h4 className="m-3">{TEXT.resultPage.sideBar.documentsView.pdfErrorNoDocumentAvailable}</h4>;
    }
    return <h4 className="m-3">{TEXT.resultPage.sideBar.documentsView.pdfErrorNoDocumentAvailable}</h4>;
  }

  /**
   * Sets the href for the button with the viewed file
   */
  downloadFile = dossier => {
    const element = document.createElement('a');
    const blob = UTILS.dataURIToBlob(dossier);
    element.setAttribute('href', URL.createObjectURL(blob));

    element.setAttribute(
      'download',

      `Kandidatendossier_${this.props.selectedCandidate.lastName}.pdf`
    );

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  handleDropDownChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onFrontPageSwitchChange = () => {
    this.setState(prevState => {
      const includeFrontPage = !prevState.includeFrontPage;

      return { includeFrontPage };
    });
  };

  onCandidateDescriptionSwitchChange = () => {
    this.setState(prevState => {
      const includeCandidatePage = !prevState.includeCandidatePage;

      return { includeCandidatePage };
    });
  };

  onCandidateLinksSwitchChange = () => {
    console.log(this.props.selectedCandidate);

    this.setState(prevState => {
      const includeCandidateLinks = !prevState.includeCandidateLinks;
      return { includeCandidateLinks };
    });
  };

  onPersonalDataSwitchChange = () => {
    this.setState(prevState => {
      const includePersonalDataPage = !prevState.includePersonalDataPage;

      return { includePersonalDataPage };
    });
  };

  onDossierDownloadSwitchChange = () => {
    this.setState(prevState => {
      const downloadDossier = !prevState.downloadDossier;

      return { downloadDossier };
    });
  };

  showDocContent() {
    if (this.state.taggedDocuments.length !== 0) {
      return (
        <div className="row col-12">
          <Paper style={{ width: '100%' }}>
            <div className="col-12">
              <div className="text-right p-2">{this.renderAvailableFiles()}</div>
            </div>
            <div className="col-12">
              <div className="row pt-3 pb-3">
                <div className="text-center col-md-4 mb-5">
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="berater-dropdown">
                      Berater
                    </InputLabel>
                    <Select
                      style={{ minWidth: '200px' }}
                      value={this.state.selectedEmployeeId}
                      onChange={this.handleDropDownChange}
                      inputProps={{
                        name: 'selectedEmployeeId',
                        id: 'berater-dropdown'
                      }}
                    >
                      {this.props.employees &&
                        this.props.employees.map(employee => (
                          <MenuItem value={employee.id}>
                            {employee.lastname} {employee.firstname} {employee.email}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
                {CONSTANTS.isLienert && (
                  <div className="text-center col-md-4 mb-5">
                    <FormControl fullWidth>
                      <TextField
                        className="MuiInputBase-input"
                        label="Berater Position"
                        style={{ minWidth: '200px', display: 'flex' }}
                        value={this.state.consultantPosition}
                        onChange={this.handleDropDownChange}
                        inputProps={{
                          name: 'consultantPosition',
                          id: 'consultantPosition-textField'
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </div>
                )}
                <div className="text-center col-md-4 mb-5">
                  <FormControl fullWidth>
                    <InputLabel shrink htmlFor="project-dropdown">
                      Auftrag
                    </InputLabel>
                    <Select
                      style={{ minWidth: '200px' }}
                      value={this.state.selectedProjectId}
                      onChange={this.handleDropDownChange}
                      inputProps={{
                        name: 'selectedProjectId',
                        id: 'project-dropdown'
                      }}
                    >
                      {this.props.selectedCandidate.projects &&
                        this.props.selectedCandidate.projects.map(project => {
                          if (project.proposed || (CONSTANTS.isLienert && project.applied)) {
                            return (
                              <MenuItem value={project.id}>
                                {project.projectNumber} | {project.jobTitle1}
                              </MenuItem>
                            );
                          }
                          return '';
                        })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="row pt-3 pb-3">
                {!CONSTANTS.isLienert && (
                  <div className="text-center align-self-center col-md-4">
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={this.onCandidateDescriptionSwitchChange}
                          value={this.state.includeCandidatePage}
                          checked={this.state.includeCandidatePage}
                          color="primary"
                        />
                      }
                      label="Kandidatensteckbrief"
                    />
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={!this.state.includeCandidatePage}
                          onChange={this.onCandidateLinksSwitchChange}
                          value={this.state.includeCandidateLinks}
                          checked={this.state.includeCandidateLinks}
                          color="primary"
                        />
                      }
                      label="Kandidatenlinks"
                    />
                  </div>
                )}
                {CONSTANTS.isLienert && (
                  <div className="text-center align-self-center col-md-4">
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={this.onPersonalDataSwitchChange}
                          value={this.state.includePersonalDataPage}
                          checked={this.state.includePersonalDataPage}
                          color="primary"
                        />
                      }
                      label="P-Blatt"
                    />
                  </div>
                )}

                <div className="text-center align-self-center col-md-4">
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={this.onDossierDownloadSwitchChange}
                        value={this.state.downloadDossier}
                        checked={this.state.downloadDossier}
                        color="primary"
                      />
                    }
                    label="Dossier nach Generierung herunterladen"
                  />
                </div>

                {CONSTANTS.isLienert && (
                  <div className="text-center align-self-end col-md-4">
                    <FormControlLabel
                      control={
                        <Switch
                          onChange={this.onFrontPageSwitchChange}
                          value={this.state.includeFrontPage}
                          checked={this.state.includeFrontPage}
                          color="primary"
                        />
                      }
                      label="Dossier-Deckblatt"
                    />
                  </div>
                )}

                <div className="text-center align-self-center col-md-12 row pt-5">
                  <div className="col-md-4">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={this.state.selectedEmployeeId === '' || this.state.selectedProjectId === ''}
                      onClick={this.getDossier}
                      download
                    >
                      {CONSTANTS.isLienert ? 'Dossier für Vorselektion (Berater)' : 'Dossier erstellen'}
                    </Button>
                  </div>
                  {CONSTANTS.isLienert && (
                    <>
                      <div className="col-md-4">
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={this.state.selectedEmployeeId === '' || this.state.selectedProjectId === ''}
                          onClick={() => this.getDossier(DOSSIER_TYPE.isDossierLienertLongList)}
                          download
                        >
                          Dossier für Longlist (Kundenportal)
                        </Button>
                      </div>
                      <div className="col-md-4">
                        <Button
                          variant="contained"
                          color="primary"
                          disabled={this.state.selectedEmployeeId === '' || this.state.selectedProjectId === ''}
                          onClick={() => this.getDossier(DOSSIER_TYPE.isDossierLienertShortList)}
                          download
                        >
                          Dossier für Shortlist (Kundenportal)
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="text-center col-12">
              {this.state.isLoadingDocument && <CircularProgress size={110} thickness={3} />}
              {this.documentPreview()}
            </div>
          </Paper>
        </div>
      );
    }
    return (
      <div>
        <div className="sideBarWrapper m-2 p-2">
          <div className="candidateArchive-emptyPage">
            {this.props.disabled
              ? 'Dokumente können erst nach Erstellung des Kandidaten hochgeladen werden.'
              : TEXT.resultPage.sideBar.documentsView.candidateHasNoTaggedDocs}
          </div>
        </div>
      </div>
    );
  }

  displayPdfDocumentPages(numOfPages) {
    const pageList = [];
    for (let i = 1; i <= numOfPages; i++) {
      pageList.push(<Page width={800} className="mx-auto" pageNumber={i} renderAnnotations={false} renderTextLayer={false} />);
    }
    return pageList;
  }

  getCandidateDocumentById = (documentId, isTaggedDocument) => {
    const url = CONSTANTS.getCandidateDocumentByIdURL;
    const documentRequest = {
      candidateId: this.props.selectedCandidate.id,
      documentId,
      isTaggedDocument
    };
    console.log('tagged doc', documentId, documentRequest);

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      headers: { 'x-auth': tokenFromLocalStorage },
      contentType: 'application/json',
      data: JSON.stringify(documentRequest)
    })
      .done(response => {
        let result;
        if (response.content) {
          if (response.contentType === 'pdf') {
            result = UTILS.convertBufferToPdf(response.content);
          } else if (response.contentType === 'jpeg' || response.contentType === 'jpg' || response.contentType === 'png') {
            result = UTILS.convertBufferToImageV2(response.content, response.contentType);
          } else {
            result = UTILS.convertBufferToDocx(response.content, response.contentType);
          }
        } else {
          result = response.contentUrl;
        }
        this.setState({
          currentDoc: result,
          currentDocId: documentId,
          currentDocFileType: response.contentType
        });

        return result;
      })
      .fail(err => {
        this.props.handleSnackbarOpen('Das Dokument konnte nicht geladen werden!');
        this.setState({ isLoadingDocument: false });
        console.log(err);
      });
  };

  sortTagDocuments(a, b) {
    const sortArray = ['IB', 'ED', 'CV', 'AZ', 'DZ', 'BB', 'ALL'];
    const aType = sortArray.indexOf(a.documentType);
    const bType = sortArray.indexOf(b.documentType);
    if (aType > bType) {
      return 1;
    }
    if (aType < bType) {
      return -1;
    }
    return 0;
  }

  sortTagDocumentsLienert(a, b) {
    const possibleDocumentTypes = ['IB', 'SE', 'BB', 'CV', 'AZ', 'DZ', 'ED', 'EDM', 'EDK', 'ALL'];

    const aType = possibleDocumentTypes.indexOf(a.documentType);
    const bType = possibleDocumentTypes.indexOf(b.documentType);
    if (aType < 0 && bType > -1) {
      return 1;
    }
    if (aType > -1 && bType < 0) {
      return -1;
    }
    if (aType > bType) {
      return 1;
    }
    if (aType < bType) {
      return -1;
    }
    if (aType === bType) {
      if (a.creationDate > b.creationDate) {
        return -1;
      }
      if (a.creationDate < b.creationDate) {
        return 1;
      }
    }
    return 0;
  }

  getDossier = (dossierType = '') => {
    const tempArray = this.state.taggedDocuments.filter(tagdocument => tagdocument.checked === true);
    this.props.liftUpStateToApp({
      showLoadingIndicatorGlobal: true,
      showLoadingIndicatorGlobalText: 'Dossier wird erstellt'
    });

    const url = CONSTANTS.getCandidateDossierURL;
    const documentRequest = {
      candidateId: this.props.selectedCandidate.id,
      selectedProjectId: this.state.selectedProjectId,
      selectedEmployeeId: this.state.selectedEmployeeId,
      arrayOfDocuments: tempArray,
      includeCandidatePage: this.state.includeCandidatePage,
      includePersonalDataPage: this.state.includePersonalDataPage,
      consultantPosition: this.state.consultantPosition,
      includeFrontPage: this.state.includeFrontPage,
      isDossierLienertShortList: dossierType === DOSSIER_TYPE.isDossierLienertShortList,
      isDossierLienertLongList: dossierType === DOSSIER_TYPE.isDossierLienertLongList,
      isDossierLienertLateComer: dossierType === DOSSIER_TYPE.isDossierLienertLateComer,
      includeCandidateLinks: this.state.includeCandidateLinks
    };

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      headers: { 'x-auth': tokenFromLocalStorage },
      contentType: 'application/json',
      data: JSON.stringify(documentRequest)
    })
      .done(response => {
        console.log('response', response);
        const result = UTILS.convertBufferToPdf(response.dossier);
        this.props.liftUpStateToApp({
          showLoadingIndicatorGlobal: false,
          showLoadingIndicatorGlobalText: ''
        });
        this.props.handleSnackbarOpen('Erfolgreich Erstellt');
        if (this.state.downloadDossier) {
          this.downloadFile(result);
        }
      })
      .fail(err => {
        console.log(err);
        this.props.liftUpStateToApp({
          showLoadingIndicatorGlobal: false,
          showLoadingIndicatorGlobalText: ''
        });
      });
  };

  prepareDocumentPanel = () => {
    if (this.props.selectedCandidate.taggedDocuments && this.props.selectedCandidate.taggedDocuments.length > 0) {
      const taggedDocuments = JSON.parse(JSON.stringify(this.props.selectedCandidate.taggedDocuments));
      taggedDocuments.sort(CONSTANTS.isLienert ? this.sortTagDocumentsLienert : this.sortTagDocuments);
      this.setState({
        currentDocId: '',
        currentDoc: '',
        taggedDocuments
      });
    }
  };

  render() {
    return this.showDocContent();
  }
}
CandidateDossier.defaultProps = {
  selectedCandidate: undefined,

  disabled: false
};
CandidateDossier.propTypes = {
  selectedCandidate: PropTypes.object,
  handleSnackbarOpen: PropTypes.func.isRequired,

  employees: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  liftUpStateToApp: PropTypes.func.isRequired
};

export default withUser(CandidateDossier);
