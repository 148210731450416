import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, TablePagination } from '@material-ui/core';

import FileList from '../generic_components/file_util_components/FileList';
import { getCandidateDocumentUrlById } from '../candidateArchive/api/candidateArchiveAPI';

import { downloadBlobFile } from '../utils/utilFunctions';

export default function SemanticSearchDocuments({ candidateId, documents }) {
  const [pageNumber, setPageNumber] = React.useState(0);
  const [numberOfRows, setNumberOfRows] = React.useState(localStorage.getItem('semanticSearchDocumentsNumberOfRows') || 5);

  function getDocumentUrl(fileName) {
    console.log('fileName', fileName);
    const { id } = documents.find(entry => entry.documentName === fileName);
    return getCandidateDocumentUrlById(candidateId, id)
      .then(responseBody => responseBody.contentUrl)
      .catch(err => console.error(err));
  }

  function getDocumentBlob(fileName) {
    const { id } = documents.find(entry => entry.documentName === fileName);
    return getCandidateDocumentUrlById(candidateId, id)
      .then(responseBody => downloadBlobFile(responseBody.contentUrl))
      .catch(err => console.error(err));
  }

  function handleChangeRowsPerPage(rowsPerPage) {
    setPageNumber(0);
    localStorage.setItem('semanticSearchDocumentsNumberOfRows', rowsPerPage);
    setNumberOfRows(rowsPerPage)
  }

  return (
    <>
      {documents.length > 0 ? (
        <>
          <FileList
            fileNameList={documents.slice(pageNumber * numberOfRows, (pageNumber + 1) * numberOfRows).map(entry => ({
              name: entry.documentName,
              secondaryText: `Erstellungsdatum: ${new Date(entry.creationDate).toLocaleDateString('de-DE')}`,
              letterAvatar: entry.documentType,
              entry
            }))}
            onResolveFileUrl={getDocumentUrl}
            onDownload={getDocumentBlob}
          />
          <TablePagination
            component="nav"
            count={documents.length}
            rowsPerPage={numberOfRows}
            rowsPerPageOptions={[5, 10, 20]}
            labelRowsPerPage="Dokumente pro Seite"
            page={pageNumber}
            onChangePage={(_, page) => setPageNumber(page)}
            onChangeRowsPerPage={event => {
              handleChangeRowsPerPage(event.target.value)
            }}
            labelDisplayedRows={({ from, to, count }) => `${from} bis ${to} aus ${count}`}
          />
        </>
      ) : (
        <Box mt={3}>
          <Typography variant="h6" component="div" align="center" color="textSecondary">
            Keine Dokumente vorhanden
          </Typography>
        </Box>
      )}
    </>
  );
}

SemanticSearchDocuments.propTypes = {
  candidateId: PropTypes.string.isRequired,
  documents: PropTypes.array.isRequired
};
