/**
 *
 * @type {Sektor[]}
 */
export const Branches = [
  {
    id: 24000,
    label: 'Agentur, Werbung, Marketing & PR'
  },
  {
    id: 19001,
    label: 'Banken'
  },
  {
    id: 14001,
    label: 'Baugewerbe & -industrie'
  },
  {
    id: 2000,
    label: 'Bergbau'
  },
  {
    id: 28000,
    label: 'Bildung & Training'
  },
  {
    id: 7000,
    label: 'Chemie- & Erdölverarbeitende Industrie'
  },
  {
    id: 12002,
    label: 'Druck-, Papier & Verpackungsindustrie'
  },
  {
    id: 12005,
    label: 'Elektrotechnik, Feinmechanik & Optik'
  },
  {
    id: 13000,
    label: 'Energie-, Wasserversorgung & Entsorgung'
  },
  {
    id: 10000,
    label: 'Fahrzeugbau & -zulieferer'
  },
  {
    id: 19002,
    label: 'Finanzdienstleister'
  },
  {
    id: 30000,
    label: 'Freizeit, Touristik, Kultur & Sport'
  },
  {
    id: 29000,
    label: 'Gesundheit & soziale Dienste'
  },
  {
    id: 12003,
    label: 'Glas-, Keramik-Herstellung & -verarbeitung'
  },
  {
    id: 15000,
    label: 'Groß- & Einzelhandel'
  },
  {
    id: 14002,
    label: 'Handwerk'
  },
  {
    id: 12001,
    label: 'Holz- & Möbelindustrie'
  },
  {
    id: 16000,
    label: 'Hotel, Gastronomie & Catering'
  },
  {
    id: 20000,
    label: 'Immobilien'
  },
  {
    id: 21000,
    label: 'IT & Internet'
  },
  {
    id: 11000,
    label: 'Konsumgüter & Gebrauchsgüter'
  },
  {
    id: 1000,
    label: 'Land-, Forst-, Fischwirtschaft & Gartenbau'
  },
  {
    id: 10002,
    label: 'Luft- & Raumfahrt'
  },
  {
    id: 12004,
    label: 'Maschinen- & Anlagenbau'
  },
  {
    id: 6000,
    label: 'Medien (Film, Funk, TV, Verlage)'
  },
  {
    id: 32000,
    label: 'Medizintechnik'
  },
  {
    id: 9000,
    label: 'Metallindustrie'
  },
  {
    id: 3000,
    label: 'Nahrungs- & Genussmittel'
  },
  {
    id: 27000,
    label: 'Öffentlicher Dienst & Verbände'
  },
  {
    id: 25000,
    label: 'Personaldienstleistungen'
  },
  {
    id: 8000,
    label: 'Pharmaindustrie'
  },
  {
    id: 31000,
    label: 'Sonstige Branchen'
  },
  {
    id: 26000,
    label: 'Sonstige Dienstleistungen'
  },
  {
    id: 12006,
    label: 'Sonstiges produzierendes Gewerbe'
  },
  {
    id: 18000,
    label: 'Telekommunikation'
  },
  {
    id: 4000,
    label: 'Textilien, Bekleidung & Lederwaren'
  },
  {
    id: 17000,
    label: 'Transport & Logistik'
  },
  {
    id: 23000,
    label: 'Unternehmensberatg., Wirtschaftsprüfg. & Recht'
  },
  {
    id: 19003,
    label: 'Versicherungen'
  },
  {
    id: 22000,
    label: 'Wissenschaft & Forschung'
  }
];

/**
 *
 * @type {Sektor[]}
 */
export const TypeOfContract = [
  {
    id: 222,
    label: 'Feste Anstellung'
  },
  {
    id: 228,
    label: 'Praktikum'
  },
  {
    id: 224,
    label: 'Berufseinstieg/Trainee'
  },
  {
    id: 229,
    label: 'Studentenjobs, Werkstudent'
  },
  {
    id: 223,
    label: 'Befristeter Vertrag'
  },
  {
    id: 226,
    label: 'Ausbildung, Studium'
  },
  {
    id: 220,
    label: 'Arbeitnehmerüberlassung'
  },
  {
    id: 227,
    label: 'Bachelor-/Master-/Diplom-Arbeiten'
  },
  {
    id: 225,
    label: 'Freie Mitarbeit/Projektmitarbeit'
  },
  {
    id: 230,
    label: 'Promotion/Habilitation'
  },
  {
    id: 232,
    label: 'Handelsvertreter'
  },
  {
    id: 231,
    label: 'Referendariat'
  },
  {
    id: 221,
    label: 'Franchise'
  }
];

/**
 *
 * @type {Sektor[]}
 */
export const WorkingTime = [
  {
    id: 80001,
    label: 'Vollzeit'
  },
  {
    id: 80002,
    label: 'Teilzeit'
  },
  {
    // This id may not match for stepstone this may change...
    id: 80005,
    label: 'Home-Office'
  }
];

/**
 *
 * @type {Sektor[]}
 */
export const WorkingExperience = [
  {
    id: 90002,
    label: 'Mit Berufserfahrung'
  },
  {
    id: 90003,
    label: 'Mit Personalverantwortung'
  },
  {
    id: 90001,
    label: 'Ohne Berufserfahrung'
  }
];
