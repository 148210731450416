import React from 'react';
import PropTypes from 'prop-types';
import { Visibility, VisibilityOff } from '@material-ui/icons';

/**
 * Used in the DossierList component to show the dossier viewed by customer
 * @param {boolean} isViewed
 * @returns the jsx Element to show the dossier viewed by customer
 */
const DossierViewedIcon = ({ isViewed }) => <>{isViewed ? <Visibility /> : <VisibilityOff />}</>;

DossierViewedIcon.propTypes = {
  isViewed: PropTypes.bool.isRequired
};
export default DossierViewedIcon;
