import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

class RemoveAttachments extends PureComponent {
  render() {
    return (
      <Fragment>
        <Button onClick={this.props.removeAttachments} color="secondary" style={{ fontSize: '13px', margin: '12px 24px' }}>
          <i className="material-icons pr-1">delete</i>
          Anhänge entfernen
        </Button>
      </Fragment>
    );
  }
}

RemoveAttachments.propTypes = {
  removeAttachments: PropTypes.func.isRequired
};

export default RemoveAttachments;
