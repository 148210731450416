// material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import '../generic_components/ConfirmationModal.css';
import ValidatedTextField from '../generic_components/ValidatedTextField';
import TEXT from '../text';

const FolderModal = ({
  open,
  handleClose,
  headlineText,
  icon,
  buttonSecondaryText,
  buttonPrimaryAction,
  buttonPrimaryText,
  descriptionText,
  inputLabel,
  initialFolderName
}) => {
  const [folderName, setFolderName] = useState(initialFolderName);
  const onChange = e => {
    const value = e.target.value;
    setFolderName(value);
  };

  useEffect(() => {
    if (open) {
      setFolderName(initialFolderName);
    }
  }, [open, initialFolderName]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <div className="confirmation-dialog">
        <DialogTitle>
          <div className="confirmation-dialog-title">
            <i className="material-icons confirmation-dialog-title-icon pb-3">{icon}</i>
            {headlineText}
          </div>
        </DialogTitle>
        <DialogContent id="confirmation-dialog-content">
          <DialogContentText>
            <div className="userResetConfirmation-descriptionText">{descriptionText}</div>

            <ValidatedTextField label={inputLabel} onChange={onChange} value={folderName} customClass="col-12" fullWidth />
          </DialogContentText>
        </DialogContent>
        <DialogActions className="confirmation-dialog-actions">
          <Button onClick={handleClose} color="secondary">
            {buttonSecondaryText}
          </Button>
          <Button onClick={() => buttonPrimaryAction(folderName)} color="secondary" variant="contained">
            {buttonPrimaryText}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

FolderModal.defaultProps = {
  headlineText: TEXT.ConfirmationModal.headlineText,
  buttonPrimaryText: TEXT.ConfirmationModal.buttonPrimaryText,
  buttonSecondaryText: TEXT.ConfirmationModal.buttonSecondaryText,
  icon: 'help_outline',
  initialFolderName: ''
};
FolderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  headlineText: PropTypes.string,
  inputLabel: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
  buttonPrimaryAction: PropTypes.func.isRequired,
  buttonPrimaryText: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  icon: PropTypes.string,
  initialFolderName: PropTypes.string
};
export default FolderModal;
