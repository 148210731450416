const text = {
  search: {
    searchFieldErrorMessage: 'Bitte geben Sie einen gültigen Suchbegriff ein.' // duplicate in text.js company
  },
  bulkDownloadDossierNoDossiers: 'Keiner der ausgewählten Kandidaten hat für diese Liste ein Dossier',
  projectDetails: {
    projectId: 'Auftragsnummer:',
    creationDate: 'Erstellungsdatum:',
    industrySector: 'Branche:',
    status: 'Status:',
    statusDetails: 'Statusgrund:',
    serviceType: 'Leistungsart:',
    consultant: 'Berater:',
    editor: 'Bearbeitung:',
    partner: 'Systempartner:',
    numberOfPositions: 'Anzahl an Positionen:',
    jobTitle1: 'Anzeigentitel (intern):',
    supervisor: 'Vorgesetzer:',
    salary: 'Gehaltswunsch:',
    partTime: 'Teilzeit:',
    comment: 'Kommentar:',
    internalComment: 'Interne Notizen:',
    confidential: 'Vertraulich:',
    castingType: 'Besetzungsart:',
    loop: 'Loop',
    conditionsAmount: 'Betrag',
    percentage: 'Prozent',
    da: 'DA',
    salaryLimitAmount: 'Betrag',
    conditions: 'Konditionen',
    salaryLimit: 'Salärrahmen'
  },
  projectDetailsUpdatePopUp: {
    /**
     * @param {string} listType
     * @param {string} selectedCandidateName
     */
    title: (listType, selectedCandidateName) => `Dossier - ${listType} : ${selectedCandidateName || ''}`,
    headline: 'Auftragsdaten ändern',
    buttons: {
      cancel: 'Abbrechen',
      save: 'Änderung speichern'
    },
    labels: {
      jobTitle1: 'Auftragstitle (Jobbezeichnung 1)',
      projectNumber: ' Auftragsnummer',
      status: 'Status:',
      statusDetails: 'Statusgrund',
      serviceType: 'Leistungsart',
      consultingUser: 'Berater',
      editingUser: 'Bearbeitung',
      numberOfPositions: 'Anzahl an Positionen',
      supervisor: 'Vorgesetzter',
      partTime: 'Teilzeit',
      salary: 'Gehaltswunsch',
      comment: 'Interne Notizen',
      confidential: 'Vertraulich',
      castingType: 'Besetzungsart',
      conditionsAmount: 'Betrag',
      percentage: 'Prozent',
      loop: 'Loop',
      da: 'DA',
      salaryLimitAmount: 'Betrag'
    }
  },
  actionProtocolCreationPopUp: {
    headline: 'Eintrag hinzufügen',
    buttons: {
      cancel: 'Abbrechen',
      save: 'Speichern'
    },
    labels: {
      type: 'Art des Eintrags',
      description: 'Beschreibung',
      date: 'Datum',
      doneByUser: 'Bearbeiter'
    }
  },
  projectStatusReport: {
    preview: {
      openPreview: 'Download Status Report',
      previewTitle: 'Vorschau',
      previewClose: 'Schließen',
      previewDownload: 'Download'
    }
  },
  bookingOptionsTitle: 'Buchungsoptionen',
  bookingOptions: {
    activeSearch: 'Active Sourcing',
    advert: 'Anzeigenbundle',
    searchSupport: 'Search Service'
  },
  projectSelection: {
    downloadButton: 'Ausgewählte Dossiers herunterladen',
    selectCandidatesForDossierDownload: 'Kandidaten für Dossierdownload auswählen'
  },
  deleteConfirmation: {
    button: 'Ausgewählte Dossiers löschen',
    headlineText: 'Dossiers löschen',
    descriptionText: 'Wollen Sie die ausgewählten Dossiers wirklich löschen?',
    buttonPrimaryText: 'Dossiers löschen'
  },
  deleteAllConfirmation: {
    /**
     * @param {string} listType
     */
    button: listType => `Alle Dossiers für ${listType} löschen`,

    headlineText: 'Dossiers löschen',
    descriptionText: 'Wollen Sie die Ausgewählten Dossiers wirklich löschen?',
    buttonPrimaryText: 'Dossiers löschen'
  },
  deleteAllDossiersError: 'Ein technischer Fehler ist aufgetreten.',
  employeeCommentConfirmation: {
    clearButton: 'Ausgewählte Beraterkommentare freigeben',
    hideButton: 'Ausgewählte Beraterkommentare verbergen',
    /**
     * @param {boolean} hideProjectCommentFromCustomer
     */
    headlineText: hideProjectCommentFromCustomer =>
      hideProjectCommentFromCustomer ? 'Beraterkommentare verbergen' : 'Beraterkommentare freigeben',
    /**
     * @param {boolean} hideProjectCommentFromCustomer
     */
    descriptionText: hideProjectCommentFromCustomer =>
      hideProjectCommentFromCustomer
        ? 'Sollen die ausgewählten Beraterkommentare wirklich verborgen werden?'
        : 'Sollen die ausgewählten Beraterkommentare wirklich freigegeben werden?',
    /**
     * @param {boolean} hideProjectCommentFromCustomer
     */
    buttonPrimaryText: hideProjectCommentFromCustomer =>
      hideProjectCommentFromCustomer ? 'Beraterkommentare verbergen' : 'Beraterkommentare freigeben'
  }
};
module.exports = text;
