import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// mdi Icon
// import Icon from '@mdi/react';
// import { mdiDomain as mdiCompanyIcon } from '@mdi/js';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';

// import HelperFunctions from './utils/helperFunctions';
// TODO direct copy of companyResult

const muiTheme = {
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        '&:nth-child(1)': {
          //    width: '70px'
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }
    },
    MUIDataTableBodyCell: {
      root: {
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  }
};

class ProjecResults extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.getColumns()
    };
  }

  getColumns() {
    return [
      {
        name: 'ID',
        options: {
          display: 'excluded',
          filter: false
        }
      },
      { name: 'Unternehmen' },
      { name: 'Berufsbezeichnung' },
      { name: 'PLZ' },
      { name: 'Stadt' },
      { name: 'Status' },
      {
        name: 'Auftragsnummer',
        options: {
          customBodyRender: (value, metaData) => this.props.projectNumberCustomBodyRenderer(value, metaData)
        }
      },
      {
        name: this.props.inCompanyArchive || CONSTANTS.isLienert ? 'Erstellungsdatum' : 'Zuletzt geändert am:',
        options: {
          sortDirection: 'desc',
          customBodyRender: value => UTILS.formatStringToDELocale(value)
        }
      }
    ];
  }

  prepareDataForTable(data) {
    const formatedData = [];
    if (data) {
      data.forEach(entry => {
        const mappedStatus = entry.status !== 'undefined' ? entry.status : '';
        formatedData.push([
          entry.id,
          entry.companyName,
          entry.jobTitle1,
          entry.postalCode,
          // HRNET-771 - location instead of city for Lienert
          CONSTANTS.isLienert ? entry.location : entry.city,
          mappedStatus,
          entry.projectNumber,
          this.props.inCompanyArchive || CONSTANTS.isLienert ? entry.creationDate : entry.lastUpdate
        ]);
      });
    }
    return formatedData;
  }

  onTableChange = (action, tableState) => {
    const displayedProjectIndexes = tableState.displayData.map(entry => entry.dataIndex).slice();
    sessionStorage.setItem('displayedProjectIndexes', JSON.stringify(displayedProjectIndexes));
  };

  render() {
    return (
      <div>
        {this.props.handleProjectSelected ? (
          <SafeMuiDatatable
            title="Aufträge"
            tableName="projectResults"
            data={this.prepareDataForTable(this.props.projectList)}
            columns={this.state.columns}
            onRowClick={this.props.handleProjectSelected}
            muiTheme={muiTheme}
            onTableChange={this.onTableChange}
            showMarklistButton
            markListType="project"
            handleSnackbarOpen={this.props.handleSnackbarOpen}
            unformatedData={this.props.projectList}
          />
        ) : (
          <SafeMuiDatatable
            title="Aufträge"
            tableName="projectResults"
            data={this.prepareDataForTable(this.props.projectList)}
            columns={this.state.columns}
          />
        )}
      </div>
    );
  }
}

ProjecResults.defaultProps = {
  handleProjectSelected: undefined,
  projectNumberCustomBodyRenderer: value => value,
  inCompanyArchive: false
};
ProjecResults.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  handleProjectSelected: PropTypes.func,
  projectList: PropTypes.array.isRequired,
  projectNumberCustomBodyRenderer: PropTypes.func,
  inCompanyArchive: PropTypes.bool
};
export default ProjecResults;
