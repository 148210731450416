import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import CheckIcon from '@material-ui/icons/Check';

// mdi Icon
// import Icon from '@mdi/react';
// import { mdiDomain as mdiCompanyIcon } from '@mdi/js';

import SafeMuiDatatable from '../../generic_components/SafeMuiDatatable';
import * as UTILS from '../../utils/utilFunctions';
import * as CONSTANTS from '../../constants';
// import HelperFunctions from './utils/helperFunctions';
// TODO direct copy of companyResult
const columns = CONSTANTS.isLienert
  ? [
    {
      name: 'ID',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    {
      name: 'Vorname',
      options: {
        filter: false
      }
    },
    {
      name: 'Nachname',
      options: {
        filter: false
      }
    },
    {
      name: 'E-Mail',
      options: {
        filter: false
        // customBodyRender: value => {
        //   const hrefParam = `mailto:${value}`;
        //   return <a href={hrefParam}>{value}</a>;
        // }
      }
    },
    {
      name: 'Betreff',
      options: {
        filter: false
      }
    },
    {
      name: 'Berufsbezeichnung',
      options: {
        filter: false
      }
    },
    {
      name: 'Unternehmen',
      options: {
        filter: false
      }
    },
    {
      name: 'PLZ',
      options: {
        filter: false
      }
    },
    {
      name: 'Stadt',
      options: {
        filter: false
      }
    },
    {
      name: 'Standort',
      options: {
        filter: true
      }
    },
    {
      name: 'Auftragsnummer',
      options: {
        filter: true
      }
    },
    { name: 'Bearbeitung' },
    {
      name: 'Beworben am',
      options: {
        customBodyRender: value => UTILS.formatDateToDELocale(new Date(value)),
        filter: false
      }
    }
  ]
  : [
    {
      name: 'ID',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    {
      name: 'Vorname',
      options: {
        filter: false
      }
    },
    {
      name: 'Nachname',
      options: {
        filter: false
      }
    },
    {
      name: 'Berufsbezeichnung',
      options: {
        filter: false
      }
    },
    {
      name: 'Unternehmen',
      options: {
        filter: false
      }
    },
    {
      name: 'PLZ',
      options: {
        filter: false
      }
    },
    {
      name: 'Stadt',
      options: {
        filter: false
      }
    },
    { name: 'Marke' },
    {
      name: 'Beworben am',
      options: {
        customBodyRender: value => UTILS.formatDateToDELocale(new Date(value)),
        filter: false
      }
    },
    {
      name: 'Selbsteingabe',
      options: {
        filter: true,
        customBodyRender: value => (value === 'Ja' ? <CheckIcon color="primary" /> : <div />)
      }
    }
  ];

const options = {
  filterType: 'checkbox',
  responsive: 'scroll',
  selectableRows: 'none',
  fixedHeader: false,
  filter: true,
  search: true,
  print: false,
  download: false,
  viewColumns: true,
  textLabels: {
    body: {
      noMatch: 'Keine passenden Einträge gefunden',
      toolTip: 'Sort'
    },
    pagination: {
      next: 'Nächste Seite',
      previous: 'Vorherige Seite',
      rowsPerPage: 'Zeilen pro Seite:',
      displayRows: 'von'
    },
    toolbar: {
      search: 'Suche',
      downloadCsv: 'Download CSV',
      print: 'Print',
      viewColumns: 'View Columns',
      filterTable: 'Filter Table'
    },
    filter: {
      all: 'Alle',
      title: 'FILTERS',
      reset: 'RESET'
    },
    viewColumns: {
      title: 'Spalten anzeigen',
      titleAria: 'Spalten anzeigen'
    },
    selectedRows: {
      text: 'rows(s) selected',
      delete: 'Delete',
      deleteAria: 'Delete Selected Rows'
    }
  }
};

const muiTheme = {
  overrides: {

    MUIDataTable: {
      responsiveScroll: {
        maxHeight: 'calc(100vh - 215px)',
      }
    },
    MUIDataTableHeadCell: {
      root: {
        '&:nth-child(1)': {
          //    width: '70px'
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }
    },
    MUIDataTableBodyCell: {
      root: {
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  }
};

class IncomingCandidateResults extends PureComponent {
  constructor(props) {
    super(props);
    if (CONSTANTS.isLienert) {
      const locationsFilter = UTILS.getURLParam(this.props.history, 'lo') ? UTILS.getURLParam(this.props.history, 'lo').split(',') : '';

      const advisorsFilter = UTILS.getURLParam(this.props.history, 'be') ? UTILS.getURLParam(this.props.history, 'be').split(',') : '';
      this.state = {
        initialFilter: {
          Standort: locationsFilter,
          Bearbeitung: advisorsFilter
        }
      };
    } else this.state = {};
    console.log('this.state', this.state);
  }

  prepareDataForTable(data) {
    const formatedData = [];
    if (data && CONSTANTS.isLienert) {
      data.forEach(entry => {
        formatedData.push([
          entry.id,
          entry.firstName,
          entry.lastName,
          entry.mailAddress,
          entry.mailSubject,
          entry.currentJob,
          entry.currentCompany,
          entry.postalCode,
          entry.city,
          entry.location,
          entry.projectNumber,
          entry.editedBy,
          entry.creationDate
        ]);
      });
    } else if (data && !CONSTANTS.isLienert) {
      data.forEach(entry => {
        formatedData.push([
          entry.id,
          entry.firstName,
          entry.lastName,
          entry.currentJob,
          entry.currentCompany,
          entry.postalCode,
          entry.city,
          entry.brand,
          entry.creationDate,
          entry.externalProfile ? 'Ja' : 'Nein'
        ]);
      });
    }
    return formatedData;
  }

  onTableChange = (action, tableState) => {
    if (action === 'filterChange' && tableState.filterList.length === 12) {
      // tableState.filterList[9][0] <-- Filter for Location
      if (tableState.filterList[9] && tableState.filterList[9].length > 0)
        UTILS.setURLParam(this.props.history, 'lo', tableState.filterList[9].join(','));
      else {
        UTILS.removeURLParam(this.props.history, 'lo');
      }
      if (tableState.filterList[10] && tableState.filterList[10].length > 0)
        UTILS.setURLParam(this.props.history, 'be', tableState.filterList[10].join(','));
      else {
        UTILS.removeURLParam(this.props.history, 'be');
      }
    }
  };

  render() {
    return (
      <SafeMuiDatatable
        title="Eingegangene Bewerbungen"
        tableName="incomingCandidateResults"
        data={this.prepareDataForTable(this.props.incomingCandidateList)}
        columns={columns}
        onRowClick={this.props.handleIncomingCandidateSelected}
        muiTheme={muiTheme}
        options={options}
        onTableChange={this.onTableChange}
        initialFilter={this.state.initialFilter}
      />
    );
  }
}
IncomingCandidateResults.propTypes = {
  handleIncomingCandidateSelected: PropTypes.func.isRequired,
  incomingCandidateList: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired
};
export default withRouter(IncomingCandidateResults);
