import React from 'react';
import {Typography} from '@material-ui/core';
import {Editor} from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';

/**
 * This is only a wrapper for the editor witch adds a label on the top
 *
 * @param {string?} label - Label text
 * @param {boolean?} isValid - if is not valid then label will change red
 * @param {Editor.propTypes?} props - props for editor
 * @return {JSX.Element}
 * @constructor
 */
const LabelEditor = ({label, isValid, ...props}) => (
    <>
        <Typography color={isValid ? 'textPrimary' : 'error'} component="label" variant="subtitle1">
            {label}
        </Typography>
        <Editor
            {...props}
        />
    </>
    );

LabelEditor.defaultProps = {
    isValid: true,
    label: '',
};

LabelEditor.propTypes = {
    isValid: PropTypes.bool,
    label: PropTypes.string,
};

export default LabelEditor;
