// base imports
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';

import * as CONSTANTS from '../constants';
import * as UTILS from '../utils/utilFunctions';

class DetailSearchLoadQueryBox extends PureComponent {
  constructor(props) {
    super(props);
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.state = {
      savedQueries: [],
      isDisabled: false
    };
  }

  getAllDetailSearchQueriesAPICall = () => {
    const url = CONSTANTS.getAllDetailSearchQueriesURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const payload = { detailSearchType: this.props.detailSearchType };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(payload),
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: 90000
    })
      .done(response => {
        this.setState({
          savedQueries: response
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  loadDetailSearchAPICall = queryId => {
    const url = CONSTANTS.loadDetailSearchURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const payload = {
      detailSearchType: this.props.detailSearchType,
      detailSearchQueryId: queryId
    };
    this.setState({
      isDisabled: true
    });
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(payload),
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: 90000
    })
      .done(response => {
        this.props.handleLoadQuery(response.query);
        this.props.handleClose();
        this.setState({
          isDisabled: false
        });
      })
      .fail(err => {
        this.setState({
          isDisabled: false
        });
        this.errorHandling(err, url);
      });
  };

  deleteDetailSearchAPICall = queryId => {
    const url = CONSTANTS.deleteDetailSearchURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const payload = {
      detailSearchType: this.props.detailSearchType,
      detailSearchQueryId: queryId
    };
    this.setState({
      isDisabled: true
    });
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(payload),
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: 90000
    })
      .done(response => {
        console.log('deleteDetailSearchAPICall_RESPONSE', response);
        this.setState(prevState => {
          const savedQueries = prevState.savedQueries.filter(entry => entry.id !== queryId);
          return { savedQueries };
        });
        this.props.handleSnackbarOpen('Ihre Detailsuche wurde erfolgreich gelöscht');
        this.setState({
          isDisabled: false
        });
      })
      .fail(err => {
        this.setState({
          isDisabled: false
        });
        this.errorHandling(err, url);
      });
  };

  renderListItems() {
    const savedQueries = this.state.savedQueries;
    const result = [];
    savedQueries.forEach(query => {
      result.push(
        <ListItem
          button
          disabled={this.state.isDisabled}
          onClick={() => this.loadDetailSearchAPICall(query.id)}
          color="primary"
          key={`${query.name}_${this.props.detailSearchType}`}
        >
          <ListItemText primary={query.name} />
          <ListItemSecondaryAction>
            <IconButton aria-label="Delete" disabled={this.state.isDisabled} onClick={() => this.deleteDetailSearchAPICall(query.id)}>
              <i className="material-icons">delete</i>
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
    return result;
  }

  render() {
    return (
      <Dialog open={this.props.open} onEntering={this.getAllDetailSearchQueriesAPICall} aria-labelledby="loadDetailSearchBox-dialog-title">
        <DialogTitle id="loadDetailSearchBox-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{this.state.savedQueries.length > 0 ? this.props.description : this.props.emptyListText}</DialogContentText>
          <List style={{ maxHeight: '100%', overflow: 'auto' }}>{this.state.savedQueries.length > 0 && this.renderListItems()}</List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.props.handleClose}>
            {this.props.cancelText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DetailSearchLoadQueryBox.defaultProps = {
  cancelText: 'cancel',
  title: 'Input custom title here',
  description: 'Input custom description here',
  emptyListText: 'Nothing available'
};

DetailSearchLoadQueryBox.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  emptyListText: PropTypes.string,
  detailSearchType: PropTypes.string.isRequired,
  handleLoadQuery: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default DetailSearchLoadQueryBox;
