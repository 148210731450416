import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import TEXT from './staticContent/text';
import * as UTILS from '../utils/utilFunctions';
import PdfViewerModal from '../generic_components/file_util_components/PdfViewerModal';
import * as API from './api/projectArchiveAPI';

class ProjectStatusReport extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showPreview: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  getPreview = () =>
    API.getProjectStatusReport(this.props.selectedProject.id)
      .then(responseBody => {
        const file = `data:application/pdf;fileName:Statusreport${this.props.selectedProject.projectNumber};base64,${responseBody.buffer}`;
        return file;
      })
      .catch(err => console.error(err));

  handlePreviewClick = () => {
    this.setState({ showPreview: true });
  };

  handlePreviewClose = () => {
    this.setState({
      showPreview: false
    });
  };

  render() {
    return (
      <>
        {this.state.showPreview && (
          <PdfViewerModal
            file={`Statusreport${this.props.selectedProject.projectNumber}.pdf`}
            open={this.state.showPreview}
            onResolveFileUrl={this.getPreview}
            onClose={this.handlePreviewClose}
          />
        )}
        <Paper>
          <div className="text-center p-5">
            <div className="col-12">
              <CloudDownloadIcon color="primary" fontSize="large" className="mt-2" />
            </div>
            <div className="col-12">
              <Button variant="contained" onClick={this.handlePreviewClick}>
                {TEXT.projectStatusReport.preview.openPreview}
              </Button>
            </div>
          </div>
        </Paper>
      </>
    );
  }
}

ProjectStatusReport.propTypes = {
  selectedProject: PropTypes.object.isRequired
};

export default ProjectStatusReport;
