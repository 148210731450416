import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Popover, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import CounterPartAccountStatus from './CounterPartAccountStatus';
import { counterPartAccountActions } from './CounterPartAccountPopUp';
import accountStatus from '../general/staticContent/accountStatus';
import TEXT from '../text';

const CounterPartAccountPopover = ({ counterPart, refreshCurrentCompany, openCounterPartAccountPopUp, openHistoryDrawer }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const anchorEl = useRef(null);

  const handleOpenAccountAction = useCallback(
    action => () => {
      setIsPopoverOpen(false);
      openCounterPartAccountPopUp({
        action,
        counterPart,
        afterSubmit: refreshCurrentCompany
      });
    },
    [counterPart, refreshCurrentCompany, openCounterPartAccountPopUp]
  );

  const handleOpenHistoryDrawer = useCallback(() => {
    setIsPopoverOpen(false);
    openHistoryDrawer();
  }, [openHistoryDrawer]);

  // Select the menu items needed to manage the user account based on the user state.
  const accountManagementMenuItems = useMemo(() => {
    switch (counterPart.status) {
      case accountStatus.NO_ACCOUNT:
        return (
          <MenuItem key="createAccount" onClick={handleOpenAccountAction(counterPartAccountActions.CREATE)} color="inherit">
            <i className="material-icons pr-2">library_add</i>
            {TEXT.companyArchive.counterPartAccessMenuLabel.createAccess}
          </MenuItem>
        );
      case accountStatus.INVITED:
      case accountStatus.INITIAL_LOGIN:
      case accountStatus.PASSWORD_SET:
      case accountStatus.REINVITED:
      case accountStatus.ACTIVE:
        return (
          <>
            <MenuItem key="unlockProjects" onClick={handleOpenAccountAction(counterPartAccountActions.UNLOCK_PROJECTS)} color="inherit">
              <i className="material-icons pr-2">lock_open</i>
              {TEXT.companyArchive.counterPartAccessMenuLabel.unlockProjects}
            </MenuItem>
            <MenuItem key="disableAccount" onClick={handleOpenAccountAction(counterPartAccountActions.DISABLE)} color="inherit">
              <i className="material-icons pr-2">lock</i>
              {TEXT.companyArchive.counterPartAccessMenuLabel.lockAccess}
            </MenuItem>
            <MenuItem key="resetPassword" onClick={handleOpenAccountAction(counterPartAccountActions.RESETPASSWORD)} color="inherit">
              <i className="material-icons pr-2">rotate_left</i>
              {TEXT.companyArchive.counterPartAccessMenuLabel.resetPassword}
            </MenuItem>
            <MenuItem key="viewLogs" color="inherit" onClick={handleOpenHistoryDrawer}>
              <i className="material-icons pr-2">history</i>
              {TEXT.companyArchive.counterPartAccessMenuLabel.showHistory}
            </MenuItem>
          </>
        );
      case accountStatus.ACCOUNT_LOCKED:
      case accountStatus.DELETED:
        return (
          <MenuItem key="activateAccount" onClick={handleOpenAccountAction(counterPartAccountActions.ACTIVATE)} color="inherit">
            <i className="material-icons pr-2">library_add</i>
            {TEXT.companyArchive.counterPartAccessMenuLabel.reactivateAccess}
          </MenuItem>
        );
      default:
        return null;
    }
  }, [counterPart.status, handleOpenAccountAction, handleOpenHistoryDrawer]);

  return (
    <>
      <div ref={anchorEl}>
        <CounterPartAccountStatus status={counterPart.status} onClick={() => setIsPopoverOpen(true)} />
      </div>
      <Popover
        open={isPopoverOpen}
        anchorEl={anchorEl.current}
        anchorReference="anchorEl"
        onClose={() => setIsPopoverOpen(false)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <div className="p-2 d-flex flex-column">{accountManagementMenuItems}</div>
      </Popover>
    </>
  );
};

CounterPartAccountPopover.propTypes = {
  openCounterPartAccountPopUp: PropTypes.func.isRequired,
  counterPart: PropTypes.object.isRequired,
  refreshCurrentCompany: PropTypes.func.isRequired,
  openHistoryDrawer: PropTypes.func.isRequired
};

export default CounterPartAccountPopover;
