import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './SingleSearchResult.css';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import withUser from '../utils/withUser';
import TEXT from '../text';
import * as UTILS from '../utils/utilFunctions';
import * as constants from '../constants';
import WillingnessToChangeCircle from './WillingnessToChangeCircle';

/**
 * The single frame for displaying the candidate information
 * @author Victor
 * @extends Component
 */
class SingleSearchResult extends Component {
  constructor(props) {
    super(props);
    this.handleMarkButton = this.handleMarkButton.bind(this);
    this.state = {
      isExpanded: false
    };
  }

  isExtendedView() {
    return ['admin', 'employee'].includes(this.props.user.role) && !this.props.anonymized;
  }

  getCompanyName(companyName) {
    if (!this.isExtendedView()) return TEXT.resultPage.company;
    return companyName;
  }

  /**
   * Return a JSX with the "current" and "previous" job
   * @return {Array} an array of JSX elemtent for the
   *                 current and the previous job description
   */
  getJobs(getAllJobs) {
    let jobsArray = this.props.jobs;
    if (!this.state.isExpanded && !getAllJobs) {
      jobsArray = jobsArray.slice(0, 2);
    }
    return jobsArray.map((job, cnt) => (
      <div key={job.ID} className="current-job mb-4">
        <div className="mb-2 font-size-title">
          <div className="font-weight-bold cl-blue capitalize-words">{job.jobText}</div>

          {this.getCompanyName(job.company) && (
            <div className={`font-weight-bold capitalize-words ${this.isExtendedView() ? '' : ' blurry'}`}>
              {this.getCompanyName(job.company)}
            </div>
          )}
        </div>
        {this.calcJobsDuration(job, cnt)}
        {this.displayCandidateJobBranches(job)}
      </div>
    ));
  }

  displayCandidateJobBranches(job) {
    let result = <div className="mb-2">{TEXT.resultPage.noJobDescription}</div>;
    if (job.description && job.description.length > 0) {
      result = (
        <div className="text-left">
          <span className="capitalize-words">{`${TEXT.resultPage.description} ${job.description}`}</span>
        </div>
      );
    } else if (job.branches.length > 0 && job.branches[0] !== '') {
      result = (
        <div className="text-left">
          {TEXT.resultPage.branches}
          <span className="capitalize-words">
            {job.branches.map((element, index) => ` ${element}${index === job.branches.length - 1 ? '.' : ', '}`)}
          </span>
        </div>
      );
    }
    return result;
  }

  mapSkillLevel(skillLevel) {
    let skillLevelText = '';
    switch (skillLevel) {
      case 0:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel0;
        break;
      case 1:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel1;
        break;
      case 2:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel2;
        break;
      case 3:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel3;
        break;
      case 4:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel4;
        break;
      case 5:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel5;
        break;
      case 7:
        skillLevelText = TEXT.resultPage.sideBar.skillLevel6;
        break;
      default:
        break;
    }
    return skillLevelText;
  }

  getSkills() {
    return (
      <div>
        {this.props.languageSkills && this.props.languageSkills.length > 0 ? (
          <div>
            <h6 className="cl-blue m-md-0 font-weight-bold">{TEXT.resultPage.languageSkills}</h6>
            <br />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{TEXT.resultPage.skillDetails.languageTableHeading.leftColumn}</TableCell>
                  <TableCell>{TEXT.resultPage.skillDetails.languageTableHeading.rightColumn}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.languageSkills.map(row => (
                  <TableRow key={row.language + row.skillLevel}>
                    <TableCell component="th" scope="row">
                      {row.language}
                    </TableCell>
                    <TableCell>{this.mapSkillLevel(row.skillLevel)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>{' '}
          </div>
        ) : (
          ''
        )}
        {this.props.additionalSkills && this.props.additionalSkills.length > 0 ? (
          <div>
            <h6 className="cl-blue m-md-0 font-weight-bold pt-3">{TEXT.resultPage.additionalSkills}</h6>
            <br />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{TEXT.resultPage.skillDetails.otherSkillsTableHeading.leftColumn}</TableCell>
                  <TableCell>{TEXT.resultPage.skillDetails.otherSkillsTableHeading.rightColumn}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.props.additionalSkills.map(row => (
                  <TableRow key={row.skillName + row.skillLevel}>
                    <TableCell component="th" scope="row">
                      {row.skillName}
                    </TableCell>
                    <TableCell>{row.skillLevel}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }

  disableSelection(e) {
    e.preventDefault();
  }

  /**
   * Returns the given duration in years
   * @param  {number} duration denotes job time in milliseconds
   * @return {number}          converted number to years
   */
  durationMillisecondsToYears(duration) {
    return Math.floor(duration / (1000 * 60 * 60 * 24 * 30 * 12));
  }

  /**
   * Returns the remainder of months from a job duration
   * ex: 1,5 years -> 18 months -> 1 year and 6 months
   * the number of years is omitted in the output, only the months
   * are returned
   * @param  {number} duration denotes job time in milliseconds
   * @return {number}          converted number to months
   */
  durationMillisecondsToRestMonths(duration) {
    return Math.floor(duration / (1000 * 60 * 60 * 24 * 30)) % 12;
  }

  /**
   * [calcJobsDuration description]
   * @param  {Object} job  see job object in the prop validation for details
   * @param  {number} index denotes the position of the job Object in the jobs Array
   *                        if index == 0 the job is the current job, if index == 1
   *                        the job is the old job
   * @return {JSX}    the job duration for the current or the old job
   */
  calcJobsDuration(job, index) {
    let result = <div className="mb-2">{TEXT.resultPage.noJobDuration}</div>;
    if (job.duration > 0) {
      result = (
        <div className="mb-2">
          {index === 0 ? TEXT.resultPage.lastPositions.currentPosition : TEXT.resultPage.lastPositions.formerPosition}
          {`${this.durationMillisecondsToYears(job.duration)} ${
            TEXT.resultPage.lastPositions.years
          } ${this.durationMillisecondsToRestMonths(job.duration)} ${TEXT.resultPage.lastPositions.months}`}
          )
        </div>
      );
    }
    return result;
  }

  getContactInformation() {
    if (this.isExtendedView()) {
      return (
        <div>
          {this.props.phoneNumber ? (
            <p className="text-left">
              {`${TEXT.resultPage.phoneNumber}`}
              <br />
              <a href={`tel:${this.props.phoneNumber}`}>{this.props.phoneNumber}</a>
            </p>
          ) : (
            ''
          )}
          {this.props.mailAddress && (
            <p className="text-left">
              {`${TEXT.resultPage.mailAddress}`} <br />
              <a href={UTILS.parserMailLink(this.props.mailAddress)}>{this.props.mailAddress}</a>
            </p>
          )}
        </div>
      );
    }
    return '';
  }

  handleMarkButton(e) {
    e.stopPropagation();
    this.props.handleMarkCandidate(this.props.ID);
  }

  handleExpandButton = e => {
    e.stopPropagation();
    this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
  };

  handleExpandButton = e => {
    e.stopPropagation();
    this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
  };

  handleMoreInfoButton = () => {
    const liftUpObj = {
      picture: this.isExtendedView() ? (this.props.avatar || {}).contentUrl : constants.candidatePlaceholderPictureURL,
      isAvailable: this.props.isAvailable,
      score: this.props.score,
      willingnessToChange: this.props.willingnessToChange,
      lastChange: this.props.lastChange,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      jobs: this.getJobs(true),
      skills: this.getSkills(),
      anonymized: this.props.anonymized,
      contactInformation: this.getContactInformation(),
      candidateInformation: this.getCandidateInformation(),
      documents: this.props.documents,
      taggedDocuments: this.props.taggedDocuments,
      ID: this.props.ID,
      candidateIDs: this.props.origin
    };
    // liftUpObj.candidateNumbers = candidateNumbersDom;
    this.props.liftUpStateToApp('sidePanelContent', liftUpObj);
  };

  renderWillingnessToChange = () => (
    <div>
      <WillingnessToChangeCircle willingnessToChange={this.props.willingnessToChange} textAboveCircle={false} />
    </div>
  );

  getCandidatePicture() {
    if (!this.isExtendedView()) {
      return <img src={constants.candidatePlaceholderPictureURL} alt="" width="150px" className="img-fluid rounded" />;
    }
    if (this.props.avatar) {
      return <img src={this.props.avatar.contentUrl} alt="" width="150px" className="img-fluid rounded" />;
    }
    return <CircularProgress className="m-5" />;
  }

  getResidence() {
    if (this.props.currentResidence === null && this.props.currentCountry === null) return '';
    if (this.props.currentResidence === null && this.props.currentCountry !== null) return this.props.currentCountry;
    if (this.props.currentResidence !== null && this.props.currentCountry === null) return this.props.currentResidence;
    return `${this.props.currentResidence}, ${this.props.currentCountry}`;
  }

  restrictDisplayItemChangeDateForClient() {
    let result = '';
    if (['admin', 'employee'].includes(this.props.user.role)) {
      result = `${TEXT.resultPage.lastChange} ${
        this.props.lastChange === 'N/A' ? this.props.lastChange : UTILS.formatDateToDELocale(UTILS.parseDate(this.props.lastChange))
      }`;
    }
    return result;
  }

  getCandidateInformation() {
    return (
      <div>
        <p className="text-left">{this.getResidence()}</p>

        <p className="text-left">
          {TEXT.resultPage.age} {this.props.age}
        </p>

        <p className="text-left">{this.restrictDisplayItemChangeDateForClient()}</p>
      </div>
    );
  }

  renderBrandIcons() {
    console.log('debug brand icons', this.props.brand);
    if (this.props.brand) {
      const { brand } = this.props;
      console.log('DEBUG brand', brand);
      return (
        <div>
          {/* <Divider className="mb-3" /> */}
          <div className="candidateArchive_brand-logo-container">
            {brand.includes('personaltotal') && (
              <img
                className="img-fluid brand-logo"
                src="./img/favicon_personaltotal.png"
                alt="personaltotal"
                // style={{ width: '24px' }}
              />
            )}
            {brand.includes('senator') && <img className="img-fluid brand-logo" src="./img/favicon_senator.png" alt="senator" />}
            {brand.includes('lienert') && <img className="img-fluid brand-logo" src="./img/favicon_lienert.png" alt="lienert" />}
          </div>
        </div>
      );
    }
    return '';
  }

  render() {
    // TODO adapt conditions for availability of other job details
    const showExpandFunctionality = this.props.jobs.length > 2;
    const expandCollapseIcon = this.state.isExpanded ? 'expand_less' : 'expand_more';

    return (
      <Paper className={`mainBoxWrapper m-2 p-2 ${this.props.isMarked ? 'marked' : ''}`} onClick={this.handleMoreInfoButton}>
        <div className="row">
          <div className="col-md-2 col-xs-12">
            <div className="sidebarInfo">
              <div className="portrait-wrapper text-center mb-1">{this.getCandidatePicture()}</div>
              <p className="text-center">
                {this.props.isAvailable ? (
                  <span className="cl-green d-flex flex-row justify-content-center">
                    <i className="material-icons font-size-title">fiber_manual_record</i>
                    <span>{TEXT.resultPage.available}</span>
                  </span>
                ) : (
                  <span className="cl-red d-flex flex-row justify-content-center">
                    <i className="material-icons font-size-title">fiber_manual_record</i>
                    <span>{TEXT.resultPage.notAvailable}</span>
                  </span>
                )}
              </p>
              {this.getCandidateInformation()}
              {this.renderBrandIcons()}
              {/* {this.getContactInformation()} */}
            </div>
          </div>
          <div className="col-md-7 col-xs-12">
            <div className="headerInfo mb-3 d-md-flex justify-content-md-between align-items-md-center">
              <h4 className={`candidate-name cl-blue m-md-0 ${this.isExtendedView() ? '' : ' blurry'}`}>
                {`${this.props.firstName} ${this.props.lastName}`}
              </h4>
            </div>

            <div className="jobQualification">{this.getJobs()}</div>
            {this.state.isExpanded ? this.getSkills() : ''}
          </div>
          <div className="col-md-3 text-center">
            <div className="row">
              <div className="col-12 mt-3">
                {UTILS.calcMatchStars(this.props.score)}
                <br />
                {TEXT.resultPage.matchPercentage}
              </div>
            </div>
            <div />
            <div className="row">
              <div className="col-12  mt-3 ">{this.renderWillingnessToChange()}</div>
            </div>
            {this.props.user.role === 'client' ? (
              <div className="row">
                <div className="col-12  mt-3 mb-1">
                  <Button className="button-more-info" variant="contained" color="secondary" onClick={this.handleMarkButton} fullWidth>
                    {this.props.isMarked ? TEXT.resultPage.isInInterviewList : TEXT.resultPage.addToInterviewList}
                  </Button>
                </div>
              </div>
            ) : (
              ''
            )}
            <Button
              disabled={!this.isExtendedView()}
              className="button-more-info"
              variant="contained"
              color="secondary"
              onClick={() => {
                this.props.liftUpStateToAllSearchResults('selectedUserMarkItem', {
                  candidate: this.props.ID,
                  matchPercentage: this.props.score,
                  willingnessToChange: this.props.willingnessToChange
                });
                this.props.liftUpStateToAllSearchResults('markCandidatePopUpOpen', true);
              }}
              fullWidth
            >
              {TEXT.markListPopup.addItemToMarkListButton}
            </Button>
            <Button
              disabled={!this.isExtendedView()}
              className="button-more-info"
              variant="contained"
              color="secondary"
              onClick={() => {
                this.props.liftUpStateToAllSearchResults('addCandidateToProjectCandidateId', this.props.ID);
                this.props.liftUpStateToAllSearchResults('showAddCandidateToProjectPopUp', true);
              }}
              fullWidth
            >
              {'Zu Auftrag vorschlagen'}
            </Button>
          </div>
        </div>
        {showExpandFunctionality && (
          <div className="row expanderBar">
            <div className="col-12" align="center">
              <Button onClick={this.handleExpandButton} fullWidth>
                <i className="material-icons">{expandCollapseIcon}</i>
              </Button>
            </div>
          </div>
        )}
      </Paper>
    );
  }
}

SingleSearchResult.defaultProps = {
  firstName: TEXT.resultPage.firstName,
  lastName: TEXT.resultPage.lastName,

  currentResidence: '',
  currentCountry: TEXT.commonTerms.NA,
  jobs: [],
  avatar: undefined,
  age: 0,
  lastChange: TEXT.commonTerms.NA,
  additionalSkills: undefined,
  languageSkills: undefined,
  phoneNumber: undefined,
  mailAddress: undefined,
  origin: undefined,
  brand: [],
  documents: [],
  taggedDocuments: []
};

SingleSearchResult.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  liftUpStateToAllSearchResults: PropTypes.func.isRequired,
  isAvailable: PropTypes.bool.isRequired,
  currentResidence: PropTypes.string,
  currentCountry: PropTypes.string,
  age: PropTypes.number,
  ID: PropTypes.string.isRequired,
  lastChange: PropTypes.string,
  jobs: PropTypes.arrayOf(
    PropTypes.shape({
      jobText: PropTypes.string,
      company: PropTypes.string,
      duration: PropTypes.number,
      branches: PropTypes.arrayOf(PropTypes.string),
      ID: PropTypes.string.isRequired,
      description: PropTypes.string
    })
  ),
  // TODO type is not known
  documents: PropTypes.array,
  taggedDocuments: PropTypes.array,
  languageSkills: PropTypes.array,
  additionalSkills: PropTypes.array,
  score: PropTypes.number.isRequired,
  isMarked: PropTypes.bool.isRequired,
  handleMarkCandidate: PropTypes.func.isRequired,
  avatar: PropTypes.shape({
    contentUrl: PropTypes.string
  }),
  user: PropTypes.object.isRequired,
  willingnessToChange: PropTypes.number.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  anonymized: PropTypes.bool.isRequired,
  phoneNumber: PropTypes.string,
  mailAddress: PropTypes.string,
  origin: PropTypes.array,
  brand: PropTypes.array
};

export default withUser(SingleSearchResult);
