import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, ListItem, ListItemText } from '@material-ui/core';

class CategoryNameMapper extends PureComponent {
  render() {
    return (
      <div className="pb-4">
        <Typography variant="h6" component="h6">
          {this.props.name}
        </Typography>
        <div className="row">
          {this.props.inputList.map(entry => (
            <div className="col-6">
              <ListItem>
                <ListItemText primary={entry.label} secondary={`${entry.category}`} />
              </ListItem>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

CategoryNameMapper.propTypes = {
  inputList: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default CategoryNameMapper;
