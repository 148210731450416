import React, { useCallback, useEffect, useState } from 'react';
import { Page } from 'react-pdf';
import { getCandidateLienertDossier, updatePotentialCandidate } from '../api/projectArchiveAPI';
import { deleteProjectCandidateDossier } from '../../candidateArchive/api/candidateArchiveAPI';

/**
 * This holds the states inside the popup
 * @param {string} selectedPotentialCandidateId
 * @param {string} selectedCandidateId
 * @param {string} selectedProceeding
 * @param {string} selectedRating
 * @param {string} selectedComment
 * @param {Object} selectedProject - the whole project object -> no specific type exist yet...
 * @param {ProjectDossierListEntry[]} selectedDossierList
 * @param {ListType} listType
 * @param {boolean} hiddenFromClient
 * @return {{
 * deleteDossier: () => Promise<void>
 * comment: string,
 * saving: boolean,
 * handleSubmit: (potentialCandidateId: string, liftUpStateSelectedProject: function(value):void, handleCloseDialog: function(): void) => void,
 * handleChangeNode: (value: string) => void,
 * handleChangeRating: (value: string) => void,
 * handleChangeProceeding: (event: React.ChangeEvent) => void,
 * handleChangeHideFromClient: (event: React.ChangeEvent<HTMLInputElement>) => void,
 * rating: string,
 * proceeding: string,
 * pdfPageNumber: Number,
 * setPdfPageNumber: function,
 *
 * }}
 */
const useProjectSelectionPopUp = (
  selectedPotentialCandidateId,
  selectedCandidateId,
  selectedProceeding,
  selectedRating,
  selectedComment,
  selectedProject,
  selectedDossierList,
  listType,
  hiddenFromClient
) => {
  const [proceeding, setProceeding] = useState(selectedProceeding);
  const [rating, setRating] = useState(selectedRating);
  const [comment, setComment] = useState(selectedComment);
  const [saving, setSaving] = useState(false);
  const [dossierURL, setDossierURL] = useState('');
  const [pdfPageNumber, setPdfPageNumber] = useState(0);
  const [hideFromClient, setHideFromClient] = useState(hiddenFromClient);

  const handleChangeProceeding = event => {
    setProceeding(event.target.value);
  };
  const handleChangeRating = value => {
    let updatedRating = value;
    if (value === rating) {
      // reset rating if the same is selected
      updatedRating = '';
    }
    setRating(updatedRating);
  };

  const handelChangeHideFromClient = useCallback(
    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event
     */
    event => {
      setHideFromClient(event.target.checked);
    },
    []
  );

  const handleChangeComment = value => {
    setComment(value);
  };

  const handlePdfPages = ({ numPages }) => {
    const pageList = [];
    for (let i = 1; i <= numPages; i++) {
      pageList.push(<Page width={800} key={i - 1} className="mx-auto" pageNumber={i} renderAnnotations={false} renderTextLayer={false} />);
    }
    return pageList;
  };

  const getDossier = useCallback(async () => {
    if (selectedCandidateId) {
      if (selectedDossierList.length) {
        const newestDossierDate = new Date(Math.max(...selectedDossierList.map(dossierEntry => new Date(dossierEntry.creationDate))));
        if (newestDossierDate) {
          const newestDossier = selectedDossierList.filter(dossierEntry => dossierEntry.creationDate === newestDossierDate.toISOString());
          if (newestDossier.length) {
            const result = await getCandidateLienertDossier(selectedCandidateId, newestDossier[0].fileName, true);
            if (result) {
              return result.contentUrl;
            }
          }
          return '';
        }
      } else {
        // if it's the selection list dossier selectedDossierList is not an array it's an object with only one dossier
        const result = await getCandidateLienertDossier(selectedCandidateId, selectedDossierList.fileName, true);
        if (result) {
          return result.contentUrl;
        }
      }
    }
    return '';
  }, [selectedDossierList, selectedCandidateId]);

  const deleteDossier = useCallback(async () => {
    if (selectedCandidateId) {
      if (selectedDossierList.length) {
        const newestDossierDate = new Date(Math.max(...selectedDossierList.map(dossierEntry => new Date(dossierEntry.creationDate))));
        if (newestDossierDate) {
          const newestDossier = selectedDossierList.filter(dossierEntry => dossierEntry.creationDate === newestDossierDate.toISOString());
          if (newestDossier.length) {
            const result = await getCandidateLienertDossier(selectedCandidateId, newestDossier[0].fileName, true);
            if (result) {
              await deleteProjectCandidateDossier(selectedCandidateId, result.fileName);
            }
          }
        }
      } else {
        // if it's the selection list dossier selectedDossierList is not an array it's an object with only one dossier
        const result = await getCandidateLienertDossier(selectedCandidateId, selectedDossierList.fileName, true);
        if (result) {
          await deleteProjectCandidateDossier(selectedCandidateId, result.fileName);
        }
      }
    }
    return '';
  }, [selectedCandidateId, selectedDossierList]);

  useEffect(() => {
    if (selectedPotentialCandidateId) {
      setProceeding(selectedProceeding);
      setRating(selectedRating);
      setComment(selectedComment);
      setHideFromClient(hiddenFromClient);
      getDossier()
        .then(res => {
          setDossierURL(res);
        })
        .catch(() => {
          setDossierURL('');
        });
    }
  }, [selectedPotentialCandidateId, selectedComment, selectedProceeding, selectedRating, getDossier, hiddenFromClient]);
  /**
   * This will handle submit - simple huh... :)
   * @param {string} potentialCandidateId
   * @param {function(value): void} liftUpStateSelectedProject
   * @param {function(): void} handleCloseDialog
   */
  const handleSubmit = useCallback(
    (potentialCandidateId, liftUpStateSelectedProject) => {
      const furtherProceedingListName = 'furtherProceedingLonglist'; // longlist further proceeding is used for every list also for shortlist
      const updateObject = {
        [furtherProceedingListName]: proceeding,
        rating,
        comment,
        hideEmployeeCommentInCustomerPortal: hideFromClient
      };

      // This code down here will build the whole potential candidate for the lift-up state
      const {
        /**
         * @type PotentialCandidateState[]
         */
        potentialCandidates
      } = selectedProject;
      // First search index for current object candidate
      const potentialIndex = potentialCandidates.findIndex(candidate => candidate.id === potentialCandidateId);

      const updateCandidate = {
        ...potentialCandidates[potentialIndex],
        ...updateObject
      };

      // Then build the new array with ALL potential candidates but with the updated object
      const updatedPotentialCandidates = [
        ...potentialCandidates.filter(candidates => candidates.id !== potentialCandidateId),
        updateCandidate
      ];
      // Then build whole new selected project object for the lift-up state function
      const updatedSelectedProjects = {
        ...selectedProject,
        potentialCandidates: updatedPotentialCandidates
      };
      setSaving(true);
      // Then make the call and update state
      updatePotentialCandidate(selectedProject.id, potentialCandidateId, updateObject)
        .then(() => {
          liftUpStateSelectedProject(updatedSelectedProjects);
          setSaving(false);
        })
        .catch(() => {
          setSaving(false);
          window.location = '/err';
        });
    },
    [comment, proceeding, rating, selectedProject, hideFromClient]
  );

  return {
    proceeding,
    rating,
    comment,
    saving,
    dossierURL,
    hideFromClient,
    handelChangeHideFromClient,
    handleChangeRating,
    handleChangeProceeding,
    handleChangeComment,
    handleSubmit,
    deleteDossier,
    pdfPageNumber,
    setPdfPageNumber,
    handlePdfPages
  };
};

export default useProjectSelectionPopUp;
