import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import getAllOffersList from '../offers/api/offersAPI';
import OffersTable from '../offers/components/OffersTable';
import * as UTILS from '../utils/utilFunctions';
import withUser from '../utils/withUser';
import TEXT_GENERAL from '../text';

class Offers extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      offersList: []
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  fetchAllOffers = async () => {
    try {
      const allOffersList = await getAllOffersList();
      this.setState({ offersList: allOffersList });
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
    } catch (e) {
      this.errorHandling(e);
    }
  };

  componentDidMount() {
    document.title = `Offerten - ${UTILS.getTitle()}`;
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    this.fetchAllOffers();
  }

  render() {
    return (
      <div className="Offers h-100-minusAppBar">
        <div className="container_fluid h-100 col-12 mt-4">
          {this.state.offersList && this.state.offersList !== null && this.state.offersList.length > 0 ? (
            <OffersTable {...this.props} offersList={this.state.offersList} errorHandling={this.errorHandling} />
          ) : (
            <>
              <div>
                <div className="sideBarWrapper p-2">
                  <div className="candidateArchive-emptyPage">{TEXT_GENERAL.offers.noOffers}</div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

Offers.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired
};

export default withUser(Offers);
