const tooltips = {
  companyArchive: {
    editButton: 'Datensatz ändern',
    showHistoryButton: 'Änderungsverlauf anzeigen',
    editCompanyStatusButton: 'Status ändern',
    markButton: 'Unternehmen markieren',
    deleteButton: 'Unternehmen löschen'
  },
  projectArchive: {
    editButton: 'Datensatz ändern',
    showHistoryButton: 'Änderungsverlauf anzeigen',
    editCompanyStatusButton: 'Status ändern',
    markButton: 'Auftrag markieren',
    deleteButton: 'Auftrag löschen'
  },
  candidateArchive: {
    tooltipIsAvailable: 'verfügbar',
    editButton: 'Datensatz ändern',
    showHistoryButton: 'Änderungsverlauf anzeigen',
    editCandidateStatusButton: 'Status ändern',
    resetCandidateStatusButton: 'Status zurücksetzen',
    markButton: 'Kandidat markieren',
    addCandidateToProjectButton: 'Kandidat zu Auftrag hinzufügen',
    showAddCandidateToProjectButton: 'Kandidat für Auftrag vorschlagen',
    showAddCandidateToProjectApplicationButton: 'Kandidat für Auftrag beworben',
    downloadPersonalDataSheet: 'Personalbogen herunterladen',
    downloadISheet: 'I-Blatt herunterladen',
    downloadPSheet: 'P-Blatt herunterladen',
    deletePicture: 'Profilbild löschen',
    deleteCandidateData: 'Kandidaten Daten löschen',
    deleteCandidateDocuments: 'Dokumente löschen',
    deleteCandidate: 'Kandidat löschen',
    setNeedsValidation: 'Kandidat zur Überprüfung kennzeichnen',
    clearNeedsValidation: 'Kennzeichnung zur Überprüfung zurücknehmen',
    needsValidationSet: 'Der Kandidat wurde zur Überprüfung gekennzeichnet.',
    needsValidationCleared: 'Der Kandidat ist nicht mehr zur Überprüfung gekennzeichnet.'
  }
};
module.exports = tooltips;
