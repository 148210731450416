import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import GenericForm from '../generic_components/GenericForm';
import ReleaseNotePopUp from '../general/releaseNote/ReleaseNotePopUp';
import { post } from '../utils/apiUtils';
import { errorHandling } from '../utils/utilFunctions';

const FIELDS = {
  icon: {
    value: '',
    label: 'Symbol',
    type: 'dropDown',
    placeHolderText: 'Ohne Symbol',
    content: [
      { value: 'info', label: 'Info' },
      { value: 'warning', label: 'Warnung' },
      { value: 'mood', label: 'Lachender Smiley' },
      { value: 'build', label: 'Wartungsarbeiten' }
    ]
  },
  headline: {
    customClass: 'col-12 mb-2',
    value: '',
    required: true,
    label: 'Überschrift',
    type: 'text'
  },
  content: {
    customClass: 'col-12 mb-2',
    value: '',
    required: true,
    label: 'Inhalt',
    type: 'editor'
  }
};

const ReleaseNotesTab = ({ handleSnackbarOpen }) => {
  const [previewData, setPreviewData] = React.useState({});
  const [saved, setSaved] = React.useState(false);
  const [showPreview, setShowPreview] = React.useState(false);

  function preview(data) {
    setPreviewData(data);
    setShowPreview(true);
  }
  async function save(data) {
    try {
      await post('/addReleaseNote', data);
      setSaved(true);
    } catch (err) {
      errorHandling.bind({ props: { handleSnackbarOpen } })(err);
    }
  }

  return (
    <div className="container">
      <Paper className="p-3">
        <h4>Neue Ankündigung</h4>
        {saved ? (
          <div>Die Ankündigung wurde erfolgreich gespeichert.</div>
        ) : (
          <>
            <p>
              Erstellen Sie eine Ankündigungsnachricht, die Kunden-Benutzern einmalig bei der Anmeldung angezeigt wird. Die Ankündigung ist
              7 Tage gültig.
            </p>
            <GenericForm
              formFields={FIELDS}
              buttonSaveText="Absenden"
              buttonCancelText=""
              handleSubmit={save}
              handleSecondarySubmit={preview}
            />
          </>
        )}
      </Paper>
      <ReleaseNotePopUp open={showPreview} handleClose={() => setShowPreview(false)} {...previewData} />
    </div>
  );
};

ReleaseNotesTab.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default ReleaseNotesTab;
