import jwt from 'jsonwebtoken';
import * as UTILS from './utilFunctions';

export default function parseTokenToState(token, user, serialMailCount) {
  const decodedToken = jwt.decode(token);
  const userRole = UTILS.getHighestUserRole(decodedToken.roles);
  const additionalPermissions = decodedToken.additionalPermissions;
  return {
    loggedIn: true,
    user: {
      role: userRole,
      id: decodedToken.id,
      additionalPermissions,
      serialMailLimit: user && user.serialMailLimit,
      serialMailCount,
      canClearCandidates67: user && user.canClearCandidates67,
      partnerId: user && user.partnerId,
      canAccessExportFeature: user && user.canAccessExportFeature
    }
  };
}
