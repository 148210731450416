import React from 'react';
import { List, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import LinkListItem from './LinkListItem';

/**
 * @typedef Props
 * @property {import('./LinkListItem').LinkItem[]} [links]
 *
 * @property {(link: import('./LinkListItem').LinkItem) => void} [onLinkDelete]
 * @property {(link: import('./LinkListItem').LinkItem) => void} [onLinkClick]
 * @property {(link: import('./LinkListItem').LinkItem) => void} [onLinkUpdate]
 * @property {(link: import('./LinkListItem').LinkItem) => void} [onLinkOpen]
 */

/**
 * This will handle link list
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
const LinkList = ({ links, onLinkClick, onLinkDelete, onLinkUpdate, onLinkOpen }) => (
  <List dense>
    {links !== null && links.length > 0 ? (
      links.map(link => (
        <LinkListItem
          linkItem={link}
          onClick={onLinkClick}
          onDelete={onLinkDelete}
          onUpdate={onLinkUpdate}
          onOpen={onLinkOpen}
          key={link.id}
        />
      ))
    ) : (
      <Typography>Kein Links</Typography>
    )}
  </List>
);

LinkList.propTypes = {
  links: PropTypes.array,
  onLinkDelete: PropTypes.func,
  onLinkClick: PropTypes.func,
  onLinkUpdate: PropTypes.func,
  onLinkOpen: PropTypes.func
};

LinkList.defaultProps = {
  links: null,
  onLinkDelete: () => {},
  onLinkClick: () => {},
  onLinkUpdate: () => {},
  onLinkOpen: () => {}
};

export default LinkList;
