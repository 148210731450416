import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ValidatedTextField from '../generic_components/ValidatedTextField';

import '../generic_components/ConfirmationModal.css';

import TEXT from '../text';
// import * as CONSTANTS from '../constants';
// import * as CONFIG from '../config';
// import * as UTILS from '../utils/utilFunctions';

class ConfirmNewProjectPopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
          <div className="confirmation-dialog">
            <DialogTitle>
              <div className="confirmation-dialog-title">
                <i className="material-icons confirmation-dialog-title-icon pb-3">{this.props.icon}</i>
                {this.props.headlineText}
              </div>
            </DialogTitle>
            <DialogContent id="confirmation-dialog-content">
              <DialogContentText>
                <div className="userResetConfirmation-descriptionText">
                  <p>
                    Wollen Sie wirklich einen neuen Auftrag für die folgende Firma anlegen?
                    <div className="userDeleteConfirmation-contentText">{this.props.selectedCompany.name}</div>
                  </p>
                </div>
                <p>
                  Die Auftragsnummer wird automatisch generiert. Optional können Sie eine Auftragsnummer wählen, die noch nicht vergeben
                  ist:
                  <ValidatedTextField label="Auftragsnummer" onChange={this.props.onProjectNumberChange} customClass="col-12" fullWidth />
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="confirmation-dialog-actions">
              <Button onClick={this.props.handleClose} color="secondary">
                {this.props.buttonSecondaryText}
              </Button>
              <Button
                disabled={this.props.primaryButtonIsDisabled}
                onClick={this.props.buttonPrimaryAction}
                color="secondary"
                variant="contained"
              >
                {this.props.buttonPrimaryText}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}
ConfirmNewProjectPopUp.defaultProps = {
  headlineText: TEXT.ConfirmationModal.headlineText,
  buttonPrimaryText: TEXT.ConfirmationModal.buttonPrimaryText,
  buttonSecondaryText: TEXT.ConfirmationModal.buttonSecondaryText,
  icon: 'help_outline'
};
ConfirmNewProjectPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  primaryButtonIsDisabled: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  buttonPrimaryAction: PropTypes.func.isRequired,
  headlineText: PropTypes.string,
  //   descriptionText: PropTypes.string,
  //   contentText: PropTypes.object,
  buttonPrimaryText: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  icon: PropTypes.string,
  onProjectNumberChange: PropTypes.func.isRequired,
  selectedCompany: PropTypes.object.isRequired
};
export default ConfirmNewProjectPopUp;
