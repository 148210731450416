const PARTNER = require('../../constants').partner;
const COUNTRY_DROPDOWN_CONTENT = require('../../general/staticContent/countryDropDown');

module.exports = {
  getDropDownValues(employees, partner, contactType, statusType, industrySectorList, companyStatusType, directContactStatusList) {
    const countries = COUNTRY_DROPDOWN_CONTENT;

    const result = [
      {
        fieldId: '_id',
        label: 'Unternehmens ID',
        fieldType: 'textField'
      },
      {
        fieldId: 'counterParts.contactInfo.mailAddress',
        label: 'E-Mail (Ansprechpartner)',
        fieldType: 'textField'
      },
      {
        fieldId: 'industrySector',
        label: 'Industriesektor',
        fieldType: 'dropDown',
        dropDownValues: industrySectorList
      },
      {
        fieldId: 'creationDate',
        label: 'Eingegeben am',
        fieldType: 'datePicker'
      },
      {
        fieldId: 'inputLocationSpecialCase',
        label: 'Ort der Eingabe',
        fieldType: 'dropDown',
        dropDownValues: partner
      },
      {
        fieldId: 'status.partner',
        label: 'Status Standort',
        fieldType: 'dropDown',
        dropDownValues: partner
      },
      {
        fieldId: 'companyChangeHistory.changes.user',
        label: 'geändert durch',
        fieldType: 'dropDown',
        dropDownValues: employees
      },
      {
        fieldId: 'pastContacts.contactType',
        label: 'Art der Kontaktaufnahme',
        fieldType: 'dropDown',
        dropDownValues: contactType
      },
      {
        fieldId: 'advisor',
        label: 'Berater',
        fieldType: 'dropDown',
        dropDownValues: employees
      },
      {
        fieldId: 'status.statusType',
        label: 'Status',
        fieldType: 'dropDown',
        dropDownValues: statusType
      },
      {
        fieldId: 'counterParts.firstName',
        label: 'Vorname (Ansprechpartner)',
        fieldType: 'textField'
      },
      {
        fieldId: 'counterParts.lastName',
        label: 'Nachname (Ansprechpartner)',
        fieldType: 'textField'
      },
      {
        fieldId: 'address.city',
        label: 'Ort',
        fieldType: 'textField'
      },
      {
        fieldId: 'address.postalCode',
        label: 'PLZ',
        fieldType: 'textField'
      },
      {
        fieldId: 'address.country',
        label: 'Land',
        fieldType: 'dropDown',
        dropDownValues: countries
      },
      {
        fieldId: 'directContact',
        label: 'Direktansprache ',
        fieldType: 'dropDown',
        dropDownValues: directContactStatusList
      },
      {
        fieldId: 'name',
        label: 'Unternehmen',
        fieldType: 'textField'
      },
      {
        fieldId: 'pastContacts.companyStatus',
        label: 'Ergebnis',
        fieldType: 'dropDown',
        dropDownValues: companyStatusType
      },
      {
        fieldId: 'counterParts.position',
        label: 'Funktion (Ansprechpartner)',
        fieldType: 'textField'
      }
    ];
    if (PARTNER === 'lienert') {
      const lienertAdditions = [
        {
          fieldId: 'category',
          label: 'Kategorie',
          fieldType: 'textField'
        },
        {
          fieldId: 'counterParts.advisor',
          label: 'Betreuer',
          fieldType: 'dropDown',
          dropDownValues: employees
        }
      ];
      lienertAdditions.forEach(item => {
        result.push(item);
      });
    } else {
      const standardAdditions = [
        {
          fieldId: 'pastContacts.intermediary._id',
          label: 'Akquisiteur',
          fieldType: 'dropDown',
          dropDownValues: employees
        }
      ];
      standardAdditions.forEach(item => {
        result.push(item);
      });
    }
    return result;
  }
};
