import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, Divider } from '@material-ui/core';
import CategoryNameMapper from './CategoryNameMapper';

class InterimPositionCriteria extends PureComponent {
  render() {
    return (
      <div>
        <Typography variant="h5" component="h5">
          Erfahrung / Tagessatz
        </Typography>
        <Divider className="mb-3" />

        <CategoryNameMapper name="Branchen" inputList={this.props.industrySectors} />

        <CategoryNameMapper name="Funktionen" inputList={this.props.positionDescription} />

        <CategoryNameMapper
          name="Hierarchieebenen"
          inputList={this.props.hierarchyLevels.map(entry => {
            const arr = entry.split(':');
            return { label: arr[1], category: arr[0] };
          })}
        />

        <Typography variant="h6" component="h6" className="pb-3">
          Mindesttagessatz:{' '}
          {this.props.dailyRate < 3001
            ? `${Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR'
              }).format(this.props.dailyRate)}`
            : `über ${Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: 'EUR'
              }).format(3000)}`}
        </Typography>

        <Typography variant="h6" component="h6" className="pb-3">
          Interesse an einer permanenter Festanstellung: {this.props.isPermanentEmployment ? 'Ja' : 'Nein'}
        </Typography>
      </div>
    );
  }
}

InterimPositionCriteria.propTypes = {
  industrySectors: PropTypes.arrayOf(PropTypes.object).isRequired,
  positionDescription: PropTypes.arrayOf(PropTypes.object).isRequired,
  dailyRate: PropTypes.number.isRequired,
  hierarchyLevels: PropTypes.arrayOf(PropTypes.string).isRequired,
  isPermanentEmployment: PropTypes.bool.isRequired
};

export default InterimPositionCriteria;
