// base imports
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as CONSTANTS from '../constants';
import * as UTILS from '../utils/utilFunctions';

class DetailSearchSaveQueryBox extends PureComponent {
  constructor(props) {
    super(props);
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.state = {
      queryName: '',
      isDisabled: false
    };
  }

  saveDetailSearchAPICall = () => {
    const url = CONSTANTS.saveDetailSearchURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const payload = {
      detailSearchType: this.props.detailSearchType,
      detailSearchQuery: this.props.detailSearchQuery,
      detailSearchName: this.state.queryName
    };
    this.setState({
      isDisabled: true
    });
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(payload),
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: 90000
    })
      .done(response => {
        console.log('saveDetailSearchAPICall_RESPONSE', response);
        this.props.handleClose();
        this.setState({
          queryName: '',
          isDisabled: false
        });
        this.props.handleSnackbarOpen('Ihre Detailsuche wurde erfolgreich gespeichert');
      })
      .fail(err => {
        this.setState({
          isDisabled: false
        });
        this.errorHandling(err, url);
      });
  };

  textFieldOnChange = e => {
    this.setState({
      queryName: e.target.value
    });
  };

  render() {
    return (
      <Dialog open={this.props.open} aria-labelledby="saveDetailSearch-dialog-title">
        <DialogTitle id="saveDetailSearch-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{this.props.description}</DialogContentText>
          <div className="row">
            <div className="col-12 pb-3 pt-1">
              <TextField
                fullWidth
                onChange={this.textFieldOnChange}
                value={this.state.queryName}
                autoFocus
                label={this.props.placeholderText}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.props.handleClose}>
            {this.props.cancelText}
          </Button>
          <Button color="primary" onClick={this.saveDetailSearchAPICall} disabled={this.state.isDisabled}>
            {this.props.saveText}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

DetailSearchSaveQueryBox.defaultProps = {
  cancelText: 'cancel',
  saveText: 'save',
  title: 'Input custom title here',
  description: 'Input custom description here',
  placeholderText: 'Placeholder text'
};

DetailSearchSaveQueryBox.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  detailSearchType: PropTypes.string.isRequired,
  detailSearchQuery: PropTypes.object.isRequired,
  cancelText: PropTypes.string,
  saveText: PropTypes.string,
  placeholderText: PropTypes.string,
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default DetailSearchSaveQueryBox;
