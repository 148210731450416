/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import PropTypes from 'prop-types';
import { Typography, Dialog, AppBar, Toolbar, IconButton, Fade, CircularProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { downloadBlobFile } from '../../utils/utilFunctions';

const styles = {
  modalStyle1: {
    position: 'absolute',
    top: '10%',
    left: '10%',
    textAlign: 'center',
    overflow: 'scroll',
    height: '100%',
    display: 'block'
  },
  pdfPaper: {
    backgroundColor: 'transparent !important'
  },
  appBarDrawer: {
    backgroundColor: '#222'
  },
  appBarTitle: {
    marginLeft: '1em',
    flex: 1
  }
};
const Transition = React.forwardRef((props, ref) => <Fade direction="up" ref={ref} {...props} />);

class PdfViewerModal extends Component {
  state = {
    numPages: null,
    currentDoc: undefined
  };

  componentDidMount() {
    this.getFileUrl();
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };

  download = async file => {
    const fileUrl = await this.props.onResolveFileUrl(file);
    downloadBlobFile(fileUrl);
  };

  displayPdfDocumentPages(numPages) {
    const pageList = [];
    for (let i = 1; i <= numPages; i++) {
      pageList.push(
        <Page
          style={{ display: 'flex', justifyContent: 'center' }}
          className="mx-auto mb-3"
          pageNumber={i}
          renderAnnotations={false}
          renderTextLayer={false}
        />
      );
    }
    return pageList;
  }

  getFileUrl = async () => {
    console.log('filestring', this.props.file);
    const fileUrl = await this.props.onResolveFileUrl(this.props.file);

    this.setState({ currentDoc: fileUrl });
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        PaperProps={{ className: classes.pdfPaper }}
        fullScreen
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionComponent={Transition}
      >
        <AppBar className={classes.appBarDrawer}>
          <Toolbar>
            <Typography variant="h6" className={classes.appBarTitle}>
              {this.props.file}
            </Typography>
            <IconButton color="inherit" onClick={() => this.download(this.props.file)}>
              <CloudDownloadIcon />
            </IconButton>
            <IconButton color="inherit" onClick={this.props.onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Toolbar className="mb-3" />

        <Document
          file={this.state.currentDoc}
          onLoadSuccess={this.onDocumentLoadSuccess}
          loading={<CircularProgress />}
          className="mx-auto"
          renderMode='canvas'
        >
          {this.displayPdfDocumentPages(this.state.numPages)}
        </Document>
      </Dialog>
    );
  }
}

PdfViewerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  onResolveFileUrl: PropTypes.func.isRequired,
  file: PropTypes.string.isRequired
};
export default withStyles(styles)(PdfViewerModal);
