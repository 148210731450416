import { Chip, MenuItem, Select, FormControl, InputLabel } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { WorkingTime } from '../staticContent/stepstoneCategories';

function mapLabel(id) {
  if (!id) {
    return '';
  }
  const workingTime = WorkingTime.find(wt => wt.id === id);

  return workingTime ? workingTime.label : '';
}

/**
 * @typedef WorkingType
 * @property {number} id
 * @property {string} label
 */

/**
 * @typedef Props
 * @property {WorkingType[]} currentList
 * @property {(newList: WorkingType[]) => void } onSelect
 * @property {string} label
 */

/**
 *
 * @param {Props} param0
 * @returns
 */
const WorkingTypeSelect = ({ currentList, onSelect, label }) => {
  const handleChangeSelect = useCallback(
    /**
     *
     * @param {React.ChangeEvent<{name?: string | undefined; value: unknown}>} event
     */
    event => {
      const workingTypes = event.target.value.map(id => ({ id, label: mapLabel(id) })).filter(wt => wt.id && wt.label);
      onSelect(workingTypes);
    },
    [onSelect]
  );
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        label="Arbeitszeit"
        fullWidth
        multiple
        onChange={handleChangeSelect}
        value={currentList.map(wt => wt.id)}
        renderValue={selected => (
          <div className="w-100">
            {Array.isArray(selected) && selected.map(value => <Chip style={{ margin: '1px' }} key={value} label={mapLabel(value)} />)}
          </div>
        )}
      >
        {WorkingTime.map(wt => (
          <MenuItem key={wt.id} value={wt.id}>
            {wt.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

WorkingTypeSelect.propTypes = {
  currentList: PropTypes.array,
  onSelect: PropTypes.func,
  label: PropTypes.string
};

WorkingTypeSelect.defaultProps = {
  currentList: [],
  label: '',
  onSelect: () => {}
};

export default WorkingTypeSelect;
