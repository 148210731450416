import { IconButton } from '@material-ui/core';
import { Done, OpenInNew } from '@material-ui/icons';
import ReadIcon from '@material-ui/icons/Drafts';
import UnreadIcon from '@material-ui/icons/Mail';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import useNotification from './hooks/Notification';
import markNotificationAsRead from './api/NotificationApi';

/**
 * @typedef Props
 * @property {() => void} handleSnackbarOpen
 * @property {Array} notifications
 * @property {React.SetStateAction} liftUpToState
 * @property {() => void} refreshNotifications
 *
 */

/**
 * @param {Props}
 * @returns
 */
const NotificationsSenatorV2 = ({ notifications, refreshNotifications }) => {
  const { mappedNotification } = useNotification({ notifications });

  const handleOnRowClick = useCallback(
    (id, isRead) => () => {
      if (!isRead) {
        markNotificationAsRead([id]).then(() => {
          refreshNotifications();
        });
      }
    },
    [refreshNotifications]
  );

  const COLUMNS = useMemo(
    () => [
      {
        name: 'id',
        label: 'id',
        options: {
          display: 'excluded',
          sort: false,
          filter: false
        }
      },
      {
        name: 'isRead',
        options: {
          sort: false,
          filter: false,
          display: 'excluded'
        }
      },
      {
        name: 'isRead',
        label: 'Gelesen',
        options: {
          sort: true,
          filter: true,
          customBodyRender: value => {
            if (value === undefined) {
              return null;
            }

            return value ? <ReadIcon /> : <UnreadIcon />;
          }
        }
      },
      {
        name: 'triggerDate',
        label: 'Datum',
        options: {
          sort: true,
          filter: true,
          customBodyRender: value => {
            if (!value) {
              return '';
            }

            return `${moment(value).format('DD.MM.YYYY - HH:mm')} Uhr`;
          }
        }
      },
      {
        name: 'title',
        label: 'Titel',
        options: {
          sort: true,
          filter: true
        }
      },
      {
        name: 'message',
        label: 'Nachricht',
        options: {
          sort: false,
          filter: false
        }
      },
      {
        name: 'actions',
        label: 'Aktionen',
        options: {
          sort: false,
          filter: false,
          customBodyRender: (value, tableMeta) => {
            const { rowData } = tableMeta;
            if (value) {
              return (
                <Link to={value}>
                  <IconButton onClick={handleOnRowClick(rowData[0], rowData[1])}>
                    <OpenInNew />
                  </IconButton>
                </Link>
              );
            }
            if (!value && !rowData[1]) {
              return (
                <IconButton onClick={handleOnRowClick(rowData[0], rowData[1])}>
                  <Done />
                </IconButton>
              );
            }
            return null;
          }
        }
      }
    ],
    [handleOnRowClick]
  );

  return (
    <div className="w-100">
      <SafeMuiDatatable columns={COLUMNS} data={mappedNotification} />
    </div>
  );
};

NotificationsSenatorV2.propTypes = {
  notifications: PropTypes.array,
  refreshNotifications: PropTypes.func.isRequired
};

NotificationsSenatorV2.defaultProps = {
  notifications: []
};

export default NotificationsSenatorV2;
