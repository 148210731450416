import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import { urlify, stringToShortenHTMLCode } from '../utils/utilFunctions';
import ViewHtmlPopUp from '../generic_components/ViewHtmlPopUp';

export default class HTMLNotes extends PureComponent {
  state = {
    popupOpen: false
  };

  handleOpenPopUp = ev => {
    this.setState({ popupOpen: true });
    ev.stopPropagation();
  };

  handleClosePopUp = ev => {
    this.setState({ popupOpen: false });
    ev.stopPropagation();
  };

  containsImage = html => html.includes('<img');

  render() {
    const urlifiedValue = urlify(this.props.html);
    return (
      // eslint-disable-next-line
      <div style={{ cursor: 'pointer' }} onClick={this.handleOpenPopUp}>
        {this.props.html.trim().length > 0 && this.containsImage(this.props.html) ? (
          <Tooltip title="Um Inhalt anzuzeigen, hier klicken">
            <InfoIcon />
          </Tooltip>
        ) : (
          stringToShortenHTMLCode(urlifiedValue.replace(/<[^>]+>/g, ''), this.props.maxLength, true)
        )}
        <ViewHtmlPopUp
          open={this.state.popupOpen}
          html={this.props.html}
          handleClose={this.handleClosePopUp}
          onBackdropClick={this.handleClosePopUp}
        />
      </div>
    );
  }
}

HTMLNotes.defaultProps = {
  maxLength: 250
};

HTMLNotes.propTypes = {
  html: PropTypes.string.isRequired,
  maxLength: PropTypes.number
};
