import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withRouter, Link } from 'react-router-dom';

import $ from 'jquery';
import CandidateDetails from './CandidateDetails';
import ProjectMatchingForm from '../projectArchive/ProjectMatchingForm';

import CandidateProfile from './CandidateProfile';
import CandidateDossier from './CandidateDossier';
import CandidateStatus from './CandidateStatus';
import CandidateApplications from './CandidateApplications';
import CandidatePastContactsTab from './CandidatePastContactsTab';
import CandidateInterimProfile from './CandidateInterimProfile';
import * as CONSTANTS from '../constants';
import * as UTILS from '../utils/utilFunctions';
import * as CONFIG from '../config';
import CandidateProjectDossierList from './CandidateProjectDossierList';
import CandidateDocumentsV2 from './CandidateDocumentsV2';
import ProjectCandidateDataEdit from './ProjectCandidateDataEdit';
import LinkCandidatePanel from './LinkPanel';

class SingleCandidateResult extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pdfErrorMessage: '',
      documentLoading: false,
      openProjectEdit: false,
      selectedProjectId: ''
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  onDocumentDrop = (acceptedFiles, callback) => {
    const url = CONSTANTS.uploadCandidateDocumentURL;
    const candidateId = this.props.selectedCandidate.id;
    this.setState({ pdfErrorMessage: '', documentLoading: true });

    const request = new FormData();
    acceptedFiles.forEach((file, index) => {
      request.append(`file-${index}`, file);
    });
    request.append('candidateId', candidateId);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      contentType: false,
      processData: false,
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: request
    })
      .done(responseBody => {
        callback({
          taggedDocuments: [...this.props.selectedCandidate.taggedDocuments, ...responseBody.taggedDocuments]
        });

        this.props.liftUpStateToCandidateArchive({
          selectedCandidate: {
            ...this.props.selectedCandidate,
            taggedDocuments: [...this.props.selectedCandidate.taggedDocuments, ...responseBody.taggedDocuments]
          }
        });

        this.setState({
          documentLoading: false
        });
      })
      .fail(err => {
        const ignoreErrorCodes = [0, 413, 415];
        if (err.status === 413 || err.status === 0) {
          this.setState({
            pdfErrorMessage: 'Datei zu groß (maximale Größe 16mb)',
            documentLoading: false
          });
        }
        if (err.status === 415) {
          this.setState({
            pdfErrorMessage: 'Nicht unterstütztes Dateiformat',
            documentLoading: false
          });
        }
        this.setState({
          documentLoading: false
        });
        this.errorHandling(err, url, null, ignoreErrorCodes);
      });
  };

  updateDocumentName = (fileName, newFileName, documentId) => {
    const url = CONSTANTS.updateCandidateDocumentNameURL;

    let taggedDocumentId = this.props.selectedCandidate.taggedDocuments.filter(doc => doc.documentName === fileName)[0].id;
    if (documentId) {
      taggedDocumentId = this.props.selectedCandidate.taggedDocuments.filter(doc => doc.id === documentId)[0].id;
    }

    const documentRequest = {
      candidateId: this.props.selectedCandidate.id,
      taggedDocumentId,
      fileName,
      newFileName
    };
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      headers: { 'x-auth': tokenFromLocalStorage },
      contentType: 'application/json',
      data: JSON.stringify(documentRequest)
    })
      .done(response => {
        if (response.status === 'success') {
          // taggedDocument
          const newTaggedDocuments = this.props.selectedCandidate.taggedDocuments.map(doc => {
            if (doc.id === taggedDocumentId) {
              return { ...doc, documentName: newFileName };
            }
            return doc;
          });
          this.props.liftUpStateToCandidateArchive({
            selectedCandidate: {
              ...this.props.selectedCandidate,
              taggedDocuments: newTaggedDocuments
            }
          });
        }
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        this.errorHandling(err, url);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  };

  handleTagDocument = (fileName, tag, isTaggedDocument) => {
    const url = CONSTANTS.updateCandidateDocumentTagURL;
    const documentRequest = {
      candidateId: this.props.selectedCandidate.id,
      fileName,
      tag,
      isTaggedDocument
    };

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      headers: { 'x-auth': tokenFromLocalStorage },
      contentType: 'application/json',
      data: JSON.stringify(documentRequest)
    })
      .done(response => {
        console.log(response);
      })
      .fail(err => {
        console.log(err);
        this.errorHandling(err, url);
      });
  };

  renderLienertTabs = () => (
    <Tabs
      value={this.props.currentTab}
      onChange={(event, value) => {
        UTILS.setURLParam(this.props.history, 't', value);

        this.props.liftUpStateToCandidateArchive({ currentTab: value });
      }}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab label="Profildaten" />
      <Tab label="Historie" />
      <Tab label="OriginalDateien" />
      <Tab label="Dossier" />
      <Tab label="Matching Profil" />
    </Tabs>
  );

  renderStandardTab = () => (
    <Tabs
      value={this.props.currentTab}
      onChange={(event, value) => {
        UTILS.setURLParam(this.props.history, 't', value);

        this.props.liftUpStateToCandidateArchive({ currentTab: value });
      }}
      indicatorColor="primary"
      textColor="primary"
      centered
    >
      <Tab label="Profildaten" />
      <Tab label="OriginalDateien" />
      <Tab label="Vorgeschlagen auf" />
      <Tab label="Beworben auf" />
      <Tab label="Kontakthistorie" />
      <Tab label="Eignungsdiagnostik" />
      <Tab label="Dossier" />
      {(CONSTANTS.isMatchflix || CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) && <Tab label="Auftrag Dossier Liste" />}
      {CONSTANTS.isSenatorInterim && <Tab label="Interim" />}
    </Tabs>
  );

  renderTabs = () => {
    if (CONSTANTS.isLienert) {
      return this.renderLienertTabs();
    }
    return this.renderStandardTab();
  };

  renderTabContent = () => {
    if (CONSTANTS.isLienert) {
      return (
        <div className="col-12 mt-3 plr-3">
          {this.props.currentTab === 0 && this.renderProfileTab()}
          {this.props.currentTab === 1 && (
            <React.Fragment>
              <div className="mb-3">{this.renderAppliedTab()}</div>
              <div className="mb-3">{this.renderProposedTab()}</div>
              <div className="mb-3">{this.renderPastContactsTab()}</div>
            </React.Fragment>
          )}
          {this.props.currentTab === 2 && this.renderDocumentsTab()}
          {this.props.currentTab === 3 && this.renderDossierTab()}
          {this.props.currentTab === 4 && this.renderMatchingProfileTab()}
        </div>
      );
    }
    return (
      <div className="col-12 mt-3 plr-3">
        {this.props.currentTab === 0 && this.renderProfileTab()}
        {this.props.currentTab === 1 && this.renderDocumentsTab()}
        {this.props.currentTab === 2 && this.renderProposedTab()}
        {this.props.currentTab === 3 && this.renderAppliedTab()}
        {this.props.currentTab === 4 && this.renderPastContactsTab()}
        {this.props.currentTab === 5 && this.renderDiagnosticsTab()}
        {this.props.currentTab === 6 && this.renderDossierTab()}
        {this.props.currentTab === 7 &&
          (CONSTANTS.isMatchflix || CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) &&
          this.renderProjectDossierListTab()}
        {this.props.currentTab === 7 && CONSTANTS.isSenatorInterim && this.renderInterimProfile()}
      </div>
    );
  };

  renderProfileTab = () => {
    if (!this.props.selectedCandidate) return '';
    return (
      <CandidateProfile
        key={`${this.props.selectedCandidate.id}CandidateProfile`}
        selectedCandidate={this.props.selectedCandidate}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshCandidate={this.props.refreshCandidate}
        handleOpenCandidateJobUpdatePopUp={this.props.handleOpenCandidateJobUpdatePopUp}
        handleOpenCandidateEducationUpdatePopUp={this.props.handleOpenCandidateEducationUpdatePopUp}
        handleOpenCandidateSkillUpdatePopUp={this.props.handleOpenCandidateSkillUpdatePopUp}
        handleOpenCandidateLanguageUpdatePopUp={this.props.handleOpenCandidateLanguageUpdatePopUp}
        handleOpenCandidatePastContactUpdatePopUp={this.props.handleOpenCandidatePastContactUpdatePopUp}
        liftUpStateToApp={this.props.liftUpStateToApp}
      />
    );
  };

  renderDocumentsTab = () => (
    <>
      <CandidateDocumentsV2
        key={`${this.props.selectedCandidate.id}documents`}
        selectedCandidate={this.props.selectedCandidate}
        handleTagDocument={this.handleTagDocument}
        onDrop={this.onDocumentDrop}
        updateDocumentName={this.updateDocumentName}
        pdfErrorMessage={this.state.pdfErrorMessage}
        documentLoading={this.state.documentLoading}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        liftUpStateToApp={this.props.liftUpStateToApp}
        candidateArchiveStateUplift={newDocuments =>
          this.props.liftUpStateToCandidateArchive({
            selectedCandidate: {
              ...this.props.selectedCandidate,
              taggedDocuments: [...newDocuments]
            }
          })
        }
      />
      {(CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) && (
        <LinkCandidatePanel
          links={this.props.selectedCandidate.customLinks}
          currentCandidate={this.props.selectedCandidate}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          refreshCandidate={this.props.refreshCandidate}
        />
      )}
    </>
  );

  renderProposedTab = () => {
    if (!this.props.selectedCandidate.projects) return '';
    return (
      <CandidateStatus
        key={`${this.props.selectedCandidate.id}status`}
        data={this.props.selectedCandidate.projects}
        selectedCandidateId={this.props.selectedCandidate.id}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        liftUpUpdatedPotentialCandidates={this.props.liftUpUpdatedPotentialCandidates}
        refreshCandidate={this.props.refreshCandidate}
        liftUpStateToApp={this.props.liftUpStateToApp}
        openEntryEdit={projectId => {
          this.setState({ openProjectEdit: true, selectedProjectId: projectId });
        }}
      />
    );
  };

  renderAppliedTab = () => (
    <CandidateApplications
      key={`${this.props.selectedCandidate.id}applications`}
      data={this.props.selectedCandidate.projects}
      selectedCandidate={this.props.selectedCandidate}
      selectedCandidateId={this.props.selectedCandidate.id}
      refreshCandidate={this.props.refreshCandidate}
      liftUpStateToApp={this.props.liftUpStateToApp}
      handleSnackbarOpen={this.props.handleSnackbarOpen}
      liftUpUpdatedPotentialCandidates={this.props.liftUpUpdatedPotentialCandidates}
    />
  );

  renderPastContactsTab = () => (
    <CandidatePastContactsTab
      key={`${this.props.selectedCandidate.id}pastContacts`}
      data={this.props.selectedCandidate.pastContacts}
      selectedCandidateId={this.props.selectedCandidate.id}
      handleSnackbarOpen={this.props.handleSnackbarOpen}
      refreshCandidate={this.props.refreshCandidate}
      liftUpStateToApp={this.props.liftUpStateToApp}
      handleOpenCandidatePastContactUpdatePopUp={this.props.handleOpenCandidatePastContactUpdatePopUp}
      liftSubschemaToCandidateArchive={this.props.liftSubschemaToCandidateArchive}
    />
  );

  renderDiagnosticsTab = () => (
    <CandidateDocumentsV2
      key={`${this.props.selectedCandidate.id}diagnostics${this.props.selectedCandidate.taggedDocuments &&
        this.props.selectedCandidate.taggedDocuments.length}${this.props.selectedCandidate.documents &&
        this.props.selectedCandidate.documents.length}`}
      selectedCandidate={this.props.selectedCandidate}
      handleTagDocument={this.handleTagDocument}
      onDrop={this.onDocumentDrop}
      pdfErrorMessage={this.state.pdfErrorMessage}
      documentLoading={this.state.documentLoading}
      handleSnackbarOpen={this.props.handleSnackbarOpen}
      liftUpStateToApp={this.props.liftUpStateToApp}
      candidateArchiveStateUplift={newDocuments =>
        this.props.liftUpStateToCandidateArchive({
          selectedCandidate: {
            ...this.props.selectedCandidate,
            taggedDocuments: [...newDocuments]
          }
        })
      }
      isDiagnostics
    />
  );

  renderDossierTab = () => (
    <CandidateDossier
      key={`${this.props.selectedCandidate.id}dossier${this.props.selectedCandidate.taggedDocuments &&
        this.props.selectedCandidate.taggedDocuments.length}${this.props.selectedCandidate.documents &&
        this.props.selectedCandidate.documents.length}`}
      selectedCandidate={this.props.selectedCandidate}
      pdfErrorMessage={this.state.pdfErrorMessage}
      documentLoading={this.state.documentLoading}
      handleSnackbarOpen={this.props.handleSnackbarOpen}
      liftUpStateToApp={this.props.liftUpStateToApp}
      employees={this.props.employees}
    />
  );

  renderProjectDossierListTab = () => <CandidateProjectDossierList candidateId={this.props.selectedCandidate.id} />;

  renderInterimProfile = () => <CandidateInterimProfile interimProfile={this.props.selectedCandidate.externalProfile} />;

  renderMatchingProfileTab() {
    if (this.props.selectedCandidate && this.props.selectedCandidate.profileData) {
      if (this.props.selectedCandidate.candidateDB) {
        return (
          <div>
            {this.props.selectedCandidate.matchedProjectList && (
              <div className="container">
                <div className="row pl-2 pr-2">
                  <div className="col-6">
                    <p>Aufträge zu denen ein Matching stattgefunden hat:</p>
                  </div>
                  <div className="col-6">
                    <p>Datum des Matches:</p>
                  </div>
                </div>
                {this.props.selectedCandidate.matchedProjectList.map(matchedProject => (
                  <div className="row pl-2 pr-2">
                    <div className="col-6">
                      <Link to={`projects?id=${matchedProject.id}`}>{matchedProject.projectNumber}</Link>
                    </div>
                    <div className="col-6">{UTILS.formatStringToDELocale(matchedProject.dateOfMatch)}</div>
                  </div>
                ))}{' '}
              </div>
            )}
            <ProjectMatchingForm
              key={`${this.props.selectedCandidate.id}matching`}
              selectedProjectId="noProject"
              data={this.props.selectedCandidate.profileData}
              handleSnackbarOpen={this.props.handleSnackbarOpen}
              inCandiateArchive
            />
          </div>
        );
      }
      return (
        <div className="sideBarWrapper p-2">
          <div className="candidateArchive-emptyPage">Matching Profil ist vorhanden.</div>
        </div>
      );
    }
    return (
      <div className="sideBarWrapper p-2">
        <div className="candidateArchive-emptyPage">Es wurde noch kein Matching Profil hinterlegt.</div>
      </div>
    );
  }

  render() {
    const mainColumnClass = this.props.duplicateCheckMode ? 'col-8 pl-0' : 'col-10  pl-0';
    return (
      <>
        <div className="SingleCandidateResult row">
          {this.props.duplicateCheckMode && (
            <div className="col-2 pr-0">
              <div className="col-12 mt-3 pr-0">
                <CandidateDetails
                  duplicateCheckMode
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  // handleDuplicateCheckMerge={
                  //   this.props.handleDuplicateCheckMerge
                  // }
                  handleDuplicateCheckCreateNew={this.props.handleDuplicateCheckCreateNew}
                  selectedCandidate={this.props.incomingCandidate}
                  candidateChangeHistoryId={this.props.selectedCandidate.candidateChangeHistoryId}
                  liftUpStateToCandidateArchive={this.props.liftUpStateToCandidateArchive}
                  handleOpenCandidateMainUpdatePopUp={this.props.handleOpenCandidateMainUpdatePopUp}
                  refreshCandidate={this.props.refreshCandidate}
                  liftUpStateToApp={this.props.liftUpStateToApp}
                  openContactHistoryForPhone={this.props.openContactHistoryForPhone}
                />
              </div>
            </div>
          )}
          {this.props.showCandidateDetailsColumn && (
            <div className="col-2 pr-0">
              <div className="col-12 mt-3 pr-0">
                <CandidateDetails
                  locked={this.props.duplicateCheckMode}
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  selectedCandidate={this.props.selectedCandidate}
                  candidateChangeHistoryId={this.props.selectedCandidate.candidateChangeHistoryId}
                  liftUpStateToCandidateArchive={this.props.liftUpStateToCandidateArchive}
                  handleOpenCandidateMainUpdatePopUp={this.props.handleOpenCandidateMainUpdatePopUp}
                  handleOpenPrivacyMailConfirmationModal={this.props.handleOpenPrivacyMailConfirmationModal}
                  handleDuplicateCheckResumeWithDuplicate={this.props.handleDuplicateCheckResumeWithDuplicate}
                  refreshCandidate={this.props.refreshCandidate}
                  liftUpStateToApp={this.props.liftUpStateToApp}
                  openContactHistoryForPhone={this.props.openContactHistoryForPhone}
                />
              </div>
            </div>
          )}

          <div className={mainColumnClass}>
            <div className="row navidation">
              <div className="col-12">{this.renderTabs()}</div>
            </div>
            {this.renderTabContent()}
          </div>
        </div>
        <ProjectCandidateDataEdit
          open={this.state.openProjectEdit}
          projectId={this.state.selectedProjectId}
          candidateId={this.props.selectedCandidate.id}
          onClose={() => {
            this.setState({ openProjectEdit: false, selectedProjectId: '' });
          }}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          refreshCandidate={this.props.refreshCandidate}
        />
      </>
    );
  }
}

SingleCandidateResult.defaultProps = {
  showCandidateDetailsColumn: true,
  currentTab: 0,
  refreshCandidate: undefined,
  liftUpStateToCandidateArchive: undefined,
  handleSnackbarOpen: undefined,
  handleOpenCandidateJobUpdatePopUp: undefined,
  handleOpenCandidateEducationUpdatePopUp: undefined,
  handleOpenCandidateSkillUpdatePopUp: undefined,
  handleOpenCandidateLanguageUpdatePopUp: undefined,
  handleOpenCandidateMainUpdatePopUp: undefined,
  handleOpenCandidatePastContactUpdatePopUp: undefined,
  handleOpenPrivacyMailConfirmationModal: undefined,
  duplicateCheckMode: false,
  incomingCandidate: undefined,
  handleDuplicateCheckCreateNew: undefined,
  handleDuplicateCheckResumeWithDuplicate: undefined
};
SingleCandidateResult.propTypes = {
  showCandidateDetailsColumn: PropTypes.bool,
  selectedCandidate: PropTypes.object.isRequired,
  refreshCandidate: PropTypes.func,
  liftUpStateToCandidateArchive: PropTypes.func,
  handleSnackbarOpen: PropTypes.func,
  handleOpenCandidateJobUpdatePopUp: PropTypes.func,
  handleOpenCandidateEducationUpdatePopUp: PropTypes.func,
  handleOpenCandidateSkillUpdatePopUp: PropTypes.func,
  handleOpenCandidateLanguageUpdatePopUp: PropTypes.func,
  handleOpenCandidateMainUpdatePopUp: PropTypes.func,
  handleOpenCandidatePastContactUpdatePopUp: PropTypes.func,
  handleOpenPrivacyMailConfirmationModal: PropTypes.func,
  handleDuplicateCheckCreateNew: PropTypes.func,
  handleDuplicateCheckResumeWithDuplicate: PropTypes.func,
  currentTab: PropTypes.number,
  duplicateCheckMode: PropTypes.bool,
  incomingCandidate: PropTypes.object,
  liftUpStateToApp: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  liftUpUpdatedPotentialCandidates: PropTypes.func.isRequired,
  liftSubschemaToCandidateArchive: PropTypes.func.isRequired,
  openContactHistoryForPhone: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};
export default withRouter(SingleCandidateResult);
