import * as CONSTANTS from '../constants';

export default function isPermissionSet(user, permission) {
  let isPermitted = false;
  if (user.role === 'admin') return true;
  if (!permission) return false;

  const specificUserPermissions = [];
  if (user.additionalPermissions)
    user.additionalPermissions.forEach(entry => {
      if (entry.isSet) specificUserPermissions.push(entry.key);
    });
  if (specificUserPermissions.includes(permission)) isPermitted = true;
  return isPermitted;
}

export function isPermissionSetIgnoreAdmin(user, permission) {
  return (user.additionalPermissions || []).some(p => p.key === permission && p.isSet);
}

export function mayManageMatchflix(user) {
  return CONSTANTS.partner === 'matchflix' || isPermissionSetIgnoreAdmin(user, 'crossPartnerAdmin');
}
