const TOKEN_KEY = 'token';

export function readToken() {
  return window.sessionStorage.getItem(TOKEN_KEY);
}

export function storeToken(token) {
  window.sessionStorage.setItem(TOKEN_KEY, token);
}

function clearToken() {
  window.sessionStorage.removeItem(TOKEN_KEY);
}

// callback is called when a token becomes available, or after an arbitrarily chosen delay
export function syncStoredToken(callback) {
  let timeout;
  window.addEventListener('storage', event => {
    const credentials = readToken();
    if (event.key === 'REQUESTING_SHARED_CREDENTIALS' && credentials) {
      window.localStorage.setItem('CREDENTIALS_SHARING', credentials);
      window.localStorage.removeItem('CREDENTIALS_SHARING');
    }
    if (event.key === 'CREDENTIALS_SHARING' && !credentials) {
      storeToken(event.newValue);
      window.clearTimeout(timeout);
      callback();
    }
    if (event.key === 'CREDENTIALS_FLUSH' && credentials) {
      clearToken();
    }
  });

  if (readToken()) {
    callback();
  } else {
    timeout = window.setTimeout(callback, 100);
    window.localStorage.setItem('REQUESTING_SHARED_CREDENTIALS', Date.now().toString());
    window.localStorage.removeItem('REQUESTING_SHARED_CREDENTIALS');
  }
}

export function clearStoredToken() {
  window.localStorage.setItem('CREDENTIALS_FLUSH', Date.now().toString());
  window.localStorage.removeItem('CREDENTIALS_FLUSH');
  clearToken();
}
