import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Checkbox, ImageList, ImageListItem, ImageListItemBar, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
    borderColor: theme.palette.grey.A200,
    borderStyle: 'solid',
    borderWidth: 'thin',
    boxShadow: 'unset'
  },
  imageList: {
    width: '100%',
    height: 400
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)'
  }
}));

/**
 * @typedef ImageData
 * @property {string} img - the source to the image
 * @property {boolean} selected  - is the image selected
 * @property {string} title - the image title
 */

/**
 * With this component you can select pictures form a picture list.
 *
 * @param {ImageData[]} imageData
 * @param setImageDate
 * @return {JSX.Element}
 * @constructor
 */

const PictureSelect = ({ imageData, setImageDate }) => {
  const handleChangeImageProp = useCallback(
    (imageObj, event, index) => {
      const copyImageData = [...imageData];

      copyImageData.splice(index, 1, {
        img: imageObj.img,
        selected: event.target.checked,
        title: imageObj.title
      });
      setImageDate(copyImageData);
    },
    [imageData, setImageDate]
  );

  const classes = useStyles();
  return (
    <>
      <Typography>Bilderauswahl:</Typography>
      <div className={classes.root}>
        <ImageList rowHeight={200} gap={1} className={classes.imageList}>
          {(!imageData || imageData.length < 1) && <Typography>Keine Bilder vorhanden!</Typography>}
          {(imageData && imageData.length) > 0 &&
            imageData.map((image, index) => (
              <ImageListItem key={image.img} cols={1} rows={1}>
                <img src={image.img} alt={image.title} />
                <ImageListItemBar
                  className={classes.icon}
                  title={image.title}
                  position="bottom"
                  actionIcon={
                    <Checkbox
                      style={{ color: 'white' }}
                      checked={image.selected}
                      onChange={event => handleChangeImageProp(image, event, index)}
                    />
                  }
                />
              </ImageListItem>
            ))}
        </ImageList>
      </div>
    </>
  );
};

export default PictureSelect;

PictureSelect.defaultProps = {
  imageData: [{}]
};

PictureSelect.propTypes = {
  imageData: PropTypes.arrayOf(PropTypes.object),
  setImageDate: PropTypes.func.isRequired
};
