import { useCallback } from 'react';
import { createLink, deleteLink, updateLink } from '../api/intranetApi';

/**
 * @typedef Link
 * @property {string} id - unique link identifier
 * @property {string} name - name of the link
 * @property {string} url - e.g 'https://google.de'
 */

/**
 * @typedef Folder
 * @property {string} [id] - folder identifier
 * @property {string} [name] - folder name
 */

/**
 * This will return you a object of link api functions
 *
 * @param {{
 *  currentFolder: Folder,
 *  handleSnackbarOpen: (text: string) => void,
 *  refreshDocumentList: () => Promise<void>,
 * }} param - Hook parameters
 *
 * @returns {{
 *  createLink: (link?: Link) => void
 *  updateLink: (link?: Link) => void
 *  deleteLink: (linkId: string) => void
 * }}
 */
const useIntranetLinks = ({ currentFolder, handleSnackbarOpen, refreshDocumentList }) => {
  const createLinkApiCall = useCallback(
    /**
     * @param {Link} [link]
     */
    link => {
      if (!link || !currentFolder) {
        return;
      }

      createLink(currentFolder.id, { name: link.name, url: link.url })
        .then(() => {
          handleSnackbarOpen(`Der Link ${link.name} wurde erstellt`);
          refreshDocumentList();
        })
        .catch(() => {
          handleSnackbarOpen('Es ist ein technischer Fehler aufgetreten');
        });
    },
    [handleSnackbarOpen, refreshDocumentList, currentFolder]
  );

  const deleteLinkApiCall = useCallback(
    /**
     * @param {string} [linkId]
     */
    linkId => {
      if (!linkId || !currentFolder) {
        return;
      }

      deleteLink(currentFolder.id, linkId)
        .then(() => {
          handleSnackbarOpen('Der Link erfolgreich gelöscht!');
          refreshDocumentList();
        })
        .catch(() => {
          handleSnackbarOpen('Es ist ein technischer Fehler aufgetreten');
        });
    },
    [handleSnackbarOpen, refreshDocumentList, currentFolder]
  );

  const updateLinkApiCall = useCallback(
    /**
     * @param {Link} [link]
     */
    link => {
      if (!link.id || !currentFolder) {
        return;
      }

      updateLink(currentFolder.id, link.id, { name: link.name, url: link.url })
        .then(() => {
          handleSnackbarOpen('Der Link erfolgreich geändert!');
          refreshDocumentList();
        })
        .catch(() => {
          handleSnackbarOpen('Es ist ein technischer Fehler aufgetreten');
        });
    },
    [handleSnackbarOpen, refreshDocumentList, currentFolder]
  );

  return {
    createLink: createLinkApiCall,
    updateLink: updateLinkApiCall,
    deleteLink: deleteLinkApiCall
  };
};

export default useIntranetLinks;
