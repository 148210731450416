import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ProjectFinder from '../../generic_components/ProjectFinder';
import DrawerWrapper from '../../generic_components/DrawerWrapper';

import TEXT from '../staticContent/text';

class AddCandidateToProjectPopUp extends PureComponent {
  render() {
    return (
      <DrawerWrapper open={this.props.open} onClose={this.props.handleClose} title="Kandidat zu Auftrag hinzufügen">
        <ProjectFinder
          handleProjectSelected={selectedProject => {
            this.props.handleAddCandidateToProject(selectedProject[0], selectedProject[6]);
          }}
          helperText={TEXT.search.searchFieldErrorMessage}
          confirmationDescription={this.props.confirmationDescription}
        />
      </DrawerWrapper>
    );
  }
}
AddCandidateToProjectPopUp.defaultProps = {
  confirmationDescription: TEXT.addCandidateToProjectPopUp.description
};

AddCandidateToProjectPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleAddCandidateToProject: PropTypes.func.isRequired,
  confirmationDescription: PropTypes.string
};

export default AddCandidateToProjectPopUp;
