import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import './ErrorPage.css';
import Typography from '@material-ui/core/Typography';

import TEXT from '../text';
import * as UTILS from '../utils/utilFunctions';

class ErrorPage extends PureComponent {
  componentDidMount() {
    document.title = `Fehler - ${UTILS.getTitle()}`;
  }

  render() {
    console.log('INFO: render of ErrorPage');
    return (
      <div className="container-fluid  h-100-minusAppBar" key="maincontainer">
        <div className="row justify-content-center align-items-center h-100vh">
          <div className="Login col col-sm-6 col-md-6 col-lg-4 col-xl-3">
            <Typography variant="h3" gutterBottom>
              {TEXT.errorPage.headlineLarge}
            </Typography>
            <h1>{TEXT.errorPage.headlineSmall}</h1>
            <p>{TEXT.errorPage.infoMessage}</p>
            {this.props.onRetry && (
              <Button variant="contained" color="primary" onClick={this.props.onRetry}>
                {TEXT.errorPage.tryAgain}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

ErrorPage.defaultProps = {
  onRetry: undefined
};

ErrorPage.propTypes = {
  onRetry: PropTypes.func
};

export default ErrorPage;
