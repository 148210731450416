import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import SingleCandidateResult from '../../candidateArchive/SingleCandidateResult';
import CandidateResults from '../../candidateArchive/CandidateResult';
import * as CandidateAPI from '../../candidateArchive/api/candidateArchiveAPI';
import * as UTILS from '../../utils/utilFunctions';
import TEXT from './staticContent/text';

class IncomingCandidateDuplicateCheck extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: 0,
      duplicateCandidateList: [],
      selectedCandidate: undefined,
      listLoaded: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    this.getDuplicateCandidateList();
  }

  handleDuplicateCheckCreateNew = () => {
    this.props.handleSelectDuplicateCandidate({});

    this.props.handleNext();
  };

  handleDuplicateCheckResumeWithDuplicate = selectedDuplicateCandidate => {
    console.log('duplicateVal:', selectedDuplicateCandidate);
    this.props.handleSelectDuplicateCandidate(selectedDuplicateCandidate);
    this.props.handleNext();
  };

  getDuplicateCandidateList() {
    CandidateAPI.getDuplicateCandidateList(this.props.selectedIncomingCandidate)
      .then(responseBody => {
        if (responseBody && responseBody.length && responseBody[0].autoSelect) {
          this.autoAcceptDuplicateCandidate(responseBody[0].id);
        } else {
          this.setState({
            duplicateCandidateList: responseBody,
            listLoaded: true
          });
        }
      })
      .catch(err => {
        this.errorHandling(err);
      });
  }

  autoAcceptDuplicateCandidate(candidateId) {
    CandidateAPI.getCandidateById(candidateId)
      .then(candidate => this.handleDuplicateCheckResumeWithDuplicate(candidate))
      .catch(err => {
        this.errorHandling(err);
      });
  }

  getCandidateById(candidateId) {
    CandidateAPI.getCandidateById(candidateId)
      .then(responseBody => {
        this.setState({
          selectedCandidate: responseBody
        });
        this.getProjectListByCandidateIdAPICall(responseBody.id);
      })
      .catch(err => {
        this.errorHandling(err);
      });
  }

  getProjectListByCandidateIdAPICall(candidateId) {
    CandidateAPI.getProjectListByCandidateId(candidateId)
      .then(responseBody => {
        this.setState(prevState => ({
          selectedCandidate: {
            ...prevState.selectedCandidate,
            projects: responseBody.projects
          }
        }));
        // this.props.refreshCandidate();
      })
      .catch(err => {
        this.errorHandling(err);
      });
  }

  renderDuplicateList() {
    return (
      <CandidateResults
        handleCandidateSelected={selectedRow => {
          this.getCandidateById(selectedRow[0]);
        }}
        candidateList={this.state.duplicateCandidateList}
      />
    );
  }

  renderSelectedCandiadte() {
    return (
      <SingleCandidateResult
        selectedCandidate={this.state.selectedCandidate}
        duplicateCheckMode
        incomingCandidate={this.props.selectedIncomingCandidate}
        liftUpStateToCandidateArchive={stateObject => {
          console.log(stateObject);
          if (stateObject && stateObject.currentTab !== undefined) this.setState({ currentTab: stateObject.currentTab });
        }}
        currentTab={this.state.currentTab}
        // handleDuplicateCheckMerge={this.handleDuplicateCheckMerge}
        handleDuplicateCheckCreateNew={this.handleDuplicateCheckCreateNew}
        handleDuplicateCheckResumeWithDuplicate={this.handleDuplicateCheckResumeWithDuplicate}
      />
    );
  }

  renderCreateNewCandidate() {
    // TODO make it beautiful
    return (
      <div>
        {TEXT.incomingCandidateDuplicateCheck.noDuplicates}
        <Button color="primary" onClick={this.handleDuplicateCheckCreateNew}>
          {TEXT.incomingCandidateDuplicateCheck.createNew}
        </Button>
      </div>
    );
  }

  renderDuplicateCheck() {
    return this.state.duplicateCandidateList.length > 0 ? (
      <div>
        {this.renderDuplicateList()}
        {this.state.selectedCandidate && this.renderSelectedCandiadte()}
      </div>
    ) : (
      this.renderCreateNewCandidate()
    );
  }

  render() {
    // if (this.state.redirect) {
    //   this.setState({ redirect: false });
    //   return (
    //     <Redirect to={`/candidates?id=${this.state.preSelectedCandidateId}`} />
    //   );
    // }
    return <div>{this.state.listLoaded ? this.renderDuplicateCheck() : <CircularProgress className="mb-3" size={60} thickness={5} />}</div>;
  }
}

IncomingCandidateDuplicateCheck.defaultProps = {};
IncomingCandidateDuplicateCheck.propTypes = {
  selectedIncomingCandidate: PropTypes.object.isRequired,
  handleSelectDuplicateCandidate: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired
};
export default IncomingCandidateDuplicateCheck;
