function determineStatusColor(statusType) {
  if (statusType === 'available') return 'green';
  if (statusType === 'globalLock') return 'orange';
  return 'red';
}

module.exports = {
  /**
   *  verify if company lock is already expired and set it to available after expiration
   */
  verifyCompanyStatus(companyStatus) {
    let mappedCompanyStatus = companyStatus;

    const expirationDate =
      companyStatus.expirationDate && companyStatus.expirationDate != null ? new Date(companyStatus.expirationDate) : new Date(0);

    // console.log('expirationDate.getTime() ', expirationDate.getTime());
    const isDateInPast = expirationDate.getTime() + 86400000 < new Date().getTime();
    if (isDateInPast && companyStatus.statusType !== 'globalLock') {
      mappedCompanyStatus = { statusType: 'available' };
    }
    return mappedCompanyStatus;
  },

  /**
   *  map the companyStatus to corresponding color (green, orange or red)
   */
  getCompanyStatusColor(companyStatus) {
    const mappedCompanyStatus = this.verifyCompanyStatus(companyStatus);
    const statusColor = determineStatusColor(mappedCompanyStatus.statusType);
    return statusColor;
  }
};
