import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { InputAdornment, IconButton, FormControl, Input, InputLabel } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import $ from 'jquery';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';

import './Login.css';
import { storeToken } from '../utils/tokenStorage';
import parseTokenToState from '../utils/parseTokenToState';

const TEXT = require('../text');

const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

const theme = createTheme(THEME.login);

class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      tan: '',
      errorMsg: '',
      requestTan: false,
      redirectToPortal: false,
      showPassword: false
      // userStatus: '',
      // userIsInternal: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    document.title = `Login - ${UTILS.getTitle()}`;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    this.loginAPIcall();
  };

  validateForm() {
    let isValid = false;
    if (!this.state.requestTan) isValid = this.state.email.length > 4 && this.state.password.length > 5;
    else isValid = this.state.tan.length > 5;
    return isValid;
  }

  handleMouseDownPassword(event) {
    event.preventDefault();
  }

  handleClickShowPassword() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  handleRedirect = () => {
    if (CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) {
      return <Redirect to="/notifications" />;
    }

    return <Redirect to="/search" />;
  };

  loginAPIcall() {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    this.setState({ errorMsg: '', redirectToPortal: false });
    const requestBody = {
      email: this.state.email.toLowerCase(),
      password: this.state.password,
      company: CONSTANTS.partner
    };
    if (this.state.requestTan) requestBody.tan = this.state.tan;
    const url = CONSTANTS.loginURL;
    // console.log('API login request: ', requestBody);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status, xhr) => {
        console.log('API login response body: ', responseBody);
        console.log('API login response status: ', status);

        // save token and role from user in case no additionalTan is isRequired
        if (xhr.getResponseHeader('x-auth')) {
          const token = xhr.getResponseHeader('x-auth');
          console.log('API login response header x-auth: ', token);
          storeToken(token);
          this.props.liftUpStateToApp(parseTokenToState(token));
          this.props.liftUpStateToApp('showLoadingIndicatorGlobal', false);
        } else {
          const liftUpObj = {
            showLoadingIndicatorGlobal: false
          };
          this.props.liftUpStateToApp(liftUpObj);
        }

        // check for returned status of user
        if (['invited', 'reinvited', 'initialLogin'].includes(responseBody.status)) this.props.history.push('/register');
        else if (responseBody.status === 'passwordSet') this.props.history.push('/registerTan');
        else if (['active'].includes(responseBody.status)) {
          if (xhr.getResponseHeader('x-auth')) {
            // regular login of active user with/without tan validation
            console.log('active User: Valid login');
            this.setState({
              redirectToPortal: true
            });
          } else {
            console.log('active User: Tan required');
            this.setState({ requestTan: true });
          }
        } else {
          // else case should not occur as these cases are handled in the error handling below
          console.log('WARNING unexpected user.status');
          this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
          this.setState({ errorMsg: TEXT.loginPage.errorMsg.error400 });
        }

        // if (responseBody.requestTan === false) {
        //   const token = xhr.getResponseHeader('x-auth');
        //   console.log('API login response header x-auth: ', token);
        //   window.sessionStorage.setItem('token', token);
        // } else {
        //   this.setState({ requestTan: true });
        // }
        //
        //
        //
        // else if (['active'].includes(responseBody.status)) {
        //   const userRoles = jwt.decode(token).roles;
        //   const liftUpObj = {
        //     loggedIn: true,
        //     user: { roles: userRoles},
        //     showLoadingIndicatorGlobal: false
        //   };
        //   this.props.liftUpStateToApp(liftUpObj);
        // } else {
        //   // else case should not occur as these cases are handled in the error handling below
        //   console.log('WARNING unexpected user.status');
        //   this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        //   this.setState({ errorMsg: TEXT.loginPage.errorMsg.error400 });
        // }
      })
      // error case user not found / login data not validate
      // error cases user.status = blocked or deactived
      .fail(err => {
        console.log('error', err);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        const ignoreErrorCodes = [400, 401];
        this.errorHandling(err, url, null, ignoreErrorCodes);
        if (err.status === 400) {
          this.setState({ errorMsg: TEXT.loginPage.errorMsg.error400 });
        } else if (err.status === 401) {
          if (err.responseText === 'startPasswordExpired')
            this.setState({
              errorMsg: TEXT.loginPage.errorMsg.startPasswordExpired
            });
          else if (err.responseText === 'accessOnlyToDifferentPortal')
            this.setState({
              errorMsg: TEXT.loginPage.errorMsg.accessOnlyToDifferentPortal
            });
          else this.setState({ errorMsg: TEXT.loginPage.errorMsg.accountLocked });
        }
      });
  }

  render() {
    return (
      <div className="container-fluid h-100vh" style={THEME.loginBackground}>
        <div className="row justify-content-center align-items-center h-100">
          <div className="Login col col-sm-6 col-md-6 col-lg-4 col-xl-3">
            {this.props.loggedIn && this.state.redirectToPortal && this.handleRedirect()}

            {this.state.errorMsg && <div className="errorContainer text-center">{this.state.errorMsg}</div>}

            <form onSubmit={this.handleSubmit}>
              <Paper style={THEME.loginPaper} className="paperLogin text-center" elevation={24}>
                <img src={THEME.loginLogo} alt={TEXT.loginPage.imageAlt} style={THEME.loginLogoStyle} />
                <MuiThemeProvider theme={theme}>
                  {!this.state.requestTan && (
                    <div>
                      <TextField
                        id="email"
                        color="primary"
                        label={TEXT.loginPage.inputfield.email.label}
                        value={this.state.email}
                        onChange={this.handleChange}
                        margin="normal"
                        type="email"
                        fullWidth
                        autoFocus
                      />
                      <FormControl className="password-field">
                        <InputLabel htmlFor="password">{TEXT.loginPage.inputfield.password.label}</InputLabel>
                        <Input
                          id="password"
                          value={this.state.password}
                          onChange={this.handleChange}
                          margin="normal"
                          type={this.state.showPassword ? 'text' : 'password'}
                          fullWidth
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => this.handleClickShowPassword()}
                                onMouseDown={e => this.handleMouseDownPassword(e)}
                                edge="end"
                              >
                                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </div>
                  )}
                  {this.state.requestTan && (
                    <TextField
                      id="tan"
                      label={TEXT.loginPage.inputfield.tan.label}
                      value={this.state.tan}
                      onChange={this.handleChange}
                      margin="normal"
                      fullWidth
                      autoFocus
                    />
                  )}

                  <Button className="login-button mt-3" variant="contained" color="primary" disabled={!this.validateForm()} type="submit">
                    {TEXT.loginPage.button}
                  </Button>
                </MuiThemeProvider>
              </Paper>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  history: PropTypes.instanceOf(Object).isRequired
};

export default withRouter(Login);
