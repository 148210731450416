import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { withRouter } from 'react-router-dom';

import ProjectDetails from './ProjectDetails';
import JobPosting from './JobPosting';
import ProjectControlling from './ProjectControlling';
import ProjectMatchingTab from './ProjectMatchingTab';
import ProjectStatus from './ProjectStatus';
import ProjectApplications from './ProjectApplications';
import ProjectStatusReport from './ProjectStatusReport';
import ProjectCustomerInfoTab from './ProjectCustomerInfoTab';
import ProjectDossierList from './ProjectDossierList';
import DocumentPanel from '../generic_components/DocumentPanel';

import * as CONSTANTS from '../constants';
import * as UTILS from '../utils/utilFunctions';
import ProjectCustomerInfoForm from './ProjectCustomerInfoForm';
import ProjectSelection from './ProjectSelection';
import { ListType } from './hooks/ProjectSelectionHook';
import LinkProjectPanel from './LinkPanel';

class SingleProjectResult extends PureComponent {
  /**
   * !! Important: When changing the Tab indices you need to verify if other Files (here ProjectArchive) depends on an index
   * In case of changes make a search for this.state.currentTab === also check for &t=
   * for example:             {this.state.currentTab === 4 && ( in ProjectArchive
   * @returns
   */
  render() {
    const mainColumnClass = this.props.showProjectDetailsColumn ? 'col-10 pl-0' : 'col-12';
    return (
      <div>
        <div className="row navigation">
          <div className="col-12">
            <Box display="flex" justifyContent="center" width="100%">
              <Tabs
                value={this.props.currentTab}
                onChange={(event, value) => {
                  UTILS.setURLParam(this.props.history, 't', value);

                  this.props.liftUpStateToProjectArchive({
                    currentTab: value,
                    statusFilterCandidateId: null
                  });
                }}
                indicatorColor="primary"
                textColor="primary"
                scrollButtons="auto"
                variant="scrollable"
              >
                {CONSTANTS.isLienert && <Tab label="Auftragserfassung" />}
                {CONSTANTS.isLienert && <Tab label="Auftragsstatus" />}
                {CONSTANTS.isLienert && <Tab label="Eingegangene Bewerbungen" />}
                {CONSTANTS.isLienert && <Tab label="Selektion" />}
                {CONSTANTS.isLienert && <Tab label="Longlist" />}
                {CONSTANTS.isLienert && <Tab label="Nachzügler" />}
                {CONSTANTS.isLienert && <Tab label="Shortlist" />}
                {CONSTANTS.isLienert && <Tab label="Dokumente" />}
                {CONSTANTS.isLienert && <Tab label="Matching" />}

                {CONSTANTS.isSenator && <Tab label="Anforderungsprofil" />}
                {CONSTANTS.isSenator && <Tab label="Auftragserfassung" />}
                {CONSTANTS.isSenator && <Tab label="Auftragsstatus" />}
                {CONSTANTS.isSenator && <Tab label="Eingegangene Bewerbungen" />}
                {CONSTANTS.isSenator && <Tab label="Auftragssteuerung" />}
                {CONSTANTS.isSenator && <Tab label="Statusreport" />}
                {CONSTANTS.isSenator && <Tab label="Dokumente" />}
                {CONSTANTS.isSenator && <Tab label="Dossier" />}

                {CONSTANTS.isPersonalTotal && <Tab label="Anforderungsprofil" />}
                {CONSTANTS.isPersonalTotal && <Tab label="Auftragserfassung" />}
                {CONSTANTS.isPersonalTotal && <Tab label="Auftragsstatus" />}
                {CONSTANTS.isPersonalTotal && <Tab label="Eingegangene Bewerbungen" />}
                {CONSTANTS.isPersonalTotal && <Tab label="Auftragssteuerung" />}
                {CONSTANTS.isPersonalTotal && <Tab label="Statusreport" />}
                {CONSTANTS.isPersonalTotal && <Tab label="Dokumente" />}
                {CONSTANTS.isPersonalTotal && <Tab label="Dossier" />}

                {!CONSTANTS.isPersonalTotal && !CONSTANTS.isLienert && !CONSTANTS.isSenator && <Tab label="Auftragserfassung" />}
                {!CONSTANTS.isPersonalTotal && !CONSTANTS.isLienert && !CONSTANTS.isSenator && <Tab label="Auftragsstatus" />}
                {!CONSTANTS.isPersonalTotal && !CONSTANTS.isLienert && !CONSTANTS.isSenator && <Tab label="Eingegangene Bewerbungen" />}
                {!CONSTANTS.isPersonalTotal && !CONSTANTS.isLienert && !CONSTANTS.isSenator && <Tab label="Auftragssteuerung" />}
                {!CONSTANTS.isPersonalTotal && !CONSTANTS.isLienert && !CONSTANTS.isSenator && <Tab label="Statusreport" />}
                {!CONSTANTS.isPersonalTotal && !CONSTANTS.isLienert && !CONSTANTS.isSenator && <Tab label="Dokumente" />}
                {!CONSTANTS.isPersonalTotal && !CONSTANTS.isLienert && !CONSTANTS.isSenator && <Tab label="Dossier" />}
                {!CONSTANTS.isLienert &&
                  !CONSTANTS.isSenator &&
                  CONSTANTS.isMatchflix &&
                  this.props.selectedProject.customerInformation && <Tab label="Kunden-Stellenprofil" />}
              </Tabs>
            </Box>
          </div>
        </div>
        <div className="SingleProjectResult row">
          {this.props.showProjectDetailsColumn && this.props.selectedProject && (
            <div className="col-2 pr-0">
              <div className="col-12 mt-3 pr-0">
                <ProjectDetails
                  key={this.props.selectedProject.id}
                  company={this.props.selectedProject.company}
                  projectId={this.props.selectedProject.id}
                  isEditable={this.props.selectedProject.isEditable}
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  projectChangeHistoryId={this.props.selectedProject.projectChangeHistoryId}
                  projectNumber={this.props.selectedProject.projectNumber}
                  creationDate={this.props.selectedProject.creationDate}
                  status={this.props.selectedProject.status}
                  statusDetails={this.props.selectedProject.statusDetails}
                  serviceType={this.props.selectedProject.serviceType}
                  consultingUser={this.props.selectedProject.consultingUser}
                  editingUser={this.props.selectedProject.editingUser}
                  partner={this.props.selectedProject.partner}
                  numberOfPositions={this.props.selectedProject.numberOfPositions}
                  jobTitle1={this.props.selectedProject.jobTitle1}
                  supervisor={this.props.selectedProject.supervisor}
                  partTime={this.props.selectedProject.partTime}
                  salary={this.props.selectedProject.salary}
                  comment={this.props.selectedProject.comment}
                  loop={this.props.selectedProject.loop}
                  da={this.props.selectedProject.da}
                  conditionsAmount={this.props.selectedProject.conditionsAmount}
                  salaryLimitAmount={this.props.selectedProject.salaryLimitAmount}
                  percentage={this.props.selectedProject.percentage}
                  bookingOptions={this.props.selectedProject.bookingOptions}
                  castingType={this.props.selectedProject.castingType}
                  confidential={this.props.selectedProject.confidential}
                  liftUpStateToProjectArchive={this.props.liftUpStateToProjectArchive}
                  liftUpStateToApp={this.props.liftUpStateToApp}
                  userAccesses={this.props.selectedProject.userAccesses}
                />
              </div>
            </div>
          )}

          <div className={mainColumnClass}>
            <div className="col-12 mt-3 plr-3">
              {CONSTANTS.isSenator && (
                <>
                  {this.props.currentTab === 0 && (
                    <ProjectCustomerInfoForm
                      selectedProject={this.props.selectedProject}
                      liftUpSelectedProject={this.props.liftUpSelectedProject}
                      liftUpStateToApp={this.props.liftUpStateToApp}
                      handleSnackbarOpen={this.props.handleSnackbarOpen}
                    />
                  )}
                  {this.props.currentTab === 1 && (
                    <div>
                      {this.props.selectedProject && this.props.selectedProject.jobPosting && (
                        <JobPosting
                          liftUpStateToApp={this.props.liftUpStateToApp}
                          key={this.props.selectedProject.id}
                          selectedProject={this.props.selectedProject}
                          handleSnackbarOpen={this.props.handleSnackbarOpen}
                          liftUpStateToProjectArchive={this.props.liftUpStateToProjectArchive}
                        />
                      )}
                    </div>
                  )}
                  {this.props.currentTab === 2 && (
                    <div>
                      <ProjectStatus
                        key={this.props.selectedProject.id}
                        selectedProjectId={this.props.selectedProject.id}
                        projectNumber={this.props.selectedProject.projectNumber}
                        jobTitle1={this.props.selectedProject.jobTitle1}
                        filterCandidateId={this.props.statusFilterCandidateId}
                        data={this.props.selectedProject.potentialCandidates}
                        isEditable={this.props.selectedProject.isEditable}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        handleOpenPotentialCandidateUpdatePopUp={this.props.handleOpenPotentialCandidateUpdatePopUp}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        liftUpUpdatedPotentialCandidates={this.props.liftUpUpdatedPotentialCandidates}
                        liftUpAllPotentialCandidates={this.props.liftUpAllPotentialCandidates}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 3 && (
                    <div>
                      <ProjectApplications
                        key={this.props.selectedProject.id}
                        data={this.props.selectedProject.potentialCandidates}
                        selectedProjectId={this.props.selectedProject.id}
                        filterCandidateId={this.props.statusFilterCandidateId}
                        isEditable={this.props.selectedProject.isEditable}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        handleOpenPotentialCandidateUpdatePopUp={this.props.handleOpenPotentialCandidateUpdatePopUp}
                        refreshProject={this.props.refreshProject}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        liftUpUpdatedPotentialCandidates={this.props.liftUpUpdatedPotentialCandidates}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 4 && (
                    <div>
                      <ProjectControlling
                        key={this.props.selectedProject.id}
                        isEditable={this.props.selectedProject.isEditable}
                        data={this.props.selectedProject.actionProtocolEntries}
                        selectedProjectId={this.props.selectedProject.id}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        refreshProject={this.props.refreshProject}
                        employees={this.props.employees}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 5 && (
                    <div>
                      <ProjectStatusReport
                        key={this.props.selectedProject.id}
                        selectedProject={this.props.selectedProject}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 6 && (
                    <>
                      <DocumentPanel
                        key={`${this.props.selectedProject.id}documents`}
                        documentList={this.props.selectedProject.documents}
                        id={this.props.selectedProject.id}
                        deleteURL={CONSTANTS.deleteNewProjectDocumentURL}
                        getDocumentURL={CONSTANTS.getProjectDocumentByIdURL}
                        uploadDocumentURL={CONSTANTS.uploadNewProjectDocumentsURL}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        archive="project"
                        liftUpDocuments={this.props.liftUpSelectedProject}
                      />
                      <LinkProjectPanel
                        liftUpSelectedProject={this.props.liftUpSelectedProject}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        links={this.props.selectedProject.links}
                        currentProject={this.props.selectedProject}
                      />
                    </>
                  )}
                  {this.props.currentTab === 7 && (
                    <ProjectDossierList
                      project={this.props.selectedProject}
                      liftUpUpdatedPotentialCandidates={this.props.liftUpUpdatedPotentialCandidates}
                      handleSnackbarOpen={this.props.handleSnackbarOpen}
                    />
                  )}
                </>
              )}
              {CONSTANTS.isLienert && (
                <>
                  {this.props.currentTab === 0 && (
                    <div>
                      {this.props.selectedProject && this.props.selectedProject.jobPosting && (
                        <JobPosting
                          liftUpStateToApp={this.props.liftUpStateToApp}
                          key={this.props.selectedProject.id}
                          selectedProject={this.props.selectedProject}
                          handleSnackbarOpen={this.props.handleSnackbarOpen}
                          liftUpStateToProjectArchive={this.props.liftUpStateToProjectArchive}
                        />
                      )}
                    </div>
                  )}
                  {this.props.currentTab === 1 && (
                    <div>
                      <ProjectStatus
                        key={this.props.selectedProject.id}
                        selectedProjectId={this.props.selectedProject.id}
                        projectNumber={this.props.selectedProject.projectNumber}
                        jobTitle1={this.props.selectedProject.jobTitle1}
                        filterCandidateId={this.props.statusFilterCandidateId}
                        data={this.props.selectedProject.potentialCandidates}
                        isEditable={this.props.selectedProject.isEditable}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        handleOpenPotentialCandidateUpdatePopUp={this.props.handleOpenPotentialCandidateUpdatePopUp}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        liftUpUpdatedPotentialCandidates={this.props.liftUpUpdatedPotentialCandidates}
                        liftUpAllPotentialCandidates={this.props.liftUpAllPotentialCandidates}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 2 && (
                    <div>
                      <ProjectApplications
                        key={this.props.selectedProject.id}
                        data={this.props.selectedProject.potentialCandidates}
                        selectedProjectId={this.props.selectedProject.id}
                        filterCandidateId={this.props.statusFilterCandidateId}
                        isEditable={this.props.selectedProject.isEditable}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        handleOpenPotentialCandidateUpdatePopUp={this.props.handleOpenPotentialCandidateUpdatePopUp}
                        refreshProject={this.props.refreshProject}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        liftUpUpdatedPotentialCandidates={this.props.liftUpUpdatedPotentialCandidates}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 3 && (
                    <div>
                      <ProjectSelection
                        selectedProject={this.props.selectedProject}
                        liftUpStateToProjectArchive={this.props.liftUpStateToProjectArchive}
                        liftUpSelectedProject={this.props.liftUpSelectedProject}
                        listType={ListType.SELECTION_LIST}
                        title="Selektion"
                        // liftUpDocuments={this.props.liftUpSelectedProject} // TODO - isRequired name feels wrong
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        onRowSelectionChange={this.props.onRowSelectionChange}
                        refreshProject={this.props.refreshProject}
                        dossiersAreSelectableForDownload={this.props.dossiersAreSelectableForDownload}
                        onBulkDownloadDossierClick={this.props.onBulkDownloadDossierClick}
                        dossierBulkDownloadInProgress={this.props.dossierBulkDownloadInProgress}
                        onDeleteSelectedDossiers={this.props.onDeleteSelectedDossiers}
                        onDeleteAllDossiers={this.props.onDeleteAllDossiers}
                        onHandleEmployeeComment={this.props.onHandleEmployeeComment}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 4 && (
                    <div>
                      <ProjectSelection
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        selectedProject={this.props.selectedProject}
                        liftUpSelectedProject={this.props.liftUpSelectedProject}
                        liftUpStateToProjectArchive={this.props.liftUpStateToProjectArchive}
                        listType={ListType.LONG_LIST}
                        title="Longlist"
                        onRowSelectionChange={this.props.onRowSelectionChange}
                        refreshProject={this.props.refreshProject}
                        dossiersAreSelectableForDownload={this.props.dossiersAreSelectableForDownload}
                        onBulkDownloadDossierClick={this.props.onBulkDownloadDossierClick}
                        dossierBulkDownloadInProgress={this.props.dossierBulkDownloadInProgress}
                        onDeleteSelectedDossiers={this.props.onDeleteSelectedDossiers}
                        onDeleteAllDossiers={this.props.onDeleteAllDossiers}
                        onHandleEmployeeComment={this.props.onHandleEmployeeComment}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 5 && (
                    <div>
                      <ProjectSelection
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        selectedProject={this.props.selectedProject}
                        liftUpSelectedProject={this.props.liftUpSelectedProject}
                        liftUpStateToProjectArchive={this.props.liftUpStateToProjectArchive}
                        listType={ListType.LATECOMER_LIST}
                        title="Nachzügler"
                        onRowSelectionChange={this.props.onRowSelectionChange}
                        refreshProject={this.props.refreshProject}
                        dossiersAreSelectableForDownload={this.props.dossiersAreSelectableForDownload}
                        onBulkDownloadDossierClick={this.props.onBulkDownloadDossierClick}
                        dossierBulkDownloadInProgress={this.props.dossierBulkDownloadInProgress}
                        onDeleteSelectedDossiers={this.props.onDeleteSelectedDossiers}
                        onDeleteAllDossiers={this.props.onDeleteAllDossiers}
                        onHandleEmployeeComment={this.props.onHandleEmployeeComment}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 6 && (
                    <div>
                      <ProjectSelection
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        selectedProject={this.props.selectedProject}
                        liftUpSelectedProject={this.props.liftUpSelectedProject}
                        liftUpStateToProjectArchive={this.props.liftUpStateToProjectArchive}
                        listType={ListType.SHORT_LIST}
                        title="Shortlist"
                        refreshProject={this.props.refreshProject}
                        onRowSelectionChange={this.props.onRowSelectionChange}
                        dossiersAreSelectableForDownload={this.props.dossiersAreSelectableForDownload}
                        onBulkDownloadDossierClick={this.props.onBulkDownloadDossierClick}
                        dossierBulkDownloadInProgress={this.props.dossierBulkDownloadInProgress}
                        onDeleteSelectedDossiers={this.props.onDeleteSelectedDossiers}
                        onDeleteAllDossiers={this.props.onDeleteAllDossiers}
                        onHandleEmployeeComment={this.props.onHandleEmployeeComment}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 7 && (
                    <DocumentPanel
                      key={`${this.props.selectedProject.id}documents`}
                      documentList={this.props.selectedProject.documents}
                      id={this.props.selectedProject.id}
                      deleteURL={CONSTANTS.deleteNewProjectDocumentURL}
                      getDocumentURL={CONSTANTS.getProjectDocumentByIdURL}
                      uploadDocumentURL={CONSTANTS.uploadNewProjectDocumentsURL}
                      handleSnackbarOpen={this.props.handleSnackbarOpen}
                      liftUpStateToApp={this.props.liftUpStateToApp}
                      archive="project"
                      liftUpDocuments={this.props.liftUpSelectedProject}
                    />
                  )}
                  {this.props.currentTab === 8 && (
                    <div>
                      <ProjectMatchingTab
                        key={this.props.selectedProject.id}
                        selectedProject={this.props.selectedProject}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                      />
                    </div>
                  )}
                </>
              )}
              {CONSTANTS.isPersonalTotal && (
                <>
                  {this.props.currentTab === 0 && (
                    <ProjectCustomerInfoForm
                      selectedProject={this.props.selectedProject}
                      liftUpSelectedProject={this.props.liftUpSelectedProject}
                      liftUpStateToApp={this.props.liftUpStateToApp}
                      handleSnackbarOpen={this.props.handleSnackbarOpen}
                    />
                  )}
                  {this.props.currentTab === 1 && (
                    <div>
                      {this.props.selectedProject && this.props.selectedProject.jobPosting && (
                        <JobPosting
                          liftUpStateToApp={this.props.liftUpStateToApp}
                          key={this.props.selectedProject.id}
                          selectedProject={this.props.selectedProject}
                          handleSnackbarOpen={this.props.handleSnackbarOpen}
                          liftUpStateToProjectArchive={this.props.liftUpStateToProjectArchive}
                        />
                      )}
                    </div>
                  )}
                  {this.props.currentTab === 2 && (
                    <div>
                      <ProjectStatus
                        key={this.props.selectedProject.id}
                        selectedProjectId={this.props.selectedProject.id}
                        projectNumber={this.props.selectedProject.projectNumber}
                        jobTitle1={this.props.selectedProject.jobTitle1}
                        filterCandidateId={this.props.statusFilterCandidateId}
                        data={this.props.selectedProject.potentialCandidates}
                        isEditable={this.props.selectedProject.isEditable}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        handleOpenPotentialCandidateUpdatePopUp={this.props.handleOpenPotentialCandidateUpdatePopUp}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        liftUpUpdatedPotentialCandidates={this.props.liftUpUpdatedPotentialCandidates}
                        liftUpAllPotentialCandidates={this.props.liftUpAllPotentialCandidates}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 3 && (
                    <div>
                      <ProjectApplications
                        key={this.props.selectedProject.id}
                        data={this.props.selectedProject.potentialCandidates}
                        selectedProjectId={this.props.selectedProject.id}
                        filterCandidateId={this.props.statusFilterCandidateId}
                        isEditable={this.props.selectedProject.isEditable}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        handleOpenPotentialCandidateUpdatePopUp={this.props.handleOpenPotentialCandidateUpdatePopUp}
                        refreshProject={this.props.refreshProject}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        liftUpUpdatedPotentialCandidates={this.props.liftUpUpdatedPotentialCandidates}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 4 && (
                    <div>
                      <ProjectControlling
                        key={this.props.selectedProject.id}
                        isEditable={this.props.selectedProject.isEditable}
                        data={this.props.selectedProject.actionProtocolEntries}
                        selectedProjectId={this.props.selectedProject.id}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        refreshProject={this.props.refreshProject}
                        employees={this.props.employees}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 5 && (
                    <div>
                      <ProjectStatusReport
                        key={this.props.selectedProject.id}
                        selectedProject={this.props.selectedProject}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 6 && (
                    <>
                      <DocumentPanel
                        key={`${this.props.selectedProject.id}documents`}
                        documentList={this.props.selectedProject.documents}
                        id={this.props.selectedProject.id}
                        deleteURL={CONSTANTS.deleteNewProjectDocumentURL}
                        getDocumentURL={CONSTANTS.getProjectDocumentByIdURL}
                        uploadDocumentURL={CONSTANTS.uploadNewProjectDocumentsURL}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        archive="project"
                        liftUpDocuments={this.props.liftUpSelectedProject}
                      />
                      <LinkProjectPanel
                        liftUpSelectedProject={this.props.liftUpSelectedProject}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        links={this.props.selectedProject.links}
                        currentProject={this.props.selectedProject}
                      />
                    </>
                  )}
                  {this.props.currentTab === 7 && (
                    <ProjectDossierList
                      project={this.props.selectedProject}
                      liftUpUpdatedPotentialCandidates={this.props.liftUpUpdatedPotentialCandidates}
                      handleSnackbarOpen={this.props.handleSnackbarOpen}
                    />
                  )}
                </>
              )}
              {!CONSTANTS.isPersonalTotal && !CONSTANTS.isLienert && !CONSTANTS.isSenator && (
                <>
                  {this.props.currentTab === 0 && (
                    <div>
                      {this.props.selectedProject && this.props.selectedProject.jobPosting && (
                        <JobPosting
                          liftUpStateToApp={this.props.liftUpStateToApp}
                          key={this.props.selectedProject.id}
                          selectedProject={this.props.selectedProject}
                          handleSnackbarOpen={this.props.handleSnackbarOpen}
                          liftUpStateToProjectArchive={this.props.liftUpStateToProjectArchive}
                        />
                      )}
                    </div>
                  )}
                  {this.props.currentTab === 1 && (
                    <div>
                      <ProjectStatus
                        key={this.props.selectedProject.id}
                        selectedProjectId={this.props.selectedProject.id}
                        projectNumber={this.props.selectedProject.projectNumber}
                        jobTitle1={this.props.selectedProject.jobTitle1}
                        filterCandidateId={this.props.statusFilterCandidateId}
                        data={this.props.selectedProject.potentialCandidates}
                        isEditable={this.props.selectedProject.isEditable}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        handleOpenPotentialCandidateUpdatePopUp={this.props.handleOpenPotentialCandidateUpdatePopUp}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        liftUpUpdatedPotentialCandidates={this.props.liftUpUpdatedPotentialCandidates}
                        liftUpAllPotentialCandidates={this.props.liftUpAllPotentialCandidates}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 2 && (
                    <div>
                      <ProjectApplications
                        key={this.props.selectedProject.id}
                        data={this.props.selectedProject.potentialCandidates}
                        selectedProjectId={this.props.selectedProject.id}
                        filterCandidateId={this.props.statusFilterCandidateId}
                        isEditable={this.props.selectedProject.isEditable}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        handleOpenPotentialCandidateUpdatePopUp={this.props.handleOpenPotentialCandidateUpdatePopUp}
                        refreshProject={this.props.refreshProject}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                        liftUpUpdatedPotentialCandidates={this.props.liftUpUpdatedPotentialCandidates}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 3 && (
                    <div>
                      <ProjectControlling
                        key={this.props.selectedProject.id}
                        isEditable={this.props.selectedProject.isEditable}
                        data={this.props.selectedProject.actionProtocolEntries}
                        selectedProjectId={this.props.selectedProject.id}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                        refreshProject={this.props.refreshProject}
                        employees={this.props.employees}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 4 && (
                    <div>
                      <ProjectStatusReport
                        key={this.props.selectedProject.id}
                        selectedProject={this.props.selectedProject}
                        liftUpStateToApp={this.props.liftUpStateToApp}
                      />
                    </div>
                  )}
                  {this.props.currentTab === 5 && (
                    <DocumentPanel
                      key={`${this.props.selectedProject.id}documents`}
                      documentList={this.props.selectedProject.documents}
                      id={this.props.selectedProject.id}
                      deleteURL={CONSTANTS.deleteNewProjectDocumentURL}
                      getDocumentURL={CONSTANTS.getProjectDocumentByIdURL}
                      uploadDocumentURL={CONSTANTS.uploadNewProjectDocumentsURL}
                      handleSnackbarOpen={this.props.handleSnackbarOpen}
                      liftUpStateToApp={this.props.liftUpStateToApp}
                      archive="project"
                      liftUpDocuments={this.props.liftUpSelectedProject}
                    />
                  )}
                  {CONSTANTS.isMatchflix && this.props.currentTab === 6 && (
                    <div>
                      <ProjectCustomerInfoTab
                        key={this.props.selectedProject.id}
                        projectId={this.props.selectedProject.id}
                        customerInformation={(this.props.selectedProject || {}).customerInformation}
                        handleSnackbarOpen={this.props.handleSnackbarOpen}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SingleProjectResult.defaultProps = {
  showProjectDetailsColumn: true,
  currentTab: 0,
  statusFilterCandidateId: undefined,
  onRowSelectionChange: undefined,
  dossiersAreSelectableForDownload: false,
  onBulkDownloadDossierClick: undefined,
  dossierBulkDownloadInProgress: false,
  onDeleteSelectedDossiers: undefined,
  onDeleteAllDossiers: undefined,
  onHandleEmployeeComment: undefined
};
SingleProjectResult.propTypes = {
  showProjectDetailsColumn: PropTypes.bool,
  selectedProject: PropTypes.object.isRequired,
  refreshProject: PropTypes.func.isRequired,
  liftUpStateToProjectArchive: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  liftUpUpdatedPotentialCandidates: PropTypes.func.isRequired,
  liftUpAllPotentialCandidates: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  currentTab: PropTypes.number,
  handleOpenPotentialCandidateUpdatePopUp: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  history: PropTypes.object.isRequired,
  statusFilterCandidateId: PropTypes.string,
  liftUpSelectedProject: PropTypes.func.isRequired,
  onRowSelectionChange: PropTypes.func,
  dossiersAreSelectableForDownload: PropTypes.bool,
  onBulkDownloadDossierClick: PropTypes.func,
  dossierBulkDownloadInProgress: PropTypes.bool,
  onDeleteSelectedDossiers: PropTypes.func,
  onDeleteAllDossiers: PropTypes.func,
  onHandleEmployeeComment: PropTypes.func
};
export default withRouter(SingleProjectResult);
