import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material UI
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip';
import CompareArrows from '@material-ui/icons/CompareArrows';

import { KeyboardDatePicker } from '@material-ui/pickers';
import ValidatedDropDown from './ValidatedDropDown';
import ValidatedTextField from './ValidatedTextField';

class GenericArrayForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isNewValues: true,
      formRows: this.props.formRows
    };
    if (!this.props.baseModel) {
      this.baseModel = JSON.parse(JSON.stringify(this.props.formRows[0]));
      Object.keys(this.baseModel).forEach(key => {
        this.baseModel[key].value = '';
      });
    } else {
      this.baseModel = this.props.baseModel;
    }
  }

  handleInputFieldChange = rowIndex => e => {
    const inputFieldValue = e.target.value;
    const inputFieldKey = e.target.id || e.target.name;

    this.setState(prevState => {
      const newFormRows = prevState.formRows.map((formRow, srowIndex) => {
        if (rowIndex !== srowIndex) return formRow;
        return {
          ...formRow,
          [inputFieldKey]: { ...formRow[inputFieldKey], value: inputFieldValue }
        };
      });
      return { formRows: newFormRows };
    });
  };

  onDateFieldChange = (rowIndex, date, id) => {
    const inputFieldValue = date;
    const inputFieldKey = id;

    this.setState(prevState => {
      const newFormRows = prevState.formRows.map((formRow, srowIndex) => {
        if (rowIndex !== srowIndex) return formRow;
        return {
          ...formRow,
          [inputFieldKey]: { ...formRow[inputFieldKey], value: inputFieldValue }
        };
      });
      return { formRows: newFormRows };
    });
  };

  // handleDropDownChange = rowIndex => e => {
  //   const inputFieldValue = e.target.value;
  //   const inputFieldKey = e.target.name;
  //   this.setState(prevState => ({
  //     formRows: {
  //       ...prevState.formRows,
  //       [inputFieldKey]: {
  //         ...prevState.formRows[inputFieldKey],
  //         value: inputFieldValue
  //       }
  //     }
  //   }));
  // };

  handleAddRow = () => {
    this.setState(prevState => ({
      formRows: prevState.formRows.concat([this.baseModel])
    }));
  };

  handleRemoveRow = rowIndex => () => {
    this.setState(prevState => ({
      formRows: prevState.formRows.filter((s, srowIndex) => rowIndex !== srowIndex)
    }));
  };

  handleSwitchToDuplicateCandidate = () => {
    this.setState(prevState => ({
      isNewValues: !prevState.isNewValues,
      formRows: prevState.isNewValues ? this.props.oldValue : this.props.newValue
    }));
  };

  handleSubmitButtonClick = () => {
    const data = [];
    this.state.formRows.forEach(row => {
      const dataOfRowObject = {};
      Object.keys(row).forEach(key => {
        dataOfRowObject[key] = row[key].value;
      });
      data.push(dataOfRowObject);
    });
    this.props.handleSubmit(data);
  };

  handleMiddleButton = () => {
    const data = [];
    this.state.formRows.forEach(row => {
      const dataOfRowObject = {};
      Object.keys(row).forEach(key => {
        dataOfRowObject[key] = row[key].value;
      });
      data.push(dataOfRowObject);
    });
    this.props.handleMiddleButton(data);
  };

  renderDropDown(dropDownId, index, rowIndex) {
    const currentDropDownState = this.state.formRows[rowIndex][dropDownId];
    return (
      <ValidatedDropDown
        id={dropDownId}
        key={dropDownId}
        // ref={this.formFieldRefs[index]}
        errorMessage={currentDropDownState.errorMessage}
        content={currentDropDownState.content}
        onChange={this.handleInputFieldChange(rowIndex)}
        {...currentDropDownState}
      />
    );
  }

  renderDateField(fieldId, rowIndex) {
    return (
      <div className="picker">
        <KeyboardDatePicker
          fullWidth
          id={fieldId}
          key={fieldId}
          label={this.state.formRows[rowIndex][fieldId].label}
          format="dd.MM.yyyy"
          cancelLabel="Abbrechen"
          value={this.state.formRows[rowIndex][fieldId].value === '' ? null : this.state.formRows[rowIndex][fieldId].value}
          onChange={date => this.onDateFieldChange(rowIndex, date, fieldId)}
          disableOpenOnEnter
          animateYearScrolling={false}
        />
      </div>
    );
  }

  renderInputField(inputFieldId, index, furtherProps, rowIndex) {
    const props = {
      ...furtherProps,
      ...this.state.formRows[rowIndex][inputFieldId]
    };
    console.log('props', props);
    const value = props.value ? props.value.toString() : '';
    return (
      <ValidatedTextField
        fullWidth
        id={inputFieldId}
        key={inputFieldId + rowIndex}
        // autoFocus={index === 0}
        // ref={this.formFieldRefs[index]}
        onChange={this.handleInputFieldChange(rowIndex)}
        {...props}
        value={value}
      />
    );
  }

  renderInputFields(formRow, rowIndex) {
    console.log('formRow', formRow);
    return (
      <div key={rowIndex} className="row">
        {Object.keys(formRow).map((fieldKey, index) => {
          const { customClass, type, additionalProps } = this.state.formRows[rowIndex][fieldKey];
          let typeOfField = 'textField';
          if (type) typeOfField = type;
          if (typeOfField === 'dropDown') {
            return (
              <div className={customClass || this.props.fieldClass} key={fieldKey + rowIndex}>
                {this.renderDropDown(fieldKey, index, rowIndex)}
              </div>
            );
          }
          if (typeOfField === 'date') {
            return (
              <div className={customClass || this.props.fieldClass} key={fieldKey + rowIndex}>
                {this.renderDateField(fieldKey, rowIndex)}
              </div>
            );
          }
          // typeOfField: inputField
          return (
            <div className={customClass || this.props.fieldClass} key={fieldKey + rowIndex}>
              {this.renderInputField(fieldKey, index, additionalProps, rowIndex)}
            </div>
          );
        })}
      </div>
    );
  }

  removeAllRows = () => {
    // TODO: @Maxi ich weiß nicht wie schön das ist.
    this.setState({ formRows: [] });
  };

  renderInputFieldRows() {
    return this.state.formRows.map((formRow, rowIndex) => (
      // eslint-disable-next-line
      <div className="row" key={rowIndex}>
        <div className="col-11">{this.renderInputFields(formRow, rowIndex)}</div>
        <div className="col-1">
          <div className="row">
            <IconButton color="primary" onClick={this.handleRemoveRow(rowIndex)}>
              <DeleteIcon />
            </IconButton>
          </div>
        </div>
        <div className="col-12 mb-3 mt-3">
          <Divider />
        </div>
      </div>
    ));
  }

  renderButtons() {
    return (
      <div>
        <Button type="button" onClick={this.props.handleCancel}>
          {this.props.buttonCancelText}
        </Button>
        {this.props.handleMiddleButton && (
          <Button type="button" onClick={this.handleMiddleButton}>
            {this.props.middleButtonText}
          </Button>
        )}
        <Button type="button" onClick={this.handleSubmitButtonClick}>
          {this.props.buttonSubmitText}
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div
        className="container pt-3"
        style={{
          overflow: 'auto',
          maxWidth: '100%',
          width: '100%',
          height: 'calc(90vh - 150px - 64px)'
        }}
      >
        {this.props.title && (
          <div className="row">
            <div className="col-6">
              <h2>{this.props.title}</h2>
              <Tooltip title="Alle Einträge löschen">
                <IconButton color="primary" onClick={this.removeAllRows}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              {this.props.oldValue.length > 0 && (
                <Tooltip title={this.state.isNewValues ? 'Aktuelle Anzeige: Neue Werte' : 'Aktuelle Anzeige: AlteWerte'}>
                  <IconButton color="primary" onClick={this.handleSwitchToDuplicateCandidate}>
                    <CompareArrows />
                  </IconButton>
                </Tooltip>
              )}
            </div>
            <div className="col-6 text-right">{this.props.buttonsOnTop && this.renderButtons()}</div>
          </div>
        )}
        {this.renderInputFieldRows()}
        <div className="row">
          <div className="col-12 text-right mb-3">
            <IconButton color="primary" onClick={this.handleAddRow}>
              <AddCircleIcon />
            </IconButton>
          </div>
          <div className="col-12 text-right mb-3">{this.props.buttonsOnBottom && this.renderButtons()}</div>
        </div>
      </div>
    );
  }
}
GenericArrayForm.defaultProps = {
  isUpdate: false,

  fieldClass: 'col-6 mb-2',
  oldValue: [],
  // text elements
  buttonCancelText: 'Abbrechen',
  buttonSubmitText: 'Speichern',
  baseModel: undefined,
  title: undefined,
  buttonsOnTop: true,
  buttonsOnBottom: true,
  handleMiddleButton: undefined,
  middleButtonText: ''
};

GenericArrayForm.propTypes = {
  isUpdate: PropTypes.bool,
  formRows: PropTypes.array.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleMiddleButton: PropTypes.func,
  title: PropTypes.string,
  oldValue: PropTypes.array,
  newValue: PropTypes.array.isRequired,
  // class for input field. Only used for input fields without customClass property
  fieldClass: PropTypes.string,

  // text elements
  buttonCancelText: PropTypes.string,
  buttonSubmitText: PropTypes.string,
  middleButtonText: PropTypes.string,
  baseModel: PropTypes.object,
  buttonsOnTop: PropTypes.bool,
  buttonsOnBottom: PropTypes.bool
};

export default GenericArrayForm;
