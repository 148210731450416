import { IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import { Delete, Edit, Link as LinkIcon, OpenInNew } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

/**
 * @typedef LinkItem
 *
 * @property {string} id
 * @property {string} [name]
 * @property {string} [url]
 */

/**
 * @typedef Props
 * @property {LinkItem} [linkItem]

 *
 * @property {(link: LinkItem) => void} [onUpdate]
 * @property {(link: LinkItem) => void} [onDelete]
 * @property {(link: LinkItem) => void} [onClick]
 * @property {(link: LinkItem) => void} [onOpen]
 */

/**
 * This displays a single item for links and will
 * @param {Props} props
 *
 * @returns {JSX.Element}
 */
const LinkListItem = ({ linkItem, onUpdate, onDelete, onClick, onOpen }) => {
  const handleOnEvent = useCallback(
    /**
     *
     * @param {'delete' | 'update' | 'click' | 'open'} action
     * @returns
     */
    action => () => {
      switch (action) {
        case 'delete':
          onDelete(linkItem);
          break;
        case 'update':
          onUpdate(linkItem);
          break;
        case 'click':
          onClick(linkItem);
          break;
        case 'open':
          onOpen(linkItem);
          break;
        default:
      }
    },
    [linkItem, onUpdate, onDelete, onClick, onOpen]
  );
  return (
    <ListItem button onClick={handleOnEvent('click')}>
      <ListItemIcon>
        <LinkIcon />
      </ListItemIcon>
      <ListItemText primary={linkItem.name} secondary={linkItem.url} />
      <ListItemSecondaryAction>
        <IconButton onClick={handleOnEvent('open')}>
          <OpenInNew />
        </IconButton>
        <IconButton onClick={handleOnEvent('update')}>
          <Edit />
        </IconButton>
        <IconButton onClick={handleOnEvent('delete')}>
          <Delete />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

LinkListItem.propTypes = {
  linkItem: PropTypes.object,
  onUpdate: PropTypes.func,
  onDelete: PropTypes.func,
  onClick: PropTypes.func,
  onOpen: PropTypes.func
};

LinkListItem.defaultProps = {
  linkItem: { id: '', name: '', url: '' },
  onUpdate: () => {},
  onDelete: () => {},
  onClick: () => {},
  onOpen: () => {}
};

export default LinkListItem;
