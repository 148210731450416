import React, { PureComponent } from 'react';
import { BeatLoader } from 'react-spinners';

import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import './HelpBox.css';
import IvaPill from './IvaPill';

import TEXT from '../../text';

const FIELDS = {
  'jobs.jobText': 'Job-Titel',
  'jobs.description': 'Job-Beschreibung',
  'additionalSkills.skillName': 'Kenntnisse'
};

class HelpBox extends PureComponent {
  constructor(props) {
    super(props);

    this.onHelpBoxLinkClick = this.onHelpBoxLinkClick.bind(this);
  }

  onHelpBoxLinkClick(searchTerm) {
    // e.preventDefault();
    // console.log('warauf klicke ich hier', e.currentTarget);
    this.props.onHelpBoxLinkClick(searchTerm);
  }

  createFrequentCommonSearchTermsList() {
    return this.props.frequentCommonSearchTerms.map(searchTerm => (
      <IvaPill
        key={searchTerm}
        onClick={this.onHelpBoxLinkClick}
        label={searchTerm}
        toggleHoverOn={this.props.toggleHoverOn}
        toggleHoverOff={this.props.toggleHoverOff}
      />
    ));
  }

  createIvaSuggestionList = () => (
    <div style={{ marginTop: '1em' }}>
      {this.props.intelligentSearchSuggestions.map(fieldSuggestions => (
        <div style={{ paddingBottom: '1em', marginBottom: '1em', borderBottom: '1px solid #eee' }}>
          <p>{FIELDS[fieldSuggestions.fieldName]}</p>
          {fieldSuggestions.suggestions.map(suggestion => (
            <IvaPill
              key={suggestion.suggestionText}
              onClick={this.onHelpBoxLinkClick}
              label={suggestion.suggestionText}
              toggleHoverOn={this.props.toggleHoverOn}
              toggleHoverOff={this.props.toggleHoverOff}
            />
          ))}
        </div>
      ))}
      <BeatLoader sizeUnit="px" size={14} color="#74c3e6" loading={this.props.ivaSuggestionsAreLoading} />
    </div>
  );

  render() {
    return (
      <React.Fragment>
        <Paper className="HelpBox text-center" style={{ paddingTop: '20px', paddingBottom: '20px' }}>
          {this.props.instructionText}
          {this.createIvaSuggestionList()}
          <p>{this.props.frequentCommonSearchTerms.length > 0 ? TEXT.searchPage.helpBox.otherUsersText : ''} </p>
          {this.createFrequentCommonSearchTermsList()}
        </Paper>
        <Avatar
          src="/ivaDraft.jpg"
          style={{
            width: 80,
            height: 80,
            position: 'absolute',
            bottom: '-100px',
            right: '20px'
          }}
        />
      </React.Fragment>
    );
  }
}
HelpBox.defaultProps = {
  frequentCommonSearchTerms: []
};
HelpBox.propTypes = {
  frequentCommonSearchTerms: PropTypes.arrayOf(PropTypes.string),
  onHelpBoxLinkClick: PropTypes.func.isRequired,
  instructionText: PropTypes.string.isRequired,
  ivaSuggestionsAreLoading: PropTypes.bool.isRequired,
  intelligentSearchSuggestions: PropTypes.array.isRequired,
  toggleHoverOn: PropTypes.func.isRequired,
  toggleHoverOff: PropTypes.func.isRequired
};
export default HelpBox;
