import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isURL from 'validator/lib/isURL';
import ValidatedTextField from '../../generic_components/ValidatedTextField';
/**
 *
 * @param {{
 *  dialogState: import('../hooks/dialogAction').DialogState,
 *  initValue?: Partial<import('../hooks/link').Link>,
 *  headlineText?: React.ReactNode,
 *  descriptionText?: React.ReactNode,
 *  onSubmit: (link: import('../hooks/link').Link) => void,
 *  onClose: () => void,
 * }} param0
 * @returns
 */
const LinkModal = ({ dialogState, onClose, onSubmit, initValue, headlineText, descriptionText }) => {
  const [link, setLink] = useState({
    id: null,
    name: null,
    url: null
  });

  const handleOnClose = useCallback(() => {
    dialogState.handleClose();
    onClose();
  }, [dialogState, onClose]);

  const handleOnSubmit = useCallback(() => {
    if (!link.name || link.name === '' || !link.url || link.url === '') {
      return;
    }
    if (!isURL(link.url)) {
      return;
    }
    dialogState.handleClose();
    onSubmit(link);
  }, [dialogState, link, onSubmit]);

  const handleChangeField = useCallback(
    fieldName => event => {
      event.persist();
      setLink(prev => ({
        ...prev,
        [fieldName]: event.target.value
      }));
    },
    []
  );

  useEffect(() => {
    if (initValue) {
      setLink({
        id: initValue.id,
        name: initValue.name,
        url: initValue.url
      });
    }
  }, [initValue]);

  return (
    <Dialog open={dialogState.open} onClose={handleOnClose} aria-labelledby="form-dialog-title">
      <div className="confirmation-dialog">
        <DialogTitle>
          <div className="confirmation-dialog-title">
            <i className="material-icons confirmation-dialog-title-icon pb-3">link</i>
            {headlineText}
          </div>
        </DialogTitle>
        <DialogContent id="confirmation-dialog-content">
          <DialogContentText>
            <div className="userResetConfirmation-descriptionText">{descriptionText}</div>
            <ValidatedTextField
              label="Link Name"
              onChange={handleChangeField('name')}
              value={link.name || ''}
              customClass="col-12"
              required
              fullWidth
            />
            <ValidatedTextField
              label="Link url"
              onChange={handleChangeField('url')}
              value={link.url || ''}
              customClass="col-12"
              required
              validator="isUrl"
              fullWidth
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions className="confirmation-dialog-actions">
          <Button onClick={handleOnClose} color="secondary">
            <Typography>Abbrechen</Typography>
          </Button>
          <Button onClick={handleOnSubmit} color="secondary" variant="contained">
            <Typography>Bestätigen</Typography>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

LinkModal.propTypes = {
  dialogState: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  initValue: PropTypes.object,
  headlineText: PropTypes.node,
  descriptionText: PropTypes.node
};

LinkModal.defaultProps = {
  onClose: () => {},
  onSubmit: () => {},
  initValue: undefined,
  headlineText: null,
  descriptionText: null
};

export default LinkModal;
