import { useCallback, useMemo, useState } from 'react';

/**
 * @typedef DialogState
 * @property {boolean} open
 * @property {() => void} handleClose
 */

/**
 *
 * @returns {{
 *  dialogState: DialogState,
 *  handleOpen: () => void,
 * }}
 */
const useDialogState = () => {
  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return useMemo(
    () => ({
      dialogState: {
        open,
        handleClose
      },
      handleOpen
    }),
    [handleClose, handleOpen, open]
  );
};

export default useDialogState;
