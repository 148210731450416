import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material UI
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import { Link } from 'react-router-dom';

import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import ReminderIcon from '@material-ui/icons/Alarm';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';

// components
// basics
import $ from 'jquery';
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';

class NotificationListItem extends PureComponent {
  constructor(props) {
    super(props);

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  renderTitle() {
    return (
      <span>
        <span style={{ fontWeight: '500' }}>{this.props.title}: </span>
        {this.props.counterPart && `Ansprechpartner: ${this.props.counterPart.firstName} ${this.props.counterPart.lastName} `}
        {this.props.description.length > 0 && `Beschreibung: ${this.props.description}`}
      </span>
    );
  }

  markSingleAsRead(id) {
    const url = CONSTANTS.markCounterPartRemindersAsDoneURL;
    const requestBody = { counterPartReminderIds: [id] };
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    console.log('markSingleAsRead requestBody', requestBody);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.props.onIsReadButtonClick(true);
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  deleteCounterPartReminder(id) {
    const url = CONSTANTS.deleteCounterPartRemindersURL;
    const requestBody = { counterPartReminderIds: [id] };
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    console.log('deleteCounterPartReminder requestBody', requestBody);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        this.props.onDeleteButtonClick(true);
        console.log('deleteCounterPartReminder', responseBody);
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  onEditButtonClick = () => {
    const counterPartReminderToEdit = {
      id: this.props.id,
      companyId: this.props.companyId,
      counterPartId: this.props.counterPart.id,
      note: this.props.description,
      triggerDate: this.props.dueDate,
      notificationId: this.props.id
    };
    this.props.onEditButtonClick(counterPartReminderToEdit);
  };

  renderItem() {
    return (
      <ListItem
        button
        style={this.props.isRead ? { backgroundColor: '#e8e8e8' } : {}}
        component={Link}
        to={`/companies?id=${this.props.companyId}&cpid=${this.props.counterPart.id}`}
      >
        <ListItemAvatar>
          <Avatar>
            <ReminderIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={this.renderTitle()}
          secondary={this.props.dueDate ? UTILS.formatDateToDELocaleTime(new Date(this.props.dueDate)) : ''}
        />
        <ListItemSecondaryAction>
          <IconButton aria-label="edit" onClick={() => this.onEditButtonClick()}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="mark_unread" onClick={() => this.markSingleAsRead(this.props.id)}>
            {this.props.isRead ? <DoneIcon color="primary" /> : <DoneIcon />}
          </IconButton>
          <IconButton aria-label="Delete" onClick={() => this.deleteCounterPartReminder(this.props.id)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  }

  render() {
    return this.renderItem();
  }
}

NotificationListItem.propTypes = {
  dueDate: PropTypes.string.isRequired,

  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  isRead: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  counterPart: PropTypes.object.isRequired,
  onIsReadButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired
};
export default NotificationListItem;
