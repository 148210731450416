export default function onNextPreviousItemClick(forward = false, itemId, itemList, nameInSessionStorage, getItemFunc) {
  let displayedItemIndexes = sessionStorage.getItem(nameInSessionStorage);
  const itemIndex = itemList.findIndex(element => element.id === itemId);
  if (displayedItemIndexes) {
    displayedItemIndexes = JSON.parse(displayedItemIndexes);

    const indexInSessionState = displayedItemIndexes.findIndex(element => element === itemIndex);

    let nextIndex = 0;
    if (indexInSessionState !== undefined) {
      if (forward && indexInSessionState === displayedItemIndexes.length - 1) nextIndex = 0;
      else if (!forward && indexInSessionState === 0) nextIndex = displayedItemIndexes.length - 1;
      else nextIndex = forward ? indexInSessionState + 1 : indexInSessionState - 1;
      getItemFunc(itemList[displayedItemIndexes[nextIndex]].id);
    }
  } else {
    let nextIndex = 0;

    if (forward && itemIndex === itemList.length - 1) nextIndex = 0;
    else if (!forward && itemIndex === 0) nextIndex = itemList.length - 1;
    else nextIndex = forward ? itemIndex + 1 : itemIndex - 1;
    getItemFunc(itemList[nextIndex].id);
  }
}
