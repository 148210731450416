const staticContent = {
  CompanyCreateUpdatePopUpText: {
    titleCreate: 'Neues Unternehmen anlegen',
    titleUpdate: 'Stammdaten ändern',
    subtitle: '',
    countryDropDown: {
      textNone: 'Land auswählen',
      dropDownList: [
        'Deutschland',
        'Schweiz',
        'Österreich',
        'Afghanistan',
        'Ägypten',
        'Aland',
        'Albanien',
        'Algerien',
        'Amerikanisch-Samoa',
        'Amerikanische Jungferninseln',
        'Andorra',
        'Angola',
        'Anguilla',
        'Antarktis',
        'Antigua und Barbuda',
        'Äquatorialguinea',
        'Argentinien',
        'Armenien',
        'Aruba',
        'Ascension',
        'Aserbaidschan',
        'Äthiopien',
        'Australien',
        'Bahamas',
        'Bahrain',
        'Bangladesch',
        'Barbados',
        'Belgien',
        'Belize',
        'Benin',
        'Bermuda',
        'Bhutan',
        'Bolivien',
        'Bosnien und Herzegowina',
        'Botswana',
        'Bouvetinsel',
        'Brasilien',
        'Brunei',
        'Bulgarien',
        'Burkina Faso',
        'Burundi',
        'Chile',
        'China',
        'Cookinseln',
        'Costa Rica',
        "Cote d'Ivoire",
        'Dänemark',
        'Diego Garcia',
        'Dominica',
        'Dominikanische Republik',
        'Dschibuti',
        'Ecuador',
        'El Salvador',
        'Eritrea',
        'Estland',
        'Europäische Union',
        'Falklandinseln',
        'Färöer',
        'Fidschi',
        'Finnland',
        'Frankreich',
        'Französisch-Guayana',
        'Französisch-Polynesien',
        'Gabun',
        'Gambia',
        'Georgien',
        'Ghana',
        'Gibraltar',
        'Grenada',
        'Griechenland',
        'Grönland',
        'Großbritannien',
        'Guadeloupe',
        'Guam',
        'Guatemala',
        'Guernsey',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'Heard und McDonaldinseln',
        'Honduras',
        'Hongkong',
        'Indien',
        'Indonesien',
        'Irak',
        'Iran',
        'Irland',
        'Island',
        'Israel',
        'Italien',
        'Jamaika',
        'Japan',
        'Jemen',
        'Jersey',
        'Jordanien',
        'Kaimaninseln',
        'Kambodscha',
        'Kamerun',
        'Kanada',
        'Kanarische Inseln',
        'Kap Verde',
        'Kasachstan',
        'Katar',
        'Kenia',
        'Kirgisistan',
        'Kiribati',
        'Kokosinseln',
        'Kolumbien',
        'Komoren',
        'Kongo',
        'Kroatien',
        'Kuba',
        'Kuwait',
        'Laos',
        'Lesotho',
        'Lettland',
        'Libanon',
        'Liberia',
        'Libyen',
        'Liechtenstein',
        'Litauen',
        'Luxemburg',
        'Macao',
        'Madagaskar',
        'Malawi',
        'Malaysia',
        'Malediven',
        'Mali',
        'Malta',
        'Marokko',
        'Marshallinseln',
        'Martinique',
        'Mauretanien',
        'Mauritius',
        'Mayotte',
        'Mazedonien',
        'Mexiko',
        'Mikronesien',
        'Moldawien',
        'Monaco',
        'Mongolei',
        'Montserrat',
        'Mosambik',
        'Myanmar',
        'Namibia',
        'Nauru',
        'Nepal',
        'Neukaledonien',
        'Neuseeland',
        'Neutrale Zone',
        'Nicaragua',
        'Niederlande',
        'Niederländische Antillen',
        'Niger',
        'Nigeria',
        'Niue',
        'Nordkorea',
        'Nördliche Marianen',
        'Norfolkinsel',
        'Norwegen',
        'Oman',
        'Pakistan',
        'Palästina',
        'Palau',
        'Panama',
        'Papua-Neuguinea',
        'Paraguay',
        'Peru',
        'Philippinen',
        'Pitcairninseln',
        'Polen',
        'Portugal',
        'Puerto Rico',
        'Réunion',
        'Ruanda',
        'Rumänien',
        'Russische Föderation',
        'Salomonen',
        'Sambia',
        'Samoa',
        'San Marino',
        'São Tomé und Príncipe',
        'Saudi-Arabien',
        'Schweden',
        'Senegal',
        'Serbien und Montenegro',
        'Seychellen',
        'Sierra Leone',
        'Simbabwe',
        'Singapur',
        'Slowakei',
        'Slowenien',
        'Somalia',
        'Spanien',
        'Sri Lanka',
        'St. Helena',
        'St. Kitts und Nevis',
        'St. Lucia',
        'St. Pierre und Miquelon',
        'St. Vincent/Grenadinen (GB)',
        'Südafrika, Republik',
        'Sudan',
        'Südkorea',
        'Suriname',
        'Svalbard und Jan Mayen',
        'Swasiland',
        'Syrien',
        'Tadschikistan',
        'Taiwan',
        'Tansania',
        'Thailand',
        'Timor-Leste',
        'Togo',
        'Tokelau',
        'Tonga',
        'Trinidad und Tobago',
        'Tristan da Cunha',
        'Tschad',
        'Tschechische Republik',
        'Tunesien',
        'Türkei',
        'Turkmenistan',
        'Turks- und Caicosinseln',
        'Tuvalu',
        'Uganda',
        'Ukraine',
        'Ungarn',
        'Uruguay',
        'Usbekistan',
        'Vanuatu',
        'Vatikanstadt',
        'Venezuela',
        'Vereinigte Arabische Emirate',
        'Vereinigte Staaten von Amerika',
        'Vietnam',
        'Wallis und Futuna',
        'Weihnachtsinsel',
        'Weißrussland',
        'Westsahara',
        'Zentralafrikanische Republik',
        'Zypern'
      ]
    },
    companySizeDropDown: {
      textNone: 'keine Angaben',
      dropDownList: ['1 - 10', '10 - 100', '100 - 1.000', '1.000 - 5.000', '> 5.000']
    },
    companyDirectContactDropDown: {
      textNone: 'Keine Angabe',
      dropDownList: ['Keine Direktansprache']
    },
    industrySectorDropDown: {
      textNone: 'Branche auswählen',
      dropDownList: [
        'Agribusiness',
        'Anlagenbau',
        'Automatisierungstechnik',
        'Automobilhandel',
        'Automotive & E-Mobilität',
        'Banking & Finance',
        'Bau / TGA / FM',
        'Beratung / Consulting / Recht',
        'Bildung / Wissenschaft',
        'Chemie',
        'Consumer Goods',
        'Defence & Aerospace',
        'Dienstleistung',
        'Digital & Online Business',
        'Digitalisierung für Industrie 4.0',
        'Elektrotechnik',
        'Energie & Versorgung',
        'Engineering / TIC',
        'Entsorgung und Kreislaufwirtschaft',
        'Erneuerbare Energien / Energiewirtschaft',
        'Fashion, Media & Lifestyle',
        'Financial Services',
        'Financial Services & Real Estate',
        'Food Science & Technology',
        'Forschung & Entwicklung',
        'Foto & Imaging',
        'Games',
        'Gastgewerbe / Hotellerie',
        'Gesundheitswesen',
        'Getränke',
        'Gewerbe / Handwerk',
        'Giesserei & Aluminium',
        'Handel & Dienstleistungen',
        'Holz- & Möbelindustrie',
        'Immobilien',
        'Industrie / Technische Dienstleistungen',
        'Informationstechnologie & Telekommunikation',
        'IT & SAP',
        'Konsum- & Gebrauchsgüter',
        'Kunststofftechnik',
        'Logistik, Transport & Verkehr',
        'Maschinenbau',
        'Medien',
        'Medizintechnik',
        'Nahrungs- & Genussmittel',
        'NPO / NGO / Verbände / Vereine',
        'Öffentlicher Dienst',
        'Optoelektronik & Licht',
        'Papier- , Druck- & Verpackungsindustrie',
        'Pharma',
        'Private Equity',
        'Rechenzentren / Cloud Provider',
        'Sozialwesen',
        'Stanz- / Umformtechnik und Metallbearbeitung',
        'Steuer-, Rechts- & Finanzdienstleistungen',
        'Supply Chain Management',
        'Tourismus / Freizeit',
        'Verpackungstechnik'
      ]
    },
    inputFieldNames: [
      { companyName: 'Unternehmen' },
      { companyType: 'Unternehmenszusatz' },
      { postbox: 'Postfach' },
      { street: 'Straße' },
      { postalCode: 'Postleitzahl' },
      { city: 'Ort' },
      { country: 'Land' },
      { phoneNumber: 'Telefonnummer' },
      { faxNumber: 'Faxnummer' },
      { url: 'URL' },
      { urlCareerPage: 'Karriereseite' },
      { category: 'Kategorie' },
      { industrySector: 'Industriesektor' },
      { companySize: 'Unternehmensgröße' },
      { advisor: 'Betreuer' },
      { directContact: 'Direktansprache' },
      { comment: 'Bemerkungen' }
    ],
    snackbarInfoStart: 'Der Unternehmenseintrag "',
    snackbarInfoEnd: '" wurde angelegt',
    cancel: 'Abbrechen',
    saveEntry: 'Eintrag speichern',
    saveEntryUpdate: 'Änderungen speichern',
    updateSnackBarMessage: 'Änderungen wurden erfolgreich gespeichert',
    noChangesMadeSnackBarMessage: 'Keine Änderungen vorhanden'
  }
};

module.exports = staticContent;
