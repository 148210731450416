module.exports = {
  staticResources: {
    dropDowns: {
      status: {
        // textNone: 'Bitte wählen Sie',
        // errorMessage: 'Bitte treffen Sie eine Auswahl',
        content: ['Noch nicht freigegeben', 'In Bearbeitung', 'Erfolgreich abgeschlossen', 'Storniert']
      },
      statusDetails: {
        // errorMessage: 'Bitte treffen Sie eine Auswahl',
        content: [
          '',
          'Besetzung durch anderen DL',
          'Einstellungsstopp',
          'Interne Besetzung',
          'keine passenden Profile generiert',
          'Position on hold',
          'Prof. Zusammenarbeit unmöglich',
          'Streichung Position',
          'Unternehmen besetzt selbst'
        ]
      },
      serviceType: {
        // errorMessage: 'Bitte treffen Sie eine Auswahl',
        content: [
          '',
          'Platzierung',
          'Beratung',
          'Direct Search',
          'Executive Search',
          'RPO',
          'Outplacement',
          'Transfer',
          'Interim-Management',
          'Seminare',
          'Anzeigenschaltung',
          'Online-Platzierung',
          'Assessment',
          'Coaching'
        ]
      }
    }
  }
};
