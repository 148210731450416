import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import $ from 'jquery';
import * as CONSTANTS from '../../constants';

// import TEXT from './staticContent/text';
import * as UTILS from '../../utils/utilFunctions';
import * as CONFIG from '../../config';

class IncomingCandidateFinalStep extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      loading: false,

      preSelectedCandidateId: ''
    };

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  handleDuplicateCheckCreateNew = () => {
    this.setState({ loading: true });
    const url = CONSTANTS.createCandidateURL;
    const candidateData = this.props.selectedIncomingCandidate;
    candidateData._id = candidateData.id; // eslint-disable-line
    candidateData.candidateDB = candidateData.candidateDB === 'Ja';
    const request = {
      candidateData
    };
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: JSON.stringify(request)
    })
      .done(responseBody => {
        this.setState({
          redirect: true,
          preSelectedCandidateId: responseBody.id
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
        this.setState({ loading: false });
      });
  };

  handleDuplicateCheckMerge = () => {
    this.setState({ loading: true });
    const url = CONSTANTS.mergeCandidateURL;
    const candidateData = this.props.selectedIncomingCandidate;
    candidateData._id = this.props.selectedDuplicateCandidate.id; // eslint-disable-line
    candidateData.candidateDB = candidateData.candidateDB === 'Ja';

    const request = {
      candidateData,
      incomingCandidateId: this.props.selectedIncomingCandidate.id
    };
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: JSON.stringify(request)
    })
      .done(responseBody => {
        this.setState({
          redirect: true,
          preSelectedCandidateId: responseBody.id
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
        this.setState({ loading: false });
      });
  };

  renderInputFields() {
    return (
      <div className="container">
        <div className="row mt-3 mb-3">
          <div className="col-12 mt-3 mb-3">
            <Button
              disabled={this.state.loading}
              onClick={() => {
                const duplicateKeys = Object.keys(this.props.selectedDuplicateCandidate);
                if (duplicateKeys.length > 0) this.handleDuplicateCheckMerge();
                else this.handleDuplicateCheckCreateNew();
              }}
            >
              {this.state.loading && <CircularProgress size={20} />} Kandidat anlegen
            </Button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    if (this.state.redirect) {
      this.setState({ redirect: false });
      return <Redirect to={`/candidates?id=${this.state.preSelectedCandidateId}`} />;
    }
    console.log('incoming', this.props.selectedIncomingCandidate);
    return (
      <Paper>
        <div className="container pt-3">
          <h2>Kandidat anlegen</h2>

          {this.renderInputFields()}
        </div>
      </Paper>
    );
  }
}

IncomingCandidateFinalStep.defaultProps = {};
IncomingCandidateFinalStep.propTypes = {
  selectedIncomingCandidate: PropTypes.object.isRequired,
  selectedDuplicateCandidate: PropTypes.object.isRequired
};
export default IncomingCandidateFinalStep;
