/**
 * This will check if two arrays are equal
 *
 * use it only for elementary arrays e.g numbers, strings
 *
 * means if the have the equals items in it
 * @param {Array} arrayOne
 * @param {Array} arrayTwo
 * @return {boolean} - true if the arrays are equals
 */

export function isArrayEqual (arrayOne, arrayTwo) {
    let isEqual = false;
    if (!(arrayOne.length === arrayTwo.length)) {return false}
    if (arrayOne.length === 0 && arrayTwo.length === 0) {return true}
    for (let i = 0; i < arrayOne.length; i++) {
        isEqual = arrayTwo.indexOf(arrayOne[i]) !== -1;
    }
    return isEqual;
}

/**
 * This will compare two object arrays
 *
 * only use it for object arrays
 *
 * @param {Object[]} arrayOne
 * @param {Object[]} arrayTwo
 * @return {boolean} - true if equal false when not
 */
export function isObjectArrayEqual (arrayOne, arrayTwo) {
    if (!(arrayOne.length === arrayTwo.length)) { return false}
    const stringArrayOne = arrayOne.map(obj => JSON.stringify(obj));
    const stringArrayTwo = arrayTwo.map(obj => JSON.stringify(obj));
    return isArrayEqual(stringArrayOne, stringArrayTwo);
}
