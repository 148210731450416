/**
 * Generate csv from object array
 * @param {Object[]} data
 * @return {string} encodeURI
 */

export default function generateCSV(data) {
  if (data.length > 0) {
    const csvArray = [];

    const keys = Object.keys(data[0]);
    const escapedKeys = keys.map(key => key.replace(';', ' '));
    csvArray.push(escapedKeys);

    data.forEach(objectRow => {
      const row = [];
      keys.forEach(key => {
        if (objectRow[key]) {
          row.push(objectRow[key].toString().replace(';', ' '));
        } else {
          row.push('');
        }
      });
      csvArray.push(row);
    });

    const csvString = csvArray.map(e => e.join(';')).join('\n');
    const csvBlob = new Blob([csvString], { type: 'text/csv' });

    return encodeURI(URL.createObjectURL(csvBlob));
  }
  return undefined;
}
