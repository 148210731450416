import React from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@material-ui/core';
import 'react-image-crop/dist/ReactCrop.css';

// scale down to fit to max sizes if the picture is too big
function scaleDownIfTooBig(canvas, outputMaxWidth, outputMaxHeight) {
  if ((outputMaxWidth && outputMaxWidth < canvas.width) || (outputMaxHeight && outputMaxHeight < canvas.height)) {
    const scaledCanvas = document.createElement('canvas');

    // check if one of the max sizes is null (they are optional)
    const maxWidth = outputMaxWidth || canvas.width;
    const maxHeight = outputMaxHeight || canvas.height;

    // get the scale
    const newScale = Math.min(maxWidth / canvas.width, maxHeight / canvas.height);
    const scaleCtx = scaledCanvas.getContext('2d');

    scaledCanvas.width = Math.round(canvas.width * newScale);
    scaledCanvas.height = Math.round(canvas.height * newScale);

    // scale down the image
    scaleCtx.drawImage(canvas, 0, 0, scaledCanvas.width, scaledCanvas.height);

    return scaledCanvas;
  }
  return canvas;
}

function cropCanvas(can, pos, width, height) {
  // get your canvas and a context for it
  const ctx = can.getContext('2d');

  // get the image data you want to keep.
  const imageData = ctx.getImageData(pos.x, pos.y, pos.x + width, pos.y + height);

  // create a new cavnas same as clipped size and a context
  const newCan = document.createElement('canvas');
  newCan.width = width;
  newCan.height = height;
  const newCtx = newCan.getContext('2d');

  // put the clipped image on the new canvas.
  newCtx.putImageData(imageData, 0, 0);

  return newCan;
}

// cropImage crops the image given by imageUrl and calls onFinished with the resulting blob
function cropImage(imageUrl, x, y, width, height, outputMaxWidth, outputMaxHeight, onFinished) {
  // crop image in client using a canvas
  const canvas = document.createElement('canvas');
  if (canvas.getContext) {
    const ctx = canvas.getContext('2d');

    const img = new Image();
    img.src = imageUrl;
    canvas.width = img.width;
    canvas.height = img.height;

    img.onload = () => {
      ctx.drawImage(img, 0, 0);

      let croppedCan = cropCanvas(canvas, { x, y }, width, height);

      croppedCan = scaleDownIfTooBig(croppedCan, outputMaxWidth, outputMaxHeight);

      // create blob
      croppedCan.toBlob(onFinished, 'image/png');
    };
  }
}

const ImageCropModal = ({ imageUrl, onClose, onConfirm, aspect, outputMaxWidth, outputMaxHeight, minWidth, minHeight }) => {
  const [crop, setCrop] = React.useState({ aspect });
  const [scale, setScale] = React.useState({ x: 1, y: 1 });
  const [saving, setSaving] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  React.useEffect(() => {
    setSaving(false);
  }, [imageUrl]);

  const handleConfirm = () => {
    // crops the canvas based on the user input
    if (crop.x !== undefined && crop.y !== undefined && crop.width !== undefined && crop.height !== undefined) {
      setSaving(true);
      const width = Math.trunc(crop.width * scale.x);
      const height = Math.trunc(crop.height * scale.y);
      const x = Math.trunc(crop.x * scale.x);
      const y = Math.trunc(crop.y * scale.y);

      if (minWidth && width < minWidth) {
        setErrorMessage(`Mindestbreite ist ${minWidth} Pixel\nBitte wählen Sie einen größeren Bereich aus.`);
        setSaving(false);
        return;
      }

      if (minHeight && height < minHeight) {
        setErrorMessage(`MindestHöhe ${minHeight} Pixel\nBitte wählen Sie einen größeren Bereich aus.`);
        setSaving(false);
        return;
      }

      cropImage(imageUrl, x, y, width, height, outputMaxWidth, outputMaxHeight, blob => {
        onConfirm(blob);
        onClose();
      });
    }
  };

  return (
    <div>
      <Dialog open={!!imageUrl} onClose={onClose} aria-labelledby="form-dialog-title">
        <div className="confirmation-dialog">
          <DialogTitle>
            <div className="confirmation-dialog-title">Bild zuschneiden</div>
          </DialogTitle>
          <DialogContent>
            <ReactCrop
              src={imageUrl}
              crop={crop}
              onChange={newCrop => setCrop(newCrop)}
              onImageLoaded={image => {
                const dim = Math.min(image.width, image.height);
                const scaleX = image.naturalWidth / image.width;
                const scaleY = image.naturalHeight / image.height;
                setScale({ x: scaleX, y: scaleY });
                setCrop({ aspect, x: 0, y: 0, width: dim * aspect, height: dim });
                return false;
              }}
              imageStyle={{ maxHeight: 500 }}
            />
            {errorMessage}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color="secondary" variant="contained" disabled={saving}>
              Abbrechen
            </Button>
            <Button
              onClick={handleConfirm}
              color="primary"
              variant="contained"
              disabled={!crop || !crop.height || crop.height === 0 || !crop.width || crop.width === 0 || saving}
            >
              {saving && <CircularProgress size={24} style={{ marginRight: '8px' }} />} {'Bild zuschneiden'}
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
};
ImageCropModal.propTypes = {
  imageUrl: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  aspect: PropTypes.number,
  outputMaxWidth: PropTypes.number,
  outputMaxHeight: PropTypes.number,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number
};

ImageCropModal.defaultProps = {
  imageUrl: '',
  aspect: null,
  outputMaxWidth: null,
  outputMaxHeight: null,
  minWidth: null,
  minHeight: null
};

export default ImageCropModal;
