import { Slider, Typography } from '@material-ui/core';
import { PropTypes } from 'prop-types';
import React from 'react';

const SALARY_MARKS = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25].map(v => ({
  value: v * 10000,
  label: `${v * 10}k`
}));

const SalarySelect = ({ label, ...props }) => (
  <>
    <Typography variant="subtitle1">{label}</Typography>
    <Slider marks={SALARY_MARKS} max={250000} min={40000} step={10000} {...props} />
  </>
);

SalarySelect.defaultProps = {
  label: ''
};

SalarySelect.propTypes = {
  label: PropTypes.string
};

export default SalarySelect;
