/**
 * @typedef Sektor
 * @property {string} label
 * @property {number} id,
 */

/**
 * @typedef IndustrySektor
 * @property {string} label
 * @property {Sektor[]} values
 */

export default {
  /**
   * @type IndustrySektor
   */
  engineerAndTechnical: {
    label: 'Ingenieure und technische Berufe',
    values: [
      {
        label: 'Automatisierungstechnik',
        id: 10012000
      },
      {
        label: 'Bauwesen',
        id: 10006000
      },
      {
        label: 'Chemietechnik',
        id: 10007000
      },
      {
        label: 'Elektrotechnik, Elektronik',
        id: 10002000
      },
      {
        label: 'Energie und Umwelttechnik',
        id: 10022000
      },
      {
        label: 'Entwicklung',
        id: 10013000
      },
      {
        label: 'Fahrzeugtechnik',
        id: 10014000
      },
      {
        label: 'Fertigungstechnik',
        id: 10015000
      },
      {
        label: 'Gebäude-, Versorgungs-, Sicherheitstechnik',
        id: 10020000
      },
      {
        label: 'Konstruktion, Visualisierung',
        id: 10011000
      },
      {
        label: 'Kunststofftechnik',
        id: 10017000
      },
      {
        label: 'Leitung, Teamleitung',
        id: 10016000
      },
      {
        label: 'Luft- und Raumfahrttechnik',
        id: 10009008
      },
      {
        label: 'Maschinenbau',
        id: 10004000
      },
      {
        label: 'Materialwissenschaft',
        id: 10005000
      },
      {
        label: 'Mechatronik',
        id: 10009007
      },
      {
        label: 'Medizintechnik',
        id: 10018000
      },
      {
        label: 'Optiker, Akustiker',
        id: 10024000
      },
      {
        label: 'Projektmanagement',
        id: 10010005
      },
      {
        label: 'Prozessmanagement',
        id: 10019000
      },
      {
        label: 'Qualitätsmanagement',
        id: 10008000
      },
      {
        label: 'Servicetechniker',
        id: 10009010
      },
      {
        label: 'Technische Dokumentation',
        id: 10021000
      },
      {
        label: 'Technischer Zeichner, Bauzeichner',
        id: 10009001
      },
      {
        label: 'Veranstaltungstechnik',
        id: 10009005
      },
      {
        label: 'Verfahrenstechnik',
        id: 10001000
      },
      {
        label: 'Vertriebsingenieur',
        id: 10009009
      },
      {
        label: 'Wirtschaftsingenieur',
        id: 10023000
      },
      {
        label: 'Zahntechnik',
        id: 10009006
      },
      {
        label: 'Weitere: Ingenieure und technische Berufe',
        id: 10010000
      }
    ]
  },
  /**
   * @type IndustrySektor
   */
  it: {
    label: 'IT',
    values: [
      {
        id: 1004000,
        label: 'Anwendungsadministration'
      },
      {
        id: 1012000,
        label: 'Consulting, Engineering'
      },
      {
        id: 1005003,
        label: 'Datenbankentwicklung, Data Warehouse, Business Intelligence'
      },
      {
        id: 1005001,
        label: 'Datenbankadministration'
      },
      {
        id: 1003004,
        label: 'Embedded Systems, Firmware-Entwicklung'
      },
      {
        id: 1001000,
        label: 'Hardware-Entwicklung'
      },
      {
        id: 1007000,
        label: 'Helpdesk'
      },
      {
        id: 1008000,
        label: 'Leitung, Teamleitung'
      },
      {
        id: 1009000,
        label: 'Projektmanagement'
      },
      {
        id: 1020000,
        label: 'Prozessmanagement'
      },
      {
        id: 1011000,
        label: 'Qualitätssicherung, Qualitätsprüfung'
      },
      {
        id: 1013002,
        label: 'SAP/ERP-Beratung, Entwicklung'
      },
      {
        id: 1021000,
        label: 'Sicherheit'
      },
      {
        id: 1022000,
        label: 'Softwareentwicklung'
      },
      {
        id: 1016000,
        label: 'Systemadministration, Netzwerkadministration'
      },
      {
        id: 1017000,
        label: 'Technische Dokumentation'
      },
      {
        id: 1015000,
        label: 'Training'
      },
      {
        id: 1023000,
        label: 'Web-Entwicklung'
      },
      {
        id: 1024000,
        label: 'Wirtschaftsinformatik'
      },
      {
        id: 1013001,
        label: 'Weitere: IT'
      }
    ]
  },
  distributionAndSales: {
    label: 'Vertrieb und Verkauf',
    values: [
      {
        id: 2003000,
        label: 'Außendienst'
      },
      {
        id: 2010000,
        label: 'Immobilienmakler'
      },
      {
        id: 2006001,
        label: 'Innendienst, Sachbearbeitung'
      },
      {
        id: 2004000,
        label: 'Kundenservice'
      },
      {
        id: 2002000,
        label: 'Leitung, Teamleitung'
      },
      {
        id: 2009000,
        label: 'Pharmaberater, Pharmareferent'
      },
      {
        id: 2006004,
        label: 'Pre-Sales'
      },
      {
        id: 2001000,
        label: 'Telesales'
      },
      {
        id: 2005000,
        label: 'Verkauf (Handel)'
      },
      {
        id: 2006003,
        label: 'Vertriebsassistenz'
      },
      {
        id: 2008000,
        label: 'Weitere: Vertrieb und Verkauf'
      }
    ]
  },
  finances: {
    label: 'Finanzen',
    values: [
      {
        id: 4002001,
        label: 'Anlagebuchhaltung'
      },
      {
        id: 4008002,
        label: 'Business Analyst'
      },
      {
        id: 4001001,
        label: 'Compliance, Sicherheit'
      },
      {
        id: 4004001,
        label: 'Controlling'
      },
      {
        id: 4002002,
        label: 'Debitorenbuchhaltung'
      },
      {
        id: 4002004,
        label: 'Finanzbuchhaltung, Bilanzbuchhaltung'
      },
      {
        id: 4002005,
        label: 'Gehaltsbuchhaltung, Lohnbuchhaltung'
      },
      {
        id: 4009000,
        label: 'Konzernbuchhaltung'
      },
      {
        id: 4002003,
        label: 'Kreditorenbuchhaltung'
      },
      {
        id: 4005000,
        label: 'Leitung, Teamleitung'
      },
      {
        id: 4010000,
        label: 'Mergers & Acquisitions'
      },
      {
        id: 4011000,
        label: 'Prozessmanagement'
      },
      {
        id: 4001003,
        label: 'Revision'
      },
      {
        id: 4006000,
        label: 'Steuern'
      },
      {
        id: 4003000,
        label: 'Treasury, Cash Management'
      },
      {
        id: 4001002,
        label: 'Wirtschaftsprüfung'
      },
      {
        id: 4008001,
        label: 'Weitere: Finanzen'
      }
    ]
  },
  marketingAndCommunication: {
    label: 'Marketing und Kommunikation',
    values: [
      {
        id: 6013000,
        label: 'CRM, Direktmarketing'
      },
      {
        id: 6004000,
        label: 'Event-Marketing'
      },
      {
        id: 6010000,
        label: 'Handels-Marketing'
      },
      {
        id: 6016000,
        label: 'Journalismus, Redaktion'
      },
      {
        id: 6001000,
        label: 'Leitung, Teamleitung'
      },
      {
        id: 6012000,
        label: 'Marketingassistenz'
      },
      {
        id: 6015000,
        label: 'Marketingreferent, Marketing-Manager'
      },
      {
        id: 6005000,
        label: 'Marktforschung, Marktanalyse'
      },
      {
        id: 6008000,
        label: 'Mediaplanung, Einkauf'
      },
      {
        id: 6003000,
        label: 'Online-Marketing'
      },
      {
        id: 6006000,
        label: 'PR, Unternehmenskommunikation'
      },
      {
        id: 6014000,
        label: 'Produktmanagement'
      },
      {
        id: 6011002,
        label: 'Strategisches Marketing'
      },
      {
        id: 6009000,
        label: 'Vertriebsmarketing'
      },
      {
        id: 6011001,
        label: 'Weitere: Marketing & Werbung'
      }
    ]
  },
  administrationAndSecretariat: {
    label: 'Administration und Sekretariat',
    values: [
      {
        id: 7004000,
        label: 'Assistenz, Sekretariat'
      },
      {
        id: 7009000,
        label: 'Objektverwaltung, Immobilienverwaltung'
      },
      {
        id: 7002000,
        label: 'Office-Management'
      },
      {
        id: 7006000,
        label: 'Sachbearbeitung'
      },
      {
        id: 7008000,
        label: 'Weitere: Administration und Sekretariat'
      }
    ]
  },
  managers: {
    label: 'Führungskräfte',
    values: [
      {
        id: 11009000,
        label: 'Abteilungsleitung, Bereichsleitung'
      },
      {
        id: 11007000,
        label: 'Assistenz'
      },
      {
        id: 11008000,
        label: 'Betriebs-, Niederlassungs-, Filialleitung'
      },
      {
        id: 11003000,
        label: 'Business Development'
      },
      {
        id: 11010000,
        label: 'Teamleitung, Gruppenleitung'
      },
      {
        id: 11005000,
        label: 'Unternehmensberatung'
      },
      {
        id: 11001000,
        label: 'Vorstand, Geschäftsführung'
      },
      {
        id: 11006000,
        label: 'Weitere: Führungskräfte'
      }
    ]
  },
  naturalScienceAndResearch: {
    label: 'Naturwissenschaften und Forschung',
    values: [
      {
        id: 13005000,
        label: 'Biologie'
      },
      {
        id: 13001002,
        label: 'Biotechnologie'
      },
      {
        id: 13006000,
        label: 'Chemie'
      },
      {
        id: 13007000,
        label: 'Geowissenschaften'
      },
      {
        id: 13003000,
        label: 'Labor, Forschung'
      },
      {
        id: 13002000,
        label: 'Mathematik'
      },
      {
        id: 13009000,
        label: 'Pharmazie'
      },
      {
        id: 13008000,
        label: 'Physik'
      },
      {
        id: 13004000,
        label: 'Weitere: Naturwissenschaften und Forschung'
      }
    ]
  },
  banksFinancialServiceAndInsurance: {
    label: 'Banken, Finanzdienstleister und Versicherungen',
    values: [
      {
        id: 3001001,
        label: 'Analyst'
      },
      {
        id: 3001003,
        label: 'Anlagenberatung, Vermögensberatung'
      },
      {
        id: 3001002,
        label: 'Asset-/Fonds-Management'
      },
      {
        id: 3003002,
        label: 'Börsenhandel'
      },
      {
        id: 3001005,
        label: 'Compliance, Sicherheit'
      },
      {
        id: 3002001,
        label: 'Firmenkundengeschäft'
      },
      {
        id: 3002002,
        label: 'Investment-Banking'
      },
      {
        id: 3002004,
        label: 'Kreditanalyse'
      },
      {
        id: 3006000,
        label: 'Leitung, Teamleitung'
      },
      {
        id: 3001004,
        label: 'Mergers & Acquisitions'
      },
      {
        id: 3002003,
        label: 'Privatkundengeschäft'
      },
      {
        id: 3004002,
        label: 'Vers.-Mathematik, Produktentwicklung, Statistik'
      },
      {
        id: 3004001,
        label: 'Versicherung: Sachbearbeitung, Spezialisten'
      },
      {
        id: 3002006,
        label: 'Zahlungsverkehr'
      },
      {
        id: 3005001,
        label: 'Weitere: Banken, Finanzdienstleister und Versicherungen'
      }
    ]
  },
  personal: {
    label: 'Personal',
    values: [
      {
        id: 5003000,
        label: 'Administration'
      },
      {
        id: 5007002,
        label: 'Beratung'
      },
      {
        id: 5001000,
        label: 'Compensation und Benefits'
      },
      {
        id: 5005000,
        label: 'Entwicklung, Weiterbildung, Training'
      },
      {
        id: 5004001,
        label: 'Leitung, Teamleitung'
      },
      {
        id: 5002000,
        label: 'Lohn, Gehalt'
      },
      {
        id: 5006000,
        label: 'Recruiting, Personalmarketing'
      },
      {
        id: 5004002,
        label: 'Referent'
      },
      {
        id: 5007001,
        label: 'Weitere: Personal'
      }
    ]
  },
  craftServiceAndManufacturing: {
    label: 'Handwerk, Dienstleistung und Fertigung',
    values: [
      {
        id: 9011000,
        label: 'Arbeitssicherheit'
      },
      {
        id: 9005000,
        label: 'Bauwesen, Montage'
      },
      {
        id: 9007000,
        label: 'Beauty, Wellness'
      },
      {
        id: 9004000,
        label: 'Elektrik, Sanitär, Heizung, Klima'
      },
      {
        id: 9008000,
        label: 'Fertigung, Produktion'
      },
      {
        id: 9013000,
        label: 'Gastronomie, Hotellerie'
      },
      {
        id: 9002000,
        label: 'Holzhandwerk'
      },
      {
        id: 9009002,
        label: 'Leitung, Teamleitung'
      },
      {
        id: 9001000,
        label: 'Maler, Lackierer'
      },
      {
        id: 9010000,
        label: 'Mechaniker'
      },
      {
        id: 9003000,
        label: 'Metallhandwerk'
      },
      {
        id: 9006000,
        label: 'Nahrungsmittelherstellung, -verarbeitung'
      },
      {
        id: 9012000,
        label: 'Raumgestaltung'
      },
      {
        id: 9015000,
        label: 'Reiseverkehr, Touristik'
      },
      {
        id: 9014000,
        label: 'Sicherheitsdienste, Schutzdienste'
      },
      {
        id: 9009001,
        label: 'Weitere: Handwerk, Dienstleistung und Fertigung'
      }
    ]
  },
  purchasingMaterialsManagment: {
    label: 'Einkauf, Materialwirtschaft und Logistik',
    values: [
      {
        id: 8007000,
        label: 'Beschaffungslogistik'
      },
      {
        id: 8012000,
        label: 'Disposition'
      },
      {
        id: 8009000,
        label: 'Distributionslogistik, Transportlogistik'
      },
      {
        id: 8015000,
        label: 'Einkauf'
      },
      {
        id: 8010000,
        label: 'Entsorgungslogistik'
      },
      {
        id: 8013000,
        label: 'Fuhrparkmanagement'
      },
      {
        id: 8011000,
        label: 'Lagerlogistik'
      },
      {
        id: 8002000,
        label: 'Leitung, Teamleitung'
      },
      {
        id: 8008000,
        label: 'Produktionslogistik'
      },
      {
        id: 8014000,
        label: 'Prozessmanagement'
      },
      {
        id: 8006000,
        label: 'Supply-Chain-Management'
      },
      {
        id: 8005001,
        label: 'Weitere: Einkauf, Materialwirtschaft und Logistik'
      }
    ]
  },
  careTherapyAndAssistance: {
    label: 'Pflege, Therapie und Assistenz',
    values: [
      {
        id: 26001001,
        label: 'Altenpflege, Betreuungsberufe'
      },
      {
        id: 26001002,
        label: 'Anästhesie und Intensivpflege'
      },
      {
        id: 26001003,
        label: 'Diätassistenz'
      },
      {
        id: 26001004,
        label: 'Ergotherapie'
      },
      {
        id: 26001005,
        label: 'Gesundheits- und Kinderkrankenpflege'
      },
      {
        id: 26001006,
        label: 'Gesundheits- und Krankenpflege'
      },
      {
        id: 26001007,
        label: 'Hebamme, Entbindungshelfer'
      },
      {
        id: 26002000,
        label: 'Heilerziehungspfleger'
      },
      {
        id: 26004000,
        label: 'Leitung, Teamleitung'
      },
      {
        id: 26001008,
        label: 'Logopädie'
      },
      {
        id: 26003000,
        label: 'Pflegehelfer'
      },
      {
        id: 26001010,
        label: 'Physiotherapie'
      },
      {
        id: 26005000,
        label: 'Praxispersonal, Assistenzberufe'
      },
      {
        id: 26001011,
        label: 'Psychotherapie'
      },
      {
        id: 26001012,
        label: 'Sanitätsdienst, ambulanter Dienst'
      },
      {
        id: 26001013,
        label: 'Strahlentherapie'
      },
      {
        id: 26001014,
        label: 'Weitere: Pflege, Therapie und Assistenz'
      }
    ]
  },
  justice: {
    label: 'Recht',
    values: [
      {
        id: 12001000,
        label: 'Anwaltschaft'
      },
      {
        id: 12002000,
        label: 'Justiziariat, Rechtsabteilung'
      },
      {
        id: 12003000,
        label: 'Notar-, Justizfachangestellter, Anwaltsfachgehilfe'
      },
      {
        id: 12005000,
        label: 'Notariat'
      },
      {
        id: 12004000,
        label: 'Richter, Justizbeamte'
      },
      {
        id: 12006000,
        label: 'Weitere: Recht'
      }
    ]
  },
  designAndArchitecture: {
    label: 'Design, Gestaltung und Architektur',
    values: [
      {
        id: 21001000,
        label: 'Architektur'
      },
      {
        id: 21008000,
        label: 'Fotografie, Video'
      },
      {
        id: 21004000,
        label: 'Grafik- und Kommunikationdesign'
      },
      {
        id: 21005000,
        label: 'Medien-, Screen-, Webdesign'
      },
      {
        id: 21006000,
        label: 'Modedesign, Schmuckdesign'
      },
      {
        id: 21007000,
        label: 'Produktdesign, Industriedesign'
      },
      {
        id: 21009000,
        label: 'Theater, Schauspiel, Musik, Tanz'
      },
      {
        id: 21003000,
        label: 'Weitere: Design, Gestaltung und Architektur'
      }
    ]
  },
  educationAndSocial: {
    label: 'Bildung und Soziales',
    values: [
      {
        id: 21001000,
        label: 'Architektur'
      },
      {
        id: 21008000,
        label: 'Fotografie, Video'
      },
      {
        id: 21004000,
        label: 'Grafik- und Kommunikationdesign'
      },
      {
        id: 21005000,
        label: 'Medien-, Screen-, Webdesign'
      },
      {
        id: 21006000,
        label: 'Modedesign, Schmuckdesign'
      },
      {
        id: 21007000,
        label: 'Produktdesign, Industriedesign'
      },
      {
        id: 21009000,
        label: 'Theater, Schauspiel, Musik, Tanz'
      },
      {
        id: 21003000,
        label: 'Weitere: Design, Gestaltung und Architektur'
      },
      {
        id: 22005000,
        label: 'Ausbilder'
      },
      {
        id: 22006002,
        label: 'Berufsschule'
      },
      {
        id: 22006003,
        label: 'Erwachsenenbildung'
      },
      {
        id: 22007000,
        label: 'Erzieher'
      },
      {
        id: 22001000,
        label: 'Kindergarten, KiTa, Vorschule'
      },
      {
        id: 22008000,
        label: 'Sozialarbeit'
      },
      {
        id: 22009000,
        label: 'Leitung, Teamleitung'
      },
      {
        id: 22004000,
        label: 'Universität, Fachhochschule'
      },
      {
        id: 22002000,
        label: 'Unterricht: Grundschule'
      },
      {
        id: 22003000,
        label: 'Unterricht: Sekundarstufe'
      },
      {
        id: 22006001,
        label: 'Weitere: Bildung und Soziales'
      }
    ]
  },
  publicService: {
    label: 'Öffentlicher Dienst',
    values: [
      {
        id: 17003000,
        label: 'Angestellte, Beamte auf Bundesebene'
      },
      {
        id: 17004000,
        label: 'Angestellte, Beamte auf Landes-, kommunaler \nEbene'
      },
      {
        id: 17002000,
        label: 'Angestellte, Beamte im auswärtigen Dienst'
      },
      {
        id: 17006000,
        label: '(Bundes-)Polizei, Justizvollzug'
      },
      {
        id: 17008000,
        label: 'Bundeswehr, Wehrverwaltung'
      },
      {
        id: 17009000,
        label: 'Feuerwehr'
      },
      {
        id: 17005000,
        label: 'Steuerverwaltung, Finanzverwaltung'
      },
      {
        id: 17001000,
        label: 'Verbände, Vereine'
      },
      {
        id: 17007000,
        label: 'Weitere: Öffentlicher Dienst'
      }
    ]
  },
  doctors: {
    label: 'Ärzte',
    values: [
      {
        id: 27001001,
        label: 'Allgemeinmedizin'
      },
      {
        id: 27001002,
        label: 'Anästhesiologie'
      },
      {
        id: 27001003,
        label: 'Arbeitsmedizin'
      },
      {
        id: 27001004,
        label: 'Augenheilkunde'
      },
      {
        id: 27001005,
        label: 'Chirurgie'
      },
      {
        id: 27001006,
        label: 'Frauenheilkunde, Geburtshilfe'
      },
      {
        id: 27001008,
        label: 'Hals-Nasen-Ohrenheilkunde'
      },
      {
        id: 27001009,
        label: 'Hautkrankheiten, Geschlechtskrankheiten'
      },
      {
        id: 27001011,
        label: 'Hygienemedizin, Umweltmedizin'
      },
      {
        id: 27001012,
        label: 'Innere Medizin'
      },
      {
        id: 27001016,
        label: 'Kieferchirurgie, Mundchirurgie, Gesichtschirurgie'
      },
      {
        id: 27001020,
        label: 'Kindermedizin, Jugendmedizin'
      },
      {
        id: 27001014,
        label: 'Kinderpsychiatrie, Jugendpsychiatrie'
      },
      {
        id: 27003000,
        label: 'Klinische Forschung'
      },
      {
        id: 27001017,
        label: 'Neurochirurgie, Neurologie, Neuropathologie'
      },
      {
        id: 27001018,
        label: 'Onkologie'
      },
      {
        id: 27001019,
        label: 'Orthopädie, Unfallchirurgie'
      },
      {
        id: 27001021,
        label: 'Pathologie'
      },
      {
        id: 27001023,
        label: 'Psychiatrie, Psychotherapie'
      },
      {
        id: 27001024,
        label: 'Radiologie'
      },
      {
        id: 27001025,
        label: 'Tiermedizin'
      },
      {
        id: 27001027,
        label: 'Urologie'
      },
      {
        id: 27001028,
        label: 'Zahnmedizin'
      },
      {
        id: 27001029,
        label: 'Weitere: Ärzte'
      }
    ]
  }
};
