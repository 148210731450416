import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

// material UI
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DialogContentText from '@material-ui/core/DialogContentText';
import Accordion from '@material-ui/core/Accordion';
import Typography from '@material-ui/core/Typography';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import DeleteIcon from '@material-ui/icons/Delete';
import WillingnessToChangeCircle from '../components/WillingnessToChangeCircle';

import ShareMarkListPopUp from '../markListPage/ShareMarkListPopUp';
import AddCandidateMarkListToProjectDrawer from '../markListPage/AddCandidateMarkListToProjectDrawer';
// Icons

// components
import ConfirmationModal from '../generic_components/ConfirmationModal';
import AlertModal from '../generic_components/AlertModal';
import SerialMailDrawer from '../general/serialMail/SerialMailDrawer';

import withUser from '../utils/withUser';

import './MarkLists.css';

import * as CONSTANTS from '../constants';
import * as UTILS from '../utils/utilFunctions';
import * as markListAPI from '../general/markLists/markListAPI';
import TEXT from '../text';

const itemTypes = {
  candidateItem: 'candidate',
  companyItem: 'company',
  projectItem: 'project',
  counterPartItem: 'counterPart'
};

function prepareEmployeesKeyValuePairs(employees) {
  const mappedDropDownEntries = [{ value: '', label: 'Mitarbeiter auswählen' }];
  if (employees && employees.length > 0) {
    employees.forEach(employee => {
      if (employee.lastname) {
        const value = employee.id;
        const label = `${employee.firstname || ''} ${employee.lastname}`;
        const mappedEmployee = { value, label };
        mappedDropDownEntries.push(mappedEmployee);
      }
    });
  }
  return mappedDropDownEntries;
}

class MarkLists extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      savedListsCompanies: [],
      savedListsCandidates: [],
      savedListsProjects: [],
      savedListsCounterParts: [],
      confirmationModalListDeletion: {
        isOpen: false,
        listId: ''
      },
      confirmationModalItemRemoval: {
        isOpen: false,
        listId: '',
        itemId: '',
        selectedForDeletionItemType: ''
      },
      confirmationModalListDeletionDocuments: {
        isOpen: false
      },
      isSerialMailDrawerOpen: '', // accepts markListId; isOpen === !!markListId
      isCounterPartSerialMailDrawerOpen: '', // accepts markListId; isOpen === !!markListId
      isAddCandidateMarkListToProjectDrawerOpen: false,
      employeesByPartner: [],
      selectedCandidateMarkListForProject: ''
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    document.title = `Merklisten - ${UTILS.getTitle()}`;
    this.getEmployeeListByPartnerAPICall();
    this.loadPopulatedMarkListsAPICall();
    this.loadCounterPartListsAPICall();
  }

  sortListsByType(bulkMarkLists) {
    const markLists = bulkMarkLists;
    const result = {
      candidateMarkLists: [],
      companyMarkLists: [],
      projectMarkLists: [],
      counterPartMarkLists: []
    };

    markLists.forEach(markList => {
      if (markList.candidateMarks.length > 0) {
        result.candidateMarkLists.push(markList);
      } else if (markList.companyMarks.length > 0) {
        result.companyMarkLists.push(markList);
      } else if (markList.projectMarks.length > 0) {
        result.projectMarkLists.push(markList);
      } else if (markList.counterPartMarks.length > 0) {
        result.counterPartMarkLists.push(markList);
      }
    });
    return result;
  }

  async getEmployeeListByPartnerAPICall() {
    try {
      const response = await markListAPI.getEmployeeListByPartner();

      this.setState({
        employeesByPartner: prepareEmployeesKeyValuePairs(response)
      });
    } catch (error) {
      this.errorHandling(error);
    }
  }

  loadCounterPartListsAPICall = async () => {
    try {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
      const response = await markListAPI.loadMarkLists();
      const sortedMarkListsByType = this.sortListsByType(response.allMarkListsOfUser);

      const newCounterPartMarkLists = [];

      sortedMarkListsByType.counterPartMarkLists.forEach(list => {
        const newList = { ...list, markListId: list._id };

        newList.counterPartMarks = [];

        list.counterPartMarks.forEach(mark => {
          this.loadCounterPartsForMarkAPICall(mark.companyId, mark.counterPart).then(input => {
            const newMark = { ...mark, ...input.allCounterPartsForMark[0][0] };
            newMark.mailAddress = newMark.contactInfo.mailAddress;
            newMark.counterPartMarkId = mark._id;
            newList.counterPartMarks.push(newMark);
            this.forceUpdate();
            this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
          });
        });

        newCounterPartMarkLists.push(newList);
      });
      this.setState({
        savedListsCounterParts: newCounterPartMarkLists
      });
    } catch (error) {
      this.errorHandling(error);
    } finally {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
    }
  };

  loadCounterPartsForMarkAPICall = async (companyId, counterPartId) => {
    try {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
      return await markListAPI.loadCounterPartsForMark(companyId, counterPartId);
    } catch (error) {
      this.errorHandling(error);
      return false;
    }
  };

  loadPopulatedMarkListsAPICall = async () => {
    try {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
      const response = await markListAPI.loadPopulatedMarkLists();
      const sortedMarkListsByType = this.sortListsByType(response.allMarkListsOfUser);
      this.setState({
        savedListsCandidates: sortedMarkListsByType.candidateMarkLists,
        savedListsCompanies: sortedMarkListsByType.companyMarkLists,
        savedListsProjects: sortedMarkListsByType.projectMarkLists
      });
    } catch (error) {
      this.errorHandling(error);
    } finally {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
    }
  };

  handleOpenSerialMailDrawer = (listId, candidateCount) => {
    if (this.props.user.serialMailCount + candidateCount > this.props.user.serialMailLimit) {
      this.setState({ isSerialMailWarningOpen: true });
      return;
    }
    this.setState({ isSerialMailDrawerOpen: listId });
  };

  handleOpenCounterPartSerialMailDrawer = (listId, counterPartCount) => {
    if (this.props.user.serialMailCount + counterPartCount > this.props.user.serialMailLimit) {
      this.setState({ isSerialMailWarningOpen: true });
      return;
    }
    this.setState({ isSerialCounterPartMailDrawer: listId });
  };

  handleDeleteListAPICall = async () => {
    this.setState(prevState => ({
      confirmationModalListDeletion: {
        ...prevState.confirmationModalListDeletion,
        isOpen: false
      }
    }));

    try {
      const { listId } = this.state.confirmationModalListDeletion;
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

      await markListAPI.deleteList(listId);
      this.props.handleSnackbarOpen(TEXT.markListsPage.snackBar.success.deleteMarkList);
      await this.loadPopulatedMarkListsAPICall();
      await this.loadCounterPartListsAPICall();
    } catch (error) {
      this.errorHandling(error);
    } finally {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
    }
  };

  handleRemoveItemFromListAPICall = async () => {
    this.setState(prevState => ({
      confirmationModalItemRemoval: {
        ...prevState.confirmationModalItemRemoval,
        isOpen: false
      }
    }));
    try {
      const { listId, itemId } = this.state.confirmationModalItemRemoval;

      await markListAPI.removeItemFromList(listId, itemId);
      switch (this.state.confirmationModalItemRemoval.selectedForDeletionItemType) {
        case 'candidate':
          this.props.handleSnackbarOpen(TEXT.markListsPage.snackBar.success.removeItemFromMarkList);
          break;
        case 'company':
          this.props.handleSnackbarOpen(TEXT.markListsPage.snackBar.success.removeCompanyFromMarkList);
          break;
        case 'counterPart':
          this.props.handleSnackbarOpen(TEXT.markListsPage.snackBar.success.removeCounterPartFromMarkList);
          break;
        default:
          this.props.handleSnackbarOpen(TEXT.markListsPage.snackBar.success.genericText);
      }
      await this.loadPopulatedMarkListsAPICall();
      await this.loadCounterPartListsAPICall();
    } catch (error) {
      this.errorHandling(error);
    }
  };

  handleDeleteAllDocumentsOfCandidatesInListAPICall = async () => {
    try {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

      await markListAPI.deleteAllDocumentsOfCandidatesInList(this.state.selectedCandidateMarkList);
      this.setState({
        confirmationModalListDeletionDocuments: { isOpen: false }
      });

      this.props.handleSnackbarOpen(TEXT.markListsPage.snackBar.success.deleteCandidateDocuments);
    } catch (error) {
      this.errorHandling(error);
    } finally {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
    }
  };

  handleCloseConfirmationModal = () => {
    this.setState(prevState => ({
      confirmationModalListDeletion: {
        ...prevState.confirmationModalListDeletion,
        isOpen: false
      }
    }));
  };

  handleCloseConfirmationModalForRemovalRequest = () => {
    this.setState(prevState => ({
      confirmationModalItemRemoval: {
        ...prevState.confirmationModalItemRemoval,
        isOpen: false
      }
    }));
  };

  handleDeleteList = listId => {
    this.setState({
      confirmationModalListDeletion: {
        isOpen: true,
        listId
      }
    });
  };

  openShareMarkListPopUp = listId => {
    this.setState({
      selectedMarkListForSharing: listId,
      showShareMarkListPopUp: true
    });
  };

  openAddCandidateMarkListToProjectDrawer = listId => {
    this.setState({
      selectedCandidateMarkListForProject: listId,
      isAddCandidateMarkListToProjectDrawerOpen: true
    });
  };

  handleShareList = async (markListId, shareWithUser) => {
    this.setState({
      selectedMarkListForSharing: '',
      showShareMarkListPopUp: false
    });
    try {
      await markListAPI.shareList(markListId, shareWithUser);

      this.props.handleSnackbarOpen('Markierungsliste erfolgreich geteilt');
    } catch (error) {
      this.errorHandling(error);
    }
  };

  handleRemoveItemFromList = (listId, itemId, itemType) => {
    this.setState({
      confirmationModalItemRemoval: {
        isOpen: true,
        listId,
        itemId,
        selectedForDeletionItemType: itemType
      }
    });
  };

  renderMarkListItems(candidateMarks, companyMarks, projectMarks, counterPartMarks, listId) {
    if (candidateMarks.length === 0 && companyMarks.length === 0 && projectMarks.length === 0 && counterPartMarks.length === 0)
      return <div className="marklist-emptyList">{TEXT.markListsPage.emptyList}</div>;
    const listItems = [];
    candidateMarks.forEach(candidateMark => {
      listItems.push(
        <div className="marklist-listitem listItem" key={candidateMark.candidateMarkId}>
          <div className="capitalize-words row headline">
            <div className="col-10 headline-container">
              {`${candidateMark.lastName ? `${candidateMark.lastName}, ` : ''}${
                candidateMark.firstName ? `${candidateMark.firstName} - ` : ''
              }${candidateMark.currentJob ? candidateMark.currentJob : TEXT.commonTerms.NA}`}
            </div>
            <div className="col-2 marklist-deleteItem">
              <Button
                onClick={() => this.handleRemoveItemFromList(listId, candidateMark.candidateMarkId, itemTypes.candidateItem)}
                color="secondary"
                className="deleteListButton"
              >
                <i className="material-icons pr-1">delete_outline</i>
              </Button>
            </div>
          </div>

          {(candidateMark.willingnessToChange > 0 || candidateMark.matchPercentage > 0) && (
            <div className="row marklist-details">
              <div className="col matchPercentage  text-center">
                <div className="label">{`${TEXT.resultPage.matchPercentage}:`}</div>
                {UTILS.calcMatchStars(candidateMark.matchPercentage)}
              </div>

              <div className="col willingnessToChange text-center">
                <WillingnessToChangeCircle willingnessToChange={candidateMark.willingnessToChange} />
              </div>
            </div>
          )}
        </div>
      );
    });
    companyMarks.forEach(companyMark => {
      listItems.push(
        <div className="marklist-listitem listItem" key={companyMark.companyMarkId}>
          <div className="capitalize-words row headline">
            <div className="col-10 headline-container">{companyMark.companyName}</div>
            <div className="col-2 ">
              <IconButton
                onClick={() => this.handleRemoveItemFromList(listId, companyMark.companyMarkId, itemTypes.companyItem)}
                color="secondary"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </div>
        </div>
      );
    });
    counterPartMarks.forEach(counterPartMark => {
      listItems.push(
        <div className="marklist-listitem listItem" key={counterPartMark.counterPartMarkId}>
          <div className="capitalize-words row headline">
            <div className="col-10 headline-container">
              {counterPartMark && counterPartMark.firstName && <>{counterPartMark.firstName} </>}
              {counterPartMark && counterPartMark.lastName && <>{counterPartMark.lastName}</>}
            </div>
            <div className="col-2 ">
              <Button
                onClick={() => this.handleRemoveItemFromList(listId, counterPartMark.counterPartMarkId, itemTypes.counterPartItem)}
                color="secondary"
                className="deleteListButton"
              >
                <DeleteIcon />
              </Button>
            </div>
          </div>
        </div>
      );
    });
    projectMarks.forEach(projectMark => {
      listItems.push(
        <div className="marklist-listitem listItem" key={projectMark.projectMarkId}>
          <div className="capitalize-words row headline">
            <div className="col-12 headline-container">{projectMark.companyName}</div>
            <div className="col-6 headline-container">{projectMark.title}</div>
            <div className="col-4 headline-container">{projectMark.projectNumber}</div>
            <div className="col-2 marklist-deleteItem">
              <Button
                onClick={() => this.handleRemoveItemFromList(listId, projectMark.projectMarkId, itemTypes.projectItem)}
                color="secondary"
                className="deleteListButton"
              >
                <i className="material-icons pr-1">delete_outline</i>
              </Button>
            </div>
          </div>
        </div>
      );
    });
    return listItems;
  }

  renderDetailsButton(markListType, listId) {
    let button;
    switch (markListType) {
      case 'savedListsCandidates':
        button = (
          <Button
            component={Link}
            to={`/candidates?ls=marklist&mid=${listId}`}
            color="secondary"
            className="deleteListButton m-2"
            style={{ fontSize: '10px' }}
            variant="contained"
          >
            <i className="material-icons pr-1">info</i>
            {TEXT.markListsPage.candidateDetailsButton}
          </Button>
        );
        break;
      case 'savedListsCompanies':
        button = (
          <Button
            component={Link}
            to={`/companies?ls=marklist&mid=${listId}`}
            color="secondary"
            className="deleteListButton m-2"
            style={{ fontSize: '13px' }}
            variant="contained"
          >
            <i className="material-icons pr-1">info</i>
            {TEXT.markListsPage.companyDetailsButton}
          </Button>
        );
        break;
      case 'savedListsCounterParts':
        button = (
          <Button
            component={Link}
            to={`/companies?ls=marklist&mid=${listId}`}
            color="secondary"
            className="deleteListButton m-2"
            style={{ fontSize: '13px' }}
            variant="contained"
          >
            <i className="material-icons pr-1">info</i>
            {TEXT.markListsPage.companyDetailsButton}
          </Button>
        );
        break;
      case 'savedListsProjects':
        button = (
          <Button
            component={Link}
            to={`/projects?ls=marklist&mid=${listId}`}
            color="secondary"
            className="deleteListButton m-2"
            style={{ fontSize: '13px' }}
            variant="contained"
          >
            <i className="material-icons pr-1">info</i>
            {TEXT.markListsPage.projectDetailsButton}
          </Button>
        );
        break;
      default:
    }
    return button;
  }

  renderButtonAreaForCandidateMarkList(markListType, list) {
    const isAllowedToSeeSerialMail = true;

    return (
      <div className="d-flex flex-wrap pb-3" style={{ margin: '-0.5rem' }}>
        {this.renderDetailsButton(markListType, list.markListId)}
        {isAllowedToSeeSerialMail && (
          <Button
            id={`${list.markListId}_serialMailButton`}
            key={`${list.markListId}_serialMailButton`}
            onClick={() => this.handleOpenSerialMailDrawer(list.markListId, list.candidateMarks.length)}
            color="secondary"
            className="deleteListButton m-2"
            style={{ fontSize: '10px' }}
            variant="contained"
          >
            <i className="material-icons pr-2">email</i>
            {TEXT.markListsPage.serialMailButton}
          </Button>
        )}
        <Button
          id={`${list.markListId}_deleteListButton`}
          key={`${list.markListId}_deleteListButton`}
          onClick={() => this.handleDeleteList(list.markListId)}
          color="secondary"
          variant="contained"
          className="serialMailListButton m-2"
          style={{ fontSize: '10px' }}
        >
          <i className="material-icons pr-1">delete_outline</i>
          {TEXT.markListsPage.deleteListButton}
        </Button>
        <Button
          id={`${list.markListId}_shareListButton`}
          key={`${list.markListId}_shareListButton`}
          onClick={() => this.openShareMarkListPopUp(list.markListId)}
          color="secondary"
          variant="contained"
          className="serialMailListButton m-2"
          style={{ fontSize: '10px' }}
        >
          <i className="material-icons pr-1">send</i>
          {TEXT.markListsPage.shareListButton}
        </Button>
        {CONSTANTS.isLienert && (
          <Button
            id={`${list.markListId}_deleteDocuments`}
            key={`${list.markListId}_deleteDocuments`}
            onClick={() => {
              this.setState({
                confirmationModalListDeletionDocuments: { isOpen: true },
                selectedCandidateMarkList: list.candidateMarks
              });
            }}
            color="secondary"
            variant="contained"
            className="serialMailListButton m-2"
            style={{ fontSize: '10px' }}
          >
            <i className="material-icons pr-1">delete</i>
            {TEXT.markListsPage.candidateDeleteDocumentsButton}
          </Button>
        )}
        <Button
          id={`${list.markListId}_addCandidateButton`}
          key={`${list.markListId}_addCandidateButton`}
          onClick={() => {
            this.openAddCandidateMarkListToProjectDrawer(list.markListId);
          }}
          color="secondary"
          variant="contained"
          className="serialMailListButton m-2"
          style={{ fontSize: '10px' }}
        >
          <i className="material-icons pr-1">group_add</i>
          {TEXT.markListsPage.addCandidatemarkListToProjectButton}
        </Button>
      </div>
    );
  }

  renderButtonAreaForCounterPartMarkList(markListType, list) {
    const isAllowedToSeeSerialMail = true;

    return (
      <div className="d-flex flex-wrap pb-3" style={{ margin: '-0.5rem' }}>
        {isAllowedToSeeSerialMail && (
          <Button
            id={`${list.markListId}_serialMailButton`}
            key={`${list.markListId}_serialMailButton`}
            onClick={() => this.handleOpenCounterPartSerialMailDrawer(list.markListId, list.candidateMarks.length)} // list.markListId: 60c207c2cf141b0388d5e172, list.candidateMarks.length: 0
            color="secondary"
            className="deleteListButton m-2"
            variant="contained"
          >
            <i className="material-icons pr-2">email</i>
            {TEXT.markListsPage.serialMailButton}
          </Button>
        )}
        <Button
          id={`${list.markListId}_deleteListButton`}
          key={`${list.markListId}_deleteListButton`}
          onClick={() => this.handleDeleteList(list.markListId)}
          color="secondary"
          variant="contained"
          className="serialMailListButton m-2"
        >
          <i className="material-icons pr-1">delete_outline</i>
          {TEXT.markListsPage.deleteListButton}
        </Button>
      </div>
    );
  }

  renderButtonArea(markListType, listId) {
    return (
      <div className="d-flex flex-wrap pb-3" style={{ margin: '-0.5rem' }}>
        {this.renderDetailsButton(markListType, listId)}
        <Button
          id={listId}
          onClick={() => this.handleDeleteList(listId)}
          color="secondary"
          className="deleteListButton m-2"
          variant="contained"
          style={{ fontSize: '13px' }}
        >
          <i className="material-icons pr-1">delete_outline</i>
          {TEXT.markListsPage.deleteListButton}
        </Button>
        <Button
          id={listId}
          onClick={() => this.openShareMarkListPopUp(listId)}
          color="secondary"
          variant="contained"
          className="serialMailListButton m-2"
          style={{ fontSize: '13px' }}
        >
          <i className="material-icons pr-1">send</i>
          {TEXT.markListsPage.shareListButton}
        </Button>
      </div>
    );
  }

  renderButtonAreaForListType(markListType, list, listId) {
    if (markListType === 'savedListsCandidates') {
      return this.renderButtonAreaForCandidateMarkList(markListType, list);
    }
    if (markListType === 'savedListsCounterParts') {
      return this.renderButtonAreaForCounterPartMarkList(markListType, list);
    }
    return this.renderButtonArea(markListType, listId);
  }

  renderMarkLists(markListType) {
    let markListsForRender = [];

    if (markListType === 'savedListsCandidates') {
      markListsForRender = this.state.savedListsCandidates;
    } else if (markListType === 'savedListsCompanies') {
      markListsForRender = this.state.savedListsCompanies;
    } else if (markListType === 'savedListsProjects') {
      markListsForRender = this.state.savedListsProjects;
    } else if (markListType === 'savedListsCounterParts') {
      markListsForRender = this.state.savedListsCounterParts;
    }

    const sortedLists = markListsForRender;

    return (
      <div>
        {sortedLists.length > 0 ? (
          <div>
            {sortedLists.map(list => {
              const { name: listName, markListId: listId, candidateMarks, companyMarks, projectMarks, counterPartMarks } = list;

              return (
                <Accordion key={listId}>
                  <AccordionSummary expandIcon={<i className="material-icons">expand_more</i>}>{listName}</AccordionSummary>
                  <AccordionDetails className="AccordionClass">
                    <div className="markListsPage-listContent-container pt-0">
                      <div className="container markListsPage-listContent-button mt-0">
                        {this.renderButtonAreaForListType(markListType, list, listId)}

                        <div className="row">
                          <div className="col-12">
                            {this.renderMarkListItems(candidateMarks, companyMarks, projectMarks, counterPartMarks, listId)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </div>
        ) : (
          <div className="marklist-noListsFound">{TEXT.markListsPage.noListsExisting}</div>
        )}
      </div>
    );
  }

  renderListCategories() {
    const result = [];
    const categories = TEXT.markListsPage.categoryList;
    categories.forEach(listType => {
      const key = Object.keys(listType)[0];
      const value = listType[key];
      if (this.state[key] !== undefined) {
        if (this.state[key].length > 0) {
          result.push(
            <div className="p-3 mb-3" key={`list${key}`}>
              <Typography variant="h6" component="h6" className="pb-3">
                {value}
              </Typography>
              {this.renderMarkLists(key)}
            </div>
          );
        }
      }
    });
    if (result.length === 0)
      result.push(
        <div className="marklist-emptyList" key="no-list-available">
          {TEXT.markListsPage.noListsAvailable}
        </div>
      );
    return result;
  }

  genereateConnfirmationMalText(itemType) {
    let result = '';
    switch (itemType) {
      case 'candidate':
        result = TEXT.markListsPage.removeItemConfirmationModul;
        break;
      case 'company':
        result = TEXT.markListsPage.removeCompanyConfirmationModul;
        break;
      case 'project':
        result = TEXT.markListsPage.removeProjectConfirmationModul;
        break;
      default:
        break;
    }
    return result;
  }

  renderSerialMailDrawer() {
    return (
      <React.Fragment>
        {this.state.isSerialMailDrawerOpen && (
          <SerialMailDrawer
            open={!!this.state.isSerialMailDrawerOpen}
            markList={
              this.state.savedListsCandidates.filter(list => list.markListId === this.state.isSerialMailDrawerOpen)[0].candidateMarks
            }
            markListId={this.state.isSerialMailDrawerOpen}
            handleClose={() => this.setState({ isSerialMailDrawerOpen: '' })}
            liftUpStateToApp={this.props.liftUpStateToApp}
            handleSnackbarOpen={this.props.handleSnackbarOpen}
            serialMailType="candidates"
          />
        )}
      </React.Fragment>
    );
  }

  // TODO: maybe refactor code to combine renderSerialMailDrawer & renderCounterPartSerialMailDrawer
  renderCounterPartSerialMailDrawer() {
    return (
      <React.Fragment>
        {this.state.isSerialCounterPartMailDrawer && (
          <SerialMailDrawer
            open={!!this.state.isSerialCounterPartMailDrawer}
            markList={
              this.state.savedListsCounterParts.filter(list => list.markListId === this.state.isSerialCounterPartMailDrawer)[0]
                .counterPartMarks
            }
            markListId={this.state.isSerialCounterPartMailDrawer}
            handleClose={() => this.setState({ isSerialCounterPartMailDrawer: '' })}
            liftUpStateToApp={this.props.liftUpStateToApp}
            handleSnackbarOpen={this.props.handleSnackbarOpen}
            serialMailType="counterParts"
          />
        )}
      </React.Fragment>
    );
  }

  onSelectionOfShareWithUser = e => {
    const inputFieldValue = e.target.value;
    this.setState({ selectedShareWithUser: inputFieldValue });
  };

  render() {
    return (
      <div className="markListsPage h-100vh">
        {this.renderSerialMailDrawer()}
        {this.renderCounterPartSerialMailDrawer()}
        <div className="markListsPage-listOverview-container">
          {this.renderListCategories()}
          {this.state.confirmationModalListDeletion.isOpen && (
            <ConfirmationModal
              key="confirmation-list-deletion"
              handleClose={this.handleCloseConfirmationModal}
              open={this.state.confirmationModalListDeletion.isOpen}
              buttonPrimaryAction={this.handleDeleteListAPICall}
              headlineText={TEXT.markListsPage.deletionConfirmationModul.headline}
              decriptionText={TEXT.markListsPage.deletionConfirmationModul.description}
            />
          )}
          {this.state.confirmationModalListDeletionDocuments.isOpen && (
            <ConfirmationModal
              key="confirmation-list-deletion-documents"
              handleClose={() => {
                this.setState({
                  confirmationModalListDeletionDocuments: { isOpen: false }
                });
              }}
              open={this.state.confirmationModalListDeletionDocuments.isOpen}
              buttonPrimaryAction={this.handleDeleteAllDocumentsOfCandidatesInListAPICall}
              headlineText="Alle Kandidatendokumente löschen?"
              decriptionText="Wollen Sie wirklich alle Kandidatendokumente löschen"
              contentText="Dieser Schritt kann nicht rückgängig gemacht werden"
            />
          )}
          {this.state.confirmationModalItemRemoval.isOpen && (
            <ConfirmationModal
              key="confirmation-item-removal-from-list"
              handleClose={this.handleCloseConfirmationModalForRemovalRequest}
              open={this.state.confirmationModalItemRemoval.isOpen}
              buttonPrimaryAction={this.handleRemoveItemFromListAPICall}
              headlineText={
                this.genereateConnfirmationMalText(this.state.confirmationModalItemRemoval.selectedForDeletionItemType).headline
              }
              decriptionText={
                this.genereateConnfirmationMalText(this.state.confirmationModalItemRemoval.selectedForDeletionItemType).description
              }
              buttonPrimaryText={
                this.genereateConnfirmationMalText(this.state.confirmationModalItemRemoval.selectedForDeletionItemType).buttonPrimaryText
              }
            />
          )}
          {this.state.showShareMarkListPopUp && (
            <ShareMarkListPopUp
              key="shareMarkList_popUp"
              handleClose={() => this.setState({ showShareMarkListPopUp: false })}
              open={this.state.showShareMarkListPopUp}
              buttonPrimaryAction={() => this.handleShareList(this.state.selectedMarkListForSharing, this.state.selectedShareWithUser)}
              headlineText="Markierungsliste teilen"
              buttonPrimaryText="Liste teilen"
              icon="send"
              employeesByPartner={this.state.employeesByPartner}
              selectedShareWithUser={this.state.selectedShareWithUser}
              onChange={this.onSelectionOfShareWithUser}
            />
          )}
          {this.state.isAddCandidateMarkListToProjectDrawerOpen && (
            <AddCandidateMarkListToProjectDrawer
              open={this.state.isAddCandidateMarkListToProjectDrawerOpen}
              handleClose={() => {
                this.setState({
                  isAddCandidateMarkListToProjectDrawerOpen: false
                });
              }}
              candidateMarkListId={this.state.selectedCandidateMarkListForProject}
              handleSnackbarOpen={this.props.handleSnackbarOpen}
            />
          )}
          {this.state.isSerialMailWarningOpen && (
            <AlertModal
              open={this.state.isSerialMailWarningOpen}
              handleClose={() => {
                this.setState({
                  isSerialMailWarningOpen: false
                });
              }}
              headlineText="Tägliches Limit für Serienmails überschritten"
            >
              <DialogContentText>
                Ihr tägliches Limit von {this.props.user.serialMailLimit} Serienmails reicht nicht aus, um eine Serienmail an alle
                Kandidaten dieser Liste zu verschicken.
              </DialogContentText>
              <DialogContentText>
                Bitte versuchen Sie es später erneut, oder wenden Sie sich an einen Partner übergreifenden Administrator, um das Limit
                erhöhen zu lassen.
              </DialogContentText>
            </AlertModal>
          )}
        </div>
      </div>
    );
  }
}
MarkLists.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};
export default withUser(MarkLists);
