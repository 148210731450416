import { Icon, Popover, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import FolderIcon from '@material-ui/icons/Folder';
import React from 'react';
import { Link } from 'react-router-dom';
import HTMLNotes from '../components/HTMLNotes';

const Rating = {
  A: '1',
  AB: '1 - 2',
  B: '2',
  C: '3'
};

const id = {
  name: 'id',
  options: {
    display: 'excluded',
    filter: false
  }
};

const candidateId = {
  name: 'candidateId',
  options: {
    display: 'excluded',
    filter: false
  }
};

const firstName = {
  name: 'firstName',
  label: 'Vorname',
  options: {
    sort: true,
    filter: true,
    customBodyRender: (value, metaData) => <Link to={`candidates?id=${metaData.rowData[1]}`}>{value}</Link>
  }
};

const lastName = {
  name: 'lastName',
  label: 'Name',
  options: {
    sort: true,
    filter: true,
    customBodyRender: (value, metaData) => <Link to={`candidates?id=${metaData.rowData[1]}`}>{value}</Link>
  }
};

const dossier = {
  name: 'dossier',
  label: 'Dossier',
  options: {
    sort: true,
    filter: true,
    /**
     * @param {{value: any, handleOpen: () => void}} value
     * @return {JSX.Element}
     */
    customBodyRender: value => (
      <IconButton disabled={!value.value} onClick={value.handleOpen}>
        <Icon className="material-icons">description</Icon>
      </IconButton>
    )
  }
};

const dateOfBirth = {
  name: 'dateOfBirth',
  label: 'Geburtsdatum',
  options: {
    sort: true,
    filter: true,
    /**
     * @param {string} value
     * @return {string|*}
     */
    customBodyRender: value => {
      const date = new Date(value);
      if (date.toString() !== 'Invalid Date') {
        return date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
      }
      return value;
    }
  }
};

const residence = {
  name: 'residence',
  label: 'Wohnort',
  options: {
    sort: true,
    filter: true
  }
};

const period = {
  name: 'period',
  label: 'Zeitraum',
  options: {
    sort: true,
    filter: true
  }
};

const employer = {
  name: 'employer',
  label: 'Unternehmen',
  options: {
    sort: true,
    filter: true
  }
};

const position = {
  name: 'position',
  label: 'Position',
  options: {
    sort: true,
    filter: true
  }
};

const rating = {
  name: 'rating',
  label: 'Bewertung',
  options: {
    sort: true,
    filter: true,
    /**
     * @param {string} value
     * @return {JSX.Element}
     */
    customBodyRender: value => value && <Typography align="center">{Rating[value]}</Typography>
  }
};

const training = {
  name: 'training',
  label: 'Ausbildung',
  options: {
    sort: true,
    filter: true,
    display: false,
    /**
     *
     * @param {string} value
     * @return {JSX.Element}
     */
    customBodyRender: value => <HTMLNotes html={value} />
  }
};

const rejection = {
  name: 'rejection',
  label: 'Abgesagt',
  options: {
    sort: true,
    filter: true,
    customBodyRender: value => {
      const date = new Date(value);
      if (date.toString() !== 'Invalid Date') {
        return date.toLocaleDateString('de-DE', { day: '2-digit', month: '2-digit', year: 'numeric' });
      }
      return value;
    }
  }
};

const proceeding = {
  name: 'proceeding',
  label: 'Weiteres Vorgehen',
  options: {
    sort: true,
    filter: true
  }
};

const RenderPopover = value => {
  if (value.comment && value.comment !== '') {
    const elementId = value.isOpen ? 'simple-popover' : undefined;
    return (
      <div>
        <IconButton aria-describedby={elementId} variant="contained" onClick={value.handleClick}>
          <Icon>infoIcon</Icon>
        </IconButton>
        <Popover
          id={elementId}
          open={value.isOpen}
          anchorEl={value.anchorEl}
          onClose={value.handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          classes={{ paper: 'htmlPopoverPaper' }}
        >
          <Typography className="htmlpopover" dangerouslySetInnerHTML={{ __html: value.comment }} />
        </Popover>
      </div>
    );
  }
  return null;
};

const customerComment = {
  name: 'customerComment',
  label: 'Bemerkung des Kunden',
  options: {
    sort: true,
    filter: true,
    /**
     * @param {string} value
     * @return {JSX.Element}
     */
    customBodyRender: RenderPopover
  }
};

const projectComment = {
  name: 'projectComment',
  label: 'Beraterkommentar',
  options: {
    customBodyRender: RenderPopover
  }
};

const hideInCustomerPortal = {
  name: 'hideInCustomerPortal',
  label: 'Beraterkommentar Freigegeben',
  options: {
    sort: true,
    filter: true,
    /**
     *
     * @param {boolean} value
     * @returns {JSX.Element}
     */
    customBodyRender: value => {
      if (!value) {
        return (
          <Icon align="center" class="td-icon-center material-icons">
            done
          </Icon>
        );
      }
      return (
        <Icon align="center" class="td-icon-center material-icons">
          close
        </Icon>
      );
    }
  }
};

const additionalDocuments = {
  name: 'additionalDocuments',
  label: 'Ergänzende Dokumente',
  options: {
    sort: true,
    filter: true,
    /**
     * @param {() => void} value
     * @return {JSX.Element}
     */
    customBodyRender: value => (
      <IconButton onClick={value.handleOpen}>
        {value.additionalDocuments.length >= 1 && <FolderIcon className="material-icons" />}
        {value.additionalDocuments.length <= 0 && <CreateNewFolderOutlinedIcon className="material-icons" />}
      </IconButton>
    )
  }
};

export const longListColumns = [
  id,
  candidateId,
  firstName,
  lastName,
  dateOfBirth,
  residence,
  period,
  employer,
  position,
  training,
  rejection,
  rating,
  dossier,
  proceeding,
  customerComment,
  projectComment,
  hideInCustomerPortal
];

export const shortlistColumns = [
  id,
  candidateId,
  firstName,
  lastName,
  dateOfBirth,
  residence,
  period,
  employer,
  position,
  training,
  rejection,
  rating,
  dossier,
  additionalDocuments,
  proceeding,
  customerComment,
  projectComment,
  hideInCustomerPortal
];
