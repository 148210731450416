import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { TextField, FormControl, Typography, Divider } from '@material-ui/core';

class InterimPersonalInformation extends PureComponent {
  renderTextField = (label, value) => (
    <div key={label} className="col-6 pb-1">
      <TextField label={label} value={value} InputProps={{ disableUnderline: true, readOnly: true }} />
    </div>
  );

  render() {
    const labels = [
      { label: 'Land', name: 'country' },
      { label: 'Ort', name: 'city' },
      { label: 'PLZ', name: 'postalCode' },
      { label: 'Straße', name: 'street' },
      { label: 'Hausnummner', name: 'streetNumber' },
      { label: 'Mobilnummer', name: 'mobile' },
      { label: 'Telefonnummer', name: 'phone' },
      { label: 'Webseite', name: 'website' },
      { label: 'Xing Profil', name: 'xingUrl' },
      { label: 'LinkedIn Profil', name: 'linkedinUrl' }
    ];

    return (
      <div className="pb-4">
        <Typography variant="h5" component="h5">
          Stammdaten
        </Typography>
        <Divider className="mb-4" />
        <FormControl required>
          <div className="row">
            {labels.map(entry =>
              this.renderTextField(
                entry.label,
                this.props.personalInformation[entry.name] ? this.props.personalInformation[entry.name] : 'Keine Angaben'
              )
            )}
          </div>
        </FormControl>
      </div>
    );
  }
}

InterimPersonalInformation.propTypes = {
  personalInformation: PropTypes.object.isRequired
};

export default InterimPersonalInformation;
