import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import { Typography, Divider, Grid } from '@material-ui/core';
import FileList from '../generic_components/file_util_components/FileList';
import styles from './ProjectCustomerInfoTab.module.css';
import { getProjectAdvertDocumentById } from './api/projectArchiveAPI';

// eslint-disable-next-line react/prop-types
const Section = ({ title, children }) => (
  <div className="mb-4">
    <Typography variant="h5" component="h5">
      {title}
    </Typography>
    <Divider className="mb-3" />
    <Grid container spacing={2}>
      {children}
    </Grid>
  </div>
);

const Field = ({ label, children }) => (
  <Grid item md={6} xs={12}>
    <div className={styles.label}>{label}</div>
    <div className={styles.children}>
      {typeof children === 'string' ? (children || '').split('\n').map(line => <div>{line}</div>) : children}
    </div>
  </Grid>
);

Field.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

function formatAge(age) {
  if (!age[0] || !age[1] || (age[0] === 15 && age[1] === 99)) {
    return 'ohne Einschränkung';
  }
  if (age[0] === 15) {
    return `bis max. ${age[1]}`;
  }
  if (age[1] === 99) {
    return `ab ${age[0]}`;
  }
  return `${age[0]} bis ${age[1]}`;
}

const ProjectCustomerInfoTab = ({ customerInformation, projectId }) => {
  function getDocumentUrl(fileName) {
    console.log('fileName', fileName);
    return getProjectAdvertDocumentById(projectId)
      .then(responseBody => responseBody.contentUrl)
      .catch(err => console.error(err));
  }

  return (
    <>
      {customerInformation ? (
        <div className="container-fluid">
          <Paper className="p-3">
            <Section title="Position / Stellenprofil">
              <Field label="Position / Anzeigentitel">{customerInformation.title}</Field>
              <Field label="Arbeitsort">
                {customerInformation.address && `${customerInformation.address.postalCode} ${customerInformation.address.city}`}
              </Field>
              <Field label="Funktion">{customerInformation.positionFunction}</Field>
              <Field label="Aufgaben, Schwerpunkte">{customerInformation.tasks}</Field>
              <Field label="Führungsverantwortung">{customerInformation.superiors}</Field>
              <Field label="Teammitglied">{customerInformation.subordinates}</Field>
              <Field label="Verantwortung für Budget/Umsatz etc.">{customerInformation.respBudget}</Field>
              <Field label="Verantwortung für Mitarbeiter">{customerInformation.respEmployees}</Field>
              <Field label="Standard- / Spezialfunktion">{customerInformation.specialTasks}</Field>
              <Field label="Sonstige Punkte">{customerInformation.other}</Field>
            </Section>
            <Section title="Anforderungen an den Kandidaten">
              <Field label="Alter">{formatAge(customerInformation.age || [])}</Field>
              <Field label="Ausbildung">{customerInformation.education}</Field>
              <Field label="Berufliche Entwicklung">{customerInformation.career}</Field>
              <Field label="Fachkenntnisse">{customerInformation.skills}</Field>
              <Field label="Zusatzqualifikationen">{customerInformation.qualifications}</Field>
              <Field label="Führungserfahrung erforderlich">{customerInformation.leadershipExp ? 'ja' : 'nein'}</Field>
            </Section>
            <Section title="Vorgeschichte der Position">
              <Field label="Erfolgte Wechsel">{customerInformation.previousChanges}</Field>
              <Field label="Gründe für die Einrichtung der Position">{customerInformation.reasonsForEstablishment}</Field>
              <Field label="Bisherige Rekrutierungsbemühungen">{customerInformation.recruiting}</Field>
              <Field label="Gründe, warum der Kandidat zum Unternehmen wechseln soll">{customerInformation.reasonsForChange}</Field>
            </Section>
            <Section title="Dotierung">
              <Field label="Zieljahreseinkommen">{(customerInformation.salary || []).map(v => v.toLocaleString('de')).join(' bis ')}</Field>
              <Field label="Maximal möglicher Betrag">
                {customerInformation.maxSalary && customerInformation.maxSalary.toLocaleString('de')}
              </Field>
              <Field label="Tarifgruppe">{customerInformation.wageGroup}</Field>
              <Field label="Erfolgsbestandteile / Variable Komponenten">{customerInformation.compensation}</Field>
              <Field label="Arbeitszeit / Modelle">{customerInformation.workingTime}</Field>
              <Field label="Sozialleistungen / Benefits">{customerInformation.benefits}</Field>
              <Field label="Perspektive">{customerInformation.perspective}</Field>
            </Section>
            <Section title="Weitere Angaben">
              <Field label="Link zu bestehender Anzeige">
                <>
                  {customerInformation.advertLink ? (
                    <a href={customerInformation.advertLink} target="_blank" rel="noopener noreferrer">
                      {customerInformation.advertLink}
                    </a>
                  ) : (
                    <></>
                  )}
                </>
              </Field>
              <Field label="Stellenanzeige / Anforderungsprofil">
                <>
                  {customerInformation.advertFile ? (
                    <div>
                      <FileList fileNameList={customerInformation.advertFile} onResolveFileUrl={getDocumentUrl} />
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              </Field>
              <Field label="Sonstige Punkte">{customerInformation.other}</Field>
            </Section>
          </Paper>
        </div>
      ) : (
        <div>Kein Kunden-Stellenprofil vorhanden</div>
      )}
    </>
  );
};

ProjectCustomerInfoTab.propTypes = {
  customerInformation: PropTypes.object.isRequired,
  projectId: PropTypes.string.isRequired
};

export default React.memo(ProjectCustomerInfoTab);
