import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// external libraries
import $ from 'jquery';

import withUser from '../utils/withUser';

import CandidatePositions from './CandidatePositions';
import CandidateEducations from './CandidateEducations';
// import CandidatePastContacts from './CandidatePastContacts';
import CandidateLanguageSkills from './CandidateLanguageSkills';
import CandidateAdditionalSkills from './CandidateAdditionalSkills';

import ConfirmationModal from '../generic_components/ConfirmationModal';

import * as CONFIG from '../config';
import * as CONSTANTS from '../constants';
import * as UTILS from '../utils/utilFunctions';
import TEXT_GENERAL from '../text';

class CandidateProfile extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      deletionRequest: {
        showConfirmationPopUpForEntry: null,
        entryId: ''
      }
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  handleRemoveEntryAPICall = () => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.removeCandidateSubSchemaEntryURL;

    const payload = {
      candidateId: this.props.selectedCandidate.id,
      subSchemaName: this.state.deletionRequest.showConfirmationPopUpForEntry,
      subSchemaId: this.state.deletionRequest.entryId
    };
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.deleteSuccess);
        this.props.refreshCandidate();
        this.handleCloseConfirmationPopUp();
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  handleDeletionRequest = (subSchemaName, entryId) => {
    this.setState(
      {
        deletionRequest: {
          showConfirmationPopUpForEntry: subSchemaName,
          entryId
        }
      },
      console.log('updatedState deletionRequest', this.state.deletionRequest)
    );
  };

  handleCloseConfirmationPopUp = () => {
    this.setState({
      deletionRequest: { showConfirmationPopUpForEntry: null }
    });
  };

  mapEntryText(subSchemaName) {
    let entryType = 'Eintrag';
    if (subSchemaName === 'jobs') entryType = 'Berufserfahrung';
    if (subSchemaName === 'educations') entryType = 'Ausbildungseintrag';
    if (subSchemaName === 'languageSkills') entryType = 'Spracherfahrung';
    if (subSchemaName === 'additionalSkills') entryType = 'Erfahrungseintrag';
    return entryType;
  }

  renderRemoveEntryConfirmationPopUp() {
    const subSchemaName = this.state.deletionRequest.showConfirmationPopUpForEntry;
    const entryText = this.mapEntryText(subSchemaName);
    return (
      <ConfirmationModal
        key="confirmation-entry-deletion"
        handleClose={this.handleCloseConfirmationPopUp}
        open={!!subSchemaName}
        buttonPrimaryAction={this.handleRemoveEntryAPICall}
        headlineText={`${entryText} löschen`}
        descriptionText="Soll der entsprechende Eintrag gelöscht werden?"
      />
    );
  }

  render() {
    const isConfirmationPopUpOpen = !!this.state.deletionRequest.showConfirmationPopUpForEntry;
    return (
      <div>
        {isConfirmationPopUpOpen && this.renderRemoveEntryConfirmationPopUp()}
        <CandidatePositions
          data={this.props.selectedCandidate}
          handleOpenCandidateJobUpdatePopUp={this.props.handleOpenCandidateJobUpdatePopUp}
          handleDeleteEntry={this.handleDeletionRequest}
        />
        <CandidateEducations
          data={this.props.selectedCandidate}
          handleOpenCandidateEducationUpdatePopUp={this.props.handleOpenCandidateEducationUpdatePopUp}
          handleDeleteEntry={this.handleDeletionRequest}
        />
        <div className="CandidateEducations row mt-3">
          <div className="col-6">
            <CandidateLanguageSkills
              data={this.props.selectedCandidate}
              handleOpenCandidateLanguageUpdatePopUp={this.props.handleOpenCandidateLanguageUpdatePopUp}
              handleDeleteEntry={this.handleDeletionRequest}
            />
          </div>
          <div className="col-6">
            <CandidateAdditionalSkills
              data={this.props.selectedCandidate}
              handleOpenCandidateSkillUpdatePopUp={this.props.handleOpenCandidateSkillUpdatePopUp}
              handleDeleteEntry={this.handleDeletionRequest}
            />
          </div>
        </div>
      </div>
    );
  }
}

CandidateProfile.defaultProps = {};
CandidateProfile.propTypes = {
  selectedCandidate: PropTypes.object.isRequired,
  handleOpenCandidateJobUpdatePopUp: PropTypes.func.isRequired,
  handleOpenCandidateEducationUpdatePopUp: PropTypes.func.isRequired,
  handleOpenCandidateSkillUpdatePopUp: PropTypes.func.isRequired,
  handleOpenCandidateLanguageUpdatePopUp: PropTypes.func.isRequired,
  refreshCandidate: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};
export default withUser(CandidateProfile);
