const positionDescriptions = [
  {
    category: 'Vorstand / Geschäftsleitung',
    label: 'MD / CEO / General Manager'
  },
  {
    category: 'Vorstand / Geschäftsleitung',
    label: 'CTO (Chief Technical Officer)'
  },
  {
    category: 'Vorstand / Geschäftsleitung',
    label: 'COO (Chief Operating Officer) '
  },
  {
    category: 'Vorstand / Geschäftsleitung',
    label: 'CFO (Chief Financial Officer)'
  },
  {
    category: 'Vorstand / Geschäftsführung',
    label: 'Partner / Geschäftsführender Gesellschafter'
  },
  {
    category: 'Vorstand / Geschäftsführung',
    label: 'Non Executive Director / Mitglied Aufsichtsrat / Beirat'
  },
  {
    category: 'Vorstand / Geschäftsleitung',
    label: 'CIO (Chief Information Officer)'
  },
  {
    category: 'Vorstand / Geschäftsleitung',
    label: 'CMO (Chief Marketing Officer)'
  },
  {
    category: 'Vorstand / Geschäftsleitung',
    label: 'CSO (Chief Sales Officer)'
  },
  { category: 'Vorstand / Geschäftsleitung', label: 'Chief Supply Officer' },
  {
    category: 'Vorstand / Geschäftsleitung',
    label: 'CHRO (Chief Human Resources Officer)'
  },
  {
    category: 'Vorstand / Geschäftsleitung',
    label: 'CPO (Chief Procurement Officer)'
  },
  {
    category: 'Call Center Management',
    label: 'Call / Customer Services Center Direktor/Manager'
  },
  {
    category: 'Unternehmenskommunikation',
    label: 'Corporate Brand / PR Managerin'
  },
  {
    category: 'Unternehmenskommunikation',
    label: 'Investor Relations Manager'
  },
  { category: 'Compliance', label: 'Compliance Direktor / Manager' },
  { category: 'Risiko', label: 'Risk Director / Manager' },
  { category: 'Bau und Immobilien', label: 'Architekt / Designer' },
  { category: 'Bau und Immobilien', label: 'Bauingenieur/in' },
  { category: 'Bau und Immobilien', label: 'Statiker/in' },
  { category: 'Bau und Immobilien', label: 'Gebäudemanagement' },
  { category: 'Bau und Immobilien', label: 'Projektleiter /Direktor' },
  { category: 'Bau und Immobilien', label: 'Bauträger' },
  { category: 'Bau und Immobilien', label: 'Bauleiter' },
  { category: 'Bau und Immobilien', label: 'Sachverständiger' },
  {
    category: 'Unternehmen / Soziale Verantwortung',
    label: 'Unternehmen / Soziale Verantwortung Managerin'
  },
  {
    category: 'Ingenieurwesen / Technik',
    label: 'Technischer Direktor/Engineeringmanagerin'
  },
  {
    category: 'Ingenieurwesen / Technik',
    label: 'Luft- und Raumfahrt/Luftfahrtingenieurin'
  },
  { category: 'Ingenieurwesen / Technik', label: 'Fahrzeugtechniker' },
  { category: 'Ingenieurwesen / Technik', label: 'Chemieingenieurwesen' },
  { category: 'Ingenieurwesen / Technik', label: 'Elektrotechniker' },
  { category: 'Ingenieurwesen / Technik', label: 'Umweltingenieur/in' },
  { category: 'Ingenieurwesen / Technik', label: 'Wirtschaftsingenieurwesen' },
  {
    category: 'Ingenieurwesen / Technik',
    label: 'Instrumentierungsingenieurin'
  },
  { category: 'Ingenieurwesen / Technik', label: 'Maschinenbautechniker' },
  {
    category: 'Ingenieurwesen / Technik',
    label: 'Metallurgie / Werkstofftechniker'
  },
  { category: 'Ingenieurwesen / Technik', label: 'Fertigungsingenieurin' },
  { category: 'Finanzen', label: 'Hauptbuchhalter / Konzernrechnungsprüfer' },
  { category: 'Finanzen', label: 'Finanzanalytiker / Kreditanalytiker' },
  { category: 'Finanzen', label: 'Chief Risk Officer' },
  { category: 'Finanzen', label: 'Finanzcontroller' },
  {
    category: 'Finanzen',
    label: 'Corporate Finance Spezialist für Unternehmensfinanzierung'
  },
  { category: 'Finanzen', label: 'Commercial Finance Manager' },
  { category: 'Finanzen', label: 'Finanzdirektor / Manager' },
  { category: 'Finanzen', label: 'Fondsmanager' },
  { category: 'Finanzen', label: 'Internes Audit Manager / Direktor' },
  {
    category: 'Finanzen',
    label: 'Investment Analyst / Berater / Manager / Manager'
  },
  { category: 'Finanzen', label: 'Pension Manager / Administrator' },
  { category: 'Finanzen', label: 'Steuerberater / Spezialist' },
  { category: 'Finanzen', label: 'Treasurer' },
  { category: 'Finanzen', label: 'Investment Banking Manager' },
  { category: 'Finanzen', label: 'Controlling Manager / Leiter Controlling' },
  { category: 'Finanzen', label: 'Wirtschaftsprüfer' },
  { category: 'Finanzen', label: 'Aktuarielles' },
  {
    category: 'Gesundheit und Sicherheit',
    label: 'Gesundheit und Sicherheit Direktor/Manager'
  },
  {
    category: 'Personalwesen (HR)',
    label: 'Personaldirektor / Leiter Personalwesen / Personalverantwortlicher'
  },
  {
    category: 'Personalwesen (HR)',
    label: 'Leiter Vergütung / Sozialleistungen'
  },
  {
    category: 'Personalwesen (HR)',
    label: 'HR Geschäftspartner / HR Generalist'
  },
  { category: 'Personalwesen (HR)', label: 'Leiter Lernen und Entwicklung' },
  { category: 'Personalwesen (HR)', label: 'Leiter Organisationsentwicklung' },
  {
    category: 'Personalwesen (HR)',
    label: 'Personalbeschaffung / Resourcing Manager / Talentakquise'
  },
  {
    category: 'Human Resources (HR)',
    label: 'Talent Management / Führungskräfteentwicklung'
  },
  { category: 'Personalwesen (HR)', label: 'Trainingsmanagerin' },
  { category: 'Informationstechnologie (IT)', label: 'IT-Auditorin' },
  {
    category: 'Informationstechnologie (IT)',
    label: 'Unternehmensanalyse / Berater'
  },
  {
    category: 'Informationstechnologie (IT)',
    label: 'IT-Leiter / Manager / Leiter / Leiter IT '
  },
  { category: 'Informationstechnologie (IT)', label: 'Leiter Technologie' },
  {
    category: 'Informationstechnologie (IT)',
    label: 'IT-Projekt / Programmdirektor / Manager'
  },
  {
    category: 'Informationstechnologie (IT)',
    label: 'IT-Entwicklungsbeauftragter'
  },
  { category: 'Informationstechnologie (IT)', label: 'Softwareentwickler' },
  { category: 'Informationstechnologie (IT)', label: 'Digital Manager' },
  {
    category: 'Informationstechnologie (IT)',
    label: 'Webdesigner / Entwickler'
  },
  { category: 'Informationstechnologie (IT)', label: 'Datenanalystin' },
  {
    category: 'Informationstechnologie (IT)',
    label: 'Business Intelligence Manager'
  },
  { category: 'Informationstechnologie (IT)', label: 'IT-Berater / Manager' },
  { category: 'Informationstechnologie (IT)', label: 'ERP Berater Manager' },
  { category: 'Informationstechnologie (IT)', label: 'Product-Manager' },
  { category: 'Informationstechnologie (IT)', label: 'Product-Owner' },
  { category: 'Marketing', label: 'Produkt-/Markenverantwortlicher / Manager' },
  { category: 'Marketing', label: 'Category Manager' },
  { category: 'Marketing', label: 'Channel Marketing Manager' },
  { category: 'Marketing', label: 'Manager für Marketingkommunikation' },
  { category: 'Marketing', label: 'Designer / Leiter Design' },
  { category: 'Marketing', label: 'Marketing Direktor / Manager' },
  { category: 'Marketing', label: 'Direct Marketing Manager' },
  { category: 'Marketing', label: 'Marketing Research Manager' },
  { category: 'Marketing', label: 'Online Marketing Manager' },
  { category: 'Marketing', label: 'CRM Manager' },
  { category: 'Fertigung und Produktion', label: 'Betriebsleiter / Manager' },
  { category: 'Fertigung und Produktion', label: 'Betriebsleiter' },
  {
    category: 'Fertigung und Produktion',
    label: 'Produktion / Fertigungsleiter / Leiter / Manager'
  },
  {
    category: 'Fertigung und Produktion',
    label: 'Technischer Direktor / Manager'
  },
  {
    category: 'Fertigung und Produktion',
    label: 'Qualitätsmanager / Direktor'
  },
  {
    category: 'Beschaffung',
    label: 'Beschaffung / Einkaufsleiter / Leiter / Manager'
  },
  { category: 'Beschaffung', label: 'Einkauf' },
  { category: 'Beschaffung', label: 'Rohstoffe' },
  { category: 'Beschaffung', label: 'Operations' },
  { category: 'Beschaffung', label: 'Lieferantenmanagement' },
  { category: 'Beschaffung', label: 'Direkte Kategorien' },
  { category: 'Beschaffung', label: 'Indirekte Kategorien' },
  { category: 'Projektmanagement (nicht IT)', label: 'Change Manager' },
  {
    category: 'Projektmanagement (nicht IT)',
    label: 'Projekt / Programmdirektor / Manager / Manager'
  },
  {
    category: 'Projektmanagement (nicht IT)',
    label: 'Lean Management Spezialist / Manager'
  },
  { category: 'Professionelle Dienstleistungen', label: 'Rechtsanwalt' },
  { category: 'Professionelle Dienstleistungen', label: 'Unternehmensberater' },
  {
    category: 'Vertrieb / Business Development',
    label: '(Key) Account Manager / Director'
  },
  {
    category: 'Vertrieb / Geschäftsentwicklung',
    label: 'Business Development Direktor / Manager'
  },
  {
    category: 'Vertrieb / Geschäftsentwicklung',
    label: 'Bid Director / Manager'
  },
  {
    category: 'Vertrieb / Business Development',
    label: 'Kaufmännischer Leiter / Manager'
  },
  {
    category: 'Vertrieb / Business Development',
    label: 'Vertragsdirektor / Manager'
  },
  {
    category: 'Vertrieb / Geschäftsentwicklung',
    label: 'E-Commerce Spezialist'
  },
  {
    category: 'Vertrieb / Geschäftsentwicklung',
    label: 'Inside Sales / Sales Support Manager'
  },
  {
    category: 'Vertrieb / Business Development',
    label: 'Vertriebsleiter / Manager'
  },
  {
    category: 'Supply Chain / Logistik',
    label: 'Supply Chain Direktor / Manager'
  },
  {
    category: 'Lieferkette / Logistik',
    label: 'Vertriebs- und Betriebsplanung'
  },
  {
    category: 'Supply Chain / Logistik',
    label: 'Distribution / Logistik Direktor / Manager / Manager'
  },
  {
    category: 'Supply Chain / Logistik',
    label: 'Logistics Operations Director / Manager'
  },
  { category: 'Supply Chain / Logistik', label: 'Lagerleiter / Direktor' },
  { category: 'Supply Chain / Logistik', label: 'Transportdirektor / Manager' },
  { category: 'Supply Chain / Logistik', label: 'Lagerleiter / Manager' }
];

module.exports = positionDescriptions;
