import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

// material UI
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';

class HistoryDrawer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { changeHistory: [] };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  transition(props) {
    return <Slide direction="up" mountOnEnter unmountOnExit {...props} />;
  }

  loadHistory() {
    const url = this.props.url;
    const requestBody = this.props.payload;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    console.log('getChangeHistory requestBody', requestBody);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API loadHistory response body: ', responseBody);
        console.log('API /loadHistory response status: ', status);
        this.setState({ changeHistory: responseBody });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  componentDidMount() {
    this.loadHistory();
  }

  renderHistorylist() {
    const array = [];
    this.state.changeHistory.forEach(change => {
      const changeDate = new Date(change.changeDate);
      const oldValueText = change.oldValue ? `OldFieldValue: ${change.oldValue} ` : 'New entry created ';
      let newValueText = `NewFieldValue: ${change.newValue ? change.newValue : 'false'}`;
      if (change.newValue && change.newValue.length > 100) {
        newValueText = `NewFieldValue: ${change.newValue.substr(0, 100)}...`;
      }
      array.push(
        <ListItem divider>
          <i className="material-icons">change_history</i>
          <ListItemText
            inset
            primary={`Fieldname: ${change.changedField}`}
            secondary={`${changeDate.toLocaleString()} by ${change.user.name} (${change.user.email})`}
          />
          <i className="material-icons pr-3">update</i>
          <ListItemText primary={`${newValueText}`} secondary={`${oldValueText}`} />
        </ListItem>
      );
    });
    return array;
  }

  render() {
    return (
      <Dialog fullScreen open={this.props.open} onClose={this.props.handleClose} TransitionComponent={this.transition}>
        <AppBar style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton color="inherit" onClick={this.props.handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              Änderungsverlauf
            </Typography>
          </Toolbar>
        </AppBar>
        <List>{this.renderHistorylist()}</List>
      </Dialog>
    );
  }
}

HistoryDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,

  url: PropTypes.string.isRequired,
  payload: PropTypes.object.isRequired
};

export default HistoryDrawer;
