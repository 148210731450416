import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  MenuItem,
  Paper,
  Select,
  Slide,
  Typography
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import { ArrowBack, ArrowForward, Delete } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { Editor } from '@tinymce/tinymce-react';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { Document } from 'react-pdf/dist/entry';
import { lienert as THEME } from '../theme';
import { FurtherProceeding } from './hooks/ProjectSelectionHook';
import useProjectSelectionPopUp from './hooks/ProjectSelectionPopUpHook';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const ProjectSelectionPopUp = ({
  open,
  title,
  listType,
  selectedRating,
  selectedComment,
  handleClose,
  selectedCandidateId,
  selectedProceeding,
  selectedClientComment,
  selectedProject,
  liftUpSelectedProject,
  selectedPotentialCandidateId,
  selectedDossierList,
  selectedHideInCustomerPortal,
  navigateSelectionPopup,
  refreshProject
}) => {
  const {
    handleChangeComment,
    handleChangeProceeding,
    handleChangeRating,
    handleSubmit,
    deleteDossier,
    proceeding,
    dossierURL,
    handlePdfPages,
    setPdfPageNumber,
    pdfPageNumber,
    handelChangeHideFromClient,
    hideFromClient,
    rating,
    saving
  } = useProjectSelectionPopUp(
    selectedPotentialCandidateId,
    selectedCandidateId,
    selectedProceeding,
    selectedRating,
    selectedComment,
    selectedProject,
    selectedDossierList,
    listType,
    selectedHideInCustomerPortal
  );

  const [dossierSize, setDossierSize] = useState(8);

  const handleGoForward = useCallback(() => {
    if (navigateSelectionPopup) {
      navigateSelectionPopup(1);
    }
  }, [navigateSelectionPopup]);

  const handleGoBackward = useCallback(() => {
    if (navigateSelectionPopup) {
      navigateSelectionPopup(-1);
    }
  }, [navigateSelectionPopup]);

  const handleRefreshDossier = useCallback(async () => {
    await deleteDossier();
    refreshProject();
    handleClose();
  }, [refreshProject, deleteDossier, handleClose]);

  const RatingButton = useCallback(
    () => (
      <Box>
        <Button
          className=""
          aria-label="Edit"
          style={
            rating === 'A'
              ? { color: THEME.app.palette.primary.main, fontWeight: '600' }
              : {
                  color: THEME.app.palette.primary.veryLight,
                  fontWeight: '400'
                }
          }
          onClick={() => handleChangeRating('A')}
        >
          1
        </Button>

        <Button
          className=""
          aria-label="Edit"
          style={
            rating === 'AB'
              ? { color: THEME.app.palette.primary.main, fontWeight: '600' }
              : {
                  color: THEME.app.palette.primary.veryLight,
                  fontWeight: '400'
                }
          }
          onClick={() => handleChangeRating('AB')}
        >
          1 - 2
        </Button>

        <Button
          className=""
          aria-label="Edit"
          style={
            rating === 'B'
              ? { color: THEME.app.palette.primary.main, fontWeight: '600' }
              : {
                  color: THEME.app.palette.primary.veryLight,
                  fontWeight: '400'
                }
          }
          onClick={() => handleChangeRating('B')}
        >
          2
        </Button>
        <Button
          className=""
          aria-label="Edit"
          style={
            rating === 'C'
              ? { color: THEME.app.palette.primary.main, fontWeight: '600' }
              : {
                  color: THEME.app.palette.primary.veryLight,
                  fontWeight: '400'
                }
          }
          onClick={() => handleChangeRating('C')}
        >
          3
        </Button>
      </Box>
    ),
    [rating, handleChangeRating]
  );

  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition}>
      <AppBar style={THEME.appBar.projects}>
        <Toolbar>
          <Box flex={1} display="flex" flexWrap="nowrap" flexShrink={1} alignItems="center" overflow="hidden">
            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography
              style={{ flexGrow: 1, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}
              variant="h6"
              color="inherit"
            >
              {title}
            </Typography>
          </Box>

          <Box flex={1} textAlign="center">
            <ButtonGroup size="large">
              <IconButton onClick={handleGoBackward} color="inherit">
                <ArrowBack />
              </IconButton>
              <IconButton onClick={handleGoForward} color="inherit">
                <ArrowForward />
              </IconButton>
            </ButtonGroup>
          </Box>
          <Box flex={1} display="flex" justifyContent="end" gap={2}>
            <Button color="inherit" variant="outlined" onClick={() => handleSubmit(selectedPotentialCandidateId, liftUpSelectedProject)}>
              Speichern
              {saving && <CircularProgress className="ml-2" size={24} color="secondary" />}
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box marginTop={10}>
          <Grid container>
            <Grid item md={dossierSize}>
              <Paper elevation={0} style={{ maxHeight: '83vh', overflow: 'auto', margin: '5px', textAlign: 'center' }}>
                <Document
                  style={{ text: 'center' }}
                  file={dossierURL}
                  loading={<CircularProgress size={110} thickness={3} />}
                  noData={<Typography variant="subtitle1">Keine Daten verfügbar!</Typography>}
                  error={<Typography variant="subtitle1">Fehler beim Laden der PDF-Datei!</Typography>}
                  onLoadSuccess={setPdfPageNumber}
                  renderMode="canvas"
                >
                  {handlePdfPages(pdfPageNumber)}
                </Document>
              </Paper>
            </Grid>
            <Grid item md={12 - dossierSize}>
              <Grid container justifyContent="center" spacing={4}>
                <Grid item md={4}>
                  <Button onClick={handleRefreshDossier} color="secondary" startIcon={<Delete />}>
                    Dossier löschen
                  </Button>
                </Grid>
                <Grid item md={8}>
                  <Box textAlign="right">
                    <Typography variant="subtitle2">Breite Dossier</Typography>
                    <ButtonGroup value={dossierSize} onChange={(e, value) => setDossierSize(value)}>
                      <Button onClick={() => setDossierSize(6)}>1:1</Button>
                      <Button onClick={() => setDossierSize(8)}>2:1</Button>
                      <Button onClick={() => setDossierSize(9)}>3:1</Button>
                    </ButtonGroup>
                  </Box>
                </Grid>
                <Grid item md={6}>
                  <Typography variant="subtitle2">Weiteres Vorgehen:</Typography>
                  <Select value={proceeding} onChange={handleChangeProceeding} title="Weiteres Vorgehen" fullWidth>
                    <MenuItem value="" key="EMPTY">
                      &nbsp;
                    </MenuItem>
                    {Object.keys(FurtherProceeding).map(key => (
                      <MenuItem value={key} key={key}>
                        {FurtherProceeding[key]}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item md={6}>
                  <Typography variant="subtitle2">Bewertung:</Typography>
                  <RatingButton />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item md={12}>
                  <Typography variant="subtitle2">Kommentar:</Typography>
                  <Editor
                    initialValue={selectedComment}
                    value={selectedComment}
                    init={{
                      language_url: '/langs/de.js',
                      branding: false,
                      height: '300',
                      plugins: 'lists'
                    }}
                    onEditorChange={value => {
                      handleChangeComment(value);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={<Checkbox checked={hideFromClient} onChange={handelChangeHideFromClient} />}
                  label="Berater-Kommentar im Kundenportal verbergen"
                />
              </Grid>
              <Grid container spacing={4}>
                <Grid item md={12}>
                  <Typography variant="subtitle2">Kunden-Bemerkung: (nicht bearbeitbar):</Typography>
                  {/* eslint-disable-next-line react/no-danger */}
                  <div dangerouslySetInnerHTML={{ __html: selectedClientComment }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProjectSelectionPopUp;

ProjectSelectionPopUp.propTypes = {
  title: PropTypes.string,
  listType: PropTypes.string.isRequired,
  selectedPotentialCandidateId: PropTypes.string.isRequired,
  selectedCandidateId: PropTypes.string.isRequired,
  selectedProject: PropTypes.object.isRequired,
  liftUpSelectedProject: PropTypes.func.isRequired,
  selectedClientComment: PropTypes.string,
  selectedRating: PropTypes.string.isRequired,
  selectedHideInCustomerPortal: PropTypes.bool.isRequired,
  selectedComment: PropTypes.string.isRequired,
  selectedProceeding: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  selectedDossierList: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  navigateSelectionPopup: PropTypes.func,
  refreshProject: PropTypes.func.isRequired
};

ProjectSelectionPopUp.defaultProps = {
  title: '',
  selectedClientComment: '',
  navigateSelectionPopup: undefined
};
