import { post } from '../../utils/apiUtils';
import * as CONSTANTS from '../../constants';

/**
 * The UserActivity DTO
 * @typedef {Object} UserActivityDTO
 * @property {string} _id
 * @property {string} user - UserId
 * @property {string} [actionType] - the action type
 * @property {string} [actionSubType] - Sub type of the action
 * @property {string} [actionDetails] - Detailed description
 * @property {string} creationDate - ISO date string
 */

/**
 * Collects all activities by counterPart id
 * @param {string} counterPartId - counterPartId
 * @return {Promise<UserActivityDTO[]>} - UserActivityDTO[]
 */
export default function getUserActivities(counterPartId) {
  const payload = {
    counterPartId
  };
  return post(CONSTANTS.getUserActivityURL, payload);
}
