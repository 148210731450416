const industrySectors = [
  { category: 'Baugewerbe', label: 'Architektur & Bauplanung' },
  { category: 'Baugewerbe', label: 'Industrielle Automatisierung' },
  { category: 'Baugewerbe', label: 'Baugewerbe' },
  { category: 'Baugewerbe', label: 'Glas, Keramik & Zement' },
  { category: 'Baugewerbe', label: 'Hoch- und Tiefbau' },
  { category: 'Baugewerbe', label: 'Maschinenbau & Betriebstechnik' },
  { category: 'Baugewerbe', label: 'Baumaterialien' },
  { category: 'Baugewerbe', label: 'Gewerbeimmobilien' },
  { category: 'Baugewerbe', label: 'Immobilien' },
  { category: 'Dienstleistungen', label: 'Freizeiteinrichtungen & -dienste' },
  { category: 'Dienstleistungen', label: 'Umweltdienste' },
  { category: 'Dienstleistungen', label: 'Sicherheits- & Ermittlungsdienste' },
  { category: 'Dienstleistungen', label: 'Lebensmittelherstellung' },
  { category: 'Dienstleistungen', label: 'Gebäudeverwaltung' },
  { category: 'Dienstleistungen', label: 'Bibliothekswesen' },
  { category: 'Dienstleistungen', label: 'Informationsdienste' },
  { category: 'Dienstleistungen', label: 'Religiöse Einrichtungen' },
  { category: 'Dienstleistungen', label: 'Übersetzung und Lokalisierung' },
  { category: 'Dienstleistungen', label: 'Paket- und Frachttransport' },
  { category: 'Dienstleistungen', label: 'Soziale Dienstleistungen' },
  { category: 'Dienstleistungen', label: 'Hotel- und Gaststättengewerbe' },
  { category: 'Dienstleistungen', label: 'Soziale Einrichtungen' },
  { category: 'Dienstleistungen', label: 'Verbraucherdienste' },
  { category: 'Dienstleistungen', label: 'Veranstaltungsdienste' },
  { category: 'Dienstleistungen', label: 'Restaurants' },
  { category: 'Dienstleistungen', label: 'Tourismus und Freizeit' },
  { category: 'Dienstleistungen', label: 'Lebensmittel & Getränke' },
  {
    category: 'Dienstleistungen für Unternehmen',
    label: 'Berufliche Schulungen & Coaching'
  },
  { category: 'Dienstleistungen für Unternehmen', label: 'Gebäudeverwaltung' },
  {
    category: 'Dienstleistungen für Unternehmen',
    label: 'Programmentwicklung'
  },
  {
    category: 'Dienstleistungen für Unternehmen',
    label: 'Veranstaltungsdienste'
  },
  {
    category: 'Dienstleistungen für Unternehmen',
    label: 'Öffentlichkeitsarbeit'
  },
  { category: 'Dienstleistungen für Unternehmen', label: 'Import und Export' },
  {
    category: 'Dienstleistungen für Unternehmen',
    label: 'Personalberatung & -vermittlung'
  },
  { category: 'Dienstleistungen für Unternehmen', label: 'Marktforschung' },
  { category: 'Dienstleistungen für Unternehmen', label: 'Buchhaltung' },
  {
    category: 'Dienstleistungen für Unternehmen',
    label: 'Outsourcing & Offshoring'
  },
  {
    category: 'Dienstleistungen für Unternehmen',
    label: 'Sicherheits- & Ermittlungsdienste'
  },
  { category: 'Dienstleistungen für Unternehmen', label: 'Gewerbeimmobilien' },
  {
    category: 'Dienstleistungen für Unternehmen',
    label: 'Management-Beratung'
  },
  { category: 'Dienstleistungen für Unternehmen', label: 'Personalwesen' },
  {
    category: 'Dienstleistungen für Unternehmen',
    label: 'Übersetzung und Lokalisierung'
  },
  {
    category: 'Dienstleistungen für Unternehmen',
    label: 'Marketing und Werbung'
  },
  {
    category: 'Dienstleistungen für Unternehmen',
    label: 'Büromaterial und -ausstattung'
  },
  {
    category: 'Dienstleistungen für Unternehmen',
    label: 'Logistik und Beschaffung'
  },
  { category: 'Fertigung', label: 'Automobil' },
  { category: 'Fertigung', label: 'Energieversorgung' },
  { category: 'Fertigung', label: 'Nanotechnologie' },
  { category: 'Fertigung', label: 'Bergbau & Metallverarbeitung' },
  { category: 'Fertigung', label: 'Fluggesellschaften' },
  { category: 'Fertigung', label: 'Lebensmittelherstellung' },
  { category: 'Fertigung', label: 'Erdöl & Energiewirtschaft' },
  { category: 'Fertigung', label: 'Kunststoffe' },
  { category: 'Fertigung', label: 'Textilien' },
  { category: 'Fertigung', label: 'Chemie' },
  { category: 'Fertigung', label: 'Konsumgüter' },
  { category: 'Fertigung', label: 'Papier & Forstprodukte' },
  { category: 'Fertigung', label: 'Möbel' },
  { category: 'Fertigung', label: 'Schiffbau' },
  { category: 'Fertigung', label: 'Einzelhandel' },
  { category: 'Fertigung', label: 'Luft- & Raumfahrt' },
  { category: 'Fertigung', label: 'Glas, Keramik & Zement' },
  { category: 'Fertigung', label: 'Erneuerbare Energie & Umwelt' },
  { category: 'Fertigung', label: 'Maschinenbau & Betriebstechnik' },
  { category: 'Finanzwesen', label: 'Versicherungswesen' },
  { category: 'Finanzwesen', label: 'Vermögensverwaltung' },
  { category: 'Finanzwesen', label: 'Bankwesen' },
  { category: 'Finanzwesen', label: 'Finanzdienstleistungen' },
  { category: 'Finanzwesen', label: 'Immobilien' },
  { category: 'Finanzwesen', label: 'Buchhaltung' },
  { category: 'Finanzwesen', label: 'Investment Banking' },
  { category: 'Finanzwesen', label: 'Kapitalmärkte' },
  { category: 'Finanzwesen', label: 'Venture Capital und Private Equity' },
  { category: 'Finanzwesen', label: 'Gewerbeimmobilien' },
  { category: 'High Tech', label: 'IT und Services' },
  { category: 'High Tech', label: 'Venture Capital und Private Equity' },
  { category: 'High Tech', label: 'Biotechnologie' },
  { category: 'High Tech', label: 'Computer-Software' },
  { category: 'High Tech', label: 'Wireless' },
  { category: 'High Tech', label: 'Fluggesellschaften' },
  { category: 'High Tech', label: 'Pharmazie' },
  { category: 'High Tech', label: 'Nanotechnologie' },
  { category: 'High Tech', label: 'Computer-Netzwerke' },
  { category: 'High Tech', label: 'Computer- & Netzwerksicherheit' },
  { category: 'High Tech', label: 'Halbleiter' },
  { category: 'High Tech', label: 'Internet' },
  { category: 'High Tech', label: 'Computer-Hardware' },
  { category: 'High Tech', label: 'Telekommunikation' },
  { category: 'High Tech', label: 'Verteidigung & Raumfahrt' },
  { category: 'Konsumgüter', label: 'Weine und Spirituosen' },
  { category: 'Konsumgüter', label: 'Lebensmittelherstellung' },
  { category: 'Konsumgüter', label: 'Sportartikel' },
  { category: 'Konsumgüter', label: 'Import und Export' },
  { category: 'Konsumgüter', label: 'Kosmetik' },
  { category: 'Konsumgüter', label: 'Tabak' },
  { category: 'Konsumgüter', label: 'Immobilien' },
  { category: 'Konsumgüter', label: 'Luxusgüter & Schmuck' },
  { category: 'Konsumgüter', label: 'Einzelhandel' },
  { category: 'Konsumgüter', label: 'Verpackungsindustrie' },
  { category: 'Konsumgüter', label: 'Bekleidung & Mode' },
  { category: 'Konsumgüter', label: 'Großhandel' },
  { category: 'Konsumgüter', label: 'Elektro-/Elektronik-Herstellung' },
  { category: 'Konsumgüter', label: 'Konsumgüter' },
  { category: 'Konsumgüter', label: 'Unterhaltungselektronik' },
  { category: 'Konsumgüter', label: 'Möbel' },
  { category: 'Konsumgüter', label: 'Supermärkte' },
  { category: 'Kunst und Kultur', label: 'Design' },
  { category: 'Kunst und Kultur', label: 'Kino und Film' },
  { category: 'Kunst und Kultur', label: 'Schreiben und Redigieren' },
  { category: 'Kunst und Kultur', label: 'Kunstgewerbe' },
  { category: 'Kunst und Kultur', label: 'Schöne Künste' },
  { category: 'Kunst und Kultur', label: 'Fotografie' },
  { category: 'Kunst und Kultur', label: 'Animation' },
  { category: 'Kunst und Kultur', label: 'Musik' },
  { category: 'Kunst und Kultur', label: 'Darstellende Künste' },
  { category: 'Kunst und Kultur', label: 'Museen und Kulturstätten' },
  { category: 'Kunst und Kultur', label: 'Grafikdesign' },
  { category: 'Landwirtschaft', label: 'Landwirtschaft' },
  { category: 'Landwirtschaft', label: 'Fischereiwirtschaft' },
  { category: 'Landwirtschaft', label: 'Milchwirtschaft' },
  { category: 'Landwirtschaft', label: 'Viehwirtschaft' },
  { category: 'Medien', label: 'Online-Medien' },
  { category: 'Medien', label: 'Druckwesen' },
  { category: 'Medien', label: 'Computerspiele' },
  { category: 'Medien', label: 'Kino und Film' },
  { category: 'Medien', label: 'Marketing und Werbung' },
  { category: 'Medien', label: 'Fotografie' },
  { category: 'Medien', label: 'Kunstgewerbe' },
  { category: 'Medien', label: 'Schreiben und Redigieren' },
  { category: 'Medien', label: 'Rundfunk und Fernsehen' },
  { category: 'Medien', label: 'Design' },
  { category: 'Medien', label: 'Schöne Künste' },
  { category: 'Medien', label: 'Darstellende Künste' },
  { category: 'Medien', label: 'Grafikdesign' },
  { category: 'Medien', label: 'Unterhaltung' },
  { category: 'Medien', label: 'Medienproduktion' },
  { category: 'Medien', label: 'Museen und Kulturstätten' },
  { category: 'Medien', label: 'Verlagswesen' },
  { category: 'Medien', label: 'Bibliothekswesen' },
  { category: 'Medien', label: 'Animation' },
  { category: 'Medien', label: 'Informationsdienste' },
  { category: 'Medien', label: 'Presse' },
  {
    category: 'Medizin und Gesundheitswesen',
    label: 'Gesundheit, Wellness & Fitness'
  },
  { category: 'Medizin und Gesundheitswesen', label: 'Humanmedizin' },
  { category: 'Medizin und Gesundheitswesen', label: 'Alternative Medizin' },
  { category: 'Medizin und Gesundheitswesen', label: 'Tiermedizin' },
  { category: 'Medizin und Gesundheitswesen', label: 'Medizintechnik' },
  { category: 'Medizin und Gesundheitswesen', label: 'Biotechnologie' },
  { category: 'Medizin und Gesundheitswesen', label: 'Pharmazie' },
  {
    category: 'Medizin und Gesundheitswesen',
    label: 'Psychologie & Psychotherapie'
  },
  {
    category: 'Medizin und Gesundheitswesen',
    label: 'Krankenhaus & Gesundheitsbereich'
  },
  { category: 'Organisationen und Nonprofits', label: 'Think Tanks' },
  {
    category: 'Organisationen und Nonprofits',
    label: 'Intl. Handel & Entwicklung'
  },
  { category: 'Organisationen und Nonprofits', label: 'Soziale Einrichtungen' },
  {
    category: 'Organisationen und Nonprofits',
    label: 'Management von Nonprofit-Organisationen'
  },
  { category: 'Organisationen und Nonprofits', label: 'Fundraising' },
  {
    category: 'Organisationen und Nonprofits',
    label: 'Religiöse Einrichtungen'
  },
  {
    category: 'Organisationen und Nonprofits',
    label: 'Soziale Dienstleistungen'
  },
  { category: 'Organisationen und Nonprofits', label: 'Programmentwicklung' },
  {
    category: 'Organisationen und Nonprofits',
    label: 'Politische Organisationen'
  },
  { category: 'Organisationen und Nonprofits', label: 'Verbraucherdienste' },
  {
    category: 'Organisationen und Nonprofits',
    label: 'Sicherheits- & Ermittlungsdienste'
  },
  {
    category: 'Organisationen und Nonprofits',
    label: 'Erneuerbare Energie & Umwelt'
  },
  {
    category: 'Organisationen und Nonprofits',
    label: 'Alternative Schlichtungsmethoden'
  },
  { category: 'Organisationen und Nonprofits', label: 'E-Learning' },
  { category: 'Organisationen und Nonprofits', label: 'Philanthropie' },
  { category: 'Organisationen und Nonprofits', label: 'Umweltdienste' },
  { category: 'Rechtswesen', label: 'Polizeidienst' },
  { category: 'Rechtswesen', label: 'Alternative Schlichtungsmethoden' },
  { category: 'Rechtswesen', label: 'Justiz' },
  { category: 'Rechtswesen', label: 'Juristische Dienstleistungen' },
  { category: 'Rechtswesen', label: 'Rechtswesen' },
  { category: 'Rechtswesen', label: 'Legislative' },
  { category: 'Regierung', label: 'Ämter & Behörden' },
  { category: 'Regierung', label: 'Militär' },
  { category: 'Regierung', label: 'Polizeidienst' },
  { category: 'Regierung', label: 'Öffentliche Sicherheit' },
  { category: 'Regierung', label: 'Beziehung zu Regierungen' },
  { category: 'Regierung', label: 'Diplomatischer Dienst' },
  { category: 'Regierung', label: 'Intl. Handel & Entwicklung' },
  { category: 'Regierung', label: 'Öffentlicher Dienst' },
  { category: 'Regierung', label: 'Justiz' },
  { category: 'Regierung', label: 'Maschinenbau & Betriebstechnik' },
  { category: 'Regierung', label: 'Legislative' },
  { category: 'Regierung', label: 'Biotechnologie' },
  { category: 'Regierung', label: 'Übersetzung und Lokalisierung' },
  { category: 'Regierung', label: 'Politische Organisationen' },
  { category: 'Regierung', label: 'Nanotechnologie' },
  { category: 'Regierung', label: 'Erneuerbare Energie & Umwelt' },
  { category: 'Regierung', label: 'Verteidigung & Raumfahrt' },
  { category: 'Regierung', label: 'Think Tanks' },
  { category: 'Regierung', label: 'Öffentliche Ordnung' },
  { category: 'Regierung', label: 'Telekommunikation' },
  { category: 'Regierung', label: 'Forschung' },
  { category: 'Regierung', label: 'Luft- & Raumfahrt' },
  { category: 'Regierung', label: 'Hoch- und Tiefbau' },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Glücksspiel & Casinos'
  },
  { category: 'Tourismus, Freizeit und Unterhaltung', label: 'Druckwesen' },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Weine und Spirituosen'
  },
  { category: 'Tourismus, Freizeit und Unterhaltung', label: 'Computerspiele' },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Darstellende Künste'
  },
  { category: 'Tourismus, Freizeit und Unterhaltung', label: 'Kino und Film' },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Schreiben und Redigieren'
  },
  { category: 'Tourismus, Freizeit und Unterhaltung', label: 'Presse' },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Medienproduktion'
  },
  { category: 'Tourismus, Freizeit und Unterhaltung', label: 'Sport' },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Veranstaltungsdienste'
  },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Museen und Kulturstätten'
  },
  { category: 'Tourismus, Freizeit und Unterhaltung', label: 'Fotografie' },
  { category: 'Tourismus, Freizeit und Unterhaltung', label: 'Schöne Künste' },
  { category: 'Tourismus, Freizeit und Unterhaltung', label: 'Kunstgewerbe' },
  { category: 'Tourismus, Freizeit und Unterhaltung', label: 'Sportartikel' },
  { category: 'Tourismus, Freizeit und Unterhaltung', label: 'Verlagswesen' },
  { category: 'Tourismus, Freizeit und Unterhaltung', label: 'Musik' },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Tourismus und Freizeit'
  },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Freizeiteinrichtungen & -dienste'
  },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Lebensmittel & Getränke'
  },
  { category: 'Tourismus, Freizeit und Unterhaltung', label: 'Restaurants' },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Bibliothekswesen'
  },
  { category: 'Tourismus, Freizeit und Unterhaltung', label: 'Unterhaltung' },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Hotel- und Gaststättengewerbe'
  },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Rundfunk und Fernsehen'
  },
  {
    category: 'Tourismus, Freizeit und Unterhaltung',
    label: 'Gesundheit, Wellness & Fitness'
  },
  { category: 'Transport und Verkehr', label: 'Seefahrt' },
  { category: 'Transport und Verkehr', label: 'Paket- und Frachttransport' },
  { category: 'Transport und Verkehr', label: 'Tourismus und Freizeit' },
  { category: 'Transport und Verkehr', label: 'Transportwesen & Bahnverkehr' },
  { category: 'Transport und Verkehr', label: 'Lagerhaltung' },
  { category: 'Transport und Verkehr', label: 'Intl. Handel & Entwicklung' },
  { category: 'Transport und Verkehr', label: 'Logistik und Beschaffung' },
  { category: 'Transport und Verkehr', label: 'Hotel- und Gaststättengewerbe' },
  { category: 'Transport und Verkehr', label: 'Fluggesellschaften' },
  { category: 'Transport und Verkehr', label: 'Import und Export' },
  { category: 'Wissenschaft und Bildung', label: 'Bildungsmanagement' },
  { category: 'Wissenschaft und Bildung', label: 'Pädagogik' },
  { category: 'Wissenschaft und Bildung', label: 'E-Learning' },
  { category: 'Wissenschaft und Bildung', label: 'Hochschulwesen' },
  { category: 'Wissenschaft und Bildung', label: 'Forschung' }
];

module.exports = industrySectors;
