import React from 'react';
import PropTypes from 'prop-types';

// material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

const PopUp = ({ title, closeText, saveText, children, open, onClose, onSubmit }) => (
  <Dialog open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <div className="container">{children}</div>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">
        {closeText}
      </Button>
      {saveText && (
        <Button onClick={onSubmit} color="primary">
          {saveText}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

PopUp.defaultProps = {
  saveText: undefined,
  onSubmit: () => {}
};

PopUp.propTypes = {
  title: PropTypes.string.isRequired,
  closeText: PropTypes.string.isRequired,
  saveText: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  children: PropTypes.node.isRequired
};

export default PopUp;
