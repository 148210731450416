import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Material UI
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

// Icons
import ExtendedSearchIcon from '@material-ui/icons/FindInPage';

class MainSearchField extends PureComponent {
  generateEndAdorment(enableMatchingSearch, onExtendedSearchClick, matchingSearchClick) {
    if (enableMatchingSearch) {
      return {
        endAdornment: (
          <div className="d-flex">
            <Tooltip title="Detailsuche">
              <IconButton onClick={onExtendedSearchClick}>
                <ExtendedSearchIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Matchingsuche">
              <IconButton onClick={matchingSearchClick}>
                <i className="material-icons">assignment_ind</i>
              </IconButton>
            </Tooltip>
          </div>
        )
      };
    }
    return {
      endAdornment: (
        <Tooltip title="Detailsuche">
          <IconButton onClick={onExtendedSearchClick}>
            <ExtendedSearchIcon />
          </IconButton>
        </Tooltip>
      )
    };
  }

  render() {
    const { enableMatchingSearch, onExtendedSearchClick, matchingSearchClick, ...fieldProps } = this.props;
    return (
      <div style={{ width: '100%' }}>
        <TextField
          {...fieldProps}
          margin="normal"
          autoFocus={this.props.autoFocus}
          fullWidth
          placeholder={this.props.placeholder}
          onChange={this.props.onChange}
          onFocus={this.props.onFocus}
          variant="outlined"
          error={this.props.error}
          helperText={this.props.helperText}
          InputProps={this.generateEndAdorment(enableMatchingSearch, onExtendedSearchClick, matchingSearchClick)}
        />
      </div>
    );
  }
}
MainSearchField.defaultProps = {
  autoFocus: true,
  enableMatchingSearch: false,
  matchingSearchClick: undefined
};

MainSearchField.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  error: PropTypes.bool.isRequired,
  helperText: PropTypes.node.isRequired,
  onExtendedSearchClick: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  enableMatchingSearch: PropTypes.bool,
  matchingSearchClick: PropTypes.func
};
export default MainSearchField;
