import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material ui
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';

import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import debounce from 'lodash/debounce';
import $ from 'jquery';

import * as CONSTANTS from '../constants';
import * as UTILS from '../utils/utilFunctions';
import * as CONFIG from '../config';

class PostalCodeAutosuggest extends PureComponent {
  handleSuggestionsFetchRequested = debounce(({ value }) => {
    this.getSuggestions(value);
  }, 300); // 500

  constructor(props) {
    super(props);
    this.state = { suggestions: [] };

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  getSuggestionValue(suggestion) {
    return suggestion.city;
  }

  getSuggestions = value => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.geoLocationLookUpURL;
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify({ searchValue: value }),
      timeout: CONFIG.ajaxTimeout
    })
      .done(response => {
        console.log('____getSuggestions', response);
        this.setState({
          suggestions: response
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  handleSuggestionsSelected = (event, { suggestion }) => {
    this.props.onSelection(suggestion);
  };

  renderInput = inputProps => (
    <TextField
      error={this.props.hasError}
      fullWidth
      helperText={this.props.errorText}
      InputProps={{
        inputProps
      }}
    />
  );

  countryNameMap(country) {
    switch (country) {
      case 'germany':
        return 'Deutschland';
      case 'switzerland':
        return 'Schweiz';
      case 'austria':
        return 'Österreich';
      default:
        return country;
    }
  }

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(`${suggestion.postalCode} -- ${suggestion.city} -- ${this.countryNameMap(suggestion.country)}`, query);
    const parts = parse(`${suggestion.postalCode} -- ${suggestion.city} -- ${this.countryNameMap(suggestion.country)}`, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) =>
            part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            )
          )}
        </div>
      </MenuItem>
    );
  };

  renderSuggestionsContainer(options) {
    const { containerProps, children } = options;

    return (
      <Paper className="suggestionBOX" {...containerProps}>
        {children}
      </Paper>
    );
  }

  render() {
    return (
      <Autosuggest
        renderInputComponent={this.renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        onSuggestionSelected={this.handleSuggestionsSelected}
        renderSuggestionsContainer={this.renderSuggestionsContainer}
        getSuggestionValue={this.getSuggestionValue}
        renderSuggestion={this.renderSuggestion}
        inputProps={{
          placeholder: this.props.placeholder,
          onChange: e => this.props.onSearchFieldChange(e.target.value),
          onBlur: this.props.onSearchFieldBlur,
          onFocus: this.props.onSearchFieldFocus,
          value: this.props.value,
          autoFocus: false
        }}
      />
    );
  }
}

PostalCodeAutosuggest.defaultProps = {
  value: '',
  hasError: false,
  errorText: ''
};

PostalCodeAutosuggest.propTypes = {
  onSearchFieldChange: PropTypes.func.isRequired,
  onSearchFieldBlur: PropTypes.func.isRequired,
  onSearchFieldFocus: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  hasError: PropTypes.bool,
  onSelection: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string
};

export default PostalCodeAutosuggest;
