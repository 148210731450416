import React from 'react';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

/**
 * @typedef Props
 * @property {(instance: NumberFormat | null) => void} inputRef
 * @property {(event: {target: {name: string, value: string}}) => void} onChange
 * @property {string} name;
 */

/**
 *
 * @param {Props & NumberFormatBaseProps } props
 * @returns
 */
const PercentageInput = props => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue
          }
        });
      }}
      valueIsNumericString
      maxLength={100}
      decimalSeparator=","
      prefix="%"
    />
  );
};

PercentageInput.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

export default PercentageInput;
