import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material UI
import Button from '@material-ui/core/Button';

import $ from 'jquery';
import { Document, Page } from 'react-pdf';
import CircularProgress from '@material-ui/core/CircularProgress';
import './CandidateDocuments.css';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import FolderIcon from '@material-ui/icons/Folder';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Menu from '@material-ui/core/Menu';
import FormatColorTextIcon from '@material-ui/icons/FormatColorText';
import MenuItem from '@material-ui/core/MenuItem';
import DropContainer from '../generic_components/DropContainer';
import CandidateUpdateDocumentNamePopUpContainer from './popUps/CandidateUpdateDocumentNamePopUpContainer';
import withUser from '../utils/withUser';
import * as CONSTANTS from '../constants';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

class CandidateDocuments extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      numPages: null,
      page: 0, // pagination
      rowsPerPage: localStorage.getItem('candidateDocumentsNumberOfRows') || 5, // pagination
      currentDocName: '',
      currentDocId: '',
      currentDoc: '',
      currentEditDocFileName: '',
      currentEditDocTaggedDocumentId: undefined,
      currentDocFileType: '',
      documentList: [],
      taggedDocuments: [],
      isLoadingDocument: false,
      anchorEl: null,
      openUpdateDocumentNameDialog: false,
      updateDocumentNameOldFileName: '',
      updateDocumentNameTaggedDocumentId: undefined
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    this.prepareDocumentPanel();
  }

  /**
   * Control for the next or the previous page
   * @param  {[type]} event [description] TODO
   * @param  {number} page  the next or the previous page number
   */
  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  /**
   * The amount of pages that are displayed on a single page
   * @param  {[type]} event [description] TODO
   */
  handleChangeRowsPerPage = event => {
    localStorage.setItem('candidateDocumentsNumberOfRows', event.target.value);
    this.setState({ rowsPerPage: event.target.value });
  };

  renderPaginationControls() {
    return (
      <TablePagination
        component="nav"
        count={
          (this.state.documentList ? this.state.documentList.length : 0) +
          (this.state.taggedDocuments ? this.state.taggedDocuments.length : 0)
        }
        rowsPerPage={this.state.rowsPerPage}
        rowsPerPageOptions={[5, 10, 20]}
        labelRowsPerPage="Dokumente pro Seite"
        page={this.state.page}
        onChangePage={this.handleChangePage}
        onChangeRowsPerPage={this.handleChangeRowsPerPage}
        labelDisplayedRows={({ from, to, count }) =>
          `${from} ${TEXT.resultPage.labelPaginationFromTo1} ${to} ${TEXT.resultPage.labelPaginationFromTo2} ${count}`
        }
        // TODO check if required for "last" and "first" page controls
        // ActionsComponent={TablePaginationActionsWrapped}
      />
    );
  }

  handleEditMenuClick = (event, fileName, taggedDocumentId) => {
    this.setState({
      anchorEl: event.currentTarget,
      currentEditDocFileName: fileName,
      currentEditDocTaggedDocumentId: taggedDocumentId
    });
  };

  handleUpdateDocumentNameClick(fileName, taggedDocumentId) {
    this.setState({
      updateDocumentNameOldFileName: fileName,
      updateDocumentNameTaggedDocumentId: taggedDocumentId,
      openUpdateDocumentNameDialog: true
    });
  }

  updateDocumentName = (fileName, newFileName) => {
    const taggedDocumentId = this.state.updateDocumentNameTaggedDocumentId;
    const url = CONSTANTS.updateIncomingCandidateDocumentNameURL;

    const documentRequest = {
      candidateId: this.props.selectedCandidate.id,
      fileName,
      taggedDocumentId,
      newFileName
    };
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      headers: { 'x-auth': tokenFromLocalStorage },
      contentType: 'application/json',
      data: JSON.stringify(documentRequest)
    })
      .done(response => {
        if (response.status === 'success') {
          if (taggedDocumentId) {
            // taggedDocument
            this.setState(prevState => ({
              taggedDocuments: prevState.taggedDocuments.map(doc => {
                if (doc.id === taggedDocumentId) {
                  return {
                    ...doc,
                    documentName: newFileName
                  };
                }
                return doc;
              })
            }));
            if (this.props.isIncomingCandidates) {
              this.props.liftUpSelectedCandidateToIncomingCandidates({taggedDocuments: this.state.taggedDocuments})
            }
          } else {
            // document
            this.setState(prevState => ({
              documentList: prevState.documentList.map(doc => {
                if (doc !== fileName) {
                  return newFileName;
                }
                return doc;
              })
            }));
            if (this.props.isIncomingCandidates) {
              this.props.liftUpSelectedCandidateToIncomingCandidates({taggedDocuments: this.state.taggedDocuments})
            }
          }
        }
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        this.errorHandling(err, url);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  };

  handleDeleteClick = (fileName, taggedDocumentId, isIncomingCandidate) => {
    let url = CONSTANTS.deleteCandidateDocumentURL;
    if (isIncomingCandidate) {
      url = CONSTANTS.deleteIncomingCandidateDocumentURL;
    }

    const documentRequest = {
      candidateId: this.props.selectedCandidate.id,
      fileName,
      taggedDocumentId
    };
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      headers: { 'x-auth': tokenFromLocalStorage },
      contentType: 'application/json',
      data: JSON.stringify(documentRequest)
    })
      .done(response => {
        if (response.status === 'success') {
          if (taggedDocumentId) {
            // taggedDocument
            this.setState(prevState => ({
              taggedDocuments: prevState.taggedDocuments.filter(doc => doc.id !== taggedDocumentId)
            }));
            if (isIncomingCandidate) {
              this.props.liftUpSelectedCandidateToIncomingCandidates({taggedDocuments: this.state.taggedDocuments})
            }
          } else {
            // document
            this.setState(prevState => ({
              documentList: prevState.documentList.filter(doc => doc !== fileName)
            }));
            if (isIncomingCandidate) {
              this.props.liftUpSelectedCandidateToIncomingCandidates({taggedDocuments: this.state.taggedDocuments})
            }
          }
        }
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        this.errorHandling(err, url);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });

    console.log(fileName, taggedDocumentId);
  };

  handleEditMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleListItemClick = (documentId, isTaggedDocument) => {
    if (this.state.currentDocId !== documentId) {
      this.setState({ isLoadingDocument: true });
      this.getCandidateDocumentById(documentId, isTaggedDocument);
    }
  };

  renderAvailableFiles() {
    const startResult = this.state.page * this.state.rowsPerPage;
    const combinedArray = [];
    if (this.state.documentList) {
      this.state.documentList.forEach(fileName => combinedArray.push({ fileName, type: 'oldDoc' }));
    }
    if (this.state.taggedDocuments) {
      this.state.taggedDocuments.forEach(taggedDocument =>
        combinedArray.push({
          id: taggedDocument.id,
          fileName: taggedDocument.documentName,
          type: 'newDoc',
          tag: taggedDocument.documentType,
          date: taggedDocument.creationDate
        })
      );
    }
    combinedArray.sort((a, b) => {
      if (!a.date || !b.date) return -1;

      if (a.date > b.date) return -1;
      if (a.date <= b.date) return 1;
      return 0;
    });

    return (
      <div className="text-center">
        <List dense>
          {combinedArray.slice(startResult, startResult + this.state.rowsPerPage).map(document => {
            if (document.type === 'oldDoc') {
              return (
                <ListItem
                  button
                  key={document.fileName}
                  selected={document.fileName === this.state.currentDocName}
                  onClick={() => this.handleListItemClick(document.fileName)}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <FolderIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={document.fileName} />
                  {!this.props.isSidebar && (
                    <ListItemSecondaryAction>
                      <IconButton aria-label="Edit" onClick={event => this.handleEditMenuClick(event, document.fileName)}>
                        <EditIcon />
                      </IconButton>
                      {this.props.user.role === 'admin' && (
                        <IconButton
                          aria-label="Delete"
                          onClick={() => this.handleDeleteClick(document.fileName, undefined, this.props.isIncomingCandidates)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              );
            }
            return (
              <ListItem
                key={`${document.fileName}listItem`}
                button
                selected={document.id === this.state.currentDocId}
                onClick={() => this.handleListItemClick(document.id, true)}
              >
                <ListItemAvatar>
                  <Avatar>{document.tag ? document.tag : <FolderIcon />}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={document.fileName}
                  secondary={document.date ? `Datum: ${UTILS.formatStringToDELocale(document.date)}` : ''}
                />
                {!this.props.isSidebar && (
                  <ListItemSecondaryAction>
                    <IconButton
                      aria-label="Edit"
                      onClick={event => this.handleEditMenuClick(event, document.fileName, document.id ? document.id : 'newDoc')}
                    >
                      <EditIcon />
                    </IconButton>
                    {this.props.isIncomingCandidates && (
                      <IconButton onClick={() => this.handleUpdateDocumentNameClick(document.fileName, document.id)}>
                        <FormatColorTextIcon />
                      </IconButton>
                    )}
                    {this.props.user.role === 'admin' && (
                      <IconButton
                        aria-label="Delete"
                        disabled={!document.id}
                        onClick={() => this.handleDeleteClick(document.fileName, document.id, this.props.isIncomingCandidates)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            );
          })}
        </List>
      </div>
    );
  }

  documentLoadingInfo() {
    return <CircularProgress size={110} thickness={3} />;
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages, isLoadingDocument: false });
  };

  documentPreview() {
    if (this.state.currentDocFileType && this.state.currentDocFileType.toLowerCase() === 'pdf') {
      return (
        <Document
          file={this.state.currentDoc}
          loading={this.documentLoadingInfo()}
          noData={this.documentLoadingInfo()}
          onLoadSuccess={this.onDocumentLoad}
          error={this.documentLoadingInfo()}
          renderMode="canvas"
        >
          {this.displayPdfDocumentPages(this.state.numPages)}
        </Document>
      );
    }
    if (
      this.state.currentDocFileType &&
      (this.state.currentDocFileType.toLowerCase() === 'jpeg' ||
        this.state.currentDocFileType.toLowerCase() === 'jpg' ||
        this.state.currentDocFileType.toLowerCase() === 'png')
    ) {
      this.setState({ isLoadingDocument: false });
      return (
        <div className="text-center">
          <img src={this.state.currentDoc} alt="" className="img-fluid rounded" />
        </div>
      );
    }

    return <h4 className="m-3">{TEXT.resultPage.sideBar.documentsView.fileOnlyAsDownload}</h4>;
  }

  /**
   * Sets the href for the button with the viewed file
   */
  downloadFile = () => {
    const element = document.createElement('a');
    if (this.state.currentDoc.startsWith('data:')) {
      const blob = UTILS.dataURIToBlob(this.state.currentDoc);
      element.setAttribute('href', URL.createObjectURL(blob));
    } else {
      element.setAttribute('href', this.state.currentDoc);
    }
    element.setAttribute('target', '_blank');

    element.setAttribute('download', `${this.state.currentDocName.split('.')[0]}.${this.state.currentDocFileType}`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  renderEditMenu() {
    return (
      <Menu id="simple-menu" anchorEl={this.state.anchorEl} open={Boolean(this.state.anchorEl)} onClose={this.handleEditMenuClose}>
        <MenuItem key="IB" onClick={() => this.handleTagDocument('IB')}>
          IB
        </MenuItem>
        <MenuItem key="CV" onClick={() => this.handleTagDocument('CV')}>
          CV
        </MenuItem>
        <MenuItem key="AZ" onClick={() => this.handleTagDocument('AZ')}>
          AZ
        </MenuItem>
        <MenuItem key="DZ" onClick={() => this.handleTagDocument('DZ')}>
          DZ
        </MenuItem>
        <MenuItem key="BB" onClick={() => this.handleTagDocument('BB')}>
          BB
        </MenuItem>
        <MenuItem key="ED" onClick={() => this.handleTagDocument('ED')}>
          ED
        </MenuItem>
        <MenuItem key="EDM" onClick={() => this.handleTagDocument('EDM')}>
          EDM
        </MenuItem>
        <MenuItem key="EDK" onClick={() => this.handleTagDocument('EDK')}>
          EDK
        </MenuItem>
        <MenuItem key="SE" onClick={() => this.handleTagDocument('SE')}>
          SE
        </MenuItem>
        <MenuItem key="ALL" onClick={() => this.handleTagDocument('ALL')}>
          ALL
        </MenuItem>
      </Menu>
    );
  }

  onDrop = acceptedFiles => {
    let foundFileWithSameName = false;
    acceptedFiles.forEach(file => {
      const fileName = file.name;
      if ((this.props.selectedCandidate.documents || []).includes(fileName)) {
        foundFileWithSameName = true;
      }
      this.props.selectedCandidate.taggedDocuments.forEach(taggedDocument => {
        if (fileName.toLowerCase() === taggedDocument.documentName.toLowerCase()) {
          foundFileWithSameName = true;
        }
      });
    });
    if (foundFileWithSameName) {
      this.props.handleSnackbarOpen('Es ist bereits ein Dokument mit diesem Namen vorhanden!');
      return;
    }
    this.props.onDrop(acceptedFiles, responseBody => this.setState({ taggedDocuments: responseBody.taggedDocuments }));
  };

  showDocContent() {
    if ((this.state.documentList && this.state.documentList.length !== 0) || this.state.taggedDocuments.length !== 0) {
      if (!this.props.isSidebar) {
        return (
          <div className="row col-12">
            <Paper
              style={
                this.props.isIncomingCandidates
                  ? {
                      overflow: 'auto',
                      maxWidth: '100%;',
                      width: '100%',
                      height: 'calc(90vh - 150px - 64px)'
                    }
                  : { width: '100%', maxHeight: '1000px', overflow: 'auto' }
              }
            >
              <div className="container ">
                <div className="col-12">
                  <div className="text-right p-2">
                    <DropContainer
                      onDrop={this.onDrop}
                      dropzoneErrorMessage={this.props.pdfErrorMessage}
                      showLoadingIndicator={this.props.documentLoading}
                    >
                      {this.renderAvailableFiles()}
                      {this.renderPaginationControls()}
                    </DropContainer>
                  </div>
                </div>
                <div className="text-center col-12">
                  <Button
                    className="mb-3"
                    variant="contained"
                    color="primary"
                    onClick={this.downloadFile}
                    disabled={this.state.currentDoc === ''}
                    download
                  >
                    Download
                  </Button>
                  {this.state.isLoadingDocument && <CircularProgress size={110} thickness={3} />}
                  {this.documentPreview()}
                  <CandidateUpdateDocumentNamePopUpContainer
                    open={this.state.openUpdateDocumentNameDialog}
                    handleClose={() => this.setState({ openUpdateDocumentNameDialog: false })}
                    oldFileName={this.state.updateDocumentNameOldFileName}
                    updateDocumentName={this.updateDocumentName}
                  />
                </div>
              </div>
            </Paper>

            {!this.props.isSidebar && this.renderEditMenu()}
          </div>
        );
      }
      if (this.props.isSidebar) {
        // different styling for sidebar
        return (
          <React.Fragment>
            {this.renderAvailableFiles()}
            {this.renderPaginationControls()}
            <div className="text-center">
              <Button
                className="mb-3"
                variant="contained"
                color="primary"
                onClick={this.downloadFile}
                disabled={this.state.currentDoc === ''}
                download
              >
                Download
              </Button>
              {this.state.isLoadingDocument && <CircularProgress size={110} thickness={3} />}
              {this.documentPreview()}
            </div>
          </React.Fragment>
        );
      }
    }

    return (
      <div>
        <div className="sideBarWrapper m-2 p-2">
          <div className="candidateArchive-emptyPage">
            <DropContainer
              onDrop={this.onDrop}
              dropzoneErrorMessage={this.props.pdfErrorMessage}
              showLoadingIndicator={this.props.documentLoading}
              disabled={this.props.disabled}
              actionDescription={this.props.disabled ? '' : 'Dateien hier ablegen'}
            >
              {this.props.disabled && !this.props.isSidebar ? (
                <p>Dokumente können erst nach Erstellung des Kandidaten hochgeladen werden.</p>
              ) : (
                <p className="text-center">{TEXT.resultPage.sideBar.documentsView.candidateHasNoDocs}</p>
              )}
            </DropContainer>
          </div>
        </div>
      </div>
    );
  }

  displayPdfDocumentPages(numOfPages) {
    const pageList = [];
    for (let i = 1; i <= numOfPages; i++) {
      pageList.push(
        <Page
          // key={this.state.currentDoc + numOfPages}
          width={!this.props.isSidebar && 800}
          className="mx-auto"
          pageNumber={i}
          renderAnnotations={false}
          renderTextLayer={false}
        />
      );
    }
    return pageList;
  }

  handleTagDocument(tag) {
    this.handleEditMenuClose();
    if (this.state.currentEditDocTaggedDocumentId) {
      // console.log('state2', tag);
      this.setState(prevState => {
        const taggedDocuments = prevState.taggedDocuments;
        taggedDocuments.forEach((taggedDocument, index) => {
          if (taggedDocument.documentName === prevState.currentEditDocFileName) {
            taggedDocuments[index].documentType = tag;
          }
        });

        const updatedState = { ...prevState, taggedDocuments };
        return updatedState;
      });
    } else {
      this.setState(prevState => {
        let documentList = prevState.documentList;
        console.log('state', documentList, prevState.currentEditDocFileName);
        documentList = documentList.filter(entry => entry !== prevState.currentEditDocFileName);
        const taggedDocuments = prevState.taggedDocuments;
        taggedDocuments.push({
          id: '',
          documentName: prevState.currentEditDocFileName,
          documentType: tag
        });
        const updatedState = { ...prevState, documentList, taggedDocuments };

        return updatedState;
      });
    }

    this.props.handleTagDocument(this.state.currentEditDocFileName, tag, this.state.currentEditDocTaggedDocumentId !== undefined);
  }

  getCandidateDocumentById = (documentId, isTaggedDocument) => {
    const url = CONSTANTS.getCandidateDocumentByIdURL;
    const documentRequest = {
      candidateId: this.props.selectedCandidate.id,
      documentId,
      isTaggedDocument
    };
    console.log('tagged doc', documentId, documentRequest);

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      headers: { 'x-auth': tokenFromLocalStorage },
      contentType: 'application/json',
      data: JSON.stringify(documentRequest)
    })
      .done(response => {
        let result = '';
        if (response.content) {
          if (response.contentType === 'pdf') {
            result = UTILS.convertBufferToPdf(response.content);
          } else if (response.contentType === 'jpeg' || response.contentType === 'jpg' || response.contentType === 'png') {
            result = UTILS.convertBufferToImageV2(response.content, response.contentType);
          } else {
            result = UTILS.convertBufferToDocx(response.content, response.contentType);
          }
        } else {
          result = response.contentUrl;
        }
        this.setState({
          currentDoc: result,
          currentDocId: documentId,
          currentDocName: response.fileName,
          currentDocFileType: response.contentType
        });
      })
      .fail(err => {
        this.props.handleSnackbarOpen('Das Dokument konnte nicht geladen werden!');
        this.setState({ isLoadingDocument: false });
        console.log(err);
      });
  };

  prepareDocumentPanel = () => {
    const allowedTagsForDiagnostics = ['ED', 'EDM', 'EDK'];
    if (this.props.isDiagnostics) {
      if (this.props.selectedCandidate.taggedDocuments && this.props.selectedCandidate.taggedDocuments.length > 0) {
        const filteredDocumentsArray = this.props.selectedCandidate.taggedDocuments.filter(doc =>
          allowedTagsForDiagnostics.includes(doc.documentType)
        );
        if (filteredDocumentsArray.length > 0) {
          const documentId = filteredDocumentsArray[0].id;

          this.getCandidateDocumentById(documentId, true);
          this.setState({
            // currentDocName: this.props.selectedCandidate.taggedDocuments[0]
            //   .documentName,
            // currentEditDocFileName: this.props.selectedCandidate.taggedDocuments[0]
            //   .documentName,
            // currentDoc: '',
            // documentList: this.props.selectedCandidate.documents,
            taggedDocuments: filteredDocumentsArray
          });
        }
      }
    } else if (this.props.selectedCandidate.documents && this.props.selectedCandidate.documents.length > 0) {
      const filteredDocumentsArray = this.props.selectedCandidate.taggedDocuments.filter(
        doc => !allowedTagsForDiagnostics.includes(doc.documentType)
      );

      const documentFileName = this.props.selectedCandidate.documents ? this.props.selectedCandidate.documents[0] : '';
      this.getCandidateDocumentById(documentFileName);
      if (filteredDocumentsArray.length > 0) {
        this.setState({
          documentList: this.props.selectedCandidate.documents,
          taggedDocuments: filteredDocumentsArray
        });
      } else {
        this.setState({
          documentList: this.props.selectedCandidate.documents,
          taggedDocuments: this.props.selectedCandidate.taggedDocuments
        });
      }
    } else if (this.props.selectedCandidate.taggedDocuments && this.props.selectedCandidate.taggedDocuments.length > 0) {
      const filteredDocumentsArray = this.props.selectedCandidate.taggedDocuments.filter(
        doc => !allowedTagsForDiagnostics.includes(doc.documentType)
      );

      if (filteredDocumentsArray.length > 0) {
        const documentId = filteredDocumentsArray[0].id;
        this.getCandidateDocumentById(documentId, true);
        this.setState({
          documentList: this.props.selectedCandidate.documents,
          taggedDocuments: filteredDocumentsArray
        });
      } else {
        const documentId = this.props.selectedCandidate.taggedDocuments ? this.props.selectedCandidate.taggedDocuments[0].id : '';
        this.getCandidateDocumentById(documentId, true);
        this.setState({
          documentList: this.props.selectedCandidate.documents,
          taggedDocuments: this.props.selectedCandidate.taggedDocuments
        });
      }
    }
  };

  render() {
    return this.showDocContent();
  }
}
CandidateDocuments.defaultProps = {
  selectedCandidate: undefined,
  pdfErrorMessage: '',
  isDiagnostics: false,
  documentLoading: false,
  disabled: false,
  isSidebar: false,
  isIncomingCandidates: false,
  liftUpSelectedCandidateToIncomingCandidates: () => {}
};
CandidateDocuments.propTypes = {
  selectedCandidate: PropTypes.object,
  handleTagDocument: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  pdfErrorMessage: PropTypes.string,
  documentLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  isDiagnostics: PropTypes.bool,
  isSidebar: PropTypes.bool,
  isIncomingCandidates: PropTypes.bool,

  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  liftUpSelectedCandidateToIncomingCandidates: PropTypes.func,
  user: PropTypes.object.isRequired
};

export default withUser(CandidateDocuments);
