import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Typography, Tooltip, IconButton, TablePagination, Menu, MenuItem, Button } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';

import DropContainer from '../generic_components/DropContainer';
import FileList from '../generic_components/file_util_components/FileList';
import { deleteCandidateDocument, getCandidateDocumentUrlById, updateDocumentTagById } from './api/candidateArchiveAPI';
import { downloadBlobFile } from '../utils/utilFunctions';
import ConfirmationModal from '../generic_components/ConfirmationModal';
import * as CONSTANTS from '../constants';

import './CandidateDocumentsV2.css';

export default function CandidateDocumentsV2({
  selectedCandidate,
  handleSnackbarOpen,
  pdfErrorMessage,
  documentLoading,
  onDrop,
  candidateArchiveStateUplift,
  isDiagnostics,
  updateDocumentName
}) {
  const [fileToDelete, setFileToDelete] = React.useState();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [deleteMultipleConfirmationOpen, setDeleteMultipleConfirmationOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [documentList, setDocumentList] = React.useState([]);
  const [selectedFileForTypeEdit, setSelectedFileForTypeEdit] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const possibleDocumentTypes = ['IB', 'CV', 'AZ', 'DZ', 'BB', 'ED', 'EDM', 'EDK', 'SE', 'ALL'];

  React.useEffect(() => {
    if (selectedCandidate.taggedDocuments && selectedCandidate.taggedDocuments.length > 0) {
      setDocumentList(selectedCandidate.taggedDocuments.map((td) => ({...td, selected: false})));
    } else if (selectedCandidate.taggedDocuments && selectedCandidate.taggedDocuments.length === 0) {
      setDocumentList([]);
    }
  }, [selectedCandidate]);

  function deleteDocumentByFileName() {
    const { id } = documentList.find(entry => entry.documentName === fileToDelete.fileName && entry.id === fileToDelete.documentId);
    setLoading(true);
    deleteCandidateDocument(selectedCandidate.id, fileToDelete.fileName, id)
      .then(responseBody => {
        if (responseBody.status === 'success') {
          handleSnackbarOpen(`Das Dokument: "${fileToDelete.fileName}" wurde erfolgreich gelöscht.`);
        }
        candidateArchiveStateUplift(documentList.filter(entry => entry.id !== id));
      })
      .catch(err => {
        handleSnackbarOpen('Beim Löschvorgang ist ein Fehler aufgetreten.');
        console.error('deleteDocumentByFileName:', err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const handleDocumentSelect = (documentId) => {
    setDocumentList(documentList.map((document) => {
      if (document.id === documentId) {
        return {...document, selected: !document.selected}
      }
      return document;
    }))
  }

  const handleDeleteSelectedDocuments = () => {
    setDeleteMultipleConfirmationOpen(true);
  }

  const handleDeleteMultipleDocuments = async () => {
    setLoading(true);
    const deleteOperations = [];
    documentList.forEach(document => {
      if (document.selected) {
        deleteOperations.push(deleteCandidateDocument(selectedCandidate.id, document.fileName, document.id));
      }
    })
    await Promise.all(deleteOperations);
    candidateArchiveStateUplift(documentList.filter(doc => !doc.selected));
    setLoading(false);
    setDeleteMultipleConfirmationOpen(false);
  }

  function onDropHandler(acceptedFiles) {
    const newFiles = acceptedFiles.filter(acceptedFile => {
      if (documentList) {
        return !documentList.find(entry => entry.documentName === acceptedFile.name);
      }
      return true;
    });
    if (newFiles.length > 0) {
      onDrop(newFiles, () =>
        handleSnackbarOpen(newFiles.length > 1 ? 'Dokumente wurden erfolgreich gespeichert.' : 'Dokument wurde erfolgreich gespeichert.')
      );
    } else {
      handleSnackbarOpen('Dokument ist in der List vorhanden');
    }
  }

  function updateDocumentTypeHandler(documentType) {
    setLoading(true);
    const { candidateId, fileName } = selectedFileForTypeEdit;
    updateDocumentTagById(candidateId, fileName, true, documentType)
      .then(() => {
        const newDocumentList = documentList.map(entry => (entry.documentName === fileName ? { ...entry, documentType } : entry));
        candidateArchiveStateUplift(newDocumentList);
        handleSnackbarOpen('Dokument Typ erfolgreich verändert.');
      })
      .catch(err => {
        handleSnackbarOpen('Bei Dokumenten Typ Änderung ist ein Fehler aufgetreten.');
        console.error('updateDocumentTypeHandler:', err);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function renderDeleteDocumentConfirmationModal() {
    return (
      fileToDelete &&
      deleteConfirmationOpen && (
        <ConfirmationModal
          handleClose={() => {
            setDeleteConfirmationOpen(false);
            setFileToDelete(undefined);
          }}
          open={deleteConfirmationOpen}
          buttonPrimaryAction={() => {
            deleteDocumentByFileName();
            setDeleteConfirmationOpen(false);
          }}
          headlineText={`Wollen das Dokument:  "${fileToDelete.fileName}"  unwiderruflich löschen ?`}
          descriptionText=""
          buttonPrimaryText="Ja"
          primaryButtonIsDisabled={loading}
        />
      )
    );
  }

  function renderDeleteMultipleDocumentsConfirmationModal() {
    return (
      <ConfirmationModal
          handleClose={() => {
            setDeleteMultipleConfirmationOpen(false);
          }}
          open={deleteMultipleConfirmationOpen}
          buttonPrimaryAction={() => {
            handleDeleteMultipleDocuments();
          }}
          headlineText="Wollen Sie die ausgewählten Dokumente unwiderruflich löschen ?"
          descriptionText=""
          buttonPrimaryText="Ja"
          primaryButtonIsDisabled={loading}
        />
    )
  }

  function renderDocumentTypeSelection(fileName) {
    return (
      <>
        <Tooltip title="Dokument Typ auswählen" placement="top">
          <IconButton
            edge="end"
            onClick={event => {
              setAnchorEl(event.currentTarget);

              const file = documentList.find(entry => entry.documentName === fileName);
              setSelectedFileForTypeEdit({
                candidateId: selectedCandidate.id,
                fileName,
                isTaggedDocument: !!file.documentType,
                tag: file.documentType
              });
            }}
          >
            <EditIcon color="primary" />
          </IconButton>
        </Tooltip>
      </>
    );
  }

  function dateComparator(a, b) {
    if (a.creationDate > b.creationDate) {
      return -1;
    }
    if (a.creationDate < b.creationDate) {
      return 1;
    }
    return 0;
  }

  function sortDocumentsLienert(a, b) {
    const sortTypes = ['IB', 'SE', 'BB', 'CV', 'AZ', 'DZ', 'ED', 'EDM', 'EDK', 'ALL'];

    const aType = sortTypes.indexOf(a.documentType);
    const bType = sortTypes.indexOf(b.documentType);
    if (aType < 0 && bType > -1) {
      return 1;
    }
    if (aType > -1 && bType < 0) {
      return -1;
    }
    if (aType > bType) {
      return 1;
    }
    if (aType < bType) {
      return -1;
    }
    if (aType === bType) {
      if (a.creationDate > b.creationDate) {
        return -1;
      }
      if (a.creationDate < b.creationDate) {
        return 1;
      }
    }
    return 0;
  }

  const documentTypeMenuItems = possibleDocumentTypes.map(documentType => (
    <MenuItem
      key={`documentTypeSelectionMenu${documentType}`}
      onClick={() => {
        updateDocumentTypeHandler(documentType);
        setAnchorEl(null);
      }}
    >
      {documentType}
    </MenuItem>
  ));

  return (
    <>
      <DocumentsContainer>
        <Typography component="div" className={!documentList ? 'info-text-typography' : ''}>
          <DropContainer
            onDrop={onDropHandler}
            dropzoneErrorMessage={pdfErrorMessage}
            showLoadingIndicator={documentLoading}
            actionDescription={documentLoading ? 'Dateien werden gespeichert' : 'Dateien hier ablegen'}
          >
            {documentList ? (
              <FileListingContainer
                files={documentList
                  .sort(CONSTANTS.isLienert ? sortDocumentsLienert : dateComparator)
                  .filter(isDiagnostics ? entry => ['ED', 'EDM', 'EDK'].includes(entry.documentType) : () => true)}
                candidateId={selectedCandidate.id}
                onDelete={(fileName, fileMetaInfo) => {
                  const documentId = fileMetaInfo.id;
                  setFileToDelete({ fileName, documentId });
                  setDeleteConfirmationOpen(true);
                }}
                handleDocumentSelect={handleDocumentSelect}
                loading={loading}
                documentTypeSelection={renderDocumentTypeSelection}
                updateDocumentName={updateDocumentName}
                handleDeleteSelectedDocuments={handleDeleteSelectedDocuments}
              />
            ) : (
              <Typography component="p" align="center" variant="body1">
                Dieser Kandidat verfügt über keine Dokumente.
              </Typography>
            )}
          </DropContainer>
        </Typography>
      </DocumentsContainer>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {documentTypeMenuItems}
      </Menu>

      {renderDeleteDocumentConfirmationModal()}
      {renderDeleteMultipleDocumentsConfirmationModal()}
    </>
  );
}
CandidateDocumentsV2.defaultProps = {
  isDiagnostics: false,
  selectedCandidate: undefined,
  pdfErrorMessage: '',
  documentLoading: false
};
CandidateDocumentsV2.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  candidateArchiveStateUplift: PropTypes.func.isRequired,
  selectedCandidate: PropTypes.object,
  pdfErrorMessage: PropTypes.string,
  documentLoading: PropTypes.bool,
  isDiagnostics: PropTypes.bool,
  updateDocumentName: PropTypes.func.isRequired,
};

function DocumentsContainer({ children }) {
  return (
    <Paper>
      <div className="container">
        <div className="row">
          <div className="col-12">{children}</div>
        </div>
      </div>
    </Paper>
  );
}
DocumentsContainer.propTypes = {
  children: PropTypes.node.isRequired
};

function FileListingContainer({
  files,
  candidateId,
  onDelete,
  loading,
  documentTypeSelection,
  updateDocumentName,
  handleDocumentSelect,
  handleDeleteSelectedDocuments
}) {
  const [pageNumber, setPageNumber] = React.useState(0);
  const [numberOfRows, setNumberOfRows] = React.useState(localStorage.getItem('candidateDocumentsNumberOfRows') || 5);

  function getDocumentBlob(fileName, fileMetaInfo) {
    const { id } = files.find(entry => entry.documentName === fileName && entry.id === fileMetaInfo.id);
    return getCandidateDocumentUrlById(candidateId, id)
      .then(responseBody => downloadBlobFile(responseBody.contentUrl))
      .catch(err => console.error(err));
  }

  function getDocumentUrl(fileName, documentId) {
    const { id } = files.find(entry => entry.documentName === fileName && entry.id === documentId);
    return getCandidateDocumentUrlById(candidateId, id)
      .then(responseBody => responseBody.contentUrl)
      .catch(err => console.error(err));
  }

  function handleChangeRowsPerPage(rowsPerPage) {
    localStorage.setItem('candidateDocumentsNumberOfRows', rowsPerPage);
    setNumberOfRows(rowsPerPage)
  }

  return (
    <>
      {files.length > 0 ? (
        <>
          {files.filter((file) => file.selected).length > 0 && (
            <Button onClick={handleDeleteSelectedDocuments}>Ausgewählte Dokumente löschen</Button>
          )}
          <FileList
            fileNameList={files.slice(pageNumber * numberOfRows, (pageNumber + 1) * numberOfRows).map(entry => ({
              name: entry.documentName,
              secondaryText: `Erstellungsdatum: ${new Date(entry.creationDate).toLocaleDateString('de-DE')}`,
              letterAvatar: entry.documentType,
              entry
            }))}
            onDelete={onDelete}
            onResolveFileUrl={getDocumentUrl}
            onDownload={getDocumentBlob}
            extendedItemActions={documentTypeSelection}
            disabled={loading}
            updateDocumentName={updateDocumentName}
            handleDocumentSelect={handleDocumentSelect}
          />
          <TablePagination
            component="nav"
            count={files.length}
            rowsPerPage={numberOfRows}
            rowsPerPageOptions={[5, 10, 20]}
            labelRowsPerPage="Dokumente pro Seite"
            page={pageNumber}
            onChangePage={(_, page) => setPageNumber(page)}
            onChangeRowsPerPage={event => handleChangeRowsPerPage(event.target.value)}
            labelDisplayedRows={({ from, to, count }) => `${from} bis ${to} aus ${count}`}
          />
        </>
      ) : (
        <Typography variant="p" component="div" align="center">
          Keine Dokumente vorhanden
        </Typography>
      )}
    </>
  );
}
FileListingContainer.propTypes = {
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  candidateId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  documentTypeSelection: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  updateDocumentName: PropTypes.func.isRequired,
  handleDocumentSelect: PropTypes.func.isRequired,
  handleDeleteSelectedDocuments: PropTypes.func.isRequired,
};
