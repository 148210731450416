import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import ValidatedDropDown from '../generic_components/ValidatedDropDown';

import '../generic_components/ConfirmationModal.css';

import TEXT from '../text';

class ShareMarkListPopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
          <div className="confirmation-dialog">
            <DialogTitle>
              <div className="confirmation-dialog-title">
                {this.props.icon !== 'none' && <i className="material-icons confirmation-dialog-title-icon pb-3">{this.props.icon}</i>}
                {this.props.headlineText}
              </div>
            </DialogTitle>
            <DialogContent id="confirmation-dialog-content">
              <DialogContentText>
                <div className="userResetConfirmation-descriptionText">
                  <p>Mit welchem Mitarbeiter Ihres Standorts möchten Sie die ausgewählte Markierungsliste teilen?</p>
                </div>
                <p>
                  <ValidatedDropDown
                    label="Empfänger der Markierungsliste"
                    value={this.props.selectedShareWithUser}
                    content={this.props.employeesByPartner}
                    onChange={this.props.onChange}
                    customClass="col-12"
                    fullWidth
                  />
                </p>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="confirmation-dialog-actions">
              <Button onClick={this.props.handleClose} color="secondary">
                {this.props.buttonSecondaryText}
              </Button>
              <Button
                disabled={!this.props.selectedShareWithUser}
                onClick={this.props.buttonPrimaryAction}
                color="secondary"
                variant="contained"
              >
                {this.props.buttonPrimaryText}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}
ShareMarkListPopUp.defaultProps = {
  headlineText: TEXT.ConfirmationModal.headlineText,
  buttonPrimaryText: TEXT.ConfirmationModal.buttonPrimaryText,
  buttonSecondaryText: TEXT.ConfirmationModal.buttonSecondaryText,
  icon: 'help_outline'
};
ShareMarkListPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  buttonPrimaryAction: PropTypes.func.isRequired,
  headlineText: PropTypes.string,
  buttonPrimaryText: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  employeesByPartner: PropTypes.object.isRequired,
  selectedShareWithUser: PropTypes.object.isRequired
};
export default ShareMarkListPopUp;
