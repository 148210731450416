import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import $ from 'jquery';
import ValidatedDropDown from '../generic_components/ValidatedDropDown';
import withUser from '../utils/withUser';

// Basic utils, constants and configs
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import { CompanyCreateUpdatePopUpText } from './staticContent/CompanyCreateUpdatePopUpStaticResources';

class CompanyCreateUpdatePopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isDisabled: false,
      companyDetails: {
        companyName: this.props.companyName,
        postalCode: this.props.postalCode,
        city: this.props.city,
        street: this.props.street,
        postbox: this.props.postbox,
        country: this.props.isUpdate ? this.props.country : 'Deutschland',
        industrySector: this.props.isUpdate ? this.props.industrySector : '',
        companySize: this.props.isUpdate ? this.props.companySize : '',
        comment: this.props.comment,
        companyType: this.props.companyType,
        phoneNumber: this.props.phoneNumber,
        faxNumber: this.props.faxNumber,
        url: this.props.url,
        urlCareerPage: this.props.urlCareerPage,
        advisor: this.props.advisor.id,
        directContact: this.props.directContact,
        category: this.props.category
      }
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.initialState = JSON.parse(JSON.stringify(this.state));
  }

  isNewChange() {
    let result = false;
    Object.keys(this.state.companyDetails).forEach(key => {
      if (this.state.companyDetails[key] !== this.props[key]) {
        result = true;
      }
    });
    return result;
  }

  // TODO change name
  saveCompanyEntry = () => {
    this.setState({
      isDisabled: true
    });
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.createCompanyURL;
    const payload = {
      companyDetails: {
        postalCode: this.state.companyDetails.postalCode.trim(),
        city: this.state.companyDetails.city.trim(),
        street: this.state.companyDetails.street.trim(),
        postbox: this.state.companyDetails.postbox.trim(),
        companyName: this.state.companyDetails.companyName.trim(),
        country: this.state.companyDetails.country,
        industrySector: this.state.companyDetails.industrySector,
        companySize: this.state.companyDetails.companySize,
        directContact: this.state.companyDetails.directContact,
        comment: this.state.companyDetails.comment.trim(),
        companyType: this.state.companyDetails.companyType.trim(),
        phoneNumber: this.state.companyDetails.phoneNumber.trim(),
        faxNumber: this.state.companyDetails.faxNumber.trim(),
        url: this.state.companyDetails.url.trim(),
        urlCareerPage: this.state.companyDetails.urlCareerPage.trim(),
        category: this.state.companyDetails.category.trim()
      }
    };
    console.log('API /createCompany request', payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API /createCompany response', responseBody, status);
        this.props.handleSnackbarOpen(
          `${CompanyCreateUpdatePopUpText.snackbarInfoStart}${this.state.companyDetails.companyName}${
            CompanyCreateUpdatePopUpText.snackbarInfoEnd
          }`
        );
        this.setState({
          ...this.initialState
        });
        this.props.handleClose(responseBody.id, true);
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  getUpdateData(key) {
    let result;

    if (this.state.companyDetails[key] !== this.props[key]) {
      if (key !== 'advisor') result = this.state.companyDetails[key].trim();
      else if (this.state.companyDetails[key] !== this.props[key].id) {
        result = this.state.companyDetails[key];
      }
    }

    return result;
  }

  generateLiftUpStateCompanyDetails() {
    const selectedAdvisor = this.props.employees.filter(advisor => advisor.value === this.state.companyDetails.advisor);
    return {
      id: this.props.companyId,
      name: this.state.companyDetails.companyName,
      companyType: this.state.companyDetails.companyType,
      address: {
        postalCode: this.state.companyDetails.postalCode,
        street: this.state.companyDetails.street,
        postbox: this.state.companyDetails.postbox,
        city: this.state.companyDetails.city,
        country: this.state.companyDetails.country
      },
      contactInfo: {
        phoneNumber: this.state.companyDetails.phoneNumber,
        faxNumber: this.state.companyDetails.faxNumber,
        url: this.state.companyDetails.url,
        urlCareerPage: this.state.companyDetails.urlCareerPage
      },
      category: this.state.companyDetails.category,
      industrySector: this.state.companyDetails.industrySector,
      companySize: this.state.companyDetails.companySize,
      comment: this.state.companyDetails.comment,
      advisor: {
        id: this.state.companyDetails.advisor,
        firstName: '',
        lastName: selectedAdvisor.length ? selectedAdvisor[0].label : ''
      },
      directContact: this.state.companyDetails.directContact
    };
  }

  generateUpdateData() {
    return {
      id: this.props.companyId,
      name: this.getUpdateData('companyName'),
      companyType: this.getUpdateData('companyType'),
      address: {
        postalCode: this.getUpdateData('postalCode'),
        city: this.getUpdateData('city'),
        street: this.getUpdateData('street'),
        postbox: this.getUpdateData('postbox'),
        country: this.getUpdateData('country')
      },
      contactInfo: {
        phoneNumber: this.getUpdateData('phoneNumber'),
        faxNumber: this.getUpdateData('faxNumber'),
        url: this.getUpdateData('url'),
        urlCareerPage: this.getUpdateData('urlCareerPage')
      },
      category: this.getUpdateData('category'),
      industrySector: this.getUpdateData('industrySector'),
      companySize: this.getUpdateData('companySize'),
      advisor: this.getUpdateData('advisor'),
      status: {},
      comment: this.getUpdateData('comment'),
      directContact: this.getUpdateData('directContact')
    };
  }

  updateCompanyEntry = () => {
    this.setState({
      isDisabled: true
    });
    if (this.isNewChange()) {
      const tokenFromLocalStorage = window.sessionStorage.getItem('token');
      const url = CONSTANTS.updateCompanyDetailsURL;
      const payload = {
        companyId: this.props.companyId,
        updateData: this.generateUpdateData()
      };
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(payload),
        timeout: CONFIG.ajaxTimeout
      })
        .done((responseBody, status) => {
          console.log('API /updateCompany response', responseBody, status);
          this.props.handleSnackbarOpen(`${CompanyCreateUpdatePopUpText.updateSnackBarMessage}`);
          // TODO this.state.companyDetails !== CompanyArchive.selectedCompany (true)
          this.props.liftUpStateToCompanyArchive('selectedCompany', this.generateLiftUpStateCompanyDetails());
          this.props.handleClose();
        })
        .fail(err => {
          this.errorHandling(err, url);
        });
    } else {
      this.props.handleSnackbarOpen(CompanyCreateUpdatePopUpText.noChangesMadeSnackBarMessage);
      this.props.handleClose();
    }
  };

  isRequiredField(key) {
    let result = false;
    switch (key) {
      case 'city':
      case 'companyName':
      case 'postalCode':
      case 'street':
        result = true;
        break;
      default:
        break;
    }
    return result;
  }

  selectFieldHandleChange = event => {
    const targetName = event.target.name;
    const newValue = event.target.value;
    this.setState(prevState => ({
      companyDetails: {
        ...prevState.companyDetails,
        [targetName]: newValue
      }
    }));
  };

  renderSelectField(key, value, dropDownObject) {
    const fieldValueExistsInDropDown = dropDownObject.dropDownList.includes(this.state.companyDetails[key]);

    // TODO ignore white spaces while matching
    // const fieldValueExistsInDropDown = dropDownObject.dropDownList.some(
    //   dropDownEntry =>
    //     dropDownEntry.replace(/ /g, '') ===
    //     currentDropDownValue.replace(/ /g, '')
    // );
    if (this.props.isUpdate && !fieldValueExistsInDropDown && this.state.companyDetails[key] && this.state.companyDetails[key].length > 0) {
      dropDownObject.dropDownList.unshift(this.state.companyDetails[key]);
    }
    const fieldId = `id_${key}`;
    return (
      <div className="col-6">
        <FormControl fullWidth margin="normal">
          <InputLabel htmlFor={fieldId}>{value}</InputLabel>
          <Select
            value={this.state.companyDetails[key]}
            onChange={this.selectFieldHandleChange}
            inputProps={{
              name: key,
              id: fieldId
            }}
          >
            <MenuItem value="">
              <em>{dropDownObject.textNone}</em>
            </MenuItem>
            {dropDownObject.dropDownList.map(item => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }

  renderTextField(key, value, isAutoFocus, colSize, isMultiline, maxLength = 255, variant = 'standard') {
    return (
      <div key={key} className={`col-${colSize}`}>
        <TextField
          value={this.state.companyDetails[key]}
          name={`inputField${key}`}
          label={value}
          fullWidth
          autoFocus={isAutoFocus}
          multiline={isMultiline}
          required={this.isRequiredField(key)}
          variant={variant}
          margin="normal"
          inputProps={{
            maxLength
          }}
          onChange={e => {
            const newValue = e.target.value;
            this.setState(prevState => ({
              companyDetails: {
                ...prevState.companyDetails,
                [key]: newValue
              }
            }));
          }}
        />
      </div>
    );
  }

  onAdvisorDropDownChange = e => {
    const inputFieldValue = e.target.value;
    const inputFieldKey = e.target.name;
    this.setState(prevState => ({
      companyDetails: {
        ...prevState.companyDetails,
        [inputFieldKey]: inputFieldValue
      }
    }));
  };

  renderAdvisorDropDown() {
    return (
      <div className="col-6">
        <ValidatedDropDown
          value={this.state.companyDetails.advisor || this.props.user.id}
          content={this.props.employees}
          onChange={this.onAdvisorDropDownChange}
          label="Betreuer"
          id="advisor"
          margin="normal"
        />
      </div>
    );
  }

  renderDirectContactDropDown() {
    return (
      <div className="col-6">
        <ValidatedDropDown
          value={this.state.companyDetails.directContact}
          content={['Ja', 'Nein']}
          // onChange={[this.onDirectContactDropDownChange]}
          label="Direktansprache"
          id="directContact"
          margin="normal"
        />
      </div>
    );
  }

  renderInputField(key, value, isAutoFocus) {
    let result = '';
    switch (key) {
      case 'comment':
        result = this.renderTextField(
          key,
          value,
          isAutoFocus,
          12, // col-size
          true, // isMultiline
          250 // max length
        );
        break;
      case 'country':
        result = this.renderSelectField(key, value, CompanyCreateUpdatePopUpText.countryDropDown);
        break;
      case 'companySize':
        result = this.renderSelectField(key, value, CompanyCreateUpdatePopUpText.companySizeDropDown);
        break;
      case 'industrySector':
        result = this.renderSelectField(key, value, CompanyCreateUpdatePopUpText.industrySectorDropDown);
        break;
      case 'advisor':
        result = this.renderAdvisorDropDown();
        break;
      case 'directContact':
        result = this.renderSelectField(key, value, CompanyCreateUpdatePopUpText.companyDirectContactDropDown);
        break;
      default:
        result = this.renderTextField(key, value, isAutoFocus, 6, false);
    }
    return result;
  }

  renderCompanyInputFields() {
    const fieldNames = CompanyCreateUpdatePopUpText.inputFieldNames;
    const result = [];
    let isAutoFocus = true;
    fieldNames.forEach(name => {
      const key = Object.keys(name)[0];
      const value = name[key];
      result.push(this.renderInputField(key, value, isAutoFocus));
      isAutoFocus = false;
    });
    return result;
  }

  isAtLeastOneInputFieldEmpty() {
    return (
      this.state.companyDetails.postalCode &&
      this.state.companyDetails.city &&
      this.state.companyDetails.street &&
      this.state.companyDetails.companyName
    );
  }

  render() {
    // TODO start here
    return (
      <Dialog open={this.props.open} aria-labelledby="CompanyCreateUpdatePopUpText-dialog">
        <DialogTitle>
          {this.props.isUpdate ? CompanyCreateUpdatePopUpText.titleUpdate : CompanyCreateUpdatePopUpText.titleCreate}
        </DialogTitle>
        <DialogContent>
          <div className="row">{this.renderCompanyInputFields()}</div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.props.handleClose}>
            {CompanyCreateUpdatePopUpText.cancel}
          </Button>
          <Button
            color="primary"
            disabled={this.state.isDisabled || !this.isAtLeastOneInputFieldEmpty()}
            onClick={this.props.isUpdate ? this.updateCompanyEntry : this.saveCompanyEntry}
          >
            {this.props.isUpdate ? CompanyCreateUpdatePopUpText.saveEntryUpdate : CompanyCreateUpdatePopUpText.saveEntry}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

CompanyCreateUpdatePopUp.defaultProps = {
  isUpdate: false,
  open: false,
  companyId: '',
  companyName: '',
  companyType: '',
  street: '',
  postbox: '',
  postalCode: '',
  city: '',
  country: '',
  phoneNumber: '',
  faxNumber: '',
  url: '',
  urlCareerPage: '',
  advisor: {},
  industrySector: '',
  companySize: '',
  comment: '',
  directContact: '',
  category: ''
};

CompanyCreateUpdatePopUp.propTypes = {
  isUpdate: PropTypes.bool,
  open: PropTypes.bool,
  liftUpStateToCompanyArchive: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  companyId: PropTypes.string,
  companyName: PropTypes.string,
  companyType: PropTypes.string,
  street: PropTypes.string,
  postbox: PropTypes.string,
  postalCode: PropTypes.string,
  city: PropTypes.string,
  country: PropTypes.string,
  phoneNumber: PropTypes.string,
  faxNumber: PropTypes.string,
  url: PropTypes.string,
  urlCareerPage: PropTypes.string,
  advisor: PropTypes.object,
  industrySector: PropTypes.string,
  companySize: PropTypes.string,
  comment: PropTypes.string,
  user: PropTypes.object.isRequired,
  employees: PropTypes.array.isRequired,
  category: PropTypes.string,
  directContact: PropTypes.string
};

export default withUser(CompanyCreateUpdatePopUp);
