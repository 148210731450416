import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import GenericFormPopUp from '../generic_components/GenericFormPopUp';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT_GENERAL from '../text';

class updateCounterPartReminderPopUpContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.form = this.generateFormFields();

    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  removeMeAfterTest = () => {
    console.log(this.props.data);
  };

  updateCounterPartReminderAPICall = updatedData => {
    // TODO API Call to UpdateCounterPartReminder
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updateCounterPartReminderURL;

    // map isReferred to boolean
    const mappedUpdatedData = updatedData;
    if (updatedData.isReferred) {
      if (updatedData.isReferred === 'ja') mappedUpdatedData.isReferred = true;
      else mappedUpdatedData.isReferred = false;
    }

    const payload = {
      counterPartReminderId: this.props.data.id,
      updatedData: mappedUpdatedData
    };
    if (Object.keys(updatedData).length > 0) {
      console.log(`API ${url} request`, payload);
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(payload),
        timeout: CONFIG.ajaxTimeout
      })
        .done((responseBody, status) => {
          console.log(`API /${url} response`, responseBody, status);
          this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.updateSuccess);
          // this.props.refreshProject();
          this.props.handleClose();
        })
        .fail(err => {
          this.errorHandling(err, url);
        });
    } else {
      this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.noUpdate);
    }
  };

  generateFormFields() {
    const forms = {
      formFields: {
        triggerDate: {
          value: this.props.data.triggerDate,
          type: 'dateTime',
          label: 'Datum / Uhrzeit'
        },
        note: {
          value: this.props.data.note,
          label: 'Bemerkung',

          customClass: 'col-12 mb-2',
          additionalProps: { multiline: true }
        }
      }
    };

    return forms;
  }

  render() {
    // console.log('debug this.props.data', this.props.data);
    this.form = this.generateFormFields();
    return (
      <GenericFormPopUp
        open={this.props.open}
        headlineText={`Eintrag ändern ${this.props.indexInformation}`}
        genericFormProps={{
          formFields: this.form.formFields,
          isUpdate: true,
          handleSubmit: this.updateCounterPartReminderAPICall,
          handleClose: this.props.handleClose
        }}
      />
    );
  }
}
updateCounterPartReminderPopUpContainer.defaultProps = {
  // isUpdate: false,
  data: {},
  indexInformation: ''
};

updateCounterPartReminderPopUpContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  // isUpdate: PropTypes.bool,
  // data

  data: PropTypes.object,
  // snackBar
  handleSnackbarOpen: PropTypes.func.isRequired,
  // dropDown key values
  // employees: PropTypes.array.isRequired,

  // custom: only required for subSchema update
  // dataSetId: PropTypes.string,
  // refreshProject: PropTypes.func.isRequired,
  // handleSelectNextCandidate: PropTypes.func.isRequired,
  indexInformation: PropTypes.string
};

export default updateCounterPartReminderPopUpContainer;
