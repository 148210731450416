import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import './ConfirmationModal.css';

import { Tooltip } from '@material-ui/core';
import TEXT from '../text';

class ConfirmationModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
        <div className="confirmation-dialog">
          <DialogTitle>
            <div className="confirmation-dialog-title">
              <i className="material-icons confirmation-dialog-title-icon pb-3">{this.props.icon}</i>
              {this.props.headlineText}
            </div>
          </DialogTitle>
          <DialogContent id="confirmation-dialog-content">
            <DialogContentText>{this.props.descriptionText}</DialogContentText>
            {this.props.contentText && <DialogContentText>{this.props.contentText}</DialogContentText>}
            {this.props.children && <DialogContentText>{this.props.children}</DialogContentText>}
          </DialogContent>
          <DialogActions className="confirmation-dialog-actions">
            <Button onClick={this.props.handleClose} color="secondary">
              {this.props.buttonSecondaryText}
            </Button>
            {this.props.primaryButtonIsDisabled ? (
              <Tooltip title={this.props.buttonPrimaryDisabledTooltipText}>
                <span>
                  <Button disabled={this.props.primaryButtonIsDisabled} color="secondary" variant="contained">
                    {this.props.buttonPrimaryText}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <Button
                disabled={this.props.primaryButtonIsDisabled}
                onClick={this.props.buttonPrimaryAction}
                color="secondary"
                variant="contained"
              >
                {this.props.buttonPrimaryText}
              </Button>
            )}
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}
ConfirmationModal.defaultProps = {
  headlineText: TEXT.ConfirmationModal.headlineText,
  descriptionText: TEXT.ConfirmationModal.descriptionText,
  buttonPrimaryText: TEXT.ConfirmationModal.buttonPrimaryText,
  buttonSecondaryText: TEXT.ConfirmationModal.buttonSecondaryText,
  primaryButtonIsDisabled: false,
  icon: 'help_outline',
  contentText: null,
  buttonPrimaryDisabledTooltipText: null,
  children: <></>
};
ConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  primaryButtonIsDisabled: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  buttonPrimaryAction: PropTypes.func.isRequired,
  headlineText: PropTypes.string,
  descriptionText: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
  ]),
  children: PropTypes.node,
  contentText: PropTypes.object,
  buttonPrimaryText: PropTypes.string,
  buttonSecondaryText: PropTypes.string,
  buttonPrimaryDisabledTooltipText: PropTypes.string,
  icon: PropTypes.string
};
export default ConfirmationModal;
