import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// libraries
import $ from 'jquery';

// material UI
import {
  Checkbox,
  FormControlLabel,
  Button
  // Stepper,
  // Step,
  // StepButton,
  // Paper
} from '@material-ui/core';

import * as CONSTANTS from '../../constants';
import * as CONFIG from '../../config';
import * as UTILS from '../../utils/utilFunctions';
import ValidatedTextField from '../../generic_components/ValidatedTextField';
import ValidatedDropDown from '../../generic_components/ValidatedDropDown';

function prepareTemplatesKeyValuePairs(templates) {
  return (templates || [])
    .filter(template => !!template.templateName)
    .map(template => ({
      value: template.id,
      label: template.templateName,
      isEditingMode: template.isEditingMode
    }));
}

function prepareProjectsKeyValuePairs(projects) {
  return (projects || [])
    .filter(
      (project, index, self) => !!project.projectNumber && self.findIndex(p => p.id === project.id) === index // only keep distinct values
    )
    .map(project => ({
      value: project.id,
      label: project.projectNumber
    }));
}

class SerialMailTemplateSelection extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formFields: {
        contactHistorySubject: {
          value: ''
        },
        contactHistoryComment: {
          value: ''
        },
        contactHistoryDate: {
          value: UTILS.formatDateForDateFieldNew(new Date())
        },
        contactHistoryProject: {
          value: ''
        },
        selectedTemplate: {
          value: ''
        },
        selectedSignature: {
          value: ''
        }
      },
      projectList: [{ value: null, label: 'Liste lädt...' }],
      templateList: [{ value: null, label: 'Liste lädt...' }],
      signatureList: [{ value: null, label: 'Liste lädt...' }],
      checkboxGeneralEntry: false,
      checkboxGeneralRejection: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  loadProjectList() {
    const url = CONSTANTS.getProjectNumbersByCandidateMarkListURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = { markListId: this.props.markListId };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API loadHistory response body: ', responseBody);
        console.log('API /loadHistory response status: ', status);
        this.setState({
          projectList: prepareProjectsKeyValuePairs(responseBody.projects)
        });
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url);
      });
  }

  loadTemplateList() {
    const url = CONSTANTS.getTemplateListURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = { templateType: 'serialMail' };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API loadHistory response body: ', responseBody);
        console.log('API /loadHistory response status: ', status);
        this.setState({
          templateList: prepareTemplatesKeyValuePairs(responseBody)
        });
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url);
      });
  }

  loadSignatureList() {
    const url = CONSTANTS.getTemplateListURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = { templateType: 'signature' };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API loadHistory response body: ', responseBody);
        console.log('API /loadHistory response status: ', status);
        this.setState({
          signatureList: prepareTemplatesKeyValuePairs(responseBody)
        });
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        // this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url);
      });
  }

  componentDidMount() {
    if (this.props.serialMailType === 'candidates') {
      this.loadProjectList();
    }
    this.loadTemplateList();
    this.loadSignatureList();
  }

  onInputFieldChange = e => {
    const inputFieldValue = e.target.value;
    const inputFieldKey = e.target.id || e.target.name;
    this.setState(prevState => ({
      formFields: {
        ...prevState.formFields,
        [inputFieldKey]: {
          ...prevState.formFields[inputFieldKey],
          value: inputFieldValue
        }
      }
    }));
  };

  handleContinueButtonClick = () => {
    this.props.handleContinueButtonClick({
      selectedTemplateId: this.state.formFields.selectedTemplate.value,
      selectedSignatureId: this.state.formFields.selectedSignature.value,
      contactHistory: {
        subject: this.state.checkboxGeneralEntry ? this.getSelectedTemplateName() : this.state.formFields.contactHistorySubject.value,
        comment: this.state.checkboxGeneralEntry ? '' : this.state.formFields.contactHistoryComment.value,
        date: this.state.formFields.contactHistoryDate.value,
        project: this.state.formFields.contactHistoryProject.value,
        generalEntry: this.state.checkboxGeneralEntry,
        rejectionNotice: this.state.checkboxGeneralRejection
      }
    });
  };

  isValidateFormInput = () =>
    // if "general entry" is not checked, contact history subject is required
    this.state.checkboxGeneralEntry || this.state.formFields.contactHistorySubject.value;

  isRejectionCheckboxDisabled() {
    let disabled = true;
    if (this.state.formFields.contactHistoryProject.value) disabled = false;
    return disabled;
  }

  handleCheckboxGeneralEntry = () => {
    this.setState(prevState => {
      const newState = !prevState.checkboxGeneralEntry;
      const updatedState = {
        checkboxGeneralEntry: newState
      };
      return updatedState;
    });
  };

  getSelectedTemplateName() {
    return (this.state.templateList.find(templateEntry => templateEntry.value === this.state.formFields.selectedTemplate.value) || {})
      .label;
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-8" style={{ paddingBottom: '1em' }}>
            <h6>Bitte wählen Sie eine Vorlage aus</h6>
          </div>
        </div>

        <div className="row pb-2">
          <div className="col-8" style={{ paddingBottom: '2em' }}>
            <ValidatedDropDown
              label="E-Mail Vorlage"
              placeHolderText="keine Vorlage"
              value={this.state.formFields.selectedTemplate.value}
              onChange={this.onInputFieldChange}
              id="selectedTemplate"
              key="selectedTemplate"
              content={this.state.templateList}
              fullWidth
            />
          </div>
        </div>
        <div className="row pb-2">
          <div className="col-8" style={{ paddingBottom: '2em' }}>
            <ValidatedDropDown
              label="Signatur Vorlage"
              placeHolderText="keine Vorlage"
              value={this.state.formFields.selectedSignature.value}
              onChange={this.onInputFieldChange}
              id="selectedSignature"
              key="selectedSignature"
              content={this.state.signatureList}
              fullWidth
            />
          </div>
        </div>

        {this.props.serialMailType === 'candidates' ? (
          <>
            <div
              className="row"
              // style={{ borderTop: '1px solid #ccc', paddingTop: '1.5em' }}
            >
              <div className="col-12">
                <h6>Kontakthistorie</h6>
                {CONSTANTS.isLienert ? (
                  <p>
                    Wenn Sie im Kandidatenarchiv unter &bdquo;vorgeschlagen auf / beworben auf&ldquo; ein Absagevermerk hinterlegen wollen,
                    wählen Sie einen Auftrag und aktivieren Sie das &bdquo;Absagehöggeli&ldquo;.
                  </p>
                ) : (
                  <p>
                    Wenn Sie im Kandidatenarchiv unter &bdquo;vorgeschlagen auf / beworben auf&ldquo; ein Absagevermerk hinterlegen wollen,
                    wählen Sie einen Auftrag und aktivieren Sie das &bdquo;abgesagt&ldquo;-Häckchen.
                  </p>
                )}
              </div>
              {/* <div className="col-4" /> */}
            </div>
            <div className="row">
              <div className="col-4">
                <ValidatedTextField
                  label="Datum"
                  value={this.state.formFields.contactHistoryDate.value}
                  onChange={this.onInputFieldChange}
                  id="contactHistoryDate"
                  key="contactHistoryDate"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  type="date"
                />
              </div>
              <div className="col-4">
                <ValidatedDropDown
                  label="Auftrag"
                  placeHolderText="keine Auswahl"
                  value={this.state.formFields.contactHistoryProject.value}
                  onChange={this.onInputFieldChange}
                  id="contactHistoryProject"
                  key="contactHistoryProject"
                  content={this.state.projectList}
                  fullWidth
                />
              </div>

              <div className="col-4">
                <FormControlLabel
                  className="checkFilterLabel"
                  fullWidth
                  style={{ padding: '5px 20px 5px 5px' }}
                  control={
                    <Checkbox
                      className="chkBox_"
                      checked={this.state.checkboxGeneralRejection}
                      disabled={this.isRejectionCheckboxDisabled()}
                      onChange={() => {
                        this.setState(prevState => {
                          const newState = !prevState.checkboxGeneralRejection;
                          return { checkboxGeneralRejection: newState };
                        });
                      }}
                    />
                  }
                  label={CONSTANTS.isLienert ? 'Absagehöggeli' : 'abgesagt'}
                />
              </div>
            </div>
          </>
        ) : null}

        <div className="row" style={{ paddingTop: '1.5em' }}>
          <div className="col-12">
            <p>
              Wenn Sie im Bereich &bdquo;Kontakthistorie&ldquo; einen automatisierten Eintrag erzeugen wollen, stehen Ihnen die Textfelder
              &bdquo;Betreff&ldquo; und &bdquo;Notiz&ldquo; für eine individuelle Formulierung zur Verfügung. Aktivieren Sie nur das
              Häkchen, wird ein standardisierter Eintrag gemäß Ihrer Vorlagenauswahl bzw. Eingaben im Email-Formular vorgenommen.
            </p>
          </div>
          {/* <div className="col-4" /> */}
        </div>
        <div className="row mb-2">
          <div className="col-8">
            <ValidatedTextField
              label="Betreff"
              value={
                this.state.checkboxGeneralEntry ? this.getSelectedTemplateName() || '' : this.state.formFields.contactHistorySubject.value
              }
              onChange={this.onInputFieldChange}
              id="contactHistorySubject"
              key="contactHistorySubject"
              disabled={this.state.checkboxGeneralEntry}
              fullWidth
            />
          </div>
          <div className="col-4">
            <FormControlLabel
              className="checkFilterLabel"
              fullWidth
              style={{ padding: '5px 20px 5px 5px' }}
              control={
                <Checkbox className="chkBox_" checked={this.state.checkboxGeneralEntry} onChange={this.handleCheckboxGeneralEntry} />
              }
              label="Standardeintrag"
            />
          </div>
        </div>

        <div className="row mb-2">
          <div className="col-12">
            <ValidatedTextField
              label="Notiz"
              value={this.state.checkboxGeneralEntry ? '' : this.state.formFields.contactHistoryComment.value}
              onChange={this.onInputFieldChange}
              id="contactHistoryComment"
              key="contactHistoryComment"
              disabled={this.state.checkboxGeneralEntry}
              multiline
              fullWidth
            />
          </div>
        </div>

        <div className="mt-5 text-right">
          <Button onClick={this.handleContinueButtonClick} color="primary" variant="contained" disabled={!this.isValidateFormInput()}>
            Weiter
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

SerialMailTemplateSelection.propTypes = {
  markListId: PropTypes.string.isRequired,
  handleContinueButtonClick: PropTypes.func.isRequired,
  serialMailType: PropTypes.string.isRequired
};

export default SerialMailTemplateSelection;
