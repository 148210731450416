import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// material UI
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

class ReleaseNotePopUp extends PureComponent {
  render() {
    return (
      this.props.open && (
        <Dialog key={this.props.key} open={this.props.open}>
          <DialogTitle style={{ textAlign: 'center' }}>
            <div style={{ fontSize: '28px' }}>
              <i
                className="material-icons releaseNote-dialog-title-icon"
                // style={{ fontSize: '44px' }}
              >
                {this.props.icon}
              </i>
              {this.props.headline}
            </div>
          </DialogTitle>

          <DialogContent style={{ minWidth: 400 }}>
            <div dangerouslySetInnerHTML={{ __html: this.props.content }} />
            {this.props.adminContent && <div dangerouslySetInnerHTML={{ __html: this.props.adminContent }} />}
            {this.props.subContent && <div dangerouslySetInnerHTML={{ __html: this.props.subContent }} />}
          </DialogContent>
          <DialogActions>
            <Button style={{ marginRight: '20px' }} onClick={this.props.handleClose} color="primary">
              Schließen
            </Button>
          </DialogActions>
        </Dialog>
      )
    );
  }
}
ReleaseNotePopUp.defaultProps = {
  open: false,
  headline: 'Neue Features verfügbar ',
  icon: 'mood',
  key: 'defaultKey',
  adminContent: null,
  subContent: null
};

ReleaseNotePopUp.propTypes = {
  open: PropTypes.bool,
  key: PropTypes.string,
  headline: PropTypes.string,
  icon: PropTypes.string,
  content: PropTypes.string.isRequired,
  adminContent: PropTypes.string,
  subContent: PropTypes.string,
  handleClose: PropTypes.func.isRequired
};

export default ReleaseNotePopUp;
