import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import $ from 'jquery';

import * as CONSTANTS from './constants';
import * as CONFIG from './config';
import * as UTILS from './utils/utilFunctions';
import { syncStoredToken, readToken, clearStoredToken } from './utils/tokenStorage';
import parseTokenToState from './utils/parseTokenToState';

class PageContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { verified: false };
    this.lastLocation = '';
    this.props.history.listen(location => {
      if (this.lastLocation !== location.pathname) {
        this.lastLocation = location.pathname;
        this.checkAuthentication(location.pathname);
      }
    });
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentWillMount() {
    this.setState({ verified: false });
    syncStoredToken(() => this.checkAuthentication(this.props.history.location.pathname));
  }

  checkAuthentication(currentPage) {
    this.props.liftUpStateToApp('currentPage', currentPage);
    // console.log('currentPage', currentPage);
    // check authentication only if page requires authentication
    // CASE page which require login
    if (!CONFIG.pagesWithoutLogin.includes(currentPage) || currentPage === '/') {
      // console.log('currentPage is /login or page which requires login');
      // CASE token available => validate token
      const tokenFromLocalStorage = readToken();
      if (tokenFromLocalStorage) {
        this.verifyTokenAPIcall(tokenFromLocalStorage);
        // console.log('verify');
      }
      // CASE no token available => redirect to loginPage
      else if (currentPage !== '/') {
        this.props.history.push('/');
        // console.log('verify2');
      }

      // CASE login page without token => show loginPage
      else if (currentPage === '/') {
        this.setState({ verified: true });
      }
    }
    // CASE page which doesn't require login
    else {
      this.setState({ verified: true });
      // console.log('verify3');
    }
  }

  verifyTokenAPIcall(token) {
    this.props.liftUpStateToApp('showLoadingIndicatorLoggedIn', true);
    // console.log('API verifyToken request');
    const url = CONSTANTS.verifyTokenURL;
    const pagesWithoutReleaseNotes = CONFIG.pagesWithoutLogin.concat(['/register', '/registerTan']);
    const requestBody = {
      requestReleaseNote: !pagesWithoutReleaseNotes.includes(this.props.history.location.pathname),
      requestSerialMailCount: ['/markLists'].includes(this.props.history.location.pathname)
    };
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(requestBody),
      headers: { 'x-auth': token }
    })
      .done(responseBody => {
        // console.log('responseBody', responseBody);
        this.props.liftUpStateToApp(parseTokenToState(token, responseBody.user, responseBody.serialMailCount));
        this.props.liftUpStateToApp('showLoadingIndicatorLoggedIn', false);
        this.props.liftUpStateToApp('notificationCount', responseBody.notificationCount);
        this.props.liftUpStateToApp('releaseNote', responseBody.releaseNote);

        // check if user status allows to see specific pages
        const currentPage = this.props.history.location.pathname;
        const userStatus = responseBody.user.status;
        //  console.log('responseBody', responseBody);
        // const userRole = UTILS.getHighestUserRole(responseBody.user.roles);
        // console.log('userRole', userRole);
        // console.log('API /verify has been called successfully');
        // TODO berechtigung unternehmensarchiv
        // TODO after your TODO Move to config file
        if (
          ([
            '/notifications',
            '/reminders',
            '/search',
            '/results',
            '/markLists',
            '/offers',
            '/companies',
            '/projects',
            '/intranet',
            '/candidates',
            '/incomingCandidates',
            '/incomingMatchingProfiles'
          ].includes(currentPage) &&
            userStatus === 'active') ||
          (['/register'].includes(currentPage) && userStatus === 'initialLogin') ||
          (['/registerTan'].includes(currentPage) && userStatus === 'passwordSet') ||
          (['/settings'].includes(currentPage) && userStatus === 'active')
          // userRole === 'admin'
        ) {
          // if yes
          //  console.log('Verified User');
          this.setState({ verified: true });

          // if no
          // redirect to correct page
        } else if (userStatus === 'initialLogin' && currentPage !== '/register') this.props.history.replace('/register');
        else if (userStatus === 'passwordSet' && currentPage !== '/registerTan') this.props.history.replace('/registerTan');
        else if (userStatus === 'active' && !['/search', '/results', '/settings'].includes(currentPage)) {
          this.props.history.replace('/search');
        } else {
          console.log('WARNUNG: unknown page status');
          clearStoredToken();
          this.props.history.replace('/');
        }
      })
      .fail(err => {
        // console.log('API /verify has been called with error', err);

        this.props.liftUpStateToApp('showLoadingIndicatorLoggedIn', false);
        // login with existing token which is invalid (e.g. belongs to a different user)
        if (this.props.history.location.pathname === '/') {
          this.setState({ verified: true });
        } else {
          this.errorHandling(err, url, this.props.liftUpStateToApp);
        }
      });
  }

  render() {
    const showPage = CONFIG.pagesWithoutLogin.includes(this.props.history.location.pathname) || this.state.verified;
    // console.log('showPage: ', showPage);
    return showPage ? this.props.children : ''; // this.props.children; // TODO other option should be '' but doesnt work when hitting /search directly
  }
}

PageContainer.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
};

export default withRouter(PageContainer);
