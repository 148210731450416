import { Avatar, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Tooltip } from '@material-ui/core';
import {
  CloudDownload as CloudDownloadIcon,
  Delete,
  Delete as DeleteIcon,
  Edit,
  EditRounded,
  InsertDriveFile as FileIcon,
  Folder as FolderIcon,
  Launch,
  Link
} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

/**
 *
 * @param {{
 * readonly: boolean,
 * disabled: boolean,
 * documentList: Record<string,any> ,
 * onDelete:(id: string, fileName: string) => void,
 * onDownload:(id: string, file: string) => void,
 * onFolderClick:(id: string) => void,
 * onRenameFolder:(id: string) => void,
 * onDeleteFolder:(id: string) => void,
 * onLinkEdit: (link: import('./hooks/link').Link) => void,
 * onLinkDelete: (linkId: string) => void,
 * onLinkOpen: (link: import('./hooks/link').Link) => void,
 * handleSnackbarOpen (text: string) => void,
 * }} param0
 * @returns {JSX.Element}
 */
const IntranetFileList = ({
  readOnly,
  disabled,
  documentList,
  onDelete,
  onDownload,
  onFolderClick,
  onRenameFolder,
  onDeleteFolder,
  onLinkDelete,
  onLinkEdit,
  onLinkOpen,
  handleSnackbarOpen
}) => {
  const handleCopyLinkInClipboard = useCallback(
    url => {
      navigator.clipboard.writeText(url);
      handleSnackbarOpen('Link in Zwischenablage kopiert!');
    },
    [handleSnackbarOpen]
  );

  const renderItemAvatar = isFolder => (
    <ListItemAvatar>
      <Avatar>{isFolder ? <FolderIcon color="primary" /> : <FileIcon />}</Avatar>
    </ListItemAvatar>
  );

  const renderIconButton = (ariaLabel, icon, tooltipMessage, onClick) => (
    <Tooltip title={tooltipMessage} placement="top">
      <IconButton edge="end" aria-label={ariaLabel} disabled={disabled} onClick={onClick} style={{ marginLeft: '15px' }}>
        {icon}
      </IconButton>
    </Tooltip>
  );

  const renderItemActions = (fileName, id) => (
    <ListItemSecondaryAction>
      {onDownload &&
        renderIconButton('download', <CloudDownloadIcon color="primary" />, 'Dokument speichern', () => {
          onDownload(id, fileName);
        })}
      {onDelete &&
        renderIconButton('delete', <DeleteIcon color="primary" />, 'Dokument löschen', () => {
          onDelete(id, fileName);
        })}
    </ListItemSecondaryAction>
  );

  const renderFolderActions = (name, id) => (
    <ListItemSecondaryAction>
      {onRenameFolder &&
        renderIconButton('rename', <Edit color="primary" />, 'Ordner umbenennen', () => {
          onRenameFolder(id);
        })}
      {onDeleteFolder &&
        renderIconButton('delete', <DeleteIcon color="primary" />, 'Ordner löschen', () => {
          onDeleteFolder(id);
        })}
    </ListItemSecondaryAction>
  );

  const renderFolderListItem = (name, id) => (
    <ListItem button key={id} disabled={disabled} onClick={() => onFolderClick(id)}>
      {renderItemAvatar(true)}
      <ListItemText primary={name} />

      {!readOnly && renderFolderActions(name, id)}
    </ListItem>
  );

  const renderFileListItem = (file, id, type) => (
    <ListItem button key={id} disabled={disabled} onClick={() => onDownload(id, file)}>
      {renderItemAvatar()}
      <ListItemText primary={file.name || file} secondary={file.secondaryText} />

      {!readOnly && renderItemActions(file, id, type)}
    </ListItem>
  );

  const renderLinkListItem = useCallback(
    (id, name, url) => (
      <ListItem button key={id} disable={disabled}>
        <ListItemAvatar>
          <Avatar>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link />
          </Avatar>
        </ListItemAvatar>
        <ListItemText onClick={() => handleCopyLinkInClipboard(url)} primary={name} secondary={url} />
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => onLinkOpen({ id, name, url })} style={{ marginLeft: '15px' }}>
            <Launch color="primary" />
          </IconButton>
          <IconButton onClick={() => onLinkEdit({ id, name, url })} edge="end" style={{ marginLeft: '15px' }}>
            <EditRounded color="primary" />
          </IconButton>
          <IconButton onClick={() => onLinkDelete(id)} edge="end" style={{ marginLeft: '15px' }}>
            <Delete color="primary" />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    ),
    [disabled, onLinkEdit, onLinkDelete, handleCopyLinkInClipboard, onLinkOpen]
  );

  const byName = (entity1, entity2) => {
    if (entity1.name > entity2.name) {
      return 1;
    }
    if (entity1.name < entity2.name) {
      return -1;
    }
    return 0;
  };

  return (
    <List dense>
      {[documentList.folders || []]
        .flat()
        .sort(byName)
        .map(folder => renderFolderListItem(folder.name, folder.id, 'folder'))}
      {[documentList.links || []]
        .flat()
        .sort(byName)
        .map(link => renderLinkListItem(link.id, link.name, link.url))}
      {[documentList.files || []]
        .flat()
        .sort(byName)
        .map(file => renderFileListItem(file.name, file.id, file.type))}
    </List>
  );
};

IntranetFileList.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  documentList: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  onDelete: PropTypes.func,
  onDeleteFolder: PropTypes.func,
  onRenameFolder: PropTypes.func,
  onDownload: PropTypes.func,
  onFolderClick: PropTypes.func.isRequired,
  onLinkDelete: PropTypes.func,
  onLinkEdit: PropTypes.func,
  onLinkOpen: PropTypes.func
};

IntranetFileList.defaultProps = {
  onDelete: undefined,
  onDeleteFolder: undefined,
  onDownload: undefined,
  onRenameFolder: undefined,
  onLinkDelete: () => {},
  onLinkEdit: () => {},
  onLinkOpen: () => {},
  readOnly: false,
  disabled: false,
  documentList: []
};

export default IntranetFileList;
