import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Button from '@material-ui/core/Button';
import { Switch, TextField } from '@material-ui/core';
import { Link } from 'react-router-dom';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import { updatePotentialCandidate } from './api/projectArchiveAPI';
import PopUp from '../components/PopUp';
import * as UTILS from '../utils/utilFunctions';
import DossierViewedIcon from '../components/DossierViewedIcon';

const loadCandidates = project => {
  const candidateDossierList = [];
  project.potentialCandidates.forEach(potentialCandidate => {
    if (potentialCandidate.candidate.projectDossier && potentialCandidate.candidate.projectDossier.length > 0) {
      let firstInterview = '';
      let secondInterview = '';
      try {
        firstInterview =
          potentialCandidate.clientAcceptsFirstInterview !== null ? potentialCandidate.clientAcceptsFirstInterview.toString() : '';
        secondInterview =
          potentialCandidate.clientAcceptsSecondInterview !== null ? potentialCandidate.clientAcceptsSecondInterview.toString() : '';
      } catch (e) {
        // empty on purpose
      }

      candidateDossierList.push([
        project.id,
        potentialCandidate.id,
        potentialCandidate.candidateId,
        potentialCandidate.dossierIsViewedByClient || undefined,
        potentialCandidate.candidate.firstName,
        potentialCandidate.candidate.lastName,
        potentialCandidate.candidate.dateOfBirth,
        potentialCandidate.candidate.postalCode,
        potentialCandidate.candidate.city,
        potentialCandidate.addedDate,
        potentialCandidate.rejectionDate,
        potentialCandidate.candidate.projectDossier.fileName,
        potentialCandidate.candidate.projectDossier.creationDate,
        firstInterview,
        potentialCandidate.firstInterviewText,
        secondInterview,
        potentialCandidate.secondInterviewText,
        potentialCandidate.showInCustomerPortal,
        potentialCandidate.clientWishesContractProposal
      ]);
    }
  });
  return candidateDossierList;
};

const ProjectDossierList = ({ project, liftUpUpdatedPotentialCandidates, handleSnackbarOpen }) => {
  const [showInterviewPopup, setShowInterviewPopup] = useState(false);
  const [interviewPopupText, setInterviewPopupText] = useState('');

  const updateCustomerPortalPermissionSettings = (potentialCandidateId, value, updatedField) => {
    const oldPotentialCandidates = project.potentialCandidates;
    const indexOfUpdatedPotentialCandidate = oldPotentialCandidates.findIndex(pC => pC && pC.id === potentialCandidateId);
    if (indexOfUpdatedPotentialCandidate >= 0) {
      const newPotentialCandidates = oldPotentialCandidates.slice();

      newPotentialCandidates[indexOfUpdatedPotentialCandidate][updatedField] = value;

      liftUpUpdatedPotentialCandidates(newPotentialCandidates);

      updatePotentialCandidate(project.id, potentialCandidateId, {
        [updatedField]: value
      })
        .then(() => {
          const notText = value ? '' : 'nicht mehr';
          const inCustomerJourney = 'im Kundenportal sichtbar';
          const snackBarText = `Der Kandidat ist jetzt ${notText} ${inCustomerJourney}`;

          handleSnackbarOpen(snackBarText);
        })
        .catch(err => console.log(err));
    }
  };

  const COLUMNS = [
    {
      name: 'ID',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    {
      name: 'potentialCandidateId',
      options: {
        display: 'excluded',
        filter: false,
      }
    },
    {
      name: 'candidateId',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    {
      name: 'Dossier gelesen?',
      options: {
        display: 'true',
        filter: false,
        customBodyRender: value => {
          const isViewed = !!value;
          return <DossierViewedIcon isViewed={isViewed} />;
        }
      }
    },
    {
      name: 'Vorname',
      options: {
        display: 'true',
        filter: false,
        customBodyRender: (value, metaData) => <Link to={`candidates?id=${metaData.rowData[2]}`}>{value}</Link>
      }
    },
    {
      name: 'Nachname',
      options: {
        display: 'true',
        filter: false,
        customBodyRender: (value, metaData) => <Link to={`candidates?id=${metaData.rowData[2]}`}>{value}</Link>
      }
    },
    {
      name: 'Geburtsdatum',
      options: {
        customBodyRender: value => UTILS.formatStringToDELocale(value),
        display: 'true',
        filter: false
      }
    },
    {
      name: 'PLZ',
      options: {
        display: 'true',
        filter: false
      }
    },
    {
      name: 'Wohnort',
      options: {
        display: 'true',
        filter: true
      }
    },
    {
      name: 'Zuordnung am',
      options: {
        customBodyRender: value => UTILS.formatStringToDELocale(value),
        display: true,
        filter: true
      }
    },
    {
      name: 'Abgesagt am',
      options: {
        customBodyRender: value => UTILS.formatStringToDELocale(value),
        display: true,
        filter: true
      }
    },
    {
      name: 'Dossiername'
    },
    {
      name: 'Dossier Erstellungsdatum',
      options: {
        customBodyRender: value => UTILS.formatStringToDELocale(value),
        display: 'true',
        filter: false
      }
    },
    {
      name: '1. Gespräch gewünscht',
      options: {
        customBodyRender: value => {
          if (value === 'false') {
            return <HighlightOffIcon />;
          }
          if (value === 'true') {
            return <CheckCircleOutlineIcon />;
          }
          return <RadioButtonUncheckedIcon />;
        },
        filter: false
      }
    },
    {
      name: '1. Gespräch Text',
      options: {
        customBodyRender: value =>
          value && (
            <Button
              onClick={() => {
                setInterviewPopupText(value);
                setShowInterviewPopup(true);
              }}
            >
              Anzeigen
            </Button>
          ),
        filter: false
      }
    },
    {
      name: '2. Gespräch gewünscht',
      options: {
        customBodyRender: value => {
          if (value === 'false') {
            return <HighlightOffIcon />;
          }
          if (value === 'true') {
            return <CheckCircleOutlineIcon />;
          }
          return <RadioButtonUncheckedIcon />;
        },
        filter: false
      }
    },
    {
      name: '2. Gespräch Text',
      options: {
        customBodyRender: value =>
          value && (
            <Button
              onClick={() => {
                setInterviewPopupText(value);
                setShowInterviewPopup(true);
              }}
            >
              Anzeigen
            </Button>
          ),
        filter: false
      }
    },
    {
      name: 'Kundenportal',
      options: {
        customBodyRender: (value, { rowData }) => (
          <Switch
            checked={value}
            onChange={() => updateCustomerPortalPermissionSettings(rowData[1], !value, 'showInCustomerPortal')}
            color="primary"
            inputProps={{ 'aria-label': 'controlled' }}
          />
        ),
        filter: false
      }
    },
    {
      name: 'Vertragsangebot',
      options: {
        customBodyRender: value => <Switch checked={value} disabled inputProps={{ 'aria-label': 'controlled' }} />,
        filter: false
      }
    }
  ];

  return (
    <>
      <PopUp onClose={() => setShowInterviewPopup(false)} open={showInterviewPopup} closeText="Schließen" title="Interview Termin">
        <TextField fullWidth multiline disabled value={interviewPopupText} />
      </PopUp>
      <SafeMuiDatatable
        title="Kandidaten Dossiers freischalten"
        tableName="projectDossierList"
        data={loadCandidates(project)}
        handleSnackbarOpen={handleSnackbarOpen}
        columns={COLUMNS}
      />
    </>
  );
};

ProjectDossierList.propTypes = {
  project: PropTypes.object.isRequired,
  liftUpUpdatedPotentialCandidates: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default ProjectDossierList;
