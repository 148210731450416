import { Fab, ListItemIcon, ListItemText, Menu, MenuItem, Typography } from '@material-ui/core';
import { Folder, Link } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ConfirmationModal from '../generic_components/ConfirmationModal';
import DropContainer from '../generic_components/DropContainer';
import withUser from '../utils/withUser';
import IntranetBreadcrumbs from './Breadcrumbs';
import FolderModal from './FolderModal';
import IntranetContainer from './IntranetContainer';
import './IntranetDocuments.css';
import IntranetFileList from './IntranetFileList';
import { createFile, createFolder, deleteFile, deleteFolder, getFile, getFolder, renameFolder } from './api/intranetApi';
import LinkModal from './modal/LinkModal';
import useDialogState from './hooks/dialogAction';
import useIntranetLinks from './hooks/link';
import * as CONSTANTS from '../constants';

function IntranetDocuments({ handleSnackbarOpen, pdfErrorMessage, documentLoading, user }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [fileToDelete, setFileToDelete] = useState();
  const [folderToDelete, setFolderToDelete] = useState();
  const [deleteFileConfirmationOpen, setDeleteFileConfirmationOpen] = useState(false);
  const [deleteFolderConfirmationOpen, setDeleteFolderConfirmationOpen] = useState(false);
  const [createFolderModalOpen, setCreateFolderModalOpen] = useState(false);

  const [renameFolderModalOpen, setRenameFolderModalOpen] = useState(false);
  const [selectedRenameFolder, setSelectedRenameFolder] = useState();
  const [loading, setLoading] = useState(false);
  const [currentFolder, setCurrentFolder] = useState({ name: 'Home', id: null });
  const [documentList, setDocumentList] = useState();
  const [currentLink, setCurrentLink] = useState(null);

  const { dialogState: dialogStateCreate, handleOpen: handleOpenCreate } = useDialogState();
  const { dialogState: dialogStateUpdate, handleOpen: handleOpenUpdate } = useDialogState();

  // Menu functions
  const handleOpenMenu = useCallback(event => {
    setMenuAnchorEl(event.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleOnClickMenuFolder = useCallback(() => {
    setCreateFolderModalOpen(true);
    setMenuAnchorEl(null);
  }, []);

  const refreshDocumentList = useCallback(async () => {
    const content = await getFolder(currentFolder.id);

    setDocumentList(content);
    setCurrentFolder({ name: content.name, id: content.id });
  }, [currentFolder.id]);

  const canModify = useMemo(() => user.role === 'admin', [user.role]);

  useEffect(() => {
    refreshDocumentList();
  }, [refreshDocumentList]);

  const { createLink, deleteLink, updateLink } = useIntranetLinks({ handleSnackbarOpen, currentFolder, refreshDocumentList });

  const onCreateFolderClick = folderName => {
    createFolder(folderName, currentFolder.id)
      .then(() => {
        handleSnackbarOpen(`Der Ordner ${folderName} wurde erstellt`);
        refreshDocumentList();
      })
      .catch(() => {
        handleSnackbarOpen('Es ist ein technischer Fehler aufgetreten');
      });
    setCreateFolderModalOpen(false);
  };

  const renameFolderApiCall = newFolderName => {
    if (!selectedRenameFolder) {
      return;
    }

    renameFolder(newFolderName, selectedRenameFolder.id)
      .then(() => {
        refreshDocumentList();
        handleSnackbarOpen(`Der Ordner ${newFolderName} wurde geändert`);
        setSelectedRenameFolder(undefined);
      })
      .catch(() => {
        handleSnackbarOpen('Es ist ein technischer Fehler aufgetreten');
      });
    setSelectedRenameFolder(undefined);
    setRenameFolderModalOpen(false);
  };

  const deleteFolderApiCall = () => {
    deleteFolder(folderToDelete)
      .then(() => {
        handleSnackbarOpen('Der Ordner wurde gelöscht');
        refreshDocumentList();
      })
      .catch(() => {
        handleSnackbarOpen('Es ist ein technischer Fehler aufgetreten');
      });
    setFolderToDelete(undefined);
    setDeleteFolderConfirmationOpen(false);
  };

  const deleteFileApiCall = () => {
    deleteFile(fileToDelete.fileId, currentFolder.id, fileToDelete.fileName)
      .then(() => {
        handleSnackbarOpen('Die Datei wurde gelöscht');
        refreshDocumentList();
      })
      .catch(() => {
        handleSnackbarOpen('Es ist ein technischer Fehler aufgetreten');
      });
    setFileToDelete(undefined);
    setDeleteFileConfirmationOpen(false);
  };

  const onFolderClick = async folderId => {
    const content = await getFolder(folderId);
    console.log('folder', folderId);
    setDocumentList(content);
    setCurrentFolder({ name: content.name, id: folderId });
  };

  const onFileClick = (fileId, fileName) => {
    getFile(fileId, fileName)
      .then(responseBody => {
        console.log(fileName);
        const element = document.createElement('a');
        element.setAttribute('href', responseBody.contentUrl);
        element.setAttribute('target', '_blank');

        element.setAttribute('download', fileName);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();

        document.body.removeChild(element);
      })
      .catch(err => console.error(err));
  };

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length !== 1) {
      handleSnackbarOpen('Es können nur einzelne Dateien hochgeladen werden');
      return;
    }

    if (acceptedFiles[0].size === 0) {
      handleSnackbarOpen('Datei hat keinen Inhalt (Größe ist 0 byte)');
      return;
    }

    setLoading(true);
    createFile(currentFolder.id, acceptedFiles[0])
      .then(() => {
        setLoading(false);
        refreshDocumentList();
        handleSnackbarOpen('Die Datei wurde erfolgreich hochgeladen');
      })
      .catch(err => {
        console.log('ERROR', err);
        setLoading(false);
        if (err.status === 0) {
          handleSnackbarOpen('Upload fehlgeschlagen (ggf. ist die Datei gesperrt)');
        } else if (err.status === 413) {
          handleSnackbarOpen('Datei zu groß (maximale Größe 100mb)');
        } else if (err.status === 415) {
          handleSnackbarOpen('Nicht unterstütztes Dateiformat');
        } else handleSnackbarOpen('Es ist ein technischer Fehler aufgetreten');
      });
  };

  const renderDeleteFileConfirmationModal = () =>
    fileToDelete &&
    deleteFileConfirmationOpen && (
      <ConfirmationModal
        handleClose={() => {
          setDeleteFileConfirmationOpen(false);
          setFileToDelete(undefined);
        }}
        open={deleteFileConfirmationOpen}
        buttonPrimaryAction={() => {
          deleteFileApiCall();
          setDeleteFileConfirmationOpen(false);
        }}
        headlineText="Dokument löschen"
        descriptionText="Wollen Sie das Dokument unwiderruflich löschen?"
        buttonPrimaryText="Ja"
        primaryButtonIsDisabled={loading}
      />
    );
  const renderDeleteFolderConfirmationModal = () =>
    folderToDelete &&
    deleteFolderConfirmationOpen && (
      <ConfirmationModal
        handleClose={() => {
          setDeleteFolderConfirmationOpen(false);
          setFolderToDelete(undefined);
        }}
        open={deleteFolderConfirmationOpen}
        buttonPrimaryAction={() => {
          deleteFolderApiCall();
          setDeleteFolderConfirmationOpen(false);
        }}
        headlineText="Ordner löschen"
        descriptionText="Wollen Sie den Ordner unwiderruflich löschen?"
        buttonPrimaryText="Ja"
        primaryButtonIsDisabled={loading}
      />
    );
  const onRenameFolderClick = folderId => {
    if (!documentList || !documentList.folders) {
      return;
    }
    const folder = documentList.folders.find(f => f.id === folderId);
    if (!folder) {
      return;
    }

    setRenameFolderModalOpen(true);
    setSelectedRenameFolder(folder);
  };

  const onDeleteFolderClick = folderId => {
    setDeleteFolderConfirmationOpen(true);
    setFolderToDelete(folderId);
  };

  const onDeleteFileClick = (fileId, fileName) => {
    setDeleteFileConfirmationOpen(true);
    setFileToDelete({ fileId, fileName });
  };

  const handleOnLinkEdit = useCallback(
    /**
     *
     * @param {import('./hooks/link').Link} link
     */
    link => {
      setMenuAnchorEl(null);
      setCurrentLink(link);
      handleOpenUpdate();
    },
    [handleOpenUpdate]
  );

  const handleOnCloseUpdate = useCallback(() => {
    setCurrentLink(null);
    setMenuAnchorEl(null);
  }, []);

  const handleOnCloseCreate = useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleLinkOpen = useCallback(link => {
    window.open(link.url, '_blank', 'noreferrer');
  });
  return (
    <>
      <IntranetContainer>
        {documentList && (
          <div className="mt-3 ml-3">
            <IntranetBreadcrumbs onFolderClick={onFolderClick} currentFolder={currentFolder} folderList={documentList.parentFolders} />
          </div>
        )}
        <div className={!documentList ? 'info-text-typography' : ''}>
          <DropContainer
            onDrop={canModify ? onDrop : undefined}
            multiple={false}
            noClick={false}
            dropzoneErrorMessage={pdfErrorMessage}
            showLoadingIndicator={documentLoading}
            actionDescription={documentLoading ? 'Dateien werden gespeichert' : 'Dateien hier ablegen'}
          >
            {documentList ? (
              <IntranetFileList
                documentList={documentList}
                onFolderClick={onFolderClick}
                onDelete={canModify ? onDeleteFileClick : undefined}
                onDownload={onFileClick}
                onRenameFolder={canModify ? onRenameFolderClick : undefined}
                onDeleteFolder={canModify ? onDeleteFolderClick : undefined}
                onLinkDelete={deleteLink}
                onLinkEdit={handleOnLinkEdit}
                onLinkOpen={handleLinkOpen}
                handleSnackbarOpen={handleSnackbarOpen}
                loading={loading}
              />
            ) : (
              <Typography component="p" align="center" variant="body1">
                In diesem Ordner befinden sich noch keine Dateien
              </Typography>
            )}
          </DropContainer>
        </div>
      </IntranetContainer>
      <FolderModal
        open={createFolderModalOpen}
        handleClose={() => setCreateFolderModalOpen(false)}
        inputLabel="Ordername"
        buttonPrimaryAction={onCreateFolderClick}
        headlineText="Neuer Ordner"
        descriptionText="Bitte geben Sie einen Ordnernamen an, um einen neuen Ordner zu erstellen"
        icon="create_new_folder" //
      />
      <FolderModal
        open={renameFolderModalOpen}
        handleClose={() => {
          setRenameFolderModalOpen(false);
          setSelectedRenameFolder(undefined);
        }}
        inputLabel="Ordername"
        buttonPrimaryAction={renameFolderApiCall}
        descriptionText="Bitte geben Sie einen Ordnernamen an"
        icon="create_new_folder" // TODO Icon
        headlineText="Ordner umbenennen"
        initialFolderName={selectedRenameFolder && selectedRenameFolder.name ? selectedRenameFolder.name : ''}
      />
      {/* Modal To Create Links */}
      <LinkModal
        onSubmit={createLink}
        onClose={handleOnCloseCreate}
        dialogState={dialogStateCreate}
        headlineText="Neuen Link"
        descriptionText="Bitte geben Sie einen Namen für den Link sowie die entsprechende URL ein!"
      />
      <LinkModal
        onSubmit={updateLink}
        onClose={handleOnCloseUpdate}
        initValue={currentLink}
        dialogState={dialogStateUpdate}
        headlineText="Link bearbeiten"
        descriptionText="Bitte geben Sie einen Namen für den Link sowie die entsprechende URL ein!"
      />

      {renderDeleteFileConfirmationModal()}
      {renderDeleteFolderConfirmationModal()}

      {canModify ? (
        <>
          <Fab color="primary" onClick={handleOpenMenu} style={{ position: 'absolute', bottom: '30px', right: '30px' }}>
            <AddIcon />
          </Fab>
          <Menu anchorEl={menuAnchorEl} open={Boolean(menuAnchorEl)} onClose={handleCloseMenu}>
            <MenuItem dense onClick={handleOnClickMenuFolder}>
              <ListItemIcon>
                <Folder fontSize="small" />
              </ListItemIcon>
              <ListItemText>Ordner hinzufügen</ListItemText>
            </MenuItem>
            {(CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) && (
              <MenuItem dense onClick={handleOpenCreate}>
                <ListItemIcon>
                  <Link href fontSize="small" />
                </ListItemIcon>
                <ListItemText>Link hinzufügen</ListItemText>
              </MenuItem>
            )}
          </Menu>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

IntranetDocuments.defaultProps = {};

IntranetDocuments.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  pdfErrorMessage: PropTypes.string.isRequired,
  documentLoading: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired
};
export default withUser(IntranetDocuments);
