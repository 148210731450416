// base imports
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import LZString from 'lz-string';

// Material UI
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Dialog from '@material-ui/core/Dialog';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';
import debounce from 'lodash/debounce';

// Icons
import CloseIcon from '@material-ui/icons/Close';
import { Tooltip } from '@material-ui/core';
import ValidatedDropDown from './ValidatedDropDown';
import TransitionSlide from './TransitionSlide';
import withUser from '../utils/withUser';

// custom components
import DetailSearchLoadQueryBox from './DetailSearchLoadQueryBox';
import DetailSearchSaveQueryBox from './DetailSearchSaveQueryBox';
import * as CONSTANTS from '../constants';
import * as TEXT from '../text';
import * as UTILS from '../utils/utilFunctions';
import CurrencyInputLienert from '../components/inputs/CurrencyInputLienert';
import PercentageInput from '../components/inputs/PercentageInput';

const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

class DetailSearch extends Component {
  constructor(props) {
    super(props);
    let detailSearchFieldList = UTILS.getURLParam(this.props.history, 'dq');
    const maxAmountOfSearchResults = UTILS.getURLParam(this.props.history, 'ma');

    if (detailSearchFieldList) detailSearchFieldList = LZString.decompressFromEncodedURIComponent(detailSearchFieldList);

    try {
      detailSearchFieldList = JSON.parse(detailSearchFieldList);
    } catch (e) {
      detailSearchFieldList = undefined;
    }
    this.state = {
      mainSearchFieldValue: '',
      detailSearchFieldList: detailSearchFieldList || [{ dropDownValue: 'none', fieldValue: '', isExactMatch: true }],
      maxAmountOfSearchResults: maxAmountOfSearchResults || 100,
      isSaveDialogOpen: false,
      isLoadDialogOpen: false
    };
    this.saveSearchQueryToURL = debounce(this.saveSearchQueryToURL, 500);
  }

  getAppBarStyle() {
    switch (window.location.pathname) {
      case '/candidates':
        return THEME.appBar.candidates;
      case '/projects':
        return THEME.appBar.projects;
      case '/companies':
        return THEME.appBar.companies;
      default:
        return THEME.appBar;
    }
  }

  generateDetailSearchFieldList = () => {
    const detailSearchFieldList = this.state.detailSearchFieldList.filter(
      item =>
        item.dropDownValue !== 'none' &&
        (item.fieldValue !== undefined && item.fieldValue.length !== 0) &&
        !Object.values(item.fieldValue).every(itemValue => itemValue === '')
    );
    if (this.state.mainSearchFieldValue) {
      detailSearchFieldList.unshift({
        dropDownValue: 'mainQuery',
        fieldValue: this.state.mainSearchFieldValue
      });
    }
    return detailSearchFieldList;
  };

  onSearchButtonClick = () => {
    const detailSearchFieldList = this.generateDetailSearchFieldList();
    this.props.onSearch(detailSearchFieldList, this.state.maxAmountOfSearchResults);
  };

  onExportButtonClick = () => {
    const detailSearchFieldList = this.generateDetailSearchFieldList();
    this.props.onExport(detailSearchFieldList);
  };

  resetFieldsState() {
    this.setState({
      mainSearchFieldValue: '',
      detailSearchFieldList: [{ dropDownValue: 'none', fieldValue: '', isExactMatch: true }],
      maxAmountOfSearchResults: 100
    });
  }

  isInState(option) {
    return this.state.detailSearchFieldList.find(elem => option.fieldId === elem.dropDownValue);
  }

  generateSelectList() {
    const result = [
      <option key="none" value="none">
        Bitte Feld wählen
      </option>
    ];
    this.props.dropDownOptionDataList.forEach(item => {
      // check if option is already used instate
      const isUsed = this.isInState(item);
      result.push(
        <option disabled={isUsed} key={item.label} value={item.fieldId}>
          {item.label}
        </option>
      );
    });
    return result;
  }

  generateDropDown(index, value) {
    return (
      <FormControl className="w-100">
        <Select native value={value} style={{ marginTop: '16px' }} onChange={this.onDropDownChange} autoWidth name={`${index}`}>
          {this.generateSelectList()}
        </Select>
      </FormControl>
    );
  }

  renderDropdownOptions(dropDownValues) {
    const returnArray = [];
    returnArray.push(
      <option key="none" value="none">
        Bitte Feld wählen
      </option>
    );
    dropDownValues.forEach(value => {
      if (typeof value === 'object' && value !== null) {
        returnArray.push(
          <option key={value.value} value={value.value}>
            {value.label}
          </option>
        );
      } else
        returnArray.push(
          <option key={value} value={value}>
            {value}
          </option>
        );
    });
    return returnArray;
  }

  generateInputFieldType(dropDownValue, fieldValue, index) {
    const fieldType = this.getFieldType(dropDownValue);
    let dropDownValues;
    if (fieldType === 'dropDown') dropDownValues = this.getDropDownValues(dropDownValue);
    switch (fieldType) {
      case 'lienert-currencyRage':
        return (
          <div className="d-flex" style={{ gap: '10px' }}>
            <TextField
              fullWidth
              value={fieldValue.from}
              onChange={this.handleOnCurrencyChange(index, 'from')}
              name="lienert-currency-range"
              InputProps={{
                inputComponent: CurrencyInputLienert
              }}
              label="von"
            />
            <TextField
              fullWidth
              value={fieldValue.to}
              onChange={this.handleOnCurrencyChange(index, 'to')}
              InputProps={{
                inputComponent: CurrencyInputLienert
              }}
              name="lienert-currency-range"
              label="bis"
            />
          </div>
        );
      case 'percentage-range':
        return (
          <div className="d-flex" style={{ gap: '10px' }}>
            <TextField
              fullWidth
              value={fieldValue.from}
              InputProps={{
                inputComponent: PercentageInput
              }}
              onChange={this.handleOnCurrencyChange(index, 'from')}
              name="percentage-input"
              label="von"
            />
            <TextField
              fullWidth
              value={fieldValue.from}
              InputProps={{
                inputComponent: PercentageInput
              }}
              onChange={this.handleOnCurrencyChange(index, 'to')}
              name="percentage-input"
              label="bis"
            />
          </div>
        );
      case 'textField':
        return (
          <div className="d-flex" style={{ marginTop: '16px' }}>
            <TextField fullWidth value={fieldValue} onChange={e => this.onInputChange(e, index)} id={index + fieldType} />
            <Tooltip title="Exakte Suche">
              <Checkbox
                style={{
                  color: this.getAppBarStyle().background
                }}
                checked={this.state.detailSearchFieldList[index].isExactMatch}
                onChange={e => this.onCheckBoxChange(e, index)}
                value="testCheck"
              />
            </Tooltip>
            <Tooltip title="Suchfeld löschen">
              <IconButton onClick={() => this.removeDetailSearchField(index)} aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      case 'dropDown':
        return (
          <div className="d-flex" style={{ marginTop: '16px' }}>
            <FormControl className="w-100">
              <Select native value={fieldValue} onChange={e => this.onInputChange(e, index)} autoWidth name={`${index}`}>
                {dropDownValues && this.renderDropdownOptions(dropDownValues)}
              </Select>
            </FormControl>
            <Tooltip title="Suchfeld löschen">
              <IconButton onClick={() => this.removeDetailSearchField(index)} aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      case 'datePicker':
        return (
          <div className="d-flex">
            <TextField
              fullWidth
              type="date"
              label="von"
              value={fieldValue.fromDate}
              onChange={e => this.onDateChange(e, index, 'fromDate')}
              id={`${index + fieldType}-fromData`}
              InputLabelProps={{
                shrink: true
              }}
            />
            <TextField
              fullWidth
              type="date"
              label="bis"
              style={{ marginLeft: '5px' }}
              value={fieldValue.toDate}
              onChange={e => this.onDateChange(e, index, 'toDate')}
              id={`${index + fieldType}-toDate`}
              InputLabelProps={{
                shrink: true
              }}
            />
            <Tooltip title="Suchfeld löschen">
              <IconButton onClick={() => this.removeDetailSearchField(index)} aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      default:
        return <div />;
    }
  }

  /**
   * Handle onChange range fields with different fieldNames and types
   * @param {number} index
   * @param {"from" | "to"} fieldType
   * @param {React.ChangeEvent<HTMLInputElement>}
   */
  handleOnCurrencyChange = (index, fieldType) =>
    /**
     *
     * @param {React.ChangeEvent<HTMLInputElement>} e
     */
    e => {
      const value = e.target.value;
      this.setState(prevState => {
        const detailSearchFieldList = prevState.detailSearchFieldList;
        detailSearchFieldList[index].fieldValue = {
          ...detailSearchFieldList[index].fieldValue
        };
        detailSearchFieldList[index].fieldValue[fieldType] = value;
        return { detailSearchFieldList };
      });
    };

  getFieldType(dropDownValue) {
    let fieldType;
    this.props.dropDownOptionDataList.forEach(entry => {
      if (entry.fieldId === dropDownValue) fieldType = entry.fieldType;
    });
    return fieldType;
  }

  getDropDownValues(dropDownValue) {
    let dropDownValues;
    this.props.dropDownOptionDataList.forEach(entry => {
      if (entry.fieldId === dropDownValue) dropDownValues = entry.dropDownValues;
    });
    return dropDownValues;
  }

  removeDetailSearchField = index => {
    if (this.state.detailSearchFieldList.length - 1 > 0) {
      this.setState(
        prevState => {
          const detailSearchFieldList = prevState.detailSearchFieldList;
          detailSearchFieldList.splice(index, 1);
          return { detailSearchFieldList };
        },
        () => {
          this.saveSearchQueryToURL(this.state.detailSearchFieldList);
        }
      );
    }
  };

  saveSearchQueryToURL(detailSearchFieldList) {
    UTILS.setURLParam(this.props.history, 'dq', LZString.compressToEncodedURIComponent(JSON.stringify(detailSearchFieldList)));
  }

  onDropDownChange = e => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState(
      prevState => {
        const detailSearchFieldList = prevState.detailSearchFieldList;
        if (value !== 'none' && prevState.detailSearchFieldList[name].dropDownValue === 'none')
          detailSearchFieldList.push({
            dropDownValue: 'none',
            fieldValue: '',
            isExactMatch: true
          });
        if (value === 'none' && detailSearchFieldList.length - 1 > 0 && detailSearchFieldList.length - 1 !== name) {
          detailSearchFieldList.splice(name, 1);
        } else {
          detailSearchFieldList[name].dropDownValue = value;
          detailSearchFieldList[name].fieldValue = '';
          detailSearchFieldList[name].isExactMatch = true;
        }

        return { detailSearchFieldList };
      },
      () => {
        this.saveSearchQueryToURL(this.state.detailSearchFieldList);
      }
    );
  };

  onCheckBoxChange = (e, index) => {
    const value = e.target.checked;
    this.setState(
      prevState => {
        const detailSearchFieldList = prevState.detailSearchFieldList;
        detailSearchFieldList[index].isExactMatch = value;
        return { detailSearchFieldList };
      },
      () => {
        this.saveSearchQueryToURL(this.state.detailSearchFieldList);
      }
    );
  };

  onDateChange = (e, index, entry) => {
    const value = e.target.value;
    this.setState(
      prevState => {
        const detailSearchFieldList = prevState.detailSearchFieldList;
        detailSearchFieldList[index].fieldValue = {
          ...detailSearchFieldList[index].fieldValue
        };
        detailSearchFieldList[index].fieldValue[entry] = value;
        return { detailSearchFieldList };
      },
      () => {
        this.saveSearchQueryToURL(this.state.detailSearchFieldList);
      }
    );
  };

  onInputChange = (e, index) => {
    const value = e.target.value;
    this.setState(
      prevState => {
        const detailSearchFieldList = prevState.detailSearchFieldList;
        detailSearchFieldList[index].fieldValue = value;
        return { detailSearchFieldList };
      },
      () => {
        this.saveSearchQueryToURL(this.state.detailSearchFieldList);
      }
    );
  };

  renderDetailsSearchFields() {
    const rows = [];
    this.state.detailSearchFieldList.forEach((detailSearchField, index) => {
      rows.push(
        <div key={detailSearchField.dropDownValue} className="row pb-3">
          <div className="col-6">{this.generateDropDown(index, detailSearchField.dropDownValue)}</div>
          <div className="col-6">{this.generateInputFieldType(detailSearchField.dropDownValue, detailSearchField.fieldValue, index)}</div>
        </div>
      );
    });
    return rows;
  }

  renderAppBar() {
    return (
      <div>
        <AppBar style={this.getAppBarStyle()}>
          <Toolbar>
            <IconButton color={this.props.color} onClick={this.props.handleDrawerClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              {this.props.appBarTitle}
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ height: 64 }}>{/* Shim - Not visible */}</div>
      </div>
    );
  }

  loadQuery = updatedState => {
    this.setState(updatedState, () => {
      this.saveSearchQueryToURL(this.state.detailSearchFieldList);
    });
  };

  renderLoadDialog() {
    return (
      <DetailSearchLoadQueryBox
        title="Detailsuche laden"
        description="Wählen Sie eine gespeicherte Detailsuche aus der Liste"
        emptyListText="Es sind noch keine gespeicherten Detailsuchen vorhanden"
        cancelText="abbrechen"
        open={this.state.isLoadDialogOpen}
        detailSearchType={this.props.detailSearchType}
        handleLoadQuery={this.loadQuery}
        handleClose={() => {
          this.setState({
            isLoadDialogOpen: false
          });
        }}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
      />
    );
  }

  renderSaveDialog() {
    return (
      <DetailSearchSaveQueryBox
        title="Detailsuche speichern"
        description={TEXT.resultPage.saveSearchBox.description}
        detailSearchQuery={{
          mainSearchFieldValue: this.state.mainSearchFieldValue,
          detailSearchFieldList: this.state.detailSearchFieldList,
          maxAmountOfSearchResults: this.state.maxAmountOfSearchResults
        }}
        cancelText="abbrechen"
        saveText="speichern"
        open={this.state.isSaveDialogOpen}
        detailSearchType={this.props.detailSearchType}
        placeholderText={TEXT.resultPage.saveSearchBox.labelInputField}
        handleClose={() => {
          this.setState({
            isSaveDialogOpen: false
          });
        }}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
      />
    );
  }

  renderMainSearchField() {
    return (
      <div className="row mb-3">
        <div className="col-6">
          <TextField
            margin="normal"
            value={this.state.mainSearchFieldValue}
            autoFocus
            fullWidth
            placeholder={this.props.mainSearchFieldPlaceholderText}
            onChange={e => {
              this.setState({ mainSearchFieldValue: e.target.value });
            }}
            variant="outlined"
          />
        </div>
        <div className="col-2 offset-2">
          <Button
            variant="contained"
            style={{
              marginTop: '12px',
              background: this.getAppBarStyle().background,
              color: 'white'
            }}
            fullWidth
            onClick={() => {
              UTILS.setURLParam(this.props.history, 'ls', '');
              UTILS.setURLParam(this.props.history, 'dq', '');
              UTILS.setURLParam(this.props.history, 'ma', '100');

              this.resetFieldsState();
            }}
          >
            neue suche <i className="material-icons">autorenew</i>
          </Button>
        </div>
      </div>
    );
  }

  renderSearchSettings() {
    return (
      <Tooltip title="Empfohlene Einstellung: 100 um eine maximale Suchperformance zu gewährleisten">
        <ValidatedDropDown
          value={this.state.maxAmountOfSearchResults}
          onChange={e => {
            this.setState({ maxAmountOfSearchResults: +e.target.value });
          }}
          content={systemPartner === 'lienert' ? [10, 100, 200, 500, 1000, 2000, 5000, 10000] : [10, 100, 200, 500, 1000, 2000]}
          // content={[10, 100, 200, 500, 1000, 5000, 10000]} // bad performance for more than 1000
          label="max. angezeigte Suchergebnisse"
          id="maxAmountOfSearchResults"
          errorText={
            this.state.maxAmountOfSearchResults >= 1000 ? 'Beschränken Sie die Trefferanzahl zur Steigerung der Suchperformance' : undefined
          }
        />
      </Tooltip>
    );
  }

  renderExportButton() {
    if (this.props.user.canAccessExportFeature) {
      return (
        <div className="row mt-2 mb-5">
          <div className="col-3" style={{ marginLeft: 'auto' }}>
            <Button
              variant="contained"
              style={{
                marginTop: '12px',
                background: this.getAppBarStyle().background,
                color: 'white'
              }}
              fullWidth
              onClick={() => {
                this.onExportButtonClick();
              }}
            >
              {TEXT.detailSearch.exportSearch} <i className="material-icons">file_download</i>
            </Button>
          </div>
        </div>
      );
    }
    return null;
  }

  render() {
    return (
      <Dialog fullScreen open={this.props.isDrawerOpen} onClose={this.props.handleDrawerClose} TransitionComponent={TransitionSlide}>
        <div>
          {this.renderAppBar()}
          <div className="container mt-3">
            {this.renderMainSearchField()}
            {this.state.detailSearchFieldList.length > 0 && this.renderDetailsSearchFields()}
            <div className="row mt-5 mb-5">
              <div className="col-3 white-space-nowrap">{this.renderSearchSettings()}</div>
              <div className="col-3 align-bottom">
                <Button
                  variant="contained"
                  style={{
                    marginTop: '12px',
                    background: this.getAppBarStyle().background,
                    color: 'white'
                  }}
                  fullWidth
                  onClick={() => {
                    UTILS.setURLParam(this.props.history, 'ls', 'detail');
                    UTILS.setURLParam(this.props.history, 'ma', this.state.maxAmountOfSearchResults);

                    this.onSearchButtonClick();
                  }}
                >
                  {TEXT.detailSearch.search} <i className="material-icons">search</i>
                </Button>
              </div>
              <div className="col-3">
                <Button
                  variant="contained"
                  style={{
                    marginTop: '12px',
                    background: this.getAppBarStyle().background,
                    color: 'white'
                  }}
                  fullWidth
                  onClick={() =>
                    this.setState({
                      isSaveDialogOpen: true
                    })
                  }
                >
                  {TEXT.detailSearch.saveSearch} <i className="material-icons">save</i>
                </Button>
              </div>
              <div className="col-3">
                <Button
                  variant="contained"
                  style={{
                    marginTop: '12px',
                    background: this.getAppBarStyle().background,
                    color: 'white'
                  }}
                  fullWidth
                  onClick={() => {
                    this.setState({
                      isLoadDialogOpen: true
                    });
                  }}
                >
                  {TEXT.detailSearch.loadSearch} <i className="material-icons">open_in_browser</i>
                </Button>
              </div>
            </div>
            {this.props.onExport && this.renderExportButton()}
          </div>
          {this.renderSaveDialog()}
          {this.renderLoadDialog()}
        </div>
      </Dialog>
    );
  }
}

DetailSearch.defaultProps = {
  isDrawerOpen: false,
  color: 'inherit',
  onExport: undefined
};

DetailSearch.propTypes = {
  mainSearchFieldPlaceholderText: PropTypes.string.isRequired,
  appBarTitle: PropTypes.string.isRequired,
  isDrawerOpen: PropTypes.bool,
  handleDrawerClose: PropTypes.func.isRequired,
  dropDownOptionDataList: PropTypes.array.isRequired,
  onSearch: PropTypes.func.isRequired,
  onExport: PropTypes.func,
  color: PropTypes.string,
  handleSnackbarOpen: PropTypes.func.isRequired,
  detailSearchType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

export default withUser(withRouter(DetailSearch));
