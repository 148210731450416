/**
 * Sort the potential candidates
 *
 * sorted according to rating and names
 * @param {PotentialCandidateState[]} projectApplications
 * @return {PotentialCandidateState[]}
 */
export default function(projectApplications) {
  return projectApplications.sort((a, b) => {
    const sortingParam = 'rating';
    if (!a[sortingParam] && b[sortingParam]) return -1;
    if (a[sortingParam] && !b[sortingParam]) return 1;
    if (a[sortingParam] < b[sortingParam]) return -1;
    if (a[sortingParam] > b[sortingParam]) return 1;
    if (a.candidate && b.candidate) {
      return (
        Intl.Collator().compare(a.candidate.lastName, b.candidate.lastName) ||
        Intl.Collator().compare(a.candidate.firstName, b.candidate.firstName)
      );
    }
    return 1;
  });
}
