import React, { useMemo } from 'react';
import { Laptop } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import accountStatus from '../general/staticContent/accountStatus';
import TEXT from '../text';

const CounterPartAccountStatus = ({ status, onClick }) => {
  const style = useMemo(() => {
    switch (status) {
      case accountStatus.NO_ACCOUNT:
        return { color: '#4b4b4b' };
      case accountStatus.ACTIVE:
        return { color: '#3fa447' };
      case accountStatus.INVITED:
      case accountStatus.REINVITED:
      case accountStatus.INITIAL_LOGIN:
      case accountStatus.PASSWORD_SET:
        return { color: '#cc8731' };
      case accountStatus.ACCOUNT_LOCKED:
      case accountStatus.DELETED:
        return { color: '#e73a3a' };
      default:
        return undefined;
    }
  }, [status]);

  return (
    <Tooltip title={TEXT.companyArchive.counterPart.accountTooltip}>
      <IconButton color="primary" onClick={onClick}>
        <Laptop style={style} />
      </IconButton>
    </Tooltip>
  );
};

CounterPartAccountStatus.defaultProps = {
  onClick: undefined
};

CounterPartAccountStatus.propTypes = {
  status: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default CounterPartAccountStatus;
