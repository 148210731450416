// Important: no !important in css
const theme = {
  matchflix: {
    name: 'Match-flix',
    login: {
      typography: {
        useNextVariants: true
      },
      palette: {
        type: 'dark',
        primary: {
          main: '#0079b0',
          light: '#74c3e6',
          contrastText: '#b3b3b3'
        }
      }
    },
    app: {
      typography: {
        useNextVariants: true
      },
      palette: {
        primary: {
          main: '#39a9dc',
          light: '#74c3e6',
          veryLight: '#74c3e6',
          contrastText: '#fff'
        },
        secondary: { main: '#00a69f' }
      },
      overrides: {
        MuiTooltip: {
          // Name of the rule
          tooltip: {
            // Some CSS

            'font-size': '16px'
          }
        }
      },
      status: {
        danger: 'orange'
      }
    },
    logo: '/img/logo_header3.png',
    loginLogo: '/img/mf_logo.png',
    homePageLogoStyle: {
      height: '48px'
    },
    loginBackground: {
      background: '#485258'
    },
    loginPaper: {
      background: '#000'
    },
    appBar: {
      background: '#39a9dc',

      zIndex: '1100',
      candidates: {
        background: '#a7111b'
      },
      companies: {
        background: '#A5ACB0'
      },
      projects: {
        background: '#DEE0E1'
      }
    }
  },
  senator: {
    name: 'Senator-Partners',
    login: {
      typography: {
        useNextVariants: true
      },
      palette: {
        primary: {
          main: '#0079b0',
          light: '#0079b0',
          contrastText: '#666'
        }
      }
    },
    app: {
      typography: {
        useNextVariants: true
      },
      palette: {
        primary: {
          main: '#587daf',
          light: '#89a3c6',
          veryLight: '#bdd2ef',
          contrastText: '#fff'
        },
        secondary: { main: '#00a69f' }
      },
      overrides: {
        MuiTooltip: {
          // Name of the rule
          tooltip: {
            // Some CSS

            'font-size': '16px'
          }
        }
      },
      status: {
        danger: 'orange'
      }
    },
    logo: '/img/senator_logo.png',
    loginLogo: '/img/senator_logo.png',
    homePageLogoStyle: {
      height: '48px'
    },
    loginBackground: {
      background: '#747e86'
    },
    loginPaper: {
      background: '#fff'
    },
    appBar: {
      background: '#fff',
      zIndex: '1100',
      color: '#666',
      candidates: {
        color: '#666',
        background: '#B9C8DC'
      },
      companies: {
        color: '#666',
        background: '#CCDEDC'
      },
      projects: {
        color: '#666',
        background: '#E5E5E0'
      }
    }
  },
  personaltotal: {
    name: 'Personal-Total',
    login: {
      typography: {
        useNextVariants: true
      },
      palette: {
        type: 'dark',
        primary: {
          main: '#962225',
          light: '#fff',
          contrastText: '#fff'
        }
      }
    },
    app: {
      typography: {
        useNextVariants: true
      },
      palette: {
        primary: {
          main: '#962225',
          light: '#b46365',
          veryLight: '#e89698',
          contrastText: '#fff'
        },
        secondary: { main: '#00a69f' }
      },
      overrides: {
        MuiTooltip: {
          // Name of the rule
          tooltip: {
            // Some CSS
            'font-size': '16px'
          }
        }
      },
      status: {
        danger: 'orange'
      }
    },
    logo: '/img/pt_logo.png',
    loginLogo: '/img/pt_logo.png',
    homePageLogoStyle: {
      height: '48px'
    },
    loginBackground: {
      background: '#5f6467'
    },
    appBar: {
      background: '#671314',
      zIndex: '1100',

      candidates: {
        background: '#a7111b'
      },
      companies: {
        background: '#A5ACB0'
      },
      projects: {
        background: '#1a171b'
      }
    },
    loginPaper: {
      background: '#962225'
    }
  },
  lienert: {
    name: 'Jörg Lienert AG',
    login: {
      typography: {
        useNextVariants: true
      },
      palette: {
        type: 'dark',
        primary: {
          main: '#962225',
          light: '#fff',
          contrastText: '#fff'
        }
      }
    },
    app: {
      typography: {
        useNextVariants: true
      },
      palette: {
        primary: {
          main: '#092455',
          light: '#697a97',
          veryLight: '#aec3e8',
          contrastText: '#fff'
        },
        secondary: { main: '#697a97' }
      },
      overrides: {
        MuiTooltip: {
          // Name of the rule
          tooltip: {
            // Some CSS
            'font-size': '16px'
          }
        }
      },
      status: {
        danger: 'orange'
      }
    },
    logo: '/img/JL_Logo_rgb_weiss.svg',
    loginLogo: '/img/JL_Logo_rgb_weiss.svg',
    loginLogoStyle: {
      maxWidth: '90%',
      padding: '10px'
    },
    homePageLogoStyle: {
      height: '35px',
      padding: '2px'
    },
    loginBackground: {
      background: '#dcdcdc'
    },
    appBar: {
      zIndex: '1100',

      background: '#092455',
      candidates: {
        background: '#092455'
      },
      companies: {
        background: '#092455'
      },
      projects: {
        background: '#092455'
      }
    },
    loginPaper: {
      background: '#092455'
    }
  },
  interim: {
    name: 'Interim Management Partners',
    login: {
      typography: {
        useNextVariants: true
      },
      palette: {
        primary: {
          main: '#0079b0',
          light: '#0079b0',
          contrastText: '#666'
        }
      }
    },
    app: {
      typography: {
        useNextVariants: true
      },
      palette: {
        primary: {
          main: '#587daf',
          light: '#89a3c6',
          veryLight: '#bdd2ef',
          contrastText: '#fff'
        },
        secondary: { main: '#00a69f' }
      },
      overrides: {
        MuiTooltip: {
          // Name of the rule
          tooltip: {
            // Some CSS

            'font-size': '16px'
          }
        }
      },
      status: {
        danger: 'orange'
      }
    },
    logo: '/img/interim_logo_60.png',
    loginLogo: '/img/interim_logo_60.png',
    homePageLogoStyle: {
      height: '48px'
    },
    loginBackground: {
      background: '#747e86'
    },
    loginPaper: {
      background: '#fff'
    },
    appBar: {
      background: '#fff',
      zIndex: '1100',
      color: '#666',
      candidates: {
        color: '#666',
        background: '#B9C8DC'
      },
      companies: {
        color: '#666',
        background: '#CCDEDC'
      },
      projects: {
        color: '#666',
        background: '#E5E5E0'
      }
    }
  }
};
module.exports = theme;
