import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import MatchPercentageFilter from './mainFilters/MatchPercentageFilter';
import AgeFilter from './mainFilters/AgeFilter';
import WillingnessToChangeFilter from './mainFilters/WillingnessToChangeFilter';
import ShowMarked from './mainFilters/ShowMarked';
// import SalaryFilter from "./mainFilters/SalaryFilter";
// import WillingnessToRelocate from './mainFilters/WillingnessToRelocate';
import PostalCodeFilter from './mainFilters/PostalCodeFilter';
import NameFilter from './mainFilters/NameFilter';
import CompanyNameFilter from './mainFilters/CompanyNameFilter';
import withUser from '../../utils/withUser';

import './FilterStyles.css';

class MainFilterComponent extends PureComponent {
  render() {
    return (
      <div className="container-fluid filterContainer mainFilter text-center">
        {['admin', 'employee'].includes(this.props.user.role) ? (
          <div className="row">
            <NameFilter onFilterChange={this.props.onFilterChange} name={this.props.name} />
          </div>
        ) : (
          ''
        )}
        {['admin', 'employee'].includes(this.props.user.role) ? (
          <div className="row">
            <CompanyNameFilter onFilterChange={this.props.onFilterChange} companyName={this.props.companyName} />
          </div>
        ) : (
          ''
        )}

        <div className="row">
          <MatchPercentageFilter
            onFilterChange={this.props.onFilterChange}
            matchPercentage={this.props.matchPercentage.parameters}
            wasLoaded={this.props.matchPercentage.wasLoaded}
          />
        </div>

        <div className="row paddingContainer">
          <AgeFilter onFilterChange={this.props.onFilterChange} range={this.props.age.parameters} wasLoaded={this.props.age.wasLoaded} />
        </div>
        <div className="row paddingContainer">
          <WillingnessToChangeFilter
            onFilterChange={this.props.onFilterChange}
            range={this.props.willingnessToChange.parameters}
            wasLoaded={this.props.willingnessToChange.wasLoaded}
          />
        </div>
        {/* Deactivated, will probably be used in future releases <div className="row">
					<SalaryFilter
						onFilterChange={this.props.onFilterChange}
						range={this.props.salary}
					/>
				</div> */}
        {!this.props.disableDistanceFiltering && (
          <div className="row paddingContainer">
            <PostalCodeFilter onFilterChange={this.props.onFilterChange} range={this.props.distance} />
          </div>
        )}
        {/* Deactivated, will probably be used in future releases <div className="row">
          <WillingnessToRelocate
            onFilterChange={this.props.onFilterChange}
            willingnessToRelocate={this.props.willingnessToRelocate}
          />
        </div> */}
        <div className="row">
          <ShowMarked onFilterChange={this.props.onFilterChange} showMarked={this.props.showMarked} />
        </div>
      </div>
    );
  }
}
MainFilterComponent.defaultProps = {};
MainFilterComponent.propTypes = {
  user: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  matchPercentage: PropTypes.object.isRequired,
  age: PropTypes.object.isRequired,
  willingnessToChange: PropTypes.object.isRequired,
  // salary: PropTypes.arrayOf(PropTypes.number).isRequired,
  // willingnessToRelocate: PropTypes.string.isRequired,
  distance: PropTypes.arrayOf(PropTypes.number).isRequired,
  disableDistanceFiltering: PropTypes.bool.isRequired,
  showMarked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired
};
export default withUser(MainFilterComponent);
