import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { IconButton, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Check as CheckIcon, Delete as DeleteIcon, Edit as EditIcon } from '@material-ui/icons';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import ConfirmationModal from '../generic_components/ConfirmationModal';
import { removeCandidateFromProject } from './api/candidateArchiveAPI';

import withUser from '../utils/withUser';
import * as UTILS from '../utils/utilFunctions';
import TEXT_GENERAL from '../text';

class CandidateStatus extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      columns: this.getColumns(),
      showDeleteConfirmationPopUp: false,
      potentialCandidateIdDeletionRequest: '',
      projectIdForPotentialCandidateDeletionRequest: ''
    };
  }

  getColumns() {
    return [
      {
        name: 'ID',
        options: {
          display: 'excluded',
          filter: false
        }
      },
      {
        name: 'Auftragsnummer',
        options: {
          display: 'true',
          filter: false,
          customBodyRender: (value, metaData) => (
            <Link to={`projects?id=${metaData.rowData[0]}&cid=${this.props.selectedCandidateId}&t=${2}`}>
              {value}
            </Link>
          )
        }
      },
      {
        name: 'Unternehmen',
        options: {
          display: 'true',
          filter: false
        }
      },
      {
        name: 'Position',
        options: {
          display: 'true',
          filter: false
        }
      },
      {
        name: 'Standort',
        options: {
          display: 'true',
          filter: false
        }
      },
      {
        name: 'Datum (zum Auftrag hinzugefügt)',
        options: {
          display: 'true',
          filter: false,
          sortDirection: 'desc',
          customBodyRender: value => UTILS.formatStringToDELocale(value)
        }
      },
      {
        name: 'Abgesagt',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => value && <CheckIcon style={{ color: 'Red' }} />
        }
      },
      {
        name: 'Interview am',
        options: {
          display: 'true',
          filter: false,
          sortDirection: 'desc',
          customBodyRender: value => UTILS.formatStringToDELocale(value)
        }
      },
      {
        name: 'Bewerbung hin am',
        options: {
          display: 'true',
          filter: false,
          sortDirection: 'desc',
          customBodyRender: value => UTILS.formatStringToDELocale(value)
        }
      },
      {
        name: 'Vorstellungstermin ',
        options: {
          display: 'true',
          filter: false,
          sortDirection: 'desc',
          customBodyRender: value => UTILS.formatStringToDELocale(value)
        }
      },
      {
        name: 'Vermittelt ',
        options: {
          display: 'true',
          filter: false
        }
      },
      {
        name: 'Eintrittsdatum  ',
        options: {
          display: 'true',
          filter: false,
          sortDirection: 'desc',
          customBodyRender: value => UTILS.formatStringToDELocale(value)
        }
      },
      {
        name: 'Bemerkung',
        options: {
          display: 'true',
          filter: false
        }
      },
      {
        name: 'Aktionen',
        options: {
          display: 'true',
          filter: false
        }
      }
    ];
  }

  handleRemoveEntryAPICall = () => {
    const { potentialCandidateIdDeletionRequest: candidateId, projectIdForPotentialCandidateDeletionRequest: projectId } = this.state;
    removeCandidateFromProject(projectId, candidateId)
      .then(() => {
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.deleteSuccess);
        const oldPotentialCandidates = this.props.data;
        const indexOfUpdatedPotentialCandidate = oldPotentialCandidates.findIndex(project => project.potentialCandidateId === candidateId);
        const newPotentialCandidates = oldPotentialCandidates;
        newPotentialCandidates[indexOfUpdatedPotentialCandidate] = {
          ...newPotentialCandidates[indexOfUpdatedPotentialCandidate],
          proposed: false
        };
        this.props.liftUpUpdatedPotentialCandidates(newPotentialCandidates);

        this.handleCloseConfirmationPopUp();
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleCloseConfirmationPopUp = () => {
    this.setState({
      showDeleteConfirmationPopUp: false,
      potentialCandidateIdDeletionRequest: ''
    });
  };

  renderDeleteConfirmationPopUp() {
    return (
      this.state.showDeleteConfirmationPopUp && (
        <ConfirmationModal
          key="confirmation-entry-deletion"
          handleClose={this.handleCloseConfirmationPopUp}
          open={this.state.showDeleteConfirmationPopUp}
          buttonPrimaryAction={this.handleRemoveEntryAPICall}
          headlineText="Kandidat aus Auftrag entfernen?"
          descriptionText="Soll der Kandidat aus dem entsprechenden Auftrag entfernt werden und auftragspezifische Daten des Kandidatens gelöscht werden?"
        />
      )
    );
  }

  renderActionButtons(projectId, potentialCandidateId, isEditable) {
    return (
      <>
        <Tooltip title="Eintrag ändern" placement="top">
          <span>
            <IconButton
              className=""
              disabled={!isEditable || !this.props.openEntryEdit}
              aria-label="Edit"
              color="primary"
              onClick={() => this.props.openEntryEdit(projectId)}
            >
              <EditIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Kandidat aus Auftrag entfernen" placement="top">
          <span>
            <IconButton
              className=""
              disabled={!isEditable}
              aria-label="Delete"
              color="primary"
              onClick={() => {
                this.setState({
                  showDeleteConfirmationPopUp: true,
                  potentialCandidateIdDeletionRequest: potentialCandidateId,
                  projectIdForPotentialCandidateDeletionRequest: projectId
                });
              }}
            >
              <DeleteIcon />
            </IconButton>
          </span>
        </Tooltip>
      </>
    );
  }

  prepareDataForTable(data) {
    return data
      ? data
          .filter(entry => entry.proposed === true)
          .map(entry => [
            entry.id,
            entry.projectNumber,
            entry.companyName,
            entry.jobTitle1,
            entry.partner,
            entry.addedDate,
            entry.rejectionDate,
            entry.firstInterviewDate,
            entry.applicationSendDate,
            entry.secondInterviewDate,
            entry.isReferred ? 'ja' : 'nein',
            entry.signedDate,
            entry.remark,
            this.renderActionButtons(entry.id, entry.potentialCandidateId, entry.isEditable)
          ])
      : [];
  }

  render() {
    const parsedData = this.prepareDataForTable(this.props.data);
    const isDataAvailable = this.props.data && this.props.data.length > 0 && parsedData.length > 0;
    return (
      <div className="container-fluid">
        {this.renderDeleteConfirmationPopUp()}
        <div className="CandidateStatus row">
          <div className="col-12">
            {isDataAvailable ? (
              <SafeMuiDatatable title="Vorgeschlagen auf" tableName="candidateStatus" data={parsedData} columns={this.state.columns} />
            ) : (
              <div className="sideBarWrapper p-2">
                <div className="candidateArchive-emptyPage">Der Kandidat wurde für keinen Auftrag vorgeschlagen</div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CandidateStatus.defaultProps = {
  openEntryEdit: undefined
};

CandidateStatus.propTypes = {
  openEntryEdit: PropTypes.func,
  selectedCandidateId: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpUpdatedPotentialCandidates: PropTypes.func.isRequired
};
export default withUser(CandidateStatus);
