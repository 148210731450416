import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import * as UTILS from '../utils/utilFunctions';
// import HelperFunctions from './utils/helperFunctions';
// TODO direct copy of companyResult
import * as CONSTANTS from '../constants';
import HELPERFUNCTION from './utils/helperFunctions';

const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

const columns = [
  {
    name: 'ID',
    options: {
      display: 'exclude'
    }
  },
  {
    name: 'Status',
    options: {
      customBodyRender: status => {
        if (status === 'verfügbar') {
          return (
            <div>
              <i className="material-icons" style={{ color: 'green' }}>
                check_circle
              </i>
            </div>
          );
        }
        return (
          <div>
            <i className="material-icons " style={{ color: 'red' }}>
              remove_circle
            </i>
          </div>
        );
      }
    }
  },
  { name: 'Anrede' },
  { name: 'Titel' },
  { name: 'Vorname' },
  { name: 'Nachname' },
  { name: 'E-Mail' },
  { name: 'Berufsbezeichnung' },
  { name: 'Unternehmen' },
  { name: 'PLZ' },
  { name: 'Stadt' },
  {
    name: 'Geburtsdatum',
    options: {
      customBodyRender: value => (value ? UTILS.formatDateToDELocale(new Date(value)) : ''),
      customDownloadRender: value => (value ? UTILS.formatDateToDELocale(new Date(value)) : '')
    }
  },
  {
    name: 'Zuletzt aktualisiert',
    options: {
      customBodyRender: value => (value ? UTILS.formatDateToDELocale(new Date(value)) : ''),
      customDownloadRender: value => (value ? UTILS.formatDateToDELocale(new Date(value)) : '')
    }
  },
  {
    name: 'Zuletzt kontaktiert',
    options: {
      customBodyRender: value => (value ? UTILS.formatDateToDELocale(new Date(value)) : ''),
      customDownloadRender: value => (value ? UTILS.formatDateToDELocale(new Date(value)) : '')
    }
  }
];

const muiTheme = {
  palette: THEME.app.palette,
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        '&:nth-child(1)': {
          //    width: '70px'
        },
        '&:hover': {
          cursor: 'pointer'
        }
      }
    },
    MUIDataTableBodyCell: {
      root: {
        '&:hover': {
          cursor: 'pointer'
        }
      }
    }
  }
};

class CandidateResults extends PureComponent {
  prepareDataForTable(data) {
    console.log('DATA', data);
    const formatedData = [];
    if (data) {
      data.forEach(entry => {
        formatedData.push([
          entry.id,
          HELPERFUNCTION.verifyCandidateStatus({
            statusType: entry.statusType,
            statusExpirationDate: entry.statusExpirationDate
          }).statusType === 'available'
            ? 'verfügbar'
            : 'nicht verfügbar',
          entry.salutation,
          entry.title,
          entry.firstName,
          entry.lastName,
          entry.mailAddress,
          entry.currentJob,
          entry.currentCompany,
          entry.postalCode,
          entry.city,
          entry.dateOfBirth,
          entry.lastChange,
          entry.maxContactDate
        ]);
      });
    }
    return formatedData;
  }

  onTableChange = (action, tableState) => {
    const displayedPotentialCandidateIndexes = tableState.displayData.map(entry => entry.dataIndex).slice();
    sessionStorage.setItem('displayedCandidateIndexes', JSON.stringify(displayedPotentialCandidateIndexes));
  };

  renderTitle() {
    return (
      <div>
        <span>Kandidaten </span>

        {this.props.candidateList.length === this.props.maxAmountOfSearchResults && (
          <Tooltip
            title={`Wenn Sie mehr als ${
              this.props.maxAmountOfSearchResults
            } Ergebnisse sehen wollen, verwenden Sie die Detailssuche. Dort können Sie die maximale Anzahl an Treffern einstellen.`}
          >
            <span> - auf {this.props.maxAmountOfSearchResults} Ergebnisse beschränkt</span>
          </Tooltip>
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        <SafeMuiDatatable
          title={this.renderTitle()}
          tableName="candidateResults"
          data={this.prepareDataForTable(this.props.candidateList)}
          columns={columns}
          onRowClick={this.props.handleCandidateSelected}
          muiTheme={muiTheme}
          onTableChange={this.onTableChange}
          showMarklistButton
          markListType="candidate"
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          unformatedData={this.props.candidateList}
        />
      </div>
    );
  }
}
CandidateResults.propTypes = {
  handleCandidateSelected: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  candidateList: PropTypes.array.isRequired,
  maxAmountOfSearchResults: PropTypes.number.isRequired
};
export default CandidateResults;
