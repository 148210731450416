import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import GenericForm from './GenericForm';

class GenericFormPopUp extends PureComponent {
  render() {
    return (
      <Dialog key={this.props.key} open={this.props.open} disableEnforceFocus {...this.props.dialogProps}>
        <DialogTitle>{this.props.headlineText}</DialogTitle>

        <DialogContent>
          <GenericForm {...this.props.genericFormProps} />
        </DialogContent>
      </Dialog>
    );
  }
}
GenericFormPopUp.defaultProps = {
  open: false,
  headlineText: '',
  key: 'defaultKey',
  dialogProps: {},
};

GenericFormPopUp.propTypes = {
  open: PropTypes.bool,
  headlineText: PropTypes.string,
  genericFormProps: PropTypes.object.isRequired,
  key: PropTypes.string,
  dialogProps: PropTypes.object
};

export default GenericFormPopUp;
