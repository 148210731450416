import { Box, Button, Chip, InputLabel, MenuItem, Select } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { Add } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import * as TEXT from '../../text';
import IndustrySectors from '../staticContent/IndustrySectors';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    marginLeft: 0,
    minWidth: 300
  },
  chip: {
    borderRadius: '0'
  }
}));

const JobCategorySelect = ({ industrySectors, setIndustrySectors, required, handleOpenSnackbar }) => {
  const [currentIndustrySektor, setCurrentIndustrySektors] = useState(
    /** @type {Sektor} */ {
      label: undefined,
      id: undefined
    }
  );

  const industrySelectRef = useRef();

  const handleClickAdd = useCallback(() => {
    if (industrySectors.length >= 5 || !currentIndustrySektor.id) {
      handleOpenSnackbar(TEXT.jobPosting.errors.maximum);
      return;
    }
    const alreadyExist = industrySectors.find(industrySektor => industrySektor.id === currentIndustrySektor.id);
    if (alreadyExist) {
      return;
    }
    setIndustrySectors([...industrySectors, currentIndustrySektor]);
    setCurrentIndustrySektors({ label: undefined, id: undefined });
    industrySelectRef.current.clearSektor();
  }, [setIndustrySectors, industrySectors, currentIndustrySektor, handleOpenSnackbar]);

  const handleDelete = useCallback(
    id => {
      const newArray = industrySectors.filter(industrySektor => industrySektor.id !== id);
      setIndustrySectors(newArray);
    },
    [industrySectors, setIndustrySectors]
  );

  const classes = useStyles();

  return (
    <Box>
      <Box className="mt-3" style={{ display: 'flex', gap: 3 }}>
        {industrySectors.map(industrySektor => (
          <Chip
            className={classes.chip}
            onDelete={() => handleDelete(industrySektor.id)}
            label={industrySektor.label}
            key={industrySektor.id}
          />
        ))}
      </Box>
      <Box style={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <IndustrySelect
          ref={industrySelectRef}
          required={required}
          current={currentIndustrySektor}
          setCurrent={setCurrentIndustrySektors}
        />
        <Button onClick={handleClickAdd} variant="contained" startIcon={<Add />}>
          Hinzufügen
        </Button>
      </Box>
    </Box>
  );
};
JobCategorySelect.defaultProps = {
  required: false
};

JobCategorySelect.propTypes = {
  industrySectors: PropTypes.arrayOf(Object).isRequired,
  setIndustrySectors: PropTypes.func.isRequired,
  required: PropTypes.bool,
  handleOpenSnackbar: PropTypes.func.isRequired
};

export default JobCategorySelect;

const IndustrySelect = forwardRef(({ setCurrent, current, ...props }, ref) => {
  const [sektor, setSektor] = useState(/** @type {string} */ '');

  const handleChangeSektor = useCallback(
    event => {
      setSektor(event.target.value);
      setCurrent({ label: undefined, id: undefined });
    },
    [setSektor, setCurrent]
  );

  const handleChangeCategory = useCallback(
    event => {
      const innerText = IndustrySectors[sektor].values.find(obj => obj.id === event.target.value);
      setCurrent({
        label: innerText.label,
        id: event.target.value
      });
    },
    [setCurrent, sektor]
  );

  const clearSektor = useCallback(() => {
    setSektor('');
  }, []);

  useImperativeHandle(ref, () => ({
    clearSektor
  }));

  const classes = useStyles();

  return (
    <>
      <FormControl className={classes.formControl}>
        <InputLabel>Industrie Sektor</InputLabel>
        <Select onChange={handleChangeSektor} value={sektor || ''} {...props}>
          {Object.keys(IndustrySectors).map(industrySektor => (
            <MenuItem key={industrySektor} value={industrySektor}>
              {IndustrySectors[industrySektor].label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {!!sektor && (
        <FormControl className={classes.formControl}>
          <InputLabel>Industrie Kategorie</InputLabel>
          <Select onChange={handleChangeCategory} value={current.id || ''}>
            {IndustrySectors[sektor].values.map(
              /**
               *
               * @param {Sektor} industrySector
               */
              industrySector => (
                <MenuItem key={industrySector.id} value={industrySector.id}>
                  {industrySector.label}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      )}
    </>
  );
});
IndustrySelect.propTypes = {
  setCurrent: PropTypes.func.isRequired,
  current: PropTypes.object.isRequired
};
