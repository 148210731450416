import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import TextField from '@material-ui/core/TextField';

import InputAdornment from '@material-ui/core/InputAdornment';
// Icons
import Domain from '@material-ui/icons/Domain';

import IconButton from '@material-ui/core/IconButton'; // for Snackbar
import DrawerWrapper from '../generic_components/DrawerWrapper';
import CompanyFinder from '../generic_components/CompanyFinder';

import TEXT from '../text';

class CompanyReferenceField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  openDrawer = ev => {
    ev.preventDefault();
    ev.stopPropagation();
    this.setState(prevState => ({
      open: !prevState.open
    }));
  };

  render() {
    return (
      <React.Fragment>
        <TextField
          fullWidth
          id={this.props.id}
          onClick={this.openDrawer}
          value={this.props.value.name}
          label={TEXT.adminPage.createUserPopup.inputFields.companyReference}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={this.openDrawer}>
                  <Domain />
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <DrawerWrapper open={this.state.open} onClose={() => this.setState({ open: false })} title="User mit Unternehmen verknüpfen">
          <CompanyFinder
            handleProjectSelected={selectedCompany => {
              this.setState({ open: false });
              this.props.onChange(
                {
                  name: selectedCompany[2],
                  id: selectedCompany[0]
                },
                this.props.id
              );
            }}
            helperText={TEXT.companyArchive.searchFieldErrorMessage}
            confirmationDescription="Den User mit folgendem Unternehmen verknüpfen?"
          />
        </DrawerWrapper>
      </React.Fragment>
    );
  }
}
CompanyReferenceField.defaultProps = {
  id: 'company'
};
CompanyReferenceField.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string
};
export default CompanyReferenceField;
