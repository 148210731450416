import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import TEXT from '../text';

class DistanceSlider extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onAfterChange = this.onAfterChange.bind(this);
  }

  onChange(distance) {
    this.props.liftUpStateToApp({ distance });
  }

  onAfterChange(distance) {
    this.props.onSliderChange(distance);
  }

  render() {
    const capDistanceSymbol = 'unendlich';
    const subCapDistanceSymbol = `${this.props.distance} km`;
    const headline = `${TEXT.filter.mainFilter.postalCode} (${
      this.props.distance > 240 || this.props.distance === 0 ? capDistanceSymbol : subCapDistanceSymbol
    }):`;
    return (
      <div className=" pb-3">
        <p className="pt-3">{headline}</p>
        <Slider
          value={this.props.distance}
          min={0}
          max={250}
          marks={{
            // 0: '0',
            0: '∞',
            50: '50km',
            100: '100km',
            150: '150km',
            200: '200km',
            250: '∞'
          }}
          onChange={this.onChange}
          onAfterChange={this.onAfterChange}
        />
      </div>
    );
  }
}

DistanceSlider.defaultProps = {};
DistanceSlider.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired,
  onSliderChange: PropTypes.func.isRequired,
  distance: PropTypes.number.isRequired
};

export default DistanceSlider;
