import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import GenericFormPopUp from '../generic_components/GenericFormPopUp';
import { post } from '../utils/apiUtils';
import * as CONSTANTS from '../constants';

const FORM_FIELDS = {
  formFields: {
    partnerName: {
      value: '',
      label: 'Partner Name',
      required: true
    },
    city: {
      value: '',
      label: 'Stadt',
      required: true
    },
    partnerId: {
      value: 0,
      label: 'Partner ID',
      required: true,
      additionalProps: {
        type: 'number',
        inputProps: { min: '0', step: '1' },
        onInput: e => {
          e.target.value = Math.max(0, parseInt(e.target.value, 10))
            .toString()
            .slice(0, 12);
        }
      }
    },
    initStepstoneQuota: {
      value: '',
      label: 'Stepstone Contingent',
      additionalProps: {
        type: 'number',
        inputProps: { min: '0', step: '1' },
        onInput: e => {
          e.target.value = Math.max(0, parseInt(e.target.value, 10))
            .toString()
            .slice(0, 12);
        }
      }
    }
  }
};

const CreatePartnerPopUp = ({ open, onSuccess, onError, onClose }) => {
  const handleOnSubmit = useCallback(
    values => {
      post(CONSTANTS.createPartnerURL, { partner: values })
        .then(onSuccess)
        .catch(onError)
        .finally(onClose);
    },
    [onClose, onError, onSuccess]
  );

  return (
    <GenericFormPopUp
      open={open}
      headlineText="Partner erstellen"
      genericFormProps={{
        formFields: FORM_FIELDS.formFields,
        handleSubmit: handleOnSubmit,
        handleClose: onClose
      }}
    />
  );
};

CreatePartnerPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  onClose: PropTypes.func
};

CreatePartnerPopUp.defaultProps = {
  onSuccess: () => {},
  onError: () => {},
  onClose: () => {}
};

export default CreatePartnerPopUp;
