import { Box, CircularProgress, Fab, IconButton, Tooltip, Typography } from '@material-ui/core';
import { Close, Done, Edit, Lock, LockOpen } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonGroup } from 'react-bootstrap';
import * as CONSTANTS from '../constants';
import GenericFormPopUp from '../generic_components/GenericFormPopUp';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import { post } from '../utils/apiUtils';
import CreatePartnerPopUp from './CreatePartnerPopUp';

const prepareData = values => {
  const result = [];
  if (Array.isArray(values)) {
    values.forEach(value => {
      result.push([
        value._id,
        value.partnerId,
        value.partnerName,
        value.city,
        value.status,
        value.initStepstoneQuota || 0,
        value.stepstoneQuota || 0
      ]);
    });
  }
  return result;
};

const PartnerTable = ({ handleSnackbarOpen }) => {
  const [data, setData] = useState([[]]);
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [openCreate, setOpenCreate] = useState(false);

  const handleOnClickEdit = useCallback(
    value => () => {
      const partner = {
        id: value[0],
        partnerId: value[1],
        partnerName: value[2],
        city: value[3],
        status: value[4],
        initStepstoneQuota: value[5],
        stepstoneQuota: value[6]
      };
      setSelected(partner);
    },
    []
  );

  const formData = useMemo(
    () => ({
      formFields: {
        partnerName: {
          value: selected ? selected.partnerName : undefined,
          label: 'Partner Name'
        },
        city: {
          value: selected ? selected.city : undefined,
          label: 'Stadt'
        },
        initStepstoneQuota: {
          value: selected ? selected.initStepstoneQuota : undefined,
          label: 'Stepstone Kontingent',
          additionalProps: {
            type: 'number',
            inputProps: { min: '0', step: '1' },
            onInput: e => {
              e.target.value = Math.max(0, parseInt(e.target.value, 10))
                .toString()
                .slice(0, 12);
            }
          }
        },
        stepstoneQuota: {
          value: selected ? selected.stepstoneQuota : undefined,
          label: 'Aktuelles Stepstone Kontingent',
          additionalProps: {
            disabled: true,
            inputProps: { min: '0', step: '1' }
          }
        }
      }
    }),
    [selected]
  );

  const getPartnersByBrand = useCallback(() => {
    setIsLoading(true);
    post(CONSTANTS.getPartnersByBrandURL, { company: CONSTANTS.partner }).then(res => {
      setData(prepareData(res.partners));
      setIsLoading(false);
    });
  }, []);
  const handleSubmit = useCallback(
    changedValues => {
      if (!selected) {
        return;
      }
      post(CONSTANTS.updatePartnerURL, { id: selected.id, partner: changedValues })
        .then(() => {
          handleSnackbarOpen('Änderungen gespeichert!');
        })
        .catch(e => {
          handleSnackbarOpen('Konnte partner nicht aktualisieren!');
          console.warn(e);
        })
        .finally(() => {
          setSelected(null);
          getPartnersByBrand();
        });
    },
    [selected, handleSnackbarOpen, getPartnersByBrand]
  );

  const handleDeactivatePartner = useCallback(
    id => () => {
      if (!id) {
        return;
      }

      post(CONSTANTS.deletePartnerURL, { id })
        .then(() => {
          handleSnackbarOpen('Partner erfolgreich deaktiviert');
        })
        .finally(() => {
          getPartnersByBrand();
        });
    },
    [handleSnackbarOpen, getPartnersByBrand]
  );

  const handleActivatePartner = useCallback(
    id => () => {
      if (!id) {
        return;
      }

      post(CONSTANTS.reactivatePartnerURL, { id })
        .then(() => {
          handleSnackbarOpen('Partner erfolgreich aktiviert');
        })
        .finally(() => {
          getPartnersByBrand();
        });
    },
    [handleSnackbarOpen, getPartnersByBrand]
  );

  const options = useMemo(
    () => ({
      responsive: 'scrollMaxHeight',
      fixedHeader: true,
      selectableRows: 'none',
      rowsPerPageOptions: [5, 10, 20, 30, 50]
    }),
    []
  );

  const handleOpenCreateDialog = useCallback(() => {
    setOpenCreate(true);
  }, []);

  const handleCloseCreateDialog = useCallback(() => {
    setOpenCreate(false);
  }, []);

  const handleOnSuccessCreate = useCallback(() => {
    handleSnackbarOpen('Partner erfolgreich erstellt!');
    getPartnersByBrand();
  }, [getPartnersByBrand, handleSnackbarOpen]);

  const handleOnErrorCreate = useCallback(() => {
    handleSnackbarOpen('Partner konnte nicht erstellt werden!');
  }, [handleSnackbarOpen]);

  const handleClose = useCallback(() => {
    setSelected(null);
  }, []);

  useEffect(() => {
    getPartnersByBrand();
  }, [getPartnersByBrand]);

  const COLUMNS = useMemo(
    () => [
      {
        name: 'id',
        label: 'ID',
        options: {
          display: 'excluded',
          filter: false,
          sort: false
        }
      },
      {
        name: 'partnerId',
        label: 'Partner ID',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'partnerName',
        label: 'Partner Name',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'city',
        label: 'Stadt',
        options: {
          filter: true,
          sort: true
        }
      },
      {
        name: 'status',
        label: 'Aktiv',
        options: {
          filter: true,
          sort: true,
          customBodyRender: value => {
            switch (value) {
              case 'active':
                return (
                  <Tooltip title="Aktiv">
                    <Done size="small" color="secondary" />
                  </Tooltip>
                );
              case 'deleted':
                return (
                  <Tooltip title="Inaktiv">
                    <Close size="small" color="error" />
                  </Tooltip>
                );
              default:
                return value;
            }
          }
        }
      },
      {
        name: 'stepstoneQuota',
        label: 'Vergebenes Kontingent',
        options: {
          filter: true,
          sort: true,
          display: true
        }
      },
      {
        name: 'stepstoneQuota',
        label: 'Verfügbares Kontingent',
        options: {
          filter: true,
          sort: true,
          display: true
        }
      },
      {
        name: 'actions',
        label: 'Aktionen',
        options: {
          filter: false,
          sort: false,
          customBodyRender: (value, tableMeta) => (
            <>
              {tableMeta.rowData && tableMeta.rowData[4] === 'active' && (
                <ButtonGroup>
                  <Tooltip title="Deaktivieren">
                    <IconButton onClick={handleDeactivatePartner(tableMeta.rowData[0])} size="small">
                      <Lock color="error" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Bearbeiten">
                    <IconButton size="small" onClick={handleOnClickEdit(tableMeta.rowData)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </ButtonGroup>
              )}
              {tableMeta.rowData && tableMeta.rowData[4] === 'deleted' && (
                <Tooltip title="Aktivieren">
                  <IconButton onClick={handleActivatePartner(tableMeta.rowData[0])} size="small">
                    <LockOpen color="secondary" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )
        }
      }
    ],
    [handleOnClickEdit, handleDeactivatePartner, handleActivatePartner]
  );

  return (
    <>
      <div>
        <div style={{ width: '100%' }}>
          <SafeMuiDatatable
            title={
              <Box display="flex">
                <Typography variant="h6">Standortverwaltung</Typography>
                {isLoading && <CircularProgress size={24} style={{ marginLeft: 15, position: 'relative', top: 4 }} />}
              </Box>
            }
            columns={COLUMNS}
            options={options}
            data={data}
          />
        </div>
      </div>
      <CreatePartnerPopUp
        open={openCreate}
        onClose={handleCloseCreateDialog}
        onSuccess={handleOnSuccessCreate}
        onError={handleOnErrorCreate}
      />
      <GenericFormPopUp
        open={!!selected}
        headlineText="Standort ändern"
        genericFormProps={{
          handleClose,
          handleSubmit,
          formFields: formData.formFields,
          isUpdate: true
        }}
      />
      <Fab color="primary" aria-label="Add" style={{ position: 'fixed', bottom: '30px', right: '30px' }} onClick={handleOpenCreateDialog}>
        <AddIcon />
      </Fab>
    </>
  );
};

PartnerTable.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default PartnerTable;
