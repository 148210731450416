import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import GenericFormPopUp from '../../generic_components/GenericFormPopUp';
// import SearchProjectDrawer from './SearchProjectDrawer';

import * as CONSTANTS from '../../constants';
import * as CONFIG from '../../config';
import * as UTILS from '../../utils/utilFunctions';
import TEXT_GENERAL from '../../text';

// import TEXT from './staticContent/text';
// import { staticResources } from './staticContent/ProjectDetailsCreatePopUpDropDowns';
function prepareEmployeesKeyValuePairs(employees) {
  const mappedDropDownEntries = [];
  if (employees && employees.length > 0) {
    employees.forEach(employee => {
      if (employee.lastname) {
        const value = employee.id;
        const label = `${employee.firstname || ''} ${employee.lastname}`;
        const mappedEmployee = { value, label };
        mappedDropDownEntries.push(mappedEmployee);
      }
    });
  }
  return mappedDropDownEntries;
}
function prepareProjectsKeyValuePairs(projects) {
  const mappedDropDownEntries = [];
  if (projects && projects.length > 0) {
    projects.forEach(project => {
      if (project.id) {
        const value = project.id;
        const label = project.projectNumber;
        const mappedProject = { value, label };
        // prevent duplicates - only push to mapped array if entry doesn't exist yet
        if (!mappedDropDownEntries.map(entry => entry.value).includes(project.id)) mappedDropDownEntries.push(mappedProject);
      }
    });
  }
  return mappedDropDownEntries;
}

class CandidatePastContactCreatePopUpContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.form = this.generateFormFields();
    this.state = {
      // showProjectSearchPopUp: false
      // projectId: '',
      // projectNumber: ''
    };
    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  updateAPICall = updatedData => {
    const subSchemaData = updatedData;
    // if (updatedData.project) subSchemaData.project = this.state.projectId;
    const payload = {
      candidateId: this.props.candidateId,
      subSchemaName: 'pastContacts',
      subSchemaData
    };
    if (Object.keys(updatedData).length > 0) {
      const tokenFromLocalStorage = window.sessionStorage.getItem('token');
      const url = CONSTANTS.addCandidateSubSchemaEntryURL;
      console.log(`API ${url} request`, payload);
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(payload),
        timeout: CONFIG.ajaxTimeout
      })
        .done((responseBody, status) => {
          console.log(`API /${url} response`, responseBody, status);
          this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.createSuccess);
          this.props.refreshCandidate();
          this.props.handleClose();
        })
        .fail(err => {
          this.errorHandling(err, url);
        });
    } else {
      this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.noUpdate);
    }
  };

  // openProjectSearchPopUp = () => {
  //   console.log('DEBUG success');
  //   this.setState({ showProjectSearchPopUp: true });
  // };

  // saveProjectToState = row => {
  //   console.log('DEBUG success 2', row);
  //   const selectedProjectId = row[0];
  //   const selectedProjectNumber = row[6];
  //   this.setState({
  //     showProjectSearchPopUp: false,
  //     projectId: selectedProjectId,
  //     projectNumber: selectedProjectNumber
  //   });
  // };

  generateFormFields(projectNumber) {
    console.log('debug success 3', projectNumber);
    // console.log('DEBUG contactDate', this.props.data.contactDate);
    const currentDate = UTILS.getCurrentDateFormattedForDateField();
    const forms = {
      formFields: {
        contactDate: {
          value: currentDate,
          label: 'Datum des Kontakts',
          type: 'date',
          required: true
        },
        intermediary: {
          value: this.props.userId,
          label: 'Durch',
          // customClass: 'col-12 mb-2',
          type: 'dropDown',
          content: prepareEmployeesKeyValuePairs(this.props.employees),
          required: true
        },
        contactType: {
          value: this.props.isPastContactDefaultPhoneCall ? 'telefonisch' : '',
          label: 'Art des Kontakts',
          required: true
        },
        project: {
          value: '',
          label: 'Auftrag',
          type: 'dropDown',
          content: prepareProjectsKeyValuePairs(this.props.projects),
          required: false
          // additionalProps: { onFocus: this.openProjectSearchPopUp }
        },
        subject: {
          value: '',
          label: 'Betreff',
          customClass: 'col-12 mb-2'
        },
        comment: {
          value: '',
          label: 'Notiz',
          type: 'editor',
          customClass: 'col-12 mt-5 mb-2',
          additionalProps: {
            init: {
              height: '200'
            }
          }
        }
      }
    };

    return forms;
  }

  // renderSearchProjectDrawer() {
  //   return (
  //     <SearchProjectDrawer
  //       open={this.state.showProjectSearchPopUp}
  //       handleClose={() => {
  //         this.setState({ showProjectSearchPopUp: false });
  //       }}
  //       onRowClick={this.saveProjectToState}
  //     />
  //   );
  // }

  render() {
    const { projectNumber } = this.state;
    this.form = this.generateFormFields(projectNumber);
    console.log('DEBUG this.props.isPastContactDefaultPhoneCall', this.props.isPastContactDefaultPhoneCall);
    return (
      <div>
        {/* {this.state.showProjectSearchPopUp && this.renderSearchProjectDrawer()} */}
        <GenericFormPopUp
          open={this.props.open}
          headlineText="Kontakthistorie"
          key="pastContact"
          genericFormProps={{
            formFields: this.form.formFields,
            handleSubmit: this.updateAPICall,
            // initialData: this.props.data,
            handleClose: this.props.handleClose

            // buttonCancelText: TEXT.projectDetailsUpdatePopUp.buttons.cancel,
            // buttonSaveText: TEXT.projectDetailsCreatePopUp.buttons.save
          }}
        />
      </div>
    );
  }
}
CandidatePastContactCreatePopUpContainer.defaultProps = {
  // isUpdate: false,
  // data: {}
  isPastContactDefaultPhoneCall: false
};

CandidatePastContactCreatePopUpContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,

  // data
  candidateId: PropTypes.string.isRequired,
  // data: PropTypes.object,
  // snackBar
  handleSnackbarOpen: PropTypes.func.isRequired,
  refreshCandidate: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  isPastContactDefaultPhoneCall: PropTypes.bool,
  userId: PropTypes.string.isRequired
};

export default CandidatePastContactCreatePopUpContainer;
