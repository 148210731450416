// import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
// import * as validators from '../utils/validators';
// import TEXT from '../text';

class ValidatedDropDown extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
      errorText: ''
    };
  }

  onChange = e => {
    this.isInputValid(e);
    this.props.onChange(e);
  };

  isInputValid = e => {
    const testValue = e === undefined ? this.props.value : e.target.value;
    let isValid = true;
    let errorText = '';
    if (this.props.required && (!testValue || testValue.length === 0)) {
      errorText = this.props.errorMessage;
      isValid = false;
    }

    this.setState({ isValid, errorText });
    return isValid;
  };

  /**
   * adds this.props.value to dropDown content incase the value is missing in this.props.content and this.props.content is array of strings
   */
  mapContent() {
    if (Array.isArray(this.props.content) && this.props.value && typeof this.props.value === 'string') {
      if (!this.props.content.find(item => item === this.props.value || item.value === this.props.value)) {
        return [...this.props.content, this.props.value];
      }
    }
    return this.props.content;
  }

  render() {
    console.log('ValidatedDropDown', this.props);

    const mappedContent = this.mapContent();
    return (
      <FormControl margin={this.props.margin} error={!this.state.isValid} fullWidth {...this.props.furtherPropsFormControl}>
        {this.props.label && (
          <InputLabel htmlFor={this.props.id} shrink>
            {this.props.required ? `${this.props.label}*` : this.props.label}
          </InputLabel>
        )}
        <Select
          native
          fullWidth
          value={this.props.value}
          label={this.props.label}
          onChange={this.onChange}
          inputProps={{
            name: this.props.id,
            id: this.props.id
          }}
          {...this.props.furtherPropsSelect}
        >
          {this.props.placeHolderText ? <option value="">{this.props.placeHolderText}</option> : <option value="" />}
          {/* handles simple String array dropDownItems and key value pair array dropDownItems */}
          {mappedContent !== undefined &&
            mappedContent.map(dropDownItem =>
              typeof dropDownItem === 'object' ? (
                <option
                  key={dropDownItem.value}
                  value={dropDownItem.value}
                  style={dropDownItem.isEditingMode ? { color: '#f00' } : undefined}
                  disabled={dropDownItem.isEditingMode}
                >
                  {dropDownItem.label}
                </option>
              ) : (
                <option key={dropDownItem} value={dropDownItem}>
                  {dropDownItem}
                </option>
              )
            )}
        </Select>
        {(this.props.errorText || !this.state.isValid) && <FormHelperText>{this.props.errorText || this.state.errorText}</FormHelperText>}
      </FormControl>
    );
  }
}

ValidatedDropDown.defaultProps = {
  errorMessage: 'Bitte treffen Sie eine Auswahl',
  required: false,
  placeHolderText: '',
  margin: 'none',
  furtherPropsFormControl: { fullWidth: true },
  furtherPropsSelect: { fullWidth: true },
  errorText: undefined
};

ValidatedDropDown.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  content: PropTypes.array.isRequired,

  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,

  // textElements
  placeHolderText: PropTypes.string,

  // for validation
  errorMessage: PropTypes.string,
  errorText: PropTypes.string, // force error message
  required: PropTypes.bool,
  margin: PropTypes.string,
  furtherPropsFormControl: PropTypes.object,
  furtherPropsSelect: PropTypes.object
};
export default ValidatedDropDown;
