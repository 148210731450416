import React from 'react';
import PropTypes from 'prop-types';
import loadImage from 'blueimp-load-image';
import ImageCropModal from './ImageCropModal';
import DropContainer from './DropContainer';

// ImageCropDropContainer extends the DropContainer by an image-crop dialog
// It only supports images
const ImageCropDropContainer = ({
  children,
  tooltipText,
  cloudIcon,
  actionDescription,
  onDrop,
  noClick,
  disabled,
  inline,
  dropzoneErrorMessage,
  showLoadingIndicator,
  aspect,
  outputMaxWidth,
  outputMaxHeight,
  minWidth,
  minHeight
}) => {
  const [file, setFile] = React.useState();
  const [fileUrl, setFileUrl] = React.useState();
  const [imageCropErrorMessage, setImageCropErrorMessage] = React.useState();

  const select = React.useCallback(
    newFile => {
      setFile(newFile[0]);
      setImageCropErrorMessage(null);

      loadImage(
        newFile[0],
        img => {
          if (img instanceof HTMLCanvasElement) {
            if (minWidth && img.width < minWidth) {
              setImageCropErrorMessage(`Mindestbreite ist ${minWidth} Pixel`);
              return;
            }

            if (minHeight && img.height < minHeight) {
              setImageCropErrorMessage(`MindestHöhe ${minHeight} Pixel`);
              return;
            }

            img.toBlob(blob => {
              setFileUrl(window.URL.createObjectURL(blob));
            }, 'image/png');
          } else {
            setImageCropErrorMessage('Die gewählte Datei wird nicht unterstützt.');
          }
        },
        { orientation: true, canvas: true }
      );
    },
    [setFile]
  );
  const unselect = React.useCallback(() => {
    setFile(undefined);
    setFileUrl(url => {
      if (url) {
        window.URL.revokeObjectURL(url);
      }
      return undefined;
    });
    if (fileUrl) {
      window.URL.revokeObjectURL(fileUrl);
    }
  }, [fileUrl, setFile, setFileUrl]);

  return (
    <>
      <ImageCropModal
        imageUrl={fileUrl}
        onClose={unselect}
        aspect={aspect}
        outputMaxWidth={outputMaxWidth}
        outputMaxHeight={outputMaxHeight}
        minWidth={minWidth}
        minHeight={minHeight}
        onConfirm={blob => {
          onDrop([new File([blob], file.name)]);
        }}
      />
      <DropContainer
        onDrop={select}
        dropzoneErrorMessage={dropzoneErrorMessage || imageCropErrorMessage || ''}
        showLoadingIndicator={showLoadingIndicator}
        noClick={noClick}
        actionDescription={actionDescription}
        disabled={disabled}
        multiple={false /* multiple makes no sense with image crop dialog */}
        cloudIcon={cloudIcon}
        tooltipText={tooltipText}
        inline={inline}
      >
        {children}
      </DropContainer>
    </>
  );
};
ImageCropDropContainer.defaultProps = {
  dropzoneErrorMessage: '',
  showLoadingIndicator: false,
  noClick: true,
  actionDescription: 'Dateien hier ablegen',
  disabled: false,
  cloudIcon: true,
  tooltipText: "Legen Sie die gewünschte Datei einfach hier via Drag'n'Drop ab",
  inline: false,
  aspect: null,
  outputMaxWidth: null,
  outputMaxHeight: null,
  minWidth: null,
  minHeight: null
};
ImageCropDropContainer.propTypes = {
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.object.isRequired,
  dropzoneErrorMessage: PropTypes.string,
  showLoadingIndicator: PropTypes.bool,
  noClick: PropTypes.bool,
  actionDescription: PropTypes.string,
  disabled: PropTypes.bool,
  cloudIcon: PropTypes.bool,
  tooltipText: PropTypes.string,
  inline: PropTypes.bool,
  aspect: PropTypes.number,
  outputMaxWidth: PropTypes.number,
  outputMaxHeight: PropTypes.number,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number
};
export default ImageCropDropContainer;
