import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import DropContainer from '../../../generic_components/DropContainer';

class AddAttachmentDropContainer extends PureComponent {
  onDrop = acceptedFiles => {
    this.props.addAttachment(acceptedFiles);
  };

  render() {
    return <DropContainer onDrop={this.onDrop} noClick={false} actionDescription="Datei hier ablegen oder klicken." cloudIcon />;
  }
}

AddAttachmentDropContainer.propTypes = {
  addAttachment: PropTypes.func.isRequired
};

export default AddAttachmentDropContainer;
