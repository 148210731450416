const SALARY_DROPDOWN_CONTENT = require('../../general/staticContent/salaryDropDown');
const DESIIRED_RAISE_FORCHANGE_DROPDOWN_CONTENT = require('../../general/staticContent/desiredRaiseForChangeDropDown');
const WILLINGNESS_TO_RELOCATE_DROPDOWN_CONTENT = require('../../general/staticContent/willingnessToRelocateDropdown');
const CHANNEL_FOR_INFORMATION_DROPDOWN_CONTENT = require('../../general/staticContent/channelForInformationDropDown');
const MOTIVATION_FOR_CHANGE_DROPDOWN_CONTENT = require('../../general/staticContent/motivationForChangeDropDown');
const FREQUENCY_OF_INFORMATION_DROPDOWN_CONTENT = require('../../general/staticContent/frequencyOfInformationDropDown');
const COUNTRY_DROPDOWN_CONTENT = require('../../general/staticContent/countryDropDown');
const PART_TIME_EMPLOYMENT_DROPDOWN_CONTENT = require('../../general/staticContent/partTimeEmploymentDropDown');
const PARTNER = require('../../constants').partner;
const INDUSTRY_SECTORS = require('./industrySectors');
const POSITION_DESCRIPTIONS = require('./positionDescriptions');
const { STATUS_TYPES, getAvailableStatusTypes } = require('../utils/statusTypes');

function numberToEuro(value) {
  return new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
  }).format(value);
}

function comparator(a, b) {
  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1;
  }
  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1;
  }
  return 0;
}

export default function generateDropDownValues(partners, employeesList) {
  const partnerObjectList = partners.map(entry => ({
    label: `${entry.partnerName} (${entry.partnerId})`,
    value: entry.id
  }));
  const employees = employeesList.map(entry => ({
    label: `${entry.firstname} ${entry.lastname} (${entry.email})`,
    value: entry.id
  }));
  const countries = COUNTRY_DROPDOWN_CONTENT;
  const dataPrivacyDropDowns = {
    willingnessToRelocate: WILLINGNESS_TO_RELOCATE_DROPDOWN_CONTENT,
    desiredRaiseForChange: DESIIRED_RAISE_FORCHANGE_DROPDOWN_CONTENT,
    desiredSalary: SALARY_DROPDOWN_CONTENT,
    channelForInformation: CHANNEL_FOR_INFORMATION_DROPDOWN_CONTENT,
    motivationForChange: MOTIVATION_FOR_CHANGE_DROPDOWN_CONTENT,
    frequencyOfInformation: FREQUENCY_OF_INFORMATION_DROPDOWN_CONTENT
  };
  const dropDownOptionObjects = [
    {
      fieldId: 'lastName',
      label: 'Nachname',
      fieldType: 'textField'
    },
    {
      fieldId: 'firstName',
      label: 'Vorname',
      fieldType: 'textField'
    },
    {
      fieldId: 'candidateChangeHistory.changes.user',
      label: 'geändert durch',
      fieldType: 'dropDown',
      dropDownValues: employees
    },
    {
      fieldId: 'postalCode',
      label: 'PLZ',
      fieldType: 'textField'
    },
    {
      fieldId: '_id',
      label: 'Kandidatennummer (Kandidaten ID)',
      fieldType: 'textField'
    },
    {
      fieldId: 'origin.originCandidateId',
      label: 'Kandidatennummer (Alt)',
      fieldType: 'textField'
    },
    {
      fieldId: 'mailAddress',
      label: 'Email',
      fieldType: 'textField'
    },
    {
      fieldId: 'dateOfBirth',
      label: 'Geburtsdatum',
      fieldType: 'datePicker'
    },
    {
      fieldId: 'currentJob',
      label: 'Position',
      fieldType: 'textField'
    },
    {
      fieldId: 'currentCompany',
      label: 'Firma (bisheriger Arbeitgeber)',
      fieldType: 'textField'
    },
    {
      fieldId: 'comment',
      label: 'Bemerkung',
      fieldType: 'textField'
    },
    {
      fieldId: 'creationDate',
      label: 'Eingegeben am',
      fieldType: 'datePicker'
    },
    {
      fieldId: 'lastUpdate.date',
      label: 'geändert am',
      fieldType: 'datePicker'
    },
    {
      fieldId: 'pastContacts.contactDate',
      label: 'zuletzt kontaktiert am',
      fieldType: 'datePicker'
    },
    {
      fieldId: 'dataPrivacy.mailSentDate',
      label: 'Datenschutz Mail versendet am',
      fieldType: 'datePicker'
    },
    {
      fieldId: 'dataPrivacy.consentCurrentProject',
      label: 'Einverständnis aktuelles Projekt',
      fieldType: 'dropDown',
      dropDownValues: [{ label: 'Ja', value: true }, { label: 'Nein', value: false }]
    },
    {
      fieldId: 'dataPrivacy.consentAllProjects',
      label: 'Einverständnis zukünftige Projekte',
      fieldType: 'dropDown',
      dropDownValues: [{ label: 'Ja', value: true }, { label: 'Nein', value: false }]
    },
    {
      fieldId: 'dataPrivacy.consentCancellationRight',
      label: 'Widerrufsrecht zur Kenntnis genommen',
      fieldType: 'dropDown',
      dropDownValues: [{ label: 'Ja', value: true }, { label: 'Nein', value: false }]
    },
    {
      fieldId: 'dataPrivacy.newsletter',
      label: 'Newsletter / Marketing',
      fieldType: 'dropDown',
      dropDownValues: [{ label: 'Ja', value: true }, { label: 'Nein', value: false }]
    },
    {
      fieldId: 'willingnessToRelocate',
      label: 'Umzugsbereitschaft',
      fieldType: 'dropDown',
      dropDownValues: dataPrivacyDropDowns.willingnessToRelocate
    },
    {
      fieldId: 'desiredSalary',
      label: 'Jahreszielgehalt',
      fieldType: 'dropDown',
      dropDownValues: dataPrivacyDropDowns.desiredSalary
    },
    {
      fieldId: 'desiredRaiseForChange',
      label: 'Mehr Gehalt in %',
      fieldType: 'dropDown',
      dropDownValues: dataPrivacyDropDowns.desiredRaiseForChange
    },
    {
      fieldId: 'motivationForChange',
      label: 'Motivation für Wechsel',
      fieldType: 'dropDown',
      dropDownValues: dataPrivacyDropDowns.motivationForChange
    },
    {
      fieldId: 'channelForInformation',
      label: 'Karriereangebote zuschicken über',
      fieldType: 'dropDown',
      dropDownValues: dataPrivacyDropDowns.channelForInformation
    },
    {
      fieldId: 'frequencyOfInformation',
      label: 'Häufigkeit der Angebote',
      fieldType: 'dropDown',
      dropDownValues: dataPrivacyDropDowns.frequencyOfInformation
    },
    {
      fieldId: 'origin.partner',
      label: 'Ort der Eingabe',
      fieldType: 'dropDown',
      dropDownValues: partnerObjectList
    },
    {
      fieldId: 'statusType',
      label: 'Status',
      fieldType: 'dropDown',

      dropDownValues: [
        { label: 'verfügbar', value: 'available' },
        ...getAvailableStatusTypes().map(value => ({
          label: STATUS_TYPES[value].title,
          value
        }))
      ]
    },
    {
      fieldId: 'salutation',
      label: 'Anrede',
      fieldType: 'dropDown',
      dropDownValues: [{ label: 'Herr', value: 'Herr' }, { label: 'Frau', value: 'Frau' }]
    },
    {
      fieldId: 'city',
      label: 'Wohnort',
      fieldType: 'textField'
    },
    {
      fieldId: 'partTimeEmployment',
      label: 'Teilzeit',
      fieldType: 'dropDown',
      dropDownValues: PART_TIME_EMPLOYMENT_DROPDOWN_CONTENT
    },
    {
      fieldId: 'country',
      label: 'Land',
      fieldType: 'dropDown',
      dropDownValues: countries
    },
    {
      fieldId: 'dataPrivacy.firstReminderMailSentDate',
      label: 'Datenschutz (Erste) Erinnerung versendet am',
      fieldType: 'datePicker'
    },
    {
      fieldId: 'dataPrivacy.secondReminderMailSentDate',
      label: 'Datenschutz (Zweite) Erinnerung versendet am',
      fieldType: 'datePicker'
    },
    {
      fieldId: 'phoneNumber',
      label: 'Telefonnummer',
      fieldType: 'textField'
    }
  ];
  if (PARTNER === 'lienert') {
    const lienertAdditions = [
      {
        fieldId: 'candidateDB',
        label: 'Kandidaten DB',
        fieldType: 'dropDown',
        dropDownValues: [{ label: 'Ja', value: true }, { label: 'Nein', value: false }]
      },
      {
        fieldId: 'search',
        label: 'Search/Ansprache',
        fieldType: 'dropDown',
        dropDownValues: [{ label: 'Ja', value: true }, { label: 'Nein', value: false }]
      },
      {
        fieldId: 'advisor',
        label: 'Betreuer',
        fieldType: 'dropDown',
        dropDownValues: employees
      }
    ];
    lienertAdditions.forEach(item => {
      dropDownOptionObjects.push(item);
    });
  }

  if (PARTNER === 'matchflix') {
    const matchflixAdditions = [
      {
        fieldId: 'externalProfile',
        label: 'Selbsteingabe',
        fieldType: 'dropDown',
        dropDownValues: [{ label: 'Ja', value: true }, { label: 'Nein', value: false }]
      }
    ];
    matchflixAdditions.forEach(item => {
      dropDownOptionObjects.push(item);
    });
  }
  if (PARTNER === 'interim') {
    const senatorInterimAdditions = [
      {
        fieldId: 'externalProfile.positionCriteria.isPermanentEmployment',
        label: 'Interesse an einer permanenter Festanstellung',
        fieldType: 'dropDown',
        dropDownValues: [{ label: 'Ja', value: true }, { label: 'Nein', value: false }]
      },
      {
        fieldId: 'externalProfile.positionCriteria.branches.category',
        label: 'Branche (Kategorie)',
        fieldType: 'dropDown',
        dropDownValues: Array.from(new Set(INDUSTRY_SECTORS.map(entry => entry.category)))
          .map(entry => ({ label: entry, value: entry }))
          .sort(comparator)
      },
      {
        fieldId: 'externalProfile.positionCriteria.branches.label',
        label: 'Branche (Bezeichnung)',
        fieldType: 'dropDown',
        dropDownValues: INDUSTRY_SECTORS.map(entry => ({
          label: `${entry.label} (${entry.category})`,
          value: entry.label
        })).sort(comparator)
      },
      {
        fieldId: 'externalProfile.positionCriteria.positionDescription.category',
        label: 'Funktionen (Kategorie)',
        fieldType: 'dropDown',
        dropDownValues: Array.from(new Set(POSITION_DESCRIPTIONS.map(entry => entry.category)))
          .map(entry => ({ label: entry, value: entry }))
          .sort(comparator)
      },
      {
        fieldId: 'externalProfile.positionCriteria.positionDescription.label',
        label: 'Funktionen (Bezeichnung)',
        fieldType: 'dropDown',
        dropDownValues: POSITION_DESCRIPTIONS.map(entry => ({
          label: entry.label,
          value: entry.label
        })).sort(comparator)
      },
      {
        fieldId: 'externalProfile.positionCriteria.levels',
        label: 'Hierarchieebene',
        fieldType: 'dropDown',
        dropDownValues: [
          'Ebene 1: Geschäftsführung/Vorstand',
          'Ebene 2: Bereichsleitung/Abteilungsleitung',
          'Ebene 3: Teamleitung',
          'Ebene 4: Projektleitung',
          'Ebene 5: Spezialist'
        ].map(entry => ({ label: entry, value: entry }))
      },
      {
        fieldId: 'maxDailyRate',
        label: 'Maximaler Tagessatz',
        fieldType: 'dropDown',
        dropDownValues: Array.from(Array(61).keys(), (_, num) => {
          const value = num * 50;
          return {
            label: numberToEuro(value),
            value
          };
        }).filter(entry => entry.value !== 0)
      },
      {
        fieldId: 'minDailyRate',
        label: 'Mindestagessatz',
        fieldType: 'dropDown',
        dropDownValues: Array.from(Array(61).keys(), (_, num) => {
          const value = num * 50;
          return {
            label: numberToEuro(value),
            value
          };
        })
          .filter(entry => entry.value !== 0)
          .concat([
            {
              label: `über ${numberToEuro(3000)}`,
              value: 3001
            }
          ])
      },
      {
        fieldId: 'needsValidation',
        label: 'Ist in Kandidaten Eingang',
        fieldType: 'dropDown',
        dropDownValues: [{ label: 'Ja', value: true }, { label: 'Nein', value: false }]
      }
    ];
    senatorInterimAdditions.forEach(item => {
      dropDownOptionObjects.push(item);
    });
  }
  const result = dropDownOptionObjects.sort((a, b) => {
    if (a.label.toLowerCase() < b.label.toLowerCase()) {
      return -1;
    }
    if (a.label.toLowerCase() > b.label.toLowerCase()) {
      return 1;
    }
    return 0;
  });
  return result;
}
