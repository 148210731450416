import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

// material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

class UpdateCompanyStatusPopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputFields: {
        statusType: '', // dropDown
        expirationDate: '' // date field
      }
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    const currentCompanyStatus = this.props.companyStatus;
    const partnerId = currentCompanyStatus.partner ? currentCompanyStatus.partner.id : '';
    this.setState({
      inputFields: {
        statusType: currentCompanyStatus.statusType,
        partner: partnerId,
        expirationDate: currentCompanyStatus.expirationDate && currentCompanyStatus.expirationDate.substring(0, 10)
      }
    });
  }

  updateCompanyStatusAPICall = () => {
    const input = { ...this.state.inputFields };
    const requestBody = {
      companyId: this.props.companyId,
      companyStatus: {
        statusType: input.statusType
      }
    };
    if (input.partner !== 'all' && !!input.partner && ['clientLock', 'acquisitionLock'].includes(input.statusType))
      requestBody.companyStatus.partner = input.partner;

    // expirationDate
    if (['clientLock', 'acquisitionLock'].includes(input.statusType))
      requestBody.companyStatus.expirationDate = new Date(input.expirationDate);
    else requestBody.companyStatus.expirationDate = '';
    const url = CONSTANTS.updateCompanyStatusURL;
    console.log(`API ${url} request`, requestBody);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.props.handleSnackbarOpen(TEXT.companyArchive.updateCompanyStatusPopUp.snackBarText.success);
        this.props.handleClose(true, this.props.companyId);
      })
      .fail(err => {
        const ignoreErrorCodes = [500];
        this.errorHandling(err, url, null, ignoreErrorCodes);
        if (err.status === 500) {
          this.props.handleSnackbarOpen(TEXT.companyArchive.updateCompanyStatusPopUp.snackBarText.failure);
        }
      });
  };

  handleCancelButtonClick = () => {
    this.props.handleClose(false);
  };

  onInputFieldChange = e => {
    let inputFieldValue = e.target.value;
    const inputFieldKey = e.target.id ? e.target.id : e.target.name;
    if (inputFieldKey === 'mailAddress') inputFieldValue = inputFieldValue.toLowerCase().trim();
    this.setState(prevState => ({
      inputFields: {
        ...prevState.inputFields,
        [inputFieldKey]: inputFieldValue
      }
    }));
  };

  getPartnersMenuItems() {
    const partnersMenuItems = [];
    this.props.partners.forEach(partner => {
      partnersMenuItems.push(
        <MenuItem key={partner.id} value={partner.id}>
          {`${partner.partnerName} ID: ${partner.partnerId}`}
        </MenuItem>
      );
    });
    return partnersMenuItems;
  }

  renderExpirationDateField() {
    const inputFieldKey = 'expirationDate';
    return (
      <div className="col-12 mt-2 mb-2">
        <InputLabel htmlFor={inputFieldKey}>{TEXT.companyArchive.updateCompanyStatusPopUp.inputFields[inputFieldKey]}</InputLabel>
        <TextField
          fullWidth
          id={inputFieldKey}
          onChange={this.onInputFieldChange}
          type="date"
          value={this.state.inputFields[inputFieldKey]}
        />
      </div>
    );
  }

  renderPartnersDropDown() {
    const inputFieldKey = 'partner';
    return (
      <div className="col-12 mt-2 mb-2">
        <InputLabel htmlFor={inputFieldKey}>{TEXT.companyArchive.updateCompanyStatusPopUp.inputFields[inputFieldKey]}</InputLabel>
        <Select
          value={this.state.inputFields[inputFieldKey]}
          fullWidth
          onChange={this.onInputFieldChange}
          inputProps={{
            name: inputFieldKey,
            id: inputFieldKey
          }}
        >
          <MenuItem value="">
            <em>{TEXT.companyArchive.updateCompanyStatusPopUp.dropDowns.general.pleaseSelect}</em>
          </MenuItem>
          {this.getPartnersMenuItems()}
        </Select>
      </div>
    );
  }

  renderTypeDropDown() {
    const inputFieldKey = 'statusType';
    return (
      <div className="col-12 mt-2 mb-2">
        {/* statusType of company lock */}
        <InputLabel htmlFor={inputFieldKey}>{TEXT.companyArchive.updateCompanyStatusPopUp.inputFields[inputFieldKey]}</InputLabel>
        <Select
          value={this.state.inputFields[inputFieldKey]}
          fullWidth
          onChange={this.onInputFieldChange}
          inputProps={{
            name: inputFieldKey,
            id: inputFieldKey
          }}
        >
          <MenuItem key="nothing" value="">
            <em>{TEXT.companyArchive.updateCompanyStatusPopUp.dropDowns.general.pleaseSelect}</em>
          </MenuItem>
          {TEXT.companyArchive.updateCompanyStatusPopUp.dropDowns[inputFieldKey].map(dropDowns => (
            <MenuItem key={dropDowns.key} value={dropDowns.key}>
              {dropDowns.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  }

  renderInputFields() {
    return (
      <div className="row">
        {this.renderTypeDropDown()}

        {this.state.inputFields.statusType !== 'available' &&
          ['clientLock', 'acquisitionLock'].includes(this.state.inputFields.statusType) &&
          this.renderPartnersDropDown()}

        {['clientLock', 'acquisitionLock'].includes(this.state.inputFields.statusType) && this.renderExpirationDateField()}
      </div>
    );
  }

  renderPopUp() {
    return (
      <Dialog open={this.props.open} onClose={() => this.props.handleClose(false)}>
        <DialogTitle>{TEXT.companyArchive.updateCompanyStatusPopUp.headline}</DialogTitle>
        <DialogContent>
          <div className="container">{this.renderInputFields()}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancelButtonClick} color="primary">
            {TEXT.companyArchive.updateCompanyStatusPopUp.buttons.cancel}
          </Button>
          <Button onClick={this.updateCompanyStatusAPICall} color="primary">
            {TEXT.companyArchive.updateCompanyStatusPopUp.buttons.save}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    return <div>{this.renderPopUp()}</div>;
  }
}

UpdateCompanyStatusPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  companyStatus: PropTypes.object.isRequired,
  partners: PropTypes.array.isRequired
};
export default UpdateCompanyStatusPopUp;
