import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// material UI
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import StarIcon from '@material-ui/icons/Star';
import InfoIcon from '@material-ui/icons/Info';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import Tooltip from '@material-ui/core/Tooltip';
// react context
import withUser from '../utils/withUser';
import * as CONSTANTS from '../constants';
// components
import DetailField from '../generic_components/DetailField';
import UpdateCompanyStatusPopUp from './UpdateCompanyStatusPopUp';
import HistoryDrawer from '../generic_components/HistoryDrawer';
import MarkItemPopUp from '../components/MarkItemPopUp';
import CompanyCreateUpdatePopUp from './CompanyCreateUpdatePopUp';
import DropContainer from '../generic_components/DropContainer';
import ConfirmationModal from '../generic_components/ConfirmationModal';

// css
import './CompanyDetails.css';

import TEXT from '../text';
import TOOLTIPS from '../textTooltips';
import * as UTILS from '../utils/utilFunctions';
import * as CONFIG from '../config';
import HelperFunctions from './utils/helperFunctions';

import isPermissionSet from '../utils/isPermissionSet';

class CompanyDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCompanyCreateUpdatePopUpOpen: false,
      markCompanyPopUpOpen: false,

      pictureErrorMessage: '',
      pictureLoading: false,

      showCompanyStatusPopUp: false,
      showCompanyChangeHistoryPopUp: false,
      showDeleteCompanyModal: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  onImageDrop = acceptedFiles => {
    const url = CONSTANTS.updateCompanyLogoURL;
    const companyId = this.props.selectedCompany.id;
    this.setState({ pictureErrorMessage: '', pictureLoading: true });

    const request = new FormData();
    acceptedFiles.forEach((file, index) => {
      request.append(`file-${index}`, file);
    });
    request.append('companyId', companyId);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      contentType: false,
      processData: false,
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: request
    })
      .done(responseBody => {
        this.props.liftUpStateToCompanyArchive('selectedCompany', {
          picture: responseBody.picture
        });
        this.setState({
          pictureLoading: false
        });
      })
      .fail(err => {
        const ignoreErrorCodes = [0, 413, 415];
        if (err.status === 413 || err.status === 0) {
          this.setState({
            pictureErrorMessage: 'Datei zu groß (maximale Größe 16mb)',
            pictureLoading: false
          });
        }
        if (err.status === 415) {
          this.setState({
            pictureErrorMessage: 'Nicht unterstütztes Dateiformat',
            pictureLoading: false
          });
        }

        this.errorHandling(err, url, null, ignoreErrorCodes);
      });
  };

  getValue(sectionName, key) {
    let result = '';
    if (key === 'advisor') {
      const advisor = this.props.selectedCompany.advisor;
      const advisorText = advisor ? `${advisor.firstName} ${advisor.lastName}` : '';
      return advisorText;
    }
    switch (sectionName) {
      case 'companyTypeSection':
        result = this.props.selectedCompany[key];
        break;
      case 'address':
        result = this.props.selectedCompany[sectionName][key];
        break;
      case 'contactInfo':
        result = this.props.selectedCompany[sectionName][key];
        break;
      case 'urls':
        result = this.props.selectedCompany.contactInfo[key];
        break;
      case 'general':
        result = this.props.selectedCompany[key];
        break;
      case 'comments':
        result = this.props.selectedCompany[key];
        break;

      default:
        result = this.props.selectedCompany[sectionName] && this.props.selectedCompany[sectionName][key];
    }
    return result;
  }

  renderSectionInfo(sectionName, sectionObject) {
    const result = [];
    sectionObject.forEach(infos => {
      const key = infos[0];
      const value = infos[1];
      let detailFieldType = '';
      switch (sectionName) {
        case 'contactInfo':
          detailFieldType = 'phoneNumber';
          break;
        case 'urls':
          detailFieldType = 'url';
          break;
        default:
          detailFieldType = 'text';
      }
      result.push(
        <div className="col-12">
          <DetailField
            label={value}
            type={detailFieldType}
            value={this.getValue(sectionName, key)}
            customOnClickHandler={detailFieldType === 'phoneNumber' && this.props.openContactHistoryForPhone}
          />
        </div>
      );
    });
    return result;
  }

  renderCompanyInfo() {
    const result = [];
    const informationSections = TEXT.companyArchive.companyDetails.listOfInfoSections;

    informationSections.forEach(infos => {
      const key = Object.keys(infos)[0];
      const value = Object.entries(infos[key]);
      result.push(
        <div>
          <div className={`row ${key} p-3`}>
            <div className="col-12 row">{this.renderSectionInfo(key, value)}</div>
          </div>
          <Divider />
        </div>
      );
    });
    return result;
  }

  renderCompanyNameSection() {
    return (
      <div className="row">
        <div className="col-12 mt-3">
          <div className="companyDetailName">
            <div className="companyArchive-companyName">{this.props.selectedCompany.name}</div>
          </div>
        </div>
      </div>
    );
  }

  renderButtonSection() {
    return (
      <div className="col-12 text-center">
        {this.renderEditCompanyStatusButton()}
        {this.props.user.role === 'admin' && this.renderShowHistoryButton()}
        {this.renderEditButton()}
        {this.renderMarkButton()}
        {this.renderDeleteButton()}
      </div>
    );
  }

  renderMarkButton() {
    return (
      <Tooltip title={TOOLTIPS.companyArchive.markButton}>
        <IconButton className="company-mark-button" color="primary" onClick={() => this.setState({ markCompanyPopUpOpen: true })}>
          <StarIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderDeleteButton() {
    const isAdminUser = this.props.user.role === 'admin';
    if (isAdminUser)
      return (
        <Tooltip title={TOOLTIPS.companyArchive.deleteButton}>
          <IconButton className="company-mark-button" color="primary" onClick={() => this.setState({ showDeleteCompanyModal: true })}>
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
      );
    return '';
  }

  renderDeleteCompanyConfirmationModal() {
    return (
      <ConfirmationModal
        key="confirmation-deleteCandidateData"
        handleClose={() => this.setState({ showDeleteCompanyModal: false })}
        open={this.state.showDeleteCompanyModal}
        buttonPrimaryAction={this.handleDeleteCompanyAPICall}
        headlineText="Unternehmen vollständig löschen?"
        icon="delete_forever"
        descriptionText="Soll das Unternehmen wirklich vollständig gelöscht werden?"
      />
    );
  }

  handleDeleteCompanyAPICall = () => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    const url = CONSTANTS.deleteCompanyURL;
    const companyId = this.props.selectedCompany.id;
    const request = { companyId };
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: JSON.stringify(request)
    })
      .done(responseBody => {
        if (responseBody && responseBody.status === 'projectlist_not_empty')
          this.props.handleSnackbarOpen('Unternehmen konnte nicht gelöscht werden: Es existieren Projekte für dieses Unternehmen');
        if (responseBody && responseBody.status === 'reminderlist_not_empty')
          this.props.handleSnackbarOpen('Unternehmen konnte nicht gelöscht werden: Es existieren Wiedervorlagen für dieses Unternehmen');
        if (responseBody && responseBody.status === 'success') {
          this.setState({ showDeleteCompanyModal: false });
          this.props.handleSnackbarOpen('Unternehmen wurde erfolgreich gelöscht');
          this.props.liftUpStateObjectToCompanyArchive({
            showResultList: true,
            showCompanyDetails: false
          });
        }
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url, null);
      });
  };

  renderEditCompanyStatusButton() {
    if (isPermissionSet(this.props.user, 'dataImportCompanies'))
      return (
        <Tooltip title={TOOLTIPS.companyArchive.editCompanyStatusButton}>
          <IconButton
            className="company-lock-button"
            aria-label="lock"
            color="primary"
            onClick={() => this.setState({ showCompanyStatusPopUp: true })}
          >
            <LockIcon />
          </IconButton>
        </Tooltip>
      );
    return '';
  }

  renderEditButton() {
    return (
      <Tooltip title={TOOLTIPS.companyArchive.editButton}>
        <IconButton
          className="saveEditButton editButton"
          aria-label="Edit"
          color="primary"
          onClick={() =>
            this.setState({
              isCompanyCreateUpdatePopUpOpen: true
            })
          }
        >
          <EditIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderShowHistoryButton() {
    return (
      <Tooltip title={TOOLTIPS.companyArchive.showHistoryButton}>
        <IconButton
          className="company-changeHistory-button"
          aria-label="changeHistory"
          color="primary"
          onClick={() => this.setState({ showCompanyChangeHistoryPopUp: true })}
        >
          <InfoIcon />
        </IconButton>
      </Tooltip>
    );
  }

  renderCompanyStatusText(companyStatus) {
    const statusTypeText = TEXT.companyArchive.companyDetails.companyStatus[companyStatus.statusType];
    let statusPartnerText = '';
    if (companyStatus.partner) {
      let statusPartnerIdText = '';
      if (companyStatus.partner.partnerId) statusPartnerIdText = ` ID: ${companyStatus.partner.partnerId}`;
      statusPartnerText = ` (${companyStatus.partner.partnerName}${statusPartnerIdText})`;
    }
    return (
      <div>
        <div>{statusTypeText}</div>
        <div className=" partner">{companyStatus.partner && statusPartnerText}</div>
      </div>
    );
  }

  renderCompanyStatus() {
    const companyStatus = HelperFunctions.verifyCompanyStatus(this.props.selectedCompany.status);

    const styling = `companyArchive-companyStatus-text ${HelperFunctions.getCompanyStatusColor(companyStatus)}`;

    return (
      <div className="col-12 text-center">
        <div className="companyArchive-companyStatus-container">
          <div className={styling}>{this.renderCompanyStatusText(companyStatus)}</div>
          {this.showDirectContactStatus(this.props.selectedCompany.directContact) ? this.renderDirectContactStatus() : null}
        </div>
      </div>
    );
  }

  renderDirectContactStatus() {
    const styling = 'companyArchive-directContactStatus-text';
    return <div className={styling}>Keine Direktansprache</div>;
  }

  renderCompanyLogo() {
    let image = './img/placeholer_logo.png';
    if (this.props.selectedCompany.picture) {
      image = UTILS.convertBufferToImage(this.props.selectedCompany.picture);
      return (
        <div className="row">
          <div className="col-12 text-center">
            <img src={image} alt="logo" className="img-fluid pt-3" />
          </div>
        </div>
      );
    }
    return '';
  }

  handleCloseCompanyChangeHistoryPopUp = () => {
    this.setState({
      showCompanyChangeHistoryPopUp: false
    });
  };

  handleCloseUpdateCompanyStatusPopUp = (reload, companyId) => {
    this.setState({
      showCompanyStatusPopUp: false
    });
    if (reload) {
      this.props.refreshCompany(companyId);
    }
  };

  renderHistoryDrawer() {
    return (
      <HistoryDrawer
        open={this.state.showCompanyChangeHistoryPopUp}
        payload={{
          companyChangeHistoryId: this.props.selectedCompany.companyChangeHistoryId
        }}
        url={CONSTANTS.getCompanyChangeHistoryURL}
        handleClose={this.handleCloseCompanyChangeHistoryPopUp}
      />
    );
  }

  renderCompanyStatusPopUp() {
    return (
      <UpdateCompanyStatusPopUp
        open={this.state.showCompanyStatusPopUp}
        handleClose={this.handleCloseUpdateCompanyStatusPopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        companyId={this.props.selectedCompany.id}
        companyStatus={this.props.selectedCompany.status}
        partners={this.props.partners}
      />
    );
  }

  renderIfCompanyPopUpIsOpen() {
    return (
      this.state.isCompanyCreateUpdatePopUpOpen && (
        <CompanyCreateUpdatePopUp
          open={this.state.isCompanyCreateUpdatePopUpOpen}
          handleClose={() => this.setState({ isCompanyCreateUpdatePopUpOpen: false })}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          isUpdate
          companyId={this.props.selectedCompany.id}
          companyName={this.props.selectedCompany.name}
          companyType={this.props.selectedCompany.companyType}
          street={this.props.selectedCompany.address.street}
          postbox={this.props.selectedCompany.address.postbox}
          postalCode={this.props.selectedCompany.address.postalCode}
          city={this.props.selectedCompany.address.city}
          country={this.props.selectedCompany.address.country}
          phoneNumber={this.props.selectedCompany.contactInfo.phoneNumber}
          faxNumber={this.props.selectedCompany.contactInfo.faxNumber}
          url={this.props.selectedCompany.contactInfo.url}
          urlCareerPage={this.props.selectedCompany.contactInfo.urlCareerPage}
          advisor={this.props.selectedCompany.advisor}
          industrySector={this.props.selectedCompany.industrySector}
          companySize={this.props.selectedCompany.companySize}
          comment={this.props.selectedCompany.comment}
          liftUpStateToCompanyArchive={this.props.liftUpStateToCompanyArchive}
          employees={this.props.employees}
          category={this.props.selectedCompany.category}
          directContact={this.props.selectedCompany.directContact}
        />
      )
    );
  }

  /**
   * Helper function to render the directContact status if it is not wanted
   * @param {*} directContact
   * @returns
   */
  showDirectContactStatus(directContact) {
    if (directContact === null || directContact === undefined) {
      return false;
    }

    return directContact === 'Keine Direktansprache';
  }

  render() {
    return (
      <div>
        {this.state.isCompanyCreateUpdatePopUpOpen && this.renderIfCompanyPopUpIsOpen()}
        <Paper>
          {!this.props.locked ? (
            <DropContainer
              dropzoneErrorMessage={this.state.pictureErrorMessage}
              showLoadingIndicator={this.state.pictureLoading}
              onDrop={this.onImageDrop}
            >
              {this.renderCompanyLogo()}
            </DropContainer>
          ) : (
            this.renderCompanyLogo()
          )}
          {this.state.showDeleteCompanyModal && this.renderDeleteCompanyConfirmationModal()}

          {this.renderCompanyNameSection()}
          <div className="row general p-3">
            {this.renderCompanyStatus()}
            {/* {this.showDirectContactStatus(this.props.selectedCompany.directContact) ? this.renderDirectContactStatus(): null} */}
            {this.renderButtonSection()}
          </div>
          <Divider />
          {this.renderCompanyInfo()}
        </Paper>
        {this.state.showCompanyStatusPopUp && this.renderCompanyStatusPopUp()}
        {this.state.showCompanyChangeHistoryPopUp && this.renderHistoryDrawer()}
        <MarkItemPopUp
          open={this.state.markCompanyPopUpOpen}
          handleClose={() => this.setState({ markCompanyPopUpOpen: false })}
          item={{
            company: this.props.selectedCompany.id
          }}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          markListPopupType="company"
        />
      </div>
    );
  }
}
CompanyDetails.defaultProps = {
  picture: undefined,
  locked: false
};

CompanyDetails.propTypes = {
  selectedCompany: PropTypes.object.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  user: PropTypes.shape.isRequired,
  refreshCompany: PropTypes.func.isRequired,
  liftUpStateToCompanyArchive: PropTypes.func.isRequired,
  openContactHistoryForPhone: PropTypes.func.isRequired,
  picture: PropTypes.string,
  partners: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  locked: PropTypes.bool,
  liftUpStateObjectToCompanyArchive: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
};
export default withUser(CompanyDetails);
