import $ from 'jquery';
import * as CONFIG from '../config';

function json(payload) {
  return {
    dataType: 'json',
    contentType: 'application/json; charset=utf-8',
    data: JSON.stringify(payload)
  };
}

function formData(payload) {
  return {
    contentType: false,
    processData: false,
    data: payload
  };
}

// eslint-disable-next-line import/prefer-default-export
export async function post(url, payload, useLongTimeout) {
  const tokenFromLocalStorage = window.sessionStorage.getItem('token');
  const options = payload instanceof FormData ? formData(payload) : json(payload);
  return new Promise((resolve, reject) => {
    $.ajax({
      url,
      method: 'POST',
      headers: { 'x-auth': tokenFromLocalStorage },
      // eslint-disable-next-line no-nested-ternary
      timeout: useLongTimeout === true ? CONFIG.ajaxLongTimeout : Number.isInteger(useLongTimeout) ? useLongTimeout : CONFIG.ajaxTimeout,
      ...options
    })
      .done((responseBody, status) => {
        resolve(responseBody, status);
      })
      .fail(err => {
        reject(err);
      });
  });
}
