import { Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

export default function IntranetContainer({ children }) {
  return (
    <div className="container mt-5">
      <Paper>
        <div className="row">
          <div className="col-12">{children}</div>
        </div>
      </Paper>
    </div>
  );
}
IntranetContainer.propTypes = {
  children: PropTypes.node.isRequired
};
