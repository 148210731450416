import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import validator from 'validator';
import withUser from '../../utils/withUser';

// Basic utils, constants and configs
import * as UTILS from '../../utils/utilFunctions';
import * as CONSTANTS from '../../constants';
import { post } from '../../utils/apiUtils';
import TEXT_GENERAL from '../../text';

const OfferCreateUpdatePopUp = ({
  handleClose,
  open,
  employees,
  handleSnackbarOpen,
  companyDetails,
  errorHandling,
  handleChangeTablePageNumber,
  offerData,
  isUpdate
}) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const [updatedOffer, setUpdatedOffer] = useState({});
  const [errorOffer, setErrorOffer] = useState({});
  const companyId = companyDetails.id;
  const DATE_FORMAT = 'dd.MM.yyyy';
  const TODAY_DATE = useMemo(() => new Date(), []);
  const getFields = useMemo(
    () => ({
      userId: {
        label: TEXT_GENERAL.offers.fields.label.advisor,
        type: 'userId',
        required: true,
        validate: [],
        error: null
      },
      date: {
        label: TEXT_GENERAL.offers.fields.label.date,
        type: 'date',
        required: true,
        validate: [],
        error: null
      },
      client: {
        label: TEXT_GENERAL.offers.fields.label.client,
        type: 'client',
        disabled: true,
        required: true,
        validate: [],
        error: null
      },
      newClient: {
        label: TEXT_GENERAL.offers.fields.label.newClient,
        type: 'select',
        dropDownList: [{ label: 'Ja', value: 'yes' }, { label: 'Nein', value: 'no' }],
        required: true,
        validate: [],
        error: null
      },
      vacancy: {
        label: TEXT_GENERAL.offers.fields.label.vacancy,
        type: 'text',
        required: true,
        validate: [],
        error: null
      },
      taskType: {
        label: TEXT_GENERAL.offers.fields.label.taskType,
        type: 'select',
        dropDownList: UTILS.getEnumForDropdownList(TEXT_GENERAL.offers.enum.taskType),
        required: true,
        validate: [],
        error: null
      },
      directContact: {
        label: TEXT_GENERAL.offers.fields.label.directContact,
        type: 'select',
        dropDownList: UTILS.getEnumForDropdownList(TEXT_GENERAL.offers.enum.boolean),
        required: false,
        validate: [],
        error: null
      },
      flatRate: {
        label: TEXT_GENERAL.offers.fields.label.flatRate,
        type: 'text',
        adornment: 'CHF',
        required: false,
        validate: ['chf'],
        format: 'chf',
        error: null
      },
      percentage: {
        label: TEXT_GENERAL.offers.fields.label.percentage,
        type: 'percentage',
        adornment: '%',
        required: false,
        validate: ['number', 'percentage'],
        format: 'percentage',
        error: null
      },
      status: {
        label: TEXT_GENERAL.offers.fields.label.status,
        type: 'select',
        dropDownList: UTILS.getEnumForDropdownList(TEXT_GENERAL.offers.enum.status),
        required: true,
        validate: [],
        error: null
      },
      moreInfo: {
        label: TEXT_GENERAL.offers.fields.label.moreInfo,
        type: 'multiline-text',
        required: false,
        validate: [],
        error: null
      },
      declineReason: {
        label: TEXT_GENERAL.offers.fields.label.declineReason,
        type: 'multiline-text',
        required: false,
        validate: [],
        error: null
      }
    }),
    []
  );

  const getValidations = useMemo(
    () => ({
      number: {
        pattern: /^[0-9]+$/,
        message: 'Bitte geben Sie nur Zahlen ein'
      },
      alphaNumericAndSpacesAndSymbols: {
        pattern: /^[a-zA-Z0-9äöüÄÖÜß \-.,;:!?()]+$/,
        message: 'Bitte geben Sie nur gültige Zeichen ein'
      },
      alphaNumericAndSpaces: {
        pattern: /^[a-zA-Z0-9äöüÄÖÜß \-]+$/,
        message: 'Bitte geben Sie nur gültige Zeichen ein'
      },
      chf: {
        pattern: /^[0-9'´`.,]+$/,
        message: 'Bitte geben Sie einen gültigen Betrag ein'
      },
      percentage: {
        // Must be a integer between 0 and 100
        pattern: /^(100|[0-9]?[0-9])$/,
        message: 'Geben Sie eine Zahl zwischen 0 und 100 ein'
      }
    }),
    []
  );

  const addError = (key, errorMessage) => {
    setErrorOffer(prevState => ({
      ...prevState,
      [key]: errorMessage
    }));
  };

  const removeError = key => {
    // Remove the object key from the errorOffer object
    const { [key]: omit, ...rest } = errorOffer;
    setErrorOffer(rest);
  };

  const removeAllErrors = useCallback(() => {
    setErrorOffer({});
  }, []);

  const getValue = useCallback(
    (key, preselected) => {
      const updatedValue = updatedOffer[key];

      if (preselected && preselected !== undefined) {
        return preselected;
      }
      if (updatedValue !== undefined) {
        return updatedValue;
      }

      return '';
    },
    [updatedOffer]
  );

  useEffect(() => {
    const allFieldsValid = () => {
      const isAllValid = Object.keys(getFields).map(key => {
        let isValid = true;

        // Check if the field has an error, independent of required or not, all fields must be valid
        if (typeof errorOffer[key] !== 'undefined' && errorOffer[key] !== null) {
          isValid = false;
        }

        if (getFields[key].required) {
          const currentValue = getValue(key);
          // Also the field can not be empty
          if (currentValue === undefined || currentValue === '') {
            isValid = false;
          }
        }

        return isValid;
      });

      return isAllValid.every(value => value === true);
    };

    setIsDisabled(!allFieldsValid());
  }, [updatedOffer, errorOffer, getValue, getFields]);

  const resetPopUp = useCallback(() => {
    removeAllErrors();
    setIsDisabled(true);
  }, [removeAllErrors, setIsDisabled]);

  const setInitialValues = useCallback(() => {
    resetPopUp();

    if (isUpdate && companyId && offerData) {
      setUpdatedOffer({
        client: companyId,
        newClient: offerData.newClient,
        date: offerData.date,
        vacancy: offerData.vacancy,
        directContact: offerData.directContact,
        taskType: offerData.taskType,
        flatRate: offerData.flatRate,
        percentage: offerData.percentage,
        moreInfo: offerData.moreInfo,
        status: offerData.status,
        declineReason: offerData.declineReason,
        userId: offerData.userId
      });
    } else if (companyId) {
      setUpdatedOffer(prevState => ({
        ...prevState,
        client: companyId,
        date: TODAY_DATE
      }));
    }
  }, [resetPopUp, isUpdate, companyId, offerData, setUpdatedOffer, TODAY_DATE]); // Dependencies array

  useEffect(() => {
    if (open) {
      setInitialValues();
    }
  }, [open, setInitialValues]);

  const handleAPI = async (mode, payload) => {
    const url = mode === 'create' ? CONSTANTS.createCompanyOffer : CONSTANTS.updateCompanyOffer;
    const snackBarText = mode === 'create' ? TEXT_GENERAL.offers.popup.snackbarInfo : TEXT_GENERAL.offers.popup.updateSnackBarMessage;
    setIsDisabled(true);

    post(url, payload)
      .then(responseBody => {
        if (responseBody.success) {
          setIsDisabled(false);
          handleSnackbarOpen(`${snackBarText}`);
          handleChangeTablePageNumber(0);
          resetPopUp();
          setUpdatedOffer({});
          handleClose(false);
        } else {
          handleSnackbarOpen(`${snackBarText}`);
          setUpdatedOffer({});
          handleClose(false);
        }
      })
      .catch(err => {
        errorHandling(err, url);
        setIsDisabled(false);
      });
  };

  const updateOffer = async () => {
    const payload = {
      updatedOffer,
      companyId,
      offerId: offerData.id
    };

    handleAPI('update', payload);
  };

  const createOffer = async () => {
    const payload = {
      offerDetails: {
        newClient: updatedOffer.newClient,
        date: updatedOffer.date || TODAY_DATE,
        vacancy: updatedOffer.vacancy,
        directContact: updatedOffer.directContact,
        taskType: updatedOffer.taskType,
        flatRate: updatedOffer.flatRate,
        percentage: updatedOffer.percentage,
        moreInfo: updatedOffer.moreInfo,
        status: updatedOffer.status,
        declineReason: updatedOffer.declineReason
      },
      companyId,
      userId: updatedOffer.userId
    };
    handleAPI('create', payload);
  };

  const getInputType = type => {
    switch (type) {
      case 'currency':
      case 'percentage':
        return 'text';
      default:
        return type;
    }
  };

  const validateInput = (key, value) => {
    let isValid = true;
    const validations = getValidations;
    const validateFieldArray = getFields[key].validate || [];

    if (validateFieldArray.length > 0 && value !== '') {
      validateFieldArray.forEach(validationType => {
        // Make validation here with the 'regex' of validations[validationType]
        // Make regex validation here
        if (!validations[validationType].pattern.test(value)) {
          isValid = false;
          const errorMessage = validations[validationType].message;
          addError(key, errorMessage);
          // Break the loop
          return false;
        }
        return isValid;
      });
    } else if (getFields[key].required && value === '') {
      isValid = false;
      const errorMessage = TEXT_GENERAL.offers.popup.emptyError;
      addError(key, errorMessage);
    }

    if (isValid && errorOffer[key] !== null) {
      removeError(key);
    }

    return isValid;
  };

  const formatInputValue = (key, value) => {
    let formattedValue = typeof value === 'string' || typeof value === 'number' ? validator.trim(value) : value;
    const formattingType = getFields[key].format || null;
    if (formattingType && typeof formattedValue !== 'undefined' && value !== '') {
      switch (formattingType) {
        case 'chf':
          // Replace commas with dots
          formattedValue = formattedValue.replace(/,/g, '.');
          formattedValue = formattedValue.replace(/[^0-9.]/g, '');
          // If formattedValue has no number or is empty
          if (formattedValue === '' || Number.isNaN(Number(formattedValue))) {
            formattedValue = '0';
            break;
          } else {
            // Format to 2 decimal places
            formattedValue = parseFloat(formattedValue).toFixed(2);
            // Format the value to CHF, that means adding ' as a separator for thousands
            formattedValue = formattedValue.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
            break;
          }
        case 'percentage':
          // REMOVE EVERYTHING THAT IS NOT A NUMBER
          formattedValue = value.replace(/[^0-9]/g, '');
          //
          break;
        default:
          formattedValue = value;
          break;
      }
    }
    return formattedValue;
  };

  const changeHandler = (key, newValue) => {
    // First check if newValue is different from existingOffer[key]
    if (newValue !== getValue(key)) {
      // First I need to validate the input
      validateInput(key, newValue);
      setUpdatedOffer(prevState => ({
        ...prevState,
        [key]: newValue
      }));
    }
  };

  const blurHandler = (key, newValue) => {
    const formattedValue = formatInputValue(key, newValue);
    changeHandler(key, formattedValue);
  };

  const onDateFieldChange = (date, key) => {
    if (date !== getValue(key)) {
      setUpdatedOffer(prevState => ({
        ...prevState,
        [key]: date
      }));
    }
  };

  return (
    <Dialog open={open} aria-labelledby="OfferCreateUpdatePopUpText-dialog">
      <DialogTitle>{isUpdate ? TEXT_GENERAL.offers.popup.titleUpdate : TEXT_GENERAL.offers.popup.titleCreate}</DialogTitle>
      <DialogContent>
        <div className="row">
          {Object.keys(getFields).map(key => {
            const textTypes = ['text', 'number', 'percentage', 'multiline-text', 'float'];
            const selectTypes = ['select', 'userId', 'client'];
            const dateTypes = ['date'];
            const fieldId = `id_${key}`;
            const dataObject = getFields[key];
            const renderType = dataObject.type;
            const colSize = renderType === 'multiline-text' ? 12 : 6;
            const isAutoFocus = Object.keys(getFields).indexOf(key) === 0;
            const adornment = dataObject.adornment ? dataObject.adornment : null;

            let preselected = null;
            let dropDownItems = [];

            if (renderType === 'client') {
              const optionsArray =
                updatedOffer && updatedOffer.companyName
                  ? [{ label: updatedOffer.companyName, value: updatedOffer.companyName }]
                  : [{ label: companyDetails.name, value: companyDetails.id }];

              dropDownItems = optionsArray || [];
              preselected = optionsArray.length > 0 ? optionsArray[0].value : null;
            } else if (renderType === 'date') {
              preselected = updatedOffer.date || TODAY_DATE;
            } else if (renderType === 'userId') {
              dropDownItems = employees || [];
            } else {
              dropDownItems = dataObject.dropDownList || [];
            }

            const fieldLabel = dataObject.label;
            const disabled = dataObject.disabled ? dataObject.disabled : false;
            const setValue = getValue(key, preselected);

            // Prepare the dropDownItemsObj
            const dropDownItemsObj = dropDownItems.map(item => {
              const itemOut = item;
              if (item.value === undefined) {
                itemOut.value = item.label;
              }
              return itemOut;
            });

            // If the field type is 'text' then render a text field
            if (textTypes.includes(renderType)) {
              return (
                <div className={`col-${colSize}`} key={key}>
                  <TextField
                    value={updatedOffer[key]}
                    name={`inputField${key}`}
                    label={getFields[key].label}
                    type={getInputType(renderType)}
                    fullWidth
                    required={getFields[key].required}
                    autoFocus={isAutoFocus}
                    multiline={renderType === 'multiline-text'}
                    variant="standard"
                    margin="normal"
                    InputProps={{
                      endAdornment: adornment ? <InputAdornment position="end">{adornment}</InputAdornment> : null,
                      maxLength: renderType === 'multiline-text' ? 250 : 125,
                      min: renderType === 'percentage' ? 0 : null,
                      max: renderType === 'percentage' ? 100 : null
                    }}
                    error={typeof errorOffer[key] !== 'undefined' && errorOffer[key] !== null}
                    helperText={typeof errorOffer[key] !== 'undefined' ? errorOffer[key] : null}
                    onChange={e => {
                      const newValue = e.target.value;
                      changeHandler(key, newValue);
                    }}
                    onBlur={e => {
                      const newValue = e.target.value;
                      blurHandler(key, newValue);
                    }}
                  />
                </div>
              );
            }
            if (selectTypes.includes(renderType)) {
              return (
                <div className="col-6" key={key}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor={fieldId}>
                      {fieldLabel}
                      {getFields[key].required === true ? ' *' : ''}
                    </InputLabel>

                    <Select
                      value={setValue}
                      onClose={() => {
                        const element = document.querySelector('.MuiInputBase-root.Mui-focused');
                        const label = document.querySelector('.MuiInputLabel-shrink');
                        if (element) {
                          element.classList.remove('Mui-focused');
                          label.classList.remove('Mui-focused');
                        }
                      }}
                      onChange={e => {
                        const newValue = e.target.value;
                        changeHandler(key, newValue);
                      }}
                      onBlur={e => {
                        const newValue = e.target.value;
                        blurHandler(key, newValue);
                      }}
                      disabled={disabled}
                      variant="standard"
                      margin="normal"
                      inputProps={{
                        name: key,
                        id: fieldId
                      }}
                    >
                      {!getFields[key].required && (
                        <MenuItem value="">
                          <em>{TEXT_GENERAL.offers.popup.noSelection}</em>
                        </MenuItem>
                      )}

                      {dropDownItemsObj.map(item => (
                        <MenuItem key={item.label} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              );
            }
            if (dateTypes.includes(renderType)) {
              return (
                <div className="col-6" key={key}>
                  <KeyboardDatePicker
                    fullWidth
                    id={fieldId}
                    key={fieldId}
                    label={getFields.date.label}
                    margin="normal"
                    format={DATE_FORMAT}
                    onChange={date => onDateFieldChange(date, key)}
                    required={getFields[key].required}
                    cancelLabel={TEXT_GENERAL.offers.popup.cancel}
                    value={updatedOffer[key] || TODAY_DATE}
                    animateYearScrolling={false}
                  />
                </div>
              );
            }

            return null;
          })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            setUpdatedOffer({});
            handleClose();
          }}
        >
          {TEXT_GENERAL.offers.popup.cancel}
        </Button>
        <Button color="primary" disabled={isDisabled} onClick={isUpdate ? updateOffer : createOffer}>
          {isUpdate ? TEXT_GENERAL.offers.popup.saveEntryUpdate : TEXT_GENERAL.offers.popup.saveEntry}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

OfferCreateUpdatePopUp.defaultProps = {
  isUpdate: false,
  employees: [],
  offerData: {},
  companyDetails: {},
  errorHandling: () => {},
  handleChangeTablePageNumber: () => {},
  handleClose: () => {},
  handleSnackbarOpen: () => {}
};

OfferCreateUpdatePopUp.propTypes = {
  isUpdate: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  handleSnackbarOpen: PropTypes.func,
  employees: PropTypes.arrayOf(PropTypes.object),
  offerData: PropTypes.object,
  companyDetails: PropTypes.object,
  errorHandling: PropTypes.func,
  handleChangeTablePageNumber: PropTypes.func
};

export default withUser(OfferCreateUpdatePopUp);
