import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import ProjectIcon from '@material-ui/icons/QuestionAnswer';
import Dropzone from 'react-dropzone';
import $ from 'jquery';
import AddCandidateToProjectPopUp from '../../candidateArchive/popUps/AddCandidateToProjectPopUp';
import DetailField from '../../generic_components/DetailField';
import * as CONSTANTS from '../../constants';

import TEXT from './staticContent/text';
import * as UTILS from '../../utils/utilFunctions';
import GenericForm from '../../generic_components/GenericForm';
// import SwitchInputField from '../../generic_components/SwitchInputField';
import * as CONFIG from '../../config';

import NATIONALITY_DROPDOWN_CONTENT from '../staticContent/nationalityDropDown';
import NATIONALITY_DROPDOWN_CONTENT_LIENERT from '../staticContent/nationalityDropDownLienert';
import COUNTRY_DROPDOWN_CONTENT from '../staticContent/countryDropDown';
import MARITAL_STATUS_DROPDOWN_CONTENT from '../staticContent/maritalStatusDropDown';

const baseStyle = {};
const activeStyle = {
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: '#6c6',
  backgroundColor: '#eee',
  borderRadius: 5
};
const rejectStyle = {
  borderWidth: 2,
  borderRadius: 5,
  borderStyle: 'solid',
  borderColor: '#c66',
  backgroundColor: '#eee'
};
class IncomingCandidateDetails extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showAddCandidateToProjectPopUp: false,
      showAddCandidateToProjectAppliedPopUp: false,
      pictureErrorMessage: '',
      pictureLoading: false
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  renderCandidateImage() {
    let image = './img/placeholer_logo.png';
    if (this.props.selectedIncomingCandidate.picture) {
      image = UTILS.convertBufferToImageV2(this.props.selectedIncomingCandidate.picture);
      return (
        <div className="row">
          <div className="col-12 text-center">
            <img src={image} alt="logo" className="img-fluid mt-3 mb-3 candidateDetailsImage rounded" />
          </div>
        </div>
      );
    }
    return '';
  }

  onImageDrop = acceptedFiles => {
    const url = CONSTANTS.updateIncomingCandidatePictureURL;
    this.setState({ pictureErrorMessage: '', pictureLoading: true });

    const request = new FormData();
    acceptedFiles.forEach((file, index) => {
      request.append(`file-${index}`, file);
    });
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      contentType: false,
      processData: false,
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: request
    })
      .done(responseBody => {
        this.props.liftUpSelectedCandidateToIncomingCandidates({
          picture: responseBody.picture
        });
        this.setState({
          pictureLoading: false
        });
      })
      .fail((err, err2, err3) => {
        console.log('ERROR', JSON.stringify(err), err2, err3);
        const ignoreErrorCodes = [0, 413, 415];
        if (err.status === 413 || err.status === 0) {
          this.setState({
            pictureErrorMessage: 'Datei zu groß (maximale Größe 16mb)',
            pictureLoading: false
          });
        }
        if (err.status === 415) {
          this.setState({
            pictureErrorMessage: 'Nicht unterstütztes Dateiformat',
            pictureLoading: false
          });
        }

        this.errorHandling(err, url, null, ignoreErrorCodes);
      });
  };

  renderAddCandidateToProjectPopUp() {
    return (
      <AddCandidateToProjectPopUp
        open={this.state.showAddCandidateToProjectPopUp || this.state.showAddCandidateToProjectAppliedPopUp}
        handleClose={() => {
          this.setState({
            showAddCandidateToProjectPopUp: false,
            showAddCandidateToProjectAppliedPopUp: false
          });
        }}
        handleAddCandidateToProject={(projectId, projectNumber) => {
          this.setState({
            showAddCandidateToProjectPopUp: false,
            showAddCandidateToProjectAppliedPopUp: false
          });

          this.props.liftUpSelectedCandidateToIncomingCandidates({
            projectId,
            projectNumber,
            applied: this.state.showAddCandidateToProjectAppliedPopUp
          });
        }}
        confirmationDescription={
          this.state.showAddCandidateToProjectPopUp
            ? TEXT.incomingCandidateDetails.addCandidateToProjectConfirmation
            : TEXT.incomingCandidateDetails.addCandidateToProjectAppliedConfirmation
        }
      />
    );
  }

  generateFormFields() {
    const duplicateExists = this.props.selectedDuplicateCandidate && Object.entries(this.props.selectedDuplicateCandidate).length > 0;
    const forms = {
      salutation: {
        value: this.props.selectedIncomingCandidate.salutation || this.props.selectedDuplicateCandidate.salutation,
        label: TEXT.incomingCandidateDetails.salutation,
        customClass: 'col-2 mb-2',
        type: 'dropDown',
        content: ['Herr', 'Frau']
        // validator: 'isText'
      },
      title: {
        value: this.props.selectedIncomingCandidate.title,
        label: TEXT.incomingCandidateDetails.title,
        customClass: 'col-5 mb-2',
        oldValue: this.props.selectedDuplicateCandidate.title,
        newValue: this.props.selectedIncomingCandidate.title,
        type: duplicateExists ? 'switchInputField' : 'textField',
        validator: 'isText'
      },
      firstName: {
        value: (this.props.selectedIncomingCandidate.firstName || '').trim(),
        label: TEXT.incomingCandidateDetails.firstName,
        customClass: 'col-6 mb-2',
        oldValue: (this.props.selectedDuplicateCandidate.firstName || '').trim(),
        newValue: (this.props.selectedIncomingCandidate.firstName || '').trim(),
        type: duplicateExists ? 'switchInputField' : 'textField',
        required: true,
        validator: 'isCharsOnly'
      },
      lastName: {
        value: this.props.selectedIncomingCandidate.lastName,
        label: TEXT.incomingCandidateDetails.lastName,
        customClass: 'col-6 mb-2',
        required: true,
        validator: 'isCharsOnly'
      },
      street: {
        value: (this.props.selectedIncomingCandidate.street || '').trim(),
        label: TEXT.incomingCandidateDetails.street,
        oldValue: (this.props.selectedDuplicateCandidate.street || '').trim(),
        newValue: (this.props.selectedIncomingCandidate.street || '').trim(),
        type: duplicateExists ? 'switchInputField' : 'textField',
        validator: 'isText',
        customClass: 'col-6 mb-2'
      },
      city: {
        value: (this.props.selectedIncomingCandidate.city || '').trim(),
        label: TEXT.incomingCandidateDetails.city,
        oldValue: (this.props.selectedDuplicateCandidate.city || '').trim(),
        newValue: (this.props.selectedIncomingCandidate.city || '').trim(),
        type: duplicateExists ? 'switchInputField' : 'textField',
        customClass: 'col-6 mb-2',
        validator: 'isText'
      },
      postalCode: {
        value: (this.props.selectedIncomingCandidate.postalCode || '').trim(),
        label: TEXT.incomingCandidateDetails.postalCode,
        oldValue: (this.props.selectedDuplicateCandidate.postalCode || '').trim(),
        newValue: (this.props.selectedIncomingCandidate.postalCode || '').trim(),
        type: duplicateExists ? 'switchInputField' : 'textField',
        customClass: 'col-6 mb-2',
        validator: 'isText'
      },
      country: {
        value: this.props.selectedIncomingCandidate.country,
        label: TEXT.incomingCandidateDetails.country,
        customClass: 'col-6 mb-2',
        type: 'dropDown',
        content: COUNTRY_DROPDOWN_CONTENT
        // content: [
        //   this.props.selectedIncomingCandidate.country,
        //   'Deutschland',
        //   'ToDO'
        // ]
      },
      phoneNumber: {
        value: (this.props.selectedIncomingCandidate.phoneNumber || '').trim(),
        label: TEXT.incomingCandidateDetails.phoneNumber,
        oldValue: (this.props.selectedDuplicateCandidate.phoneNumber || '').trim(),
        newValue: (this.props.selectedIncomingCandidate.phoneNumber || '').trim(),
        type: duplicateExists ? 'switchInputField' : 'textField',
        customClass: 'col-6 mb-2',
        validator: 'isPhoneNumber'
      },
      phoneNumberMobile: {
        value: (this.props.selectedIncomingCandidate.phoneNumberMobile || '').trim(),
        label: TEXT.incomingCandidateDetails.phoneNumberMobile,
        oldValue: (this.props.selectedDuplicateCandidate.phoneNumberMobile || '').trim(),
        newValue: (this.props.selectedIncomingCandidate.phoneNumberMobile || '').trim(),
        type: duplicateExists ? 'switchInputField' : 'textField',
        customClass: 'col-6 mb-2',
        validator: 'isPhoneNumber'
      },
      mailAddress: {
        value: (this.props.selectedIncomingCandidate.mailAddress || '').trim(),
        label: TEXT.incomingCandidateDetails.mailAddress,
        oldValue: (this.props.selectedDuplicateCandidate.mailAddress || '').trim(),
        newValue: (this.props.selectedIncomingCandidate.mailAddress || '').trim(),
        type: duplicateExists ? 'switchInputField' : 'textField',
        customClass: 'col-6 mb-2',
        validator: 'isEmail'
      },
      nationality: {
        value: this.props.selectedIncomingCandidate.nationality,
        label: TEXT.incomingCandidateDetails.nationality,
        customClass: 'col-6 mb-2',
        type: 'dropDown',
        content: CONSTANTS.isLienert ? NATIONALITY_DROPDOWN_CONTENT_LIENERT : NATIONALITY_DROPDOWN_CONTENT
      },
      dateOfBirth: {
        value: UTILS.formatDateForDateField(this.props.selectedIncomingCandidate.dateOfBirth),
        oldValue: UTILS.formatDateForDateField(this.props.selectedDuplicateCandidate.dateOfBirth),
        newValue: UTILS.formatDateForDateField(this.props.selectedIncomingCandidate.dateOfBirth),
        type: duplicateExists ? 'switchDateInputField' : 'date',
        label: TEXT.incomingCandidateDetails.dateOfBirth
      },
      maritalStatus: {
        value: this.props.selectedIncomingCandidate.maritalStatus && this.props.selectedIncomingCandidate.maritalStatus.toLowerCase(),
        label: TEXT.incomingCandidateDetails.maritalStatus,
        customClass: 'col-6 mb-2',
        type: 'dropDown',
        content: MARITAL_STATUS_DROPDOWN_CONTENT
      },
      creationDate: {
        value: UTILS.formatDateForDateField(this.props.selectedIncomingCandidate.creationDate),
        type: 'date',
        label: TEXT.incomingCandidateDetails.creationDate
      },
      candidateDB: {
        value:
          !this.props.selectedIncomingCandidate.candidateDB || this.props.selectedIncomingCandidate.candidateDB === 'Nein' ? 'Nein' : 'Ja',
        label: TEXT.incomingCandidateDetails.candidateDB,
        customClass: 'col-2 mb-2',
        type: 'dropDown',
        content: ['Ja', 'Nein']
      },
      search: {
        value: this.validateSearchStatus(),
        label: TEXT.incomingCandidateDetails.search,
        customClass: 'col-2 mb-2',
        type: 'dropDown',
        content: ['Ja', 'Nein']
      }
    };
    return forms;
  }

  validateSearchStatus = () => {
    if (
      this.props.selectedIncomingCandidate.search === null ||
      this.props.selectedIncomingCandidate.search === undefined ||
      this.props.selectedIncomingCandidate.search === ''
    ) {
      return '';
    }
    if (this.props.selectedIncomingCandidate.search === 'true') {
      return 'Ja';
    }
    if (this.props.selectedIncomingCandidate.search === 'false') {
      return 'Nein';
    }
    return '';
  };

  handleConfirmButton = updatedData => {
    this.props.liftUpSelectedCandidateToIncomingCandidates(updatedData);
    this.props.handleNext();
  };

  handleCompleteNowButton = () => {
    // handleConfirmButton navigates to step 4; 5 more to go to step 9 :)
    this.props.handleNext();
    this.props.handleNext();
    this.props.handleNext();
    this.props.handleNext();
    this.props.handleNext();
  };

  getProjectNumberText() {
    if (!this.props.selectedIncomingCandidate.projectNumber || this.props.selectedIncomingCandidate.projectNumber.length === 0) return '';
    if (this.props.selectedIncomingCandidate.applied) return ' (beworben)';
    return ' (vorgeschlagen)';
  }

  renderInputFields() {
    return (
      <div className="container">
        <div className="row mt-3 mb-3">
          <div className="col-5 ">
            <DetailField
              label={TEXT.incomingCandidateDetails.projectNumber + this.getProjectNumberText()}
              value={this.props.selectedIncomingCandidate.projectNumber}
            />
          </div>
          <div className="col-1">
            <Tooltip title="Zuweisung zum Auftrag entfernen">
              <IconButton
                className="send-data-privacy-mail-button"
                disabled={!this.props.selectedIncomingCandidate.projectNumber}
                onClick={() => {
                  this.props.liftUpSelectedCandidateToIncomingCandidates({
                    projectNumber: undefined,
                    applied: false
                  });
                }}
              >
                <i className="material-icons">delete</i>
              </IconButton>
            </Tooltip>
          </div>
          <div className="col-6 text-right">
            <Button
              variant="contained"
              className="company-mark-button"
              color="primary"
              onClick={() => this.setState({ showAddCandidateToProjectPopUp: true })}
            >
              {TEXT.incomingCandidateDetails.addCandidateToProject}
              <ProjectIcon />
            </Button>
            <Button
              variant="contained"
              className="company-mark-button mt-3 mb-3"
              color="primary"
              onClick={() => this.setState({ showAddCandidateToProjectAppliedPopUp: true })}
            >
              {TEXT.incomingCandidateDetails.addCandidateToProjectApplied}
              <ProjectIcon />
            </Button>
          </div>

          <div className="col-12 text-right" />
        </div>
        <Divider />
        <div className="row">
          <div className="col-12 mt-3 mb-3">
            <GenericForm
              key={this.props.selectedIncomingCandidate.id + new Date().getTime()}
              formFields={this.generateFormFields()}
              handleSubmit={this.handleConfirmButton}
              handleSaveAndContinue={this.handleCompleteNowButton}
              buttonSaveText={TEXT.incomingCandidates.buttonNext}
              buttonSaveAndContinueText={TEXT.incomingCandidates.buttonCompleteNow}
            />

            {this.renderAddCandidateToProjectPopUp()}
          </div>
        </div>
      </div>
    );
  }

  render() {
    console.log('incoming', this.props.selectedIncomingCandidate);
    return (
      <Paper>
        <div className="container pt-3">
          <h2>{TEXT.incomingCandidates.details}</h2>
          <Dropzone onDrop={this.onImageDrop}>
            {({
              getRootProps,
              getInputProps,
              isDragActive,
              // isDragAccept,
              isDragReject
              // acceptedFiles,
              // rejectedFiles
            }) => {
              let styles = { ...baseStyle };
              styles = isDragActive ? { ...styles, ...activeStyle } : styles;
              styles = isDragReject ? { ...styles, ...rejectStyle } : styles;

              return (
                <div
                  {...getRootProps()}
                  className={classNames('dropzone', {
                    'dropzone--isActive': isDragActive
                  })}
                  style={styles}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p className="text-center">Bilder hier ablegen</p>
                  ) : (
                    <p className="text-center"> Kandidatenbild hier ablegen.</p>
                  )}
                  {this.state.pictureErrorMessage.length > 0 && <p className="text-center">{this.state.pictureErrorMessage}</p>}
                  {this.state.pictureLoading ? <p>fancyLoadingIndicator</p> : this.renderCandidateImage()}
                </div>
              );
            }}
          </Dropzone>

          {this.renderInputFields()}
        </div>
      </Paper>
    );
  }
}

IncomingCandidateDetails.defaultProps = {};
IncomingCandidateDetails.propTypes = {
  selectedIncomingCandidate: PropTypes.object.isRequired,
  selectedDuplicateCandidate: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  liftUpSelectedCandidateToIncomingCandidates: PropTypes.func.isRequired,
  handleRemoveCandidate: PropTypes.func.isRequired
};
export default IncomingCandidateDetails;
