import React, { useState, useEffect, useCallback } from 'react';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { Switch, ButtonGroup, Button, Box } from '@material-ui/core';
import PopUp from '../../components/PopUp';
import CounterPartPopUpPropTypes from './propTypes';
import SafeMuiDatatable from '../../generic_components/SafeMuiDatatable';
import * as CONSTANTS from '../../constants';
import TEXT from '../../text';
import { updateAccessibleProjectListForCounterPart, getAccessibleProjectListForCounterPart } from '../api/companyArchiveAPI';

const allCheckContainerStyle = {
  textAlign: 'right',
  paddingBottom: '10px'
};

const theme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        width: '1286px',
        maxWidth: 'calc(100vw - 60px) !important',
        height: '698px', // height of all elements in popup
        maxHeight: 'calc(100vh - 60px) !important',
        '& .container': {
          paddingLeft: 0,
          paddingRight: 0
        }
      }
    }
  }
});

const dataTableTheme = createTheme({
  overrides: {
    MUIDataTable: {
      responsiveScrollMaxHeight: {
        maxHeight: '400px',
        height: '100vh'
      }
    }
  }
});

const UnlockProjectsCounterPartPopUp = ({ open, onClose, afterSubmit, projects, counterPart }) => {
  const [mappedProjects, setMappedProjects] = useState([]);
  const [initialProjects, setInitialProjects] = useState([]);

  const [addedProjects, setAddedProjects] = useState([]);
  const [removedProjects, setRemovedProjects] = useState([]);

  function sortByDate(a, b) {
    if (a.creationDate > b.creationDate) {
      return -1;
    }
    if (a.creationDate < b.creationDate) {
      return 1;
    }
    return 0;
  }

  const handleAddProjects = useCallback(
    newProjects => {
      if (removedProjects.some(p => p === newProjects.find(n => n === p))) {
        setRemovedProjects(prev => prev.filter(p => p !== newProjects.find(n => n === p)));
        return;
      }
      setAddedProjects(prev => [...prev, ...newProjects]);
    },
    [removedProjects]
  );

  const handleRemoveProjects = useCallback(
    removeProjects => {
      if (addedProjects.some(p => removeProjects.find(r => r === p))) {
        setAddedProjects(prev => [...prev.filter(p => p !== removeProjects.find(r => r === p))]);
      } else {
        setRemovedProjects(prev => [...prev, ...removeProjects]);
      }
    },
    [addedProjects]
  );

  const prepareDataForTable = (data, accessibleProjects) => {
    const formattedData = [];
    const initialData = [];
    if (data) {
      data.sort(sortByDate);
      data.forEach(entry => {
        if (entry.status === 'In Bearbeitung') {
          formattedData.push([
            entry.id,
            entry.projectNumber,
            entry.companyName,
            entry.jobTitle1,
            entry.postalCode,
            CONSTANTS.isLienert ? entry.location : entry.city,
            entry.status,
            [entry.id, accessibleProjects.filter(project => project._id === entry.id).length > 0]
          ]);
          initialData.push({ id: entry.id, status: accessibleProjects.filter(project => project._id === entry.id).length > 0 });
        }
      });
    }
    setMappedProjects(formattedData);
    setInitialProjects(initialData);
  };

  const loadAccessibleProjectList = async () => {
    try {
      const accessibleProjectsResponse = await getAccessibleProjectListForCounterPart(counterPart.id);
      prepareDataForTable(projects, accessibleProjectsResponse.projects);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadAccessibleProjectList();
  }, []);

  const handleProjectAccessSwitcher = (_, value) => {
    setMappedProjects(current =>
      current.map(project => {
        const changedProject = project;
        if (project[0] === value[0]) {
          changedProject[7][1] = !project[7][1];
          const p = initialProjects.find(i => i.id === value[0]);
          if (!p) {
            return changedProject;
          }
          if (!p.status && project[7][1]) {
            handleAddProjects([p.id]);
          } else {
            handleRemoveProjects([p.id]);
          }

          if (p.status && project[7][1]) {
            handleAddProjects([p.id]);
          }
        }
        return changedProject;
      })
    );
  };

  const handleSubmit = async () => {
    const unlockedProjects = [];
    mappedProjects.forEach(project => {
      if (project[7][1]) {
        unlockedProjects.push(project[0]);
      }
    });

    try {
      await updateAccessibleProjectListForCounterPart(
        {
          add: addedProjects,
          remove: removedProjects
        },
        counterPart.id
      );
    } catch (e) {
      console.log(e);
    }

    afterSubmit();
  };

  const handleCheckAll = checkAll => {
    setMappedProjects(current =>
      current.map(project => {
        const changedProject = project;
        const id = changedProject[0];
        const c = initialProjects.find(i => i.id === id);
        if (checkAll && !project[7][1]) {
          handleAddProjects([c.id]);
        } else if (!checkAll && project[7][1]) {
          handleRemoveProjects([c.id]);
        }
        changedProject[7][1] = checkAll;
        return changedProject;
      })
    );
  };

  const columns = [
    {
      name: 'ID',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    { name: TEXT.companyArchive.unlockProjectsForCounterPart.projectTable.columnNames.projectNumber },
    { name: TEXT.companyArchive.unlockProjectsForCounterPart.projectTable.columnNames.companyName },
    { name: TEXT.companyArchive.unlockProjectsForCounterPart.projectTable.columnNames.jobTitle },
    { name: TEXT.companyArchive.unlockProjectsForCounterPart.projectTable.columnNames.postalCode },
    { name: TEXT.companyArchive.unlockProjectsForCounterPart.projectTable.columnNames.city },
    { name: TEXT.companyArchive.unlockProjectsForCounterPart.projectTable.columnNames.status },
    {
      name: TEXT.companyArchive.unlockProjectsForCounterPart.projectTable.columnNames.unlockProject,
      options: {
        sort: false,
        customBodyRender: value => (
          <Switch checked={value[1]} onChange={event => handleProjectAccessSwitcher(event, value)} color="primary" />
        )
      }
    }
  ];

  return (
    <MuiThemeProvider theme={theme}>
      <PopUp
        open={open}
        title={TEXT.companyArchive.unlockProjectsForCounterPart.popUp.title}
        closeText={TEXT.companyArchive.unlockProjectsForCounterPart.popUp.closeText}
        saveText={TEXT.companyArchive.unlockProjectsForCounterPart.popUp.saveText}
        onClose={onClose}
        onSubmit={handleSubmit}
      >
        <Box style={allCheckContainerStyle}>
          <ButtonGroup variant="text" color="primary" aria-label="text primary button group">
            <Button onClick={() => handleCheckAll(false)}>{TEXT.companyArchive.unlockProjectsForCounterPart.unselectAllButton}</Button>
            <Button onClick={() => handleCheckAll(true)}>{TEXT.companyArchive.unlockProjectsForCounterPart.selectAllButton}</Button>
          </ButtonGroup>
        </Box>
        <SafeMuiDatatable
          title={TEXT.companyArchive.unlockProjectsForCounterPart.projectTable.title}
          tableName="counterPartProjectAccessTable"
          data={mappedProjects}
          columns={columns}
          muiTheme={dataTableTheme}
        />
      </PopUp>
    </MuiThemeProvider>
  );
};

UnlockProjectsCounterPartPopUp.propTypes = CounterPartPopUpPropTypes;

export default UnlockProjectsCounterPartPopUp;
