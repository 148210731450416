import * as CONSTANTS from '../../constants';
import { post } from '../../utils/apiUtils';

// getFolderIntranetURL;
// createFolderIntranetURL;
// deleteFolderIntranetURL;
// renameFolderIntranetURL;
// getFileIntranetURL;
// deleteFileIntranetURL;
// createFileIntranetURL;

export function getFolder(folderId) {
  const payload = { folderId };

  return post(CONSTANTS.getFolderIntranetURL, payload);
}
export function createFolder(name, parentId) {
  const payload = { name, parentId };
  console.log('create Folder ', name, 'parent', parentId);
  return post(CONSTANTS.createFolderIntranetURL, payload);
}

export function deleteFolder(folderId) {
  const payload = { folderId };

  return post(CONSTANTS.deleteFolderIntranetURL, payload);
}

export function renameFolder(newName, folderId) {
  const payload = { newName, folderId };

  return post(CONSTANTS.renameFolderIntranetURL, payload);
}

export function getFile(fileId, fileName) {
  const payload = { fileId, fileName };

  return post(CONSTANTS.getFileIntranetURL, payload);
}

export function deleteFile(fileId, folderId, fileName) {
  const payload = { fileId, folderId, fileName };

  return post(CONSTANTS.deleteFileIntranetURL, payload);
}

export function createFile(folderId, file) {
  const request = new FormData();

  request.append('fileToUpload', file);
  request.append('folderId', folderId);

  return post(CONSTANTS.createFileIntranetURL, request);
}

/**
 * This will handle the api call for creating a link inside a folder
 *
 * @param {string} folderId
 * @param {{name: string, url: string}} linkData
 * @returns {Promise<{linkId: string}>}
 */
export function createLink(folderId, linkData) {
  const payload = { folderId, linkData };
  return post(CONSTANTS.createLinkIntranetURL, payload);
}

/**
 * This will handle the api call for updating a link inside a folder
 *
 * @param {string} folderId
 * @param {string} linkId
 * @param {{name: string, url: string}} linkData
 * @returns {Promise<void>}
 */
export function updateLink(folderId, linkId, linkData) {
  const payload = { folderId, linkId, linkData };
  return post(CONSTANTS.updateLinkIntranetURL, payload);
}
/**
 * This will handle the api call for updating a link inside a folder
 *
 * @param {string} folderId
 * @param {string} linkId
 * @returns {Promise<void>}
 */
export function deleteLink(folderId, linkId) {
  const payload = { folderId, linkId };
  return post(CONSTANTS.deleteLinkIntranetURL, payload);
}
