import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import GenericFormPopUp from '../generic_components/GenericFormPopUp';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT_GENERAL from '../text';

import TEXT from './staticContent/text';
import { staticResources } from './staticContent/ActionProtocolCreationPopUpDropDowns';

function prepareEmployeesKeyValuePairs(employees) {
  const mappedDropDownEntries = [];
  if (employees && employees.length > 0) {
    employees.forEach(employee => {
      if (employee.lastname) {
        const value = employee.id;
        const label = `${employee.firstname || ''} ${employee.lastname}`;
        const mappedEmployee = { value, label };
        mappedDropDownEntries.push(mappedEmployee);
      }
    });
  }
  return mappedDropDownEntries;
}

class ProjectActionProtocolUpdatePopUpContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.form = this.generateFormFields();

    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  updateAPICall = updatedData => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updateProjectSubSchemaURL;

    const payload = {
      projectId: this.props.projectId,
      subSchemaName: 'actionProtocolEntries',
      subSchemaId: this.props.entryId,
      subSchemaUpdatedData: updatedData
    };
    if (Object.keys(updatedData).length > 0) {
      console.log(`API ${url} request`, payload);
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(payload),
        timeout: CONFIG.ajaxTimeout
      })
        .done((responseBody, status) => {
          console.log(`API /${url} response`, responseBody, status);
          this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.updateSuccess);
          this.props.refreshProject();
          this.props.handleClose();
        })
        .fail(err => {
          this.errorHandling(err, url);
        });
    } else {
      this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.noUpdate);
    }
  };

  generateFormFields() {
    // console.log('DEBUG contactDate', this.props.data.contactDate);
    console.log('DEBUG this.props.data.doneByUser', this.props.data.doneByUser);
    const forms = {
      formFields: {
        type: {
          type: 'dropDown',
          value: this.props.data.type,
          content: staticResources.dropDowns.type.content,
          required: true,
          label: TEXT.actionProtocolCreationPopUp.labels.type,
          additionalProps: { autofocus: true }
          // required: false
        },
        date: {
          type: 'date',
          value: UTILS.formatDateForDateField(this.props.data.date),
          validator: 'isText',
          required: true,
          label: TEXT.actionProtocolCreationPopUp.labels.date
        },
        doneByUser: {
          value: this.props.data.doneByUser ? this.props.data.doneByUser.id : '',
          type: 'dropDown',
          content: prepareEmployeesKeyValuePairs(this.props.employees),
          required: true,
          label: TEXT.actionProtocolCreationPopUp.labels.doneByUser,
          customClass: 'col-12 mb-1 mt-1'
        },
        description: {
          value: this.props.data.description,
          // validator: 'isText',
          required: true,
          label: TEXT.actionProtocolCreationPopUp.labels.description,
          customClass: 'col-12 mb-1 mt-1'
        }
      }
    };

    return forms;
  }

  render() {
    this.form = this.generateFormFields();
    console.log('DEBUG this.props.data', this.props.data);
    return (
      <GenericFormPopUp
        open={this.props.open}
        headlineText="Eintrag ändern"
        // key="hallo"
        genericFormProps={{
          formFields: this.form.formFields,
          isUpdate: true,
          handleSubmit: this.updateAPICall,
          // initialData: this.props.data,
          handleClose: this.props.handleClose

          // buttonCancelText: TEXT.projectDetailsUpdatePopUp.buttons.cancel,
          // buttonSaveText: TEXT.projectDetailsUpdatePopUp.buttons.save
        }}
      />
    );
  }
}
ProjectActionProtocolUpdatePopUpContainer.defaultProps = {
  // isUpdate: false,
  data: {}
};

ProjectActionProtocolUpdatePopUpContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,

  // data
  projectId: PropTypes.string.isRequired,
  entryId: PropTypes.string.isRequired,
  data: PropTypes.object,
  // snackBar
  handleSnackbarOpen: PropTypes.func.isRequired,
  refreshProject: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired
};

export default ProjectActionProtocolUpdatePopUpContainer;
