import * as CONSTANTS from '../../constants';
import { post } from '../../utils/apiUtils';

export function getProjectStatusReport(projectId) {
  const payload = { projectId };
  return post(CONSTANTS.getProjectStatusReportURL, payload);
}

export function getProjectAdvertDocumentById(projectId) {
  const payload = { projectId };

  return post(CONSTANTS.getProjectAdvertDocumentURL, payload);
}

export function updateProjectCustomerInformations(projectId, updateData) {
  const payload = {
    projectId,
    updateData: {
      customerInformation: updateData
    }
  };
  return post(CONSTANTS.updateProjectURL, payload);
}

export function removePotentialCandidate(projectId, potentialCandidateId, removeFrom) {
  const payload = {
    projectId,
    potentialCandidateId,
    removeFrom
  };

  return post(CONSTANTS.removePotentialCandidateURL, payload);
}

/**
 * Function is called in the ProjectArchive > ProjectCustomerInfoForm on the Download Button
 *
 * @param {string} projectId
 * @returns Promise<{buffer: string}> string is base64 encoded
 */
export function getRequirementsPDF(projectId) {
  return post(CONSTANTS.getRequirementsDocument, { projectId });
}

export function updatePotentialCandidate(projectId, potentialCandidateId, updatedData) {
  const payload = {
    projectId,
    potentialCandidateId,
    updatedData
  };

  return post(CONSTANTS.updatePotentialCandidateURL, payload);
}

export function updatePotentialCandidates(candidatesArray) {
  const payload = [];
  candidatesArray.forEach(candidate => {
    payload.push({
      potentialCandidateId: candidate.potentialCandidateId,
      projectId: candidate.projectId,
      updateData: candidate.updatedData
    });
  });
  return post(CONSTANTS.updatePotentialCandidatesURL, payload);
}

/**
 *
 * @param {string} candidateId
 * @param {string} fileName
 * @param {boolean} dossier
 * @return {Promise<GetCandidateLienertDossierDTO>}
 */
export function getCandidateLienertDossier(candidateId, fileName, dossier) {
  const payload = {
    candidateId,
    fileName,
    dossier
  };

  return post(CONSTANTS.getCandidateLienertDossierURL, payload);
}

/**
 * Publish stepstone project
 * @param {string} projectId
 * @param {boolean} value
 * @returns {Promise<{success: boolean}>}
 */
export function updateProjectStatusStepstone(projectId, value) {
  const payload = {
    projectId,
    value
  };

  return post(CONSTANTS.updateProjectStatusStepstoneURL, payload);
}

export function createProjectLink(projectId, linkData) {
  const payload = {
    projectId,
    linkData
  };

  return post(CONSTANTS.createProjectLinkURL, payload);
}

export function updateProjectLink(projectId, linkId, linkData) {
  const payload = {
    projectId,
    linkData,
    linkId
  };

  return post(CONSTANTS.updateProjectLinkURL, payload);
}

export function deleteProjectLink(projectId, linkId) {
  const payload = {
    linkId,
    projectId
  };

  return post(CONSTANTS.deleteProjectLinkURL, payload);
}
