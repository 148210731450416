import validator from 'validator';
import TEXT from '../text';

export const isEmail = {
  isValid: value => {
    if (typeof value !== 'string') {
      return false;
    }
    return validator.isEmail(value);
  },
  errorText: TEXT.validationErrorMessages.isEmail
};
export const isText = {
  isValid: value => {
    const reg = RegExp(
      // check with https://regex101.com/
      /^"[A-Za-z0-9\s&\-–öäüÖÄÜßÿćčž()%!?,\.:\/\*#\+À-ÿćčšśž']]*"$|^((?!\+)(?!\s))[A-Za-z0-9\s&"\-–öäüÖÄÜß()%!?,\.:\/\*#\+À-ÿćčšśž']*$/
    );
    return reg.test(value);
  },
  errorText: TEXT.validationErrorMessages.isText
};
export const isCharsOnly = {
  isValid: value => {
    const reg = RegExp(/^[A-Za-z\s&öäüÖÄÜß'’\-,À-ÿćčšśž'\.]*$/);
    return reg.test(value);
  },
  errorText: TEXT.validationErrorMessages.isText
};
export const isNumerical = {
  isValid: value => {
    const reg = RegExp(/^[0-9]*$/);
    return reg.test(value);
  },
  errorText: TEXT.validationErrorMessages.isNumerical
};
export const isUrl = {
  isValid: value => {
    const reg = RegExp(
      /[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
    );
    return reg.test(value);
  },
  errorText: TEXT.validationErrorMessages.isUrl
};
export const isPostalCode = {
  isValid: value => {
    const reg = RegExp(/^[0-9]{4,6}$/);
    return reg.test(value);
  },
  errorText: TEXT.validationErrorMessages.isPostalCode
};
export const isPhoneNumber = {
  isValid: value => {
    const reg = RegExp(/^[+-]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
    return reg.test(value);
  },
  errorText: TEXT.validationErrorMessages.isPhoneNumber
};
export const isPhoneNumberFullyQualified = {
  isValid: value => {
    const reg = RegExp(/^[+-]+[(]{0,1}[1-9]+[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
    return reg.test(value);
  },
  errorText: TEXT.validationErrorMessages.isPhoneNumber
};
export const isCurrency = {
  isValid: value => {
    const reg = RegExp(/^[0-9]+(\.[0-9]{1,2})?$/);
    return reg.test(value);
  },
  errorText: TEXT.validationErrorMessages.isCurrency
};
export const isDate = {
  // validator for date picker field
  isValid: value => {
    const reg = RegExp(/^\d{4}-\d{2}-\d{2}$/);
    return reg.test(value);
  },
  errorText: TEXT.validationErrorMessages.isDate
};
export const none = {
  isValid: () => true,
  errorText: ''
};
