import { useCallback, useMemo } from 'react';

export const NotificationType = {
  NEW_FEATURE: 'newFeature',
  COUNTERPART_REMINDER: 'counterPartReminder',
  CANDIDATE_STATUS_EXPIRED: 'candidateStatusExpired',
  PROJECT_STATUS_CHANGED: 'projectStatusChanged',
  CANDIDATE_DOSSIER_RELEASED: 'candidateDossierReleased',
  CANDIDATE_ADD_TO_PROJECT: 'candidateAddToProject',
  CLIENT_RELEASE_JOB: 'clientReleaseJob',
  CLIENT_READ_DOSSIER: 'clientReadDossier',
  CLIENT_ACCEPT_INTERVIEW: 'clientUpdateAcceptInterview',
  CLIENT_WANTS_CONTRACT_OFFER: 'clientWantsContractOffer',
  PROJECT_JOB_EXPIRED: 'projectJobsExpired'
};

const MAP_INTERVIEW_TYPE = {
  first: 'Erstgespräch',
  second: 'Zweitgespräch'
};

const MAP_INTERVIEW_STATUS = {
  accepted: 'Angenommen',
  declined: 'Abgelehnt'
};

const MAP_JOB_TYPE = {
  jobPosting: 'Homepage',
  stepstone: 'Stepstone'
};

const useNotification = ({ notifications }) => {
  const mapNotificationType = useCallback(
    /**
     * @param {Record<string, any>} notificationType
     * @returns {{ title: string ,description: string, redirectUrl: string | undefined}}
     */
    notification => {
      const { notificationObject } = notification;
      switch (notification.type) {
        case NotificationType.NEW_FEATURE:
          return {
            title: 'Neue Features',
            description: 'Ein Update mit neuen Funktionen wurde veröffentlicht',
            type: notification.type
          };

        case NotificationType.COUNTERPART_REMINDER:
          return {
            title: 'Wiedervorlage',
            description: (notificationObject.counterPartReminder.company || {}).name || '(Unternehmen nicht gefunden)',
            redirectUrl: `/companies?id=${notificationObject.counterPartReminder.company.id}`
          };

        case NotificationType.CANDIDATE_STATUS_EXPIRED:
          return {
            title: 'Kandidat im Gespräch ist abgelaufen',
            description: notificationObject.candidateStatusExpired.candidate
              ? (notificationObject.candidateStatusExpired.candidate.firstName &&
                  `${notificationObject.candidateStatusExpired.candidate.firstName} `) +
                (notificationObject.candidateStatusExpired.candidate.lastName &&
                  `${notificationObject.candidateStatusExpired.candidate.lastName} `)
              : '(Kandidat nicht gefunden)',
            redirectUrl:
              notificationObject.candidateStatusExpired && notificationObject.candidateStatusExpired.candidate
                ? `/candidates?id=${notificationObject.candidateStatusExpired.candidate.id}`
                : undefined
          };
        case NotificationType.PROJECT_STATUS_CHANGED:
          return {
            title: `Projekt-Status wurde auf '${notificationObject.projectStatusChanged.status}' gesetzt`,
            description: notificationObject.projectStatusChanged.project
              ? `${notificationObject.projectStatusChanged.project.jobTitle1} (${
                  notificationObject.projectStatusChanged.project.companyName
                })`
              : '(Projekt nicht gefunden)',
            redirectUrl:
              notificationObject.projectStatusChanged && notificationObject.projectStatusChanged.project
                ? `/projects?id=${notificationObject.projectStatusChanged.project.id}`
                : undefined
          };

        case NotificationType.CLIENT_RELEASE_JOB:
          return {
            title: 'Anzeige vom Kunden freigegeben',
            description: notificationObject.clientReleaseJob.project.jobTitle1
              ? `Anzeige ${notificationObject.clientReleaseJob.project.jobTitle1} wurde vom Kunden freigegeben`
              : 'Projekt nicht gefunden',
            redirectUrl:
              notificationObject.clientReleaseJob && notificationObject.clientReleaseJob.project
                ? `/projects?id=${notificationObject.clientReleaseJob.project.id}&t=1`
                : undefined
          };
        case NotificationType.CANDIDATE_ADD_TO_PROJECT:
          return {
            title: 'Neue Bewerbung',
            description:
              notificationObject.addCandidateToProject &&
              notificationObject.addCandidateToProject.project &&
              notificationObject.addCandidateToProject.project.jobTitle1 &&
              notificationObject.addCandidateToProject.candidate
                ? `Neue Bewerbung von ${notificationObject.addCandidateToProject.candidate.firstName} ${
                    notificationObject.addCandidateToProject.candidate.lastName
                  } für Auftrag ${notificationObject.addCandidateToProject.project.company.name} - ${
                    notification.notificationObject.addCandidateToProject.project.jobTitle1
                  } liegt vor.`
                : '(Projekt oder Kandidaten nicht gefunden)',
            redirectUrl:
              notificationObject.addCandidateToProject && notificationObject.addCandidateToProject.project
                ? `/projects?id=${notificationObject.addCandidateToProject.project.id}&cid=${
                    notificationObject.addCandidateToProject.candidate.id
                  }&t=3`
                : undefined
          };
        case NotificationType.CLIENT_READ_DOSSIER:
          return {
            title: 'Kunde hat Dossier gelesen',
            description:
              notificationObject.clientReadDossier.project.jobTitle1 && notificationObject.clientReadDossier.candidate
                ? `Kunde ${notificationObject.clientReadDossier.project.company.name} hat im Projekt ${
                    notificationObject.clientReadDossier.project.jobTitle1
                  } das Dossier von ${notificationObject.clientReadDossier.candidate.firstName} ${
                    notificationObject.clientReadDossier.candidate.lastName
                  } gelesen`
                : '(Projekt nicht gefunden)',
            redirectUrl:
              notificationObject.clientReadDossier && notificationObject.clientReadDossier.project
                ? `/projects?id=${notificationObject.clientReadDossier.project.id}&t=7`
                : undefined
          };
        case NotificationType.CLIENT_ACCEPT_INTERVIEW:
          return {
            title: 'Kunde Kandidatengespräch',
            description: notificationObject.clientUpdateAcceptInterview.project.jobTitle1
              ? `Kunde ${notificationObject.clientUpdateAcceptInterview.project.company.name} hat im Project ${
                  notificationObject.clientUpdateAcceptInterview.project.jobTitle1
                } das ${MAP_INTERVIEW_TYPE[notificationObject.clientUpdateAcceptInterview.interviewType]} mit ${
                  notificationObject.clientUpdateAcceptInterview.candidate.firstName
                } ${notificationObject.clientUpdateAcceptInterview.candidate.lastName} ${
                  MAP_INTERVIEW_STATUS[notificationObject.clientUpdateAcceptInterview.interviewStatus]
                }`
              : '(Projekt nicht gefunden)',
            redirectUrl:
              notificationObject.clientUpdateAcceptInterview && notificationObject.clientUpdateAcceptInterview.project
                ? `/projects?id=${notificationObject.clientUpdateAcceptInterview.project.id}&t=7`
                : undefined
          };
        case NotificationType.CLIENT_WANTS_CONTRACT_OFFER:
          return {
            title: 'Kunde gibt Vertragsangebot',
            description: notificationObject.clientWantsContractOffer.project.jobTitle1
              ? `Kunde ${notificationObject.clientWantsContractOffer.project.company.name} hat im Project ${
                  notificationObject.clientWantsContractOffer.project.jobTitle1
                } ein Vertragsangebot bestätigt!`
              : '(Projekt nicht gefunden)',
            redirectUrl:
              notificationObject.clientWantsContractOffer && notificationObject.clientWantsContractOffer.project
                ? `/projects?id=${notificationObject.clientWantsContractOffer.project.id}&t=7`
                : undefined
          };
        case NotificationType.PROJECT_JOB_EXPIRED:
          return {
            title: 'Anzeige ausgelaufen',
            description: notificationObject.projectJobsExpired.project.jobTitle1
              ? `Anzeige ${notificationObject.projectJobsExpired.project.company.name} - ${
                  notificationObject.projectJobsExpired.project.jobTitle1
                } ist auf ${MAP_JOB_TYPE[notificationObject.projectJobsExpired.type]} ausgelaufen!`
              : '(Projekt nicht gefunden)',
            redirectUrl:
              notificationObject.projectJobsExpired && notificationObject.projectJobsExpired.project
                ? `/projects?id=${notificationObject.projectJobsExpired.project.id}&t=1`
                : undefined
          };
        default:
          return {
            type: notification.type,
            title: 'Fehler',
            description: 'Unbekannter Nachrichtentyp'
          };
      }
    },
    []
  );

  const mappedNotification = useMemo(() => {
    if (!Array.isArray(notifications)) {
      return [];
    }

    return notifications.map(notification => {
      const { description, redirectUrl, title } = mapNotificationType(notification);
      return [notification.id, notification.isRead, notification.isRead, notification.triggerDate, title, description, redirectUrl];
    });
  }, [notifications, mapNotificationType]);

  return {
    mappedNotification
  };
};

export default useNotification;
