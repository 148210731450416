import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import AddAttachmentDialog from './AddAttachmentDialog';

class AddAttachment extends PureComponent {
  render() {
    return (
      <Fragment>
        <Button
          onClick={this.props.openAddAttachmentDialog}
          color="secondary"
          variant="contained"
          style={{ fontSize: '13px', margin: '12px 24px' }}
        >
          <i className="material-icons pr-1">add</i>
          Anhang hinzufügen
        </Button>
        <AddAttachmentDialog
          open={this.props.showAddAttachmentDialog}
          handleClose={this.props.closeAddAttachmentDialog}
          addAttachment={this.props.addAttachment}
        />
      </Fragment>
    );
  }
}

AddAttachment.propTypes = {
  addAttachment: PropTypes.func.isRequired,
  showAddAttachmentDialog: PropTypes.bool.isRequired,
  openAddAttachmentDialog: PropTypes.func.isRequired,
  closeAddAttachmentDialog: PropTypes.func.isRequired
};

export default AddAttachment;
