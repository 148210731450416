import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import { Document, Page } from 'react-pdf';
import CircularProgress from '@material-ui/core/CircularProgress';
import '../generic_components/DocumentPanel.css';
import List from '@material-ui/core/List';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';
import * as CONSTANTS from '../constants';
import DropContainer from '../generic_components/DropContainer';
import * as UTILS from '../utils/utilFunctions';
import withUser from '../utils/withUser';

class AdditionalDocumentsPanel extends PureComponent {
  state = {
    numPages: null,
    currentDocName: '',
    currentDoc: '',
    currentDocFileType: '',
    documentList: [],
    isLoadingDocument: false,
    pdfErrorMessage: ''
  };

  constructor(props) {
    super(props);
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  updatePotentialCandidateState = () => {
    const index = this.props.selectedProject.potentialCandidates.findIndex(
      potentialCandidate => potentialCandidate.id === this.props.selectedPotentialCandidate.id
    );
    const documentList = this.state.documentList;
    const selectedPotentialCandidate = JSON.parse(JSON.stringify(this.props.selectedPotentialCandidate));
    selectedPotentialCandidate.additionalDocuments = documentList;

    const updatedSelectedProject = JSON.parse(JSON.stringify(this.props.selectedProject));
    updatedSelectedProject.potentialCandidates[index] = selectedPotentialCandidate;
    this.props.liftUpSelectedProject(updatedSelectedProject);
  };

  componentDidMount() {
    if (this.props.selectedPotentialCandidate.additionalDocuments && this.props.selectedPotentialCandidate.additionalDocuments.length > 0) {
      this.setState({
        documentList: this.props.selectedPotentialCandidate.additionalDocuments
      });
    }
  }

  /**
   * @param fileName
   * @param documentId
   */
  handleDeleteClick = (fileName, documentId) => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    this.props
      .handleDelete(fileName, documentId)
      .then(response => {
        if (response.status === 'success') {
          let currentDocIsDeletedDoc;
          if (fileName === this.state.currentDocName) {
            currentDocIsDeletedDoc = true;
          }
          this.setState(
            prevState => ({
              documentList: prevState.documentList.filter(doc => doc._id !== documentId),
              currentDoc: currentDocIsDeletedDoc ? '' : prevState.currentDoc,
              currentDocFileType: currentDocIsDeletedDoc ? '' : prevState.currentDocFileType,
              currentDocName: currentDocIsDeletedDoc ? '' : prevState.currentDocName
            }),
            () => {
              this.updatePotentialCandidateState();
            }
          );
        }
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .catch(err => {
        this.errorHandling(err, 'deleteProjectCandidateAdditionalDocument');
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      });
  };

  onDrop = acceptedFiles => {
    let foundFileWithSameName = false;
    if (acceptedFiles) {
      acceptedFiles.forEach(file => {
        this.state.documentList.forEach(doc => {
          if (file.name.toLowerCase() === doc.documentName.toLowerCase()) {
            foundFileWithSameName = true;
            this.props.handleSnackbarOpen(`Es ist bereits ein Dokument mit dem Namen ${file.name} vorhanden!`);
          }
        });
      });
    }
    if (foundFileWithSameName) {
      return;
    }
    this.setState({ pdfErrorMessage: '', isLoadingDocument: true });
    this.props
      .handleUpload(acceptedFiles)
      .then(response => {
        this.setState(
          prevState => ({
            isLoadingDocument: false,
            documentList: [...prevState.documentList, ...response.documentList]
          }),
          () => {
            this.updatePotentialCandidateState();
          }
        );
      })
      .catch(err => {
        const ignoreErrorCodes = [0, 413, 415];
        if (err.status === 413 || err.status === 0) {
          this.setState({
            pdfErrorMessage: 'Datei zu groß (maximale Größe 16mb)',
            isLoadingDocument: false
          });
        }
        if (err.status === 415) {
          this.setState({
            pdfErrorMessage: 'Nicht unterstütztes Dateiformat',
            isLoadingDocument: false
          });
        }
        console.log(err);
        this.errorHandling(err, CONSTANTS.uploadProjectCandidateAdditionalDocumentsURL, null, ignoreErrorCodes);
      });
  };

  /**
   * Calls the getDocumentApi and sets the state of the component
   * @param fileName
   * @param documentId
   */
  getDocumentApiCall = (fileName, documentId) =>
    this.props
      .getDocumentApiCall(fileName, documentId)
      .then(response => {
        this.setState({
          currentDoc: response.contentUrl,
          currentDocName: fileName,
          currentDocFileType: response.contentType
        });
      })
      .catch(err => {
        console.log(`Error ${err.status}`, err);
      });

  handleListItemClick = (fileName, documentId) => {
    if (this.state.currentDocName !== fileName) {
      this.setState({ isLoadingDocument: true });
      this.getDocumentApiCall(fileName, documentId).then(() => {
        this.setState({ isLoadingDocument: false });
      });
    }
  };

  renderListItem = document => (
    <ListItem
      key={`${document.documentName}listItem`}
      button
      selected={document.documentName === this.state.currentDocName}
      onClick={() => this.handleListItemClick(document.documentName, document._id)}
    >
      <ListItemAvatar>
        <Avatar>{document.documentType ? document.documentType : <FolderIcon />}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={document.documentName}
        secondary={document.creationDate ? `Datum: ${UTILS.formatStringToDELocale(document.creationDate)}` : ''}
      />
      <ListItemSecondaryAction>
        {this.props.user.role === 'admin' && (
          <IconButton
            aria-label="Delete"
            disabled={!document._id}
            onClick={() => this.handleDeleteClick(document.documentName, document._id)}
          >
            <DeleteIcon />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );

  renderAvailableFiles() {
    return (
      <div className="text-center">
        <List dense>{this.state.documentList.map(document => this.renderListItem(document))}</List>
      </div>
    );
  }

  documentLoadingInfo() {
    return <CircularProgress size={110} thickness={3} />;
  }

  documentError() {
    return <p>Ein Fehler ist aufgetreten</p>;
  }

  documentNoData() {
    return <p>Das Dokument hat keinen Inhalt</p>;
  }

  onDocumentLoad = ({ numPages }) => {
    this.setState({ numPages, isLoadingDocument: false });
  };

  documentPreview() {
    if (this.state.currentDocFileType && this.state.currentDocFileType.toLowerCase() === 'pdf') {
      return (
        <Document
          file={this.state.currentDoc}
          loading={this.documentLoadingInfo}
          noData={this.documentNoData}
          onLoadSuccess={this.onDocumentLoad}
          error={this.documentError}
          renderMode="canvas"
        >
          {this.displayPdfDocumentPages(this.state.numPages)}
        </Document>
      );
    }
    if (
      this.state.currentDocFileType &&
      (this.state.currentDocFileType.toLowerCase() === 'jpeg' ||
        this.state.currentDocFileType.toLowerCase() === 'jpg' ||
        this.state.currentDocFileType.toLowerCase() === 'png')
    ) {
      return (
        <div className="text-center">
          <img src={this.state.currentDoc} alt="" className="img-fluid rounded" />
        </div>
      );
    }
    return <></>;
  }

  downloadFile = () => {
    const element = document.createElement('a');
    element.setAttribute('href', this.state.currentDoc);
    element.setAttribute('target', '_blank');
    element.setAttribute('download', `${this.state.currentDocName.split('.')[0]}.${this.state.currentDocFileType}`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  displayPdfDocumentPages(numOfPages) {
    const pageList = [];
    for (let i = 1; i <= numOfPages; i++) {
      pageList.push(
        <Page
          width={800}
          key={`additionalDocuments-page-${i}`}
          className="mx-auto"
          pageNumber={i}
          renderAnnotations={false}
          renderTextLayer={false}
        />
      );
    }
    return pageList;
  }

  render() {
    if (this.state.documentList && this.state.documentList.length !== 0) {
      return (
        <div className="">
          <Paper style={{ width: '100%', maxHeight: '1000px', overflow: 'auto' }}>
            <div className="col-12">
              <div className="text-right p-2">
                <DropContainer
                  onDrop={this.onDrop}
                  dropzoneErrorMessage={this.state.pdfErrorMessage}
                  // showLoadingIndicator={this.state.isLoadingDocument}
                >
                  {this.renderAvailableFiles()}
                </DropContainer>
              </div>
            </div>
            <div className="text-center col-12">
              <Button
                className="mb-3"
                variant="contained"
                color="primary"
                onClick={this.downloadFile}
                disabled={this.state.currentDoc === ''}
                download
                href=""
              >
                Download
              </Button>
              {this.state.isLoadingDocument && (
                <div className="row">
                  <div className="col-12 text-center">
                    {' '}
                    <CircularProgress size={110} thickness={3} />
                  </div>
                </div>
              )}
              {this.documentPreview()}
            </div>
          </Paper>
        </div>
      );
    }
    return (
      <div>
        <div className="sideBarWrapper m-2 p-2">
          <div className="candidateArchive-emptyPage">
            <DropContainer
              onDrop={this.onDrop}
              dropzoneErrorMessage={this.state.pdfErrorMessage}
              showLoadingIndicator={this.state.isLoadingDocument}
              disabled={this.props.disabled}
              actionDescription={this.props.disabled ? '' : 'Dateien hier ablegen'}
            >
              <div className="additionalDocumentsPanel-emptyPage">
                {this.props.disabled ? this.props.disabledInfoText : this.props.noDocumentsText}
              </div>
            </DropContainer>
          </div>
        </div>
      </div>
    );
  }

  renderDropContainer = () => (
    <div className="sideBarWrapper m-2 p-2">
      <div className="candidateArchive-emptyPage">
        <DropContainer
          onDrop={this.onDrop}
          dropzoneErrorMessage={this.state.pdfErrorMessage}
          showLoadingIndicator={this.state.isLoadingDocument}
          disabled={this.props.disabled}
          actionDescription={this.props.disabled ? '' : 'Dateien hier ablegen'}
        >
          {this.props.disabled ? this.props.disabledInfoText : this.props.noDocumentsText}
        </DropContainer>
      </div>
    </div>
  );
}

AdditionalDocumentsPanel.defaultProps = {
  disabled: false,
  disabledInfoText: 'Dokument Upload deaktiviert',
  noDocumentsText: 'Keine Dokumente vorhanden'
};

AdditionalDocumentsPanel.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  selectedPotentialCandidate: PropTypes.object.isRequired,
  liftUpSelectedProject: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  getDocumentApiCall: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  disabledInfoText: PropTypes.string,
  noDocumentsText: PropTypes.string,
  selectedProject: PropTypes.object.isRequired
};

export default withUser(AdditionalDocumentsPanel);
