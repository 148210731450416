import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Range } from 'rc-slider';

import TEXT from '../../../text';
import * as FILTER_HELPER from '../../../utils/filterHelperFunctions';

class AgeFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { range: this.props.range };
    this.onChange = this.onChange.bind(this);
    this.onAfterChange = this.onAfterChange.bind(this);
  }

  /**
   * REACT lifecycle method to update the state while receiving new props
   * @param  {object} nextProps new props
   */
  componentWillReceiveProps(nextProps) {
    this.setState({ range: nextProps.range });
  }

  /**
   * OnChange method for the AgeFilter component to update the component state while moving the handlers of the range slider
   * @param  {array} range array of values of the two handlers of slider
   */
  onChange(range) {
    this.setState({ range });
  }

  /**
   * OnAfterChange method for the AgeFilter component, calls the onFilterChange function
   * @param  {array} range array of values of the two handlers of slider
   */
  onAfterChange(range) {
    this.props.onFilterChange('age', range, FILTER_HELPER.filterFunctions.age);
  }

  render() {
    const headline = `${TEXT.filter.mainFilter.age} (${this.state.range[0]}-${this.state.range[1]}):`;
    return (
      <div className="col-12 pb-5">
        <p>{headline}</p>
        <Range
          value={this.state.range}
          step={1}
          min={15}
          max={75}
          marks={{ 15: '15', 30: '30', 45: '45', 60: '60', 75: '75' }}
          onChange={this.onChange}
          onAfterChange={this.onAfterChange}
        />
      </div>
    );
  }
}
AgeFilter.defaultProps = {};
AgeFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  range: PropTypes.arrayOf(PropTypes.number).isRequired
};
export default AgeFilter;
