import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

import PropTypes from 'prop-types';

import $ from 'jquery';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

class SaveSearchBox extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchName: ''
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
  }

  onSaveButtonClick() {
    // TODO do nothing on empty string
    const queryWithoutEmptyField = this.props.query;
    queryWithoutEmptyField.pop();
    const request = {
      query: queryWithoutEmptyField,
      ratings: this.props.ratings,
      geoLocationId: this.props.geoLocationId,
      distance: this.props.distance,
      postalCode: this.props.postalCode,
      name: this.state.searchName,
      filters: this.props.filters
    };
    // console.log('Save', request);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.saveSearchURL;
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(request),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.props.handleClose(true);
      })
      .fail(err => {
        // TODO handle Error
        this.errorHandling(err, url, this.liftUpLoggedIn);
      });
  }

  onSearchNameChange = e => {
    this.setState({
      searchName: e.target.value
    });
  };

  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="loadSearchBox-dialog-title">
        <DialogTitle id="loadSearchBox-dialog-title">{TEXT.resultPage.saveSearchBox.headline}</DialogTitle>
        <DialogContent>
          <DialogContentText>{TEXT.resultPage.saveSearchBox.description}</DialogContentText>
          <div className="row">
            <div className="col-12 pb-3 pt-1">
              <TextField
                fullWidth
                onChange={this.onSearchNameChange}
                value={this.state.searchName}
                autoFocus
                label={TEXT.resultPage.saveSearchBox.labelInputField}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.handleClose(false)} color="primary">
            {TEXT.resultPage.saveSearchBox.leftButton}
          </Button>
          <Button onClick={this.onSaveButtonClick} color="primary">
            {TEXT.resultPage.saveSearchBox.rightButton}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

SaveSearchBox.propTypes = {
  geoLocationId: PropTypes.string.isRequired,
  distance: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  postalCode: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  filters: PropTypes.object.isRequired,
  ratings: PropTypes.object.isRequired,
  query: PropTypes.array.isRequired
};
export default SaveSearchBox;
