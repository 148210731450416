import $ from 'jquery';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// generic components
import DetailSearch from '../generic_components/DetailSearch';

// static data
import generateDropDownValues from './staticContent/CandidateDetailSearchDropDown';
import { exportCandidateListByDetailSearchAPICall } from './api/candidateArchiveAPI';
// utils
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import generateCSV from '../utils/generateCSV';

class CandidateDetailSearch extends PureComponent {
  constructor(props) {
    super(props);
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.dropDownValueList = generateDropDownValues(this.props.partnersList, this.props.employeesList);
  }

  getCandidateListByDetailSearchAPICall = (detailSearchFieldList, maxAmountOfSearchResults) => {
    const url = CONSTANTS.getCandidateListByDetailSearchTermsURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const payload = { detailSearchFieldList, maxAmountOfSearchResults };
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(payload),
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(response => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.props.updateCandidateArchive(response, Number.parseInt(maxAmountOfSearchResults, 10));
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.errorHandling(err, url);
      });
  };

  /**
   * This method calls API to get candidateList by searchQuery from elasticsearch
   * Downloads a csv with the matching candidates or shows a toast if nothing found.
   * @param {*} detailSearchFieldList
   * @returns void
   */
  onExportButtonClicked = detailSearchFieldList => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    exportCandidateListByDetailSearchAPICall(detailSearchFieldList)
      .then(response => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        const encodedUri = generateCSV(response);
        if (encodedUri) {
          window.open(encodedUri);
        } else {
          this.props.handleSnackbarOpen('Keine Ergebnisse gefunden');
        }
      })
      .catch(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.errorHandling(err, CONSTANTS.exportCandidateListByDetailSearchTermsURL);
      });
  };

  render() {
    return (
      <DetailSearch
        isDrawerOpen={this.props.isDrawerOpen}
        handleDrawerClose={this.props.handleDrawerClose}
        mainSearchFieldPlaceholderText={this.props.mainSearchFieldPlaceholderText}
        dropDownOptionDataList={this.dropDownValueList}
        appBarTitle={this.props.appBarTitle}
        onSearch={this.getCandidateListByDetailSearchAPICall}
        onExport={this.onExportButtonClicked}
        detailSearchType="candidate"
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        employeesList={this.props.employeesList}
      />
    );
  }
}

CandidateDetailSearch.defaultProps = {
  mainSearchFieldPlaceholderText: 'Bitte suchen Sie nach einem Kandidaten',
  appBarTitle: 'Detailsuche für Kandidaten',
  isDrawerOpen: false,
  partnersList: []
};

CandidateDetailSearch.propTypes = {
  mainSearchFieldPlaceholderText: PropTypes.string,
  appBarTitle: PropTypes.string,
  isDrawerOpen: PropTypes.bool,
  handleDrawerClose: PropTypes.func.isRequired,
  updateCandidateArchive: PropTypes.func.isRequired,
  employeesList: PropTypes.array.isRequired,
  partnersList: PropTypes.array,
  liftUpStateToApp: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default CandidateDetailSearch;
