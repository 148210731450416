import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import LinkList from '../generic_components/LinkList/LinkList';
import { updateProjectLink, deleteProjectLink } from './api/projectArchiveAPI';
import GenericFormPopUp from '../generic_components/GenericFormPopUp';

/**
 * @typedef Props
 * @property {{url: string, name: string, id: string, partner: string}[]} [links]
 * @property {Record<string, any>} currentProject
 * @property {(newState: Record<string, any>)} liftUpSelectedProject
 * @property {(text: string) => void} handleSnackbarOpen
 */

const INIT_LINK_STATE = {
  name: null,
  id: null,
  url: null
};
/**
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
const LinkProjectPanel = ({ handleSnackbarOpen, links, liftUpSelectedProject, currentProject }) => {
  const [open, setOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState(INIT_LINK_STATE);

  const handleOnLinkUpdate = useCallback(
    /**
     *
     * @param {import('../generic_components/LinkList/LinkListItem').LinkItem} link
     */
    link => {
      setSelectedLink(link);
      setOpen(true);
    },
    []
  );

  const handleCloseDialog = useCallback(() => {
    setOpen(false);
    setSelectedLink(INIT_LINK_STATE);
  }, []);

  const handleDialogSubmit = useCallback(
    fields => {
      if (!currentProject.id) {
        return;
      }
      updateProjectLink(currentProject.id, fields.id, { name: fields.name, url: fields.url })
        .then(() => {
          // Update state links array
          const fieldsIndex = currentProject.links.findIndex(link => link.id === fields.id);
          const updatedArray = currentProject.links;
          updatedArray.splice(fieldsIndex, 1, fields);
          liftUpSelectedProject({
            links: updatedArray
          });
          handleCloseDialog();
        })
        .catch(() => {
          handleSnackbarOpen('Konnte Link nicht updaten!');
        });
    },
    [currentProject, handleCloseDialog, handleSnackbarOpen, liftUpSelectedProject]
  );

  const handleOnClickLink = useCallback(
    /**
     *
     * @param {import('../generic_components/LinkList/LinkListItem').LinkItem} link
     */
    link => {
      navigator.clipboard.writeText(link.url);
      handleSnackbarOpen('Link erfolgreich in Zwischenablage kopiert!');
    },
    [handleSnackbarOpen]
  );

  const handleOnLinkDelete = useCallback(
    /**
     *
     * @param {import('../generic_components/LinkList/LinkListItem').LinkItem} link
     */
    link => {
      if (currentProject.id) {
        deleteProjectLink(currentProject.id, link.id)
          .then(() => {
            const updatedArray = currentProject.links;
            currentProject.links.splice(currentProject.links.findIndex(currentLink => link.id === currentLink.id), 1);
            liftUpSelectedProject({
              links: updatedArray
            });
            handleSnackbarOpen('Link erfolgreich gelöscht!');
          })
          .catch(() => {
            handleSnackbarOpen('Fehler beim Löschen des Links!');
          });
      }
    },
    [currentProject.id, handleSnackbarOpen, currentProject.links, liftUpSelectedProject]
  );

  const handleOnLinkOpen = useCallback(
    /**
     *
     * @param {import('../generic_components/LinkList/LinkListItem').LinkItem} link
     */
    link => {
      window.open(link.url, '_blank');
    },
    []
  );

  const formFields = useMemo(
    () => ({
      name: {
        value: selectedLink.name,
        label: 'Link Name',
        validator: 'isText',
        required: true
      },
      url: {
        value: selectedLink.url,
        label: 'URL',
        validator: 'isUrl',
        required: true
      },
      id: {
        value: selectedLink.id,
        additionalProps: {
          hidden: true
        }
      }
    }),
    [selectedLink]
  );

  return (
    <div className="row col-12">
      <Paper style={{ width: '100%', maxHeight: '1000px', overflow: 'auto', marginTop: '10px' }}>
        <div className="col-12">
          <div className="p-2">
            <LinkList
              links={links}
              onLinkClick={handleOnClickLink}
              onLinkDelete={handleOnLinkDelete}
              onLinkUpdate={handleOnLinkUpdate}
              onLinkOpen={handleOnLinkOpen}
            />
            <GenericFormPopUp
              open={open}
              headlineText="Link anpassen"
              genericFormProps={{
                formFields,
                handleClose: handleCloseDialog,
                handleSubmit: handleDialogSubmit
              }}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};

LinkProjectPanel.propTypes = {
  links: PropTypes.array,
  currentProject: PropTypes.object.isRequired,
  liftUpSelectedProject: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};

LinkProjectPanel.defaultProps = {
  links: []
};

export default LinkProjectPanel;
