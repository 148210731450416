import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';
import flowRight from 'lodash/flowRight';

import MainSearchField from './MainSearchField';
import CompanyResult from '../companyArchive/CompanyResult';
import { getCompanyListBySearchTerm } from '../companyArchive/api/companyArchiveAPI';
import ConfirmationModal from './ConfirmationModal';

import * as UTILS from '../utils/utilFunctions';

// import TEXT from '../candidateArchive/staticContent/text';
import TEXT_GENERAL from '../text';

class CompanyFinder extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      companyList: [],
      searchFieldValidationError: false,
      showConfirmationModal: false
    };
    this.mainSearchFieldChange = flowRight(
      debounce(this.mainSearchFieldChange, 200),
      e => e.persist() || e
    );
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  isValidateSearchTerm(searchTerm) {
    if (!UTILS.isSearchtermValid(searchTerm)) {
      this.setState({ searchFieldValidationError: true });
      return false;
    }
    if (this.state.searchFieldValidationError) this.setState({ searchFieldValidationError: false });
    return true;
  }

  getCompanyListBySearchTermAPICall(searchTerm) {
    if (!this.isValidateSearchTerm(searchTerm) || !searchTerm) return;
    getCompanyListBySearchTerm(searchTerm)
      .then(responseBody => {
        const companyList = responseBody;

        this.setState({ companyList });
      })
      .catch(err => {
        this.errorHandling(err);
      });
  }

  mainSearchFieldChange = e => {
    if (e.target.value !== '') this.getCompanyListBySearchTermAPICall(e.target.value);
  };

  onCompanyRowClick = row => {
    console.log(row);
    this.setState({ showConfirmationModal: true, selectedCompany: row });
  };

  renderDescriptionText() {
    return (
      <div className="userResetConfirmation-descriptionText">
        <p>{this.props.confirmationDescription}</p>
      </div>
    );
  }

  render() {
    return (
      <div className="container">
        <MainSearchField
          placeholder={this.props.placeholder}
          onChange={this.mainSearchFieldChange}
          error={this.state.searchFieldValidationError}
          helperText={this.state.searchFieldValidationError && this.props.helperText}
          onExtendedSearchClick={() => console.log('extendedSearchClicked')}
        />
        <CompanyResult handleCompanySelected={this.onCompanyRowClick} companyList={this.state.companyList} />
        {this.state.showConfirmationModal && (
          <ConfirmationModal
            key="confirmation-addCandidateTo-Project"
            handleClose={() => this.setState({ showConfirmationModal: false })}
            open={this.state.showConfirmationModal}
            buttonPrimaryAction={() => {
              this.setState({ showConfirmationModal: false });
              this.props.handleProjectSelected(this.state.selectedCompany);
            }}
            headlineText={TEXT_GENERAL.ConfirmationModal.headlineText}
            descriptionText={this.renderDescriptionText()}
            contentText={this.state.selectedCompany[2]}
            buttonPrimaryText={TEXT_GENERAL.ConfirmationModal.buttonPrimaryText}
          />
        )}
      </div>
    );
  }
}

CompanyFinder.defaultProps = {
  placeholder: 'Bitte suchen Sie nach einem Unternehmen'
};

CompanyFinder.propTypes = {
  placeholder: PropTypes.string,
  handleProjectSelected: PropTypes.func.isRequired,
  helperText: PropTypes.string.isRequired,
  confirmationDescription: PropTypes.string.isRequired
};

export default CompanyFinder;
