import React, { Component } from 'react';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import * as CONSTANTS from '../constants';
import AdditionalDocumentsPanel from './AdditionalDocumentsPanel';
import { post } from '../utils/apiUtils';

const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

class UploadDocuments extends Component {
  TransitionSlide = React.forwardRef((props, ref) => <Slide direction="up" mountOnEnter unmountOnExit ref={ref} {...props} />);

  renderAppBar() {
    return (
      <div>
        <AppBar style={THEME.appBar.projects}>
          <Toolbar>
            <IconButton color="inherit" onClick={this.props.handlePopupClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              Dokumente
            </Typography>
          </Toolbar>
        </AppBar>
        <div style={{ height: 64 }}>{/* Shim - Not visible */}</div>
      </div>
    );
  }

  render() {
    return (
      <Dialog fullScreen open={this.props.open} onClose={this.props.handlePopupClose} TransitionComponent={this.TransitionSlide}>
        <div>
          {this.renderAppBar()}
          <AdditionalDocumentsPanel
            handleSnackbarOpen={this.props.handleSnackbarOpen}
            liftUpSelectedProject={this.props.liftUpSelectedProject}
            liftUpStateToApp={this.props.liftUpStateToApp}
            selectedProject={this.props.selectedProject}
            selectedPotentialCandidate={this.props.selectedPotentialCandidate}
            handleDelete={this.handleDelete}
            handleUpload={this.handleUpload}
            getDocumentApiCall={this.getDocumentApiCall}
          />
        </div>
      </Dialog>
    );
  }

  /**
   * Uploads a document to the server
   * @param  {unknown} acceptedFiles
   * @returns {Promise<unknown>}
   */
  handleUpload = acceptedFiles => {
    const request = new FormData();
    acceptedFiles.forEach((file, index) => {
      request.append(`file-${index}`, file);
    });
    request.append('projectId', this.props.selectedProject.id);
    request.append('potentialCandidateId', this.props.selectedPotentialCandidate.id);
    request.append('partner', this.props.selectedProject.partner);
    return post(CONSTANTS.uploadProjectCandidateAdditionalDocumentsURL, request);
  };

  /**
   * Fetches a document from the server
   * @param {String} fileName
   * @param {String} documentId
   * @returns {Promise<unknown>}
   */
  getDocumentApiCall = (fileName, documentId) => {
    const payload = {
      fileName,
      documentId,
      projectId: this.props.selectedProject.id,
      partner: this.props.selectedProject.partner,
      potentialCandidateId: this.props.selectedPotentialCandidate.id
    };
    return post(CONSTANTS.getProjectCandidateAdditionalDocumentByIdURL, payload);
  };

  /**
   * Deletes a document from the server
   * @param {String} fileName
   * @param {String} documentId
   * @returns {Promise<unknown>}
   */
  handleDelete = (fileName, documentId) => {
    const payload = {
      fileName,
      documentId,
      projectId: this.props.selectedProject.id,
      partner: this.props.selectedProject.partner,
      potentialCandidateId: this.props.selectedPotentialCandidate.id
    };

    return post(CONSTANTS.deleteProjectCandidateAdditionalDocumentURL, payload);
  };
}

UploadDocuments.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpSelectedProject: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  selectedProject: PropTypes.object.isRequired,
  selectedPotentialCandidate: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handlePopupClose: PropTypes.func.isRequired
};

export default UploadDocuments;
