export default {
    defaultText: `<br />
  <h2>Das Unternehmen</h2>
  <p>[Unternehmenstext]</p>
  <h2>Die Position</h2>
  <ul>
    <li>...</li>
    <li>...</li>
    <li>...</li>
  </ul>
  <h2>Ihr Profil</h2>
  <ul>
    <li>...</li>
    <li>...</li>
    <li>...</li>
  </ul>
  <h2>Benefits</h2>
  <p>[Vorteile bei Mandanten]</p>
  <h2>Bewerben Sie sich unter Angabe der Kennziffer [§PROJECTNUMBER§]</h2>
  <p>
    Bitte senden Sie Ihre aussagekräftigen Unterlagen unter Angabe der oben
    genannten Kennziffer an [§EMAIL§].
  </p>
  <p>
    Für weitere Informationen steht Ihnen [§CONSULTANT§] unter
    <i>
      <b>[§PHONENUMbER§]</b>
    </i>
    gerne zur Verfügung.
  </p>
  <p>
    Möchten Sie mehr über uns erfahren? Informieren Sie sich auf [§URL§].
  </p>
  <p>
    Aus Gründen der besseren Lesbarkeit verwenden wir in diesem Anzeigentext
    meist nur die männliche Form. Selbstverständlich sind immer alle
    Geschlechter angesprochen.
  </p>`,
    description: ` 
 <h2>Das Unternehmen</h2>
  <p>[Unternehmenstext]</p>`,
    tasks: `
     <h2>Die Position</h2>
  <ul>
    <li>...</li>
    <li>...</li>
    <li>...</li>
  </ul>
  `,
    profile: `
    <h2>Ihr Profil</h2>
      <ul>
        <li>...</li>
        <li>...</li>
        <li>...</li>
      </ul>
    `,
    benefits: `
     <h2>Benefits</h2>
     <p>[Vorteile bei Mandanten]</p>
    `,
    contactInfo: `
    <p><b>Bewerben Sie sich unter Angabe der Kennziffer [§PROJECTNUMBER§]</b></p>
      <p>
        Bitte senden Sie Ihre aussagekräftigen Unterlagen unter Angabe der oben
        genannten Kennziffer an [§EMAIL§].
      </p>
      <p>
        Für weitere Informationen steht Ihnen [§CONSULTANT§] unter
        <i>
          <b>[§PHONENUMbER§]</b>
        </i>
        gerne zur Verfügung.
      </p>
      <p>
        Möchten Sie mehr über uns erfahren? Informieren Sie sich auf [§URL§].
      </p>
      <p>
        Aus Gründen der besseren Lesbarkeit verwenden wir in diesem Anzeigentext
        meist nur die männliche Form. Selbstverständlich sind immer alle
        Geschlechter angesprochen.
      </p>
    `
};

export const DefaultTextForSenator = {
    description: ` 
  <p>[Unternehmenstext]</p>`,
    tasks: `
  <ul>
    <li>...</li>
    <li>...</li>
    <li>...</li>
  </ul>
  `,
    profile: `
      <ul>
        <li>...</li>
        <li>...</li>
        <li>...</li>
      </ul>
    `,
    benefits: `
     <p>[Vorteile bei Mandanten]</p>
    `,
    contactInfo: `
    <p><b>Bewerben Sie sich unter Angabe der Kennziffer [§PROJECTNUMBER§]</b></p>
      <p>
        Bitte senden Sie Ihre aussagekräftigen Unterlagen unter Angabe der oben
        genannten Kennziffer an [§EMAIL§].
      </p>
      <p>
        Für weitere Informationen steht Ihnen [§CONSULTANT§] unter
        <i>
          <b>[§PHONENUMbER§]</b>
        </i>
        gerne zur Verfügung.
      </p>
      <p>
        Möchten Sie mehr über uns erfahren? Informieren Sie sich auf [§URL§].
      </p>
      <p>
        Aus Gründen der besseren Lesbarkeit verwenden wir in diesem Anzeigentext
        meist nur die männliche Form. Selbstverständlich sind immer alle
        Geschlechter angesprochen.
      </p>
    `
}