import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import $ from 'jquery';
import debounce from 'lodash/debounce';
import flowRight from 'lodash/flowRight';
import LZString from 'lz-string';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Redirect, withRouter } from 'react-router-dom';

// Icons
import AddIcon from '@material-ui/icons/Add';

// Material UI
import Divider from '@material-ui/core/Divider';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Alert } from 'react-bootstrap';
// components
import CandidateDetailSearch from '../candidateArchive/CandidateDetailSearch';
import CandidateMatchingSearch from '../candidateArchive/CandidateMatchingSearch';
import CandidateResult from '../candidateArchive/CandidateResult';
import CandidateEducationCreatePopUpContainer from '../candidateArchive/popUps/CandidateEducationCreatePopUpContainer';
import CandidateEducationUpdatePopUpContainer from '../candidateArchive/popUps/CandidateEducationUpdatePopUpContainer';
import CandidateJobCreatePopUpContainer from '../candidateArchive/popUps/CandidateJobCreatePopUpContainer';
import CandidateJobUpdatePopUpContainer from '../candidateArchive/popUps/CandidateJobUpdatePopUpContainer';
import CandidateLanguageCreatePopUpContainer from '../candidateArchive/popUps/CandidateLanguageCreatePopUpContainer';
import CandidateLanguageUpdatePopUpContainer from '../candidateArchive/popUps/CandidateLanguageUpdatePopUpContainer';
import CandidateMainUpdatePopUpContainer from '../candidateArchive/popUps/CandidateMainUpdatePopUpContainer';
import CandidatePastContactCreatePopUpContainer from '../candidateArchive/popUps/CandidatePastContactCreatePopUpContainer';
import CandidatePastContactUpdatePopUpContainer from '../candidateArchive/popUps/CandidatePastContactUpdatePopUpContainer';
import CandidateSkillCreatePopUpContainer from '../candidateArchive/popUps/CandidateSkillCreatePopUpContainer';
import CandidateSkillUpdatePopUpContainer from '../candidateArchive/popUps/CandidateSkillUpdatePopUpContainer';
import SingleCandidateResult from '../candidateArchive/SingleCandidateResult';
import ConfirmationModal from '../generic_components/ConfirmationModal';

import MainSearchField from '../generic_components/MainSearchField';

import * as CONFIG from '../config';
import * as CONSTANTS from '../constants';
import PROJECT_TEXT from '../projectArchive/staticContent/text';
import GLOBAL_TEXT from '../text';
import onNextPreviousItemClick from '../utils/forwardBackwardsUtils';
import * as UTILS from '../utils/utilFunctions';
import withUser from '../utils/withUser';

// css
import { addCandidateLinks } from '../candidateArchive/api/candidateArchiveAPI';
import GenericFormPopUp from '../generic_components/GenericFormPopUp';
import { post } from '../utils/apiUtils';
import './CandidateArchive.css';

class CandidateArchive extends PureComponent {
  constructor(props) {
    super(props);
    sessionStorage.removeItem('displayedCandidateIndexes');
    let currentTab = UTILS.getURLParam(this.props.history, 't');
    const candidateIdFromParms = UTILS.getURLParam(this.props.history, 'id');
    currentTab = currentTab ? parseInt(currentTab, 10) : 0;
    this.state = {
      searchFieldValidationError: false,
      isCreateLinkPopoverOpen: false,
      isPopoverOpen: false,
      candidateList: [],
      selectedCandidate: {},
      isDataPrivacyEmailButtonDisabled: false,
      currentTab,
      showResultList: false,
      candidateDetails: false,
      selectedJob: {},
      selectedEntry: {},
      redirectToCreateCandidate: false,
      employees: [],
      partners: [],
      isDetailSearchDrawerOpen: false,
      isMatchingSearchDrawerOpen: false,
      searchFieldAutoFocus: !(candidateIdFromParms && candidateIdFromParms.length > 0),
      maxAmountOfSearchResults: 100,
      isLienertBulkDeleteOlderCandidateModalOpen: false
    };
    this.mainSearchFieldChange = flowRight(
      debounce(this.mainSearchFieldChange, 500),
      e => e.persist() || e
    );
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.candidateDetailsRef = React.createRef();
  }

  componentDidMount() {
    document.title = `Kandidaten - ${UTILS.getTitle()}`;
    const searchQueryFromParams = UTILS.getURLParam(this.props.history, 'sq');
    const candidateIdFromParms = UTILS.getURLParam(this.props.history, 'id');
    const lastSearchTypeFromParms = UTILS.getURLParam(this.props.history, 'ls');
    const projectIdFromParams = UTILS.getURLParam(this.props.history, 'pid');
    const marklistIdFromParams = UTILS.getURLParam(this.props.history, 'mid');
    const maxAmountOfSearchResults = UTILS.getURLParam(this.props.history, 'ma');

    let detailSearchQueryFromParams = UTILS.getURLParam(this.props.history, 'dq');
    if (detailSearchQueryFromParams) detailSearchQueryFromParams = LZString.decompressFromEncodedURIComponent(detailSearchQueryFromParams);

    try {
      detailSearchQueryFromParams = JSON.parse(detailSearchQueryFromParams);
    } catch (e) {
      detailSearchQueryFromParams = undefined;
    }

    if (candidateIdFromParms && candidateIdFromParms.length > 0) {
      this.getCandidateById(candidateIdFromParms);
    }
    if (lastSearchTypeFromParms === 'marklist' && marklistIdFromParams) {
      this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
      this.getCandidateListByMarkListId(marklistIdFromParams);
    } else if (searchQueryFromParams && lastSearchTypeFromParms && lastSearchTypeFromParms === 'query') {
      this.getCandidateListBySearchAPICall(searchQueryFromParams, !(candidateIdFromParms && candidateIdFromParms.length > 0));
    } else if (detailSearchQueryFromParams && lastSearchTypeFromParms && lastSearchTypeFromParms === 'detail') {
      this.getCandidateListByDetailSearchAPICall(
        detailSearchQueryFromParams,
        maxAmountOfSearchResults,
        !(candidateIdFromParms && candidateIdFromParms.length > 0)
      );
    } else if (lastSearchTypeFromParms === 'matching') {
      this.getCandidateListByMatchingProjectIdAPICall(projectIdFromParams, projectIdFromParams && projectIdFromParams.length > 0);
    }

    this.getEmployeeListByPartnerAPICall();

    this.getPartnersOfCompanyAPICall();
  }

  getCandidateListByDetailSearchAPICall = (detailSearchFieldList, maxAmountOfSearchResults, showResultList) => {
    const url = CONSTANTS.getCandidateListByDetailSearchTermsURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const filteredDetailSearchFieldList = detailSearchFieldList.filter(
      item =>
        item.dropDownValue !== 'none' &&
        (item.fieldValue !== undefined && item.fieldValue.length !== 0) &&
        // WARNING code pope blessing
        Object.values(item.fieldValue).every(itemValue => itemValue !== '')
    );
    const payload = {
      detailSearchFieldList: filteredDetailSearchFieldList,
      maxAmountOfSearchResults
    };
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(payload),
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(response => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.setState({
          showResultList,
          candidateList: response,
          maxAmountOfSearchResults: Number.parseInt(maxAmountOfSearchResults, 10) || 100
        });
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.errorHandling(err, url);
      });
  };

  getCandidateListByMatchingProjectIdAPICall = (projectId, showResultList) => {
    const url = CONSTANTS.getCandidateListByMatchingProjectIdURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');

    const payload = {
      projectId
    };
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      data: JSON.stringify(payload),
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(response => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.setState({
          showResultList,
          candidateList: response
        });
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        this.errorHandling(err, url);
      });
  };

  getPartnersOfCompanyAPICall() {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.getAllPartnersOfCompanyURL;
    console.log(`API ${url} request`);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout,
      data: JSON.stringify({
        includeDeleted: this.props.user.role === 'admin'
      })
    })
      .done(responseBody => {
        this.setState({
          partners: responseBody.partners
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  isValidateSearchTerm(searchTerm) {
    if (!UTILS.isSearchtermValid(searchTerm)) {
      this.setState({ searchFieldValidationError: true });
      return false;
    }
    if (!searchTerm) {
      this.setState({ searchFieldValidationError: false });
      return false;
    }
    if (this.state.searchFieldValidationError) this.setState({ searchFieldValidationError: false });
    return true;
  }

  sendDataPrivacyMailAPICall(candidateId) {
    // WARNING this call requires a state for disableing the YES button
    this.setState({
      isDataPrivacyEmailButtonDisabled: true
    });
    const url = CONSTANTS.sendDataPrivacyMailURL;
    const requestBody = { candidateId };
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        console.log('CandidateArchive.sendDataPrivacyMailAPICall.responseBody :: ', responseBody);
        this.setState({
          isPrivacyMailConfirmationModalPopUpOpen: false,
          isDataPrivacyEmailButtonDisabled: false
        });
        this.props.handleSnackbarOpen(
          `Datenschutz Email wurde an "${this.state.selectedCandidate.firstName} ${
            this.state.selectedCandidate.lastName
          }" erfolgreich versendet`
        );
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  getCandidateListByMarkListId(candidateMarkListId) {
    const requestBody = { candidateMarkListId };
    const url = CONSTANTS.getCandidateListByMarkListIdURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        if (responseBody.length === 1) {
          this.setState({
            candidateList: responseBody
          });
          this.getCandidateById(responseBody[0].id);
        } else this.setState({ showResultList: true, candidateList: responseBody });
        this.props.liftUpStateToApp({
          showLoadingIndicatorGlobal: false
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  getCandidateListBySearchAPICall(searchTerm, showResultList = true) {
    if (!this.isValidateSearchTerm(searchTerm) || !searchTerm) return;
    this.setState({ searchTerm });
    const requestBody = { searchTerm };
    const url = CONSTANTS.getCandidateListBySearchTermURL; //
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        console.log(`API ${url} response `, responseBody);
        if (this.state.searchTerm === searchTerm) {
          this.setState({
            showResultList,
            candidateList: responseBody
          });
        } else {
          console.log(`Discarding outdated response for ${searchTerm}`);
        }
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  mainSearchFieldChange = e => {
    UTILS.setURLParam(this.props.history, 'sq', e.target.value);
    UTILS.setURLParam(this.props.history, 'ls', 'query');
    this.getCandidateListBySearchAPICall(e.target.value);
  };

  getEmployeeListByPartnerAPICall() {
    const url = CONSTANTS.getEmployeeListByPartnerURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        this.setState({ employees: responseBody });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  getProjectListByCandidateIdAPICall(candidateId) {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.getProjectListByCandidateIdURL;

    const payload = {
      candidateId
    };
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API ${url} response`, responseBody, status);
        this.setState(prevState => ({
          selectedCandidate: {
            ...prevState.selectedCandidate,
            projects: responseBody.projects
          }
        }));
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  /**
   * loads candidate data by candidateId
   * set isRefresh === true to speed up the call and not load the candidate picture
   */
  getCandidateById = (id, isRefresh = false) => {
    UTILS.setURLParam(this.props.history, 'id', id);
    const url = CONSTANTS.getCandidateByIdURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });

    const requestBody = { candidateId: id, isRefresh };
    console.log('getCandidateById requestBody', requestBody);

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        console.log('getCandidateById response', responseBody);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        // keep existing picture in state on isRefresh === true
        if (isRefresh) {
          this.setState(prevState => ({
            selectedCandidate: {
              ...responseBody,
              picture: prevState.selectedCandidate.picture
            },
            showResultList: false,
            candidateDetails: true
          }));
        } else
          this.setState({
            selectedCandidate: responseBody,
            showResultList: false,
            candidateDetails: true
          });
        this.getProjectListByCandidateIdAPICall(responseBody.id);
      })
      .fail(err => {
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url);
      });
  };

  refreshCandidate = () => {
    this.getCandidateById(this.state.selectedCandidate.id, true);
  };

  liftUpStateToCandidateArchive = (stateNameOrObject, value) => {
    if (typeof stateNameOrObject === 'object') {
      Object.keys(stateNameOrObject).forEach(key => {
        this.setState({
          [key]: stateNameOrObject[key]
        });
      });
    } else if (typeof value === 'string') {
      const key = stateNameOrObject;
      this.setState({ [key]: value });
    } else {
      const key = stateNameOrObject;
      this.setState(prevState => ({ [key]: { ...prevState[key], ...value } }));
    }
  };

  liftSubschemaToCandidateArchive = (id, updatedEntry, subschema) => {
    this.setState(prevState => {
      const subschemas = [...prevState.selectedCandidate[subschema]];
      const listPosition = subschemas.findIndex(element => element.id === id);
      subschemas[listPosition] = {
        ...subschemas[listPosition],
        ...updatedEntry
      };
      return {
        selectedCandidate: {
          ...prevState.selectedCandidate,
          [subschema]: subschemas
        }
      };
    });
  };

  handleLienertBulkDeleteOldCandidates = () => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    const url = CONSTANTS.lienertBulkDeleteOldCandidatesURL;
    // Turn of time out
    post(url, undefined, 0)
      .finally(() => {
        this.handleCloseLienertBulkDeleteOldCandidatesModal();
        this.props.liftUpStateToApp({
          showLoadingIndicatorGlobal: false
        });
        window.location.reload();
      })
      .catch(error => {
        console.error(error);
      });
  };

  handleClosePrivacyMailConfirmationModal = () => {
    this.setState({
      isPrivacyMailConfirmationModalPopUpOpen: false
    });
  };

  handleCloseCandidateMainUpdatePopUp = () => {
    this.setState({ isCandidateMainUpdatePopUpOpen: false });
  };

  handleCloseCandidateSkillUpdatePopUp = () => {
    this.setState({ isCandidateSkillUpdatePopUpOpen: false });
  };

  handleCloseCandidatePastContactUpdatePopUp = () => {
    this.setState({ isCandidatePastContactUpdatePopUpOpen: false });
  };

  handleCloseCandidateLanguageUpdatePopUp = () => {
    this.setState({ isCandidateLanguageUpdatePopUpOpen: false });
  };

  handleCloseCandidateEducationUpdatePopUp = () => {
    this.setState({ isCandidateEducationUpdatePopUpOpen: false });
  };

  handleCloseCandidateJobUpdatePopUp = () => {
    this.setState({ isCandidateJobUpdatePopUpOpen: false });
  };

  handleCloseCandidateSkillCreatePopUp = () => {
    this.setState({ isCandidateSkillCreatePopUpOpen: false });
  };

  handleCloseCandidatePastContactCreatePopUp = () => {
    this.setState({ isCandidatePastContactCreatePopUpOpen: false });
  };

  handleCloseCandidateLanguageCreatePopUp = () => {
    this.setState({ isCandidateLanguageCreatePopUpOpen: false });
  };

  handleCloseCandidateEducationCreatePopUp = () => {
    this.setState({ isCandidateEducationCreatePopUpOpen: false });
  };

  handleCloseCandidateJobCreatePopUp = () => {
    this.setState({ isCandidateJobCreatePopUpOpen: false });
  };

  handleOpenCandidateMainUpdatePopUp = () => {
    this.setState({
      isCandidateMainUpdatePopUpOpen: true
    });
  };

  handleOpenPrivacyMailConfirmationModal = () => {
    this.setState({
      isPrivacyMailConfirmationModalPopUpOpen: true
    });
  };

  handleOpenCandidateSkillUpdatePopUp = selectedEntryData => {
    this.setState({
      isCandidateSkillUpdatePopUpOpen: true,
      selectedEntry: selectedEntryData
    });
  };

  handleOpenCandidateLanguageUpdatePopUp = selectedEntryData => {
    this.setState({
      isCandidateLanguageUpdatePopUpOpen: true,
      selectedEntry: selectedEntryData
    });
  };

  handleOpenCandidateEducationUpdatePopUp = selectedEntryData => {
    this.setState({
      isCandidateEducationUpdatePopUpOpen: true,
      selectedEntry: selectedEntryData
    });
  };

  handleOpenCandidateJobUpdatePopUp = selectedJobData => {
    this.setState({
      isCandidateJobUpdatePopUpOpen: true,
      selectedJob: selectedJobData
    });
  };

  handleOpenCandidatePastContactUpdatePopUp = selectedEntryData => {
    this.setState({
      isCandidatePastContactUpdatePopUpOpen: true,
      selectedEntry: selectedEntryData
    });
  };

  openContactHistoryForPhone = () => {
    this.setState({
      isCandidatePastContactCreatePopUpOpen: true,
      isPastContactDefaultPhoneCall: true
    });
  };

  /**
   * @returns {{
   *  isDisabled: boolean,
   *  onClickFn: () => void | undefined,
   * }}
   */
  fabButtonHandling() {
    if (CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) {
      switch (this.state.currentTab) {
        case 0:
          return {
            isDisabled: false,
            onClickFn: () => this.setState({ isPopoverOpen: true })
          };
        case 1:
          return {
            isDisabled: false,
            onClickFn: () => this.setState({ isCreateLinkPopoverOpen: true })
          };
        case 4:
          return {
            isDisabled: false,
            onClickFn: () => this.setState({ isCandidatePastContactCreatePopUpOpen: true, isPastContactDefaultPhoneCall: false })
          };
        default:
          return {
            isDisabled: true,
            onClickFn: undefined
          };
      }
    }

    if (CONSTANTS.isLienert) {
      switch (this.state.currentTab) {
        case 0:
          return {
            isDisabled: false,
            onClickFn: () => this.setState({ isPopoverOpen: true })
          };

        case 1:
          return {
            isDisabled: false,
            onClickFn: () => this.setState({ isCandidatePastContactCreatePopUpOpen: true, isPastContactDefaultPhoneCall: false })
          };

        default:
          return {
            isDisabled: true,
            onClickFn: undefined
          };
      }
    }

    return {
      isDisabled: true,
      onClickFn: undefined
    };
  }

  renderPrivacyMailConfirmationModalPopUp() {
    const candidateId = this.state.selectedCandidate.id;
    return (
      <ConfirmationModal
        open={this.state.isPrivacyMailConfirmationModalPopUpOpen}
        handleClose={this.handleClosePrivacyMailConfirmationModal}
        headlineText="Datenschutz Email"
        buttonPrimaryAction={() => {
          this.sendDataPrivacyMailAPICall(candidateId);
        }}
        descriptionText={`Wollen Sie an den Nutzer "${this.state.selectedCandidate.firstName} ${
          this.state.selectedCandidate.lastName
        }" mit der E-Mail Adresse "${this.state.selectedCandidate.mailAddress}" einen Link zur Datenschutzseite senden?`}
        buttonPrimaryText="ja"
        buttonSecondaryText="nein"
        primaryButtonIsDisabled={this.state.isDataPrivacyEmailButtonDisabled}
        icon="security"
      />
    );
  }

  renderDeleteCandidate67ConfirmationModalPopUp() {
    return (
      <ConfirmationModal
        open={this.state.isLienertBulkDeleteOlderCandidateModalOpen}
        handleClose={this.handleCloseLienertBulkDeleteOldCandidatesModal}
        headlineText={GLOBAL_TEXT.candidateArchive.deleteCandidateOver67.modal.header}
        buttonPrimaryAction={this.handleLienertBulkDeleteOldCandidates}
        descriptionText={GLOBAL_TEXT.candidateArchive.deleteCandidateOver67.modal.text}
        buttonPrimaryText={GLOBAL_TEXT.commonTerms.yes}
        buttonSecondaryText={GLOBAL_TEXT.commonTerms.no}
        contentText={<Alert bsStyle="warning">{GLOBAL_TEXT.candidateArchive.deleteCandidateOver67.modal.warning}</Alert>}
        icon="delete"
      />
    );
  }

  handleCloseLienertBulkDeleteOldCandidatesModal = () => {
    this.setState({
      isLienertBulkDeleteOlderCandidateModalOpen: false
    });
  };

  handleOpenLienertBulkDeleteOldCandidatesModal = () => {
    this.setState({
      isLienertBulkDeleteOlderCandidateModalOpen: true
    });
  };

  renderCandidateMainUpdatePopUp() {
    return (
      <CandidateMainUpdatePopUpContainer
        open={this.state.isCandidateMainUpdatePopUpOpen}
        key={this.state.selectedEntry.entryId}
        candidateId={this.state.selectedCandidate.id}
        data={this.state.selectedCandidate}
        isUpdate
        handleClose={this.handleCloseCandidateMainUpdatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshCandidate={this.refreshCandidate}
        employees={this.state.employees}
      />
    );
  }

  renderCandidateSkillUpdatePopUp() {
    return (
      <CandidateSkillUpdatePopUpContainer
        open={this.state.isCandidateSkillUpdatePopUpOpen}
        key={this.state.selectedEntry.entryId}
        candidateId={this.state.selectedCandidate.id}
        entryId={this.state.selectedEntry.entryId}
        data={this.state.selectedEntry}
        isUpdate
        handleClose={this.handleCloseCandidateSkillUpdatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshCandidate={this.refreshCandidate}
      />
    );
  }

  renderCandidateLanguageUpdatePopUp() {
    return (
      <CandidateLanguageUpdatePopUpContainer
        open={this.state.isCandidateLanguageUpdatePopUpOpen}
        key={this.state.selectedEntry.entryId}
        candidateId={this.state.selectedCandidate.id}
        entryId={this.state.selectedEntry.entryId}
        data={this.state.selectedEntry}
        isUpdate
        handleClose={this.handleCloseCandidateLanguageUpdatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshCandidate={this.refreshCandidate}
      />
    );
  }

  renderCandidatePastContactUpdatePopUp() {
    return (
      <CandidatePastContactUpdatePopUpContainer
        open={this.state.isCandidatePastContactUpdatePopUpOpen}
        key={this.state.selectedEntry.entryId}
        candidateId={this.state.selectedCandidate.id}
        entryId={this.state.selectedEntry.entryId}
        data={this.state.selectedEntry}
        isUpdate
        handleClose={this.handleCloseCandidatePastContactUpdatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshCandidate={this.refreshCandidate}
        employees={this.state.employees}
        projects={this.state.selectedCandidate.projects}
      />
    );
  }

  renderCandidateEducationUpdatePopUp() {
    return (
      <CandidateEducationUpdatePopUpContainer
        open={this.state.isCandidateEducationUpdatePopUpOpen}
        key={this.state.selectedEntry.entryId}
        candidateId={this.state.selectedCandidate.id}
        entryId={this.state.selectedEntry.entryId}
        data={this.state.selectedEntry}
        isUpdate
        handleClose={this.handleCloseCandidateEducationUpdatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshCandidate={this.refreshCandidate}
      />
    );
  }

  renderCandidateJobUpdatePopUp() {
    return (
      <CandidateJobUpdatePopUpContainer
        open={this.state.isCandidateJobUpdatePopUpOpen}
        key={this.state.selectedJob.jobId}
        jobId={this.state.selectedJob.jobId}
        candidateId={this.state.selectedCandidate.id}
        data={this.state.selectedJob}
        isUpdate
        handleClose={this.handleCloseCandidateJobUpdatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshCandidate={this.refreshCandidate}
      />
    );
  }

  renderCandidateSkillCreatePopUp() {
    return (
      <CandidateSkillCreatePopUpContainer
        open={this.state.isCandidateSkillCreatePopUpOpen}
        key={this.state.selectedEntry.entryId}
        candidateId={this.state.selectedCandidate.id}
        entryId={this.state.selectedEntry.entryId}
        data={this.state.selectedEntry}
        handleClose={this.handleCloseCandidateSkillCreatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshCandidate={this.refreshCandidate}
      />
    );
  }

  renderCandidateLanguageCreatePopUp() {
    return (
      <CandidateLanguageCreatePopUpContainer
        open={this.state.isCandidateLanguageCreatePopUpOpen}
        key={this.state.selectedEntry.entryId}
        candidateId={this.state.selectedCandidate.id}
        entryId={this.state.selectedEntry.entryId}
        data={this.state.selectedEntry}
        handleClose={this.handleCloseCandidateLanguageCreatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshCandidate={this.refreshCandidate}
      />
    );
  }

  renderCandidateEducationCreatePopUp() {
    return (
      <CandidateEducationCreatePopUpContainer
        open={this.state.isCandidateEducationCreatePopUpOpen}
        key={this.state.selectedEntry.entryId}
        candidateId={this.state.selectedCandidate.id}
        entryId={this.state.selectedEntry.entryId}
        data={this.state.selectedEntry}
        handleClose={this.handleCloseCandidateEducationCreatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshCandidate={this.refreshCandidate}
      />
    );
  }

  renderCandidateJobCreatePopUp() {
    return (
      <CandidateJobCreatePopUpContainer
        open={this.state.isCandidateJobCreatePopUpOpen}
        key={this.state.selectedJob.jobId}
        jobId={this.state.selectedJob.jobId}
        candidateId={this.state.selectedCandidate.id}
        data={this.state.selectedJob}
        handleClose={this.handleCloseCandidateJobCreatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshCandidate={this.refreshCandidate}
      />
    );
  }

  renderCandidatePastContactCreatePopUp() {
    return (
      <CandidatePastContactCreatePopUpContainer
        open={this.state.isCandidatePastContactCreatePopUpOpen}
        isPastContactDefaultPhoneCall={this.state.isPastContactDefaultPhoneCall}
        key={this.state.selectedEntry.entryId + this.state.isPastContactDefaultPhoneCall}
        candidateId={this.state.selectedCandidate.id}
        entryId={this.state.selectedEntry.entryId}
        data={this.state.selectedEntry}
        handleClose={this.handleCloseCandidatePastContactCreatePopUp}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        refreshCandidate={this.refreshCandidate}
        employees={this.state.employees}
        projects={this.state.selectedCandidate.projects}
        userId={this.props.user.id}
      />
    );
  }

  renderCreateLinkPopup() {
    const formFields = {
      name: {
        value: '',
        label: 'Link Name',
        validator: 'isText',
        required: true
      },
      url: {
        value: '',
        label: 'URL',
        validator: 'isUrl',
        required: true
      }
    };
    return (
      <GenericFormPopUp
        genericFormProps={{
          formFields,
          handleClose: () => this.setState({ isCreateLinkPopoverOpen: false }),
          handleSubmit: fields => {
            addCandidateLinks(this.state.selectedCandidate.id, fields)
              .then(() => {
                this.refreshCandidate();
                this.setState({
                  isCreateLinkPopoverOpen: false
                });
                this.props.handleSnackbarOpen('Link erfolgreich erstellt!');
              })
              .catch(() => {
                this.props.handleSnackbarOpen('Konnte Link nicht erstellen');
              });
          }
        }}
        headlineText="Neuen Link hinzufügen"
        open={this.state.isCreateLinkPopoverOpen}
      />
    );
  }

  liftUpUpdatedPotentialCandidates = newPotentialCandidates => {
    this.setState(prevState => ({
      selectedCandidate: {
        ...prevState.selectedCandidate,
        projects: newPotentialCandidates
      }
    }));
  };

  handleCandidateSelected = selectedRow => {
    this.setState({
      showResultList: false
    });
    this.getCandidateById(selectedRow[0]);
  };

  render() {
    const isDataLoaded = this.state.selectedCandidate && Object.keys(this.state.selectedCandidate).length > 0; // &&
    if (this.state.redirectToCreateCandidate) {
      this.setState({ redirectToCreateCandidate: false });
      return <Redirect to="/incomingCandidates" />;
    }
    return (
      <div className="CandidateArchive  h-100-minusAppBar">
        {isDataLoaded && this.state.isCandidateMainUpdatePopUpOpen && this.renderCandidateMainUpdatePopUp()}
        {isDataLoaded && this.state.isCandidateLanguageUpdatePopUpOpen && this.renderCandidateLanguageUpdatePopUp()}
        {isDataLoaded && this.state.isCandidateSkillUpdatePopUpOpen && this.renderCandidateSkillUpdatePopUp()}
        {isDataLoaded && this.state.isCandidateEducationUpdatePopUpOpen && this.renderCandidateEducationUpdatePopUp()}
        {isDataLoaded && this.state.isCandidateJobUpdatePopUpOpen && this.renderCandidateJobUpdatePopUp()}
        {isDataLoaded && this.state.isCandidatePastContactUpdatePopUpOpen && this.renderCandidatePastContactUpdatePopUp()}
        {isDataLoaded && this.state.isCreateLinkPopoverOpen && this.renderCreateLinkPopup()}
        {/* Create PopUps */}
        {this.state.isLienertBulkDeleteOlderCandidateModalOpen && this.renderDeleteCandidate67ConfirmationModalPopUp()}
        {this.state.isCandidateLanguageCreatePopUpOpen && this.renderCandidateLanguageCreatePopUp()}
        {this.state.isCandidateSkillCreatePopUpOpen && this.renderCandidateSkillCreatePopUp()}
        {this.state.isCandidateEducationCreatePopUpOpen && this.renderCandidateEducationCreatePopUp()}
        {this.state.isCandidateJobCreatePopUpOpen && this.renderCandidateJobCreatePopUp()}
        {this.state.isPrivacyMailConfirmationModalPopUpOpen && this.renderPrivacyMailConfirmationModalPopUp()}
        {this.state.isCandidatePastContactCreatePopUpOpen && this.renderCandidatePastContactCreatePopUp()}
        <div className="container-fluid  h-100" style={{ height: '100%', padding: '0px' }}>
          <div className="d-flex justify-content-center align-items-center">
            <IconButton
              aria-label="back"
              color="primary"
              disabled={!this.state.candidateList || this.state.candidateList.length === 0 || !this.state.selectedCandidate.id}
              onClick={() =>
                onNextPreviousItemClick(
                  false,
                  this.state.selectedCandidate.id,
                  this.state.candidateList,
                  'displayedCandidateIndexes',
                  this.getCandidateById
                )
              }
            >
              <ArrowBackIosIcon />
            </IconButton>
            <div style={{ width: '33%' }}>
              <MainSearchField
                placeholder="Bitte suchen Sie nach einem Kandidaten"
                autoFocus={this.state.searchFieldAutoFocus}
                onChange={this.mainSearchFieldChange}
                defaultValue={UTILS.getURLParam(this.props.history, 'sq')}
                onFocus={() => {
                  if (this.state.candidateList.length) console.log('Onfocus');
                  this.setState({ showResultList: true });
                  // this.getCandidateListBySearchAPICall(this.state.searchTerm);
                }}
                error={this.state.searchFieldValidationError}
                helperText={this.state.searchFieldValidationError && PROJECT_TEXT.search.searchFieldErrorMessage}
                onExtendedSearchClick={() =>
                  this.setState({
                    isDetailSearchDrawerOpen: true
                  })
                }
                enableMatchingSearch={CONSTANTS.isLienert}
                matchingSearchClick={() =>
                  this.setState({
                    isMatchingSearchDrawerOpen: true
                  })
                }
              />
            </div>
            <IconButton
              aria-label="Forward"
              color="primary"
              disabled={!this.state.candidateList || this.state.candidateList.length === 0 || !this.state.selectedCandidate.id}
              onClick={() =>
                onNextPreviousItemClick(
                  true,
                  this.state.selectedCandidate.id,
                  this.state.candidateList,
                  'displayedCandidateIndexes',
                  this.getCandidateById
                )
              }
            >
              <ArrowForwardIosIcon />
            </IconButton>
          </div>
          <Collapse
            direction="down"
            mountOnEnter
            in={this.state.showResultList}
            {...(this.state.candidateDetails ? { timeout: 800 } : { timeout: 800 })}
          >
            <div className="row mb-3">
              <div className="col-12">
                <div className="ProjecResults col-12">
                  <CandidateResult
                    candidateList={this.state.candidateList}
                    maxAmountOfSearchResults={this.state.maxAmountOfSearchResults}
                    handleCandidateSelected={this.handleCandidateSelected}
                    handleSnackbarOpen={this.props.handleSnackbarOpen}
                  />
                </div>
              </div>
            </div>
          </Collapse>
          <Fade
            direction="up"
            in={this.state.candidateDetails}
            mountOnEnter
            unmountOnExit
            {...(this.state.candidateDetails ? { timeout: 1000 } : {})}
          >
            <SingleCandidateResult
              currentTab={this.state.currentTab}
              selectedCandidate={this.state.selectedCandidate}
              liftUpStateToCandidateArchive={this.liftUpStateToCandidateArchive}
              refreshCandidate={this.refreshCandidate}
              handleSnackbarOpen={this.props.handleSnackbarOpen}
              handleOpenCandidateJobUpdatePopUp={this.handleOpenCandidateJobUpdatePopUp}
              handleOpenCandidateEducationUpdatePopUp={this.handleOpenCandidateEducationUpdatePopUp}
              handleOpenCandidateLanguageUpdatePopUp={this.handleOpenCandidateLanguageUpdatePopUp}
              handleOpenCandidatePastContactUpdatePopUp={this.handleOpenCandidatePastContactUpdatePopUp}
              handleOpenCandidateSkillUpdatePopUp={this.handleOpenCandidateSkillUpdatePopUp}
              handleOpenCandidateMainUpdatePopUp={this.handleOpenCandidateMainUpdatePopUp}
              handleOpenPrivacyMailConfirmationModal={this.handleOpenPrivacyMailConfirmationModal}
              liftUpStateToApp={this.props.liftUpStateToApp}
              liftUpUpdatedPotentialCandidates={this.liftUpUpdatedPotentialCandidates}
              liftSubschemaToCandidateArchive={this.liftSubschemaToCandidateArchive}
              openContactHistoryForPhone={this.openContactHistoryForPhone}
              employees={this.state.employees}
            />
          </Fade>
          <div style={{ height: '100px' }} className="spacer" />
          {/* BEGIN Button */}
          <Fab
            buttonRef={node => {
              this.anchorEl = node;
            }}
            disabled={this.fabButtonHandling().isDisabled}
            color="primary"
            aria-label="Add"
            style={{ position: 'absolute', bottom: '30px', right: '30px' }}
            onClick={this.fabButtonHandling().onClickFn}
          >
            <AddIcon />
          </Fab>
          <Popover
            open={this.state.isPopoverOpen}
            anchorEl={this.anchorEl}
            anchorReference="anchorEl"
            onClose={() => this.setState({ isPopoverOpen: false })}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          >
            {this.state.currentTab === 0 && (
              <MenuItem
                onClick={() =>
                  this.setState({
                    isCandidateJobCreatePopUpOpen: true
                  })
                }
                color="inherit"
              >
                <i className="material-icons pr-2">library_add</i>
                Berufserfahrung
              </MenuItem>
            )}
            {this.state.currentTab === 0 && (
              <MenuItem
                onClick={() =>
                  this.setState({
                    isCandidateEducationCreatePopUpOpen: true
                  })
                }
                color="inherit"
              >
                <i className="material-icons pr-2">library_add</i>
                Ausbildung
              </MenuItem>
            )}
            {this.state.currentTab === 0 && (
              <MenuItem
                onClick={() =>
                  this.setState({
                    isCandidateLanguageCreatePopUpOpen: true
                  })
                }
                color="inherit"
              >
                <i className="material-icons pr-2">library_add</i>
                Sprachen
              </MenuItem>
            )}
            {this.state.currentTab === 0 && (
              <MenuItem
                onClick={() =>
                  this.setState({
                    isCandidateSkillCreatePopUpOpen: true
                  })
                }
                color="inherit"
              >
                <i className="material-icons pr-2">library_add</i>
                EDV und sonstige Kenntnisse
              </MenuItem>
            )}
            {this.state.currentTab === 4 && (
              <MenuItem
                onClick={() =>
                  this.setState({
                    isCandidatePastContactCreatePopUpOpen: true,
                    isPastContactDefaultPhoneCall: false
                  })
                }
                color="inherit"
              >
                <i className="material-icons pr-2">library_add</i>
                Kontakthistorie
              </MenuItem>
            )}

            <Divider />
            {this.state.currentTab === 0 && (
              <MenuItem
                onClick={() =>
                  this.props.liftUpStateToApp('createNewCandidate', true, () => this.setState({ redirectToCreateCandidate: true }))
                }
                color="inherit"
              >
                <i className="material-icons pr-2">library_add</i>
                Kandidat anlegen
              </MenuItem>
            )}

            {this.state.currentTab === 0 && CONSTANTS.isLienert && this.props.user.canClearCandidates67 && (
              <>
                <Divider />
                <MenuItem onClick={this.handleOpenLienertBulkDeleteOldCandidatesModal} style={{ color: 'red' }}>
                  <i style={{ marginLeft: '-192px' }} className="material-icons pr-2">
                    library_delete_sweep
                  </i>
                  Kandidaten Ü67 löschen
                </MenuItem>
              </>
            )}
          </Popover>
          {/* END Button */}
        </div>
        {this.state.partners.length > 0 && this.state.employees.length > 0 && (
          <CandidateDetailSearch
            isDrawerOpen={this.state.isDetailSearchDrawerOpen}
            handleDrawerClose={() =>
              this.setState({
                isDetailSearchDrawerOpen: false
              })
            }
            updateCandidateArchive={(detailSearchResult, maxAmountOfSearchResults) =>
              this.setState({
                isDetailSearchDrawerOpen: false,
                showResultList: true,
                candidateList: detailSearchResult,
                maxAmountOfSearchResults: maxAmountOfSearchResults || 100
              })
            }
            employeesList={this.state.employees}
            partnersList={this.state.partners.map(partner => {
              if (partner.status === 'deleted') {
                return {
                  ...partner,
                  partnerName: `${partner.partnerName} (ausgeschieden)`
                };
              }
              return partner;
            })}
            liftUpStateToApp={this.props.liftUpStateToApp}
            handleSnackbarOpen={this.props.handleSnackbarOpen}
            user={this.props.user}
          />
        )}
        <CandidateMatchingSearch
          open={this.state.isMatchingSearchDrawerOpen}
          handleDrawerClose={() => this.setState({ isMatchingSearchDrawerOpen: false })}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          updateCandidateArchive={detailSearchResult =>
            this.setState({
              isMatchingSearchDrawerOpen: false,
              showResultList: true,
              candidateList: detailSearchResult
            })
          }
          liftUpStateToApp={this.props.liftUpStateToApp}
        />
      </div>
    );
  }
}
CandidateArchive.defaultProps = {
  candidateMarkListId: undefined
};
CandidateArchive.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  candidateMarkListId: PropTypes.array,
  user: PropTypes.object.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};
export default withRouter(withUser(CandidateArchive));
