// this util class contains helper functions for sorting

export function currentEmployerfilter(result, parameters) {
  // if (result.jobs[0] && result.jobs[0].company)
  // in case no checkbox is checked show all results
  if (parameters.length === 0) return true;
  // in case at least one checkbox is checked don't show results without defined currentEmployer
  const currentEmployer = result.jobs[0] && result.jobs[0].company ? result.jobs[0].company : null;
  if (!currentEmployer) return false;
  // check if the latest industry of the candidate matches the filter
  return parameters.includes(currentEmployer);
}

export const filterFunctions = {
  matchPercentage: (result, parameters) => result.score + 0.2 >= parameters,
  age: (result, parameters) => result.age <= parameters[1] && result.age >= parameters[0],

  name: (result, parameters) => {
    const name = `${result.firstName} ${result.lastName}`;
    return name.toLowerCase().includes(parameters.toLowerCase());
  },
  companyName: (result, parameters) => {
    let companyIncludesSearchTerm = false;
    result.jobs.forEach(job => {
      if ((job.company !== null && job.company && job.company.toLowerCase().includes(parameters.toLowerCase())) || parameters.length === 0)
        companyIncludesSearchTerm = true;
    });
    if (parameters.length === 0 && result.jobs.length === 0) companyIncludesSearchTerm = true;

    return companyIncludesSearchTerm;
  },
  willingnessToChange: (result, parameters) => result.willingnessToChange <= parameters[1] && result.willingnessToChange >= parameters[0],
  salary: {
    filterFunction: undefined
  },
  willingnessToRelocate: {
    filterFunction: undefined
  },
  // TODO it may require refactoring
  showMarked: (result, filterParam) => (filterParam && result.isMarked) || !filterParam,
  distance: (result, parameters) => {
    if (parameters[1] > 240) {
      return result.distance <= 1500 && result.distance >= parameters[0];
    }
    return result.distance <= parameters[1] && result.distance >= parameters[0];
  },
  // job filters
  durationCurrentJob: (result, parameters) => {
    // show all results in case all checkboxes are unselected
    if (parameters.length === 0) return true;
    // show results without duration only incase all checkboxes of filter are selected
    if (!result.jobs[0] || !result.jobs[0].duration) {
      if (parameters.length === 5) return true;
      return false;
    }

    const rawValue = result.jobs[0].duration;
    const durationInMonths = Math.round(rawValue / (31556952000 / 12));
    let durationRange;
    if (durationInMonths <= 12) durationRange = '0-1';
    else if (durationInMonths <= 24) durationRange = '1-2';
    else if (durationInMonths <= 60) durationRange = '2-5';
    else if (durationInMonths <= 120) durationRange = '5-10';
    else if (durationInMonths > 120) durationRange = '10-100';
    return parameters.includes(durationRange);
  },
  currentPosition: (result, parameters) => {
    // show all results in case all checkboxes are unselected
    if (parameters.length === 0) return true;
    if (!result.jobs[0] || !result.jobs[0].jobText) {
      return false;
    }

    const jobTextOfCandidate = result.jobs[0].jobText;
    return parameters.includes(jobTextOfCandidate);
  },
  industrySectorName: (result, parameters) => {
    // in case no checkbox is checked show all results
    if (parameters.length === 0) return true;
    // in case at least one checkbox is checked don't show results without defined indutries
    const latestIndustry = result.jobs[0] && result.jobs[0].branches && result.jobs[0].branches[0] ? result.jobs[0].branches[0] : null;
    if (!latestIndustry) return false;
    // check if the latest industry of the candidate matches the filter
    return parameters.includes(latestIndustry);
  },
  currentEmployer: {
    filterFunction: (result, parameters) => {
      // if (result.jobs[0] && result.jobs[0].company)
      // in case no checkbox is checked show all results
      if (parameters.length === 0) return true;
      // in case at least one checkbox is checked don't show results without defined currentEmployer
      const currentEmployer = result.jobs[0] && result.jobs[0].company ? result.jobs[0].company : null;
      if (!currentEmployer) return false;
      // check if the latest industry of the candidate matches the filter
      return parameters.includes(currentEmployer);
    }
  },
  workExperience: (result, parameters) => {
    // show all results in case all checkboxes are unselected
    if (parameters.length === 0) return true;
    // show results without workExperience only incase all checkboxes of filter are selected
    if (!result || !result.workExperience) {
      if (parameters.length === 5) return true;
      return false;
    }

    const rawValue = result.workExperience;
    const durationInMonths = Math.round(rawValue / (31556952000 / 12));
    let durationRange;
    if (durationInMonths <= 12) durationRange = '0-1';
    else if (durationInMonths <= 24) durationRange = '1-2';
    else if (durationInMonths <= 60) durationRange = '2-5';
    else if (durationInMonths <= 120) durationRange = '5-10';
    else if (durationInMonths > 120) durationRange = '10-100';
    return parameters.includes(durationRange);
  }
};

export function mapMonthsToYearRange(months) {
  let value;
  if (months <= 12) value = '0-1';
  else if (months <= 24) value = '1-2';
  else if (months <= 60) value = '2-5';
  else if (months <= 120) value = '5-10';
  else if (months > 120) value = '10-100';
  return value;
}

export function mapJobPropertyToFilterName(jobProperty) {
  let filterName;
  switch (jobProperty) {
    case 'duration':
      filterName = 'durationCurrentJob';
      break;
    case 'jobText':
      filterName = 'currentPosition';
      break;
    case 'workExperience':
      filterName = 'workExperience';
      break;
    case 'branches':
      filterName = 'industrySectorName';
      break;
    case 'company':
      filterName = 'currentEmployer';
      break;
    default:
      filterName = null;
  }
  return filterName;
}

export function sortCountJobParameters(obj, jobProperties, showFilterOptions) {
  const sortedCountJobParameters = obj;
  jobProperties.forEach(jobProperty => {
    // // skip sorting for current job duration
    // if (jobProperty === 'duration')
    const objToJobParameter = obj[jobProperty];
    const arrayToJobParameter = [];
    // TODO code review and extensive testing required
    Object.entries(objToJobParameter).forEach(([key, count]) => {
      arrayToJobParameter.push({ key, count });
    });
    // TODO old version output of a es-lint error
    // for (const entries in objToJobParameter) {
    //   const key = entries;
    //   const count = objToJobParameter[key];
    //   const object = { key, count };
    //   arrayToJobParameter.push(object);
    // }
    // skip sorting for current job duration
    if (jobProperty !== 'duration' && jobProperty !== 'workExperience') {
      arrayToJobParameter.sort((a, b) => {
        if (a.count > b.count) return -1;
        if (a.count < b.count) return 1;
        return 0;
      });
    }

    // HELPER FUNCTION: don't remove entries from slicedArray which are selected as a filter
    // create array with all entries as a copy of the original array
    const fullArray = arrayToJobParameter.slice();
    // slice the array
    let numberOfShownFilterOption = 10;
    const filterNameToJobProperty = mapJobPropertyToFilterName(jobProperty);
    console.log('showFilterOptions[filterNameToJobProperty]', showFilterOptions[filterNameToJobProperty]);
    if (showFilterOptions[filterNameToJobProperty]) {
      numberOfShownFilterOption = showFilterOptions[filterNameToJobProperty];
    }
    const slicedArray = arrayToJobParameter.slice(0, numberOfShownFilterOption);
    // search through entires of the full array with are part of filter parameters
    // not required for the year filters due to default labels
    if (jobProperty !== 'duration' && jobProperty !== 'workExperience') {
      fullArray.forEach(elementOfFullArray => {
        // TODO map filter criterias/parameters to filterName
        const filterName = mapJobPropertyToFilterName(jobProperty);
        if (filterName) {
          // console.log('DEBUG: jobProperty', jobProperty);
          const parameters = this.state.filters[filterName].parameters;
          if (parameters.includes(elementOfFullArray.key)) {
            // console.log(
            // 	'DEBUG filterName inside fix slicedArray',
            // 	filterName
            // );
            // check if element is also part of slicedArray
            let elementIsMissing = true;
            slicedArray.forEach(elementOfSlicedArray => {
              // case: element exists in both array
              if (elementOfFullArray.key === elementOfSlicedArray.key) {
                elementIsMissing = false;
              }
            });

            // case: element exists only in fullArray but is missing in sliced Array
            if (elementIsMissing) {
              // add the missing entries to the slices array
              slicedArray.push(elementOfFullArray);
            }
          }
        }
      });
    }
    // console.log(
    // 	`DEBUG sliced Array  for jobProperty ${jobProperty} `,
    // 	slicedArray
    // );
    sortedCountJobParameters[jobProperty] = slicedArray;
    sortedCountJobParameters[jobProperty].numberOfFilterOptions = fullArray.length;
  });
  return sortedCountJobParameters;
}
