import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import GenericFormPopUp from '../../generic_components/GenericFormPopUp';

import * as CONSTANTS from '../../constants';
import * as CONFIG from '../../config';
import * as UTILS from '../../utils/utilFunctions';
import TEXT_GENERAL from '../../text';
import NATIONALITY_DROPDOWN_CONTENT from '../../general/staticContent/nationalityDropDown';
import NATIONALITY_DROPDOWN_CONTENT_LIENERT from '../../general/staticContent/nationalityDropDownLienert';
import COUNTRY_DROPDOWN_CONTENT from '../../general/staticContent/countryDropDown';
import MARITAL_STATUS_DROPDOWN_CONTENT from '../../general/staticContent/maritalStatusDropDown';
import SALARY_DROPDOWN_CONTENT from '../../general/staticContent/salaryDropDown';
import PART_TIME_EMPLOYMENT_DROPDOWN_CONTENT from '../../general/staticContent/partTimeEmploymentDropDown';

function prepareEmployeesKeyValuePairs(employees) {
  const mappedDropDownEntries = [];
  if (employees && employees.length > 0) {
    if (CONSTANTS.isLienert) {
      employees.forEach(employee => {
        if (employee.lastname && employee.isAdvisor) {
          const value = employee.id;
          const label = `${employee.firstname || ''} ${employee.lastname}`;
          const mappedEmployee = { value, label };
          mappedDropDownEntries.push(mappedEmployee);
        }
      });
    } else {
      employees.forEach(employee => {
        if (employee.lastname) {
          const value = employee.id;
          const label = `${employee.firstname || ''} ${employee.lastname}`;
          const mappedEmployee = { value, label };
          mappedDropDownEntries.push(mappedEmployee);
        }
      });
    }
  }
  return mappedDropDownEntries;
}

class CandidateMainUpdatePopUpContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.form = this.generateFormFields();

    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  updateAPICall = updatedData => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updateCandidateURL;

    const payload = {
      candidateId: this.props.candidateId,
      updateData: updatedData
    };
    if (Object.keys(updatedData).length > 0) {
      console.log(`API ${url} request`, payload);
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(payload),
        timeout: CONFIG.ajaxTimeout
      })
        .done((responseBody, status) => {
          console.log(`API /${url} response`, responseBody, status);
          if (responseBody.statusType === 'error') {
            let snackbarMessage = 'Fehler';
            switch (responseBody.statusMessage) {
              case 'no_country_selected':
                snackbarMessage = 'Land wurde nicht angegeben';
                break;
              case 'no_valid_postal_code':
                snackbarMessage = 'Die eingegebene Postleitzahl ist nicht gültig.';
                break;
              case 'no_valid_postal_code_for_selected_country':
                snackbarMessage = 'Die eingegebene Postleitzahl wurde im angegebenen Land nicht gefunden';
                break;
              default:
                break;
            }
            this.props.handleSnackbarOpen(`${snackbarMessage}. Die Änderungen wurden nicht gespeichert.`);
          } else if (responseBody.statusType === 'info') {
            if (responseBody.statusMessage === 'geo_location_for_country_not_supported') {
              this.props.handleSnackbarOpen(`${TEXT_GENERAL.snackBarTexts.updateSuccess} Postleitzahl und Land werden nicht unterstützt.`);
            } else {
              this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.updateSuccess);
            }
            this.props.refreshCandidate();
            this.props.handleClose();
          }
        })
        .fail(err => {
          this.errorHandling(err, url);
        });
    } else {
      this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.noUpdate);
    }
  };

  generateFormFields() {
    const forms = {
      formFields: {
        salutation: {
          value: this.props.data.salutation,
          label: 'Anrede',
          type: 'dropDown',
          content: ['Herr', 'Frau']
        },
        title: {
          value: this.props.data.title,
          label: 'Titel'
        },
        firstName: {
          value: this.props.data.firstName,
          label: 'Vorname',
          validator: 'isCharsOnly'
        },
        lastName: {
          value: this.props.data.lastName,
          label: 'Nachname',
          validator: 'isCharsOnly'
        },

        street: {
          value: this.props.data.street,
          label: 'Straße',
          customClass: 'col-12 mt-2 mb-2',
          validator: 'isText'
        },
        postalCode: {
          value: this.props.data.postalCode,
          label: 'PLZ',
          validator: 'isPostalCode'
        },
        city: {
          value: this.props.data.city,
          label: 'Ort',
          validator: 'isText'
        },
        country: {
          value: this.props.data.country,
          label: 'Land',
          type: 'dropDown',
          content: COUNTRY_DROPDOWN_CONTENT
        },
        nationality: {
          value: this.props.data.nationality,
          label: 'Nationalität',
          type: 'dropDown',
          content: CONSTANTS.isLienert ? NATIONALITY_DROPDOWN_CONTENT_LIENERT : NATIONALITY_DROPDOWN_CONTENT
        },
        phoneNumber: {
          value: this.props.data.phoneNumber,
          label: 'Telefon',
          validator: 'isPhoneNumber',
          customClass: 'col-6 mt-2 mb-2'
        },
        phoneNumberWork: {
          value: this.props.data.phoneNumberWork,
          label: 'Telefon (Geschäftlich)',
          validator: 'isPhoneNumber',
          customClass: 'col-6 mt-2 mb-2'
        },
        phoneNumberMobile: {
          value: this.props.data.phoneNumberMobile,
          label: 'Mobil',
          validator: 'isPhoneNumber'
        },
        mailAddress: {
          value: this.props.data.mailAddress,
          label: 'Email-Adresse',
          validator: 'isEmail'
        },

        dateOfBirth: {
          value: UTILS.formatDateForDateField(this.props.data.dateOfBirth),
          label: 'Geburtsdatum',
          type: 'date',
          validator: 'isDate',
          customClass: 'col-6 mt-2 mb-2'
        },
        maritalStatus: {
          value: this.props.data.maritalStatus,
          label: 'Familienstand',
          type: 'dropDown',
          content: MARITAL_STATUS_DROPDOWN_CONTENT,
          customClass: 'col-6 mt-2 mb-2'
        },
        children: {
          value: this.props.data.children,
          label: 'Kinder',
          validator: 'isNumerical'
        },

        // divider on detail view

        links__linkedIn: {
          value: this.props.data.links.linkedIn,
          label: 'LinkedIn',
          validator: 'isUrl',
          customClass: 'col-6 mt-2 mb-2'
        },
        links__xing: {
          value: this.props.data.links.xing,
          label: 'Xing',
          validator: 'isUrl',
          customClass: 'col-6 mt-2 mb-2'
        },
        links__facebook: {
          value: this.props.data.links.facebook,
          label: 'Facebook',
          validator: 'isUrl'
        },
        links__twitter: {
          value: this.props.data.links.twitter,
          label: 'Twitter',
          validator: 'isUrl'
        },
        partTimeEmployment: {
          value: this.props.data.partTimeEmployment,
          label: 'Teilzeit',
          type: 'dropDown',
          content: PART_TIME_EMPLOYMENT_DROPDOWN_CONTENT.filter(
            item => item.value !== 101 // remove entry from list with "Keine Angaben"
          )
        },
        partTimeFrom: {
          value: this.props.data.partTimeFrom,
          type: 'number',
          label: 'Pensum von'
        },
        partTimeTill: {
          value: this.props.data.partTimeTill,
          type: 'number',
          label: 'Pensum bis'
        },
        candidateDB: {
          value: this.props.data.candidateDB,
          label: 'Kandidaten DB',

          type: 'dropDown',
          content: [{ label: 'Ja', value: true }, { label: 'Nein', value: false }]
        },
        search: {
          value: this.props.data.search,
          label: 'Search/Ansprache',
          type: 'dropDown',
          content: [{ label: 'Ja', value: 'true' }, { label: 'Nein', value: 'false' }]
        },
        advisor: {
          value: this.props.data.advisor ? this.props.data.advisor.id : '',
          label: 'Betreuer',
          type: 'dropDown',
          content: prepareEmployeesKeyValuePairs(this.props.employees)
        },
        comment: {
          value: this.props.data.comment,
          label: 'Bemerkung',
          customClass: 'col-12 mt-1 mb-2',
          additionalProps: { multiline: true }
        }
      }
    };

    // Only not LIENERT Felder:

    if (!CONSTANTS.isLienert) {
      forms.formFields.desiredSalary = {
        value: this.props.data.desiredSalary,
        label: 'Gehaltswunsch',
        type: 'dropDown',
        content: SALARY_DROPDOWN_CONTENT
      };
      forms.formFields.willingnessToRelocate = {
        value: this.props.data.willingnessToRelocate,
        label: 'Umzugsbereitschaft',
        type: 'dropDown',
        content: ['Ja', 'Nein']
      };

      forms.formFields.mobility = {
        value: this.props.data.mobility,
        label: 'Mobilität',
        type: 'dropDown',
        content: ['< 10 km', '10 - 25 km', '25 - 50 km', '50 - 100 km', '> 100 km']
      };
    }

    // Datenschutz und Links

    return forms;
  }

  render() {
    this.form = this.generateFormFields();
    console.log('DEBUG this.props.data', this.props.data);
    return (
      <GenericFormPopUp
        open={this.props.open}
        headlineText="Kandidatendaten ändern"
        // key="hallo"
        genericFormProps={{
          formFields: this.form.formFields,
          isUpdate: true,
          handleSubmit: this.updateAPICall,
          handleClose: this.props.handleClose
        }}
      />
    );
  }
}
CandidateMainUpdatePopUpContainer.defaultProps = {
  data: {}
};

CandidateMainUpdatePopUpContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,

  // data
  candidateId: PropTypes.string.isRequired,
  data: PropTypes.object,
  // snackBar
  handleSnackbarOpen: PropTypes.func.isRequired,
  refreshCandidate: PropTypes.func.isRequired,
  employees: PropTypes.array.isRequired
};

export default CandidateMainUpdatePopUpContainer;
