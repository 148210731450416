import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

import TEXT from './staticContent/text';
import * as UTILS from '../../utils/utilFunctions';
import * as CONSTANTS from '../../constants';
import GenericArrayForm from '../../generic_components/GenericArrayForm';

class IncomingCandidateJobs extends PureComponent {
  generateFormRows(candidate) {
    const forms = [];
    if (candidate.jobs)
      candidate.jobs.forEach((job, index) => {
        forms.push({
          jobText: {
            value: job.jobText,
            label: TEXT.incomingCandidateJobs.jobText,
            customClass: 'col-6 mb-2'
          },
          company: {
            value: job.company,
            label: TEXT.incomingCandidateJobs.company,
            customClass: 'col-6 mb-2'
          },
          fromDate: {
            value: UTILS.formatDateForDateField(job.fromDate),
            label: TEXT.incomingCandidateJobs.fromDate,
            type: 'date',
            customClass: 'col-6 mb-2'
          },
          tillDate: {
            value: UTILS.formatDateForDateField(job.tillDate),
            label: TEXT.incomingCandidateJobs.tillDate,
            type: 'date',
            customClass: 'col-6 mb-2'
          },
          description: {
            value: job.description,
            label: TEXT.incomingCandidateJobs.description,
            customClass: 'col-12 mb-2',
            multiline: true
          }
        });
        if (CONSTANTS.isLienert)
          forms[index].dateFormatForLists = {
            value: job.dateFormatForLists,
            type: 'dropDown',
            label: 'Datumsformat für Datenexport',
            content: ['Monat und Jahr', 'Nur Jahr']
          };
      });

    return forms;
  }

  generateBaseModel = () => {
    const baseModel = {
      jobText: {
        value: '',
        label: TEXT.incomingCandidateJobs.jobText,
        customClass: 'col-6 mb-2'
      },
      company: {
        value: '',
        label: TEXT.incomingCandidateJobs.company,
        customClass: 'col-6 mb-2'
      },
      fromDate: {
        value: '',
        label: TEXT.incomingCandidateJobs.fromDate,
        type: 'date',
        customClass: 'col-6 mb-2'
      },
      tillDate: {
        value: '',
        label: TEXT.incomingCandidateJobs.tillDate,
        type: 'date',
        customClass: 'col-6 mb-2'
      },
      description: {
        value: '',
        label: TEXT.incomingCandidateJobs.description,
        customClass: 'col-12 mb-2',
        multiline: true
      }
    };
    if (CONSTANTS.isLienert)
      baseModel.dateFormatForLists = {
        value: 'Monat und Jahr',
        type: 'dropDown',
        label: 'Datumsformat für Datenexport',
        content: ['Monat und Jahr', 'Nur Jahr']
      };

    return baseModel;
  };

  handleConfirmButton = updatedData => {
    const update = { jobs: updatedData };
    this.props.liftUpSelectedCandidateToIncomingCandidates(update);

    this.props.handleNext();
  };

  handleMiddleButton = updatedData => {
    let update = { jobs: updatedData };
    if (CONSTANTS.isLienert) {
       update = { jobs: updatedData, educations: [], additionalSkills: [], languageSkills: [], tags: [] };
    }
    
    this.props.liftUpSelectedCandidateToIncomingCandidates(update);

    this.props.handleNext();
    this.props.handleNext();
    this.props.handleNext();
    this.props.handleNext();
    this.props.handleNext();
  };

  renderInputFields() {
    return (
      <GenericArrayForm
        // key={this.props.selectedIncomingCandidate.id}
        formRows={this.generateFormRows(this.props.selectedIncomingCandidate)}
        oldValue={this.generateFormRows(this.props.selectedDuplicateCandidate)}
        newValue={this.generateFormRows(this.props.selectedIncomingCandidate)}
        handleSubmit={this.handleConfirmButton}
        handleCancel={this.props.handleBack}
        handleMiddleButton={this.handleMiddleButton}
        buttonSaveText={TEXT.incomingCandidates.buttonNext}
        title={TEXT.incomingCandidates.jobs}
        buttonCancelText={TEXT.incomingCandidates.buttonBack}
        buttonSubmitText={TEXT.incomingCandidates.buttonNext}
        middleButtonText={TEXT.incomingCandidates.buttonCompleteNow}
        baseModel={this.generateBaseModel()}
      />
    );
  }

  render() {
    return <Paper>{this.renderInputFields()}</Paper>;
  }
}

IncomingCandidateJobs.defaultProps = {
  selectedDuplicateCandidate: {}
};
IncomingCandidateJobs.propTypes = {
  selectedIncomingCandidate: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  liftUpSelectedCandidateToIncomingCandidates: PropTypes.func.isRequired,
  selectedDuplicateCandidate: PropTypes.object
};
export default IncomingCandidateJobs;
