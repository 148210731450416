import * as CONSTANTS from '../../constants';
import { post } from '../../utils/apiUtils';

export function deleteCounterPartAPICall(counterPartId, companyId) {
  const payload = {
    companyId,
    counterPartId
  };
  return post(CONSTANTS.deleteCompanyContactURL, payload);
}

export function toggleChristmasExportAPICall(counterPartId, state) {
  const payload = {
    counterPartId,
    state
  };
  return post(CONSTANTS.toggleChristmasExportURL, payload);
}

export function deletePastContactAPICall(pastContactId, companyId) {
  const payload = {
    companyId,
    pastContactId
  };
  return post(CONSTANTS.deleteCompanyContactHistoryEntryURL, payload);
}

export function getCompanyListByDetailSearch(detailSearchFieldList, maxAmountOfSearchResults) {
  const payload = {
    detailSearchFieldList,
    maxAmountOfSearchResults
  };
  return post(CONSTANTS.getCompanyListByDetailSearchTermsURL, payload);
}

export function getEmployeeListByPartner() {
  return post(CONSTANTS.getEmployeeListByPartnerURL);
}

export function getPartnersOfCompany(includeDeleted) {
  const payload = includeDeleted
    ? {
        includeDeleted
      }
    : undefined;

  return post(CONSTANTS.getAllPartnersOfCompanyURL, payload);
}

export function getCompanyListByIds(companyMarkListId) {
  const payload = {
    companyMarkListId
  };
  return post(CONSTANTS.getCompanyListByIdsURL, payload);
}

export function getCompanyListByCompanyIds(companyIds) {
  const payload = {
    companyIds
  };
  return post(CONSTANTS.getCompanyListByCompanyIdsURL, payload);
}

export function getCompanyCSV() {
  return post(CONSTANTS.exportCompanyCSVURL, {});
}

export function getCompanyListBySearchTerm(searchTerm) {
  const payload = {
    searchTerm
  };
  return post(CONSTANTS.getCompanyListBySearchTermURL, payload);
}

export function getCounterPartReminderListByCompany(companyId) {
  const payload = {
    companyId
  };
  return post(CONSTANTS.getCounterPartReminderListByCompanyURL, payload);
}

export function getProjectByCompanyId(companyId) {
  const payload = {
    companyId
  };
  return post(CONSTANTS.getProjectListCompanyIdURL, payload);
}

export function getCompany(companyId) {
  const payload = {
    companyId
  };
  return post(CONSTANTS.getCompanyURL, payload);
}

export function createProject(companyId, projectNumber) {
  const payload = {
    companyId
  };
  if (projectNumber) payload.projectNumber = projectNumber;
  return post(CONSTANTS.createProjectURL, payload);
}

export function getCompanyProfilePdf(companyId) {
  const payload = {
    companyId
  };
  return post(CONSTANTS.getCompanyProfilePdfURL, payload);
}

export function updateAccessibleProjectListForCounterPart(accessibleProjectList, counterPartId) {
  const payload = {
    counterPartId,
    projectIds: accessibleProjectList
  };
  return post(CONSTANTS.updateAccessibleProjectsURL, payload);
}

export function getAccessibleProjectListForCounterPart(counterPartId) {
  const payload = {
    counterPartId
  };
  return post(CONSTANTS.getAccessibleProjectListForCounterPartURL, payload);
}

/**
 * sends a request to create a user from counter part
 * @param {string} counterPartId
 * @param {string} email
 * @param {string} firstname
 * @param {string} lastname
 * @param {string} phoneNumber
 * @returns {Promise<{success: boolean, message: string}|{success: boolean, createdUser: *}>}
 */
export async function createUserFromCounterPart(counterPartId, email, firstname, lastname, phoneNumberMobile) {
  const payload = {
    counterPartId,
    counterPartData: {
      email,
      firstname,
      lastname,
      phoneNumberMobile
    }
  };
  return post(CONSTANTS.createUserFromCounterPartURL, payload);
}

/**
 * send a post request to changes status to a counter part user
 * @param {string} counterPartId
 * @param {"accountLocked" |"reinvited"} status
 * @returns {Promise<void>}
 */
export async function setCounterPartUserStatus(counterPartId, status) {
  const payload = {
    counterPartId,
    status
  };
  return post(CONSTANTS.updateCounterPartUserStatusURL, payload);
}

/**
 * Sends a post request to find all Counterparts in the companys esindex matching the searchQuery resulting from detailSearchFieldList
 * @param {unknown[]} detailSearchFieldList
 * @returns Promise<unknown>
 */
export async function exportCounterpartsByDetailSearchAPICall(detailSearchFieldList) {
  const payload = { detailSearchFieldList };
  return post(CONSTANTS.getCounterPartByDetailSearchTermsExportURL, payload);
}
