import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';

import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';

function mapUserStatusClass(userStatus) {
  let userStatusClass = 'status-ok';
  if (['accountLocked', 'passwordExpired'].includes(userStatus)) userStatusClass = 'status-error';
  else if (['active'].includes(userStatus)) userStatusClass = 'status-active';
  return userStatusClass;
}

const columns = [
  {
    name: 'ID',
    options: {
      display: 'excluded',
      filter: false
    }
  },
  {
    name: 'Name',
    options: {
      filter: false
    }
  },
  {
    name: 'E-Mail',
    options: {
      customBodyRender: value => {
        const hrefParam = UTILS.parserMailLink(value);
        return <a href={hrefParam}>{value}</a>;
      },
      filter: false
    }
  },
  {
    name: 'Firmenzugehörigkeit',
    options: {
      display: false,
      filter: false
    }
  },
  { name: 'Rollen' },

  {
    name: 'Kontingent',
    options: {
      display: (CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) ? 'true' : 'excluded',
    }
  },

  { name: 'Berater' },
  // {
  //   name: 'Berechtigungen',
  //   options: {
  //     display: false,
  //     filter: false
  //   }
  // },
  { name: 'Partner' },
  {
    name: 'Status',
    options: {
      customBodyRender: userStatus => <div className={`adminPage-userStatus ${mapUserStatusClass(userStatus)}`}>{userStatus}</div>
    }
  },
  {
    name: 'Aktionen',
    options: {
      filter: false
    }
  }
];

class UsersTable extends PureComponent {
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //     };
  //   }

  renderButtons(user) {
    return (
      <div className="adminPage-usersTable__actionButtons">
        {user.roles.find(r => ['admin', 'employee'].includes(r)) && (
          <Tooltip title="Zusatzberechtigungen spezifizieren">
            <Button
              onClick={() => this.props.showEditAdditionalPermissions(user)}
              id={user.email}
              color="secondary"
              // className="editUserButton"
            >
              <i className="material-icons">tune</i>
            </Button>
          </Tooltip>
        )}
        <Tooltip title="Nutzerdaten bearbeiten">
          <Button onClick={() => this.props.showEditUser(user)} id={user.email} color="secondary" className="editUserButton">
            <i className="material-icons">edit</i>
          </Button>
        </Tooltip>
        <Tooltip title="Nutzer entsperren">
          <Button onClick={() => this.props.showResetUser(user.email)} id={user.email} color="secondary" className="resetUserButton">
            <i className="material-icons">lock_open</i>
          </Button>
        </Tooltip>
        <Tooltip title="Nutzer entfernen">
          <Button onClick={() => this.props.showDeleteUser(user.email)} id={user.email} color="secondary" className="deleteUserButton">
            <i className="material-icons">delete</i>
          </Button>
        </Tooltip>
      </div>
    );
  }

  prepareDataForTable(data) {
    const formatedData = [];
    if (data)
      data.forEach(user => {
        const mappedUserName = `${user.firstname !== undefined ? user.firstname : ''} ${user.lastname !== undefined ? user.lastname : ''}`;

        formatedData.push([
          user.id,
          mappedUserName,
          user.email,
          user.companyReference && user.companyReference.name,
          user.roles.join(', '),
          (CONSTANTS.isSenator || CONSTANTS.isPersonalTotal) ? user.stepstoneQuota : undefined,
          UTILS.mapBoolean(user.isAdvisor),
          // user.scope.join(', '),
          user.partner ? `${user.partner.partnerName} (${user.partner.partnerId})` : '',
          user.status,
          this.renderButtons(user)
        ]);
      });
    return formatedData;
  }

  render() {
    return (
      <div>
        <div className="UsersTable" style={{ width: '100%' }}>
          <SafeMuiDatatable
            title="Nutzerverwaltung"
            tableName="UsersTable"
            data={this.prepareDataForTable(this.props.data)}
            columns={columns}
          />
        </div>
      </div>
    );
  }
}

UsersTable.defaultProps = {};
UsersTable.propTypes = {
  data: PropTypes.array.isRequired,
  showDeleteUser: PropTypes.func.isRequired,
  showResetUser: PropTypes.func.isRequired,
  showEditUser: PropTypes.func.isRequired,
  showEditAdditionalPermissions: PropTypes.func.isRequired
};
export default UsersTable;
