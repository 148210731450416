import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import AddAttachmentDropContainer from './AddAttachmentDropContainer';

class AddAttachmentDialog extends PureComponent {
  render() {
    return (
      <Dialog open={this.props.open} onClose={this.props.handleClose}>
        <DialogTitle onClose={this.props.handleClose} />
        <DialogContent>
          <AddAttachmentDropContainer addAttachment={this.props.addAttachment} />
        </DialogContent>
      </Dialog>
    );
  }
}

AddAttachmentDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  addAttachment: PropTypes.func.isRequired
};

export default AddAttachmentDialog;
