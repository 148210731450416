import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

class DrawerWrapper extends PureComponent {
  render() {
    return (
      <Dialog
        fullScreen={this.props.fullScreen}
        open={this.props.open}
        onClose={this.props.onClose}
        TransitionComponent={this.props.transition}
      >
        <AppBar style={{ position: 'relative' }}>
          <Toolbar>
            <IconButton color="inherit" onClick={this.props.onClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit">
              {this.props.title}
            </Typography>
          </Toolbar>
        </AppBar>
        {this.props.children}
      </Dialog>
    );
  }
}

DrawerWrapper.defaultProps = {
  children: <div />,
  fullScreen: true,
  transition: props => <Slide direction="up" mountOnEnter unmountOnExit {...props} />
};

DrawerWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element,
  title: PropTypes.string.isRequired,
  fullScreen: PropTypes.bool,
  transition: PropTypes.func
};

export default DrawerWrapper;
