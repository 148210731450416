import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import FilterList from '@material-ui/icons/FilterList';
import { DatePicker } from '@material-ui/pickers';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import withUser from '../../utils/withUser';
import SafeMuiDatatable from '../../generic_components/SafeMuiDatatable';
import TEXT_GENERAL from '../../text';
import OFFER_CONSTANTS from '../staticContent/offersConstants';
import * as UTILS from '../../utils/utilFunctions';
import { getEmployeeListByPartner, getCompanyListByCompanyIds } from '../../companyArchive/api/companyArchiveAPI';

const OffersTable = ({ offersList, errorHandling, liftUpStateToApp }) => {
  const [employees, setEmployees] = useState([]);
  const [companyData, setCompanyData] = useState([]);
  const [formattedTableData, setFormattedTableData] = useState([]);
  const [initialEarliestDate, setInitialEarliestDate] = useState(new Date());
  const [earliestDate, setEarliestDate] = useState(new Date());
  const [initialLatestDate, setInitialLatestDate] = useState(new Date());
  const [latestDate, setLatestDate] = useState(new Date());
  const [pageLoaded, setPageLoaded] = useState(false);
  const DATE_FORMAT = 'dd.MM.yyyy';
  const TODAY_DATE = useMemo(() => new Date(), []);

  const getColumns = useMemo(
    () => [
      {
        name: TEXT_GENERAL.offers.fields.label.advisor,
        label: TEXT_GENERAL.offers.fields.label.advisor,
        id: 'advisor',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => (
            <Typography className="d-flex" variant="p">
              <Box
                className="ml-2 my-auto"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '100px'
                }}
                variant="p"
              >
                {value}
              </Box>
            </Typography>
          ),
          customDownloadRender: value => value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.date,
        label: TEXT_GENERAL.offers.fields.label.date,
        id: 'date',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => UTILS.formatDateToDELocale(value ? new Date(value) : ''),
          customDownloadRender: value => (value ? UTILS.formatDateToDELocale(new Date(value)) : '')
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.client,
        label: TEXT_GENERAL.offers.fields.label.client,
        id: 'client',
        options: {
          display: true,
          filter: false
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.newClient,
        label: TEXT_GENERAL.offers.fields.label.newClient,
        id: 'newClient',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => TEXT_GENERAL.offers.enum.boolean[value] || value,
          customDownloadRender: value => TEXT_GENERAL.offers.enum.boolean[value] || value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.vacancy,
        label: TEXT_GENERAL.offers.fields.label.vacancy,
        id: 'vacancy',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => (
            <Box
              sx={{
                maxWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': '5',
                '-webkit-box-orient': 'vertical'
              }}
            >
              {value}
            </Box>
          ),
          customDownloadRender: value => value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.taskType,
        label: TEXT_GENERAL.offers.fields.label.taskType,
        id: 'taskType',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => TEXT_GENERAL.offers.enum.taskType[value] || value,
          customDownloadRender: value => TEXT_GENERAL.offers.enum.taskType[value] || value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.directContact,
        label: TEXT_GENERAL.offers.fields.label.directContact,
        id: 'directContact',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => TEXT_GENERAL.offers.enum.boolean[value] || value,
          customDownloadRender: value => TEXT_GENERAL.offers.enum.boolean[value] || value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.flatRate,
        label: TEXT_GENERAL.offers.fields.label.flatRate,
        id: 'flatRate',
        options: {
          display: true,
          filter: true
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.percentage,
        label: TEXT_GENERAL.offers.fields.label.percentage,
        id: 'percentage',
        options: {
          display: true,
          filter: false
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.moreInfo,
        label: TEXT_GENERAL.offers.fields.label.moreInfo,
        id: 'moreInfo',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => (
            <Box
              sx={{
                maxWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': '5',
                '-webkit-box-orient': 'vertical'
              }}
            >
              {value}
            </Box>
          ),
          customDownloadRender: value => value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.status,
        label: TEXT_GENERAL.offers.fields.label.status,
        id: 'status',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => {
            const showValue = TEXT_GENERAL.offers.enum.status[value] || value;
            const valueColor = OFFER_CONSTANTS.status[value] || '';
            return showValue ? (
              <Box
                sx={{
                  p: 1,
                  borderRadius: 10,
                  bgcolor: valueColor,
                  color: valueColor !== '' ? 'white' : '',
                  textAlign: 'center',
                  maxWidth: '85px'
                }}
              >
                {showValue}
              </Box>
            ) : null;
          },
          customDownloadRender: value => TEXT_GENERAL.offers.enum.status[value] || value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.declineReason,
        label: TEXT_GENERAL.offers.fields.label.declineReason,
        id: 'declineReason',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => (
            <Box
              sx={{
                maxWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': '5',
                '-webkit-box-orient': 'vertical'
              }}
            >
              {value}
            </Box>
          ),
          customDownloadRender: value => value
        }
      }
    ],
    []
  );

  const getOptions = useMemo(
    () => ({
      filter: false,
      enableSorting: true,
      customSort: (data, colIndex, order) => UTILS.sortOffersTable(data, colIndex, order, getColumns),
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'sortieren'
        }
      },
      downloadOptions: { filename: 'Offerten-Export.csv' }
    }),
    [getColumns]
  );

  const pickerInputTheme = useMemo(
    () =>
      createTheme({
        overrides: {
          MuiSvgIcon: {
            root: {
              color: 'rgba(0, 0, 0, 0.54)'
            }
          },
          MuiInputBase: {
            input: {
              fontSize: '.9rem',
              textAlign: 'center',
              width: '90px',
              lineHeight: '1',
              height: '1rem',
              transition: 'all ease-in-out .3s',
              cursor: 'pointer',
              '&:hover': {
                color: '#0056b3'
              }
            },
            root: {
              '&:before': {
                content: 'none',
                display: 'none'
              }
            }
          },
          MuiPickersToolbar: {
            toolbar: {
              backgroundColor: '#0a2455'
            }
          },
          MuiPickersDay: {
            daySelected: {
              backgroundColor: '#0a2455',
              '&:hover': {
                backgroundColor: '#0a2455'
              }
            }
          },
          MuiButton: {
            textPrimary: {
              color: '#0a2455'
            }
          }
        }
      }),
    []
  );

  const onDateFieldChange = (date, field) => {
    if (field === 'from') {
      setEarliestDate(date);
    } else if (field === 'to') {
      setLatestDate(date);
    }
  };

  const preparedTableData = useCallback(() => {
    const formattedData = [];

    offersList.forEach(offer => {
      const matchingEmployees = employees.filter(employee => employee.id === offer.userId);
      const itemEmployee = matchingEmployees.length > 0 ? matchingEmployees[0] : null;
      const employeeName = itemEmployee ? `${itemEmployee.firstname} ${itemEmployee.lastname}` : '';
      const company = companyData.find(companyItem => companyItem.id === offer.companyId);

      const mappedData = {
        userId: employeeName,
        date: offer.date,
        client: company ? company.name : '',
        newClient: offer.newClient,
        vacancy: offer.vacancy,
        taskType: offer.taskType,
        directContact: offer.directContact,
        flatRate: typeof offer.flatRate !== 'undefined' && offer.flatRate !== null ? `${offer.flatRate} CHF` : offer.flatRate,
        percentage: typeof offer.percentage !== 'undefined' && offer.percentage !== null ? `${offer.percentage}%` : offer.percentage,
        moreInfo: offer.moreInfo,
        status: offer.status,
        declineReason: offer.declineReason
      };

      // Here I have to check if the offer date is between the earliest and latest date
      const offerDate = new Date(offer.date);
      if (offerDate >= earliestDate && offerDate <= latestDate) {
        formattedData.push(Object.values(mappedData));
      }
    });

    return formattedData;
  }, [offersList, employees, companyData, earliestDate, latestDate]); // Add all external dependencies here

  useEffect(() => {
    const getEmployeesData = async () => {
      try {
        return getEmployeeListByPartner();
      } catch (error) {
        errorHandling(error);
      }
      return null;
    };

    // I NEED A ARRAY WITH UNIQUE COMPANY IDS. First I need to take the offersList and get only the companyIds. Then I make all entries unique.
    const getUniqueCompanyIdArray = () => {
      const companyIdArray = offersList.map(offer => offer.companyId);
      return [...new Set(companyIdArray)];
    };

    async function fetchData() {
      const employeesData = await getEmployeesData();
      const uniqueCompanyIdArray = getUniqueCompanyIdArray();
      const companyDataRaw = await getCompanyListByCompanyIds(uniqueCompanyIdArray);
      // I need to find the earliest date in the offersList
      const earliestDateRaw = offersList.reduce((acc, offer) => {
        const date = new Date(offer.date);
        return date < acc ? date : acc;
      }, new Date());

      const latestDateRaw = offersList.reduce((acc, offer) => {
        const date = new Date(offer.date);
        return date > acc ? date : acc;
      }, new Date());

      setInitialEarliestDate(earliestDateRaw);
      setInitialLatestDate(latestDateRaw);
      setEarliestDate(earliestDateRaw);
      setLatestDate(latestDateRaw);
      setEmployees(employeesData);
      setCompanyData(companyDataRaw);
      setPageLoaded(true);
    }
    fetchData();
  }, [offersList, errorHandling, TODAY_DATE]);

  useEffect(() => {
    // When employees and companyData are loaded, we can prepare the table data
    if (employees.length > 0 && companyData.length > 0) {
      const formattedData = preparedTableData();
      setFormattedTableData(formattedData);
      liftUpStateToApp({ showLoadingIndicatorGlobal: false });
    }
  }, [employees, companyData, offersList, liftUpStateToApp, preparedTableData]);

  return (
    <>
      {pageLoaded ? (
        <MuiThemeProvider theme={pickerInputTheme}>
          <div className="Mui-Custom-Filter mb-3 d-flex">
            <div className="Mui-Custom-Filter-Wrapper d-flex bg-white shadow-sm rounded p-3">
              <div className="Mui-Custom-Filter-Header d-flex">
                <SvgIcon component={FilterList} className="my-auto" />
                <p className="my-auto mx-2 fs-6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                  {TEXT_GENERAL.offers.filter.label}
                </p>
              </div>
              <div className="Mui-Custom-Filter-Body d-flex ml-1">
                <DatePicker
                  label={false}
                  fullWidth
                  id="data_picker_from"
                  key="data_picker_from"
                  className="my-auto fs-6 text-center"
                  format={DATE_FORMAT}
                  onChange={date => onDateFieldChange(date, 'from')}
                  required
                  cancelLabel={TEXT_GENERAL.offers.button.cancel}
                  value={earliestDate || TODAY_DATE}
                  minDate={initialEarliestDate || TODAY_DATE}
                  maxDate={latestDate || initialLatestDate || TODAY_DATE}
                  animateYearScrolling={false}
                />
                <p className="my-auto mx-2 fs-6" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
                  {TEXT_GENERAL.offers.filter.to}
                </p>
                <DatePicker
                  label={false}
                  fullWidth
                  id="data_picker_to"
                  key="data_picker_to"
                  className="my-auto fs-6 text-center"
                  format={DATE_FORMAT}
                  onChange={date => onDateFieldChange(date, 'to')}
                  required
                  cancelLabel={TEXT_GENERAL.offers.button.cancel}
                  value={latestDate || TODAY_DATE}
                  minDate={earliestDate || initialEarliestDate || TODAY_DATE}
                  maxDate={initialLatestDate || TODAY_DATE}
                  animateYearScrolling={false}
                />
              </div>
            </div>
          </div>
        </MuiThemeProvider>
      ) : null}

      <SafeMuiDatatable
        title={
          <Box display="flex">
            <Typography variant="h6">{TEXT_GENERAL.offers.label}</Typography>
          </Box>
        }
        doNotResetPages
        columns={getColumns}
        data={formattedTableData}
        options={getOptions}
        tableName="offersListTable"
        stickyHeader
        disableColumnFilter
        toolbar={false}
      />
    </>
  );
};

OffersTable.propTypes = {
  offersList: PropTypes.arrayOf(PropTypes.object).isRequired,
  errorHandling: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired
};

export default withUser(OffersTable);
