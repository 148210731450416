import $ from 'jquery';
import React from 'react';
import DOMPurify from 'dompurify';
import currency from 'currency.js';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import { clearStoredToken, readToken } from './tokenStorage';

const dateFormatGerman = new Intl.DateTimeFormat('de-DE', {
  day: '2-digit',
  month: '2-digit',
  year: 'numeric'
});
export function getTitle() {
  let title;
  switch (CONSTANTS.partner) {
    case 'matchflix':
      title = 'Match-Flix';
      break;
    case 'personaltotal':
      title = 'Personal Total';
      break;
    case 'senator':
      title = 'Senator';
      break;
    case 'lienert':
      title = 'Jörg Lienert AG';
      break;
    case 'interim':
      title = 'Interim Management Partners';
      break;
    default:
  }
  return title;
}

export function removeURLParam(history, key) {
  if (!history || !history.location || history.location.search === undefined) return false;
  const searchParams = new URLSearchParams(history.location.search);
  searchParams.delete(key);
  history.replace({
    pathname: history.location.pathname,
    search: searchParams.toString()
  });
  return true;
}

export function setURLParam(history, key, value) {
  if (!history || !history.location || history.location.search === undefined) return false;

  const searchParams = new URLSearchParams(history.location.search);
  searchParams.set(key, value);
  history.replace({
    pathname: history.location.pathname,
    search: searchParams.toString()
  });
  return true;
}

export function getURLParam(history, key) {
  if (!history || !history.location || !history.location.search) return undefined;

  const searchParams = new URLSearchParams(history.location.search);
  return searchParams.get(key);
}

export function getHighestUserRole(roles) {
  if (roles.includes('admin')) return 'admin';
  if (roles.includes('employee')) return 'employee';
  return 'client';
}

/**
 * replaces all URLS in the string with <a> tags
 * @param  {String} text                      string which may include URLS
 * @param  {String} [customLinkText='-Link-'] optinal: custom Text for the <a> tag
 * @return {String}                           String including <a> tags
 */
export function urlify(text, customLinkText = '-Link-') {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, url => `<a href="${url}">${customLinkText}</a>`);
}

/**
 * takes an html string and removes all fishy XSS stuff
 * @param  {String} unsafeHTMLString unsafeHTMLString
 * @return {String}                  safeHTMLString
 */
export function sanitize(unsafeHTMLString) {
  return DOMPurify.sanitize(unsafeHTMLString);
}

/**
 * takes a string and returns a sanitized html object
 * @param  {String} htmlString htmlString
 * @return {DOM element}       sanitized DOM Element
 */
export function stringToHTMLCode(htmlString) {
  const cleanHTMLString = sanitize(htmlString);
  return <div dangerouslySetInnerHTML={{ __html: cleanHTMLString }} />;
}
/**
 * remove token from local storage and redirect to login pages
 * token will not removed from mongoDB user collection to keep other devices
 * which have a valid token stored logged in
 */
export function logoutLocalUser() {
  clearStoredToken();
  window.location = '/';
}

export function dataURIToBlob(dataURI) {
  const binStr = atob(dataURI.split(',')[1]);

  const len = binStr.length;

  const arr = new Uint8Array(len);

  for (let i = 0; i < len; i++) {
    arr[i] = binStr.charCodeAt(i);
  }
  return new Blob([arr]);
}

export function convertDateToDateAndTime(dateString) {
  if (dateString && dateString.length > 0)
    return `${new Date(dateString).toLocaleDateString('de-DE')} ${new Date(dateString).toLocaleTimeString('de-DE')}`;
  return '';
}

/**
 * Checks if the testString matches the regex pattern
 * @param  {string}  testString string to test
 * @return {Boolean}            true if string matches the pattern
 */
export function isSearchtermValid(testString) {
  const reg = RegExp(
    /^"[A-Za-z0-9\s&\-öäüÖÄÜß\*#\+@À-ÿćčšž.?:%=/'´`’!]{2,}"$|^((?!\+)(?!\s))[A-Za-z0-9\s&\-öäüÖÄÜß\*#\+@À-ÿćčšž.?:%=/'´`’!]{2,}$/
  );
  return reg.test(testString);
}

/**
 * global error handling for all API calls
 * @param  {objects} err               error object response from API call
 * @param  {function} specificHandleError401    optional: specific handling of http error code 401
 * @param  {array} ignoredErrorCodes optional: error codes which should be ignored
 */
// eslint-disable-next-line
export function errorHandling(err, url, specificHandleError401, ignoredErrorCodes) {
  if (specificHandleError401) this.handleError401 = specificHandleError401.bind(this, 'loggedIn', false);
  else this.handleError401 = logoutLocalUser.bind(this);

  console.log(`API Error handling - error occurred while API call with url ${url} throws error: `, err);
  if (ignoredErrorCodes) {
    console.log('Error handling - following error codes are ignored by this util function: ', ignoredErrorCodes);
  }

  // skip error handling for ignored http error codes (param:ignoreErrorCodes)
  // network connection error is always handled
  // const errorType = err.readyState === 0 ? 'network/timeoutError' : 'httpError';
  if (!ignoredErrorCodes || !ignoredErrorCodes.includes(err.status)) {
    if (err.status === 401) {
      console.log('Error handling - 401 authentication failed');
      this.handleError401();
    } else if (err.status === 403 && this.props.handleSnackbarOpen) {
      this.props.handleSnackbarOpen('Fehlende Berechtigung! Sie können nur Aufträge Ihres Standorts bearbeiten.');
    } else if (this.props.handleSnackbarOpen) {
      /* handle network connection error and other http errors
       * except http error 401 and are not ignoredErrorCodes
       * */
      this.props.handleSnackbarOpen('Es ist ein technischer Fehler aufgetreten');
    } else window.location = '/err';
  }
}

export function logout() {
  const tokenFromLocalStorage = readToken();
  const url = CONSTANTS.logoutURL;
  $.ajax({
    url,
    method: 'POST',
    headers: { 'x-auth': tokenFromLocalStorage },
    timeout: CONFIG.ajaxTimeout
  })
    .done((responseBody, status, xhr) => {
      if (xhr.status === 200) {
        logoutLocalUser();
      }
    })
    .fail(err => {
      errorHandling(err, url);
    });
}

export function convertMillisecondsToMonths(rawValue) {
  const roundedValue = Math.round(rawValue / (31556952000 / 12));
  return roundedValue;
}

export function convertBufferToImage(imageBuffer) {
  return `data:image/jpeg;base64,${Buffer.from(imageBuffer, 'binary').toString('base64')}`;
}

export function convertBufferToImageV2(imageBuffer, fileFormat) {
  return `data:image/${fileFormat};base64,${imageBuffer}`;
}

export function convertBufferToDocx(binaryBuffer, fileFormat) {
  return `data:image/${fileFormat};base64,${binaryBuffer}`;
}

export function convertBufferToPdf(pdfBuffer) {
  return `data:application/pdf;base64,${pdfBuffer}`;
}
function isValidDate(d) {
  // eslint-disable-next-line
  return d instanceof Date && !isNaN(d);
}
export function formatDateToDELocale(date) {
  if (!date || !isValidDate(date)) return '';
  return dateFormatGerman.format(date);
}
export function formatDateToDELocaleTime(date) {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
  };
  return date.toLocaleDateString('de-DE', options);
}
export function formatStringToDELocale(dateString) {
  let formattedDate = '';
  if (!dateString) formattedDate = '';
  else formattedDate = formatDateToDELocale(new Date(dateString));
  return formattedDate;
}

export function formatDateForDateField(dateString) {
  if (dateString instanceof Date) return dateString;
  if (dateString && dateString.length > 0) {
    return new Date(dateString);
  }

  return null;
}
export function formatDateForMuiPickerDateField(dateString) {
  if (dateString && dateString.length > 0) {
    return new Date(dateString);
  }

  return null;
}

export function formatDateForDateFieldNew(date) {
  const d = new Date(date);
  // eslint-disable-next-line
  if (d instanceof Date && !isNaN(d)) {
    let month = `${d.getMonth() + 1}`;

    let day = `${d.getDate()}`;

    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  }
  return '';
}

export function getCurrentDateFormattedForDateField() {
  const currentDate = new Date()
    .toLocaleDateString('de-DE', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    })
    .split('.')
    .reverse()
    .join('-');
  return currentDate;
}

export function parseDate(dateString) {
  let date = new Date('0000-00-00');
  try {
    date = new Date(dateString);
  } catch (err) {
    console.log('Error during parseDate', err);
    // TODO exception handling
  }

  return date;
}
export function calcYearsFromDate(date, roundYear) {
  const actDate = new Date(date);

  let diffInMilliseconds = 0;
  if (date !== null) {
    diffInMilliseconds = Date.now() - actDate.getTime();
  }
  const millisecondsHalfYear = roundYear ? 31556952000 / 2 : 0;
  const ageDate = new Date(diffInMilliseconds + millisecondsHalfYear);
  const diffYears = Math.abs(ageDate.getUTCFullYear() - 1970);
  if (diffYears >= 1) {
    return diffYears;
  }
  return 1;
}
/**
 * Maps a received Score to the number of stars
 * @param  {number} score an elastic search score value
 * @return {JSX}       number of stars depending on the score
 */
export function calcMatchStars(score) {
  // TODO these block requires extensive rework ("bell"-curve not implemented)
  let tmpScore = score;
  const starArray = [];
  // TODO each score value > 5 gets 5 stars, it is wrong
  for (let i = 0; i < 5; i++) {
    if (tmpScore <= 0) starArray.push('star_border');
    else if (tmpScore > 0 && tmpScore < 0.9) starArray.push('star_half');
    else starArray.push('star');
    tmpScore--;
  }
  return starArray.map((element, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <i key={`star${index}`} className="material-icons cl-dred">
      {element}
    </i>
  ));
}

export function mapBoolean(bool) {
  switch (bool) {
    case true:
      return 'Ja';
    case false:
      return 'Nein';
    default:
      return bool;
  }
}

export function mapPotentialCandidateRating(rating) {
  if (CONSTANTS.isLienert)
    switch (rating) {
      case 'A':
        return '1';
      case 'AB':
        return '1-2';
      case 'B':
        return '2';
      case 'C':
        return '3';
      default:
        return '';
    }
  else return rating;
}

export function removeHtmlTags(html) {
  if (!html) return '';
  return html.replace(/(<([^>]+)>)/gi, ' ');
}

export function trimText(text, maxAllowedLength = 50) {
  const textLength = text.length;
  let mappedText = text;
  if (textLength > maxAllowedLength) mappedText = `${text.slice(0, maxAllowedLength)}...`;
  return mappedText;
}

export function mapHTMLToShortenText(html, maxAllowedLength = 50) {
  if (!html) return '';
  let mappedText = removeHtmlTags(html);
  mappedText = trimText(mappedText, maxAllowedLength);
  return mappedText;
}

export function stringToShortenHTMLCode(htmlString, maxAllowedLength = 250, trim = false) {
  if (!htmlString) return '';
  let cleanHTMLString = sanitize(htmlString);
  if (trim) cleanHTMLString = trimText(cleanHTMLString, maxAllowedLength);
  return <div dangerouslySetInnerHTML={{ __html: cleanHTMLString }} />;
}

export function parserMailLink(mailAddress, subject) {
  if (!mailAddress) return '';
  const bbc = CONSTANTS.parserMailAddress();
  const mailLink = `mailto:${mailAddress}?bcc=${bbc}${subject ? `&subject=${encodeURIComponent(subject)}` : ''}`;
  return mailLink;
}

export function downloadBlobFile(fileOrUrl) {
  const a = document.createElement('a');
  const url = fileOrUrl instanceof File ? window.URL.createObjectURL(fileOrUrl) : fileOrUrl;
  a.href = url;
  if (fileOrUrl instanceof File) {
    a.download = fileOrUrl.name;
  } else if (fileOrUrl.includes('base64') && fileOrUrl.split(';')[1].split(':')[0] === 'fileName') {
    // included possibility to define file name for base64 encoded files
    a.download = fileOrUrl.split(';')[1].split(':')[1];
  }

  a.style.display = 'none';
  document.body.appendChild(a);

  a.click();
  if (fileOrUrl instanceof File) {
    window.URL.revokeObjectURL(url);
  }
  document.body.removeChild(a);
}

export function mapIsReferred(isReferred) {
  if (isReferred === false) return 'nein';
  if (isReferred === true) return 'ja';
  return '';
}

export function sortOffersTable(data, colIndex, order, getColumns) {
  const columnName = getColumns[colIndex].id;
  const newSort = data.sort((a, b) => {
    // Check if the value is a boolean
    let aVal = a.data[colIndex].trim();
    let bVal = b.data[colIndex].trim();

    // Sorting currency and float values
    if (columnName === 'flatRate' || columnName === 'percentage') {
      aVal = currency(aVal);
      bVal = currency(bVal);

      if (aVal.value < bVal.value) {
        return 1;
      }
      if (aVal.value > bVal.value) {
        return -1;
      }
    }

    if (aVal < bVal) {
      return -1;
    }
    if (aVal > bVal) {
      return 1;
    }
    return 0;
  });

  if (order === 'asc') {
    // Flip the order if descending
    newSort.reverse();
  }

  // Flip one more time if columnName is date
  if (columnName === 'date') {
    newSort.reverse();
  }

  return newSort;
}

export function getEnumForDropdownList(enumObject) {
  return Object.keys(enumObject).map(key => ({ label: enumObject[key], value: key }));
}

export const formatToCHF = value => currency(value, { symbol: 'CHF ', decimal: '.', separator: "'" });
