import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import './SearchResultCountBox.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import CountUp from 'react-countup';
import TEXT from '../text';
import * as CONFIG from '../config';

class SearchResultCountBox extends PureComponent {
  statusCheck() {
    let color = 'ctm-green-bg';
    let text = TEXT.searchPage.searchResultCountBox.greenText;
    if (this.props.count === 0) {
      color = 'ctm-grey-bg';
      text = TEXT.searchPage.searchResultCountBox.greyText;
    } else if (this.props.count > CONFIG.maxResults) {
      color = 'ctm-red-bg';
      text = TEXT.searchPage.searchResultCountBox.redText;
    }
    return {
      statusColor: color,
      statusText: text
    };
  }

  render() {
    let startCount = this.props.count > 1 ? 1 : 0;
    if (this.props.count > 10) startCount = this.props.count - 9;
    this.statusCheck();
    const countLimites = this.props.count > 10000 ? '> 10000' : '0';

    return (
      <div className="SearchResultCountBox">
        <div className="ctm-blue-bg">
          <p className="SearchResultCountBox-Header">{TEXT.searchPage.searchResultCountBox.title}</p>

          {this.props.showLoadingIndicator ? (
            <CircularProgress className="mb-3" size={100} style={{ color: '#fff' }} />
          ) : (
            <div>
              <p className="SearchResultCountBox-Count m-0 mb3 ">
                {this.props.count === 10001 || this.props.count === 0 ? (
                  countLimites
                ) : (
                  <CountUp end={this.props.count} start={startCount} duration={2} />
                )}
              </p>
            </div>
          )}
        </div>
        <div className="ctm-blue-bg">
          <p className={this.statusCheck().statusColor}>{this.statusCheck().statusText}</p>
        </div>
      </div>
    );
  }
}

SearchResultCountBox.propTypes = {
  showLoadingIndicator: PropTypes.bool.isRequired,
  count: PropTypes.number.isRequired
};

export default SearchResultCountBox;
