import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

// material UI
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import * as CONFIG from '../config';
import DropContainer from '../generic_components/DropContainer';
// import './CompanyImportModal.css';
import * as CONSTANTS from '../constants';
import * as UTILS from '../utils/utilFunctions';

import TEXT from '../text';

class CompanyImportModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      fileLoading: false,
      fileErrorMessage: '',
      fileName: '',
      fileSize: '',
      acceptedFiles: undefined,
      successfullImports: 0,
      failedImports: 0,
      failedRows: [],
      activeStep: 0,
      selectedType: ''
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  getSteps() {
    return ['Format auswählen', 'Datei hochladen', 'Import wird durchgeführt', 'Abgeschlossen'];
  }

  onFileDrop = acceptedFiles => {
    console.log(acceptedFiles);
    if (acceptedFiles && acceptedFiles.length > 0)
      this.setState({
        fileName: acceptedFiles[0].name,
        fileSize: acceptedFiles[0].size,
        acceptedFiles
      });
  };

  onDownloadButtonClick = () => {
    const url = CONSTANTS.downloadExcelExampleFileURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = { type: this.state.selectedType };

    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      let a;
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhttp.response);
        a.download = `${this.state.selectedType}_Vorlage.xlsx`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
      }
    };
    xhttp.open('POST', url);
    xhttp.setRequestHeader('Content-Type', 'application/json');
    xhttp.setRequestHeader('x-auth', tokenFromLocalStorage);
    xhttp.responseType = 'blob';
    xhttp.send(JSON.stringify(requestBody));
  };

  uploadFile = () => {
    let url;
    if (this.state.selectedType === 'Standard') url = CONSTANTS.uploadXlsForCompanyImportURL;
    if (this.state.selectedType === 'Jobfeed') url = CONSTANTS.uploadXlsForJobFeedImportURL;
    this.setState({
      fileErrorMessage: '',
      fileLoading: true
    });

    const request = new FormData();
    this.state.acceptedFiles.forEach((file, index) => {
      request.append(`file-${index}`, file);
    });
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      contentType: false,
      processData: false,
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout * 4,
      data: request
    })
      .done(responseBody => {
        if (responseBody && responseBody.status && responseBody.status === 'success')
          this.setState(prevState => ({
            fileLoading: false,
            successfullImports: responseBody.successfull,
            failedImports: responseBody.failed,
            failedRows: responseBody.failedArray,
            activeStep: prevState.activeStep + 1
          }));
        else if (responseBody && responseBody.status && responseBody.status === 'wrong_format')
          this.setState({
            fileErrorMessage: 'Nicht unterstütztes Format',
            activeStep: 1,
            fileLoading: false
          });
        else if (responseBody && responseBody.status && responseBody.status === 'too_many_entries')
          this.setState({
            fileErrorMessage: 'Zuviele Einträge. Maximal 2000 Einträge',
            activeStep: 1,
            fileLoading: false
          });
      })
      .fail(err => {
        console.log('err.status', err.status);
        const ignoreErrorCodes = [0, 413, 415];
        if (err.status === 413 || err.status === 0) {
          this.setState({
            fileErrorMessage: 'Datei zu groß (maximale Größe 16mb)',
            activeStep: 1,

            fileLoading: false
          });
        } else if (err.status === 415) {
          this.setState({
            fileErrorMessage: 'Nicht unterstütztes Format',
            activeStep: 1,
            fileLoading: false
          });
        }

        this.errorHandling(err, url, null, ignoreErrorCodes);
      });
  };

  renderSelectType() {
    return (
      <div>
        <FormControl>
          <InputLabel htmlFor="format">Bitte Format auswählen</InputLabel>

          <Select
            value={this.state.selectedType}
            onChange={e => this.setState({ selectedType: e.target.value })}
            style={{ minWidth: '200px' }}
          >
            <MenuItem value="Standard">Standard</MenuItem>
            <MenuItem value="Jobfeed">Jobfeed</MenuItem>
          </Select>
        </FormControl>
        <Button
          disabled={this.isNextButtonDisabled()}
          style={{ marginTop: '16px', marginLeft: '20px' }}
          onClick={this.onDownloadButtonClick}
        >
          Vorlage herunterladen
        </Button>
      </div>
    );
  }

  renderUploadFile() {
    return (
      <div>
        <Typography color="error" paragraph variant="body1">
          {this.state.fileErrorMessage}
        </Typography>
        <DropContainer
          dropzoneErrorMessage={this.state.acceptedFiles && `Datei:${this.state.fileName} Größe: ${this.state.fileSize / 1000} KB`}
          showLoadingIndicator={this.state.fileLoading}
          onDrop={this.onFileDrop}
          noClick={false}
          actionDescription={this.props.descriptionText}
          cloudIcon={false}
        >
          <div className="confirmation-dialog-title">
            <i className="material-icons confirmation-dialog-title-icon pb-3">{this.props.icon}</i>
            {this.props.headlineText}
          </div>
        </DropContainer>
      </div>
    );
  }

  getStepContent(step) {
    switch (step) {
      case 0:
        return this.renderSelectType();
      case 1:
        return this.renderUploadFile();
      case 2:
        return (
          <div>
            <div className="row">Dies kann je nach Anzahl der Einträge ein paar Sekunden dauern...</div>
            <div className="row">
              <CircularProgress
                className="mb-3 mt-3"
                size={60}
                thickness={5}
                style={{
                  position: 'relative',
                  left: '50%',
                  marginLeft: '-50px'
                }}
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div>
            <Typography paragraph variant="h6">
              {TEXT.CompanyImportModal.importSuccessfull}
            </Typography>
            <Typography paragraph variant="body1">
              {`${TEXT.CompanyImportModal.successfull} ${this.state.successfullImports}`}
            </Typography>
            <Typography paragraph variant="body1">
              {`${TEXT.CompanyImportModal.failed} ${this.state.failedImports}`}
            </Typography>
            {this.state.failedRows.length > 0 && (
              <Typography paragraph variant="body1">
                {`${TEXT.CompanyImportModal.failedRows} ${this.state.failedRows}`}
              </Typography>
            )}
          </div>
        );
      default:
        return 'Unknown step';
    }
  }

  isNextButtonDisabled() {
    switch (this.state.activeStep) {
      case 0:
        return this.state.selectedType === '';
      case 1:
        return this.state.acceptedFiles === undefined;
      default:
        return false;
    }
  }

  renderButtonArea() {
    const { activeStep } = this.state;
    switch (activeStep) {
      case 22:
        return '';
      case 3:
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.setState({
                fileLoading: false,
                fileErrorMessage: '',
                fileName: '',
                fileSize: '',
                acceptedFiles: undefined,
                successfullImports: 0,
                failedImports: 0,
                failedRows: [],
                activeStep: 0,
                selectedType: ''
              });
              this.props.handleClose();
            }}
          >
            Abschließen
          </Button>
        );
      default:
        return (
          <div>
            <Button disabled={activeStep === 0} onClick={this.handleBack} className="mr-3">
              Zurück
            </Button>
            <Button disabled={this.isNextButtonDisabled()} variant="contained" color="primary" onClick={this.handleNext}>
              Weiter
            </Button>
          </div>
        );
    }
  }

  handleNext = () => {
    if (this.state.activeStep === 1) {
      this.setState(state => ({
        activeStep: state.activeStep + 1
      }));
      this.uploadFile();
    } else {
      this.setState(state => ({
        activeStep: state.activeStep + 1
      }));
    }
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  render() {
    const steps = this.getSteps();
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.handleClose}
          aria-labelledby="form-dialog-title"
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth={false}
        >
          <div>
            <DialogTitle onClose={this.handleClose}>
              Excel Import{' '}
              <IconButton
                disabled={this.state.activeStep === 2}
                aria-label="Close"
                onClick={this.props.handleClose}
                style={{ position: 'absolute', right: '10px', top: '10px' }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent id="confirmation-dialog-content" style={{ minWidth: '800px' }}>
              <Stepper activeStep={this.state.activeStep} orientation="vertical">
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                    <StepContent>{this.getStepContent(index)}</StepContent>
                  </Step>
                ))}
              </Stepper>
            </DialogContent>
            <DialogActions className="confirmation-dialog-actions">{this.renderButtonArea()}</DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}
CompanyImportModal.defaultProps = {
  headlineText: TEXT.CompanyImportModal.headlineText,
  descriptionText: TEXT.CompanyImportModal.descriptionText,

  icon: 'cloud_upload'
};
CompanyImportModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  headlineText: PropTypes.string,
  descriptionText: PropTypes.string,

  icon: PropTypes.string
};
export default CompanyImportModal;
