import TextField from '@material-ui/core/TextField';

import React, { PureComponent, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as validators from '../utils/validators';
import TEXT from '../text';

class ValidatedTextField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isValid: true,
      errorText: ''
    };
  }

  isInputValid = () => {
    let isValid = true;
    let errorText = '';

    if (this.props.required && this.props.value !== undefined && this.props.value.length === 0) {
      errorText = TEXT.validationErrorMessages.isEmpty;
      isValid = false;
    }
    if (
      this.props.value &&
      this.props.value.length !== 0 &&
      validators[this.props.validator] &&
      !validators[this.props.validator].isValid(this.props.value)
    ) {
      errorText = validators[this.props.validator].errorText;
      isValid = false;
    }

    this.setState({ isValid, errorText });
    return isValid;
  };

  render() {
    const { required, customClass, label, ...rest } = this.props;
    const mappedLabel = required ? `${label}*` : label;
    return (
      <TextField {...rest} label={mappedLabel} error={!this.state.isValid} onBlur={this.isInputValid} helperText={this.state.errorText} />
    );
  }
}

ValidatedTextField.defaultProps = {
  required: false,
  validator: 'none',
  customClass: ''
};

ValidatedTextField.propTypes = {
  validator: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  label: PropTypes.string.isRequired
};
export default ValidatedTextField;
/**
 *
 * @typedef UseValidatedTextFieldProps
 * @property {boolean?} fullWidth
 * @property {string?} id
 * @property {boolean?} autoFocus
 * @property {string?} label
 * @property {string?} validator
 * @property {boolean?} required
 * @property {string? | number?} value
 */

/**
 * ValidatedTextField for use with functional components.
 * Accepts the same props as the ValidatedTextField
 * @param {UseValidatedTextFieldProps} props
 * @returns {[
 *  Component,
 *  function(),
 *  any,
 * ]} the rendered component, the validate function and the current value in a
 * destructable array.
 *
 * Use destruction for all values or you will have problems with re-rendering. See the example.
 *
 * Important: Keep in mind to put the separate values in the Dependency arrays (e.g. of useEffect)
 * and not the whole array to avoid recalculations.
 *
 * @example
 * const [phoneNumberComponent, validatePhoneNumber, phoneNumber, setPhoneNumber] = useValidatedTextField({
 *   fullWidth: true,
 *   id: 'phoneNumberMobile',
 *   autoFocus: true,
 *   label: TEXT.companyArchive.addCounterPartPopUp.inputFields.phoneNumberMobile,
 *   validator: 'isPhoneNumber',
 *   required: true,
 *   value: counterPart.contactInfo.phoneNumberMobile
 * });
 *
 * useEffect(() => {
 *  if (validatePhoneNumber()) {
 *    console.log("valid")
 *  }
 * }, [validatePhoneNumber, phoneNumber])
 *
 * return <>{phoneNumberComponent}{phoneNumber}{setPhoneNumber}</>
 */
export const useValidatedTextField = props => {
  const [value, setValue] = useState(props.value);
  const ref = useRef();

  const handleChange = useCallback(e => {
    setValue(e.target.value);
  }, []);

  const validate = useCallback(() => {
    if (ref && ref.current) {
      return ref.current.isInputValid();
    }
    return false;
  }, [ref]);

  return [<ValidatedTextField {...props} key={props.id} onChange={handleChange} value={value} ref={ref} />, validate, value, setValue];
};
