import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import InterimPersonalInformation from './interimProfileTabComponents/InterimPersonalInformation';
import InterimPositionCriteria from './interimProfileTabComponents/InterimPositionCriteria';

class CandidateInterimProfile extends PureComponent {
  renderEmptyContent = () => (
    <div>
      <div className="sideBarWrapper m-2 p-2">
        <div className="candidateArchive-emptyPage">Keine Daten vorhanden</div>
      </div>
    </div>
  );

  renderValidContent = () => (
    <Paper className="p-5">
      <div className="row">
        <div className="col">
          <InterimPositionCriteria
            industrySectors={this.props.interimProfile.positionCriteria.branches}
            positionDescription={this.props.interimProfile.positionCriteria.positionDescription}
            dailyRate={this.props.interimProfile.positionCriteria.dailyRate}
            hierarchyLevels={this.props.interimProfile.positionCriteria.levels}
            isPermanentEmployment={this.props.interimProfile.positionCriteria.isPermanentEmployment}
          />
        </div>
        <div className="col">
          <InterimPersonalInformation personalInformation={this.props.interimProfile.personalInformation} />
        </div>
      </div>
    </Paper>
  );

  render() {
    return <div className="container-fluid">{this.props.interimProfile ? this.renderValidContent() : this.renderEmptyContent()}</div>;
  }
}

CandidateInterimProfile.propTypes = {
  interimProfile: PropTypes.object.isRequired
};

export default CandidateInterimProfile;
