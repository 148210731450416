import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Typography, TableCell } from '@material-ui/core';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import { longListColumns, shortlistColumns } from './ProjectSelectionColumns';
import UploadDocuments from './UploadDocuments';
import useProjectSelection, { ListType } from './hooks/ProjectSelectionHook';
import ProjectSelectionPopUp from './ProjectSelectionPopUp';
import TEXT from './staticContent/text';
import * as CONSTANTS from '../constants';

const printListType = renderListType => {
  switch (renderListType) {
    case ListType.SHORT_LIST:
      return 'Shortlist';
    case ListType.LONG_LIST:
      return 'Longlist';
    case ListType.SELECTION_LIST:
      return 'Selektion';
    case ListType.LATECOMER_LIST:
      return 'Nachzügler';
    default:
      return '';
  }
};

/**
 *
 * @param {ListType} listType
 * @param {PotentialCandidateState} candidate
 */
const getDossierList = (listType, candidate) => {
  switch (listType) {
    case ListType.LONG_LIST:
      if (candidate.showInCustomerPortal) {
        return candidate.candidate.dossierLienertLongList;
      }
      return [];

    case ListType.LATECOMER_LIST:
      if (candidate.isLatecomer) {
        return candidate.candidate.dossierLienertLongList;
      }

      return [];

    case ListType.SHORT_LIST:
      if (candidate.isShortList) {
        return candidate.candidate.dossierLienertShortList;
      }

      return [];

    case ListType.SELECTION_LIST:
      if (candidate) {
        return candidate.candidate.projectDossier;
      }
      return [];

    default:
      return candidate.candidate.dossierLienertShortList;
  }
};

const ProjectSelection = ({
  selectedProject,
  /**
   * @type ListType
   */
  listType,
  /**
   * @type string
   */
  title,
  /**
   * @type (values)=>void
   */
  liftUpStateToApp,
  /**
   * @type (values)=>void
   */
  handleSnackbarOpen,
  /**
   * @type (values) => void
   */
  liftUpSelectedProject,
  /**
   * @type boolean
   */
  dossiersAreSelectableForDownload,
  /**
   * @types (currentRowsSelected: array, allRowsSelected: array) => void
   */
  onRowSelectionChange,
  /**
   * @types (listType: ListType) => void
   */
  onBulkDownloadDossierClick,
  /**
   * @types (listType: ListType) => void
   */
  onDeleteSelectedDossiers,
  /**
   * @types (listType: ListType, hideEmployeeComment: boolean)
   */
  onDeleteAllDossiers,
  /**
   * @types (listType: ListType, hideEmployeeComment: boolean)
   */
  onHandleEmployeeComment,
  /**
   * @type boolean
   */
  dossierBulkDownloadInProgress,
  /**
   * @type () => void
   */
  refreshProject
}) => {
  const {
    isUploadOpen,
    setUploadOpen,
    selectedDossierList,
    prepareDataForTable,
    dialogOpen,
    selectedClientComment,
    handleDialogOpen,
    handleDialogClose,
    selectedRating,
    selectedProceeding,
    selectedPotentialCandidateId,
    selectedCandidateId,
    selectedComment,
    selectedPotentialCandidate,
    selectedHideInCustomerPortal,
    selectedCandidateName
  } = useProjectSelection();

  const getMuiDataTableOptions = useMemo(
    () => ({
      selectableRows: dossiersAreSelectableForDownload ? 'multiple' : 'none',
      fixedSelectColumn: false,
      customToolbarSelect: () => (
        <TableCell style={{ border: 'none' }}>
          <Typography>{TEXT.projectSelection.selectCandidatesForDossierDownload}</Typography>
        </TableCell>
      ),
      setRowProps: row => {
        const candidate = selectedProject.potentialCandidates.find(pC => pC.id === row[0]);
        if (listType === ListType.SELECTION_LIST && CONSTANTS.isLienert && candidate.isLatecomer) {
          return {
            style: { background: '#e0e0e0' }
          };
        }
        return {};
      }
    }),
    [dossiersAreSelectableForDownload, selectedProject.potentialCandidates, listType]
  );

  const handleNavigatePopUp = useCallback(
    index => {
      if (!selectedCandidateId) {
        return;
      }
      /**
       * @type {PotentialCandidateState[]}
       */
      const potentialCandidates = selectedProject.potentialCandidates.filter(candidate => getDossierList(listType, candidate).length > 0);
      /**
       * @type {number}
       */
      const currentIndex = potentialCandidates.findIndex(candidate => candidate.candidateId === selectedCandidateId);

      if (currentIndex < 0 || currentIndex + index < 0 || currentIndex + index >= potentialCandidates.length) {
        return;
      }

      if (getDossierList(listType, potentialCandidates[currentIndex + index]).length < 1) {
        return;
      }
      handleDialogOpen(
        potentialCandidates[currentIndex + index].id,
        potentialCandidates[currentIndex + index].candidateId,
        potentialCandidates[currentIndex + index].rating || '',
        potentialCandidates[currentIndex + index].comment || '',
        potentialCandidates[currentIndex + index].clientCommentLongList || '',
        potentialCandidates[currentIndex + index].furtherProceedingLonglist || '',
        getDossierList(listType, potentialCandidates[currentIndex + index]),
        `${potentialCandidates[currentIndex + index].candidate.firstName} ${potentialCandidates[currentIndex + index].candidate.lastName}`,
        potentialCandidates[currentIndex + index].hideEmployeeCommentInCustomerPortal
      );
    },
    [handleDialogOpen, selectedCandidateId, selectedProject, listType]
  );

  return (
    <div className="m-3">
      <SafeMuiDatatable
        columns={listType === ListType.SHORT_LIST ? shortlistColumns : longListColumns}
        tableName={`projectSelection_${title}`}
        data={prepareDataForTable(selectedProject.potentialCandidates, listType)}
        title={title}
        handleSnackbarOpen={handleSnackbarOpen}
        options={getMuiDataTableOptions}
        onRowSelectionChange={onRowSelectionChange}
      />
      {dossiersAreSelectableForDownload && (
        <Box marginTop={2}>
          <Button onClick={() => onBulkDownloadDossierClick(listType)} disabled={dossierBulkDownloadInProgress}>
            {TEXT.projectSelection.downloadButton}
          </Button>
        </Box>
      )}
      <Box>
        <Button onClick={() => onHandleEmployeeComment(listType, false, true)}>{TEXT.employeeCommentConfirmation.clearButton}</Button>
      </Box>
      <Box>
        <Button onClick={() => onHandleEmployeeComment(listType, true, true)}>{TEXT.employeeCommentConfirmation.hideButton}</Button>
      </Box>
      <Box>
        <Button onClick={() => onDeleteSelectedDossiers(listType, true)}>{TEXT.deleteConfirmation.button}</Button>
      </Box>
      <Box>
        <Button onClick={() => onDeleteAllDossiers(listType, true)}>{TEXT.deleteAllConfirmation.button(printListType(listType))}</Button>
      </Box>
      {listType === ListType.SHORT_LIST && selectedPotentialCandidate && (
        <UploadDocuments
          handleSnackbarOpen={handleSnackbarOpen}
          open={isUploadOpen}
          handlePopupClose={() => {
            setUploadOpen(false);
          }}
          selectedProject={selectedProject}
          selectedPotentialCandidate={selectedPotentialCandidate}
          liftUpSelectedProject={liftUpSelectedProject}
          liftUpStateToApp={liftUpStateToApp}
        />
      )}

      <ProjectSelectionPopUp
        title={TEXT.projectDetailsUpdatePopUp.title(printListType(listType), selectedCandidateName)}
        listType={listType}
        selectedRating={selectedRating}
        selectedProceeding={selectedProceeding}
        selectedComment={selectedComment}
        selectedClientComment={selectedClientComment}
        selectedDossierList={selectedDossierList}
        selectedProject={selectedProject}
        liftUpSelectedProject={liftUpSelectedProject}
        handleClose={handleDialogClose}
        open={dialogOpen}
        refreshProject={refreshProject}
        navigateSelectionPopup={handleNavigatePopUp}
        selectedPotentialCandidateId={selectedPotentialCandidateId}
        selectedCandidateId={selectedCandidateId}
        selectedHideInCustomerPortal={selectedHideInCustomerPortal}
      />
    </div>
  );
};

ProjectSelection.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  selectedProject: PropTypes.object.isRequired,
  liftUpSelectedProject: PropTypes.func.isRequired,
  listType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onRowSelectionChange: PropTypes.func,
  dossiersAreSelectableForDownload: PropTypes.bool,
  onBulkDownloadDossierClick: PropTypes.func,
  dossierBulkDownloadInProgress: PropTypes.bool,
  onDeleteSelectedDossiers: PropTypes.func,
  onDeleteAllDossiers: PropTypes.func,
  onHandleEmployeeComment: PropTypes.func,
  refreshProject: PropTypes.func.isRequired
};

ProjectSelection.defaultProps = {
  onRowSelectionChange: undefined,
  dossiersAreSelectableForDownload: false,
  onBulkDownloadDossierClick: undefined,
  dossierBulkDownloadInProgress: false,
  onDeleteSelectedDossiers: undefined,
  onDeleteAllDossiers: undefined,
  onHandleEmployeeComment: undefined
};
export default ProjectSelection;
