const text = {
  search: {
    searchFieldErrorMessage: 'Bitte geben Sie einen gültigen Suchbegriff ein.' // duplicate in text.js company
  },
  candidateDetails: {
    currentCompany: 'Aktueller Arbeitgeber',
    currentJob: 'Aktuelle Position',
    phoneNumber: 'Telefon',
    phoneNumberMobile: 'Mobil',
    phoneNumberWork: 'Telefon (Geschäftlich)',
    mailAddress: 'Email-Adresse',
    street: 'Straße',
    postalCode: 'Postleitzahl',
    city: 'Ort',
    country: 'Land',
    nationality: 'Nationalität',
    dateOfBirth: 'Geburtsdatum',
    placeOfBirth: 'Geburtsort',
    maritalStatus: 'Familienstand',
    children: 'Kinder',
    desiredSalary: 'Gehaltswunsch',
    mobility: 'Mobilität',
    willingnessToRelocate: 'Umzugsbereitschaft',
    comment: 'Bemerkung',

    candidateId: 'Kandidaten ID',
    creationDate: 'Eingegeben am',
    candidateDB: 'Kandidaten DB',
    search: 'Search/Ansprache',
    advisor: 'Betreuer',

    desiredRaiseForChange: 'Mehr Gehalt in %',
    motivationForChange: 'Motivation für Wechsel',
    channelForInformation: 'Information über Angebote',
    frequencyOfInformation: 'Häufigkeit der Angebote',
    partTimeEmployment: 'Teilzeit',
    dataPrivacy: {
      mailSentDate: 'E-Mail versendet am',
      mailSentBy: '	E-Mail versendet durch',
      mailSentTo: 'E-Mail versendet an',
      firstReminderMailSentDate: 'Erste Datenschutz Email versendet am',
      secondReminderMailSentDate: 'Zweite Datenschutz Email versendet am',
      consentCurrentProject: 'Einverständnis aktuelles Projekt',
      consentAllProjects: 'Einverständnis zukünftige Projekte',
      consentCancellationRight: 'Kenntnis Widerrufsrecht',
      newsletter: 'Newsletter / Marketing'
    }
  },
  addCandidateToProjectPopUp: {
    description: 'Wollen Sie den Kandidaten wirklich als vorgeschlagen zum Auftrag hinzufügen?'
  },
  addCandidateToProjectApplicationPopUp: {
    description: 'Wollen Sie den Kandidaten wirklich als beworben zum Auftrag hinzufügen?'
  },
  projectDetailsUpdatePopUp: {
    headline: 'Auftragsdaten ändern',
    buttons: {
      cancel: 'Abbrechen',
      save: 'Änderung speichern'
    },
    labels: {
      jobTitle1: 'Auftragstitle (Jobbezeichnung 1)',
      status: 'Status:',
      statusDetails: 'Statusgrund',
      serviceType: 'Leistungsart',
      consultingUser: 'Berater',
      editingUser: 'Bearbeitung',
      // jobTitle2: 'Jobbezeichnung 2',
      numberOfPositions: 'Anzahl an Positionen',
      supervisor: 'Vorgesetzer',
      partTime: 'Teilzeit',
      salary: 'Gehaltswunsch',
      comment: 'Interne Notizen'
    }
  },
  actionProtocolCreationPopUp: {
    headline: 'Eintrag hinzufügen',
    buttons: {
      cancel: 'Abbrechen',
      save: 'Speichern'
    },
    labels: {
      type: 'Art des Eintrags',
      description: 'Beschreibung',
      date: 'Datum',
      doneByUser: 'Bearbeiter'
    }
  }
};
module.exports = text;
