import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

// material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ValidatedTextField from '../generic_components/ValidatedTextField';
import ValidatedDropDown from '../generic_components/ValidatedDropDown';

import withUser from '../utils/withUser';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

function prepareEmployeesKeyValuePairs(employees) {
  // DUPLICATE TO COMPANYARCHIVE && CandidateMainiUpdatePopUpContainer
  const mappedDropDownEntries = [];
  if (employees && employees.length > 0) {
    if (CONSTANTS.isLienert) {
      employees.forEach(employee => {
        if (employee.lastname && employee.isAdvisor) {
          const value = employee.id;
          const label = `${employee.firstname || ''} ${employee.lastname}`;
          const mappedEmployee = { value, label };
          mappedDropDownEntries.push(mappedEmployee);
        }
      });
    } else {
      employees.forEach(employee => {
        if (employee.lastname) {
          const value = employee.id;
          const label = `${employee.firstname || ''} ${employee.lastname}`;
          const mappedEmployee = { value, label };
          mappedDropDownEntries.push(mappedEmployee);
        }
      });
    }
  }
  return mappedDropDownEntries;
}
class CounterPartCUPopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      inputFields: {
        salutation: {
          value: this.props.counterPartData.salutation,
          label: 'Anrede',
          type: 'dropDown',
          content: ['Herr', 'Frau']
        },
        title: {
          value: this.props.isUpdate ? this.props.counterPartData.title : '',
          validator: ''
          // required: false
        },
        firstName: {
          value: this.props.isUpdate ? this.props.counterPartData.firstName : '',
          validator: 'isText'
          // required: false
        },
        lastName: {
          value: this.props.isUpdate ? this.props.counterPartData.lastName : '',
          validator: 'isText',
          required: true
        },
        phoneNumber: {
          value: this.props.isUpdate ? this.props.counterPartData.phoneNumber : '',
          validator: 'isPhoneNumberFullyQualified'
          // required: false
        },
        phoneNumberMobile: {
          value: this.props.isUpdate ? this.props.counterPartData.phoneNumberMobile : '',
          validator: 'isPhoneNumberFullyQualified',
          required: false
        },
        faxNumber: {
          value: this.props.isUpdate ? this.props.counterPartData.faxNumber : '',
          validator: 'isPhoneNumber',
          required: false
        },
        mailAddress: {
          value: this.props.isUpdate ? this.props.counterPartData.mailAddress : '',
          validator: 'isEmail',
          required: false
        },
        division: {
          value: this.props.isUpdate ? this.props.counterPartData.division : '',
          validator: 'isText'
        },
        hierarchyLevel: {
          value: this.props.isUpdate ? this.props.counterPartData.hierarchyLevel : '',
          validator: 'isText'
        },
        position: {
          value: this.props.isUpdate ? this.props.counterPartData.position : '',
          validator: 'isText'
        },
        advisor: {
          label: 'Betreuer',
          value: this.props.counterPartData.advisor || this.props.user.id,
          content: [],
          type: 'dropDown'
        }
      }
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
    // validation
    this.inputFieldRefs = [];
    Object.keys(this.state.inputFields).forEach(() => this.inputFieldRefs.push(React.createRef()));
  }

  componentDidMount() {
    this.getEmployeeListByPartnerAPICall();
  }

  getEmployeeListByPartnerAPICall() {
    const url = CONSTANTS.getEmployeeListByPartnerURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        this.setState(prevState =>
          // console.log(
          //   'debug prevState.inputFields.advisor',
          //   prevState.inputFields.advisor
          // );
          ({
            inputFields: {
              ...prevState.inputFields,
              advisor: {
                ...prevState.inputFields.advisor,
                content: prepareEmployeesKeyValuePairs(responseBody)
              }
            }
          })
        );
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  // validation
  handleConfirmButtonClick = () => {
    let fieldsValid = true;
    this.inputFieldRefs.forEach(ref => {
      if (!ref.current.isInputValid()) fieldsValid = false;
    });
    if (this.props.isUpdate && fieldsValid) {
      this.updateCounterPartAPICall();
    } else if (fieldsValid) this.addCounterPartAPICall();
  };

  addCounterPartAPICall = () => {
    const input = { ...this.state.inputFields };
    const requestBody = {
      companyId: this.props.companyId,
      counterPartData: {
        salutation: input.salutation.value,
        title: input.title.value,
        firstName: input.firstName.value,
        lastName: input.lastName.value,
        contactInfo: {
          phoneNumber: input.phoneNumber.value,
          phoneNumberMobile: input.phoneNumberMobile.value,
          faxNumber: input.faxNumber.value,
          mailAddress: this.state.inputFields.mailAddress.value.toLowerCase()
        },
        division: input.division.value,
        hierarchyLevel: input.hierarchyLevel.value,
        advisor: input.advisor.value,
        position: input.position.value
        // map advisor
      }
    };
    const url = CONSTANTS.createCompanyContactURL;
    console.log(`API ${url} request`, requestBody);
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.props.handleSnackbarOpen(TEXT.companyArchive.addCounterPartPopUp.snackBarText.addCounterPartCall.success);
        this.props.handleClose(true);
      })
      .fail(err => {
        const ignoreErrorCodes = [500];
        this.errorHandling(err, url, null, ignoreErrorCodes);
        if (err.status === 409) {
          // counterPart already exists as a user (mail already exists)
          this.props.handleSnackbarOpen(TEXT.companyArchive.addCounterPartPopUp.snackBarText.addCounterPartCall.emailAlreadyExists);
        }
        if (err.status === 500) {
          this.props.handleSnackbarOpen(TEXT.companyArchive.addCounterPartPopUp.snackBarText.addCounterPartCall.failure);
        }
      });
  };

  updateCounterPartAPICall() {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const input = { ...this.state.inputFields };
    const requestBody = {
      companyId: this.props.companyId,
      counterPartId: this.props.counterPartId,
      counterPartData: {
        salutation: input.salutation.value,
        title: input.title.value,
        firstName: input.firstName.value,
        lastName: input.lastName.value,
        contactInfo: {
          phoneNumber: input.phoneNumber.value,
          phoneNumberMobile: input.phoneNumberMobile.value,
          faxNumber: input.faxNumber.value,
          mailAddress: this.state.inputFields.mailAddress.value && this.state.inputFields.mailAddress.value.toLowerCase()
        },
        division: input.division.value,
        hierarchyLevel: input.hierarchyLevel.value,
        advisor: input.advisor.value,
        position: input.position.value
      }
    };
    const url = CONSTANTS.updateCounterPartDetailsURL;
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.props.handleSnackbarOpen(TEXT.companyArchive.addCounterPartPopUp.snackBarText.updateCounterPartCall.success);

        const selectedAdvisor = this.state.inputFields.advisor.content.filter(
          advisor => advisor.value === this.state.inputFields.advisor.value
        );
        const liftUpData = {
          id: this.props.counterPartId,
          ...requestBody.counterPartData,
          status: this.props.counterPartData.status,
          advisor: {
            id: this.state.inputFields.advisor.value,
            name: selectedAdvisor.length ? selectedAdvisor[0].label : ''
          }
        };
        this.props.liftUpUpdateCounterPartDetails(this.props.counterPartId, liftUpData);
        this.props.handleClose(true);
      })
      .fail(err => {
        const ignoreErrorCodes = [500];
        this.errorHandling(err, url, null, ignoreErrorCodes);
        if (err.status === 409) {
          // counterPart already exists as a user (mail already exists)
          this.props.handleSnackbarOpen(TEXT.companyArchive.addCounterPartPopUp.snackBarText.updateCounterPartCall.emailAlreadyExists);
        }
        if (err.status === 500) {
          this.props.handleSnackbarOpen(TEXT.companyArchive.addCounterPartPopUp.snackBarText.updateCounterPartCall.failure);
        }
      });
  }

  handleCancelButtonClick = () => {
    this.props.handleClose(false);
  };

  isConfirmButtonDisabled() {
    const inputFields = Object.keys(this.state.inputFields);
    let buttonDisabled = false;
    inputFields.forEach(key => {
      if (!this.state.inputFields[key].valid) buttonDisabled = true;
    });
    return buttonDisabled;
  }

  onInputFieldChange = e => {
    let inputFieldValue = e.target.value;
    const inputFieldKey = e.target.id;
    if (inputFieldKey === 'mailAddress') inputFieldValue = inputFieldValue.toLowerCase().trim();
    this.setState(prevState => ({
      inputFields: {
        ...prevState.inputFields,
        [inputFieldKey]: {
          ...prevState.inputFields[inputFieldKey],
          value: inputFieldValue
        }
      }
    }));
  };

  onDropDownChange = e => {
    const inputFieldValue = e.target.value;
    const inputFieldKey = e.target.name;
    this.setState(prevState => ({
      inputFields: {
        ...prevState.inputFields,
        [inputFieldKey]: {
          ...prevState.inputFields[inputFieldKey],
          value: inputFieldValue
        }
      }
    }));
  };

  renderInputFields() {
    const inputFields = Object.keys(this.state.inputFields);
    return (
      <div className="row">
        {inputFields.map((inputFieldId, index) => (
          <div key={inputFieldId} className="col-6 mt-1 mb-1">
            {this.state.inputFields[inputFieldId].type === 'dropDown' ? (
              <ValidatedDropDown
                id={inputFieldId}
                key={inputFieldId}
                ref={this.inputFieldRefs[index]}
                content={this.state.inputFields[inputFieldId].content}
                onChange={this.onDropDownChange}
                {...this.state.inputFields[inputFieldId]}
              />
            ) : (
              <ValidatedTextField
                fullWidth
                id={inputFieldId}
                autoFocus={index === 0}
                ref={this.inputFieldRefs[index]}
                onChange={this.onInputFieldChange}
                {...this.state.inputFields[inputFieldId]}
                label={TEXT.companyArchive.addCounterPartPopUp.inputFields[inputFieldId]}
              />
            )}
          </div>
        ))}
      </div>
    );
  }

  renderPopUp() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>
          {this.props.isUpdate ? TEXT.companyArchive.addCounterPartPopUp.headlineUpdate : TEXT.companyArchive.addCounterPartPopUp.headline}
        </DialogTitle>
        <DialogContent>
          <div className="container">{this.renderInputFields()}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="primary">
            {TEXT.companyArchive.addCounterPartPopUp.buttons.cancel}
          </Button>
          <Button
            onClick={this.handleConfirmButtonClick}
            // disabled={this.isConfirmButtonDisabled()}
            color="primary"
          >
            {TEXT.companyArchive.addCounterPartPopUp.buttons.save}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    // console.log('debug counterPartData', this.props.counterPartData);
    return <div>{this.renderPopUp()}</div>;
  }
}

CounterPartCUPopUp.defaultProps = {
  isUpdate: false,
  counterPartData: {},
  counterPartId: ''
};

CounterPartCUPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  counterPartData: PropTypes.object,
  companyId: PropTypes.string.isRequired,
  counterPartId: PropTypes.string,
  liftUpUpdateCounterPartDetails: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
};
export default withUser(CounterPartCUPopUp);
