import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';

import AddAttachment from './SerialMailAttachment/AddAttachment';
import RemoveAttachments from './SerialMailAttachment/RemoveAttachments';
import AttachmentList from './SerialMailAttachment/AttachmentList';

class SerialMailAttachment extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { showAddAttachmentDialog: false };
  }

  addAttachment = newAttachments => {
    const updatedAttachments = [...this.props.attachments, ...newAttachments];
    this.props.liftUpAttachments(updatedAttachments);
  };

  handleAddAttachment = attachment => {
    this.addAttachment(attachment);
    this.closeAddAttachmentDialog();
  };

  openAddAttachmentDialog = () => {
    this.setState({ showAddAttachmentDialog: true });
  };

  closeAddAttachmentDialog = () => {
    this.setState({ showAddAttachmentDialog: false });
  };

  removeAttachments = () => {
    this.props.liftUpAttachments([]);
  };

  render() {
    return (
      <Fragment>
        <h6>E-Mail Anhänge ({this.props.attachments.length})</h6>
        <AddAttachment
          addAttachment={this.handleAddAttachment}
          showAddAttachmentDialog={this.state.showAddAttachmentDialog}
          openAddAttachmentDialog={this.openAddAttachmentDialog}
          closeAddAttachmentDialog={this.closeAddAttachmentDialog}
        />
        {!!this.props.attachments.length && <RemoveAttachments removeAttachments={this.removeAttachments} />}
        <AttachmentList attachments={this.props.attachments} />
      </Fragment>
    );
  }
}

SerialMailAttachment.defaultProps = {
  attachments: []
};

SerialMailAttachment.propTypes = {
  attachments: PropTypes.array,
  liftUpAttachments: PropTypes.func.isRequired
};

export default SerialMailAttachment;
