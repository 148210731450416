import PropTypes from 'prop-types';

const CounterPartPopUpPropTypes = {
  counterPart: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  afterSubmit: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default CounterPartPopUpPropTypes;
