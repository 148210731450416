import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import StarRatingComponent from 'react-star-rating-component';
import text from '../../../text';

class MatchPercentageFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.wasLoaded = false;
  }

  /**
   * OnChange method for the StarRating component, calls the onFilterChange function
   * @param  {number} value number of selected newSetFilterParameters
   */
  onChange(nextValue, prevValue, name, e) {
    let newStarRating = nextValue;
    const xPos = (e.pageX - e.currentTarget.getBoundingClientRect().left) / e.currentTarget.offsetWidth;
    if (xPos <= 0.5) {
      newStarRating -= 0.5;
    }
    this.props.onFilterChange('matchPercentage', newStarRating, this.filter);
  }

  /**
   * Filter Method of this FilterComponent, defines on which criteria the results should be filtered
   * @param  {object} result     the object which will be tested
   * @param  {number} parameters number of selected stars
   * @return {bool}            true, if the score of the result is >= the amount of selected stars
   */
  filter(result, parameters) {
    return result.score + 0.2 >= parameters;
  }

  render() {
    // show 5 stars in case of default props
    const currentStarValue = this.props.matchPercentage === 0 ? 5 : this.props.matchPercentage;
    return (
      <div className="col-12">
        <p>{text.filter.mainFilter.matchPercentage}</p>

        <div className="star-rating-filter ">
          <StarRatingComponent
            name="matchPercentageFilter"
            value={currentStarValue}
            onStarClick={this.onChange}
            renderStarIcon={(index, value) => (
              <span>
                <i className="material-icons cl-dred ratingStars">{index <= value ? 'star' : 'star_border'}</i>
              </span>
            )}
            renderStarIconHalf={() => (
              <span>
                <i className="material-icons cl-dred ratingStars">star_half</i>
              </span>
            )}
          />
        </div>
      </div>
    );
  }
}
MatchPercentageFilter.defaultProps = {};
MatchPercentageFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  matchPercentage: PropTypes.number.isRequired
};
export default MatchPercentageFilter;
