import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { List, ListItem } from '@material-ui/core';
import './AttachmentList.css';

class AttachmentList extends PureComponent {
  calculateMegabyte(byteSize) {
    if (!byteSize || typeof byteSize !== 'number') return 0;
    return Math.round((byteSize / 1024 / 1024) * 1000) / 1000;
  }

  render() {
    return (
      <div className="attachment-list">
        <List dense>
          {this.props.attachments.map(attachment => (
            <ListItem>
              {attachment.name} ({this.calculateMegabyte(attachment.size)} MB)
            </ListItem>
          ))}
        </List>
      </div>
    );
  }
}

AttachmentList.defaultProps = {
  attachments: []
};

AttachmentList.propTypes = {
  attachments: PropTypes.array
};

export default AttachmentList;
