import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// material UI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import TEXT from '../text';
// import * as CONSTANTS from '../constants';
// import * as CONFIG from '../config';
// import * as UTILS from '../utils/utilFunctions';

class AlertModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
          <div className="confirmation-dialog">
            <DialogTitle>
              <div className="confirmation-dialog-title">
                <i className="material-icons confirmation-dialog-title-icon pb-3">{this.props.icon}</i>
                {this.props.headlineText}
              </div>
            </DialogTitle>
            <DialogContent>{this.props.children}</DialogContent>
            <DialogActions>
              <Button onClick={this.props.buttonAction || this.props.handleClose} color="secondary" variant="contained">
                {this.props.buttonText}
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      </div>
    );
  }
}
AlertModal.defaultProps = {
  headlineText: TEXT.ConfirmationModal.headlineText,
  buttonText: TEXT.ConfirmationModal.buttonPrimaryText,
  buttonAction: null,
  icon: 'warning',
  children: null
};
AlertModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  buttonAction: PropTypes.func,
  headlineText: PropTypes.string,
  children: PropTypes.object,
  buttonText: PropTypes.string,
  icon: PropTypes.string
};
export default AlertModal;
