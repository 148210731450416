import { Box, Button, Divider, FormControlLabel, Grid, Paper, Switch, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from '@material-ui/core/Slider';
import FormControl from '@material-ui/core/FormControl';
import styles from './ProjectCustomerInfoTab.module.css';
import PostalCodeAutosuggest from '../components/PostalCodeAutosuggest';

import PdfViewerModal from '../generic_components/file_util_components/PdfViewerModal';
import { updateProjectCustomerInformations, getRequirementsPDF } from './api/projectArchiveAPI';

const SALARY_MARKS = [4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25].map(v => ({
  value: v * 10000,
  label: `${v * 10}k`
}));
const AGE_MARKS = [1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 99].map(v => ({
  value: v,
  label: v
}));

const WORK_EXPERIENCE_MARKS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(v => ({
  value: v,
  label: v
}));

const PERCENT_SLIDER = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(v => ({
  value: v,
  label: `${v}%`
}));

const Section = ({ title, children }) => (
  <div className="mb-4">
    <Typography variant="h5" component="h5">
      {title}
    </Typography>
    <Divider className="mb-3" />
    <Grid container spacing={2}>
      {children}
    </Grid>
  </div>
);
Section.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
};

const Field = ({ label, ...props }) => (
  <Grid className="px-4" item md={12}>
    <div className={styles.label}>{label}</div>
    <TextField multiline fullWidth {...props} />
  </Grid>
);
Field.propTypes = {
  label: PropTypes.string.isRequired
};
const AutoSuggestField = ({ label, ...props }) => (
  <Grid className="px-4" item md={12}>
    <div className={styles.label}>{label}</div>
    <PostalCodeAutosuggest {...props} />
  </Grid>
);
AutoSuggestField.propTypes = {
  label: PropTypes.string.isRequired
};

const LabelSwitch = ({ label, ...props }) => (
  <Grid className="px-4" item md={12} xs={12}>
    <FormControl>
      <FormControlLabel control={<Switch {...props} />} label={label} />
    </FormControl>
  </Grid>
);
LabelSwitch.defaultProps = {
  label: ''
};
LabelSwitch.propTypes = {
  label: PropTypes.string
};

const RangeSlider = ({ min, max, step, value, label, marks, ...props }) => (
  <Grid className="px-4" item xs={12}>
    <div className={styles.label}>{label}</div>
    <Box px={5}>
      <Slider aria-labelledby={`${value}_${label}`} marks={marks} max={max} min={min} step={step} value={value || 0} {...props} />
    </Box>
  </Grid>
);
RangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(Number)]).isRequired,
  label: PropTypes.string.isRequired,
  marks: PropTypes.arrayOf(Number).isRequired
};
const countryNameMap = country => {
  switch (country) {
    case 'germany':
      return 'Deutschland';
    case 'switzerland':
      return 'Schweiz';
    case 'austria':
      return 'Österreich';
    default:
      return country;
  }
};

export const ProjectCustomerInfoForm = ({ selectedProject, liftUpSelectedProject, liftUpStateToApp, handleSnackbarOpen }) => {
  const [showPreview, setShowPreview] = useState(false);
  // Position / Profile
  const [title, setTitle] = useState('');
  const [address, setAddress] = useState('');
  const [tasks, setTasks] = useState('');
  const [superiors, setSuperiors] = useState('');
  const [subordinates, setSubordinates] = useState('');
  const [respBudget, setRespBudget] = useState();
  const [departmentStructure, setDepartmentStructure] = useState('');
  const [specialTasks, setSpecialTasks] = useState('');
  const [reportLine, setReportLine] = useState('');
  const [other, setOther] = useState('');

  // Candidate Requirements
  const [age, setAge] = useState([0, 99]);
  const [workExperience, setWorkExperience] = useState(0);
  const [education, setEducation] = useState('');
  const [career, setCareer] = useState('');
  const [skills, setSkills] = useState('');
  const [qualifications, setQualifications] = useState('');
  const [travelActivity, setTravelActivity] = useState(0);
  const [leadershipExp, setLeadershipExp] = useState(false);
  const [goalForPosition, setGoalForPosition] = useState('');
  const [softSkills, setSoftSkills] = useState('');
  const [koCriteria, setKoCriteria] = useState('');

  // History Postition
  const [previousChanges, setPreviousChanges] = useState('');
  const [reasonsForEstablishment, setReasonsForEstablishment] = useState('');
  const [recruiting, setRecruiting] = useState('');

  // Endowment
  const [salary, setSalary] = useState([0, 0]);
  const [maxSalary, setMaxSalary] = useState(0);
  const [performanceComponentsSalary, setPerformanceComponentsSalary] = useState('');
  const [benefits, setBenefits] = useState('');
  const [perspective, setPerspective] = useState('');
  const [workingTime, setWorkingTime] = useState('');
  const [homeOfficeHandling, setHomeOfficeHandling] = useState('');
  const [familiarization, setFamiliarization] = useState('');

  // More fields
  const [companyInformation, setCompanyInformation] = useState('');
  const [blacklist, setBlacklist] = useState('');
  const [whitelist, setWhitelist] = useState('');
  const [advertLink, setAdvertLink] = useState('');
  const [moreOther, setMoreOther] = useState('');

  const [addressErrorText, setAddressErrorText] = useState(undefined);
  const [addressSearchValue, setAddressSearchValue] = useState(undefined);

  const handleLoadRequirementsPDF = () => {
    liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    return getRequirementsPDF(selectedProject.id)
      .then(responseBody => {
        const file = `data:application/pdf;fileName:Anforderungsprofil${selectedProject.projectNumber};base64,${responseBody.buffer}`;
        liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        return file;
      })
      .catch(err => {
        handleSnackbarOpen('Fehler beim Laden des PDFs');
        liftUpStateToApp({ showLoadingIndicatorGlobal: false });
        setShowPreview(false);
        console.error(err);
      });
  };

  const handleDownloadRequirementsDocumentButtonClick = useCallback(async () => {
    setShowPreview(true);
  }, []);

  const handlePreviewClose = useCallback(() => {
    setShowPreview(false);
  }, []);

  const handleSumbit = () => {
    if (!address) {
      handleSnackbarOpen('Bitte geben Sie eine Adresse ein');
      setAddressErrorText('Bitte Adresse auswählen!');
      return;
    }

    setAddressErrorText(undefined);
    const updateData = {
      title,
      address: address.id,
      tasks,
      superiors,
      subordinates,
      respBudget,
      departmentStructure,
      specialTasks,
      reportLine,
      other,
      age,
      workExperience,
      education,
      career,
      skills,
      qualifications,
      travelActivity,
      leadershipExp,
      goalForPosition,
      previousChanges,
      softSkills,
      koCriteria,
      reasonsForEstablishment,
      recruiting,
      salary,
      maxSalary,
      workingTime,
      homeOfficeHandling,
      familiarization,
      companyInformation,
      blacklist,
      whitelist,
      performanceComponentsSalary,
      benefits,
      perspective,
      advertLink,
      moreOther
    };

    updateProjectCustomerInformations(selectedProject.id, updateData)
      .then(() => {
        handleSnackbarOpen('Erfolgreich gespeichert');
        const selectedProjectWithCustomerInformation = Object.assign(selectedProject, { customerInformation: updateData });
        selectedProjectWithCustomerInformation.customerInformation.address = address;
        const updatedSelectedProject = Object.assign(selectedProject, {
          customerInformation: selectedProjectWithCustomerInformation.customerInformation
        });

        liftUpSelectedProject(updatedSelectedProject);
      })
      .catch(() => {
        handleSnackbarOpen('Fehler beim Speichern');
      });
  };

  const geoLocationOnBlur = useCallback(() => {
    if (!address && addressSearchValue.length > 1) {
      setAddressErrorText('Keine gültige Eingabe');
    }
  }, [address, addressSearchValue]);

  const geoLocationOnFocus = useCallback(() => {
    // setAddress(undefined);
    // setAddressSearchValue('');
  }, []);

  const geoLocationSelection = useCallback(value => {
    setAddressErrorText();
    setAddress(value);
    setAddressSearchValue(`${value.postalCode} -- ${value.city} -- ${countryNameMap(value.country)}`);
  }, []);

  useEffect(() => {
    if (selectedProject.customerInformation) {
      setTitle(selectedProject.customerInformation.title);
      setTasks(selectedProject.customerInformation.tasks);
      setSuperiors(selectedProject.customerInformation.superiors);
      setSubordinates(selectedProject.customerInformation.subordinates);
      setRespBudget(selectedProject.customerInformation.respBudget);
      setDepartmentStructure(selectedProject.customerInformation.departmentStructure);
      setSpecialTasks(selectedProject.customerInformation.specialTasks);
      setReportLine(selectedProject.customerInformation.reportLine);
      setOther(selectedProject.customerInformation.other);
      if (selectedProject.customerInformation.age) {
        setAge(
          selectedProject.customerInformation.age.length === 1
            ? [selectedProject.customerInformation.age[0], selectedProject.customerInformation.age[0] + 1]
            : selectedProject.customerInformation.age
        );
      }
      setWorkExperience(selectedProject.customerInformation.workExperience || 0);
      setEducation(selectedProject.customerInformation.education);
      setCareer(selectedProject.customerInformation.career);
      setSkills(selectedProject.customerInformation.skills);
      setQualifications(selectedProject.customerInformation.qualifications);
      setTravelActivity(selectedProject.customerInformation.travelActivity);
      setLeadershipExp(selectedProject.customerInformation.leadershipExp);
      setGoalForPosition(selectedProject.customerInformation.goalForPosition);
      setSoftSkills(selectedProject.customerInformation.softSkills);
      setKoCriteria(selectedProject.customerInformation.koCriteria);
      setPreviousChanges(selectedProject.customerInformation.previousChanges);
      setReasonsForEstablishment(selectedProject.customerInformation.reasonsForEstablishment);
      setRecruiting(selectedProject.customerInformation.recruiting);
      if (selectedProject.customerInformation.salary) {
        setSalary(
          selectedProject.customerInformation.salary.length === 1
            ? [selectedProject.customerInformation.salary[0], selectedProject.customerInformation.salary[0] + 1]
            : selectedProject.customerInformation.salary
        );
      }
      setMaxSalary(selectedProject.customerInformation.maxSalary);
      setWorkingTime(selectedProject.customerInformation.workingTime);
      setHomeOfficeHandling(selectedProject.customerInformation.homeOfficeHandling);
      setFamiliarization(selectedProject.customerInformation.familiarization);
      setPerformanceComponentsSalary(selectedProject.customerInformation.performanceComponentsSalary || 0);
      setBenefits(selectedProject.customerInformation.benefits);
      setPerspective(selectedProject.customerInformation.perspective);
      setCompanyInformation(selectedProject.customerInformation.companyInformation);
      setBlacklist(selectedProject.customerInformation.blacklist);
      setWhitelist(selectedProject.customerInformation.whitelist);
      setAdvertLink(selectedProject.customerInformation.advertLink);
      setMoreOther(selectedProject.customerInformation.moreOther);
      geoLocationSelection(selectedProject.customerInformation.address);
    }
  }, [geoLocationSelection, selectedProject.customerInformation]);

  return (
    <div className="container-fluid">
      {showPreview && (
        <PdfViewerModal
          file={`Anforderungsprofil_${selectedProject.projectNumber}.pdf`}
          open={showPreview}
          onResolveFileUrl={handleLoadRequirementsPDF}
          onClose={handlePreviewClose}
        />
      )}

      <Paper className="p-3">
        <Box textAlign="right">
          <Button
            variant="contained"
            color="primary"
            disabled={!selectedProject.customerInformation}
            onClick={handleDownloadRequirementsDocumentButtonClick}
          >
            Download
          </Button>
        </Box>
        <Section title="Position / Stellenprofil">
          <Field value={title} onChange={e => setTitle(e.target.value)} label="Position" />
          <AutoSuggestField
            onSearchFieldChange={value => {
              setAddressErrorText();
              setAddressSearchValue(value);
              setAddress(undefined);
            }}
            label="Arbeitsort*"
            onSearchFieldBlur={geoLocationOnBlur}
            onSearchFieldFocus={geoLocationOnFocus}
            hasError={addressErrorText !== undefined}
            errorText={addressErrorText}
            onSelection={geoLocationSelection}
            value={addressSearchValue}
            placeholder=""
          />
          <Field value={tasks} onChange={e => setTasks(e.target.value)} label="Aufgaben, Schwerpunkte" />
          <Field value={superiors} onChange={e => setSuperiors(e.target.value)} label="Führungsverantwortung / MA Gesamt" />
          <Field value={subordinates} onChange={e => setSubordinates(e.target.value)} label="Direct Reports" />
          <Field value={departmentStructure} onChange={e => setDepartmentStructure(e.target.value)} label="Struktur der Abteilungen" />
          <Field value={respBudget} onChange={e => setRespBudget(e.target.value)} label="Verantwortung für Budget / Umsatz etc." />
          <Field value={specialTasks} onChange={e => setSpecialTasks(e.target.value)} label="Standard- / Spezialfunktion" />
          <Field value={reportLine} onChange={e => setReportLine(e.target.value)} label="Berichtslinie" />
          <Field value={other} onChange={e => setOther(e.target.value)} label="Highlights der Position / Sonstige Punkte" />
        </Section>
        <Section title="Anforderungen an den Kandidaten">
          <RangeSlider marks={AGE_MARKS} min={1} max={99} label="Alter" step={1} value={age} onChange={(_, newValue) => setAge(newValue)} />
          <RangeSlider
            marks={WORK_EXPERIENCE_MARKS}
            min={0}
            max={15}
            label="Berufserfahrung"
            step={1}
            value={workExperience}
            onChange={(_, newValue) => setWorkExperience(newValue)}
          />
          <Field value={education} onChange={e => setEducation(e.target.value)} label="Ausbildung" />
          <Field value={career} onChange={e => setCareer(e.target.value)} label="Berufliche Entwicklung" />
          <Field value={skills} onChange={e => setSkills(e.target.value)} label="Fachkenntnisse" />
          <Field value={qualifications} onChange={e => setQualifications(e.target.value)} label="Zusatzqualifikationen" />
          <RangeSlider
            max={100}
            label="Reisetätigkeit"
            min={0}
            value={travelActivity}
            marks={PERCENT_SLIDER}
            step={5}
            onChange={(_, newValue) => setTravelActivity(newValue)}
          />
          <LabelSwitch
            name="leadershipExp"
            checked={leadershipExp}
            onChange={() => setLeadershipExp(!leadershipExp)}
            label="Führungserfahrung erforderlich"
          />
          <Field
            value={goalForPosition}
            onChange={e => setGoalForPosition(e.target.value)}
            label="Ziele der Position / anstehende Projekte"
          />
          <Field value={perspective} onChange={e => setPerspective(e.target.value)} label="Perspektive / Weiterentwicklungsmöglichkeiten" />
          <Field value={softSkills} onChange={e => setSoftSkills(e.target.value)} label="Soft Skills" />
          <Field value={koCriteria} onChange={e => setKoCriteria(e.target.value)} label="K.O.-Kriterien" />
        </Section>
        <Section title="Vorgeschichte der Position">
          <Field value={previousChanges} onChange={e => setPreviousChanges(e.target.value)} label="Erfolgte Wechsel" />
          <Field
            value={reasonsForEstablishment}
            onChange={e => setReasonsForEstablishment(e.target.value)}
            label="Gründe für die Einrichtung der Position"
          />
          <Field value={recruiting} onChange={e => setRecruiting(e.target.value)} label="Bisherige Rekrutierungsbemühungen" />
        </Section>
        <Section title="Dotierung">
          <RangeSlider
            marks={SALARY_MARKS}
            max={250000}
            min={40000}
            label="Zieljahreseinkommen"
            step={1000}
            value={salary < 40000 ? 40000 : salary}
            onChange={(_, newValue) => setSalary(newValue)}
          />
          <RangeSlider
            max={100}
            label="Erfolgsbestandteile im Gehalt"
            min={0}
            value={Number.parseInt(performanceComponentsSalary, 10)}
            marks={PERCENT_SLIDER}
            step={5}
            onChange={(_, newValue) => setPerformanceComponentsSalary(newValue)}
          />
          <Field value={benefits} onChange={e => setBenefits(e.target.value)} label="Sozialleistungen / Benefits" />
          <Field value={workingTime} onChange={e => setWorkingTime(e.target.value)} label="Arbeitszeit / Urlaub" />
          <Field value={homeOfficeHandling} onChange={e => setHomeOfficeHandling(e.target.value)} label="Home Office Regelung" />
          <Field value={familiarization} onChange={e => setFamiliarization(e.target.value)} label="Einarbeitung" />
        </Section>
        <Section title="Weitere Angaben">
          <Field value={companyInformation} onChange={e => setCompanyInformation(e.target.value)} label="Informationen zum Unternehmen" />
          <Field value={blacklist} onChange={e => setBlacklist(e.target.value)} label="Sperrvermerke / Blacklist" />
          <Field value={whitelist} onChange={e => setWhitelist(e.target.value)} label="Wunschtargets" />
          <Field value={advertLink} onChange={e => setAdvertLink(e.target.value)} label="Link zu bestehender Anzeige" />
          <Field value={moreOther} onChange={e => setMoreOther(e.target.value)} label="Sonstiges" />
        </Section>
        <div className="ml-3 mb-3">
          <label className="text-secondary">Alle Felder mit * sind Pflichtfelder</label>
        </div>
        <Button color="primary" onClick={() => handleSumbit()} variant="contained" fullWidth>
          Speichern
        </Button>
      </Paper>
    </div>
  );
};

ProjectCustomerInfoForm.propTypes = {
  selectedProject: PropTypes.object.isRequired,
  liftUpSelectedProject: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default ProjectCustomerInfoForm;
