import React, { useCallback, useEffect, useState } from 'react';
import { AppBar, Dialog, IconButton, Toolbar, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import getUserActivities from './api/userActivitiesAPI';

const useTransition = props => <Slide direction="up" mountOnEnter unmountOnExit {...props} />;
/**
 * Checks if a string is in a valid json
 * @param {string} str
 * @return {boolean}
 */
const isValidJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

const Translation = {
  projectNumber: 'Auftragsnummer',
  candidate: 'Kandidat',
  fileName: 'Dateiname',
  oldComment: 'Bemerkung alt',
  newComment: 'Bemerkung neu',
  user: 'Benutzer',
  clickEventLocation: 'Listentyp'
};

const columnDef = [
  {
    name: 'creationDate',
    label: 'Datum',
    options: {
      customBodyRender: value => {
        const date = new Date(value);
        const formattedDate = new Intl.DateTimeFormat('de-DE', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }).format(date);
        return <p>{formattedDate}</p>;
      }
    }
  },
  {
    name: 'actionType',
    label: 'Aktion'
  },
  {
    name: 'actionSubType',
    label: 'Beschreibung',
    options: {
      /**
       *
       * @param {string} value
       */
      customBodyRender: value => {
        const [key, ...rest] = value.split('-');
        const details = rest.join('-');

        if (isValidJsonString(details)) {
          const obj = JSON.parse(details);
          const entrys = Object.entries(obj);

          return (
            <>
              <Typography className="mb-3">{key}</Typography>
              {entrys.map(entry => (
                <p>
                  {Translation[entry[0]]}: {entry[1]}
                </p>
              ))}
            </>
          );
        }
        return value;
      }
    }
  }
];

const ActionType = {
  view: 'Ansicht',
  login: 'Login',
  logout: 'Logout',
  download: 'Download',
  downloadDocument: 'Download',
  register: 'Registrierung',
  updateUser: 'Benutzerdaten geändert',
  verifyTan: 'TAN verifiziert',
  modify: 'Änderung'
};

const ActionSubType = {
  none: '',
  potentialCandidates: 'Kandidatenliste',
  accountLocked: 'Gesperrt',
  reinvited: 'Passwort zurückgesetzt',
  changeMobileNumber: 'Mobilfunknummer geändert',
  passwordSet: 'Password vergeben',
  candidateDossier: 'Dossier',
  documents: 'Dokumente',
  initialLogin: '',
  updateComment: 'Bemerkung geändert',
  active: ''
};

const CounterPartUserHistory = ({ isOpen, handleClose, counterPartId }) => {
  const [history, setHistory] = useState([]);

  const requestData = useCallback(() => {
    getUserActivities(counterPartId)
      .then(response => {
        const filteredHistory = response.activities.filter(activity => activity.actionType !== 'verifyTan');
        setHistory(filteredHistory);
      })
      .catch(err => {
        console.error(err);
      });
  }, [counterPartId]);

  const handleCloseEvent = useCallback(() => {
    setHistory([]);
    handleClose();
  }, [handleClose]);

  useEffect(() => {
    if (isOpen) {
      requestData();
    }
  }, [isOpen, requestData]);

  /**
   * Prepares data to be parsed into DataTable
   * @param {UserActivityDTO[]} - data
   * @return {[][]}
   */
  const prepareData = useCallback(data => {
    const result = [];
    data.forEach(el => {
      const details = el.actionDetails !== 'none' ? ` - ${el.actionDetails}` : '';
      const description = ActionSubType[el.actionSubType] + details;
      result.push([el.creationDate, ActionType[el.actionType], description]);
    });
    return result;
  }, []);

  return (
    <Dialog open={isOpen} fullScreen onClose={handleClose} TransitionComponent={useTransition}>
      <AppBar style={{ position: 'relative' }}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleCloseEvent} aria-label="Close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" color="inherit">
            Benutzerverlauf
          </Typography>
        </Toolbar>
      </AppBar>
      <div className="m-5">
        <SafeMuiDatatable
          columns={columnDef}
          data={prepareData(history)}
          title="Benutzer Aktivitäten"
          options={{
            filter: false,
            download: false,
            expandableRowsHeader: false,
            fixedHeader: false,
            print: false,
            selectableRows: false,
            selectableRowsHeader: false
          }}
        />
      </div>
    </Dialog>
  );
};

CounterPartUserHistory.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  counterPartId: PropTypes.string.isRequired
};

export default CounterPartUserHistory;
