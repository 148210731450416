import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import GenericFormPopUp from '../../generic_components/GenericFormPopUp';
import TEXT from '../../general/incomingCandidates/staticContent/text';

class CandidateUpdateDocumentNamePopUpContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.form = this.generateFormFields();
  }

  generateFormFields() {
    const form = {
      formFields: {
        newName: {
          value: this.props.oldFileName,
          label: 'Dateiname',
          required: true,
          customClass: 'col-12 mb-2'
        }
      }
    };
    return form;
  }

  handleSubmit(data) {
    let newFileName = data.newName;
    // prevent user from changing file type
    const newFileNameType = newFileName.split('.')[newFileName.split('.').length - 1];
    const oldFileNameType = this.props.oldFileName.split('.')[this.props.oldFileName.split('.').length - 1];
    if (newFileNameType !== oldFileNameType) {
      newFileName = `${newFileName}.${oldFileNameType}`;
    }
    this.props.updateDocumentName(this.props.oldFileName, newFileName, this.props.documentId);
  }

  render() {
    this.form = this.generateFormFields();
    return (
      <GenericFormPopUp
        open={this.props.open}
        headlineText={TEXT.incomingCandidates.renameDocument.title}
        genericFormProps={{
          formFields: this.form.formFields,
          handleSubmit: data => this.handleSubmit(data),
          handleClose: this.props.handleClose,
          buttonSaveText: TEXT.incomingCandidates.renameDocument.save
        }}
      />
    );
  }
}

CandidateUpdateDocumentNamePopUpContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  oldFileName: PropTypes.string.isRequired,
  updateDocumentName: PropTypes.func.isRequired,
  documentId: PropTypes.string
};

CandidateUpdateDocumentNamePopUpContainer.defaultProps = {documentId: undefined}

export default CandidateUpdateDocumentNamePopUpContainer;
