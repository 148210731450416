import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import * as FILTER_HELPER from '../../../utils/filterHelperFunctions';

import TEXT from '../../../text';

class ShowMarked extends PureComponent {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  /**
   * OnAfterChange method for the ShowMarkedFilter component, calls the onFilterChange function
   * @param  {array} range array of values of the two handlers of slider
   */
  onChange(event, checked) {
    this.props.onFilterChange('showMarked', checked, FILTER_HELPER.filterFunctions.showMarked);
  }

  render() {
    return (
      <div className="col-12 pt-5 pb-5">
        <FormControlLabel
          className="checkFilterLabel"
          control={<Checkbox className="chkBox_" checked={this.props.showMarked} onChange={this.onChange} />}
          label={`${TEXT.filter.mainFilter.showMarked.headline}`}
        />
      </div>
    );
  }
}
ShowMarked.defaultProps = {};
ShowMarked.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  showMarked: PropTypes.bool.isRequired
};
export default ShowMarked;
