import React, { useState } from 'react';

import PropTypes from 'prop-types';
import {
  CircularProgress,
  makeStyles,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Tooltip,
  ListItemIcon,
  Checkbox
} from '@material-ui/core';
import {
  RemoveRedEye as EyeIcon,
  Delete as DeleteIcon,
  InsertDriveFile as FileIcon,
  CloudDownload as CloudDownloadIcon,
  FormatColorText as FormatColorTextIcon
} from '@material-ui/icons';
import PdfViewerModal from './PdfViewerModal';
import CandidateUpdateDocumentNamePopUpContainer from '../../candidateArchive/popUps/CandidateUpdateDocumentNamePopUpContainer';

const useStyles = makeStyles({
  fileRow: {
    '& .hoverIcon': {
      display: 'none'
    },
    '&:hover .icon': {
      display: 'none'
    },
    '&:hover .hoverIcon': {
      display: 'inline-block'
    }
  }
});

const FileList = ({
  readOnly,
  disabled,
  fileNameList,
  onDelete,
  onResolveFileUrl,
  onDownload,
  extendedItemActions,
  updateDocumentName,
  handleDocumentSelect
}) => {
  const classes = useStyles();
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [pdfFile, setPdfFile] = useState();
  const [actionItemIndex, setActionItemIndex] = useState();
  const [viewFileId, setViewFileId] = useState();
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [documentFileName, setDocumentFileName] = useState('');
  const [documentFileId, setDocumentFileId] = useState();

  function view(file, documentId) {
    setPdfFile(file);
    if (documentId) {
      setViewFileId(documentId);
    }
    setPdfViewerOpen(true);
  }

  const renderItemAvatar = (letterAvatar, deleting) => (
    <ListItemAvatar>{deleting ? <CircularProgress color="primary" /> : <Avatar>{letterAvatar || <FileIcon />}</Avatar>}</ListItemAvatar>
  );

  const renderIconButton = (ariaLabel, icon, tooltipMessage, onClick) => (
    <Tooltip title={tooltipMessage} placement="top">
      <IconButton edge="end" aria-label={ariaLabel} disabled={disabled} onClick={onClick} style={{ marginLeft: '15px' }}>
        {icon}
      </IconButton>
    </Tooltip>
  );

  const handleUpdateDocumentNameClick = file => {
    setDocumentFileId(file.entry.id);
    setDocumentFileName(file.entry.documentName);
    setRenameDialogOpen(true);
  };

  const renderItemActions = (file, index) => (
    <ListItemSecondaryAction>
      {extendedItemActions ? extendedItemActions(file.name || file, file) : ''}
      {updateDocumentName &&
        renderIconButton('view', <FormatColorTextIcon color="primary" />, 'Dokument umbenennen', () => handleUpdateDocumentNameClick(file))}
      {renderIconButton('view', <EyeIcon color="primary" />, 'Dokument anzeigen', () =>
        file.entry ? view(file.name || file, file.entry.id) : view(file.name || file)
      )}
      {onDownload &&
        renderIconButton('download', <CloudDownloadIcon color="primary" />, 'Dokument speichern', () => {
          onDownload(file.name || file, file.entry);
        })}
      {onDelete &&
        renderIconButton('delete', <DeleteIcon color="primary" />, 'Dokument löschen', () => {
          setActionItemIndex(index);
          onDelete(file.name || file, file.entry, index);
        })}
    </ListItemSecondaryAction>
  );

  const renderListItem = (file, index) => (
    <ListItem key={(file.entry && file.entry.id) || file.name || file} disabled={disabled} className={classes.fileRow}>
      {index === actionItemIndex && disabled ? (
        <>
          {renderItemAvatar(file.letterAvatar, true)}
          <ListItemText primary="Diese Datei wird gelöscht" />
        </>
      ) : (
        <>
          {file.entry && file.entry.selected !== undefined && (
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={file.entry.selected}
                tabIndex={-1}
                disableRipple
                onClick={() => handleDocumentSelect(file.entry.id)}
              />
            </ListItemIcon>
          )}
          {renderItemAvatar(file.letterAvatar)}
          <ListItemText primary={file.name || file} secondary={file.secondaryText} />
        </>
      )}
      {!readOnly && renderItemActions(file, index)}
    </ListItem>
  );

  return (
    <>
      <List dense>{[fileNameList || []].flat().map((fileName, index) => renderListItem(fileName, index))}</List>
      {pdfFile && (
        <PdfViewerModal
          file={pdfFile}
          open={pdfViewerOpen}
          onResolveFileUrl={viewFileId ? fileName => onResolveFileUrl(fileName, viewFileId) : onResolveFileUrl}
          onClose={() => {
            setPdfViewerOpen(false);
            setViewFileId(undefined);
            setPdfFile(undefined);
          }}
        />
      )}
      <CandidateUpdateDocumentNamePopUpContainer
        open={renameDialogOpen}
        handleClose={() => setRenameDialogOpen(false)}
        oldFileName={documentFileName}
        updateDocumentName={updateDocumentName}
        documentId={documentFileId}
      />
    </>
  );
};

FileList.propTypes = {
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  fileNameList: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
  handleDocumentSelect: PropTypes.func,
  onDelete: PropTypes.func,
  onDownload: PropTypes.func,
  onResolveFileUrl: PropTypes.func.isRequired,
  updateDocumentName: PropTypes.func.isRequired,
  extendedItemActions: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.element])
};

FileList.defaultProps = {
  onDelete: undefined,
  onDownload: undefined,
  readOnly: false,
  disabled: false,
  fileNameList: [],
  handleDocumentSelect: () => {},
  extendedItemActions: undefined
};

export default FileList;
