import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

// material UI
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

// components
import CompanyDetails from './CompanyDetails';
import CompanyContacts from './CompanyContacts';
import CompanyComments from './CompanyComments';
import NotificationList from '../notifications/NotificationList';
import ProjectResult from '../projectArchive/ProjectResult';
import DocumentPanel from '../generic_components/DocumentPanel';
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import OffersPanel from '../offers/components/OffersPanel';

class SingleCompanyResult extends PureComponent {
  reloadNotifications = () => {
    this.props.getCounterPartReminderListByCompanyAPICall(this.props.selectedCompany.id);
  };

  onProjectNumberClick = (value, metaData) => <Link to={`projects?id=${metaData.rowData[0]}`}>{value}</Link>;

  render() {
    const mainColumnClass = this.props.showCompanyDetailsColumn ? 'col-10 pl-0' : 'col-12';
    return (
      <div>
        <div className="row navidation">
          <div className="col-12">
            <Tabs
              value={this.props.currentTab}
              onChange={(event, value) => {
                UTILS.setURLParam(this.props.history, 't', value);

                this.props.liftUpStateObjectToCompanyArchive({
                  currentTab: value
                });
              }}
              indicatorColor="primary"
              textColor="primary"
              centered
            >
              <Tab label="Stammdaten" />
              <Tab label="Dokumente" />
              {CONSTANTS.isLienert && <Tab label="Offerten" />}
            </Tabs>
          </div>
        </div>
        <div className="SingleCompanyResult row">
          {this.props.showCompanyDetailsColumn && (
            <div className="col-2 pr-0">
              <div className="col-12 mt-3 pr-0">
                <CompanyDetails
                  selectedCompany={this.props.selectedCompany}
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  refreshCompany={this.props.refreshCompany}
                  liftUpStateToCompanyArchive={this.props.liftUpStateToCompanyArchive}
                  partners={this.props.partners}
                  employees={this.props.employees}
                  openContactHistoryForPhone={this.props.openContactHistoryForPhone}
                  liftUpStateObjectToCompanyArchive={this.props.liftUpStateObjectToCompanyArchive}
                  liftUpStateToApp={this.props.liftUpStateToApp}
                />
              </div>
            </div>
          )}
          {this.props.currentTab === 0 && (
            <div className={mainColumnClass}>
              <div className="col-12 mt-3">
                <CompanyContacts
                  refreshCompany={this.props.refreshCompany}
                  setLoading={this.props.setLoading}
                  isLoading={this.props.isLoading}
                  data={this.props.counterParts}
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  companyId={this.props.selectedCompany.id}
                  liftUpUpdateCounterPartDetails={this.props.liftUpUpdateCounterPartDetails}
                  preSelectedCounterPartId={this.props.preSelectedCounterPartId}
                  openContactHistoryForPhone={this.props.openContactHistoryForPhone}
                  removeCounterPart={this.props.removeCounterPart}
                  projects={this.props.projects}
                />
              </div>
              <div className="col-12 mt-3">
                <CompanyComments
                  liftUpUpdatePastContactDetails={this.props.liftUpUpdatePastContactDetails}
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  companyId={this.props.selectedCompany.id}
                  data={this.props.pastContacts}
                  counterParts={this.props.counterParts}
                  handleClosePastContactCreateUpdatePopUp={this.props.handleClosePastContactCreateUpdatePopUp}
                  employees={this.props.employees}
                  removePastContact={this.props.removePastContact}
                />
              </div>
              <div className="col-12 mt-3">
                <NotificationList
                  notifications={this.props.notifications}
                  onlyReminders
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  onIsReadButtonClick={this.reloadNotifications}
                  onDeleteButtonClick={this.reloadNotifications}
                  onRowClick={this.props.liftUpStateToApp}
                  onEditButtonClick={this.reloadNotifications}
                />
              </div>
              <div className="col-12 mt-5">
                <ProjectResult
                  projectList={this.props.projects}
                  projectNumberCustomBodyRenderer={this.onProjectNumberClick}
                  inCompanyArchive
                />
              </div>
            </div>
          )}
          {this.props.currentTab === 1 && (
            <div className={mainColumnClass}>
              <div className="col-12 mt-3">
                <DocumentPanel
                  key={`${this.props.selectedCompany.id}documents`}
                  documentList={this.props.selectedCompany.documents}
                  id={this.props.selectedCompany.id}
                  deleteURL={CONSTANTS.deleteCompanyDocumentURL}
                  getDocumentURL={CONSTANTS.getCompanyDocumentByIdURL}
                  uploadDocumentURL={CONSTANTS.uploadCompanyDocumentsURL}
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  liftUpStateToApp={this.props.liftUpStateToApp}
                  archive="company"
                  liftUpDocuments={this.props.liftUpSelectedCompany}
                />
              </div>
            </div>
          )}
          {CONSTANTS.isLienert && this.props.currentTab === 2 && (
            <div className={mainColumnClass}>
              <div className="col-12 mt-3">
                <OffersPanel
                  key={`${this.props.selectedCompany.id}documents`}
                  handShowOffersCreateUpdatePopUp={this.props.handShowOffersCreateUpdatePopUp}
                  selectedCompany={this.props.selectedCompany}
                  handleSnackbarOpen={this.props.handleSnackbarOpen}
                  errorHandling={this.props.errorHandling}
                  loadOffers={this.props.loadOffers}
                  offerData={this.props.offerData}
                  handleChangeTablePageNumber={this.props.handleChangeTablePageNumber}
                  offerTablePaging={this.props.offerTablePaging}
                  selectedOffer={this.props.selectedOffer}
                  setSelectedOffer={this.props.setSelectedOffer}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

SingleCompanyResult.defaultProps = {
  preSelectedCounterPartId: undefined,
  isLoading: false
};
SingleCompanyResult.propTypes = {
  selectedCompany: PropTypes.object.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  counterParts: PropTypes.array.isRequired,
  pastContacts: PropTypes.array.isRequired,
  notifications: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
  refreshCompany: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  showCompanyDetailsColumn: PropTypes.bool.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  liftUpStateToCompanyArchive: PropTypes.func.isRequired,
  liftUpUpdateCounterPartDetails: PropTypes.func.isRequired,
  liftUpUpdatePastContactDetails: PropTypes.func.isRequired,
  getCounterPartReminderListByCompanyAPICall: PropTypes.func.isRequired,
  preSelectedCounterPartId: PropTypes.string,
  handleClosePastContactCreateUpdatePopUp: PropTypes.func.isRequired,
  openContactHistoryForPhone: PropTypes.func.isRequired,
  partners: PropTypes.array.isRequired,
  employees: PropTypes.array.isRequired,
  liftUpStateObjectToCompanyArchive: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  currentTab: PropTypes.number.isRequired,
  removeCounterPart: PropTypes.func.isRequired,
  removePastContact: PropTypes.func.isRequired,
  liftUpSelectedCompany: PropTypes.func.isRequired,
  errorHandling: PropTypes.func.isRequired,
  loadOffers: PropTypes.func.isRequired,
  offerData: PropTypes.object.isRequired,
  handleChangeTablePageNumber: PropTypes.func.isRequired,
  offerTablePaging: PropTypes.object.isRequired,
  handShowOffersCreateUpdatePopUp: PropTypes.func.isRequired,
  selectedOffer: PropTypes.object.isRequired,
  setSelectedOffer: PropTypes.func.isRequired
};
export default withRouter(SingleCompanyResult);
