import { useState } from 'react';
import sortProjectApplications from '../utils/sortProjectApplications';

export const ListType = {
  SHORT_LIST: 'shortList',
  LONG_LIST: 'longList',
  LATECOMER_LIST: 'latecomerList',
  SELECTION_LIST: 'selectionList'
};

export const FurtherProceeding = Object.freeze({
  INVITE: 'Einladen',
  CALL: 'Anruf',
  ON_HOLD: 'On Hold',
  DECLINE: 'Absagen DB ja',
  DECLINEDB: 'Absagen DB nein',
  WITHDRAWN: 'zurückgezogen'
});
/**
 * This hook will handle all data within the project selection and als components within it
 *
 * it will also handle dialog data and open states
 * and prepare data for the safeMuiDatatable
 */
const useProjectSelection = () => {
  // Dialog and upload open states
  const [isUploadOpen, setUploadOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  // The popup data with can be edited by user
  const [selectedPotentialCandidateId, setSelectedPotentialCandidateId] = useState('');
  const [selectedCandidateId, setSelectedCandidateId] = useState('');
  const [selectedRating, setSelectedRating] = useState('');

  const [selectedComment, setSelectedComment] = useState('');
  const [selectedClientComment, setSelectedClientComment] = useState('');
  const [selectedProceeding, setSelectedProceeding] = useState('');
  const [selectedCandidateName, setSelectedCandidateName] = useState('');
  const [selectedDossierList, setSelectedDossierList] = useState(
    /**
     * @type ProjectDossierListEntry[]
     */
    []
  );
  const [anchorElCusomerCommentsList, setAnchorElCustomerCommentList] = useState({});
  const [anchorElProjectCommentsList, setAnchorElProjectCommentList] = useState({});
  const [selectedHideInCustomerPortal, setSelectedHideInCustomerPortal] = useState(true);
  const [selectedPotentialCandidate, setSelectedPotentialCandidate] = useState('');

  const formatDateToDELocale = (date, dateFormatForLists) => {
    // toIsoString ignores Timezone
    // https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset
    const dateString = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
    if (dateFormatForLists === 'Nur Jahr') {
      return dateString.split('-')[0];
    }
    return `${dateString.split('-')[1]}.${dateString.split('-')[0]}`;
  };

  const mapDuration = latestJob => {
    if (latestJob.fromDate && latestJob.tillDate) {
      return `${formatDateToDELocale(new Date(latestJob.fromDate), latestJob.dateFormatForLists)} - ${formatDateToDELocale(
        new Date(latestJob.tillDate),
        latestJob.dateFormatForLists
      )}`;
    }
    if (latestJob.fromDate && !latestJob.tillDate)
      return `seit ${formatDateToDELocale(new Date(latestJob.fromDate), latestJob.dateFormatForLists)}`;
    return '';
  };

  /**
   * Handles dialog open
   *
   * this will put the data into state
   * @param {string} potentialCandidateId
   * @param {string} candidateId
   * @param {string} rating
   * @param {string} comment
   * @param {string} clientComment
   * @param {string} proceeding
   * @param {string} candidateName
   * @param { import('../ProjectArchive.typedef.js').ProjectDossierListEntry[]} projectDossierList
   */
  const handleDialogOpen = (
    potentialCandidateId,
    candidateId,
    rating,
    comment,
    clientComment,
    proceeding,
    projectDossierList,
    candidateName,
    hideInCustomerPortal
  ) => {
    setDialogOpen(true);
    setSelectedPotentialCandidateId(potentialCandidateId); // PotentialCandidateId
    setSelectedCandidateId(candidateId);
    setSelectedRating(rating);
    setSelectedComment(comment);
    setSelectedClientComment(clientComment);
    setSelectedProceeding(proceeding);
    setSelectedDossierList(projectDossierList);
    setSelectedCandidateName(candidateName);
    setSelectedHideInCustomerPortal(hideInCustomerPortal);
  };

  /**
   * This will handle close dialog
   *
   * and it will also reset the states
   */
  const handleDialogClose = () => {
    setSelectedPotentialCandidateId('');
    setSelectedRating('');
    setSelectedComment('');
    setSelectedClientComment('');
    setSelectedProceeding('');
    setDialogOpen(false);
  };

  /**
   *
   * @param {string[]} data
   * @return {string} educationString
   */
  const formatEducationTypeString = data => {
    let educationString = '';
    data.forEach(education => {
      educationString += `${education}, `;
    });
    educationString = educationString.replace(/,\s*$/, '');
    return educationString;
  };

  const handleUploadDialogOpen = selectedPC => {
    setSelectedPotentialCandidate(selectedPC); // that calls the open dialog and set the attributes in to state
    setUploadOpen(true);
    return selectedPC;
  };

  const handleClick = (event, candidateId, listType) => {
    const updater = current => ({ ...current, [candidateId]: event === null ? null : event.currentTarget });
    switch (listType) {
      case 'customer':
        setAnchorElCustomerCommentList(updater);
        break;
      case 'project':
        setAnchorElProjectCommentList(updater);
        break;
      default:
    }
  };

  /**
   * Prepare data for SafeMuiDataTable
   *
   * which is need to have and two-dimensional array as input
   * and with different listTypes we get different data (e.g. customerRating)
   * @param {PotentialCandidateState[]} data
   * @param {string} listType
   * @return [][]
   */
  const prepareDataForTable = (data, listType) => {
    const result = [];
    let preData = sortProjectApplications(data);
    switch (listType) {
      case ListType.SHORT_LIST:
        preData = preData.filter(value => value.isShortList);
        break;
      case ListType.LATECOMER_LIST:
        preData = preData.filter(value => value.isLatecomer);
        break;
      case ListType.LONG_LIST:
        preData = preData.filter(value => value.showInCustomerPortal);
        break;
      default:
        break;
    }

    switch (listType) {
      case ListType.SELECTION_LIST:
        preData.forEach(
          /**
           *
           * @param {PotentialCandidateState} value
           */
          value => {
            if (!value.candidate) {
              return;
            }
            result.push([
              value.id, // The potentialCandidate id
              value.candidateId, // The candidate id
              value.candidate.firstName, // First name of the candidate
              value.candidate.lastName || '', // Second name of the candidate

              value.candidate.dateOfBirth, // candidate birthday
              value.candidate.city, // candidate living place
              mapDuration(value.candidate.latestJob), // candidate latest jop time duration
              value.candidate.currentCompany, // candidate current company
              value.candidate.currentJob, // candidate current jop
              // This is the education string with will format in this function
              formatEducationTypeString(value.candidate.educationList),
              value.rejectionDate,
              value.rating,
              {
                // This is the dossier button which the value is an object
                value: value.candidate.projectDossier.length, // First key is the project dossier, this is used to disable the button when no dossier exist
                // Second key is the handle function to open the dialog
                handleOpen: () =>
                  handleDialogOpen(
                    value.id,
                    value.candidateId,
                    value.rating || '',
                    value.comment || '',
                    value.clientCommentLongList || '',
                    value.furtherProceedingLonglist || '',
                    value.candidate.projectDossier,
                    `${value.candidate.firstName} ${value.candidate.lastName}`,
                    value.hideEmployeeCommentInCustomerPortal
                  )
              }, // candidate rating
              FurtherProceeding[value.furtherProceedingLonglist], // candidate proceeding
              {
                comment: value.clientCommentLongList || '',
                isOpen: Boolean(anchorElCusomerCommentsList[value.candidateId]),
                anchorEl: anchorElCusomerCommentsList[value.candidateId] ? anchorElCusomerCommentsList[value.candidateId] : null,
                handleClick: event => handleClick(event, value.candidateId, 'customer'),
                handleClose: () => handleClick(null, value.candidateId, 'customer')
              }, // comment from client
              {
                comment: value.comment || '',
                isOpen: Boolean(anchorElProjectCommentsList[value.candidateId]),
                anchorEl: anchorElProjectCommentsList[value.candidateId] ? anchorElProjectCommentsList[value.candidateId] : null,
                handleClick: event => handleClick(event, value.candidateId, 'project'),
                handleClose: () => handleClick(null, value.candidateId, 'project')
              },
              value.hideEmployeeCommentInCustomerPortal
            ]);
          }
        );
        break;
      case ListType.SHORT_LIST:
        preData.forEach(
          /**
           *
           * @param {PotentialCandidateState} value
           */
          value => {
            if (!value.candidate) {
              return;
            }
            result.push([
              value.id, // The potentialCandidate id
              value.candidateId, // The candidate id
              value.candidate.firstName || '', // First name of the candidate
              value.candidate.lastName || '', // Second name of the candidate
              value.candidate.dateOfBirth, // candidate birthday
              value.candidate.city, // candidate living place
              mapDuration(value.candidate.latestJob), // candidate latest jop time duration
              value.candidate.currentCompany, // candidate current company
              value.candidate.currentJob, // candidate current jop
              // This is the education string with will format in this function
              formatEducationTypeString(value.candidate.educationList),
              value.rejectionDate,
              value.rating, // candidate rating
              {
                // This is the dossier button which the value is an object
                value: value.candidate.dossierLienertShortList.length, // First key is the project dossier, this is used to disable the button when no dossier exist
                // Second key is the handle function to open the dialog
                handleOpen: () =>
                  handleDialogOpen(
                    value.id,
                    value.candidateId,
                    value.rating || '',
                    value.comment || '',
                    value.clientCommentLongList || '',
                    // longlist further proceeding is used for every list also for shortlist
                    value.furtherProceedingLonglist || '',
                    value.candidate.dossierLienertShortList,
                    `${value.candidate.firstName} ${value.candidate.lastName}`,
                    value.hideEmployeeCommentInCustomerPortal
                  )
              },
              {
                // This is the dossier button which the value is an object
                additionalDocuments: value.additionalDocuments, // First key is the project dossier, this is used to disable the button when no dossier exist
                // Second key is the handle function to open the dialog
                handleOpen: () => handleUploadDialogOpen(value)
              },
              FurtherProceeding[value.furtherProceedingLonglist], // candidate proceeding
              {
                comment: value.clientCommentLongList || '',
                isOpen: Boolean(anchorElCusomerCommentsList[value.candidateId]),
                anchorEl: anchorElCusomerCommentsList[value.candidateId] ? anchorElCusomerCommentsList[value.candidateId] : null,
                handleClick: event => handleClick(event, value.candidateId, 'customer'),
                handleClose: () => handleClick(null, value.candidateId, 'customer')
              }, // comment from client
              {
                comment: value.comment || '',
                isOpen: Boolean(anchorElProjectCommentsList[value.candidateId]),
                anchorEl: anchorElProjectCommentsList[value.candidateId] ? anchorElProjectCommentsList[value.candidateId] : null,
                handleClick: event => handleClick(event, value.candidateId, 'project'),
                handleClose: () => handleClick(null, value.candidateId, 'project')
              },
              value.hideEmployeeCommentInCustomerPortal
            ]);
          }
        );
        break;
      default:
        preData.forEach(
          /**
           *
           * @param {PotentialCandidateState} value
           */
          value => {
            if (!value.candidate) {
              return;
            }
            result.push([
              value.id, // The potentialCandidate id
              value.candidateId, // The candidate id
              value.candidate.firstName, // First name of the candidate
              value.candidate.lastName || '', // Second name of the candidate

              value.candidate.dateOfBirth, // candidate birthday
              value.candidate.city, // candidate living place
              mapDuration(value.candidate.latestJob), // candidate latest jop time duration
              value.candidate.currentCompany, // candidate current company
              value.candidate.currentJob, // candidate current jop
              // This is the education string with will format in this function
              formatEducationTypeString(value.candidate.educationList),
              value.rejectionDate,
              value.rating,
              {
                // This is the dossier button which the value is an object
                value: value.candidate.dossierLienertLongList.length, // First key is the project dossier, this is used to disable the button when no dossier exist
                // Second key is the handle function to open the dialog
                handleOpen: () =>
                  handleDialogOpen(
                    value.id,
                    value.candidateId,
                    value.rating || '',
                    value.comment || '',
                    value.clientCommentLongList || '',
                    value.furtherProceedingLonglist || '',
                    value.candidate.dossierLienertLongList,
                    `${value.candidate.firstName} ${value.candidate.lastName}`,
                    value.hideEmployeeCommentInCustomerPortal
                  )
              }, // candidate rating
              FurtherProceeding[value.furtherProceedingLonglist], // candidate proceeding
              {
                comment: value.clientCommentLongList || '',
                isOpen: Boolean(anchorElCusomerCommentsList[value.candidateId]),
                anchorEl: anchorElCusomerCommentsList[value.candidateId] ? anchorElCusomerCommentsList[value.candidateId] : null,
                handleClick: event => handleClick(event, value.candidateId, 'customer'),
                handleClose: () => handleClick(null, value.candidateId, 'customer')
              }, // comment from client
              {
                comment: value.comment || '',
                isOpen: Boolean(anchorElProjectCommentsList[value.candidateId]),
                anchorEl: anchorElProjectCommentsList[value.candidateId] ? anchorElProjectCommentsList[value.candidateId] : null,
                handleClick: event => handleClick(event, value.candidateId, 'project'),
                handleClose: () => handleClick(null, value.candidateId, 'project')
              },
              value.hideEmployeeCommentInCustomerPortal
            ]);
          }
        );
    }
    return result;
  };

  return {
    formatEducationTypeString,
    prepareDataForTable,
    setUploadOpen,
    isUploadOpen,
    dialogOpen,
    selectedDossierList,
    selectedComment,
    setSelectedComment,
    selectedClientComment,
    selectedRating,
    setSelectedRating,
    selectedPotentialCandidateId,
    selectedPotentialCandidate,
    selectedCandidateId,
    selectedProceeding,
    setSelectedProceeding,
    selectedCandidateName,
    handleDialogOpen,
    handleDialogClose,
    selectedHideInCustomerPortal
  };
};

export default useProjectSelection;
