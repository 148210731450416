import React from 'react';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

/**
 * @typedef CurrencyInputLienertProps
 * @property {(instance: NumberFormat | null) => void} inputRef
 * @property {(event: {target: {name: string, value: number | undefined}}) => void} onChange
 * @property {string} name;
 */

/**
 *
 * @param {CurrencyInputLienertProps & NumberFormatBaseProps } props
 * @returns
 */
const CurrencyInputLienert = props => {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue
          }
        });
      }}
      decimalScale={2}
      thousandSeparator="'"
      valueIsNumericString
      prefix="CHF "
    />
  );
};

CurrencyInputLienert.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired
};

export default CurrencyInputLienert;
