const accountStatus = {
  NO_ACCOUNT: 'noAccount',
  ACTIVE: 'active',
  DELETED: 'deleted',
  ACCOUNT_LOCKED: 'accountLocked',
  INVITED: 'invited',
  REINVITED: 'reinvited',
  INITIAL_LOGIN: 'initialLogin',
  PASSWORD_SET: 'passwordSet'
};

module.exports = accountStatus;
