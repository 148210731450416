import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import $ from 'jquery';

import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import { TableCell } from '@material-ui/core';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import ConfirmationModal from '../generic_components/ConfirmationModal';

import withUser from '../utils/withUser';
import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as TEXT_GENERAL from '../text';

import isPermissionSet from '../utils/isPermissionSet';
import { removePotentialCandidate, updatePotentialCandidate } from './api/projectArchiveAPI';
import sortProjectApplications from './utils/sortProjectApplications';

import './ProjectApplication.css';

const THEME = require('../theme')[CONSTANTS.partner];

// PAGE: Eingehende Bewerbungen
class ProjectApplications extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // for removal of potentialCandidate
      showDeleteConfirmationPopUp: false,
      potentialCandidateIdDeletionRequest: ''
    };

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  handleRemoveEntryAPICall = () => {
    const { potentialCandidateIdDeletionRequest } = this.state;

    removePotentialCandidate(this.props.selectedProjectId, potentialCandidateIdDeletionRequest, 'applied')
      .then(() => {
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.deleteSuccess);

        const oldPotentialCandidates = this.props.data;
        console.log('DEBUG oldPotentialCandidates', oldPotentialCandidates);

        const indexOfUpdatedPotentialCandidate = oldPotentialCandidates.findIndex(
          project => project.id === potentialCandidateIdDeletionRequest
        );
        const newPotentialCandidates = oldPotentialCandidates;
        newPotentialCandidates[indexOfUpdatedPotentialCandidate] = {
          ...newPotentialCandidates[indexOfUpdatedPotentialCandidate],
          applied: false
        };

        this.props.liftUpUpdatedPotentialCandidates(newPotentialCandidates);

        this.handleCloseConfirmationPopUp();
      })
      .catch(err => this.errorHandling(err, CONSTANTS.removePotentialCandidateURL));
  };

  handleCloseConfirmationPopUp = () => {
    this.setState({
      showDeleteConfirmationPopUp: false,
      potentialCandidateIdDeletionRequest: ''
    });
  };

  onDownloadButtonClick(projectId, listType) {
    // Why is this "xhttp.request"
    console.log('DEBUG projectId', projectId);
    this.setState({
      isDownloadButtonActive: `${listType}_${projectId}`
    });
    let url = CONSTANTS.getLongListExcelURL;
    let downloadFileName = 'Auftragsexport_Longlist.xlsx';

    switch (listType) {
      case 'latecomer':
        url = CONSTANTS.getLatecomerListExcelURL;
        downloadFileName = 'Auftragsexport_Nachzuegler.xlsx';
        break;
      case 'shortList':
        url = CONSTANTS.getShortListExcelURL;
        downloadFileName = 'Auftragsexport_Shortlist.xlsx';
        break;
      default:
        break;
    }

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = {
      projectId
    };

    const xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = () => {
      let a;
      if (xhttp.readyState === 4 && xhttp.status === 200) {
        // Trick for making downloadable link
        a = document.createElement('a');
        a.href = window.URL.createObjectURL(xhttp.response);
        // Give filename you wish to download
        a.download = downloadFileName;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        this.setState({
          isDownloadButtonActive: ''
        });
      }
    };
    // Post data to URL which handles post request
    xhttp.open('POST', url);
    xhttp.setRequestHeader('Content-Type', 'application/json');
    xhttp.setRequestHeader('x-auth', tokenFromLocalStorage);
    // You should set responseType as blob for binary responses
    xhttp.responseType = 'blob';
    xhttp.send(JSON.stringify(requestBody));
  }

  updateIsShortListAPICall = (potentialCandidateId, isShortList) => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updatePotentialCandidateURL;

    const payload = {
      projectId: this.props.selectedProjectId,
      potentialCandidateId,
      updatedData: {
        isShortList
      }
    };

    // liftUp new state
    const oldPotentialCandidates = this.props.data;
    const indexOfUpdatedPotentialCandidate = oldPotentialCandidates.findIndex(project => project && project.id === potentialCandidateId);
    const newPotentialCandidates = oldPotentialCandidates.slice();

    newPotentialCandidates[indexOfUpdatedPotentialCandidate].isShortList = isShortList;

    this.props.liftUpUpdatedPotentialCandidates(newPotentialCandidates);

    // API call
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        if (isShortList) this.props.handleSnackbarOpen('Der Kandidat wurde zur Shortlist hinzugefügt');
        else this.props.handleSnackbarOpen('Der Kandidat wurde von der Shortlist entfernt');
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  updateProposedStatusAPICall = (potentialCandidateId, proposedNewValue) => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updatePotentialCandidateURL;

    const payload = {
      projectId: this.props.selectedProjectId,
      potentialCandidateId,
      updatedData: {
        proposed: proposedNewValue
      }
    };

    // liftUp new state
    const oldPotentialCandidates = this.props.data;
    const indexOfUpdatedPotentialCandidate = oldPotentialCandidates.findIndex(project => project && project.id === potentialCandidateId);
    const newPotentialCandidates = oldPotentialCandidates.slice();
    if (proposedNewValue) {
      delete newPotentialCandidates[indexOfUpdatedPotentialCandidate];
      newPotentialCandidates.unshift({
        ...oldPotentialCandidates[indexOfUpdatedPotentialCandidate],
        proposed: proposedNewValue
      });
    } else
      newPotentialCandidates[indexOfUpdatedPotentialCandidate] = {
        ...newPotentialCandidates[indexOfUpdatedPotentialCandidate],
        proposed: proposedNewValue
      };
    this.props.liftUpUpdatedPotentialCandidates(newPotentialCandidates);

    // API call
    console.log(`API ${url} request`, payload);
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log(`API /${url} response`, responseBody, status);
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.updateSuccess);
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  updateProjectDetailsAPICall = (potentialCandidateId, rating, oldRating) => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updatePotentialCandidateURL;

    let updatedRating = rating;
    if (rating === oldRating) {
      updatedRating = '';
    }

    const payload = {
      projectId: this.props.selectedProjectId,
      potentialCandidateId,
      updatedData: {
        rating: updatedRating
      }
    };

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(payload),
      timeout: CONFIG.ajaxTimeout
    })
      .done(() => {
        this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.updateSuccess);

        const oldPotentialCandidates = this.props.data;

        const indexOfUpdatedPotentialCandidate = oldPotentialCandidates.findIndex(
          potentialCandidate => potentialCandidate.id === potentialCandidateId
        );

        const newPotentialCandidates = oldPotentialCandidates.slice();
        if (rating === 'A' && !CONSTANTS.isLienert) {
          delete newPotentialCandidates[indexOfUpdatedPotentialCandidate];
          newPotentialCandidates.unshift({
            ...oldPotentialCandidates[indexOfUpdatedPotentialCandidate],
            rating: updatedRating,
            proposed: true
          });
        } else
          newPotentialCandidates[indexOfUpdatedPotentialCandidate] = {
            ...newPotentialCandidates[indexOfUpdatedPotentialCandidate],
            rating: updatedRating
          };

        this.props.liftUpUpdatedPotentialCandidates(newPotentialCandidates);
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  };

  /**
   *
   * @param {string} potentialCandidateId id of the candidate that is being edited in the Table (row)
   * @param {boolean} value the changed value of a Cell inside the Table from a Switch
   * @param {string} updatedField the field that was changed and set to the passed value (the same Name as in Database)
   * @param {boolean} all does not show snackbar text E.g. if function called from a loop, if set to true
   */
  updateCustomerPortalPermissionSettings = (potentialCandidateId, value, updatedField, all) => {
    // liftUp new state
    const oldPotentialCandidates = this.props.data;
    const indexOfUpdatedPotentialCandidate = oldPotentialCandidates.findIndex(project => project && project.id === potentialCandidateId);
    const newPotentialCandidates = oldPotentialCandidates.slice();

    newPotentialCandidates[indexOfUpdatedPotentialCandidate][updatedField] = value;

    this.props.liftUpUpdatedPotentialCandidates(newPotentialCandidates);

    updatePotentialCandidate(this.props.selectedProjectId, potentialCandidateId, {
      [updatedField]: value
    })
      .then(() => {
        const notText = value ? '' : 'nicht mehr';
        const inCustomerJourney = 'im Kundenportal sichtbar';
        let snackBarText = '';

        if (!all) {
          switch (updatedField) {
            case 'showInCustomerPortal':
              snackBarText = `Der Kandidat ist jetzt ${notText} ${inCustomerJourney}`;
              break;
            case 'showDossierInCustomerPortal':
              snackBarText = `Das Dossier ist jetzt ${notText} ${inCustomerJourney}`;
              break;
            case 'showAdditionalDocumentsInCustomerPortal':
              snackBarText = `Die weiteren Dokumente ist jetzt ${notText} ${inCustomerJourney}`;
              break;
            default:
              snackBarText = `Der Kandidat ist jetzt ${notText} ${inCustomerJourney}`;
              break;
          }
          if (CONSTANTS.isLienert) {
            snackBarText = `Der Kandidat ist jetzt ${notText} in der ausgewählten Liste sichtbar. Um diesen im Kundenportal anzuzeigen geben sie dem Kandidaten bitte eine Bewertung`;
          }
          this.props.handleSnackbarOpen(snackBarText);
        }
      })
      .catch(err => this.errorHandling(err, CONSTANTS.updatePotentialCandidateURL));
  };

  /**
   *
   * @param {*} user from props.user
   */
  renderAuftragsstatusColumnName = user => {
    const isAdmin = user.role === 'admin';
    const onlyAuftragsStatus = 'im Auftragsstatus';
    const adminAuftragsStatus = 'im Auftragsstatus / Shortlist / entfernen';
    const lienertAdminAuftragsStatus = 'Auftragsstatus / X ';
    if (isAdmin && !CONSTANTS.isLienert) {
      return adminAuftragsStatus;
    }
    if (isAdmin && CONSTANTS.isLienert) {
      return lienertAdminAuftragsStatus;
    }
    return onlyAuftragsStatus;
  };

  renderCustomerPortalHeaderCells(name) {
    return (
      <TableCell className={`${CONSTANTS.isLienert && 'lienert-customer-portal-column'} column-custom-render-header`}>{name}</TableCell>
    );
  }

  getColumns() {
    return [
      {
        name: 'ID',
        options: {
          display: 'excluded',
          filter: false
        }
      },
      {
        name: 'candidateId',
        options: {
          display: 'excluded',
          filter: false
        }
      },

      {
        name: 'Vorname',
        options: {
          display: 'true',
          filter: false,
          customBodyRender: (value, metaData) => <Link to={`candidates?id=${metaData.rowData[1]}`}>{value}</Link>
        }
      },
      {
        name: 'Nachname',
        options: {
          display: 'true',
          filter: false,
          customBodyRender: (value, metaData) => <Link to={`candidates?id=${metaData.rowData[1]}`}>{value}</Link>
        }
      },
      {
        name: 'Geburtsdatum',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: 'true',
          filter: false
        }
      },
      {
        name: 'PLZ',
        options: {
          display: 'true',
          filter: false
        }
      },
      {
        name: 'Wohnort',
        options: {
          display: 'true',
          filter: true
        }
      },
      {
        name: 'Zuordnung',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: true,
          filter: true
        }
      },
      {
        name: 'Abgesagt',
        options: {
          customBodyRender: value => UTILS.formatStringToDELocale(value),
          display: true,
          filter: true
        }
      },
      {
        name: 'Bewertung',
        options: {
          customBodyRender: (value, tableMeta) => this.renderRatingButton(value, tableMeta.rowData),
          display: true,
          filter: false
        }
      },
      {
        name: this.renderAuftragsstatusColumnName(this.props.user),
        options: {
          display: 'true',
          filter: false
        }
      },
      {
        name: CONSTANTS.isLienert ? 'Longlist' : 'Kundenportal',
        options: {
          display: CONSTANTS.isSenator ? 'excluded' : '',
          customBodyRender: ({ value, id }) =>
            this.renderCustomerPortalPermissionSetting(value, false, () =>
              this.updateCustomerPortalPermissionSettings(id, !value, 'showInCustomerPortal')
            ),
          filter: false,
          customHeadRender: ({ name }) => this.renderCustomerPortalHeaderCells(name)
        }
      },
      {
        name: 'Dossier',
        options: {
          display: CONSTANTS.isMatchflix ? 'true' : 'excluded',
          customBodyRender: ({ value, id }) =>
            this.renderCustomerPortalPermissionSetting(value, this.checkIfSwitcherIsDisabled(id), () =>
              this.updateCustomerPortalPermissionSettings(id, !value, 'showDossierInCustomerPortal')
            ),
          filter: false,
          customHeadRender: ({ name }) => this.renderCustomerPortalHeaderCells(name)
        }
      },
      {
        name: 'Nachzügler',
        options: {
          display: CONSTANTS.isLienert ? 'true' : 'excluded',
          customBodyRender: ({ value, id }) =>
            this.renderCustomerPortalPermissionSetting(value, false, () =>
              this.updateCustomerPortalPermissionSettings(id, !value, 'isLatecomer')
            ),
          filter: false,
          customHeadRender: ({ name }) => this.renderCustomerPortalHeaderCells(name)
        }
      },
      {
        name: 'Shortlist',
        options: {
          display: CONSTANTS.isLienert ? 'true' : 'excluded',
          customBodyRender: ({ value, id }) =>
            this.renderCustomerPortalPermissionSetting(value, false, () =>
              this.updateCustomerPortalPermissionSettings(id, !value, 'isShortList')
            ),
          filter: false,
          customHeadRender: ({ name }) => this.renderCustomerPortalHeaderCells(name)
        }
      },
      {
        name: 'Weitere Dokumente',
        options: {
          display: CONSTANTS.isSenator ? 'excluded' : '',
          customBodyRender: ({ value, id }) =>
            this.renderCustomerPortalPermissionSetting(value, false, () =>
              this.updateCustomerPortalPermissionSettings(id, !value, 'showAdditionalDocumentsInCustomerPortal')
            ),
          filter: false,
          customHeadRender: ({ name }) => this.renderCustomerPortalHeaderCells(name)
        }
      }
    ];
  }

  checkIfSwitcherIsDisabled(candidateId) {
    const rowCandidate = this.props.data.find(candidate => {
      if (!candidate) {
        return false;
      }
      return candidate.id === candidateId;
    });
    let disabled = true;
    if (rowCandidate && rowCandidate.showInCustomerPortal) {
      disabled = false;
    }
    return disabled;
  }

  generateName(firstName, lastName) {
    let name = '';

    if (firstName) name = `${name + firstName} `;
    if (lastName) name = `${name + lastName} `;
    return name;
  }

  mapIsReferred(isReferred) {
    if (isReferred === false) return 'nein';
    if (isReferred === true) return 'ja';
    return '';
  }

  renderDeleteConfirmationPopUp() {
    return (
      this.state.showDeleteConfirmationPopUp && (
        <ConfirmationModal
          key="confirmation-entry-deletion"
          handleClose={this.handleCloseConfirmationPopUp}
          open={this.state.showDeleteConfirmationPopUp}
          buttonPrimaryAction={this.handleRemoveEntryAPICall}
          headlineText="Kandidat aus Auftrag entfernen?"
          descriptionText="Soll der Kandidat aus dem entsprechenden Auftrag entfernt werden und auftragspezifische Daten des Kandidatens gelöscht werden?"
        />
      )
    );
  }

  renderShortListDownloadButton = projectId => {
    if (isPermissionSet(this.props.user, 'shortLongList')) {
      return (
        <Button
          onClick={() => this.onDownloadButtonClick(projectId, 'shortList')}
          disabled={this.state.isDownloadButtonActive === `shortList_${projectId}`}
          color="secondary"
          key={`shortList_${projectId}`}
        >
          Shortlist
        </Button>
      );
    }
    return '';
  };

  renderLatecomerListDownloadButton = projectId => {
    if (isPermissionSet(this.props.user, 'shortLongList')) {
      return (
        <Button
          onClick={() => this.onDownloadButtonClick(projectId, 'latecomer')}
          disabled={this.state.isDownloadButtonActive === `latecomer_${projectId}`}
          color="secondary"
          key={`latecomer_${projectId}`}
        >
          Nachzügler
        </Button>
      );
    }
    return '';
  };

  renderLongListDownloadButton = projectId => {
    if (isPermissionSet(this.props.user, 'shortLongList'))
      return (
        <Button
          onClick={() => this.onDownloadButtonClick(projectId, 'longList')}
          disabled={this.state.isDownloadButtonActive === `longList_${projectId}`}
          color="secondary"
          key={`longList_${projectId}`}
        >
          Longlist
        </Button>
      );
    return '';
  };

  renderRatingButton = (rating, potentialCandidateData) => {
    const selectedStyle = { color: THEME.app.palette.primary.main, fontWeight: '600', minWidth: '50px' };
    const notSelectedStyle = {
      color: THEME.app.palette.primary.veryLight,
      fontWeight: '400',
      minWidth: '50px'
    };
    return (
      <React.Fragment>
        <Button
          className=""
          aria-label="Edit"
          style={rating === 'A' ? selectedStyle : notSelectedStyle}
          onClick={() => this.updateProjectDetailsAPICall(potentialCandidateData[0], 'A', rating)}
        >
          {CONSTANTS.isLienert ? '1' : 'A'}
        </Button>

        {CONSTANTS.isLienert && (
          <Button
            className=""
            aria-label="Edit"
            style={rating === 'AB' ? selectedStyle : notSelectedStyle}
            onClick={() => this.updateProjectDetailsAPICall(potentialCandidateData[0], 'AB', rating)}
          >
            1 - 2
          </Button>
        )}

        <Button
          className=""
          aria-label="Edit"
          style={rating === 'B' ? selectedStyle : notSelectedStyle}
          onClick={() => this.updateProjectDetailsAPICall(potentialCandidateData[0], 'B', rating)}
        >
          {CONSTANTS.isLienert ? '2' : 'B'}
        </Button>
        <Button
          className=""
          aria-label="Edit"
          style={rating === 'C' ? selectedStyle : notSelectedStyle}
          onClick={() => this.updateProjectDetailsAPICall(potentialCandidateData[0], 'C', rating)}
        >
          {CONSTANTS.isLienert ? '3' : 'C'}
        </Button>
      </React.Fragment>
    );
  };

  renderEditButton(potentialCandidateData) {
    const proposed = potentialCandidateData.proposed ? potentialCandidateData.proposed : '';
    const isShortList = potentialCandidateData.isShortList ? potentialCandidateData.isShortList : '';
    return (
      <React.Fragment>
        <Tooltip title="im Auftragsstatus">
          <Switch
            key={potentialCandidateData.potentialCandidateId}
            checked={proposed === true}
            onChange={() => this.updateProposedStatusAPICall(potentialCandidateData.id, !proposed)}
            value="proposed"
            color="primary"
          />
        </Tooltip>
        {this.props.user.role === 'admin' && (
          <React.Fragment>
            {!CONSTANTS.isLienert && (
              <Tooltip title="in Shortlist aufnehmen">
                <Switch
                  checked={isShortList === true}
                  onChange={() => this.updateIsShortListAPICall(potentialCandidateData.id, !isShortList)}
                  value="proposed"
                  color="primary"
                />
              </Tooltip>
            )}
            <Tooltip title="Kandidat aus Auftrag entfernen">
              <IconButton
                className=""
                disabled={!this.props.isEditable}
                aria-label="Delete"
                color="primary"
                onClick={() => {
                  this.setState({
                    showDeleteConfirmationPopUp: true,
                    potentialCandidateIdDeletionRequest: potentialCandidateData.id
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderActionItems = entry => <div className="col-12">{this.renderEditButton(entry)}</div>;

  /**
   *
   * @param {boolean} value that is being checked to tell if the Switch is active
   * @param {boolean} disabled that is being checked to tell if the Switch is disabled
   * @param {function(): void} onSwitch that gets executed when Switch is Clicked
   */
  renderCustomerPortalPermissionSetting(value, disabled = false, onSwitch) {
    return <Switch checked={value === true} disabled={disabled} onChange={onSwitch} value="proposed" color="primary" />;
  }

  handleUpdatePotentialCandidateShowInCustomerPortal(showInCustomerPortal) {
    const promises = [];

    this.props.data.forEach(dataRow => {
      promises.push(
        new Promise(() => {
          this.updateCustomerPortalPermissionSettings(dataRow.id, showInCustomerPortal, 'showInCustomerPortal', true);
        })
      );
    });

    Promise.all(promises).then(
      this.props.handleSnackbarOpen(TEXT_GENERAL.projectArchive.projectApplications.snackBarTexts.allCandidates(showInCustomerPortal))
    );
  }

  renderSelectAllforCustomerPortal() {
    return (
      <Button onClick={() => this.handleUpdatePotentialCandidateShowInCustomerPortal(true)} disabled={false} color="secondary">
        {TEXT_GENERAL.projectArchive.projectApplications.buttons.selectAll}
      </Button>
    );
  }

  renderDeselectAllforCustomerPortal() {
    return (
      <Button onClick={() => this.handleUpdatePotentialCandidateShowInCustomerPortal(false)} disabled={false} color="secondary">
        {TEXT_GENERAL.projectArchive.projectApplications.buttons.deselectAll}
      </Button>
    );
  }

  prepareDataForTable(data) {
    const formattedData = [];
    // sometimes candidate is null
    let projectApplications = data.filter(entry => entry.applied && entry.candidate);

    projectApplications = sortProjectApplications(projectApplications);

    if (projectApplications)
      projectApplications.forEach(entry => {
        if (entry.applied === true) {
          const cPostalCode = entry.candidate ? entry.candidate.postalCode : '';
          const cCity = entry.candidate ? entry.candidate.city : '';

          const potentialCandidateData = {
            potentialCandidateId: entry.id,
            candidateId: entry.candidate && entry.candidate.id,

            // candidate data
            candidateFirstName: entry.candidate ? entry.candidate.firstName : '',
            candidateLastName: entry.candidate ? entry.candidate.lastName : '',
            candidateBirthDay: entry.candidate ? entry.candidate.dateOfBirth : '',
            candidatePostalCode: cPostalCode,
            candidateCity: cCity,
            addedDate: entry.addedDate,
            rejectionDate: entry.rejectionDate
          };
          /**
           *  The Three Switches at the End of the Table to change appearence inside
           * of the customerPortal
           */
          const customerPortalPermissionSettings = {
            showInCustomerPortal: { id: entry.id, value: !!entry.showInCustomerPortal },
            showDossierInCustomerPortal: { id: entry.id, value: !!entry.showDossierInCustomerPortal },
            isLatecomer: { id: entry.id, value: !!entry.isLatecomer },
            isShortList: { id: entry.id, value: !!entry.isShortList },
            showAdditionalDocumentsInCustomerPortal: { id: entry.id, value: !!entry.showAdditionalDocumentsInCustomerPortal }
          };
          formattedData.push([
            ...Object.values(potentialCandidateData),
            entry.rating,
            this.renderActionItems(entry),
            ...Object.values(customerPortalPermissionSettings)
          ]);
        }
      });
    return formattedData;
  }

  render() {
    return (
      <div className="container-fluid">
        {this.renderDeleteConfirmationPopUp()}
        <div className="ProjectApplications row">
          <div className="col-12">
            <SafeMuiDatatable
              title="Eingegangene Bewerbungen"
              tableName="projectApplications"
              data={this.prepareDataForTable(this.props.data)}
              columns={this.getColumns()}
              initialFilter={this.props.filterCandidateId ? { candidateId: this.props.filterCandidateId } : null}
              showMarklistButton
              markListType="candidate"
              handleSnackbarOpen={this.props.handleSnackbarOpen}
              unformatedData={this.props.data.filter(entry => entry.applied)}
              customMarklistEntryIdParameterName="candidateId"
            />
          </div>
          <div className="container-fluid">
            <div className="text-right pt-2">
              {CONSTANTS.isLienert && (
                <>
                  {this.renderSelectAllforCustomerPortal()}
                  {this.renderDeselectAllforCustomerPortal()}
                </>
              )}

              {this.renderLongListDownloadButton(this.props.selectedProjectId)}
              {CONSTANTS.isLienert && this.renderLatecomerListDownloadButton(this.props.selectedProjectId)}
              {this.renderShortListDownloadButton(this.props.selectedProjectId)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProjectApplications.defaultProps = {
  filterCandidateId: undefined
};

ProjectApplications.propTypes = {
  data: PropTypes.array.isRequired,
  selectedProjectId: PropTypes.string.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpUpdatedPotentialCandidates: PropTypes.func.isRequired,
  isEditable: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
  filterCandidateId: PropTypes.string
};
export default withUser(ProjectApplications);
