import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import GenericFormPopUp from '../../generic_components/GenericFormPopUp';

import * as CONSTANTS from '../../constants';
import * as CONFIG from '../../config';
import * as UTILS from '../../utils/utilFunctions';
import TEXT_GENERAL from '../../text';

// import TEXT from './staticContent/text';
// import { staticResources } from './staticContent/ProjectDetailsCreatePopUpDropDowns';

class CandidateEducationCreatePopUpContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.form = this.generateFormFields();

    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  updateAPICall = updatedData => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.addCandidateSubSchemaEntryURL;

    const payload = {
      candidateId: this.props.candidateId,
      subSchemaName: 'educations',
      subSchemaData: updatedData
    };
    if (Object.keys(updatedData).length > 0) {
      console.log(`API ${url} request`, payload);
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(payload),
        timeout: CONFIG.ajaxTimeout
      })
        .done((responseBody, status) => {
          console.log(`API /${url} response`, responseBody, status);
          this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.createSuccess);
          this.props.refreshCandidate();
          this.props.handleClose();
        })
        .fail(err => {
          this.errorHandling(err, url);
        });
    } else {
      this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.noCreate);
    }
  };

  generateFormFields() {
    // console.log('DEBUG contactDate', this.props.data.contactDate);
    const forms = {
      formFields: {
        educationText: {
          value: '',
          label: 'Beschreibung',
          customClass: 'col-12 mb-2',
          required: 'true',
          validator: 'isText'
        },
        instituteName: {
          value: '',
          label: 'Institutsname',
          customClass: 'col-12 mb-2',

          validator: 'isText'
        },
        educationType: {
          value: '',
          label: 'Typ',
          customClass: 'col-12 mb-2'
        },
        fromDate: {
          value: '',
          type: 'date',
          label: 'Von',
          required: true
        },
        tillDate: {
          value: '',
          type: 'date',
          label: 'Bis',
          required: true
        }
      }
    };

    return forms;
  }

  render() {
    this.form = this.generateFormFields();
    console.log('DEBUG this.props.data', this.props.data);
    return (
      <GenericFormPopUp
        open={this.props.open}
        headlineText="Ausbildung hinzufügen"
        // key="hallo"
        genericFormProps={{
          formFields: this.form.formFields,
          isCreate: true,
          handleSubmit: this.updateAPICall,
          // initialData: this.props.data,
          handleClose: this.props.handleClose

          // buttonCancelText: TEXT.projectDetailsCreatePopUp.buttons.cancel,
          // buttonSaveText: TEXT.projectDetailsCreatePopUp.buttons.save
        }}
      />
    );
  }
}
CandidateEducationCreatePopUpContainer.defaultProps = {
  // isCreate: false,
  data: {}
};

CandidateEducationCreatePopUpContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,

  // data
  candidateId: PropTypes.string.isRequired,
  data: PropTypes.object,
  // snackBar
  handleSnackbarOpen: PropTypes.func.isRequired,
  refreshCandidate: PropTypes.func.isRequired
};

export default CandidateEducationCreatePopUpContainer;
