import { post } from '../../utils/apiUtils';
import * as CONSTANTS from '../../constants';

/**
 *
 * @param {string} notificationId
 */
function markNotificationAsRead(notificationIds) {
  const payload = {
    notificationIds
  };
  return post(CONSTANTS.markNotificationsAsReadURL, payload);
}

export default markNotificationAsRead;
