import * as CONSTANTS from '../../constants';
import { post } from '../../utils/apiUtils';

export function shareList(markListId, shareWithUser) {
  const payload = {
    markListId,
    shareWithUser
  };
  return post(CONSTANTS.shareMarkListURL, payload);
}

export function removeItemFromList(listId, itemId) {
  const payload = {
    listId,
    itemId
  };
  return post(CONSTANTS.removeItemFromMarkListURL, payload);
}

export function loadPopulatedMarkLists() {
  return post(CONSTANTS.loadPopulatedMarkListsURL);
}

export function loadMarkLists() {
  return post(CONSTANTS.loadMarkListsURL);
}

export function loadCounterPartsForMark(companyId, counterPartId) {
  const payload = {
    companyId,
    counterPartId
  };
  return post(CONSTANTS.loadCounterPartsForMarkURL, payload);
}

export function loadCounterPartLists() {
  return post(CONSTANTS.loadCounterPartListsURL);
}

export function deleteList(listId) {
  const payload = {
    listId
  };
  return post(CONSTANTS.deleteMarkListURL, payload);
}

export function getEmployeeListByPartner() {
  return post(CONSTANTS.getEmployeeListByPartnerURL);
}

export function deleteAllDocumentsOfCandidatesInList(candidateIds) {
  const payload = {
    candidateList: candidateIds
  };
  return post(CONSTANTS.deleteAllDocumentsOfCandidatesInListURL, payload, true);
}
