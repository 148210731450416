import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import currency from 'currency.js';
// libraries
import $ from 'jquery';

import GenericFormPopUp from '../generic_components/GenericFormPopUp';

import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT_GENERAL from '../text';

import TEXT from './staticContent/text';
import { staticResources } from './staticContent/ProjectDetailsUpdatePopUpDropDowns';

// custom: for key value dropDowns
// TODO refactor to parent component
function prepareEmployeesKeyValuePairs(employees, onlyAdvisors = false) {
  const mappedDropDownEntries = [];
  if (employees && employees.length > 0) {
    if (CONSTANTS.isLienert && onlyAdvisors) {
      employees.forEach(employee => {
        if (employee.lastname && employee.isAdvisor) {
          const value = employee.id;
          const label = `${employee.firstname || ''} ${employee.lastname}`;
          const mappedEmployee = { value, label };
          mappedDropDownEntries.push(mappedEmployee);
        }
      });
    } else {
      employees.forEach(employee => {
        if (employee.lastname) {
          const value = employee.id;
          const label = `${employee.firstname || ''} ${employee.lastname}`;
          const mappedEmployee = { value, label };
          mappedDropDownEntries.push(mappedEmployee);
        }
      });
    }
  }
  return mappedDropDownEntries;
}

export const formatToCHF = value => currency(value, { symbol: 'CHF ', decimal: '.', separator: "'" });

class ProjectDetailsUpdatePopUpContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.form = this.generateFormFields();
    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  updateProjectDetailsAPICall = updatedData => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    // custom
    // TODO refactor parent component
    const url = CONSTANTS.updateProjectURL;
    const payload = {
      projectId: this.props.projectId,
      updateData: updatedData
    };
    if (Object.keys(updatedData).length > 0) {
      if (payload.updateData.conditionsAmount) {
        payload.updateData.conditionsAmount = payload.updateData.conditionsAmount;
      }
      if (payload.updateData.loop) {
        payload.updateData.loop = payload.updateData.loop;
      }
      if (payload.updateData.da) {
        payload.updateData.da = payload.updateData.da;
      }
      if (payload.updateData.salaryLimitAmount) {
        payload.updateData.salaryLimitAmount = payload.updateData.salaryLimitAmount;
      }

      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(payload),
        timeout: CONFIG.ajaxTimeout
      })
        .done(() => {
          this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.updateSuccess);
          this.props.refreshProject();
          this.props.handleClose();
        })
        .fail(err => {
          this.errorHandling(err, url, null, [400]);
          if (err.status === 400) {
            this.props.handleSnackbarOpen('Die Auftragsnummer ist bereits vergeben!');
          }
        });
    } else {
      this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.noUpdate);
    }
  };

  generateFormFields() {
    const forms = {
      formFields: {
        jobTitle1: {
          value: this.props.isUpdate ? this.props.data.jobTitle1 || '' : '',
          required: true,
          customClass: 'col-12 mb-2',
          label: TEXT.projectDetailsUpdatePopUp.labels.jobTitle1
        },
        projectNumber: {
          value: this.props.isUpdate ? this.props.data.projectNumber || '' : '',
          required: true,
          customClass: 'col-12 mb-2',
          label: TEXT.projectDetailsUpdatePopUp.labels.projectNumber,
          validator: 'isText'
        },
        status: {
          value: this.props.isUpdate ? this.props.data.status : '',
          type: 'dropDown',
          content: staticResources.dropDowns.status.content,
          required: true,
          label: TEXT.projectDetailsUpdatePopUp.labels.status
        },
        statusDetails: {
          value: this.props.isUpdate ? this.props.data.statusDetails : '',
          type: 'dropDown',
          content: staticResources.dropDowns.statusDetails.content,
          required: false,
          label: TEXT.projectDetailsUpdatePopUp.labels.statusDetails
        },
        serviceType: {
          value: this.props.isUpdate ? this.props.data.serviceType : '',
          type: 'dropDown',
          content: staticResources.dropDowns.serviceType.content,
          required: true,
          label: TEXT.projectDetailsUpdatePopUp.labels.serviceType
        },
        consultingUser: {
          value: this.props.isUpdate && this.props.data.consultingUser ? this.props.data.consultingUser.id : '',
          type: 'dropDown',
          content: prepareEmployeesKeyValuePairs(this.props.employees, true),
          required: true,
          label: TEXT.projectDetailsUpdatePopUp.labels.consultingUser
        },
        editingUser: {
          value: this.props.isUpdate && this.props.data.editingUser ? this.props.data.editingUser.id : '',
          type: 'dropDown',
          content: prepareEmployeesKeyValuePairs(this.props.employees, false),
          required: true,
          label: TEXT.projectDetailsUpdatePopUp.labels.editingUser
        },
        numberOfPositions: {
          value: this.props.isUpdate ? this.props.data.numberOfPositions || '' : '',
          validator: 'isNumerical',
          isRequired: true,
          label: TEXT.projectDetailsUpdatePopUp.labels.numberOfPositions
        },
        comment: {
          value: this.props.isUpdate ? this.props.data.comment || '' : '',
          required: false,
          customClass: 'col-12 mb-1 mt-1',
          additionalProps: { multiline: true },
          label: TEXT.projectDetailsUpdatePopUp.labels.comment
        }
      }
    };
    if (CONSTANTS.isLienert) {
      forms.formFields.confidential = {
        value: this.props.isUpdate ? this.props.data.confidential || '' : '',
        label: TEXT.projectDetailsUpdatePopUp.labels.confidential
      };
      forms.formFields.castingType = {
        value: this.props.isUpdate ? this.props.data.castingType || '' : '',
        label: TEXT.projectDetailsUpdatePopUp.labels.castingType
      };
      forms.formFields.conditionsAmount = {
        type: 'lienertCurrency',
        value: this.props.isUpdate ? this.props.data.conditionsAmount : 0,
        label: TEXT.projectDetailsUpdatePopUp.labels.conditionsAmount
      };
      forms.formFields.percentage = {
        value: this.props.isUpdate ? this.props.data.percentage : 0,
        label: TEXT.projectDetailsUpdatePopUp.labels.percentage,
        additionalProps: {
          type: 'number',
          InputProps: { endAdornment: '%' },
          inputProps: { min: '0', max: '100' },
          onInput: e => {
            e.target.value = parseInt(e.target.value, 10);
            if (e.target.value > 100) {
              e.target.value = 100;
            }
            if (e.target.value < 0) {
              e.target.value = 0;
            }
          }
        }
      };
      forms.formFields.loop = {
        type: 'lienertCurrency',
        value: this.props.isUpdate ? this.props.data.loop : 0,
        label: TEXT.projectDetailsUpdatePopUp.labels.loop
      };
      forms.formFields.da = {
        type: 'lienertCurrency',
        value: this.props.isUpdate ? this.props.data.da : 0,
        label: TEXT.projectDetailsUpdatePopUp.labels.da
      };
      forms.formFields.salaryLimitAmount = {
        type: 'lienertCurrency',
        value: this.props.isUpdate ? this.props.data.salaryLimitAmount : 0,
        customClass: 'col-12 mb-5 mt-1',
        label: TEXT.projectDetailsUpdatePopUp.labels.salaryLimitAmount
      };
    } else {
      forms.formFields.supervisor = {
        value: this.props.isUpdate ? this.props.data.supervisor || '' : '',
        label: TEXT.projectDetailsUpdatePopUp.labels.supervisor
      };
      forms.formFields.salary = {
        value: this.props.isUpdate ? this.props.data.salary || '' : '',
        label: TEXT.projectDetailsUpdatePopUp.labels.salary
      };
    }
    // add label text to formFields state
    Object.keys(forms.formFields).forEach(key => {
      forms.formFields[key].label = TEXT.projectDetailsUpdatePopUp.labels[key] || '';
    });
    return forms;
  }

  render() {
    this.form = this.generateFormFields();
    return (
      <GenericFormPopUp
        open={this.props.open}
        headlineText={TEXT.projectDetailsUpdatePopUp.headline}
        genericFormProps={{
          formFields: this.form.formFields,
          isUpdate: true,
          handleSubmit: this.updateProjectDetailsAPICall,
          handleClose: this.props.handleClose,

          buttonCancelText: TEXT.projectDetailsUpdatePopUp.buttons.cancel,
          buttonSaveText: TEXT.projectDetailsUpdatePopUp.buttons.save
        }}
        dialogProps={{}}
      />
    );
  }
}
ProjectDetailsUpdatePopUpContainer.defaultProps = {
  isUpdate: false,
  data: {}
};

ProjectDetailsUpdatePopUpContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool,
  // data
  projectId: PropTypes.string.isRequired,
  data: PropTypes.object,
  // snackBar
  handleSnackbarOpen: PropTypes.func.isRequired,
  // dropDown key values
  employees: PropTypes.array.isRequired,

  // custom: only required for subSchema update
  // dataSetId: PropTypes.string,
  refreshProject: PropTypes.func.isRequired
};

export default ProjectDetailsUpdatePopUpContainer;
