import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import StarIcon from '@material-ui/icons/Star';
import Tooltip from '@material-ui/core/Tooltip';

import AlarmOn from '@material-ui/icons/AlarmOn';

import $ from 'jquery';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import ConfirmationModal from '../generic_components/ConfirmationModal';
import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import CounterPartCUPopUp from './CounterPartCUPopUp';
import MarkItemPopUp from '../components/MarkItemPopUp';
import { deleteCounterPartAPICall, toggleChristmasExportAPICall } from './api/companyArchiveAPI';

import * as UTILS from '../utils/utilFunctions';
import * as CONSTANTS from '../constants';
import TEXT_GENERAL from '../text';
import CounterPartAccountPopover from './CounterPartAccountPopover';
import { withCounterPartAccountPopUp } from './CounterPartAccountPopUp';
import CounterPartUserHistory from './CounterPartUserHistory';

const columns = [
  {
    name: 'ID',
    options: {
      display: 'excluded'
    }
  },
  // { name: 'Bearbeiten' },
  { name: 'Name', options: { filter: false } },
  {
    name: 'Durchwahl',
    options: {
      filter: false
    }
  },
  { name: 'Fax', options: { filter: false } },
  {
    name: 'Mobilfunk',
    options: {
      filter: false
    }
  },
  {
    name: 'E-Mail',
    options: {
      filter: false,
      customBodyRender: value => {
        const hrefParam = UTILS.parserMailLink(value);
        return <a href={hrefParam}>{value}</a>;
      }
    }
  },
  { name: 'Bereich' },
  { name: 'Hierarchieebene' },
  { name: 'Funktion' },
  { name: 'Betreuer' },
  {
    name: 'Zugang',
    options: {
      filter: false,
      display:
        CONSTANTS.partner === 'lienert' || CONSTANTS.partner === 'personaltotal' || CONSTANTS.partner === 'senator' ? true : 'excluded'
    }
  },
  {
    name: 'Kundenversand',
    options: {
      display: CONSTANTS.isLienert ? true : 'excluded'
    }
  },
  { name: 'Aktionen', options: { filter: false } }
  // { name: 'edit_col' }
];

class CompanyContacts extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isMarkCounterPartPopUpOpen: false,
      counterPartHistoryDialog: null,
      showHistoryDialog: false,
      isCounterPartCUPopUpOpen: false,
      isDeleteCounterPartPopUpOpen: false,
      entryId: ''
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  generateName(salutation, title, firstName, lastName) {
    let name = '';
    if (salutation) name = `${salutation} `;
    if (title) name = `${name + title} `;
    if (firstName) name = `${name + firstName} `;
    if (lastName) name = `${name + lastName} `;
    return name;
  }

  handleCloseCounterPartCUPopup = () => {
    this.setState({
      isCounterPartCUPopUpOpen: false
    });
  };

  generateSelectedCounterPartData(entryId) {
    const result = {};
    const foundEntry = this.props.data.find(entry => entry.id === entryId);
    Object.keys(foundEntry).forEach(key => {
      switch (key) {
        case 'contactInfo':
          Object.keys(foundEntry.contactInfo).forEach(subKey => {
            result[subKey] = foundEntry.contactInfo[subKey];
          });
          break;
        case 'advisor':
          result[key] = foundEntry[key].id;
          break;
        default:
          result[key] = foundEntry[key];
      }
    });
    return result;
  }

  async deleteCounterPart() {
    try {
      await deleteCounterPartAPICall(this.state.entryId, this.props.companyId);
      this.props.handleSnackbarOpen('Ansprechpartner wurde erfolgreich gelöscht');
      this.setState({ isDeleteCounterPartPopUpOpen: false });
      this.props.removeCounterPart(this.state.entryId);
    } catch (err) {
      this.errorHandling(err);
    }
  }

  renderButtons(entryId) {
    return (
      <div>
        <Tooltip title="Eintrag bearbeiten">
          <IconButton
            className=""
            aria-label="Edit"
            color="primary"
            onClick={() => this.setState({ isCounterPartCUPopUpOpen: true, entryId })}
            disabled={this.props.isLoading !== false}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Eintrag löschen">
          <IconButton
            className=""
            aria-label="Delete"
            color="primary"
            onClick={() => this.setState({ entryId, isDeleteCounterPartPopUpOpen: true })}
            disabled={this.props.isLoading !== false}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>

        <Tooltip title="Ansprechpartner markieren">
          <IconButton
            className="counterPart-mark-button"
            color="primary"
            onClick={() => this.setState({ entryId, isMarkCounterPartPopUpOpen: true })}
            disabled={this.props.isLoading !== false}
          >
            <StarIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  }

  renderPhoneLink = value => {
    const hrefParam = `tel:${value}`;
    return (
      <a href={hrefParam} onClick={this.props.openContactHistoryForPhone}>
        {value}
      </a>
    );
  };

  renderEmailAddressLink(mailAddress) {
    const hrefParam = UTILS.parserMailLink(mailAddress);
    return <a href={hrefParam}>{mailAddress}</a>;
  }

  async onCheckboxChange(eID, state) {
    this.props.setLoading(eID);
    await toggleChristmasExportAPICall(eID, state);
    this.props.handleSnackbarOpen(TEXT_GENERAL.offers.snackbar.christmasExport.success);
    this.props.refreshCompany(this.props.companyId);
  }

  prepareDataForTable(data) {
    const formattedData = [];
    if (data)
      data.forEach(entry => {
        formattedData.push([
          entry.id,
          entry.id === this.props.preSelectedCounterPartId ? (
            <div className="markedCandidate">
              {this.generateName(entry.salutation, entry.title, entry.firstName, entry.lastName)} <AlarmOn color="disabled" />
            </div>
          ) : (
            this.generateName(entry.salutation, entry.title, entry.firstName, entry.lastName)
          ),
          entry.contactInfo && this.renderPhoneLink(entry.contactInfo.phoneNumber),
          entry.contactInfo && entry.contactInfo.faxNumber,
          entry.contactInfo && this.renderPhoneLink(entry.contactInfo.phoneNumberMobile),
          entry.contactInfo && entry.contactInfo.mailAddress,
          entry.division,
          entry.hierarchyLevel,
          entry.position,
          entry.advisor ? entry.advisor.name : '',
          entry ? (
            <CounterPartAccountPopover
              openCounterPartAccountPopUp={this.props.openCounterPartAccountPopUp}
              counterPart={entry}
              openHistoryDrawer={() =>
                this.setState({
                  counterPartHistoryDialog: entry.id,
                  isMarkCounterPartPopUpOpen: false,
                  showHistoryDialog: true
                })
              }
              refreshCurrentCompany={() => this.props.refreshCompany(this.props.companyId)}
            />
          ) : (
            <></>
          ),
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            {this.props.isLoading !== entry.id && (
              <Checkbox
                className="chkBox_"
                checked={entry.christmasExport}
                disabled={this.props.isLoading !== false}
                onChange={() => {
                  this.setState({ entryId: entry.id });
                  this.onCheckboxChange(entry.id, entry.christmasExport !== undefined ? !entry.christmasExport : true);
                }}
              />
            )}
            {this.props.isLoading === entry.id && (
              <div className="inTableLoader">
                <span className="dot" />
                <span className="dot" />
                <span className="dot" />
              </div>
            )}
          </Box>,
          this.renderButtons(entry.id)
        ]);
      });

    return formattedData;
  }

  renderCounterPartCUPopUp() {
    return (
      <CounterPartCUPopUp
        open={this.state.isCounterPartCUPopUpOpen}
        handleClose={this.handleCloseCounterPartCUPopup}
        handleSnackbarOpen={this.props.handleSnackbarOpen}
        projects={this.props.projects}
        companyId={this.props.companyId}
        isUpdate
        counterPartData={this.generateSelectedCounterPartData(this.state.entryId)}
        counterPartId={this.state.entryId}
        liftUpUpdateCounterPartDetails={this.props.liftUpUpdateCounterPartDetails}
      />
    );
  }

  onCellClick(colData, cellMeta, rowIndex, dataIndex) {
    console.log('debug onCellClick', {
      colData,
      cellMeta,
      rowIndex,
      dataIndex
    });
    if (colData && colData.props && colData.props.href === 'tel:') console.log('debug onCellClick triggerPoint');
  }

  renderActivityDialog() {
    return (
      <CounterPartUserHistory
        handleClose={() =>
          this.setState({
            counterPartHistoryDialog: null,
            showHistoryDialog: false
          })
        }
        isOpen={this.state.showHistoryDialog}
        counterPartId={this.state.counterPartHistoryDialog}
      />
    );
  }

  render() {
    $(document).ready(() => {
      $('.markedCandidate')
        .parent()
        .parent()
        .css('background-color', '#e8e8e8');
    });

    return (
      <div>
        {this.renderActivityDialog()}
        <ConfirmationModal
          key="confirmation-entry-deletion"
          handleClose={() => this.setState({ isDeleteCounterPartPopUpOpen: false })}
          open={this.state.isDeleteCounterPartPopUpOpen}
          buttonPrimaryAction={() => this.deleteCounterPart()}
          headlineText="Ansprechpartner löschen"
          descriptionText="Soll der entsprechende Eintrag gelöscht werden?"
        />

        <MarkItemPopUp
          open={this.state.isMarkCounterPartPopUpOpen}
          handleClose={() => this.setState({ isMarkCounterPartPopUpOpen: false })}
          item={{
            counterPart: this.state.entryId,
            companyId: this.props.companyId
          }}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          markListPopupType="counterPart" // contact
        />

        {this.state.isCounterPartCUPopUpOpen && this.renderCounterPartCUPopUp()}

        <div className="CompanyContacts">
          <SafeMuiDatatable
            title="Ansprechpartner"
            tableName="companyContacts"
            data={this.prepareDataForTable(this.props.data)}
            onCellClick={this.onCellClick}
            columns={columns}
            handleSnackbarOpen={this.props.handleSnackbarOpen}
          />
        </div>
      </div>
    );
  }
}

CompanyContacts.defaultProps = {
  preSelectedCounterPartId: undefined,
  openContactHistoryForPhone: () => null,
  isLoading: false
};
CompanyContacts.propTypes = {
  openCounterPartAccountPopUp: PropTypes.func.isRequired,
  refreshCompany: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  isLoading: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  data: PropTypes.array.isRequired,
  companyId: PropTypes.string.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  liftUpUpdateCounterPartDetails: PropTypes.func.isRequired,
  openContactHistoryForPhone: PropTypes.func,
  preSelectedCounterPartId: PropTypes.string,
  removeCounterPart: PropTypes.func.isRequired,
  projects: PropTypes.array.isRequired
};
export default withCounterPartAccountPopUp(CompanyContacts);
