import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Tooltip from '@material-ui/core/Tooltip';
import styles from './DropContainer.module.css';

class DropContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderDropContainer() {
    return (
      <div className={`text-center ${this.props.inline ? '' : 'mb-3'}`}>
        <Tooltip title={this.props.tooltipText}>
          <div>
            {this.props.cloudIcon && <CloudUploadIcon color="primary" fontSize="large" className="mt-2" />}
            <div>{this.props.actionDescription}</div>
          </div>
        </Tooltip>
      </div>
    );
  }

  render() {
    if (!this.props.onDrop) {
      return this.props.children;
    }

    return (
      <Dropzone
        onDrop={this.props.onDrop}
        noClick={this.props.noClick}
        noKeyboard
        disabled={this.props.disabled}
        multiple={this.props.multiple}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          // isDragAccept,
          isDragReject
          // acceptedFiles,
          // rejectedFiles
        }) => (
          <div
            {...getRootProps()}
            className={classNames('dropzone', styles.base, {
              'dropzone--isActive': isDragActive,
              [styles.active]: isDragActive,
              [styles.reject]: isDragReject,
              [styles.clickable]: !this.props.noClick
            })}
            style={styles}
          >
            <input {...getInputProps()} />
            {!this.props.disabled && !this.props.inline && this.renderDropContainer()}

            <div className="text-center">{this.props.dropzoneErrorMessage.length > 0 && <p>{this.props.dropzoneErrorMessage}</p>}</div>
            {this.props.showLoadingIndicator ? (
              <CircularProgress size={110} thickness={3} style={{ left: 'calc(50% - 64px)', position: 'relative' }} />
            ) : (
              <Tooltip title={this.props.tooltipText} open={this.props.inline ? undefined : false}>
                {
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                  <div style={{ position: 'relative' }} onClick={e => e.stopPropagation()}>
                    {this.props.children}
                    {this.props.inline && <div className={styles.overlay}>{this.renderDropContainer()}</div>}
                  </div>
                }
              </Tooltip>
            )}
          </div>
        )}
      </Dropzone>
    );
  }
}
DropContainer.defaultProps = {
  onDrop: undefined,
  dropzoneErrorMessage: '',
  showLoadingIndicator: false,
  noClick: true,
  actionDescription: 'Dateien hier ablegen',
  disabled: false,
  multiple: true,
  cloudIcon: true,
  tooltipText: "Legen Sie die gewünschte Datei einfach hier via Drag'n'Drop ab",
  inline: false
};
DropContainer.propTypes = {
  onDrop: PropTypes.func,
  children: PropTypes.object.isRequired,
  dropzoneErrorMessage: PropTypes.string,
  showLoadingIndicator: PropTypes.bool,
  noClick: PropTypes.bool,
  actionDescription: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  cloudIcon: PropTypes.bool,
  tooltipText: PropTypes.string,
  inline: PropTypes.bool
};
export default DropContainer;
