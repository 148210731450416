import MUIDataTable from 'mui-datatables';
import React, { PureComponent } from 'react';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import Tooltip from '@material-ui/core/Tooltip';

import MuiDatatableSaveResultListBox from './MuiDatatableSaveResultListBox';
// import { th } from 'date-fns/esm/locale';
import * as CONSTANTS from '../constants';

const systemPartner = CONSTANTS.partner;
const THEME = require('../theme')[systemPartner];

class SafeMuiDatatable extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      markListPopupOpen: false,
      data: this.replaceUndefinedWithEmptyString(props.data)
    };
    this.muiTheme = createTheme(this.props.muiTheme);
    this.tableRef = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    // fix error when table data changes so current page no longer exists
    if (!this.props.doNotResetPages) {
      if (nextProps.data.length !== this.props.data.length) {
        this.tableRef.current.changePage(0);
      }
    }

    if (nextProps.data !== this.props.data) {
      this.setState({
        data: this.replaceUndefinedWithEmptyString(nextProps.data)
      });
    }
  }

  replaceUndefinedWithEmptyString(data) {
    const formatedData = [];
    data.forEach(row => {
      const formatedRow = [];
      row.forEach(cell => {
        // !IMPORTANT: if value is false it will be replaced with ''
        formatedRow.push(cell || '');
      });
      formatedData.push(formatedRow);
    });
    return formatedData;
  }

  prepareDataForListSave(data) {
    let indices = [];
    if (this.tableRef.current && this.tableRef.current.state.displayData) {
      indices = this.tableRef.current.state.displayData.map(entry => entry.dataIndex);
    }
    const mappedData = indices.length ? indices.map(index => data[index]) : data;
    return mappedData
      .filter(entry => !!entry)
      .map(entry => ({
        [this.props.markListType]: entry[this.props.customMarklistEntryIdParameterName],
        matchPercentage: this.props.markListType === 'candidate' && '0',
        willingnessToChange: this.props.markListType === 'candidate' && '0'
      }));
  }

  prepareColumns(columns) {
    if (!this.props.tableName) return columns;

    const mappedColumns = columns;
    let savedColumnSettings = localStorage.getItem(this.props.tableName);
    if (savedColumnSettings) {
      savedColumnSettings = JSON.parse(savedColumnSettings);
      savedColumnSettings = savedColumnSettings.columnSettings;
    }

    // in case settings are found in local storage
    if (savedColumnSettings && savedColumnSettings.length > 0) {
      columns.forEach((column, index) => {
        if (savedColumnSettings.indexOf(column.name) === -1) {
          if (column.options && column.options.display !== 'excluded') {
            mappedColumns[index].options.display = false;
          } else if (!column.options) {
            mappedColumns[index].options = {};
            mappedColumns[index].options.display = false;
          }
        } else {
          if (!mappedColumns[index].options) mappedColumns[index].options = {};
          mappedColumns[index].options.display = true;
        }
      });
    }
    columns.forEach((column, index) => {
      let columnFilter = this.props.initialFilter && this.props.initialFilter[column.name];
      if (this.state.filterList && this.state.filterList.length === columns.length) {
        columnFilter = this.state.filterList[index];
      }
      if (!mappedColumns[index].options) {
        mappedColumns[index].options = {};
      }
      if (columnFilter) {
        mappedColumns[index].options.filterList = Array.isArray(columnFilter) ? columnFilter : [columnFilter];
      }
      if (this.state.sortState && this.state.sortState.length === columns.length) {
        if (this.state.sortState[index] === 'none') {
          delete mappedColumns[index].options.sortDirection;
        } else {
          mappedColumns[index].options.sortDirection = this.state.sortState[index];
        }
      }
    });
    return mappedColumns;
  }

  onTableChange = (action, tableState) => {
    if (this.props.onTableChange) this.props.onTableChange(action, tableState);
    if (!this.props.tableName) return;

    const rowsPerPage = tableState.rowsPerPage;
    const columnSettings = [];
    tableState.columns.forEach(column => {
      if (column.display === 'true' || column.display === true) {
        columnSettings.push(column.name);
      }
    });
    const tableSettings = {
      rowsPerPage,
      columnSettings
    };

    localStorage.setItem(this.props.tableName, JSON.stringify(tableSettings));
    const sortState = tableState.columns.map(col => col.sortDirection);
    this.setState({
      filterList: tableState.filterList,
      sortState,
      searchText: tableState.searchText
    });
  };

  getOptions() {
    const copyOfOptions = { ...SafeMuiDatatable.defaultProps.options, ...this.props.options };

    // default is true and causes checkboxes to overflow other content when scrolling on x axis
    copyOfOptions.fixedHeader = false;

    if (this.props.onRowClick) {
      copyOfOptions.onRowClick = this.props.onRowClick;
    }
    if (this.props.onRowSelectionChange) {
      // in newer versions the name of the function is onRowSelectionChange
      copyOfOptions.onRowsSelect = this.props.onRowSelectionChange;
    }
    if (this.props.showMarklistButton) {
      copyOfOptions.customToolbar = () => (
        <IconButton
          variant="contained"
          color="primary"
          disabled={this.props.data.length === 0}
          onClick={() => this.setState({ markListPopupOpen: true })}
        >
          <Tooltip title="Daten in Markierungsliste speichern">
            <StarIcon style={{ color: 'rgba(0,0,0,0.54)' }} />
          </Tooltip>
        </IconButton>
      );
    }
    if (!this.props.tableName) return copyOfOptions;
    let savedColumnSettings = localStorage.getItem(this.props.tableName);
    if (savedColumnSettings) savedColumnSettings = JSON.parse(savedColumnSettings);
    if (savedColumnSettings && savedColumnSettings.rowsPerPage) copyOfOptions.rowsPerPage = savedColumnSettings.rowsPerPage;
    copyOfOptions.onTableChange = this.onTableChange;
    copyOfOptions.onTableInit = this.onTableChange;
    copyOfOptions.onCellClick = this.props.onCellClick || this.onCellClick;

    const downloadOptions = {
      filename: 'tableDownload.csv',
      separator: ';'
    };

    if (copyOfOptions.downloadOptions && copyOfOptions.downloadOptions.filename) {
      downloadOptions.filename = copyOfOptions.downloadOptions.filename;
    }

    copyOfOptions.downloadOptions = downloadOptions;

    copyOfOptions.onDownload = (buildHead, buildBody, columns, data) => {
      // format dates for report
      const mutatedData = data.map(item => ({
        ...item,
        data: item.data.map((value, index) => {
          const column = columns[index];
          return column.customDownloadRender ? column.customDownloadRender(value) : value;
        })
      }));

      // add UTF8 BOM
      return `\ufeff${`${buildHead(columns)}${buildBody(mutatedData)}`.trim()}`;
    };
    if (this.state.searchText) {
      copyOfOptions.searchText = this.state.searchText;
    }

    return copyOfOptions;
  }

  render() {
    return (
      <div>
        <MuiThemeProvider theme={this.muiTheme}>
          <MUIDataTable
            {...this.props}
            title={this.props.title}
            data={this.state.data}
            columns={this.prepareColumns(this.props.columns)}
            options={this.getOptions()}
            innerRef={this.tableRef}
            // options={this.props.options}
          />
        </MuiThemeProvider>
        <MuiDatatableSaveResultListBox
          data={this.prepareDataForListSave(this.props.unformatedData)}
          open={this.state.markListPopupOpen}
          markListPopupType={this.props.markListType}
          handleSnackbarOpen={this.props.handleSnackbarOpen}
          handleClose={() => this.setState({ markListPopupOpen: false })}
        />
      </div>
    );
  }
}

SafeMuiDatatable.defaultProps = {
  options: {
    filterType: 'checkbox',
    rowsPerPageOptions: [5, 10, 15, 100],
    responsive: 'scrollMaxHeight',
    selectableRows: 'none',
    fixedHeader: true,
    filter: true,
    search: true,
    print: true,
    download: true,
    viewColumns: true,
    textLabels: {
      body: {
        noMatch: 'Keine passenden Einträge gefunden',
        toolTip: 'Sortieren'
      },
      pagination: {
        next: 'Nächste Seite',
        previous: 'Vorherige Seite',
        rowsPerPage: 'Zeilen pro Seite:',
        displayRows: 'von'
      },
      toolbar: {
        search: 'Suche',
        downloadCsv: 'Download CSV',
        print: 'Drucken',
        viewColumns: 'Spalten',
        filterTable: 'Tabelle filtern'
      },
      filter: {
        all: 'Alle',
        title: 'Filter',
        reset: 'Zurücksetzten'
      },
      viewColumns: {
        title: 'Spalten anzeigen',
        titleAria: 'Spalten anzeigen/ausblenden'
      },
      selectedRows: {
        text: 'Zeilen ausgewählt',
        delete: 'Löschen',
        deleteAria: 'Ausgewählte Zeilen löschen'
      }
    }
  },
  muiTheme: {
    palette: THEME.app.palette,
    overrides: {
      MUIPopover: {
        root: {
          top: 0
        }
      },

      MUIDataTable: {
        responsiveScroll: {
          maxHeight: '500px'
        }
      },
      MUITableHead: {
        root: {
          position: 'sticky'
        }
      },
      MUIDataTableViewCol: {
        root: {
          maxHeight: '350px'
        }
      },
      MUIDataTableBodyCell: {
        root: {
          whiteSpace: 'normal',
          fontSize: '0.8125rem',
          wordWrap: 'break-word',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '10px',
          paddingRight: '10px'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          whiteSpace: 'normal',
          wordWrap: 'break-word',
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '10px',
          paddingRight: '10px',
          position: 'sticky',
          top: 0,
          backgroundColor: '#fff',
          zIndex: 1
        }
      }
    }
  },

  onRowClick: undefined,
  onRowSelectionChange: undefined,
  tableName: undefined,
  initialFilter: undefined, // {columnName: "Standort"}
  onTableChange: undefined,
  onCellClick: undefined,
  showMarklistButton: false,
  markListType: '',
  handleSnackbarOpen: undefined,
  unformatedData: [],
  customMarklistEntryIdParameterName: 'id',
  doNotResetPages: false
};

SafeMuiDatatable.propTypes = {
  options: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  muiTheme: PropTypes.object,
  onRowClick: PropTypes.func,
  onRowSelectionChange: PropTypes.func,
  tableName: PropTypes.string,
  initialFilter: PropTypes.object,
  onTableChange: PropTypes.func,
  onCellClick: PropTypes.func,
  showMarklistButton: PropTypes.bool,
  markListType: PropTypes.string, // needed if showMarklistButton===true
  handleSnackbarOpen: PropTypes.func, // needed if showMarklistButton===true
  unformatedData: PropTypes.array, // needed if showMarklistButton===true
  customMarklistEntryIdParameterName: PropTypes.string, // optional if showMarklistButton===true
  doNotResetPages: PropTypes.bool
};
export default SafeMuiDatatable;
