import React, { Component } from 'react';
import PropTypes from 'prop-types';

// material UI
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import NotificationListItem from './NotificationListItem';
import ReminderListItem from './ReminderListItem';
import UpdateCounterPartReminderPopUpContainer from './updateCounterPartReminderPopUpContainer';

const MAP_INTERVIEW_TYPE = {
  first: 'Erstgespräch',
  second: 'Zweitgespräch'
};

const MAP_INTERVIEW_STATUS = {
  accepted: 'Angenommen',
  declined: 'Abgelehnt'
};

const MAP_JOB_TYPE = {
  jobPosting: 'Homepage',
  stepstone: 'Stepstone'
};
class NotificationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateCounterPartReminderPopUpIsOpen: false,
      counterPartReminderToEdit: {
        /*
        company: { type: mongoose.Schema.Types.ObjectId, ref: 'Company' },
  // TODO refactor counter parts to separate model
  counterPart: {
    type: mongoose.Schema.Types.ObjectId,
    ref: 'Company.counterPart' // this approach doesn't work
  },
  note: String,
  isDone: { type: Boolean, default: false },
  //   dueDate: { type: Date, default: Date.now }
  notification: { type: mongoose.Schema.Types.ObjectId, ref: 'Notification' } */
        id: '',
        companyId: '',
        counterPartId: '',
        note: '',
        triggerDate: new Date(), // evtl überflüssig weil Inhalt von der notifcation
        notificationId: ''
      }
    };
  }

  onEditButtonClick = counterPartReminder => {
    console.log('this counterpartReminder aus dem onEditButtonClick');
    this.setState({
      updateCounterPartReminderPopUpIsOpen: true,
      counterPartReminderToEdit: counterPartReminder
    });
    this.props.onEditButtonClick();
  };

  renderNotificationItems(dateSpanFrom, dateSpanTill, timeSeparation = true) {
    const array = [];
    let isFirstItem = true;
    this.props.notifications.forEach(notification => {
      let skipTheDate = false;

      if (timeSeparation) {
        let timeTriggerDate = new Date(); // hack?
        if (notification.dueDate) {
          timeTriggerDate = new Date(notification.dueDate).getTime();
        } else if (notification.triggerDate) {
          timeTriggerDate = new Date(notification.triggerDate).getTime();
        }

        const timespanFrom = new Date().getTime() - dateSpanFrom * 24 * 60 * 60 * 1000;
        const timespanTill = new Date().getTime() - dateSpanTill * 24 * 60 * 60 * 1000;
        if (!(timeTriggerDate <= timespanFrom && timeTriggerDate > timespanTill)) {
          skipTheDate = true;
        }
      }
      if (!skipTheDate) {
        if (!isFirstItem) {
          array.push(<Divider key={`dividerFor${notification.id}`} />);
        }
        isFirstItem = false;
        if (this.props.onlyReminders) {
          array.push(
            <ReminderListItem
              key={notification.id}
              dueDate={notification.dueDate}
              title={`Wiedervorlage ${notification.company.name}`}
              description={notification.note}
              counterPart={notification.counterPart}
              isRead={notification.isDone}
              id={notification.id}
              companyId={notification.company.id}
              onIsReadButtonClick={this.props.onIsReadButtonClick}
              onDeleteButtonClick={this.props.onDeleteButtonClick}
              onRowClick={this.props.onRowClick}
              onEditButtonClick={this.onEditButtonClick}
            />
          );
        } else if (notification.type === 'counterPartReminder') {
          array.push(
            <NotificationListItem
              key={notification.id}
              dueDate={notification.triggerDate}
              title="Wiedervorlage"
              description={(notification.notificationObject.counterPartReminder.company || {}).name || '(Unternehmen nicht gefunden)'}
              isRead={notification.isRead}
              id={notification.id}
              onIsReadButtonClick={this.props.onIsReadButtonClick}
              onRowClick={this.props.onRowClick}
              notificationObject={notification.notificationObject}
              type={notification.type}
            />
          );
        } else if (notification.type === 'candidateStatusExpired') {
          const candidateName = notification.notificationObject.candidateStatusExpired.candidate
            ? (notification.notificationObject.candidateStatusExpired.candidate.firstName &&
                `${notification.notificationObject.candidateStatusExpired.candidate.firstName} `) +
              (notification.notificationObject.candidateStatusExpired.candidate.lastName &&
                `${notification.notificationObject.candidateStatusExpired.candidate.lastName} `)
            : '(Kandidat nicht gefunden)';
          array.push(
            <NotificationListItem
              key={notification.id}
              dueDate={notification.triggerDate}
              title="Kandidat im Gespräch ist abgelaufen"
              description={candidateName}
              isRead={notification.isRead}
              id={notification.id}
              onIsReadButtonClick={this.props.onIsReadButtonClick}
              onRowClick={this.props.onRowClick}
              notificationObject={notification.notificationObject}
              type={notification.type}
            />
          );
        } else if (notification.type === 'projectStatusChanged') {
          const projectName = notification.notificationObject.projectStatusChanged.project
            ? `${notification.notificationObject.projectStatusChanged.project.jobTitle1} (${
                notification.notificationObject.projectStatusChanged.project.companyName
              })`
            : '(Projekt nicht gefunden)';
          array.push(
            <NotificationListItem
              key={notification.id}
              dueDate={notification.triggerDate}
              title={`Projekt-Status wurde auf '${notification.notificationObject.projectStatusChanged.status}' gesetzt`}
              description={projectName}
              isRead={notification.isRead}
              id={notification.id}
              onIsReadButtonClick={this.props.onIsReadButtonClick}
              onRowClick={this.props.onRowClick}
              notificationObject={notification.notificationObject}
              type={notification.type}
            />
          );
        } else if (notification.type === 'candidateAddToProject') {
          const description = notification.notificationObject.addCandidateToProject.project.jobTitle1
            ? `Neue Bewerbung von ${notification.notificationObject.addCandidateToProject.candidate.lastName} ${
                notification.notificationObject.addCandidateToProject.candidate.lastName
              } für Auftrag ${notification.notificationObject.addCandidateToProject.project.company.name} - ${
                notification.notificationObject.addCandidateToProject.project.jobTitle1
              } liegt vor.`
            : '(Projekt nicht gefunden)';
          array.push(
            <NotificationListItem
              key={notification.id}
              dueDate={notification.triggerDate}
              title="Neue Bewerbung"
              description={description}
              isRead={notification.isRead}
              id={notification.id}
              onIsReadButtonClick={this.props.onIsReadButtonClick}
              onRowClick={this.props.onRowClick}
              notificationObject={notification.notificationObject}
              type={notification.type}
            />
          );
        } else if (notification.type === 'clientReleaseJob') {
          const description = notification.notificationObject.clientReleaseJob.project.jobTitle1
            ? `Die Anzeige ${notification.notificationObject.clientReleaseJob.project.company.name} - ${
                notification.notificationObject.clientReleaseJob.project.jobTitle1
              } wurde vom Kunden freigegeben`
            : '(Projekt nicht gefunden)';
          array.push(
            <NotificationListItem
              key={notification.id}
              dueDate={notification.triggerDate}
              title="Anzeige vom Kunden freigegeben"
              description={description}
              isRead={notification.isRead}
              id={notification.id}
              onIsReadButtonClick={this.props.onIsReadButtonClick}
              onRowClick={this.props.onRowClick}
              notificationObject={notification.notificationObject}
              type={notification.type}
            />
          );
        } else if (notification.type === 'clientReadDossier') {
          const description = notification.notificationObject.clientReadDossier.project.jobTitle1
            ? `Kunde ${notification.notificationObject.clientReadDossier.project.company.name} hat im Projekt ${
                notification.notificationObject.clientReadDossier.project.jobTitle1
              } das Dossier von ${notification.notificationObject.clientReadDossier.candidate.firstName} ${
                notification.notificationObject.clientReadDossier.candidate.lastName
              } gelesen`
            : '(Projekt nicht gefunden)';
          array.push(
            <NotificationListItem
              key={notification.id}
              dueDate={notification.triggerDate}
              title="Dossier gelesen"
              description={description}
              isRead={notification.isRead}
              id={notification.id}
              onIsReadButtonClick={this.props.onIsReadButtonClick}
              onRowClick={this.props.onRowClick}
              notificationObject={notification.notificationObject}
              type={notification.type}
            />
          );
        } else if (notification.type === 'clientUpdateAcceptInterview') {
          const description = notification.notificationObject.clientUpdateAcceptInterview.project.jobTitle1
            ? `Kunde ${notification.notificationObject.clientUpdateAcceptInterview.project.company.name} hat im Project ${
                notification.notificationObject.clientUpdateAcceptInterview.project.jobTitle1
              } das ${MAP_INTERVIEW_TYPE[notification.notificationObject.clientUpdateAcceptInterview.interviewType]} mit ${
                notification.notificationObject.clientUpdateAcceptInterview.candidate.firstName
              } ${notification.notificationObject.clientUpdateAcceptInterview.candidate.lastName} ${
                MAP_INTERVIEW_STATUS[notification.notificationObject.clientUpdateAcceptInterview.interviewStatus]
              }`
            : '(Projekt nicht gefunden)';
          array.push(
            <NotificationListItem
              key={notification.id}
              dueDate={notification.triggerDate}
              title="Kunde Kandidatengespräch"
              description={description}
              isRead={notification.isRead}
              id={notification.id}
              onIsReadButtonClick={this.props.onIsReadButtonClick}
              onRowClick={this.props.onRowClick}
              notificationObject={notification.notificationObject}
              type={notification.type}
            />
          );
        } else if (notification.type === 'clientWantsContractOffer') {
          const description = notification.notificationObject.clientWantsContractOffer.project.jobTitle1
            ? `Kunde ${notification.notificationObject.clientWantsContractOffer.project.company.name} hat im Project ${
                notification.notificationObject.clientWantsContractOffer.project.jobTitle1
              } ein Vertragsangebot bestätigt!`
            : '(Projekt nicht gefunden)';
          array.push(
            <NotificationListItem
              key={notification.id}
              dueDate={notification.triggerDate}
              title="Kunde gibt Vertragsangebot"
              description={description}
              isRead={notification.isRead}
              id={notification.id}
              onIsReadButtonClick={this.props.onIsReadButtonClick}
              onRowClick={this.props.onRowClick}
              notificationObject={notification.notificationObject}
              type={notification.type}
            />
          );
        } else if (notification.type === 'projectJobsExpired') {
          const description = notification.notificationObject.projectJobsExpired.project.jobTitle1
            ? `Anzeige ${notification.notificationObject.projectJobsExpired.project.company.name} - ${
                notification.notificationObject.projectJobsExpired.project.jobTitle1
              } ist auf ${MAP_JOB_TYPE[notification.notificationObject.projectJobsExpired.type]} ausgelaufen!`
            : '(Projekt nicht gefunden)';
          array.push(
            <NotificationListItem
              key={notification.id}
              dueDate={notification.triggerDate}
              title="Anzeige ausgelaufen"
              description={description}
              isRead={notification.isRead}
              id={notification.id}
              onIsReadButtonClick={this.props.onIsReadButtonClick}
              onRowClick={this.props.onRowClick}
              notificationObject={notification.notificationObject}
              type={notification.type}
            />
          );
        }
      }
    });

    if (array.length > 0) {
      return array;
    }
    if (this.props.onlyReminders) return <div className="col-12 text-center p-3">Keine Wiedervorlagen vorhanden </div>;
    return <div className="col-12 text-center p-3">Keine Benachrichtigungen vorhanden </div>;
  }

  renderNotificationList() {
    if (this.props.notifications.length > 0) {
      if (this.props.timeSeparation) {
        return (
          <React.Fragment>
            <h6 className="mb-3 mt-3">Letzte 24 Stunden</h6>
            <Paper>
              <List>{this.renderNotificationItems(0, 1)}</List>
            </Paper>
            {this.props.onlyReminders && (
              <React.Fragment>
                <h6 className="mb-3 mt-3">In Zukunft</h6>
                <Paper>
                  <List>{this.renderNotificationItems(-99999, 0)}</List>
                </Paper>
              </React.Fragment>
            )}
            <h6 className="mb-3 mt-3">Letzte Woche</h6>
            <Paper>
              <List>{this.renderNotificationItems(1, 7)}</List>
            </Paper>
            <h6 className="mb-3 mt-3">Älter als 7 Tage</h6>
            <Paper>
              <List>{this.renderNotificationItems(7, 999999)}</List>
            </Paper>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <Paper>
            <List>{this.renderNotificationItems(0, 0, false)}</List>
          </Paper>
        </React.Fragment>
      );
    }
    if (this.props.onlyReminders) return <div className="col-12 text-center p-3">Keine Wiedervorlagen vorhanden </div>;
    return <div className="col-12 text-center p-3">Keine Benachrichtigungen vorhanden </div>;
  }

  render() {
    return (
      <React.Fragment>
        {this.renderNotificationList()}
        {this.state.updateCounterPartReminderPopUpIsOpen && (
          <UpdateCounterPartReminderPopUpContainer
            open={this.state.updateCounterPartReminderPopUpIsOpen}
            data={this.state.counterPartReminderToEdit}
            handleSnackbarOpen={this.props.handleSnackbarOpen}
            indexInformation="Wiedervorlage"
            handleClose={() => {
              this.props.onEditButtonClick();
              this.setState({ updateCounterPartReminderPopUpIsOpen: false });
            }}
          />
        )}
      </React.Fragment>
    );
  }
}

NotificationList.propTypes = {
  notifications: PropTypes.array.isRequired,
  onlyReminders: PropTypes.bool,
  onIsReadButtonClick: PropTypes.func.isRequired,
  onDeleteButtonClick: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
  timeSeparation: PropTypes.bool,
  handleSnackbarOpen: PropTypes.func.isRequired
};
NotificationList.defaultProps = {
  onlyReminders: false,
  timeSeparation: false
};
export default NotificationList;
