import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// import IconButton from '@material-ui/core/IconButton';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import * as UTILS from '../utils/utilFunctions';

// prepare column options according to save viewColumn settings
function prepareColumns(columns) {
  const mappedColumns = columns;
  const projectStatusColumnSettings = [];
  columns.forEach(column => {
    if (column.options) {
      if (column.options.display === 'true' || column.options.display === true) {
        projectStatusColumnSettings.push(column.name);
      }
    } else {
      projectStatusColumnSettings.push(column.name);
    }
  });

  return mappedColumns;
}

class CandidateLanguageSkills extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { columns: prepareColumns(this.props.columns) };

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  mapLanguageSkills(skillLevel) {
    console.log(skillLevel);
    switch (skillLevel) {
      case '1':
        return 'Anfängerkenntnisse';

      case '2':
        return 'Grundkenntnisse';

      case '3':
        return 'Fortgeschrittene Kenntnisse';

      case '4':
        return 'Gute Kenntnisse';

      case '5':
        return 'Sehr gute Kenntnisse';

      case '7':
        return 'Perfekte Kenntnisse oder Muttersprache';

      default:
        return skillLevel;
    }
  }

  prepareDataForTable(data) {
    const formatedData = [];
    if (data)
      data.forEach(entry => {
        const educationData = {
          entryId: entry.id,
          language: entry.language,
          skillLevel: this.mapLanguageSkills(entry.skillLevel)
        };

        formatedData.push([...Object.values(educationData), this.renderEditButton(educationData)]);
      });

    return formatedData;
  }

  renderEditButton(entry) {
    return (
      <div className="candidateTableAction">
        <IconButton className="" aria-label="Edit" color="primary" onClick={() => this.props.handleOpenCandidateLanguageUpdatePopUp(entry)}>
          <EditIcon />
        </IconButton>
        <IconButton
          className=""
          aria-label="Delete"
          color="primary"
          onClick={() => this.props.handleDeleteEntry('languageSkills', entry.entryId)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="CandidateLanguageSkills row">
          <div className="col-12">
            <SafeMuiDatatable
              title="Sprachen"
              tableName="candidateLanguageSkills"
              data={this.prepareDataForTable(this.props.data.languageSkills)}
              columns={this.state.columns}
            />
          </div>
        </div>
      </div>
    );
  }
}

CandidateLanguageSkills.defaultProps = {
  columns: [
    {
      name: 'ID',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    {
      name: 'Sprache',
      options: {
        display: true,
        filter: false
      }
    },
    {
      name: 'Grad der Beherrschung',
      options: {
        display: true,
        filter: false
      }
    },
    {
      name: 'Aktionen',
      options: {
        display: true,
        filter: false
      }
    }
  ]
};

CandidateLanguageSkills.propTypes = {
  data: PropTypes.object.isRequired,

  columns: PropTypes.array,
  handleOpenCandidateLanguageUpdatePopUp: PropTypes.func.isRequired,
  handleDeleteEntry: PropTypes.func.isRequired
};
export default CandidateLanguageSkills;
