import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';

import TEXT from './staticContent/text';
import * as UTILS from '../../utils/utilFunctions';
import * as CONSTANTS from '../../constants';

import GenericArrayForm from '../../generic_components/GenericArrayForm';

class IncomingCandidateEducations extends PureComponent {
  generateFormRows(candidate) {
    const forms = [];
    if (candidate.educations)
      candidate.educations.forEach(education => {
        forms.push({
          educationText: {
            value: education.educationText,
            label: TEXT.incomingCandidateEducations.educationText,
            customClass: 'col-6 mb-2'
          },
          instituteName: {
            value: education.instituteName,
            label: TEXT.incomingCandidateEducations.instituteName,
            customClass: 'col-6 mb-2'
          },
          educationType: {
            value: education.educationType,
            label: TEXT.incomingCandidateEducations.educationType,
            customClass: 'col-6 mb-2'
          },
          fromDate: {
            value: UTILS.formatDateForDateField(education.fromDate),
            label: TEXT.incomingCandidateEducations.fromDate,
            type: 'date',
            customClass: 'col-3 mb-2'
          },
          tillDate: {
            value: UTILS.formatDateForDateField(education.tillDate),
            label: TEXT.incomingCandidateEducations.tillDate,
            type: 'date',
            customClass: 'col-3 mb-2'
          }
        });
      });

    return forms;
  }

  handleConfirmButton = updatedData => {
    const update = { educations: updatedData };

    this.props.liftUpSelectedCandidateToIncomingCandidates(update);

    this.props.handleNext();
  };

  handleMiddleButton = updatedData => {
    
    this.props.handleNext();
    this.props.handleNext();
    this.props.handleNext();
    this.props.handleNext();

    let update = { educations: updatedData};

    if (CONSTANTS.isLienert) {
      update = { educations: updatedData, additionalSkills: [], languageSkills: [], tags: [] };
    }
    this.props.liftUpSelectedCandidateToIncomingCandidates(update);
  };

  renderInputFields() {
    return (
      <div className="row">
        <div className="col-12">
          <GenericArrayForm
            // key={this.props.selectedIncomingCandidate.id}
            formRows={this.generateFormRows(this.props.selectedIncomingCandidate)}
            oldValue={this.generateFormRows(this.props.selectedDuplicateCandidate)}
            newValue={this.generateFormRows(this.props.selectedIncomingCandidate)}
            handleSubmit={this.handleConfirmButton}
            handleCancel={this.props.handleBack}
            handleMiddleButton={this.handleMiddleButton}
            buttonSaveText={TEXT.incomingCandidates.buttonNext}
            title={TEXT.incomingCandidates.educations}
            buttonCancelText={TEXT.incomingCandidates.buttonBack}
            buttonSubmitText={TEXT.incomingCandidates.buttonNext}
            middleButtonText={TEXT.incomingCandidates.buttonCompleteNow}
            baseModel={{
              educationText: {
                value: '',
                label: TEXT.incomingCandidateEducations.educationText,
                customClass: 'col-6 mb-2'
              },
              instituteName: {
                value: '',
                label: TEXT.incomingCandidateEducations.instituteName,
                customClass: 'col-6 mb-2'
              },
              educationType: {
                value: '',
                label: TEXT.incomingCandidateEducations.educationType,
                customClass: 'col-6 mb-2'
              },
              fromDate: {
                value: '',
                label: TEXT.incomingCandidateEducations.fromDate,
                type: 'date',
                customClass: 'col-3 mb-2'
              },
              tillDate: {
                value: '',
                label: TEXT.incomingCandidateEducations.tillDate,
                type: 'date',
                customClass: 'col-3 mb-2'
              }
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    return <Paper>{this.renderInputFields()}</Paper>;
  }
}

IncomingCandidateEducations.defaultProps = {
  selectedDuplicateCandidate: {}
};
IncomingCandidateEducations.propTypes = {
  selectedIncomingCandidate: PropTypes.object.isRequired,
  handleNext: PropTypes.func.isRequired,
  handleBack: PropTypes.func.isRequired,
  liftUpSelectedCandidateToIncomingCandidates: PropTypes.func.isRequired,
  selectedDuplicateCandidate: PropTypes.object
};
export default IncomingCandidateEducations;
