import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TEXT_GENERAL from '../../text';
import * as CONSTANTS from '../../constants';
import { post } from '../../utils/apiUtils';
import * as UTILS from '../../utils/utilFunctions';
import OFFER_CONSTANTS from '../staticContent/offersConstants';
import withUser from '../../utils/withUser';
import ConfirmationModal from '../../generic_components/ConfirmationModal';
import SafeMuiDatatable from '../../generic_components/SafeMuiDatatable';

const OffersPanel = ({
  selectedCompany,
  handShowOffersCreateUpdatePopUp,
  handleSnackbarOpen,
  errorHandling,
  loadOffers,
  offerData,
  handleChangeTablePageNumber,
  offerTablePaging,
  selectedOffer,
  setSelectedOffer
}) => {
  const [isDeleteOfferPopUpOpen, setIsDeleteOfferPopUpOpen] = useState(false);

  const handleLoadOffers = useCallback(async () => {
    await loadOffers(selectedCompany.id, offerTablePaging.page, null);
  }, [selectedCompany, loadOffers, offerTablePaging]);

  useEffect(() => {
    handleLoadOffers();
  }, [handleLoadOffers]);

  const handleEditOffer = useCallback(
    offerId => {
      if (!offerId) {
        return;
      }
      const findOffer = offerData.offerList.find(singleItem => singleItem.id === offerId);
      if (!findOffer) {
        return;
      }
      setSelectedOffer(findOffer);
      handShowOffersCreateUpdatePopUp(true);
    },
    [offerData, handShowOffersCreateUpdatePopUp, setSelectedOffer]
  );

  const handleDeleteOffer = useCallback(
    offerId => {
      if (!offerId) {
        return;
      }

      const findOffer = offerData.offerList.find(singleItem => singleItem.id === offerId);

      if (!findOffer) {
        return;
      }

      setIsDeleteOfferPopUpOpen(true);
      setSelectedOffer(findOffer);
    },
    [offerData, setSelectedOffer]
  );

  const deleteOffer = useCallback(() => {
    const url = CONSTANTS.deleteCompanyOffer;
    const payload = {
      companyId: selectedCompany.id,
      offerId: selectedOffer.id
    };
    post(url, payload)
      .then(() => {
        handleSnackbarOpen(`${TEXT_GENERAL.offers.popup.deleteSnackbarMessage}`);
        setIsDeleteOfferPopUpOpen(false);
        handleChangeTablePageNumber(0);
      })
      .catch(err => {
        errorHandling(err, url);
      });
  }, [selectedCompany, errorHandling, handleSnackbarOpen, selectedOffer, handleChangeTablePageNumber]);

  const getColumns = useMemo(
    () => [
      {
        name: TEXT_GENERAL.offers.fields.label.id,
        label: TEXT_GENERAL.offers.fields.label.id,
        id: 'id',
        options: {
          display: 'excluded',
          filter: false
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.advisor,
        label: TEXT_GENERAL.offers.fields.label.advisor,
        id: 'advisor',
        options: {
          display: true,
          filter: true,
          customBodyRender: value => (
            <Typography className="d-flex" variant="p">
              <Box
                className="ml-2 my-auto"
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '100px'
                }}
                variant="p"
              >
                {value}
              </Box>
            </Typography>
          ),
          customDownloadRender: value => value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.date,
        label: TEXT_GENERAL.offers.fields.label.date,
        id: 'date',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => UTILS.formatDateToDELocale(value ? new Date(value) : ''),
          customDownloadRender: value => (value ? UTILS.formatDateToDELocale(new Date(value)) : '')
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.client,
        label: TEXT_GENERAL.offers.fields.label.client,
        id: 'client',
        options: {
          display: true,
          filter: false,
          sort: false
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.newClient,
        label: TEXT_GENERAL.offers.fields.label.newClient,
        id: 'newClient',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => TEXT_GENERAL.offers.enum.boolean[value] || value,
          customDownloadRender: value => TEXT_GENERAL.offers.enum.boolean[value] || value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.vacancy,
        label: TEXT_GENERAL.offers.fields.label.vacancy,
        id: 'vacancy',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => (
            <Box
              sx={{
                maxWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': '5',
                '-webkit-box-orient': 'vertical'
              }}
            >
              {value}
            </Box>
          ),
          customDownloadRender: value => value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.taskType,
        label: TEXT_GENERAL.offers.fields.label.taskType,
        id: 'taskType',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => TEXT_GENERAL.offers.enum.taskType[value] || value,
          customDownloadRender: value => TEXT_GENERAL.offers.enum.taskType[value] || value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.directContact,
        label: TEXT_GENERAL.offers.fields.label.directContact,
        id: 'directContact',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => TEXT_GENERAL.offers.enum.boolean[value] || value,
          customDownloadRender: value => TEXT_GENERAL.offers.enum.boolean[value] || value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.flatRate,
        label: TEXT_GENERAL.offers.fields.label.flatRate,
        id: 'flatRate',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => (value ? value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") : value),
          customDownloadRender: value => (value ? value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") : value)
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.percentage,
        label: TEXT_GENERAL.offers.fields.label.percentage,
        id: 'percentage',
        options: {
          display: true,
          filter: false
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.moreInfo,
        label: TEXT_GENERAL.offers.fields.label.moreInfo,
        id: 'moreInfo',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => (
            <Box
              sx={{
                maxWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': '5',
                '-webkit-box-orient': 'vertical'
              }}
            >
              {value}
            </Box>
          ),
          customDownloadRender: value => value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.status,
        label: TEXT_GENERAL.offers.fields.label.status,
        id: 'status',
        options: {
          display: true,
          filter: false,
          customBodyRender: (value, tableMeta) => {
            const updatedValue = tableMeta.rowData[tableMeta.columnIndex] || value;
            const showValue = TEXT_GENERAL.offers.enum.status[updatedValue] || updatedValue;
            const valueColor = OFFER_CONSTANTS.status[updatedValue] || 'primary.main';
            return showValue ? (
              <Box
                sx={{
                  p: 1,
                  borderRadius: 10,
                  bgcolor: valueColor,
                  color: valueColor !== '' ? 'white' : '',
                  textAlign: 'center',
                  maxWidth: '85px'
                }}
              >
                {showValue}
              </Box>
            ) : null;
          },
          customDownloadRender: value => TEXT_GENERAL.offers.enum.status[value] || value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.declineReason,
        label: TEXT_GENERAL.offers.fields.label.declineReason,
        id: 'declineReason',
        options: {
          display: true,
          filter: false,
          customBodyRender: value => (
            <Box
              sx={{
                maxWidth: '200px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                '-webkit-line-clamp': '5',
                '-webkit-box-orient': 'vertical'
              }}
            >
              {value}
            </Box>
          ),
          customDownloadRender: value => value
        }
      },
      {
        name: TEXT_GENERAL.offers.fields.label.actions,
        label: TEXT_GENERAL.offers.fields.label.actions,
        id: 'actions',
        options: {
          filter: false,
          sort: false,
          download: false,
          customBodyRender: (value, tableMeta) => (
            <div className="candidateTableAction">
              <Tooltip title="Eintrag bearbeiten">
                <IconButton
                  className=""
                  aria-label="Edit"
                  color="primary"
                  onClick={() => {
                    handleEditOffer(...tableMeta.rowData);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Eintrag löschen">
                <IconButton className="" aria-label="Delete" color="primary" onClick={() => handleDeleteOffer(...tableMeta.rowData)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          ),
          customDownloadRender: value => value
        }
      }
    ],
    [handleDeleteOffer, handleEditOffer]
  );

  const options = useMemo(() => {
    const rowsPerPageOptions = [5, 10, 20, 30, 50];
    const newOptions = {
      count: offerData ? offerData.totalCount : 0,
      pagination: true,
      responsive: 'scrollMaxHeight',
      fixedHeader: true,
      selectableRows: 'none',
      page: offerTablePaging.page,
      rowsPerPageOptions,
      customSort: (data, colIndex, order) => UTILS.sortOffersTable(data, colIndex, order, getColumns),
      downloadOptions: {
        filename: 'Offerten-Export.csv',
        separator: ';'
      }
    };
    return newOptions;
  }, [offerData, offerTablePaging, getColumns]);

  const preparedTableData = useCallback(data => {
    const formattedData = [];

    if (data && data.offerList && data.offerList.length > 0) {
      data.offerList.forEach(entry => {
        const mappedData = {
          id: entry.id,
          user: `${entry.user.firstname} ${entry.user.lastname}`,
          date: entry.date,
          client: entry.company.name,
          newClient: entry.newClient,
          vacancy: entry.vacancy,
          taskType: entry.taskType,
          directContact: entry.directContact,
          flatRate: typeof entry.flatRate !== 'undefined' && entry.flatRate !== null ? `${entry.flatRate} CHF` : entry.flatRate,
          percentage: typeof entry.percentage !== 'undefined' && entry.percentage !== null ? `${entry.percentage}%` : entry.percentage,
          moreInfo: entry.moreInfo,
          status: entry.status,
          declineReason: entry.declineReason
        };

        const mappedEntry = JSON.parse(JSON.stringify(entry));
        mappedEntry.entryId = entry.id;
        formattedData.push([...Object.values(mappedData)]);
      });
    }

    return formattedData;
  }, []);

  return (
    <div className="row col-12">
      <Paper style={{ width: '100%', maxHeight: '1000px', overflow: 'auto' }}>
        <>
          {typeof getColumns !== 'undefined' && getColumns.length > 0 ? (
            <SafeMuiDatatable
              title={
                <Box display="flex">
                  <Typography variant="h6">Offerten</Typography>
                </Box>
              }
              options={options}
              doNotResetPages
              columns={getColumns}
              data={preparedTableData(offerData)}
              tableName="companyOffersTable"
            />
          ) : null}
        </>
      </Paper>
      <ConfirmationModal
        key="confirmation-entry-deletion"
        handleClose={() => setIsDeleteOfferPopUpOpen(false)}
        open={isDeleteOfferPopUpOpen}
        buttonPrimaryAction={() => deleteOffer()}
        headlineText="Offerte löschen"
        descriptionText="Soll die entsprechende Offerte gelöscht werden?"
      />
    </div>
  );
};

OffersPanel.propTypes = {
  selectedCompany: PropTypes.object.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  errorHandling: PropTypes.func.isRequired,
  loadOffers: PropTypes.func.isRequired,
  offerData: PropTypes.object.isRequired,
  handleChangeTablePageNumber: PropTypes.func.isRequired,
  offerTablePaging: PropTypes.object.isRequired,
  handShowOffersCreateUpdatePopUp: PropTypes.func.isRequired,
  selectedOffer: PropTypes.object.isRequired,
  setSelectedOffer: PropTypes.func.isRequired
};

export default withUser(OffersPanel);
