import React from 'react';

import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Grow from '@material-ui/core/Grow';
// import PlusIcon from '@material-ui/icons/AddCircleOutline';

export default function IvaPill(props) {
  return (
    <Grow in style={{ transitionDelay: '50ms' }}>
      <Chip
        label={props.label}
        onClick={() => props.onClick(props.label)}
        style={{ margin: '3px' }}
        size="small"
        color="primary"
        onMouseEnter={props.toggleHoverOn}
        onMouseLeave={props.toggleHoverOff}
      />
    </Grow>
  );
}

IvaPill.defaultProps = {};
IvaPill.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  toggleHoverOn: PropTypes.func.isRequired,
  toggleHoverOff: PropTypes.func.isRequired
};
