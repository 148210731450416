import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';

import TextField from '@material-ui/core/TextField';
import debounce from 'lodash/debounce';
import flowRight from 'lodash/flowRight';
import TEXT from '../../../text';
import * as FILTER_HELPER from '../../../utils/filterHelperFunctions';

class TitleCurrentJobFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { titleCurrentJobFilteredOptions: '' };

    this.onChange = this.onChange.bind(this);
    this.onInputFieldChangeDebounced = flowRight(
      debounce(this.onInputFieldChangeDebounced, 400),
      e => e.persist() || e
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.titleCurrentJobFilteredOptions !== prevProps.titleCurrentJobFilteredOptions) {
      // eslint-disable-next-line
      this.setState({
        titleCurrentJobFilteredOptions: this.props.titleCurrentJobFilteredOptions
      });
    }
  }

  /**
   * onChange method for the DurationCurrentJobFilter component, calls the onFilterChange function
   * @param  {array} range array of values of the two handlers of slider
   */
  onChange(arrayOfSelected) {
    this.props.onFilterChange('currentPosition', arrayOfSelected, FILTER_HELPER.filterFunctions.currentPosition);
  }

  handleChange = name => event => {
    const arrayOfSelected = this.props.titleCurrentJob;
    if (event.target.checked) {
      arrayOfSelected.push(name);
    } else {
      const index = arrayOfSelected.indexOf(name);
      arrayOfSelected.splice(index, 1);
    }

    this.onChange(arrayOfSelected);
  };

  onInputFieldChangeDebounced = e => {
    this.props.liftUpFilteredOptions('currentPosition', e.target.value);
  };

  onInputFieldChange = event => {
    this.setState({ titleCurrentJobFilteredOptions: event.target.value });
    this.onInputFieldChangeDebounced(event);
  };

  handleExpandButton = () => {
    this.props.showMoreFilterOptions('currentPosition');
  };

  render() {
    const headline = `${TEXT.filter.jobFilter.currentPosition.headline}`;
    const countedJobParameters = this.props.countedJobParameters;
    const checkboxValues = this.props.stickyFilter ? this.props.titleCurrentJob : countedJobParameters.jobText;
    return (
      <div>
        {(this.props.titleCurrentJobFilteredOptions && this.props.titleCurrentJobFilteredOptions.length > 0) ||
        (checkboxValues && checkboxValues.length > 0) ? (
          <div className="col-12 pb-5 checkFilterBox">
            <FormControl component="fieldset" fullWidth>
              <FormLabel component="legend">{headline}</FormLabel>
              <FormGroup>
                {!this.props.stickyFilter && (
                  <div className="row justify-content-center">
                    <div className=" col-12 pb-2">
                      <TextField
                        className="filteredOptionsInput"
                        fullWidth
                        onChange={this.onInputFieldChange}
                        value={this.state.titleCurrentJobFilteredOptions}
                        placeholder={`${TEXT.filter.jobFilter.currentPosition.placeholderFilteredOptions}`}
                      />
                    </div>
                  </div>
                )}
                {checkboxValues &&
                  checkboxValues.map(rawElement => {
                    let element = rawElement;
                    let elementCount;
                    let elementCountDOM = '';
                    if (rawElement.key) {
                      element = rawElement.key;
                      elementCount = rawElement.count;
                      elementCountDOM = `(${elementCount})`;
                    }
                    return (
                      <FormControlLabel
                        className="checkFilterLabel"
                        key={element}
                        control={
                          <Checkbox
                            className="chkBox_"
                            checked={this.props.titleCurrentJob.includes(element)}
                            onChange={this.handleChange(element)}
                            value={element}
                          />
                        }
                        label={`${element} ${elementCountDOM}`}
                      />
                    );
                  })}
              </FormGroup>
            </FormControl>
            {!this.props.stickyFilter &&
              countedJobParameters.jobText &&
              countedJobParameters.jobText.numberOfFilterOptions > this.props.numberOfShownFilterOptionForCurrentPositionFilter && (
                <Button onClick={this.handleExpandButton} fullWidth>
                  <i className="material-icons">expand_more</i>
                </Button>
              )}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}
TitleCurrentJobFilter.defaultProps = {
  countedJobParameters: {},
  stickyFilter: false
};
TitleCurrentJobFilter.propTypes = {
  onFilterChange: PropTypes.func.isRequired,
  countedJobParameters: PropTypes.object,
  titleCurrentJob: PropTypes.arrayOf(PropTypes.string).isRequired,
  liftUpFilteredOptions: PropTypes.func.isRequired,
  titleCurrentJobFilteredOptions: PropTypes.string.isRequired,
  stickyFilter: PropTypes.bool,
  showMoreFilterOptions: PropTypes.func.isRequired,
  numberOfShownFilterOptionForCurrentPositionFilter: PropTypes.number.isRequired
};
export default TitleCurrentJobFilter;
