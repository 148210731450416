import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import ProjectFinder from '../generic_components/ProjectFinder';
import DrawerWrapper from '../generic_components/DrawerWrapper';
import { addCandidateMarkListToProject } from '../candidateArchive/api/candidateArchiveAPI';

import * as UTILS from '../utils/utilFunctions';
import TEXT from '../candidateArchive/staticContent/text';

class AddCandidateMarkListToProjectDrawer extends PureComponent {
  constructor(props) {
    super(props);
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  render() {
    return (
      <DrawerWrapper open={this.props.open} onClose={this.props.handleClose} title="Alle Kandidaten zu Auftrag hinzufügen">
        <ProjectFinder
          handleProjectSelected={selectedProject => {
            addCandidateMarkListToProject(selectedProject[0], this.props.candidateMarkListId)
              .then(responseBody => {
                if (responseBody.success) {
                  this.props.handleSnackbarOpen('Kandidaten Liste wurde erfolgreich zu Auftrag vorgeschlagen');
                  this.props.handleClose();
                }
              })
              .catch(err => {
                this.errorHandling(err);
              });
          }}
          helperText={TEXT.search.searchFieldErrorMessage}
          confirmationDescription="Die Kandidaten werden zum Folgenden Auftrag vorgeschlagen:"
        />
      </DrawerWrapper>
    );
  }
}

AddCandidateMarkListToProjectDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  candidateMarkListId: PropTypes.string.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};

export default AddCandidateMarkListToProjectDrawer;
