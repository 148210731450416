import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import debounce from 'lodash/debounce';

import './Search.css';

// material UI
import Button from '@material-ui/core/Button';

// components
import { Paper, Tooltip } from '@material-ui/core';

import SearchResultCountBox from '../components/SearchResultCountBox';
import HelpBox from '../components/iva/HelpBox';
import SendSearchBox from '../components/SendSearchBox';
import AllSearchFields from '../components/AllSearchFields';
import DistanceSlider from '../components/DistanceSlider';
import PostalCodeAutosuggest from '../components/PostalCodeAutosuggest';

import * as constants from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

class Search extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lastFocusedField: {},
      currentFocusedField: {},
      showLoadingIndicatorResultCount: false,
      showSendBox: false,
      geoLocationError: false,
      geoLocationErrorText: '',
      ivaSuggestionsAreLoading: false,
      hoveringOverIvaPill: false,
      lastAjaxCall: undefined
    };

    this.onSearchFieldBlur = this.onSearchFieldBlur.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchFieldFocus = this.onSearchFieldFocus.bind(this);
    this.getAmountOfSearchResults = this.getAmountOfSearchResults.bind(this);
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.onHelpBoxLinkClick = this.onHelpBoxLinkClick.bind(this);
    this.updateSearchField = this.updateSearchField.bind(this);
  }

  componentDidMount() {
    document.title = `Suche - ${UTILS.getTitle()}`;
  }

  toggleHoverOn = () => {
    this.setState({ hoveringOverIvaPill: true });
  };

  toggleHoverOff = () => {
    this.setState({ hoveringOverIvaPill: false });
  };

  onHelpBoxLinkClick(value) {
    this.updateSearchField(value, this.state.lastFocusedField.searchFieldIndex, this.state.lastFocusedField.columnIndex);
  }

  onSearchChange(searchQuery, searchFieldIndex, columnIndex) {
    this.updateLastFocusedField(searchFieldIndex, columnIndex);
    this.props.liftUpStateToApp('searchQuery', searchQuery);
  }

  onSearchFieldFocus(columnIndex, searchFieldIndex) {
    this.setState({ currentFocusedField: { columnIndex, searchFieldIndex } });
  }

  onSearchFieldBlur(value, searchFieldIndex, columnIndex) {
    if (value === '') {
      // if value is empty check if its the last field in column and delete if its not
      if (!this.isFieldLastInColumn(searchFieldIndex, columnIndex)) {
        this.handleRemoveSearchfield(searchFieldIndex, columnIndex);
      }
    } else {
      this.handleUpdateSearchterm(value, searchFieldIndex, columnIndex);
      // if there is no empty field in column -> generate one
      if (!this.existsEmptyFieldInColumn(columnIndex)) {
        this.handleAddSearchfield(columnIndex);
      }
      // if there is no unfilled column as right neighbor -> add one
      if (!this.existsEmptyColumn()) {
        this.handleAddColumn();
      }
    }
    this.updateLastFocusedField(searchFieldIndex, columnIndex);
    const searchQuery = this.props.searchQuery;
    const searchTerms = {
      query: []
    };
    searchQuery.forEach((column, columnCount) => {
      if (searchQuery[columnCount].column[0].length !== 0) {
        const tempArray = searchQuery[columnCount].column.slice(0);
        tempArray.splice(-1, 1);
        searchTerms.query.push(tempArray);
      }
    });
    if (!this.isSearchArrayIdentical(this.lastSearchTerms, searchTerms) && !this.hasErrorsInFields() && searchTerms.query.length > 1) {
      this.getAmountOfSearchResults(searchTerms);
      this.lastSearchTerms = $.extend(true, {}, searchTerms);
    }
  }

  getIntelligentSearchSuggestions(searchTerms) {
    if (this.state.lastAjaxCall) {
      this.state.lastAjaxCall.abort();
    }
    this.props.liftUpStateToApp('intelligentSearchSuggestions', []);
    this.setState({
      ivaSuggestionsAreLoading: true
    });
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = constants.getIntelligentSearchSuggestionsURL;
    const lastAjaxCall = $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(searchTerms),
      timeout: CONFIG.ajaxTimeout
    })
      .done(response => {
        this.props.liftUpStateToApp('intelligentSearchSuggestions', response);
        this.setState({
          ivaSuggestionsAreLoading: false
        });
      })

      .fail(err => {
        if (err && err.statusText !== 'abort') this.errorHandling(err, url);
      });
    this.setState({ lastAjaxCall });
  }

  getAmountOfSearchResults = debounce(searchTerms => {
    if (this.props.isIvaEnabled) this.getIntelligentSearchSuggestions(searchTerms);
    this.getAmountOfSearchResultsDebounce(searchTerms);
  }, 100);

  getAmountOfSearchResultsDebounce(searchTerms) {
    if ($.isEmptyObject(searchTerms)) {
      return;
    }
    this.setState({
      showLoadingIndicatorResultCount: true
    });
    const requestQuery = searchTerms;

    const maximumDistance = 240;
    const minimumDistance = 0;
    if (
      this.props.distance <= maximumDistance &&
      this.props.distance !== minimumDistance &&
      this.props.geoLocation &&
      this.props.geoLocation.location &&
      this.props.geoLocation.location.lat &&
      this.props.geoLocation.location.lon
    ) {
      requestQuery.distance = this.props.distance;
      requestQuery.lat = this.props.geoLocation.location.lat;
      requestQuery.lon = this.props.geoLocation.location.lon;
    }
    // Reset the resultCount
    this.props.liftUpStateToApp({ resultCount: 0 });

    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = constants.amountOfResultsURL;
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestQuery),
      timeout: CONFIG.ajaxTimeout
    })
      .done(response => {
        this.props.liftUpStateToApp('suggestions', response.frequentCommonSearchTerms);
        this.setState({
          showLoadingIndicatorResultCount: false
        });

        // lift up state
        const isShowResultsButtonDisabled = response.resultCount > CONFIG.maxResults || response.resultCount === 0;
        const updateStateObject = {
          isShowResultsButtonDisabled,
          resultCount: response.resultCount
        };

        this.props.liftUpStateToApp(updateStateObject);
      })

      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  handleAddSearchfield(columnIndex) {
    const searchQuery = this.props.searchQuery;
    searchQuery[columnIndex].column.push({
      searchTerm: '',
      isSemantic: true,
      ID: new Date().getTime() + 1,
      hasError: false
    });
    this.onSearchChange(searchQuery, searchQuery[columnIndex].column.length, columnIndex);
  }

  handleRemoveSearchfield(searchFieldIndex, columnIndex) {
    const searchQuery = this.props.searchQuery;

    if (
      columnIndex !== 0 &&
      this.existsEmptyFieldInColumn(columnIndex) &&
      searchQuery[columnIndex].column.length === 2 &&
      this.existsEmptyColumn()
    )
      searchQuery.splice(columnIndex, 1);
    else searchQuery[columnIndex].column.splice(searchFieldIndex, 1);
    this.onSearchChange(searchQuery, searchFieldIndex, columnIndex);
  }

  /**
   * Update searchTerm in corresponding field in state of search.js
   * @param  {[type]} value            fieldvalue
   * @param  {[type]} searchFieldIndex
   * @param  {[type]} columnIndex
   *   */
  handleUpdateSearchterm(value, searchFieldIndex, columnIndex) {
    const searchQuery = this.props.searchQuery;
    searchQuery[columnIndex].column[searchFieldIndex].searchTerm = value;
    searchQuery[columnIndex].column[searchFieldIndex].hasError = !UTILS.isSearchtermValid(value);
    this.onSearchChange(searchQuery, searchFieldIndex, columnIndex);
  }

  handleAddColumn() {
    const searchQuery = this.props.searchQuery;
    searchQuery.push({
      column: [
        {
          searchTerm: '',
          isSemantic: true,
          ID: new Date().getTime() + 2,
          hasError: false
        }
      ],
      ID: new Date().getTime() + 3
    });
    this.onSearchChange(searchQuery, 0, searchQuery.length - 1);
  }

  existsEmptyColumn() {
    // BUG: should check all columns
    let existsEmptyColumn = false;

    const searchQuery = this.props.searchQuery;
    searchQuery.forEach(column => {
      if (column.column.length === 1) {
        existsEmptyColumn = true;
      }
    });
    return existsEmptyColumn;
  }

  existsEmptyFieldInColumn(columnIndex) {
    let existsEmptyFieldInColumn = false;
    const searchQuery = this.props.searchQuery[columnIndex];
    searchQuery.column.forEach(searchField => {
      if (searchField.searchTerm === '') {
        existsEmptyFieldInColumn = true;
      }
    });
    return existsEmptyFieldInColumn;
  }

  isFieldLastInColumn(searchFieldIndex, columnIndex) {
    const searchQuery = this.props.searchQuery;
    let isFieldLastInColumn = false;
    if (searchQuery[columnIndex].column.length - 1 === searchFieldIndex) {
      isFieldLastInColumn = true;
    }

    return isFieldLastInColumn;
  }

  isSearchArrayIdentical(oldArray, newArray) {
    return JSON.stringify(oldArray) === JSON.stringify(newArray);
  }

  hasErrorsInFields() {
    let hasErrorsInFields = false;
    this.props.searchQuery.forEach(column => {
      column.column.forEach(searchField => {
        if (searchField.hasError) {
          hasErrorsInFields = true;
        }
      });
    });
    return hasErrorsInFields;
  }

  showSendBox = () => {
    this.setState({ showSendBox: true });
  };

  hideSendbox = () => {
    this.setState({ showSendBox: false });
  };

  showResultButtonHandler = ratings => {
    this.props.showResultButtonHandler(
      this.props.searchQuery,
      ratings,
      this.props.geoLocation.postalCode,
      this.props.distance,
      this.props.geoLocation.location.lat,
      this.props.geoLocation.location.lon
    );
  };

  updateSearchField(value, searchFieldIndex, columnIndex) {
    const searchQuery = this.props.searchQuery;
    searchQuery[columnIndex].column[searchFieldIndex].searchTerm = value;
    this.props.liftUpStateToApp('searchQuery', searchQuery);
    this.onSearchFieldBlur(value, searchFieldIndex, columnIndex);
  }

  updateLastFocusedField(searchFieldIndex, columnIndex) {
    this.setState({ lastFocusedField: { searchFieldIndex, columnIndex } });
  }

  liftUpStateToSearch = (key, value) => {
    this.setState({ [key]: value });
  };

  renderHelpBox = () => {
    let instructionText = ' ';
    const amountOfSearchFields = this.props.searchQuery ? this.props.searchQuery.length : 1;
    const suggestionsAvailable = this.props.suggestions && this.props.suggestions.length > 0;
    if (amountOfSearchFields === 1) instructionText = TEXT.searchPage.helpBox.onFirstSearchField;
    else if (amountOfSearchFields > 1 && !suggestionsAvailable)
      instructionText = TEXT.searchPage.helpBox.onOtherSearchFieldsWithoutSuggestion;
    else if (amountOfSearchFields > 1 && suggestionsAvailable) instructionText = TEXT.searchPage.helpBox.onOtherSearchFieldsWithSuggestions;
    return (
      <div className="suggestionArea">
        <div className="helpBoxContainer">
          <HelpBox
            onHelpBoxLinkClick={this.onHelpBoxLinkClick}
            frequentCommonSearchTerms={this.props.suggestions}
            instructionText={instructionText}
            isIvaEnabled={this.props.isIvaEnabled}
            intelligentSearchSuggestions={this.props.intelligentSearchSuggestions}
            ivaSuggestionsAreLoading={this.state.ivaSuggestionsAreLoading}
            toggleHoverOn={this.toggleHoverOn}
            toggleHoverOff={this.toggleHoverOff}
          />
        </div>
      </div>
    );
  };

  countryNameMap = country => {
    switch (country) {
      case 'germany':
        return 'Deutschland';
      case 'switzerland':
        return 'Schweiz';
      case 'austria':
        return 'Österreich';
      default:
        return country;
    }
  };

  triggerSearchOnChange = () => {
    const searchQuery = this.props.searchQuery;
    const searchTerms = {
      query: []
    };
    searchQuery.forEach((column, columnCount) => {
      if (searchQuery[columnCount].column[0].length !== 0) {
        const tempArray = searchQuery[columnCount].column.slice(0);
        tempArray.splice(-1, 1);
        searchTerms.query.push(tempArray);
      }
    });
    this.getAmountOfSearchResults(searchTerms);
  };

  geoLocationSelection = value => {
    this.setState({
      geoLocationError: false,
      geoLocationErrorText: ''
    });
    this.props.liftUpStateToApp({
      geoLocation: value,
      geoLocationSearchValue: `${value.postalCode} -- ${value.city} -- ${this.countryNameMap(value.country)}`
    });
    if (this.props.searchQuery.length > 1) {
      this.triggerSearchOnChange();
    }
  };

  onDistanceSliderChange = distance => {
    this.props.liftUpStateToApp({ distance });
    if (this.props.searchQuery.length > 1 && this.props.geoLocation && this.props.geoLocation.location) {
      this.triggerSearchOnChange();
    }
  };

  geoLocationOnBlur = () => {
    if (!this.props.geoLocation && this.props.geoLocationSearchValue.length > 1) {
      this.setState({
        geoLocationError: true,
        geoLocationErrorText: 'Keine gültige Eingabe'
      });
    }
  };

  geoLocationOnFocus = () => {
    this.props.liftUpStateToApp({
      geoLocation: undefined,
      geoLocationSearchValue: ''
    });
  };

  render() {
    return (
      <div className="searchPage">
        <div className="container-fluid pagecount-container">
          <div className="row">
            <div className="col-md-3 my-auto pl-0 pr-0 ">
              <Paper className="m-3">
                <div className="col-md-12 pb-3 pt-3">
                  <PostalCodeAutosuggest
                    onSearchFieldChange={value => {
                      this.setState({
                        geoLocationError: false,
                        geoLocationErrorText: ''
                      });
                      this.props.liftUpStateToApp({
                        geoLocationSearchValue: value,
                        geoLocation: undefined
                      });
                    }}
                    onSearchFieldBlur={this.geoLocationOnBlur}
                    onSearchFieldFocus={this.geoLocationOnFocus}
                    hasError={this.state.geoLocationError}
                    errorText={this.state.geoLocationErrorText}
                    onSelection={this.geoLocationSelection}
                    placeholder="Postleitzahl oder Ort eingeben*"
                    value={this.props.geoLocationSearchValue}
                  />
                  <DistanceSlider
                    liftUpStateToApp={this.props.liftUpStateToApp}
                    onSliderChange={this.onDistanceSliderChange}
                    distance={this.props.distance}
                  />
                </div>
              </Paper>
            </div>
            <div className="col-md-6  result-count-area">
              <SearchResultCountBox count={this.props.resultCount} showLoadingIndicator={this.state.showLoadingIndicatorResultCount} />
            </div>

            <div className="col-md-3 my-auto text-center pb-md-0 pb-3">
              <Tooltip title={!this.props.geoLocation ? 'Bitte befüllen Sie das Feld Postleitzahl ' : ''}>
                <span>
                  <Button
                    onClick={this.showSendBox}
                    disabled={
                      this.props.isShowResultsButtonDisabled || !this.props.geoLocation || !Object.keys(this.props.geoLocation).length
                    }
                    variant="contained"
                    color="primary"
                    className="sendButton"
                  >
                    {TEXT.searchPage.searchResultCountBox.button}
                    <i className="material-icons">forward</i>
                  </Button>
                </span>
              </Tooltip>
            </div>
          </div>
        </div>

        <SendSearchBox
          open={this.state.showSendBox}
          showResultButtonHandler={this.showResultButtonHandler}
          handleClose={this.hideSendbox}
          postalCode={this.props.postalCode}
          liftUpStateToApp={this.props.liftUpStateToApp}
        />
        <div className="container-fluid maincontainer" key="maincontainer">
          <div className="row" key="mainrow">
            <div className="col-md-9 ">
              <div className="row">
                <AllSearchFields
                  onSearchFieldBlur={this.onSearchFieldBlur}
                  searchQuery={this.props.searchQuery}
                  onSearchChange={this.onSearchChange}
                  onSearchFieldFocus={this.onSearchFieldFocus}
                  // helpBoxDOM={this.renderHelpBox()}
                  currentFocusedField={this.state.currentFocusedField}
                  isCurrentFocusedFieldHovered={this.state.hoveringOverIvaPill}
                />
              </div>
            </div>
            <div className="col-md-3 ">{this.renderHelpBox()}</div>
          </div>
        </div>
      </div>
    );
  }
}
Search.propTypes = {
  geoLocation: PropTypes.object.isRequired,
  showResultButtonHandler: PropTypes.func.isRequired,
  liftUpStateToApp: PropTypes.func.isRequired,
  searchQuery: PropTypes.array.isRequired,
  postalCode: PropTypes.string.isRequired,
  distance: PropTypes.number.isRequired,
  resultCount: PropTypes.number.isRequired,
  isShowResultsButtonDisabled: PropTypes.bool.isRequired,
  suggestions: PropTypes.array.isRequired,
  intelligentSearchSuggestions: PropTypes.array.isRequired,
  isIvaEnabled: PropTypes.bool.isRequired,
  geoLocationSearchValue: PropTypes.string.isRequired
};
export default Search;
