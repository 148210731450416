import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// import IconButton from '@material-ui/core/IconButton';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
// import EditIcon from '@material-ui/icons/Edit';

import SafeMuiDatatable from '../generic_components/SafeMuiDatatable';
import * as UTILS from '../utils/utilFunctions';

// prepare column options according to save viewColumn settings
function prepareColumns(columns) {
  const mappedColumns = columns;
  const projectStatusColumnSettings = [];
  columns.forEach(column => {
    if (column.options) {
      if (column.options.display === 'true' || column.options.display === true) {
        projectStatusColumnSettings.push(column.name);
      }
    } else {
      projectStatusColumnSettings.push(column.name);
    }
  });

  return mappedColumns;
}

class CandidatePositions extends PureComponent {
  constructor(props) {
    super(props);
    console.log('data', this.props.data.jobs);
    this.state = { columns: prepareColumns(this.props.columns) };

    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  generateName(firstName, lastName) {
    let name = '';

    if (firstName) name = `${name + firstName} `;
    if (lastName) name = `${name + lastName} `;
    return name;
  }

  mapCandidateJobBranches(job) {
    let result = '';
    if (job.branches && job.branches.length > 0 && job.branches[0] !== '') {
      result = job.branches.map((element, index) => ` ${element}${index === job.branches.length - 1 ? '.' : ', '}`);
    }
    return result;
  }

  mapCandidateJobFields(job) {
    let result = '';
    if (job.fields.length > 0 && job.fields[0] !== '') {
      result = job.fields.map((element, index) => ` ${element}${index === job.fields.length - 1 ? '.' : ', '}`);
    }
    return result;
  }

  prepareDataForTable(data) {
    const formatedData = [];
    if (data)
      data.forEach(entry => {
        // map candidate
        // const currentCompany = entry.candidate
        //   ? entry.candidate.currentCompany
        //   : '';
        // const currentJob = entry.candidate ? entry.candidate.currentJob : '';
        // const phoneNumber = entry.candidate ? entry.candidate.phoneNumber : '';
        // const phoneNumberWork = entry.candidate
        //   ? entry.candidate.phoneNumberWork
        //   : '';
        // const phoneNumberMobile = entry.candidate
        //   ? entry.candidate.phoneNumberMobile
        //   : '';
        // const mailAddress = entry.candidate ? entry.candidate.mailAddress : '';

        // const candidateId = entry.candidate ? entry.candidate.id : null;

        const positionData = {
          jobId: entry.id,
          fromDate: entry.fromDate,
          tillDate: entry.tillDate,
          company: entry.company,
          jobText: entry.jobText,
          branches: this.mapCandidateJobBranches(entry),
          description: entry.description
        };
        const dataArray = [...Object.values(positionData)];
        positionData.dateFormatForLists = entry.dateFormatForLists;

        formatedData.push([...dataArray, this.renderEditButton(positionData)]);
      });

    return formatedData;
  }

  getOptions() {
    return {
      filterType: 'checkbox',
      responsive: 'scrollMaxHeight',
      selectableRows: 'none',
      fixedHeader: true,
      filter: false,
      search: true,
      print: true,
      download: true,
      viewColumns: true,
      rowsPerPageOptions: [3, 10, 15, 100],
      downloadOptions: { filename: 'tableDownload.csv', separator: ';' },
      textLabels: {
        body: {
          noMatch: 'Keine passenden Einträge gefunden',
          toolTip: 'sortieren'
        },
        pagination: {
          next: 'Nächste Seite',
          previous: 'Vorherige Seite',
          rowsPerPage: 'Zeilen pro Seite:',
          displayRows: 'von'
        },
        toolbar: {
          search: 'Suche',
          downloadCsv: 'CSV downloaden',
          print: 'Drucken',
          viewColumns: 'Spalten anzeigen',
          filterTable: 'Tabelle filtern'
        },
        filter: {
          all: 'Alle',
          title: 'FILTER',
          reset: 'RESET'
        },
        viewColumns: {
          title: 'Spalten anzeigen',
          titleAria: 'Spalten anzeigen'
        },
        selectedRows: {
          text: 'rows(s) selected',
          delete: 'Delete',
          deleteAria: 'Delete Selected Rows'
        }
      }
    };
  }
  // TODO: remove if not needed
  // updateProjectDetailsAPICall = (candidateId, rating, oldRating) => {
  //   if (rating !== oldRating) {
  //     const tokenFromLocalStorage = window.sessionStorage.getItem('token');
  //     const url = CONSTANTS.updatePotentialCandidateURL;
  //
  //     const payload = {
  //       projectId: this.props.selectedProjectId,
  //       potentialCandidateId: candidateId,
  //       updatedData: {
  //         rating
  //       }
  //     };
  //
  //     console.log(`API ${url} request`, payload);
  //     $.ajax({
  //       url,
  //       method: 'POST',
  //       dataType: 'json',
  //       contentType: 'application/json; charset=utf-8',
  //       headers: { 'x-auth': tokenFromLocalStorage },
  //       data: JSON.stringify(payload),
  //       timeout: CONFIG.ajaxTimeout
  //     })
  //       .done((responseBody, status) => {
  //         console.log(`API /${url} response`, responseBody, status);
  //         this.props.handleSnackbarOpen(
  //           TEXT_GENERAL.snackBarTexts.updateSuccess
  //         );
  //         this.props.refreshProject();
  //       })
  //       .fail(err => {
  //         this.errorHandling(err, url);
  //       });
  //   }
  // };

  renderEditButton(entry) {
    return (
      <div className="candidateTableAction">
        <IconButton
          className=""
          aria-label="Edit"
          color="primary"
          onClick={() =>
            // TODO change entry id use
            this.props.handleOpenCandidateJobUpdatePopUp(entry)
          }
        >
          <EditIcon />
        </IconButton>
        <IconButton className="" aria-label="Delete" color="primary" onClick={() => this.props.handleDeleteEntry('jobs', entry.jobId)}>
          <DeleteIcon />
        </IconButton>
      </div>
    );
  }

  render() {
    return (
      <div className="container-fluid mb-3">
        <div className="CandidatePositions row">
          <div className="col-12">
            <SafeMuiDatatable
              title="Berufserfahrung (Positionen)"
              tableName="candidatePositions"
              data={this.prepareDataForTable(this.props.data.jobs)}
              columns={this.state.columns}
              options={this.getOptions()}
            />
          </div>
        </div>
      </div>
    );
  }
}

CandidatePositions.defaultProps = {
  columns: [
    {
      name: 'ID',
      options: {
        display: 'excluded',
        filter: false
      }
    },
    {
      name: 'Von',
      options: {
        customBodyRender: value => UTILS.formatStringToDELocale(value),
        display: true,
        filter: false
      }
    },
    {
      name: 'Bis',
      options: {
        customBodyRender: value => UTILS.formatStringToDELocale(value),
        display: true,
        filter: false
      }
    },
    {
      name: 'Firma',
      options: {
        display: 'true',
        filter: false
      }
    },
    {
      name: 'Titel',
      options: {
        display: 'true',
        filter: false
      }
    },
    {
      name: 'Branchen',
      options: {
        display: 'true',
        filter: false
      }
    },
    {
      name: 'Kommentar',
      options: {
        display: 'true',
        filter: false
      }
    },

    {
      name: 'Aktionen',
      options: {
        display: 'true',
        filter: false
      }
    }
  ]
};

CandidatePositions.propTypes = {
  data: PropTypes.object.isRequired,

  columns: PropTypes.array,
  handleOpenCandidateJobUpdatePopUp: PropTypes.func.isRequired,
  handleDeleteEntry: PropTypes.func.isRequired
};
export default CandidatePositions;
