import { Box, Button, Grid, IconButton, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Cancel from '@material-ui/icons/Cancel';
import { array, func } from 'prop-types';
import React, { useCallback, useState } from 'react';
import ValidatedDropDown from '../../generic_components/ValidatedDropDown';
import { useValidatedTextField } from '../../generic_components/ValidatedTextField';
import TEXT from '../../text';

const COUNTRY_SELECT = ['Deutschland', 'Österreich', 'Schweiz'];

/**
 *
 * @typedef JobLocation
 * @property {string} postalCode
 * @property {string} city
 * @property {string} county
 */

export const JobLocations = ({ handleSnackbarOpen, locations, setLocations }) => {
  const [country, setCountry] = useState('');

  const handleChangeCountry = useCallback(event => {
    setCountry(event.target.value);
  }, []);

  const [postalCodeTextfieldComponent, validatedPostalCode, postalCode, setPostalCode] = useValidatedTextField({
    fullWidth: true,
    autoFocus: false,
    id: 'city',
    label: TEXT.jobPosting.labels.postalCode,
    required: true,
    value: ''
  });

  const [cityTextfieldComponent, validatedCity, city, setCity] = useValidatedTextField({
    fullWidth: true,
    autoFocus: false,
    id: 'city',
    label: TEXT.jobPosting.labels.location,
    required: true,
    value: ''
  });

  const handleDeleteLocation = useCallback(
    index => {
      const updatedLocations = Array.from(locations);
      updatedLocations.splice(index, 1);

      setLocations(updatedLocations);
    },
    [locations, setLocations]
  );

  const resetForm = useCallback(() => {
    setCity('');
    setPostalCode('');
    setCountry('');
  }, [setCity, setPostalCode]);

  const validateField = useCallback(() => validatedPostalCode() && validatedCity() && !!country, [
    validatedCity,
    country,
    validatedPostalCode
  ]);

  const handleAddLocation = useCallback(() => {
    if (!validateField()) {
      handleSnackbarOpen(TEXT.jobPosting.errors.allFieldsRequired);
      return;
    }

    const newLocations = [...locations];
    newLocations.push({ postalCode, city, country });
    setLocations(newLocations);
    resetForm();
  }, [city, country, postalCode, resetForm, handleSnackbarOpen, validateField, locations, setLocations]);

  return (
    <Box>
      <Typography>Arbeitsorte: </Typography>
      {!!locations.length && (
        <Box style={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
          {locations.map((locationEle, index) => (
            <Box
              className="p-2"
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              style={{ borderColor: 'darkgray', borderStyle: 'solid', borderRadius: '10px', borderWidth: '1px' }}
            >
              <IconButton onClick={handleDeleteLocation} size="small">
                <Cancel />
              </IconButton>
              <Typography>{locationEle.city}</Typography>
              <Typography>{locationEle.postalCode}</Typography>
              <Typography>{locationEle.country}</Typography>
            </Box>
          ))}
        </Box>
      )}
      <Box style={{ width: '100%' }}>
        <Grid container spacing={3} style={{ marginTop: '5px' }}>
          <Grid item md={6}>
            <ValidatedDropDown
              required
              content={COUNTRY_SELECT}
              value={country || ''}
              onChange={handleChangeCountry}
              label={TEXT.jobPosting.labels.country}
              id="country_mul"
              style={{ width: '100%' }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={6}>
            {cityTextfieldComponent}
          </Grid>
          <Grid item md={6}>
            {postalCodeTextfieldComponent}
          </Grid>
        </Grid>
      </Box>
      <Box className="mt-3">
        <Button onClick={handleAddLocation} startIcon={<Add />} variant="contained">
          Hinzufügen
        </Button>
      </Box>
    </Box>
  );
};

JobLocations.propTypes = {
  locations: array.isRequired,
  setLocations: func.isRequired,
  handleSnackbarOpen: func.isRequired
};

export default JobLocations;
