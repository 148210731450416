const staticContent = [
  'deutsch',
  'österreichisch',
  'schweizerisch',
  'afghanisch',
  'ägyptisch',
  'albanisch',
  'algerisch',
  'andorranisch',
  'angolanisch',
  'antiguanisch',
  'äquatorialguineisch',
  'argentinisch',
  'armenisch',
  'aserbaidschanisch',
  'äthiopisch',
  'australisch',
  'bahamaisch',
  'bahrainisch',
  'bangladeschisch',
  'barbadisch',
  'belgisch',
  'belizisch',
  'beninisch',
  'bhutanisch',
  'bolivianisch',
  'bosnisch-herzegowinisch',
  'botsuanisch',
  'brasilianisch',
  'bruneiisch',
  'bulgarisch',
  'burkinisch',
  'burundisch',
  'chilenisch',
  'chinesisch',
  'costa-ricanisch',
  'ivorisch',
  'dänisch',
  'dominicanisch',
  'dominikanisch',
  'dschibutisch',
  'ecuadorianisch',
  'salvadorianisch',
  'eritreisch',
  'estnisch',
  'fidschianisch',
  'finnisch',
  'französisch',
  'gabunisch',
  'gambisch',
  'georgisch',
  'ghanaisch',
  'grenadisch',
  'griechisch',
  'guatemaltekisch',
  'guineisch',
  'guinea-bissauisch',
  'guyanisch',
  'haitianisch',
  'honduranisch',
  'indisch',
  'indonesisch',
  'irakisch',
  'iranisch',
  'irisch',
  'isländisch',
  'israelisch',
  'italienisch',
  'jamaikanisch',
  'japanisch',
  'jemenitisch',
  'jordanisch',
  'kambodschanisch',
  'kamerunisch',
  'kanadisch',
  'kap-verdisch',
  'kasachisch',
  'katarisch',
  'kenianisch',
  'kirgisisch',
  'kiribatisch',
  'kolumbianisch',
  'komorisch',
  'kongolesisch',
  'der Demokratischen Republik Kongo',
  'der Demokratischen Volksrepublik Korea',
  'der Republik Korea',
  'kosovarisch',
  'kroatisch',
  'kubanisch',
  'kuwaitisch',
  'laotisch',
  'lesothisch',
  'lettisch',
  'libanesisch',
  'liberianisch',
  'libysch',
  'liechtensteinisch',
  'litauisch',
  'luxemburgisch',
  'madagassisch',
  'malawisch',
  'malaysisch',
  'maledivisch',
  'malisch',
  'maltesisch',
  'marokkanisch',
  'marshallisch',
  'mauretanisch',
  'mauritisch',
  'mazedonisch',
  'mexikanisch',
  'mikronesisch',
  'moldauisch',
  'monegassisch',
  'mongolisch',
  'montenegrinisch',
  'mosambikanisch',
  'myanmarisch',
  'namibisch',
  'nauruisch',
  'nepalesisch',
  'neuseeländisch',
  'nicaraguanisch',
  'niederländisch',
  'nigrisch',
  'nigerianisch',
  'norwegisch',
  'omanisch',
  'pakistanisch',
  'palauisch',
  'panamaisch',
  'papua-neuguineisch',
  'paraguayisch',
  'peruanisch',
  'philippinisch',
  'polnisch',
  'portugiesisch',
  'ruandisch',
  'rumänisch',
  'russisch',
  'salomonisch',
  'sambisch',
  'samoanisch',
  'san-marinesisch',
  'são-toméisch',
  'saudi-arabisch',
  'schwedisch',
  'senegalesisch',
  'serbisch',
  'seychellisch',
  'sierra-leonisch',
  'simbabwisch',
  'singapurisch',
  'slowakisch',
  'slowenisch',
  'somalisch',
  'spanisch',
  'sri-lankisch',
  'von St. Kitts und Nevis',
  'lucianisch',
  'vincentisch',
  'südafrikanisch',
  'sudanesisch',
  'südsudanesisch',
  'surinamisch',
  'swasiländisch',
  'syrisch',
  'tadschikisch',
  'tansanisch',
  'thailändisch',
  'von Timor-Leste',
  'togoisch',
  'tongaisch',
  'von Trinidad und Tobago',
  'tschadisch',
  'tschechisch',
  'tunesisch',
  'türkisch',
  'turkmenisch',
  'tuvaluisch',
  'ugandisch',
  'ukrainisch',
  'ungarisch',
  'uruguayisch',
  'usbekisch',
  'vanuatuisch',
  'vatikanisch',
  'venezolanisch',
  'der Vereinigten Arabischen Emirate',
  'amerikanisch',
  'britisch',
  'vietnamesisch',
  'weißrussisch',
  'zentralafrikanisch',
  'zyprisch'
];

module.exports = staticContent;
