import React from 'react';
import PropTypes from 'prop-types';

import { getProjectById } from './api/candidateArchiveAPI';
import PotentialCandidateUpdatePopUpContainer from '../projectArchive/PotentialCandidateUpdatePopUpContainer';
import * as UTILS from '../utils/utilFunctions';

function mapEntryToPotentialCandidate(entry) {
  const result = {
    potentialCandidateId: entry.id,
    candidateId: entry.candidate && entry.candidate.id,
    candidateFirstName: entry.candidate ? entry.candidate.firstName : '',
    candidateLastName: entry.candidate ? entry.candidate.lastName : '',
    candidateCompany: entry.candidate ? entry.candidate.currentCompany : '',
    candidateJob: entry.candidate ? entry.candidate.currentJob : '',
    candidatePhoneNumber: entry.candidate ? entry.candidate.phoneNumber : '',
    candidatePhoneNumberWork: entry.candidate ? entry.candidate.phoneNumberWork : '',
    candidatePhoneNumberMobile: entry.candidate ? entry.candidate.phoneNumberMobile : '',
    candidateMailAddress: entry.candidate ? entry.candidate.mailAddress : '',
    source: entry.source,
    sourceText: entry.candidate.sourceText,
    department: entry.department,
    contactTrackingType: entry.contactTrackingType,
    contactDate: entry.contactDate,
    CVReceivedDate: entry.CVReceivedDate,
    initialInterviewDate: entry.initialInterviewDate,
    presentationDate: entry.presentationDate,
    status: entry.status,
    rejectionReason: entry.rejectionReason,
    remark: entry.remark,
    comment: entry.comment,
    firstInterviewDate: entry.firstInterviewDate,
    applicationSendDate: entry.applicationSendDate,
    reminderCompanyDate1: entry.reminderCompanyDate1,
    secondInterviewDate: entry.secondInterviewDate,
    reminderCandidateDate: entry.reminderCandidateDate,
    isReferred: UTILS.mapIsReferred(entry.isReferred),
    signedDate: entry.signedDate,
    rating: entry.rating
  };
  return result;
}

export default function ProjectCandidateDataEdit({ open, projectId, candidateId, onClose, handleSnackbarOpen, refreshCandidate }) {
  const [potentialCandidateData, setPotentialCandidateData] = React.useState();

  React.useEffect(() => {
    if (projectId) {
      getProjectById(projectId)
        .then(responseBody => {
          const projectCandidateData = responseBody.project.potentialCandidates.find(entry => entry.candidateId === candidateId);
          console.log('yyyy', projectCandidateData);
          setPotentialCandidateData(projectCandidateData);
        })
        .catch(error => console.error('Error in getProjectById:', error));
    }
  }, [projectId, open]);

  return (
    <>
      {open && potentialCandidateData && projectId && (
        <PotentialCandidateUpdatePopUpContainer
          open={open}
          projectId={projectId}
          potentialCandidateId={potentialCandidateData.id}
          data={mapEntryToPotentialCandidate(potentialCandidateData)}
          isUpdate
          handleClose={() => {
            setPotentialCandidateData(undefined);
            onClose();
          }}
          handleSnackbarOpen={handleSnackbarOpen}
          refreshProject={refreshCandidate}
        />
      )}
    </>
  );
}

ProjectCandidateDataEdit.defaultProps = {};

ProjectCandidateDataEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  projectId: PropTypes.string.isRequired,
  candidateId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  refreshCandidate: PropTypes.func.isRequired
};
