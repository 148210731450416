import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Box, Typography, CircularProgress, Divider, IconButton, Tooltip } from '@material-ui/core';
import { Check as CheckIcon, DoneAll as DoneAllIcon } from '@material-ui/icons';
import {
  getProjectCandidateDossierList,
  getProjectCandidateDossier,
  deleteProjectCandidateDossier,
  setProjectCandidateDossierVisibleToCustomer
} from './api/candidateArchiveAPI';
import FileList from '../generic_components/file_util_components/FileList';
import { downloadBlobFile } from '../utils/utilFunctions';
import ConfirmationModal from '../generic_components/ConfirmationModal';

const customMargin = 30;
const style = { width: '100%', maxHeight: '1000px', overflow: 'auto' };

export default function CandidateProjectDossierList({ candidateId }) {
  const [dossierList, setDossierList] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
  const [releaseConfirmationOpen, setReleaseConfirmationOpen] = React.useState(false);
  const [fileNameToDelete, setFileNameToDelete] = React.useState();
  const [fileNameToRelease, setFileNameToRelease] = React.useState();

  async function getDossierList() {
    if (candidateId) {
      const apiResponse = await getProjectCandidateDossierList(candidateId);
      setDossierList(apiResponse.projectDossierList);
      setLoading(false);
    }
  }

  React.useEffect(() => {
    getDossierList();
  }, [candidateId]);

  async function onDelete(fileName) {
    setFileNameToDelete(fileName);
    setDeleteConfirmationOpen(true);
  }

  async function getFileUrl(fileName) {
    const apiResponse = await getProjectCandidateDossier(candidateId, fileName);
    return apiResponse.contentUrl;
  }

  async function onDownload(fileName) {
    const apiResponse = await getProjectCandidateDossier(candidateId, fileName);
    downloadBlobFile(apiResponse.contentUrl);
  }

  function renderExtendedItemActions(file) {
    return (
      <Tooltip title="Dossier Freigeben" placement="top">
        <IconButton
          edge="end"
          onClick={() => {
            setFileNameToRelease(file);
            setReleaseConfirmationOpen(true);
          }}
        >
          <CheckIcon color="primary" />
        </IconButton>
      </Tooltip>
    );
  }

  function renderExtendedItemActionsForReleasedDossiers(fileName, file) {
    return (
      file.clientViewDate && (
        <IconButton disabled edge="end" style={{ color: 'rgba(0, 0, 0, 0.54)' }}>
          <DoneAllIcon />
        </IconButton>
      )
    );
  }

  function renderFilteredFileListWithHeading(heading, comparator, extendedItemActions) {
    const filteredDossierList = dossierList.filter(comparator);
    return (
      <Box pb={3}>
        <Typography variant="h6" component="h6">
          {heading}
        </Typography>
        {filteredDossierList.length > 0 ? (
          <FileList
            fileNameList={filteredDossierList.map(entry => ({ name: entry.fileName, clientViewDate: entry.clientViewDate }))}
            onDelete={onDelete}
            onResolveFileUrl={getFileUrl}
            onDownload={onDownload}
            extendedItemActions={extendedItemActions}
          />
        ) : (
          <Typography variant="p" component="div">
            Keine Dossiers vorhanden
          </Typography>
        )}
      </Box>
    );
  }

  function renderFileList() {
    return dossierList && dossierList.length > 0 ? (
      <>
        {renderFilteredFileListWithHeading('Nicht freigegebene Dossiers', entry => !entry.visibleToCustomer, renderExtendedItemActions)}
        <Box pb={2}>
          <Divider />
        </Box>
        {renderFilteredFileListWithHeading(
          'Freigegebene Dossiers',
          entry => entry.visibleToCustomer,
          renderExtendedItemActionsForReleasedDossiers
        )}
      </>
    ) : (
      <Typography variant="h5" component="h5" align="center">
        Keine Dossiers vorhanden
      </Typography>
    );
  }

  function renderDeleteConfirmation() {
    return (
      <ConfirmationModal
        handleClose={() => setDeleteConfirmationOpen(false)}
        open={deleteConfirmationOpen}
        buttonPrimaryAction={async () => {
          setDeleteConfirmationOpen(false);
          setLoading(true);
          await deleteProjectCandidateDossier(candidateId, fileNameToDelete);
          await getDossierList();
          setLoading(false);
        }}
        headlineText="Dossier löschen"
        descriptionText="Wollen Sie den Dossier löschen ?"
        buttonPrimaryText="Dossier löschen"
      />
    );
  }

  function renderDossierVisibleToCustomerConfirmation() {
    return (
      <ConfirmationModal
        handleClose={() => setReleaseConfirmationOpen(false)}
        open={releaseConfirmationOpen}
        buttonPrimaryAction={async () => {
          setReleaseConfirmationOpen(false);
          setLoading(true);
          const apiResponse = await setProjectCandidateDossierVisibleToCustomer(candidateId, fileNameToRelease);
          setDossierList(apiResponse.projectDossierList);
          setLoading(false);
        }}
        headlineText="Dossier freigeben"
        descriptionText="Wollen Sie den Dossier freigeben ?"
        buttonPrimaryText="Dossier freigeben"
      />
    );
  }

  return (
    <>
      <Paper style={style}>
        <Box p={3}>
          <Box ml={customMargin} mr={customMargin}>
            {loading ? (
              <Typography component="div" align="center">
                <CircularProgress />
              </Typography>
            ) : (
              renderFileList()
            )}
          </Box>
        </Box>
      </Paper>
      {renderDeleteConfirmation()}
      {renderDossierVisibleToCustomerConfirmation()}
    </>
  );
}

CandidateProjectDossierList.propTypes = {
  candidateId: PropTypes.string.isRequired
};
