import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Paper } from '@material-ui/core';
import LinkList from '../generic_components/LinkList/LinkList';
import GenericFormPopUp from '../generic_components/GenericFormPopUp';
import { removeCandidateLinks, updateCandidateLinks } from './api/candidateArchiveAPI';

/**
 * @typedef Props
 * @property {{url: string, name: string, id: string, partner: string}[]} [links]
 * @property {Record<string, any>} currentCandidate
 * @property {() => void} refreshCandidate
 * @property {(text: string) => void} handleSnackbarOpen
 */

const INIT_LINK_STATE = {
  name: null,
  id: null,
  url: null
};
/**
 *
 * @param {Props} props
 * @returns {JSX.Element}
 */
const LinkCandidatePanel = ({ handleSnackbarOpen, links, currentCandidate, refreshCandidate }) => {
  const [open, setOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState(INIT_LINK_STATE);

  const handleOnLinkUpdate = useCallback(
    /**
     *
     * @param {import('../generic_components/LinkList/LinkListItem').LinkItem} link
     */
    link => {
      setSelectedLink(link);
      setOpen(true);
    },
    []
  );

  const handleCloseDialog = useCallback(() => {
    setOpen(false);
    setSelectedLink(INIT_LINK_STATE);
  }, []);

  const handleDialogSubmit = useCallback(
    fields => {
      if (!currentCandidate.id) {
        return;
      }
      updateCandidateLinks(currentCandidate.id, fields.id, { name: fields.name, url: fields.url })
        .then(() => {
          refreshCandidate();
          handleCloseDialog();
        })
        .catch(() => {
          handleSnackbarOpen('Konnte Link nicht updaten!');
        });
    },
    [currentCandidate, handleCloseDialog, handleSnackbarOpen, refreshCandidate]
  );

  const handleOnClickLink = useCallback(
    /**
     *
     * @param {import('../generic_components/LinkList/LinkListItem').LinkItem} link
     */
    link => {
      navigator.clipboard.writeText(link.url);
      handleSnackbarOpen('Link erfolgreich in Zwischenablage kopiert!');
    },
    [handleSnackbarOpen]
  );

  const handleOnLinkDelete = useCallback(
    /**
     *
     * @param {import('../generic_components/LinkList/LinkListItem').LinkItem} link
     */
    link => {
      if (currentCandidate.id) {
        removeCandidateLinks(currentCandidate.id, link.id)
          .then(() => {
            refreshCandidate();
            handleSnackbarOpen('Link erfolgreich gelöscht!');
          })
          .catch(() => {
            handleSnackbarOpen('Fehler beim Löschen des Links!');
          });
      }
    },
    [currentCandidate.id, handleSnackbarOpen, refreshCandidate]
  );

  const handleOnLinkOpen = useCallback(
    /**
     *
     * @param {import('../generic_components/LinkList/LinkListItem').LinkItem} link
     */
    link => {
      window.open(link.url, '_blank');
    },
    []
  );

  const formFields = useMemo(
    () => ({
      name: {
        value: selectedLink.name,
        label: 'Link Name',
        validator: 'isText',
        required: true
      },
      url: {
        value: selectedLink.url,
        label: 'URL',
        validator: 'isUrl',
        required: true
      },
      id: {
        value: selectedLink.id,
        additionalProps: {
          hidden: true
        }
      }
    }),
    [selectedLink]
  );

  return (
    <div className="row col-12">
      <Paper style={{ width: '100%', maxHeight: '1000px', overflow: 'auto', marginTop: '10px' }}>
        <div className="col-12">
          <div className="p-2">
            <LinkList
              links={links}
              onLinkClick={handleOnClickLink}
              onLinkDelete={handleOnLinkDelete}
              onLinkUpdate={handleOnLinkUpdate}
              onLinkOpen={handleOnLinkOpen}
            />
            <GenericFormPopUp
              open={open}
              headlineText="Link anpassen"
              genericFormProps={{
                formFields,
                handleClose: handleCloseDialog,
                handleSubmit: handleDialogSubmit
              }}
            />
          </div>
        </div>
      </Paper>
    </div>
  );
};

LinkCandidatePanel.propTypes = {
  links: PropTypes.array,
  currentCandidate: PropTypes.object.isRequired,
  refreshCandidate: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired
};

LinkCandidatePanel.defaultProps = {
  links: []
};

export default LinkCandidatePanel;
