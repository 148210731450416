import $ from 'jquery';
import React, { PureComponent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Tooltip
} from '@material-ui/core';
import { AddCircle as AddCircleIcon, RemoveCircle as RemoveCircleIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

// Custom elements
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';
import TEXT from '../text';

import { removeItemFromList } from '../general/markLists/markListAPI';

class MarkItemPopUp extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      listName: '',
      isDisabled: true,
      savedLists: ''
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
    this.initialState = JSON.parse(JSON.stringify(this.state));
  }

  componentDidMount() {
    this.loadMarkListsAPICall();
  }

  handleClose = () => {
    const initialState = this.initialState;
    initialState.savedLists = this.state.savedLists;
    this.setState({
      ...initialState
    });
    this.props.handleClose();
  };

  loadMarkListsAPICall() {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.loadMarkListsURL;
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify({ markListType: this.props.markListPopupType }),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        // responseBody,
        this.setState({
          savedLists: responseBody.allMarkListsOfUser
        });
      })
      .fail(err => {
        this.errorHandling(err, url);
      });
  }

  generateSnackBarMessage(requestPackage, responseBody) {
    if (this.props.item.candidate) {
      return `${
        responseBody.isNewList
          ? TEXT.markListPopup.snackBarItemAddCandidateInfoToList
          : TEXT.markListPopup.snackBarItemAddCandidateInfoToNewList
      }${requestPackage.listName}${TEXT.markListPopup.addedInfo}`;
    }
    if (this.props.item.counterPart) {
      return `${
        responseBody.isNewList
          ? TEXT.markListPopup.snackBarItemAddCounterPartInfoToList
          : TEXT.markListPopup.snackBarItemAddCounterPartInfoToNewList
      }${requestPackage.listName}${TEXT.markListPopup.addedInfo}`;
    }
    if (this.props.item.project) {
      return `${
        responseBody.isNewList
          ? TEXT.markListPopup.snackBarItemAddProjectInfoToList
          : TEXT.markListPopup.snackBarItemAddProjectInfoToNewList
      }${requestPackage.listName}${TEXT.markListPopup.addedInfo}`;
    }
    return `${
      responseBody.isNewList
        ? TEXT.markListPopup.snackbarInfoCompany.addItemToNewMarkList
        : TEXT.markListPopup.snackbarInfoCompany.addItemToExistingMarkList
    }${requestPackage.listName}${TEXT.markListPopup.addedInfo}`;
  }

  addUserItemToMarkList(listName = '') {
    this.setState({
      isDisabled: false
    });
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const item = this.props.item;
    if (!(item.matchPercentage && item.willingnessToChange)) {
      item.matchPercentage = 0;
      item.willingnessToChange = 0;
    }
    const requestPackage = {
      item: this.props.item,
      listName: 'New List'
    };
    if (this.state.listName !== '' && listName === '') {
      requestPackage.listName = this.state.listName.trim();
    } else {
      requestPackage.listName = listName.trim();
    }
    const url = CONSTANTS.addItemToMarkListURL;
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: { 'x-auth': tokenFromLocalStorage },
      data: JSON.stringify(requestPackage),
      timeout: CONFIG.ajaxTimeout
    })
      .done((responseBody, status) => {
        console.log('API /addItemToMarkList success response', responseBody, status);

        this.setState(
          {
            savedLists: responseBody.allMarkListsOfUser
          },
          this.handleClose()
        );
        // TODO does not check if trimmed list in MarkList Collection exists
        const infoMessage = this.generateSnackBarMessage(requestPackage, responseBody);
        this.props.handleSnackbarOpen(infoMessage);
      })
      .fail(err => {
        console.log('error while deletion of search', JSON.stringify(err));
        this.errorHandling(err, url, this.liftUpLoggedIn);
      });
    this.setState({
      isDisabled: true
    });
  }

  removeUserFromMarkList() {}

  isItemInMarkList(savedList) {
    let result;
    if (savedList.companyMarks.length !== 0) {
      result = savedList.companyMarks.find(item => item.company === this.props.item.company);
    } else if (savedList.candidateMarks.length !== 0) {
      result = savedList.candidateMarks.find(item => item.candidate === this.props.item.candidate);
    } else if (savedList.counterPartMarks.length !== 0) {
      result = savedList.counterPartMarks.find(item => item.counterPart === this.props.item.counterPart);
    } else if (savedList.projectMarks.length !== 0) {
      result = savedList.projectMarks.find(item => item.project === this.props.item.project);
    }
    if (result !== undefined) {
      return true;
    }
    return false;
  }

  isExistingList(listName) {
    return this.state.savedLists.some(list => list.name === listName);
  }

  markListLengthByContent(savedList) {
    if (this.props.item.company) {
      return `${savedList.name} (${savedList.companyMarks.length})`;
    }
    if (this.props.item.project) {
      return `${savedList.name} (${savedList.projectMarks.length})`;
    }
    if (this.props.item.counterPart) {
      return `${savedList.name} (${savedList.counterPartMarks.length})`;
    }
    return `${savedList.name} (${savedList.candidateMarks.length})`;
  }

  markListInfoByContentType(savedList) {
    if (this.props.item.candidate) {
      return savedList.candidateMarks.find(item => item.candidate === this.props.item.candidate)
        ? TEXT.markListPopup.itemIsInListInfo
        : TEXT.markListPopup.addItemToMarkListInfo;
    }
    if (this.props.item.counterPart) {
      return savedList.counterPartMarks.find(item => item.counterPart === this.props.item.counterPart)
        ? TEXT.markListPopup.counterPartIsInListInfo
        : TEXT.markListPopup.addCounterPartToMarkListInfo;
    }
    if (this.props.item.project) {
      return savedList.projectMarks.find(item => item.project === this.props.item.project)
        ? TEXT.markListPopup.projectIsInListInfo
        : TEXT.markListPopup.addProjectToMarkListInfo;
    }
    return savedList.companyMarks.find(item => item.company === this.props.item.company)
      ? TEXT.markListPopup.companyIsInListInfo
      : TEXT.markListPopup.addCompanyToListInfo;
  }

  removeElementFromList(listId) {
    const selectedList = this.state.savedLists.find(entry => entry._id.toString() === listId.toString());
    const listMetaInfo = [
      { listType: 'candidateMarks', itemType: 'candidate' },
      { listType: 'counterPartMarks', itemType: 'counterPart' },
      { listType: 'companyMarks', itemType: 'company' },
      { listType: 'projectMarks', itemType: 'project' }
    ].filter(entry => selectedList[entry.listType].length > 0)[0];
    const item = selectedList[listMetaInfo.listType].find(entry => entry[listMetaInfo.itemType] === this.props.item[listMetaInfo.itemType]);

    removeItemFromList(listId, item._id)
      .then(() => {
        this.loadMarkListsAPICall();
      })
      .catch(error => console.log(error));
  }

  renderListItems() {
    let originList = [];
    if (this.state.listName === '') {
      originList = this.state.savedLists;
    } else {
      if (this.state.savedLists) {
        originList = this.state.savedLists.filter(item => item.name.toLowerCase().includes(this.state.listName.trim().toLowerCase()));
      }
      if (originList.length === 0) {
        return TEXT.markListPopup.noExistingList;
      }
    }

    const resultArray = [];

    if (originList) {
      originList.forEach(savedList => {
        const { _id: listId } = savedList;
        let markListLengthByType = 0;
        let tooltipTitleAdd = 'zu Merkliste hinzufügen';
        let tooltipTitleRemove = 'von Merkliste entfernen';
        if (this.props.item.candidate) {
          markListLengthByType = savedList.candidateMarks ? savedList.candidateMarks.length : 0;
          tooltipTitleAdd = `Kandidat ${tooltipTitleAdd}`;
          tooltipTitleRemove = `Kandidat ${tooltipTitleRemove}`;
        } else if (this.props.item.company) {
          markListLengthByType = savedList.companyMarks ? savedList.companyMarks.length : 0;
          tooltipTitleAdd = `Unternehmen ${tooltipTitleAdd}`;
          tooltipTitleRemove = `Unternehmen ${tooltipTitleRemove}`;
        } else if (this.props.item.counterPart) {
          markListLengthByType = savedList.counterPartMarks ? savedList.counterPartMarks.length : 0;
          tooltipTitleAdd = `Ansprechpartner ${tooltipTitleAdd}`;
          tooltipTitleRemove = `Ansprechpartner ${tooltipTitleRemove}`;
        } else if (this.props.item.project) {
          markListLengthByType = savedList.projectMarks ? savedList.projectMarks.length : 0;
          tooltipTitleAdd = `Auftrag ${tooltipTitleAdd}`;
          tooltipTitleRemove = `Auftrag ${tooltipTitleRemove}`;
        }
        if (markListLengthByType !== 0) {
          resultArray.push(
            <ListItem color="primary" key={listId} disabled>
              <ListItemText primary={this.markListLengthByContent(savedList)} secondary={this.markListInfoByContentType(savedList)} />
              <ListItemSecondaryAction>
                <Tooltip title={tooltipTitleAdd} placement="top">
                  <span>
                    <IconButton
                      aria-label="AddToList"
                      color="primary"
                      disabled={this.isItemInMarkList(savedList)}
                      onClick={() => this.addUserItemToMarkList(savedList.name)}
                    >
                      <AddCircleIcon color={this.isItemInMarkList(savedList) ? 'disabled' : 'primary'} />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={tooltipTitleRemove} placement="top">
                  <span>
                    <IconButton
                      aria-label="RemoveFromList"
                      disabled={!this.isItemInMarkList(savedList)}
                      onClick={() => {
                        this.removeElementFromList(listId);
                      }}
                    >
                      <RemoveCircleIcon color={!this.isItemInMarkList(savedList) ? 'disabled' : 'error'} />
                    </IconButton>
                  </span>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          );
        }
      });
    }
    return resultArray;
  }

  render() {
    return (
      <Dialog open={this.props.open} aria-labelledby="markCandidatePopUp-dialog-title">
        <DialogTitle>{TEXT.markListPopup.headline[this.props.markListPopupType]}</DialogTitle>
        <DialogContent>
          <TextField
            value={this.state.listName}
            name="listName"
            label={TEXT.markListPopup.placeholder}
            fullWidth
            autoFocus
            margin="normal"
            inputProps={{
              maxLength: 35
            }}
            onChange={e => {
              this.setState({
                listName: e.target.value,
                isDisabled: e.target.value.length === 0 || this.isExistingList(e.target.value)
              });
            }}
          />
          <div className="float-right mb-4">
            <Button
              className="button-more-info"
              variant="contained"
              disabled={this.state.isDisabled}
              color="secondary"
              onClick={() => this.addUserItemToMarkList()}
            >
              {TEXT.markListPopup.button}
              <i className="material-icons pl-2">playlist_add</i>
            </Button>
          </div>
          <div className="container mt-5 pt-5">
            {this.renderListItems().length > 0 && <DialogContentText>{TEXT.markListPopup.subheadline}</DialogContentText>}
            <List>{this.renderListItems()}</List>
          </div>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={this.handleClose}>
            {TEXT.markListPopup.cancelButton}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

MarkItemPopUp.defaultProps = {
  open: false,
  item: {}
};

MarkItemPopUp.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  handleSnackbarOpen: PropTypes.func.isRequired,
  item: PropTypes.shape({
    candidate: PropTypes.string,
    company: PropTypes.string,
    project: PropTypes.string,
    counterPart: PropTypes.string,
    matchPercentage: PropTypes.number,
    willingnessToChange: PropTypes.number
  }),
  markListPopupType: PropTypes.string.isRequired
};

export default MarkItemPopUp;
