import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';

import MonitoringTable from './MonitoringTable';

import withUser from '../utils/withUser';
import * as CONSTANTS from '../constants';
import * as CONFIG from '../config';
import * as UTILS from '../utils/utilFunctions';

class MonitoringTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data || {}
    };
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  componentDidMount() {
    if (!Object.keys(this.state.data).length) this.loadPastSearches();
  }

  loadPastSearches = filters => {
    this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: true });
    const url = CONSTANTS.getPastSearchesOfClientsURL;
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const requestBody = {};
    if (filters && filters.fromDate) {
      requestBody.fromDate = filters.fromDate;
    }
    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      contentType: 'application/json; charset=utf-8',
      headers: {
        'x-auth': tokenFromLocalStorage
      },
      data: JSON.stringify(requestBody),
      timeout: CONFIG.ajaxTimeout
    })
      .done(responseBody => {
        // console.log('API register response body: ', responseBody);
        console.log('API /getPastSearchesOfClients response body: ', responseBody);
        this.setState({ data: responseBody });
        this.props.liftUpStateToAdminPage({ pastSearchData: responseBody });
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });
      })
      .fail(err => {
        console.log('Error API /getPastSearchesOfClients err: ', err);
        this.props.liftUpStateToApp({ showLoadingIndicatorGlobal: false });

        this.errorHandling(err, url);
      });
  };

  // PAGE: Admin.Monitoring
  render() {
    return (
      <div>
        {this.state.data && this.state.data.length > 0 ? (
          <MonitoringTable
            // liftUpStateToApp={this.props.liftUpStateToApp}
            data={this.state.data}
          />
        ) : (
          <div>
            <div className="sideBarWrapper p-2">
              <div className="emptyPage">Es konnten keine Suchanfragen abgerufen werden</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

MonitoringTab.defaultProps = {
  data: {}
};

MonitoringTab.propTypes = {
  liftUpStateToApp: PropTypes.func.isRequired,
  liftUpStateToAdminPage: PropTypes.func.isRequired,
  data: PropTypes.array
};
export default withUser(MonitoringTab);
