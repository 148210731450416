import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import IntranetDocuments from '../intranet/IntranetDocuments';
import * as UTILS from '../utils/utilFunctions';
import withUser from '../utils/withUser';
import './Admin.css';

const styles = {
  root: {},
  label: {}
};

const Intranet = ({ handleSnackbarOpen }) => {
  useEffect(() => {
    document.title = `Intranet - ${UTILS.getTitle()}`;
  }, []);

  return (
    <div className="adminPage h-100vh">
      <IntranetDocuments handleSnackbarOpen={handleSnackbarOpen} />
    </div>
  );
};

export default withUser(withRouter(withStyles(styles)(Intranet)));

Intranet.propTypes = {
  handleSnackbarOpen: PropTypes.func.isRequired
};
