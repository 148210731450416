import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// libraries
import $ from 'jquery';

import GenericFormPopUp from '../../generic_components/GenericFormPopUp';

import * as CONSTANTS from '../../constants';
import * as CONFIG from '../../config';
import * as UTILS from '../../utils/utilFunctions';
import TEXT_GENERAL from '../../text';

// import TEXT from './staticContent/text';
// import { staticResources } from './staticContent/ProjectDetailsUpdatePopUpDropDowns';

class CandidateEducationUpdatePopUpContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.form = this.generateFormFields();

    // error handling required for API calls
    this.errorHandling = UTILS.errorHandling.bind(this);
  }

  updateAPICall = updatedData => {
    const tokenFromLocalStorage = window.sessionStorage.getItem('token');
    const url = CONSTANTS.updateCandidateSubSchemaURL;

    const payload = {
      candidateId: this.props.candidateId,
      subSchemaName: 'languageSkills',
      subSchemaId: this.props.entryId,
      subSchemaUpdatedData: updatedData
    };
    if (Object.keys(updatedData).length > 0) {
      console.log(`API ${url} request`, payload);
      $.ajax({
        url,
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json; charset=utf-8',
        headers: { 'x-auth': tokenFromLocalStorage },
        data: JSON.stringify(payload),
        timeout: CONFIG.ajaxTimeout
      })
        .done((responseBody, status) => {
          console.log(`API /${url} response`, responseBody, status);
          this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.updateSuccess);
          this.props.refreshCandidate();
          this.props.handleClose();
        })
        .fail(err => {
          this.errorHandling(err, url);
        });
    } else {
      this.props.handleSnackbarOpen(TEXT_GENERAL.snackBarTexts.noUpdate);
    }
  };

  generateFormFields() {
    // console.log('DEBUG contactDate', this.props.data.contactDate);

    const forms = {
      formFields: {
        language: {
          value: this.props.data.language,
          label: 'Sprache',
          customClass: 'col-12 mb-2'
        },
        skillLevel: {
          value: this.props.data.skillLevel,
          label: 'Grad der Beherrschung',
          customClass: 'col-12 mb-2',
          type: 'dropDown',
          content: [
            'Anfängerkenntnisse',
            'Grundkenntnisse',
            'Fortgeschrittene Kenntnisse',
            'Gute Kenntnisse',
            'Sehr gute Kenntnisse',
            'Perfekte Kenntnisse oder Muttersprache'
          ],
          required: true
        }
      }
    };

    return forms;
  }

  render() {
    this.form = this.generateFormFields();
    console.log('DEBUG this.props.data', this.props.data);
    return (
      <GenericFormPopUp
        open={this.props.open}
        headlineText="Eintrag ändern"
        // key="hallo"
        genericFormProps={{
          formFields: this.form.formFields,
          isUpdate: true,
          handleSubmit: this.updateAPICall,
          // initialData: this.props.data,
          handleClose: this.props.handleClose

          // buttonCancelText: TEXT.projectDetailsUpdatePopUp.buttons.cancel,
          // buttonSaveText: TEXT.projectDetailsUpdatePopUp.buttons.save
        }}
      />
    );
  }
}
CandidateEducationUpdatePopUpContainer.defaultProps = {
  // isUpdate: false,
  data: {}
};

CandidateEducationUpdatePopUpContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,

  // data
  candidateId: PropTypes.string.isRequired,
  entryId: PropTypes.string.isRequired,
  data: PropTypes.object,
  // snackBar
  handleSnackbarOpen: PropTypes.func.isRequired,
  refreshCandidate: PropTypes.func.isRequired
};

export default CandidateEducationUpdatePopUpContainer;
