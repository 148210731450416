import $ from 'jquery';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

// material UI
import Button from '@material-ui/core/Button';

import Tooltip from '@material-ui/core/Tooltip';
import { KeyboardDatePicker, KeyboardDateTimePicker } from '@material-ui/pickers';
import { Editor } from '@tinymce/tinymce-react';

import { TextField } from '@material-ui/core';
import CompanyReferenceField from '../adminPage/CompanyReferenceField';
import SwitchInputField from './SwitchInputField';
import ValidatedDropDown from './ValidatedDropDown';
import ValidatedTextField from './ValidatedTextField';

import CurrencyInputLienert from '../components/inputs/CurrencyInputLienert';
import * as CONSTANTS from '../constants';

class GenericForm extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      formFields: this.props.formFields
    };
    this.initialState = JSON.parse(JSON.stringify(this.state));

    this.allFormFieldKeys = Object.keys(this.state.formFields);
    // validation
    this.formFieldRefs = [];

    this.allFormFieldKeys.forEach(formFieldKey => {
      if (this.state.formFields[formFieldKey].type !== 'editor' && this.state.formFields[formFieldKey].ignoreValidation !== true) {
        this.formFieldRefs.push(React.createRef());
      }
    });
  }

  getNewData(key) {
    let currentValue;

    // case inputField
    if (this.state.formFields[key]) currentValue = this.state.formFields[key].value;
    // case dropDowns
    else currentValue = this.state.formFields[key].value;

    return currentValue;
  }

  getUpdateData(key) {
    let result;

    let initialValue = this.initialState.formFields[key].value;
    // special case for companyReference
    if (this.initialState.formFields[key].type === 'companyReference') initialValue = this.initialState.formFields[key].value.id;

    let currentValue;
    // case inputField
    currentValue = this.state.formFields[key].value;
    // case companyReference
    if (this.initialState.formFields[key].type === 'companyReference') currentValue = this.state.formFields[key].value.id;

    if (initialValue !== currentValue) {
      // handle case initialValue and currentValue both undefined, empty or null
      if (initialValue || currentValue) result = currentValue;
    }

    return result;
  }

  generateNewData() {
    const newData = {};
    this.allFormFieldKeys.forEach(fieldKey => {
      const newValue = this.getNewData(fieldKey);
      if (newValue !== undefined) newData[fieldKey] = newValue;
    });
    return newData;
  }

  /**
   * handles nested fields such as links__xing
   */
  populateUpdatedFieldToUpdatedData(fieldKey, updatedValue, updatedData) {
    const newUpdatedData = updatedData;
    const fieldKeyNested = fieldKey.split('__');
    // first level key
    if (fieldKeyNested.length === 1) {
      newUpdatedData[fieldKey] = updatedValue;
      // second level nested key: use following syntax for key: firstLevel__secondLevel
    } else if (fieldKeyNested.length === 2) {
      newUpdatedData[fieldKeyNested[0]] = newUpdatedData[fieldKeyNested[0]] || {};
      newUpdatedData[fieldKeyNested[0]][fieldKeyNested[1]] = updatedValue;
      // third level nested key: use following syntax for key: firstLevel__secondLevel__thirdLevel
    } else if (fieldKeyNested.length === 3) {
      newUpdatedData[fieldKeyNested[0]] = newUpdatedData[fieldKeyNested[0]] || {};
      newUpdatedData[fieldKeyNested[0]][fieldKeyNested[1]] = newUpdatedData[fieldKeyNested[0]][fieldKeyNested[1]] || {};
      newUpdatedData[fieldKeyNested[0]][fieldKeyNested[1]][fieldKeyNested[2]] = updatedValue;
    }
    return newUpdatedData;
  }

  generateUpdateData() {
    let updatedData = {};
    this.allFormFieldKeys.forEach(fieldKey => {
      const updatedValue = this.getUpdateData(fieldKey);
      if (updatedValue !== undefined) {
        updatedData = this.populateUpdatedFieldToUpdatedData(fieldKey, updatedValue, updatedData);
      }
    });

    return updatedData;
  }

  updateData(handler) {
    const updatedData = this.generateUpdateData();
    handler(updatedData);
  }

  saveNewData(handler) {
    const newData = this.generateNewData();
    handler(newData);
  }

  saveAndClose = data => {
    this.props.handleSubmit(data);
    if (this.props.handleClose) this.props.handleClose();
  };

  saveAndContinue = data => {
    this.props.handleSubmit(data);
    if (this.props.handleSaveAndContinue) this.props.handleSaveAndContinue();
  };

  // validation
  validateAllFormFields = () => {
    let fieldsValid = true;

    this.formFieldRefs.forEach(ref => {
      if (ref.current && !ref.current.isInputValid()) fieldsValid = false;
    });
    return fieldsValid;
  };

  handleConfirmButtonClick(e, handler) {
    e.preventDefault();
    const isValidForm = this.validateAllFormFields();
    if (isValidForm) {
      if (this.props.isUpdate) this.updateData(handler);
      else this.saveNewData(handler);
    }
  }

  onDropDownChange = e => {
    const inputFieldValue = e.target.value;
    const inputFieldKey = e.target.name;
    this.setState(prevState => ({
      formFields: {
        ...prevState.formFields,
        [inputFieldKey]: {
          ...prevState.formFields[inputFieldKey],
          value: inputFieldValue
        }
      }
    }));
  };

  handleEditorChange = e => {
    const inputFieldValue = e.target.getContent();
    const inputFieldKey = e.target.id;

    this.setState(prevState => ({
      formFields: {
        ...prevState.formFields,
        [inputFieldKey]: {
          ...prevState.formFields[inputFieldKey],
          value: inputFieldValue
        }
      }
    }));
  };

  onInputFieldChange = e => {
    const inputFieldValue = e.target.value;
    const inputFieldKey = e.target.id;

    this.setState(prevState => ({
      formFields: {
        ...prevState.formFields,
        [inputFieldKey]: {
          ...prevState.formFields[inputFieldKey],
          value: inputFieldValue
        }
      }
    }));
  };

  onLienertCurrencyChange = e => {
    const inputFieldValue = e.target.value;
    const inputFieldKey = e.target.name;
    this.setState(prevState => ({
      formFields: {
        ...prevState.formFields,
        [inputFieldKey]: {
          ...prevState.formFields[inputFieldKey],
          value: inputFieldValue || 0
        }
      }
    }));
  };

  onDateFieldChange = (date, id) => {
    const inputFieldValue = date;
    const inputFieldKey = id;

    this.setState(prevState => ({
      formFields: {
        ...prevState.formFields,
        [inputFieldKey]: {
          ...prevState.formFields[inputFieldKey],
          value: inputFieldValue
        }
      }
    }));
  };

  onCompanyReferenceFieldChange = (companyReference, fieldId) => {
    this.setState(prevState => ({
      formFields: {
        ...prevState.formFields,
        [fieldId]: {
          ...prevState.formFields[fieldId],
          value: companyReference
        }
      }
    }));
  };

  renderDropDown(dropDownId, index) {
    const currentDropDownState = this.state.formFields[dropDownId];
    return (
      <ValidatedDropDown
        id={dropDownId}
        key={dropDownId}
        ref={this.formFieldRefs[index]}
        errorMessage={currentDropDownState.errorMessage}
        content={currentDropDownState.content}
        onChange={this.onDropDownChange}
        {...currentDropDownState}
      />
    );
  }

  renderDateField(fieldId, additionalProps) {
    let picker = (
      <div className="picker">
        <KeyboardDatePicker
          id={fieldId}
          key={fieldId}
          label={this.state.formFields[fieldId].label}
          format="dd.MM.yyyy"
          cancelLabel="Abbrechen"
          value={this.state.formFields[fieldId].value === '' ? null : this.state.formFields[fieldId].value}
          onChange={date => this.onDateFieldChange(date, fieldId)}
          onClear={() => this.onDateFieldChange(null, fieldId)}
          disableOpenOnEnter
          animateYearScrolling={false}
          {...additionalProps}
        />
      </div>
    );
    if (this.state.formFields[fieldId].tooltip) picker = <Tooltip title={this.state.formFields[fieldId].tooltip}>{picker}</Tooltip>;
    return picker;
  }

  renderDateTimeField(fieldId) {
    return (
      <div className="picker">
        <KeyboardDateTimePicker
          id={fieldId}
          key={fieldId}
          ampm={false}
          label={this.state.formFields[fieldId].label}
          format="dd.MM.yyyy HH:mm"
          cancelLabel="Abbrechen"
          value={this.state.formFields[fieldId].value === '' ? null : this.state.formFields[fieldId].value}
          onChange={date => this.onDateFieldChange(date, fieldId)}
          onClear={() => this.onDateFieldChange(null, fieldId)}
          disableOpenOnEnter
          animateYearScrolling={false}
        />
      </div>
    );
  }

  renderEditor(inputFieldId, index, furtherProps) {
    const props = {
      ...furtherProps,
      ...this.state.formFields[inputFieldId]
    };
    const value = props.value ? props.value.toString() : '';
    return (
      <Editor
        initialValue={value}
        id={inputFieldId}
        init={{
          language_url: '/langs/de.js',
          branding: false,
          height: props.init && props.init.height ? props.init.height : '300',
          selector: 'textarea',
          fontsize_formats: '8pt 10pt 11pt 12pt 14pt 16pt 18pt 24pt 36pt',
          plugins: 'link code lists textcolor colorpicker textpattern imagetools',
          font_formats:
            'Arial=arial,helvetica,sans-serif;Georgia=georgia,serif;Times New Roman=times new roman,serif;Courier New=courier new,courier,monospace;Stencil=stencil,arial,sans-serif;Optima nova LT Pro=Optima nova LT Pro',
          toolbar: 'bold italic | bullist numlist | fontselect |  sizeselect fontsizeselect | forecolor | image',
          content_style:
            "body { font-family: Optima nova LT Pro; } @font-face { font-family: 'Optima nova LT Pro'; src: url('/fonts/OptimaNovaLTPro-Regular.otf') format('truetype'); font-weight: normal; font-style: normal; font-display: swap; } @font-face { font-family: 'Optima nova LT Pro'; src: url('/fonts/OptimaNovaLTPro-Bold.otf') format('truetype'); font-weight: bold; font-style: normal; font-display: swap; } @font-face { font-family: 'Optima nova LT Pro'; src: url('/fonts/OptimaNovaLTPro-Demi.otf') format('truetype'); font-weight: normal; font-style: normal; font-display: swap; }",

          setup(editor) {
            editor.on('init', () => {
              $('#editor_container').css('display', 'block');
              editor.execCommand('fontName', false, CONSTANTS.isLienert ? 'Optima nova LT Pro' : 'Georgia');
              editor.execCommand('fontSize', false, '10');
            });
          }
        }}
        onChange={this.handleEditorChange}
      />
    );
  }

  renderInputField(inputFieldId, index, furtherProps) {
    const props = {
      ...furtherProps,
      ...this.state.formFields[inputFieldId]
    };
    const value = props.value ? props.value.toString() : '';

    if (this.state.formFields[inputFieldId].tooltip)
      return (
        <div>
          <Tooltip title={this.state.formFields[inputFieldId].tooltip}>
            <ValidatedTextField
              fullWidth
              id={inputFieldId}
              key={inputFieldId}
              autoFocus={index === 0}
              ref={this.formFieldRefs[index]}
              onChange={this.onInputFieldChange}
              {...props}
              value={value}
            />
          </Tooltip>
        </div>
      );
    return (
      <ValidatedTextField
        fullWidth
        id={inputFieldId}
        key={inputFieldId}
        autoFocus={index === 0}
        ref={this.formFieldRefs[index]}
        onChange={this.onInputFieldChange}
        {...props}
        value={value}
      />
    );
  }

  renderSwitchInputField(inputFieldId, index, furtherProps) {
    const props = {
      ...furtherProps,
      ...this.state.formFields[inputFieldId]
    };

    const value = props.value ? props.value.toString() : '';
    return (
      <SwitchInputField
        fullWidth
        id={inputFieldId}
        key={inputFieldId}
        autoFocus={index === 0}
        forwardRef={this.formFieldRefs[index]}
        onChange={this.onInputFieldChange}
        {...props}
        value={value}
      />
    );
  }

  renderLienertCurrencyInputField(inputFieldId, index, furtherProps) {
    const props = {
      ...furtherProps,
      ...this.state.formFields[inputFieldId]
    };

    const value = props.value ? props.value : '';

    return (
      <TextField
        fullWidth
        name={inputFieldId}
        onChange={this.onLienertCurrencyChange}
        InputProps={{
          inputComponent: CurrencyInputLienert
        }}
        {...props}
        value={value}
      />
    );
  }

  renderSwitchDateInputField(inputFieldId, index, furtherProps) {
    const props = {
      ...furtherProps,
      ...this.state.formFields[inputFieldId]
    };
    const value = props.value ? props.value : null;
    return (
      <SwitchInputField
        isDate
        id={inputFieldId}
        key={inputFieldId}
        autoFocus={index === 0}
        innerRef={this.formFieldRefs[index]}
        onChange={this.onDateFieldChange}
        {...props}
        value={value}
      />
    );
  }

  renderCompanyReferenceField(inputFieldId, index, furtherProps) {
    const props = {
      ...furtherProps,
      ...this.state.formFields[inputFieldId]
    };
    const value = props.value ? props.value : { name: '', id: '' };
    return (
      <CompanyReferenceField
        value={value}
        id={inputFieldId}
        key={inputFieldId}
        forwardRef={this.formFieldRefs[index]}
        onChange={this.onCompanyReferenceFieldChange}
      />
    );
  }

  renderForm() {
    return (
      <div className="row">
        {this.allFormFieldKeys.map((fieldKey, index) => {
          const { customClass, type, additionalProps } = this.state.formFields[fieldKey];
          let typeOfField = 'textField';
          if (type) typeOfField = type;
          if (typeOfField === 'dropDown') {
            return (
              <div className={customClass || this.props.fieldClass} key={fieldKey}>
                {this.renderDropDown(fieldKey, index, additionalProps)}
              </div>
            );
          }
          if (typeOfField === 'date') {
            return (
              <div className={customClass || this.props.fieldClass} key={fieldKey}>
                {this.renderDateField(fieldKey, additionalProps)}
              </div>
            );
          }
          if (typeOfField === 'dateTime') {
            return (
              <div className={customClass || this.props.fieldClass} key={fieldKey}>
                {this.renderDateTimeField(fieldKey)}
              </div>
            );
          }
          if (typeOfField === 'lienertCurrency') {
            return (
              <div className={customClass || this.props.fieldClass} key={fieldKey}>
                {this.renderLienertCurrencyInputField(fieldKey, index, additionalProps)}
              </div>
            );
          }
          if (typeOfField === 'editor') {
            return (
              <div className={customClass || this.props.fieldClass} key={fieldKey} style={{ display: 'none' }} id="editor_container">
                {this.renderEditor(fieldKey, index, additionalProps)}
              </div>
            );
          }
          if (typeOfField === 'switchInputField') {
            return (
              <div className={customClass || this.props.fieldClass} key={fieldKey}>
                {this.renderSwitchInputField(fieldKey, index, additionalProps)}
              </div>
            );
          }
          if (typeOfField === 'switchDateInputField') {
            return (
              <div className={customClass || this.props.fieldClass} key={fieldKey}>
                {this.renderSwitchDateInputField(fieldKey, index, additionalProps)}
              </div>
            );
          }
          if (typeOfField === 'companyReference') {
            return (
              <div className={customClass || this.props.fieldClass} key={fieldKey}>
                {this.renderCompanyReferenceField(fieldKey, index, additionalProps)}
              </div>
            );
          }
          return (
            <div className={customClass || this.props.fieldClass} key={fieldKey}>
              {this.renderInputField(fieldKey, index, additionalProps)}
            </div>
          );
        })}
      </div>
    );
  }

  renderButtons() {
    return (
      <div className="row">
        <div className="col-12 text-right pt-2">
          {this.props.handleClose && this.props.buttonCancelText && (
            <span className="pl-2">
              <Button onClick={this.props.handleClose} color="primary">
                {this.props.buttonCancelText}
              </Button>
            </span>
          )}
          {this.props.handleContinue !== undefined && (
            <span className="pl-2">
              <Button onClick={this.props.handleContinue} color="primary">
                {this.props.buttonContinueText}
              </Button>
            </span>
          )}
          {this.props.handleSaveAndContinue !== undefined && (
            <span className="pl-2">
              <Button onClick={e => this.handleConfirmButtonClick(e, this.saveAndContinue)} color="primary">
                {this.props.buttonSaveAndContinueText}
              </Button>
            </span>
          )}
          {this.props.handleSecondarySubmit && (
            <span className="pl-2">
              <Button onClick={e => this.handleConfirmButtonClick(e, this.props.handleSecondarySubmit)} color="primary">
                {this.props.buttonSecondarySubmitText}
              </Button>
            </span>
          )}
          <span className="pl-2">
            <Button
              onClick={e => this.handleConfirmButtonClick(e, this.props.alwaysContinue ? this.saveAndContinue : this.saveAndClose)}
              type="submit"
              color="primary"
              variant="contained"
            >
              {this.props.buttonSaveText}
            </Button>
          </span>
        </div>
      </div>
    );
  }

  render() {
    return (
      <form>
        <div className="container">
          {this.props.buttonsOnTop && this.renderButtons()}
          {this.renderForm()}
          {this.props.buttonsOnBottom && this.renderButtons()}
        </div>
      </form>
    );
  }
}
GenericForm.defaultProps = {
  handleClose: undefined,
  isUpdate: false,

  fieldClass: 'col-6 mb-2',

  // text elements
  buttonCancelText: 'Abbrechen',
  buttonSaveText: 'Speichern',
  buttonSaveAndContinueText: 'Speichern und weiter',
  buttonSecondarySubmitText: 'Vorschau',
  handleSaveAndContinue: undefined,
  buttonContinueText: 'weiter',
  handleContinue: undefined,
  handleSecondarySubmit: undefined,
  alwaysContinue: false,
  buttonsOnTop: false,
  buttonsOnBottom: true
};

GenericForm.propTypes = {
  handleClose: PropTypes.func, // leave empty to not render the cancel button
  buttonsOnTop: PropTypes.bool,
  buttonsOnBottom: PropTypes.bool,
  isUpdate: PropTypes.bool,
  formFields: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleSecondarySubmit: PropTypes.func,

  // class for input field. Only used for input fields without customClass property
  fieldClass: PropTypes.string,

  // text elements
  buttonCancelText: PropTypes.string,
  buttonSaveText: PropTypes.string,
  buttonSaveAndContinueText: PropTypes.string,
  buttonSecondarySubmitText: PropTypes.string,
  handleSaveAndContinue: PropTypes.func,
  buttonContinueText: PropTypes.string,
  handleContinue: PropTypes.func,
  alwaysContinue: PropTypes.bool
};

export default GenericForm;
